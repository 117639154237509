import React, { FunctionComponent, useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Analytics from "../Screens/Analytics/Analytics";
import Reports from "../Screens/Reports/Reports";
import { Route } from "react-router-dom";
import OrganizationPanel from "../Screens/OrganizationPanel/OrganizationPanel";
import AddUsers from "../Screens/AddUsers/AddUsers";
import { connect, useDispatch } from "react-redux";
import { fetchOrganizationsUsers } from "../Services/users";
import ApiPanel from "../Screens/ApiPanel/ApiPanel";
import NewsPanel from "../Screens/NewsPanel/NewsPanel";
import CustomButtonPanel from "../Screens/CustomButtonPanel/CustomButtonPanel";
import UpdatePassword from "../Screens/UpdatePassword/UpdatePassword";
import styles from '../Screens/Analytics/Analytics.module.css'
import AnalyticsSideNav from "../Components/AnalyticsSideNav/AnalyticsSideNav";
// import Integration from "../Screens/IntegrationFacebookInstagram/Integration";
// import Integration from '../Screens/IntegrationFacebookInstagram/Integration'
import IntegrationFacebook from "../Screens/IntegrationFacebookInstagram/Integration";
import TrailPreriodAlert from "../Components/TrialPeriodAlert/TrailPreriodAlert";
import SubscriptionAlert from "../Components/SubscriptionAlert/SubscriptionAlert";
import TransactionHistory from "../Screens/TransactionHistory/TransactionHistory";
import PaymentDetails from "../Screens/PaymentDetails/PaymentDetails";
import UpgradeUser from "../Screens/UpgradeUser/UpgradeUser";
import HelpSupport from '../Screens/HelpSupport/HelpAndSupport';
import SubscriptionDetails from "../Screens/SubscriptionDetails/SubscriptionDetails";
import { getFbsslsValueFromApiTokenFB } from "../Services/organizations";
// import HelpSupport from "../Screens/HelpSupport/HelpSupport";
import FacebookIntegration from '../Screens/IntegrationFacebookInstagram/IntegrationFacebook'
import IntegrationAcres from '../Screens/IntegrationFacebookInstagram/99Acres';
import IntegrationMagicbricks from '../Screens/IntegrationFacebookInstagram/Magicbricks';
import IntegrationHousing from '../Screens/IntegrationFacebookInstagram/Housing';
import IntegrationJustdial from '../Screens/IntegrationFacebookInstagram/Justdial';
import IntegrationSulekha from '../Screens/IntegrationFacebookInstagram/Sulekha';
import IntegrationWebSide from '../Screens/IntegrationFacebookInstagram/WebSite';
import LeadDistributor from "../Screens/LeadDistributor/LeadDistributor";
import Project from "../Screens/ProjectsPanel/ProjectsPanel";
import AddProject from "../Screens/AddProject/AddProject";
import ProjectDetails from "../Screens/ProjectDetails/ProjectDetails";
import ProjectEditModal from "../Components/Modals/ProjectEditModal/ProjectEditModal";
import FAQPanel from "../Screens/FAQPanel/FAQPanel";
import LeadDistributorLogic from "../Screens/LeadDistributorLogic/LeadDistributorLogic";
import AddFAQ from "../Screens/AddFAQ/AddFAQ";
import EditFAQ from "../Screens/EditFAQ/EditFAQ";
import Resources from "../Screens/Resources/Resources";
import ApiDataPanel from "../Screens/ApiDataPanel/ApiDataPanel";
import Booking from "../Screens/Booking/Booking";
import AddBooking from "../Screens/Booking/AddBooking";
import AdminAnalytics from "../Screens/Analytics/AdminAnalytics";
import EditLeadDistributorLogic from "../Screens/LeadDistributorLogic/EditLeadDistributorLogic";

type props = {
  organizationId: string;
  history: any;
  showDeactivationAlert: any;
  paymentStatus: any;
  trialPeriodStatus: any;
  organizationdata: any,

};

const OrganizationRoute: FunctionComponent<props> = ({
  organizationId,
  history,
  showDeactivationAlert,
  paymentStatus,
  trialPeriodStatus,
  organizationdata
}) => {
  const dispatcher = useDispatch();

  useEffect(() => {
    if (organizationId) {
      const unSub = fetchOrganizationsUsers(
        dispatcher,
        organizationId
      );
      return unSub;
    }
    // eslint-disable-next-line
  }, [organizationId]);

  useEffect(()=>{
    if(organizationId){
      getFbsslsValueFromApiTokenFB(organizationId);
    }
  },[organizationId]);
  return (
    <>
      <Navbar
        props={{ history }}
        title={"Users"}
        path={"/"}
        leadManger={false}
        api={"/apis"}
        news={"/news"}
        customButton={"/customButton"}
        leadDistributor={"/leadDistributor"}
        project={"/projects"}
        faq={"/faq"}
        resources={"/resources"}
        apiData={"/apiData"}
      />
      {((trialPeriodStatus && organizationdata.payment_status) && history.location.pathname !== "/subscriptionDetails")
        &&
        <div style={{ position: 'fixed', zIndex: 1500,display:'flex',width:"100%",height:'auto',justifyContent:'center',alignItems:"center",marginTop:'5px'}}>
          <div style={{display:'flex',width:"63%",height:'20px',justifyContent:'center',alignItems:"center"}}>
          <TrailPreriodAlert role={"Admin"} />
          </div>
        </div>
      }
      {((showDeactivationAlert || organizationdata.payment_status === false) && history.location.pathname !== "/subscriptionDetails")
        &&
        <SubscriptionAlert role={"Admin"} paymentStatus={organizationdata.payment_status} />
      }
      <div className={styles.parent} style={{overflow:"hidden",height:"100vh"}}>
        <AnalyticsSideNav
          props={{ history }}
          title={"Users"}
          path={"/"}
          leadManger={false}
          operationManger={false}
          api={"/apis"}
          news={"/news"}
          customButton={"/customButton"}
          updatePassword={"/updateUserPassword"}
          leadDistributor={"/leadDistributor"}
          project={"/projects"}
          faq={"/faq"}
          resources={"/resources"}
          apiData={"/apiData"}
          // integrations={"/integrations"}
          // subscriptionDetails={"/subscriptionDetails"}
          // helpSupport={"/helpSupport"}
        />
          {/* <Route path="/helpSupport" component={HelpSupport} /> */}
          <Route path="/viewHelpSupport" component={HelpSupport} />
        {
          organizationdata.payment_status &&
          <>
            <Route exact path="/" component={OrganizationPanel} />
            <Route path="/analytics" component={AdminAnalytics} />
            {/* <Route path="/analytics" component={Analytics} /> */}
            <Route path="/reports" component={Reports} />
            <Route path="/apis" component={ApiPanel} />
            <Route path="/addUsers" component={AddUsers} />
            <Route path="/news" component={NewsPanel} />
            <Route path="/leadDistributor" component={LeadDistributor} />
            <Route path="/leadDistributorLogic" component={LeadDistributorLogic} />
            <Route path="/editDistributorLogic" component={EditLeadDistributorLogic} />
            <Route path="/updateUserPassword" component={UpdatePassword} />
            <Route path="/integrations" component={IntegrationFacebook} />
            <Route path="/facebook-leads" component={FacebookIntegration} />
            <Route path="/99acres" component={IntegrationAcres} />
            <Route path="/magicbricks" component={IntegrationMagicbricks} />
            <Route path="/justdial" component={IntegrationJustdial} />
            <Route path="/sulekha" component={IntegrationSulekha} />
            <Route path="/website" component={IntegrationWebSide} />
            <Route path="/housing" component={IntegrationHousing} />
            <Route path="/projects" component={Project} />
            <Route path="/projectDetails" component={ProjectDetails} />
            <Route path="/editProjects" component={ProjectEditModal} />
            <Route path="/addProjects" component={AddProject} />
            <Route path="/faq" component={FAQPanel} />
            <Route path="/addFAQ" component={AddFAQ} />
            <Route path="/editFAQ" component={EditFAQ} />
            <Route path="/resources" component={Resources} />
            <Route path="/apiData" component={ApiDataPanel} />
            <Route path="/booking" component={Booking} />
            <Route path="/addBooking" component={AddBooking} />
            <Route path="/viewBookingDetails" component={AddBooking} />
            <Route
              path="/customButton"
              component={CustomButtonPanel}
            />
          </>
        }
        <Route path="/subscriptionDetails" component={SubscriptionDetails} />
        <Route path="/transactionHistory" component={TransactionHistory} />
        <Route path="/paymentDetails" component={PaymentDetails} />
        <Route path="/upgradeUsers" component={UpgradeUser} />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    showDeactivationAlert: state.organization.showDeactivationAlert,
    paymentStatus: state.organization.paymentStatus,
    trialPeriodStatus: state.organization.organizationData.trial_period,
    organizationdata: state.organization.organizationData
  };
};

export default connect(mapStateToProps)(OrganizationRoute);
