import React, { useEffect,useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeRoute from "./HomeRoute";
import AuthRoute from "./AuthRoute";
import { checkUser } from "../Services/auth";
import PasswordReset from "../Screens/PasswordReset/PasswordReset";
import { connect, useDispatch } from "react-redux";
import { updateTheme } from "../Redux/actions";
import { useHistory } from "react-router";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";

const AppRoutes = ({
  userStatus,
  userRole,
  theme,
}: {
  userStatus: boolean;
  userRole: string;
  theme: any;
}) => {

  const dispatcher = useDispatch();
  const history = useHistory();

  const setTheme = async () => {
  let isLightMode = await localStorage.getItem("isLightMode");
    
    if (isLightMode === "true") {
      dispatcher(updateTheme(true))
    } else {
      dispatcher(updateTheme(false))
    }
  }

  useEffect(() => {
    // if(userRole !=="Lead Manager" && userRole !=="Sales" && userRole !=="Team Lead"){
    //   localStorage.removeItem("isLightMode");
    // }
    checkUser(dispatcher,history);
    // checkUser(dispatcher);
    setTheme();
    // eslint-disable-next-line
  }, [userRole]);

  // useEffect(() => {
  //   checkUser(dispatcher);
  //   // eslint-disable-next-line
  // }, [userStatus]);

  // useEffect(() => {
  //   fetchUserStatusData(user.uid)
  // }, [organizationUsers]);

  //  const fetchUserStatusData = (uid: string) => {
  //     firestore
  //       .collection("users")
  //       .doc(uid)
  //       .get()
  //       .then((user) => {
  //         if (user) {
  //           let data: any = user.data();
  //           if(data?.status==="ACTIVE")
  //           {
  //             dispatcher(updateUserStatus(true))
  //           }
  //           else
  //           {
  //             dispatcher(updateUserStatus(false))
  //           }
  //         }
  //       })
  //   };

  return (
    <Router>
      {userStatus !== undefined && (
        <>
          {userStatus === false && <Route component={AuthRoute} />}
          {userStatus === true && <Route component={HomeRoute} />}
        </>
      )}
      <Route path="/resetPassword" component={PasswordReset} />
      <Route path="/privacy_policy" component={PrivacyPolicy} />
    </Router>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(AppRoutes);
