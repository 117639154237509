import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar, alertTitleClasses } from "@mui/material";
import {
    contactResources,
} from "../../Services/contacts";
import './booking.style.css';
import { FileUpload } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone'
import { green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { uploadAttachment } from "../../Services/booking";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { showSnackbarAction } from "../../Redux/actions";
import moment from "moment";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../Components/theme";

const sourceFund = ["Self Funding", "Self Loan Process", "Loan Stone"];
const CssTextField: any = styled(TextField)({
    fontSize: "0.8rem"
});
const arrMeasure = ['Square Mm (Sq.Mm)',
    'Square Cm (Sq.Cm)',
    'Square Dm (Sq.Dm)',
    'Square M (Sq.M)',
    'Square In (Sq.In)',
    'Square Ft (Sq.Ft)',
    'Square Yd (Sq.Yd)',
    'Acre',
    'Hectare (H)',
    'Square Km (Sq.Km)',
    'Gunta',
    'Cent (dismil)',
    'Bigha (Kaccha)',
    'Bigha (Gujarat)',];
type props = {
    //   history: any;
    // user: any;
    theme: any;
    formikProps: any;
    projectsList: any;
    projectDeveloperObj: any;
    setBookingAttechment: any;
    setKycAttechment: any;
    authToken: any;
};
const EditBookingDetails: FunctionComponent<props> = ({ formikProps, projectsList, projectDeveloperObj, setBookingAttechment, setKycAttechment, theme,authToken }) => {
    const dispatcher = useDispatch();
    const location: any = useLocation();
    const handleFile = (url: any, type: any) => {
        if (type === "file") {
            const filePath = url;
            formikProps.setFieldValue('booking_attachment', '')
        }
        else {
            const filePath = url;
            formikProps.setFieldValue('Kyc_attachment', '')
        }
    }
    const handleDelete = (type: any) => {
        if (type === "KYC") {
            formikProps.setFieldValue('Kyc_attachment', '')
        }
        else if (type === "FILE") {
            formikProps.setFieldValue('booking_attachment', '')
        }
    }
    const useHelperTextStyles = makeStyles(() => ({
        inputRoot: {
            // "&$disabled": {
            //   color: "green"
            // }
        },
        disabled: {
        },
        root: {
            "& .MuiFormLabel-root": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiFormLabel-root.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSelect-select": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSelect-select.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiInputBase-input": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiInputBase-input.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
                "-webkit-text-fill-color": `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiOutlinedInput-input": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiOutlinedInput-input.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSvgIcon-root": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSvgIcon-root.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            }
        },
        notchedOutline: {
            borderWidth: "1px",
            borderColor: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark} !important`,
        },
        preview: {
            color: "black"
        },
        dropzone: {
            color: "black"
        },
        formHelperText: {
        },
    }));
    const helperTextStyles = useHelperTextStyles();
    const viewAttachment = (e: any, value: any) => {
        e.preventDefault()
        window.open(value)
    }

    return (
        <>
            <div style={{
                border: "solid 1px #AEAEAE",
                boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                borderRadius: "10px",
                padding: "10px",
            }}>
                <Grid container className="booking_container" spacing={2} style={{ padding: "30px" }} >
                    <Typography className="textTypography" variant="subtitle1">Booking Details</Typography>
                    <Grid container className='child-container' spacing={2}>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Bookings ID"
                                size="small"
                                id="custom-css-outlined-input"
                                value={formikProps.values.booking_id}
                                disabled
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Added by"
                                size="small"
                                id="custom-css-outlined-input"
                                value={formikProps.values.added_by}
                                disabled
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Added Time"
                                size="small"
                                id="custom-css-outlined-input"
                                value={formikProps.values.added_time}
                                disabled
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Modified By"
                                // required
                                // disabled
                                size="small"
                                disabled
                                id="custom-css-outlined-input"
                                value={formikProps.values.modified_by}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Modified Time"
                                // required
                                disabled
                                size="small"
                                id="custom-css-outlined-input"
                                value={formikProps.values.modified_time}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            // {...formikProps.getFieldProps('modified_time')}
                            />
                        </Grid>
                    </Grid>
                    <Typography className="textTypography" variant="subtitle1">Employee Details</Typography>
                    <Grid container className='child-container' spacing={2}>
                        <Grid item xs={6}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Employee Name"
                                required
                                size="small"
                                disabled
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('employee_details.owner_email')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="In-Active Employee (If Any)"
                                required
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('employee_details.in_active_employe')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                    </Grid>
                    <Typography className="textTypography" variant="subtitle1">Booking Registration</Typography>
                    <Grid container className='child-container' spacing={2}>
                        <Grid item xs={3}>
                            <CssTextField
                                type="date"
                                fullWidth
                                label="Date of Booking"
                                required
                                size="small"
                                InputLabelProps={{ shrink: true, required: true }}
                                id="custom-css-outlined-input"
                                defaultValue={moment(formikProps.values.date_booking).format("YYYY-MM-DD")}
                                {...formikProps.getFieldProps('date_booking')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                select
                                required
                                label="Project Name"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('project_name')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                                {projectsList.map((option: any) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                required
                                select
                                label="Developer Name"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('developer_name')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                                {projectDeveloperObj?.map((option: any) => (
                                    <MenuItem key={option.developer_name} value={option.developer_name}>
                                        {option.developer_name}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                required
                                select
                                label="Source of Fund"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('source_fund')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                                {sourceFund?.map((option: any) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Unit Number"
                                required
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('unit_no')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="PAN Number"
                                required
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('pan_card')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Area"
                                required
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('area')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Measure"
                                required
                                select
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('area_type')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                                {arrMeasure.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Grid>
                        <Grid item xs={8}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Scheme if Any"
                                required
                                multiline
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('scheme')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {formikProps.values.booking_attachment && typeof formikProps.values.booking_attachment !== 'object' && formikProps.values.booking_attachment !== null ? <>
                                <div
                                    style={{ display: "flex" }}
                                >
                                    <div>
                                        <button
                                            className="addBox"
                                            onClick={(e) => viewAttachment(e, formikProps.values.booking_attachment)}
                                        // onClick={() => window.open(formikProps.values.booking_attachment)}
                                        >
                                            <PreviewIcon />
                                            {"View Booking Attachment"}
                                        </button>
                                    </div>
                                    <div>
                                        <DeleteForeverIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => handleDelete("FILE")}
                                        />
                                    </div>
                                </div>
                            </>
                                : <>
                                    <span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Upload your Booking Attachment</span>
                                    <DropzoneArea
                                        dropzoneParagraphClass="dropzone-text"
                                        filesLimit={1}
                                        showAlerts={true}
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        maxFileSize={80000000000}
                                        acceptedFiles={['text/csv', 'application/pdf']}
                                        onDrop={(files) => formikProps.setFieldValue('booking_attachment', files[0])}
                                        onDelete={() => handleFile(formikProps.values.booking_attachment, "file")}
                                    /></>}
                        </Grid>
                        <Grid item xs={6}>
                            {(formikProps.values.Kyc_attachment) && typeof formikProps.values.Kyc_attachment !== 'object' && formikProps.values.Kyc_attachment !== null ? <>
                                <div
                                    style={{ display: "flex" }}
                                >
                                    <div>
                                        <button
                                            className="addBox"
                                            onClick={(e) => viewAttachment(e, formikProps.values.Kyc_attachment)}
                                        // onClick={() => window.open(formikProps.values.Kyc_attachment)}
                                        >
                                            <PreviewIcon />
                                            {`View KYC Image`}
                                        </button>
                                    </div>
                                    <div>
                                        <DeleteForeverIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => handleDelete("KYC")}
                                        />
                                    </div>
                                </div>
                            </>
                                : <>
                                    <span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Upload your KYC Attachment</span>
                                    <DropzoneArea
                                        dropzoneParagraphClass="dropzone-text"
                                        filesLimit={1}
                                        showAlerts={true}
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        acceptedFiles={['image/jpeg', 'image/png']}
                                        onDrop={(files) => formikProps.setFieldValue('Kyc_attachment', files[0])}
                                        onDelete={() => handleFile(formikProps.values.Kyc_attachment, "photo")}
                                    /></>}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme: state.theme.isLightMode,
        authToken:state.user.authToken
    };
};
export default connect(mapStateToProps)(EditBookingDetails);