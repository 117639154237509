import React, {
  FunctionComponent,
  useEffect,
  useState,
} from "react";
// import "./SideNavBar.css"
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AnalyticsIcon from '@mui/icons-material/Analytics';  
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import './sideNavBar.style.css';
import classNames from "classnames";
import styles from "../Navbar/Navbar.module.css";
import analyticsIcon from "../../Assets/Images/analytics.svg";
import activeAnalyticsIcon from '../../Assets/Images/analyticsActive.svg'
import contactsIcon from "../../Assets/Images/contact-info-1.svg";
import contactsIconActive from "../../Assets/Images/contact-info-Active.svg";
import projectIcon from "../../Assets/Images/checklist.svg";
import projectIconActive from "../../Assets/Images/checklistActive.svg";
import tasksIcon from "../../Assets/Images/paper.svg";
import tasksIconActive from "../../Assets/Images/paperActive.svg";
import resourcesIcon from "../../Assets/Images/globe.svg";
import resourcesIconActive from "../../Assets/Images/globeActive.svg";
import callLogsIcon from "../../Assets/Images/call.svg";
import callLogsIconActive from "../../Assets/Images/callActive.svg";
import faqIcon from "../../Assets/Images/faq.svg";
import faqIconActive from "../../Assets/Images/faqActive.svg";
import apiIcon from "../../Assets/Images/cloud-api (2).svg";
import apiIconActive from "../../Assets/Images/cloud-api-Active.svg";
import leadDIcon from "../../Assets/Images/leader.svg";
import leadDIconActive from "../../Assets/Images/leaderActive.svg";
import newsIconActive from "../../Assets/Images/newsActive.svg";
import newsIcon from "../../Assets/Images/news.svg";
import userIcon from "../../Assets/Images/user.svg";
import userIconActive from "../../Assets/Images/userActive.svg";
import clearFilterIcon from "../../Assets/Images/filter-remove.svg"
import { setClearFilter } from "../../Redux/actions";
import { connect, useDispatch } from 'react-redux';
import {themeColors} from "../../Components/theme";
import ContactsIcon from '@mui/icons-material/Contacts';
import TaskIcon from '@mui/icons-material/Task';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LanguageIcon from '@mui/icons-material/Language';
import PaymentIcon from '@mui/icons-material/Payment';
import PhoneIcon from '@mui/icons-material/Phone';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import GroupsIcon from '@mui/icons-material/Groups';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PasswordIcon from '@mui/icons-material/Password';
import './SideNav.css';
import SideNav from "./SideNav"; 

// const drawerWidth = 200;

// const openedMixin = (theme: Theme): CSSObject => ({
//   width: drawerWidth,
//   border:"none",
//   fontFamily: "Poppins",
//   fontWeight: "bold",
//   fontSize: "0.8rem",
//   boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19)',
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: 'hidden',
//   zIndex:200
// });

// const closedMixin = (theme: Theme): CSSObject => ({
//   border:"none",
//   fontFamily: "Poppins",
//   fontWeight: "bold",
//   fontSize: "0.8rem",
//   zIndex:200,
//   boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19)',
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: 'hidden',
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up('sm')]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

// const DrawerHeader = styled('div')(({ theme: any }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
// }));

// interface AppBarProps extends MuiAppBarProps {
//   open?: boolean;
// }

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })<AppBarProps>(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     width: drawerWidth,
//     flexShrink: 0,
//     border:"none",
//     whiteSpace: 'nowrap',
//     boxSizing: 'border-box',
//     ...(open && {
//       ...openedMixin(theme),
//       '& .MuiDrawer-paper': openedMixin(theme),
//     }),
//     ...(!open && {
//       ...closedMixin(theme),
//       '& .MuiDrawer-paper': closedMixin(theme),
//     }),
//   }),
// );

type props = {
  props?: any;
  title:
  | "Organizations"
  | "Contacts"
  | "Users"
  | "Bookings"
  | "Drilldown";
  path?: string;
  leadManger?: Boolean;
  operationManger?: Boolean;
  project?: string;
  task?: string;
  resources?: string;
  callLogs?: string;
  faq?: string;
  user: any;
  api?: string;
  news?: string;
  apiData?: string;
  leadDistributor?: string;
  customButton?: string;
  appTheme:any;
  updatePassword?: string;
};
const MiniDrawer: FunctionComponent<props> = ({
  props,
  title,
  path,
  leadManger,
  operationManger,
  project,
  task,
  resources,
  callLogs,
  faq,
  user,
  api,
  news,
  apiData,
  leadDistributor,
  customButton,
  appTheme,
  updatePassword
}) => {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const dispatcher = useDispatch();

  // const handleDrawer = () => {
  //   if (!open) {
  //     setOpen(true);
  //   }
  //   else {
  //     setOpen(false);
  //   }
  // };
  
 
  const sideNavbarContainer = {
    backgroundColor:
    appTheme ? themeColors.sidebarBackgroundColor:themeColors.sidebarBackgroundColorDark,
    color:appTheme ? themeColors.textPrimary:themeColors.textPrimaryDark
  }
  const iconColor={
    color:appTheme ? themeColors.iconColor:themeColors.iconColorDark
  }

  
  return (
    <Box className="containerSideBar" sx={{ display: 'flex',marginTop:"5px",overflow:"hidden",minHeight:"100vh"}}>
      <SideNav user={user.profile} pathname={props?.history?.location?.pathname} sadmin={user.user_email} Tcolor={sideNavbarContainer} />
    </Box >
  );
}
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    appTheme:state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(MiniDrawer);