import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  createTaskFirebase,
  fetchConstants,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import moment from "moment";
import Firebase from "firebase/app";
import { useHistory, useLocation } from "react-router-dom";
import TextInput from "../../TextInput/TextInput";
import {themeColors} from "../../theme";
import Label from "../../Label";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";

type props = {
  contactDetail: any;
  close: () => void;
  open: boolean;
  tasksData: any[];
  user: any;
  notes: any[];
  theme: any;
  conatctHeader: any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const CallBackModal: FunctionComponent<props> = ({
  contactDetail,
  close,
  open,
  tasksData,
  user,
  notes,
  theme,
  conatctHeader,
}) => {
  const dateRef: any = useRef();
  const noteRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [callBackSelected, setCallBackSelected] =
    useState<any>({
      label: contactDetail.call_back_reason
        ? contactDetail.call_back_reason
        : "Select",
      value: contactDetail.call_back_reason
        ? contactDetail.call_back_reason
        : "Select",
    });

  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [callbackList, setCallBackList] = useState<any[]>(
    []
  );

  useEffect(() => {
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      (data) => setnextFollowUpList(data),
      (data) => setCallBackList(data)
    );

    return () => {
      unsubConst();
    };
    // eslint-disable-next-line
  }, []);
  const dispatcher = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const onSubmit = () => {
    if (callBackSelected.value === "Select") {
      dispatcher(
        showSnackbarAction("Please Select Reason", "error")
      );
    } else if (dateRef.current.value < new Date()) {
      dispatcher(
        showSnackbarAction(
          "Task Cannot be Schedule For Old Date & Time!",
          "error"
        )
      );
    } else {
      const data = {
        call_back_reason: callBackSelected.value,
        due_date: moment(dateRef.current.value).toDate(),
        type: "Call Back",
        status: "Pending",
        customer_name: contactDetail.customer_name,
      };

      const changeLeadData =
        contactDetail.stage === "CALLBACK"
          ? {}
          : {
              stage_change_at:
                Firebase.firestore.Timestamp.now(),
            };

      if (noteRef.current.value.length !== 0) {
        addNoteFirebase(
          contactDetail.contactId,
          notes,
          noteRef.current.value,
          () => {},
          dispatcher,
          () => {},
          user.user_email
        );
      }
      createTaskFirebase(
        contactDetail.contactId,
        tasksData,
        data,
        {
          stage: "CALLBACK",
          call_back_reason: callBackSelected.value,
          next_follow_up_type: "Call Back",
          next_follow_up_date_time: moment(
            dateRef.current.value
          ).toDate(),
          modified_at: Firebase.firestore.Timestamp.now(),
          ...changeLeadData,
        },
        (value: boolean) => setLoad(value),
        dispatcher,
        user,
        close,
        history
      );
      location.state.detail.stage = "Call Back";
      location.state.detail.next_follow_up_date_time =
        moment(dateRef.current.value).toDate();
      location.state.detail.modified_at =
        Firebase.firestore.Timestamp.now().toDate();
      location.state.detail.call_back_reason =
        callBackSelected.value;
    }
  };
  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
    {load === true && <Loading />}
    {open && <Backdrop>
      <motion.div
                   key="modal"
                   className="card p-4 shadow rounded"
                   style={{position: "absolute",minWidth: "400px",
                   ...containerStyles}}
                   variants={dropIn}
                   initial="hidden"
                   animate="visible"
                   exit="exit"
                 >
      <div className={styles.firstDiv}>
        <h5 
        // className={styles.contactForm}
        >
          Call Back Details
        </h5>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      <div className={styles.line}></div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          {/* <div className={styles.title}>
            <p className={styles.one}>Call Back Reason</p>
            <p className={styles.two}></p>
          </div> */}
          <Label label={conatctHeader.find((obj:any) => obj.label === "call_back_reason").value} required={false} />
          <div style={{ marginTop: "7px" }}>
            <Dropdown
              option={callbackList}
              selectedValue={callBackSelected}
              setSelectedValue={(value) => {
                setCallBackSelected(value);
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          {/* <div className={styles.title}>
            <p className={styles.one}>
              Next Follow Up Date & Time
            </p>
            <p className={styles.two}></p>
          </div> */}
          <Label label={conatctHeader.find((obj:any) => obj.label === "next_follow_up_date_time").value} required={false} />
          <div>
            <input
              type="datetime-local"
              placeholder="Enter Date"
              ref={dateRef}
              className={styles.dateInput}
            />
          </div>
        </div>
      </div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          {/* <div className={styles.title}>
            <p className={styles.one}>Note</p>
            <p className={styles.two}></p>
          </div> */}
          <Label label="Note" required={false} />
          <div>
            <TextInput
              title={"Enter Note"}
              style={{ backgroundColor: "#fff" }}
              ref={noteRef}
            ></TextInput>
          </div>
        </div>
      </div>

      <button
        className={styles.apply}
        style={{ marginTop: "2rem" }}
        onClick={onSubmit}
      >
        Save
      </button>
                 </motion.div>
      </Backdrop>}
    </>

    // <Modal
    //   className={styles.parent}
    //   isOpen={open}
    //   shouldCloseOnOverlayClick={true}
    //   overlayClassName={styles.overlay}
    //   shouldCloseOnEsc={true}
    //   onRequestClose={close}
    //   style={customStyles}
    // >
    //   {load === true && <Loading />}
    //   <div className={styles.firstDiv}>
    //     <h5 
    //     // className={styles.contactForm}
    //     >
    //       Call Back Details
    //     </h5>
    //     <div className={styles.cross} onClick={close}>
    //       <IoIosClose size={35} color={"#808080"} />
    //     </div>
    //   </div>
    //   <div className={styles.line}></div>

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>Call Back Reason</p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Call Back Reason" required={false} />
    //       <div style={{ marginTop: "7px" }}>
    //         <Dropdown
    //           option={callbackList}
    //           selectedValue={callBackSelected}
    //           setSelectedValue={(value) => {
    //             setCallBackSelected(value);
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>
    //           Next Follow Up Date & Time
    //         </p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Next Follow Up Date & Time" required={false} />
    //       <div>
    //         <input
    //           type="datetime-local"
    //           placeholder="Enter Date"
    //           ref={dateRef}
    //           className={styles.dateInput}
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>Note</p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Note" required={false} />
    //       <div>
    //         <TextInput
    //           title={"Enter Note"}
    //           style={{ backgroundColor: "#fff" }}
    //           ref={noteRef}
    //         ></TextInput>
    //       </div>
    //     </div>
    //   </div>

    //   <button
    //     className={styles.apply}
    //     style={{ marginTop: "auto" }}
    //     onClick={onSubmit}
    //   >
    //     Save
    //   </button>
    // </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    conatctHeader:state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(CallBackModal);
