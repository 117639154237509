export const organizationCreateDateNameReducer = (
    state = {
      data: {},
    },
    action: {
      type: "SET_CREATE_DATE";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "SET_CREATE_DATE":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };