import React, { FunctionComponent, useState } from "react";
import Modal from "react-modal";
import styles from "./PasswordChange.module.css";
import { useDispatch, connect } from "react-redux";
import { themeColors } from "../../Components/theme";

type Props = {
  open: boolean;
  close: () => void;
  theme: any;
  setNewPassword: (password: string) => void;
  setConfirmPassword: (password: string) => void;
  confirmPassword:any;
  newPassword:any;
  changePasswordUser: () => void;
};

const ChangePasswordModal: FunctionComponent<Props> = ({
  open,
  close,
  theme,
  setNewPassword,
  setConfirmPassword,
  confirmPassword,
  newPassword,
  changePasswordUser
}) => {

  const handleChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const handleSavePassword = () => {
    close();
  };
  const customStyles = {
    content: {
      backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark,
      color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
    },
  };
  // const toggleNewPasswordVisibility = () => {
  //   setShowNewPassword(!showNewPassword);
  // };

  // const toggleConfirmPasswordVisibility = () => {
  //   setShowConfirmPassword(!showConfirmPassword);
  // };

  return (
    <Modal
      className={styles.parent}
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={close}
      style={customStyles}
    >
      <div className={styles.profileContainer}>
        <p className={styles.profile}>New Password</p>
        <div className={styles.passwordInputContainer}>
          <input
            type="text"
            value={newPassword}
            onChange={handleChangeNewPassword}
            className={styles.inputField}
          />
          {/* <i className={`fa ${showNewPassword ? "fa-eye-slash" : "fa-eye"} ${styles.eyeIcon}`} onClick={toggleNewPasswordVisibility}></i> */}
        </div>
        <p className={styles.profile}>Confirm Password</p>
        <div className={styles.passwordInputContainer}>
          <input
            type='text'
            value={confirmPassword}
            onChange={handleChangeConfirmPassword}
            className={styles.inputField}
          />
          {/* <i className={`fa ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"} ${styles.eyeIcon}`} onClick={toggleConfirmPasswordVisibility}></i> */}
        </div>
      </div>
      <div className={styles.buttonView} style={{ marginTop: "25px" }}>
        <button className={styles.cancelButton} onClick={close}>
          Cancel
        </button>
        <button
          className={styles.saveButton}
          onClick={changePasswordUser}
        >
          Change Password
        </button>
      </div>
    </Modal>
  );
};
const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    theme: state.theme.isLightMode,
  };
};
export default connect(mapStateToProps)(ChangePasswordModal);
