import React, { FunctionComponent } from "react";
import styles from "./AnalyticsFilter.module.css";
import Select from "react-select";
import { connect } from "react-redux";
import { themeColors } from "../theme";

type Props = {
  organizationIdNameData: any;
  setOrganizationSelectId: (data: any) => void;
  organizationSelectId: any;
  setshowOrgFilter: (data: boolean) => void;
  theme: any;
};

const OriginationFilter: FunctionComponent<Props> = ({
  organizationIdNameData,
  setOrganizationSelectId,
  organizationSelectId,
  setshowOrgFilter,
  theme,
}) => {
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.dropdownBackgroundColor
      : themeColors.dropdownBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: theme
        ? themeColors.backgroundColor
        : themeColors.backgroundColorDark,
      color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: theme
        ? themeColors.dropdownBackgroundColor
        : themeColors.dropdownBackgroundColorDark,
      color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    }),
    // Style for the selected value
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    }),
    // Style for the options when hovering
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#363853" // Change this to the desired hover color
        : provided.backgroundColor,
      color: state.isFocused
        ? "White" // Change this to the desired hover text color
        : provided.color,
    }),
  };

  return (
    <div className={styles.parent} style={containerStyles}>
      <Select
        className={styles.select}
        options={organizationIdNameData}
        value={organizationSelectId}
        onChange={(data) => setOrganizationSelectId(data)}
        styles={customStyles}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(OriginationFilter);
