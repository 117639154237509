import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import SuperAdminRoute from "./SuperAdminRoute";
import OrganizationRoute from "./OrganizationRoute";
import UserRoute from "./UserRoute";
import LeadManagerRoute from "./LeadManagerRoute";
import DrilldownDataRoute from "./DrilldownDataRoute";
import OrganizationManagerRoute from "./OrganizationManagerRoute";
import { firestore, functions } from "../Firebase";
import { getDateString, getDateIsoString, compareDates, getExpirationDate, sendMail, getDateString2 } from "../Values/utils";
import { setDeactivationAlert, setOrganizationData, setAuthToken } from "../Redux/actions";
import { useDispatch } from "react-redux";
import { handleUser } from "../Services/auth";
import TrailPreriodAlert from "../Components/TrialPeriodAlert/TrailPreriodAlert";

const HomeRoute = (props: any) => {
  const dispatch = useDispatch();

  let path = props.history.location.pathname

  const updatePlan = (organizationId: any, value: any) => {
    let docRef = firestore.collection("organizations").doc(organizationId);
    docRef.update({
      payment_status: value
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  }
  const updateTrialStatus = (organizationId: any, value: any) => {
    let docRef = firestore.collection("organizations").doc(organizationId);
    docRef.update({
      payment_status: value,
      trial_period: value
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  }
  useEffect(() => {
    let organizationId = props.user.organization_id
    let docRef = firestore.collection("organizations").doc(organizationId);
    try {
      docRef.get().then((doc) => {
        if (doc.exists) {
          let data: any = doc.data();
          dispatch(setOrganizationData({ organizationData: data }));
          let gracePeriod = data.grace_period;
          let trialPeriod = data.trial_period
          let validTill = getDateIsoString(data.valid_till);
          let createDate = getDateIsoString(data.created_at);
          let createDataWithTrial = getExpirationDate(createDate, 7);
          let validTillDate = getDateString2(data.valid_till);
          let expirationDate = getExpirationDate(validTill, gracePeriod);
          let currentDate = new Date().toISOString().slice(0, 10);
          let isDeactivated = compareDates(currentDate, expirationDate);
          let istrialPeriod = compareDates(currentDate, createDataWithTrial);
          let paymentStatus = data.payment_status;
          let mailId = data.org_mail_id;
          let orgName = data.organization_name;

          if (isDeactivated && paymentStatus) {
            const mailRequest = `<div>Hello,</div> <br/><div>Leads Rubix licenses of ${orgName} has expired and all their users have been disabled.</div> <br/>  <div>Please reach out to them as soon as possible.</div>  <br/>`
            const mailRequestUser = `<div>Hello,</div> <br/><div>Leads Rubix licenses of your organization has expired and all of your users have been disabled.</div> <br/>  <div>Please renew your Leads Rubix subscription to continue using our services.</div>  <br/>`
            // sendMail(mailRequest,["helpdesk@noteg.live","accounts@noteg.live"],"Account deactivated");
            // sendMail(mailRequestUser,mailId,"Account deactivated");
            updatePlan(organizationId, false);
          } else {
            if (isDeactivated === false) {
              if (istrialPeriod && trialPeriod) {
                updateTrialStatus(organizationId, false);
              }
              else if (paymentStatus === true) {
                updatePlan(organizationId, true);
              }
            }
            let isLicenseInactive = compareDates(currentDate, validTill);
            if (isLicenseInactive) {
              dispatch(setDeactivationAlert({ showAlert: isLicenseInactive, expirationDate: expirationDate, validTill: validTillDate }))
            }
            else {
              dispatch(setDeactivationAlert({ showAlert: isLicenseInactive, expirationDate: expirationDate, validTill: validTillDate }))
            }
          }
        } else {
          console.log("No such document!");
        }
      })
    } catch (err) {
      console.log(err);
    }
  }, [
    props.user, path
  ])

  useEffect(() => {
    if (props.userStatus) {
      let authTokenRef = firestore.collection("values").doc("constants");
      authTokenRef.get().then((doc) => {
        if (doc.exists) {
          let authToken = doc.data()?.authToken
          dispatch(setAuthToken({ authToken: authToken }))
        } else {
          dispatch(setAuthToken({ authToken: null }))
        }
      }).catch((error) => {
        dispatch(setAuthToken({ authToken: null }))
      });
    }
  }, [props.userStatus]);



  useEffect(() => {
    if (props.user.uid) {
      const unSub = handleUser(props.user.organization_id, props.user, dispatch);
      return unSub;
    }
  }, [
    props.user.uid
  ]);

  // useEffect(() => {
  //   const startTime = performance.now();
  //   const image = new Image();
  //   image.src = `https://i.imgur.com/rc8vtVX.jpg?time=${Date.now()}`;
  //   image.onload = () => {
  //     const endTime = performance.now();
  //     let timeTaken = endTime - startTime;
  //     if (timeTaken > 2000) {
  //       alert("Your network speed is slow, Please switch to a better connection for improved performance");
  //     }
  //   };
  // }, []);

  return (
    <div>
      {String(props.history.location.pathname).startsWith("/resetpassword") !==
        true &&
        props.userStatus && (
          <>
            {props.userStatus === true && (
              <>
                {String(props.history.location.pathname).startsWith(
                  "/drilldownData"
                ) !== true &&
                  String(props.history.location.pathname).startsWith(
                    "/taskDrilldownData"
                  ) !== true &&
                  String(props.history.location.pathname).startsWith(
                    "/callDrilldownData"
                  ) !== true && (
                    <>

                      {props.userRole === "superAdmin" && (
                        <Route component={SuperAdminRoute} />
                      )}
                      {props.userRole === "Operation Manager" && (
                        <Route component={OrganizationManagerRoute} />
                      )}
                      {props.userRole === "organization" && (
                        <Route component={OrganizationRoute} />
                      )}
                      {props.userRole !== "organization" &&
                        props.userRole !== "superAdmin" &&
                        props.userRole !== "Lead Manager" &&
                        props.userRole !== "Operation Manager" && (
                          <Route component={UserRoute} />
                        )}
                      {props.userRole === "Lead Manager" && (
                        <Route component={LeadManagerRoute} />
                      )}
                    </>
                  )}

                {String(props.history.location.pathname).startsWith(
                  "/drilldownData"
                ) === true && (
                    <Route path="/drilldownData" component={DrilldownDataRoute} />
                  )}
                {String(props.history.location.pathname).startsWith(
                  "/taskDrilldownData"
                ) === true && (
                    <Route
                      path="/taskDrilldownData"
                      component={DrilldownDataRoute}
                    />
                  )}
                {String(props.history.location.pathname).startsWith(
                  "/callDrilldownData"
                ) === true && (
                    <Route
                      path="/callDrilldownData"
                      component={DrilldownDataRoute}
                    />
                  )}
              </>
            )}
          </>
        )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
    user: state.user.data,
    // organizationCreateDate: state.orgCreateDate.data
  };
};

export default connect(mapStateToProps)(HomeRoute);
