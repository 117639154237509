import React, { FunctionComponent, useRef, useEffect } from "react";
import styles from "../../../Screens/AddProject/AddProject.module.css";
import Modal from "react-modal";
import { useState } from "react";
import Loading from "../../Loading/Loading";
import { IoIosClose } from "react-icons/io";
import TextInput from "../../TextInput/TextInput";
import Dropdown from "../../DropDown/Dropdown";
import { useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { editProjectDetails } from "../../../Services/resources";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import { fetchProjects, fetchpropertyStage, fetchpropertyType } from "../../../Services/contacts";
import { themeColors } from "../../theme";
import Label from "../../Label";

const propertyStatus=["Launched","Pre Launch","Intermediate Occupation"];
type props = { history: any; user: any, theme: any,projectHeader: any };
const ProjectEditModal: FunctionComponent<props> = ({ history, user, theme, projectHeader }) => {
  const location: any = useLocation();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>({
    label:"Select",
    value:"Select",
  });
  const [projectStatusSelected, setProjectStatusSelected] = useState<any>({
    value:"Select",
    label:"Select"
});
  const [projectsList, setProjectsList] = useState<any[] | undefined>(
    undefined
  );
  const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
  const projectNameRef: any = useRef();
  const developerNameRef: any = useRef();
  const addressRef: any = useRef();
  const reraRef: any = useRef();
  const walkthroughRef: any = useRef();
  const [propertyStages, setPropertyStages] = useState<string[]>([]);
  const [propertyStageSelected, setPropertyStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });

  // useEffect(() => {
  //   let data = ["Ready to move in", "Under Construction"];
  //   setPropertyStages(data);
  // }, []);

  useEffect(() => {
    // fetchNewPropertySubType
    const getPropertyState = fetchpropertyStage(
      user.organization_id,
      (data) => setPropertyStages(data)
    );
    return () => {
      getPropertyState();
    };
  }, []);

  useEffect(()=>{
    setPropertyTypeSelected({
      label: location.state.detail.property_type,
      value: location.state.detail.property_type,
    })
    setProjectStatusSelected({
      label: location.state.detail.project_status,
      value: location.state.detail.project_status,
    })
    setPropertyStageSelected(
      {
        label: location.state.detail.property_stage,
        value: location.state.detail.property_stage,
      }
    )
  },[propertyTypes,propertyStatus,propertyStages]);
  useEffect(() => {
    const unNewPropertyType = fetchpropertyType(
      user.organization_id,
      (data) => setPropertyTypes(data)
    );
    // let data = ["Commercial", "Residential"];
    // setPropertyTypes(data);
    if (user.organization_id) {
      const unsub = fetchProjects(
        (data) => setProjectsList(data),
        user.organization_id
      );
      return () => {
        unsub();
        unNewPropertyType();
      };
    }
  }, []);
  const handleCheck = () => {
    if (projectNameRef.current.value === "") {
      projectNameRef.current.value = location.state.detail.project_name;
    }
    if (developerNameRef.current.value === "") {
      developerNameRef.current.value = location.state.detail.developer_name;
    }
    if (addressRef.current.value === "") {
      addressRef.current.value = location.state.detail.address;
    }

    if (walkthroughRef.current.value === "") {
      walkthroughRef.current.value = location.state.detail.walkthrough_link;
      dispatcher(showSnackbarAction("Please Enter Walkthrough Link", "error"));
      return false;
    }
    if (propertyTypeSelected.label === "Select") {
      propertyTypeSelected.label = location.state.detail.property_type;
    }
    if (projectStatusSelected.label === "Select") {
      projectStatusSelected.label = location.state.detail.project_status;
    }
    if (propertyStageSelected.label === "Select") {
      propertyStageSelected.label = location.state.detail.property_stage;
    }

    return true;
  };
  const edit = (e: any) => {
    e.preventDefault();
    const val = handleCheck();
    if (val && projectsList) {
      setLoad(true);
      editProjectDetails(
        user.organization_id,
        projectsList,
        location.state.detail.project_name,
        projectNameRef.current.value,
        developerNameRef.current.value,
        addressRef.current.value,
        propertyTypeSelected.label,
        projectStatusSelected.label,
        propertyStageSelected.label,
        reraRef.current.value,
        walkthroughRef.current.value,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <div className={styles.parent} style={{...containerStyles,marginTop:"4%"}}>
      {load === true && <Loading />}
      <div
        // className={styles.headerView}
        className="d-flex flex-row w-100 px-4 pt-4"
      >
        <h5
        // className={styles.heading}
        > Edit Project Details</h5>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/projects")}
        />
      </div>
      <div
        // className={styles.child}
        className="w-100 d-flex flex-column gap-2 px-4"
      >
        <form className="card pt-1 pb-4 px-4 shadow rounded mt-2 pt-4" style={cardContainerStyles}>
          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Developer Name</p>
              <p className={styles.two}></p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "developer_name").value} required={false} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={developerNameRef}
                  value={location.state.detail.developer_name}
                  // disabled={location.state.detail.project_status==="Not Launch"?false:true}
                ></TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Project Name</p>
              <p className={styles.two}></p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "project_name").value} required={false} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={projectNameRef}
                  value={location.state.detail.project_name}
                  // disabled={location.state.detail.project_status==="Not Launch"?false:true}
                ></TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={projectHeader.find((obj:any) => obj.label === "property_type").value} required={false} />
              <div style={{ width: "140px", marginRight: "5px" }}>
                <Dropdown
                  option={propertyTypes}
                  selectedValue={propertyTypeSelected}
                  setSelectedValue={(value) => setPropertyTypeSelected(value)}
                />
              </div>
            </div>
          </div>
          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Address</p>
              <p className={styles.two}></p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "address").value} required={false} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={addressRef}
                  // disabled={location.state.detail.project_status==="Not Launch"?false:true}
                  value={location.state.detail.address}
                />
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Rera Link</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "rera_link").value} required={false} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={reraRef}
                  value={location.state.detail.rera_link}
                  // disabled={location.state.detail.project_status==="Not Launch" || location.state.detail.project_status==="INTERMEDIATE"?false:true}
                />
              </div>
            </div>

            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Walkthrough Link</p>
              <p className={styles.two}></p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "walkthrough_link").value} required={false} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={walkthroughRef}
                  value={location.state.detail.walkthrough_link}
                />
              </div>
            </div>
          </div>
          <div className={styles.box2}>
            {/* <div className={styles.divide}> */}
              {/* <div className={styles.title}>
              <p className={styles.one}>Property Type</p>
            </div> */}
              {/* <Label label="Property Type" required={false} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={propertyTypeSelected}
                  disabled={location.state.detail.project_status==="Not Launch"?false:true}
                  value={location.state.detail.property_type}
                />
              </div>
            </div> */}
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Property Stage</p>
              <p className={styles.two}>*</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "property_stage").value} required={true} />
              <div style={{ width: "140px", marginRight: "5px" }}>
                <Dropdown
                  option={propertyStages}
                  selectedValue={propertyStageSelected}
                  setSelectedValue={(value) => setPropertyStageSelected(value)}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={projectHeader.find((obj:any) => obj.label === "project_status").value} required={true} />
              <div style={{ width: "140px", marginRight: "5px" }}>
                <Dropdown
                  option={propertyStatus}
                  selectedValue={projectStatusSelected}
                  setSelectedValue={(value) => setProjectStatusSelected(value)}
                />
              </div>
            </div>
          </div>
          <button className={styles.button} onClick={(e: any) => edit(e)}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    projectHeader:state.projectHeaderList.data,
  };
};

export default connect(mapStateToProps)(ProjectEditModal);
