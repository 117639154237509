import React, { FunctionComponent, useState, useEffect } from "react";
import styles from "../../CommonStyles/CommonGraphStyles.module.css";
import { Doughnut } from "react-chartjs-2";
import { connect, useDispatch } from "react-redux";
import { getDrillDownChartOrg } from "../../Services/analytics";
import { setClearFilter } from "../../Redux/actions";
import { changeDateForRoute } from "../../Services/contacts";
import { textAlign } from "@mui/system";
import Chart from "react-apexcharts";
import {themeColors} from "../../Components/theme";

type props = {
  analyticsData: any;
  type: "not_int_reason" | "lost_reason"|"call_back_reason";
  color: string[];
  style?: any;
  role: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  contacts: any;
  user: any;
  organizationUsers: any[];
  teamLeadUsers: any;
  branchUsers: any;
  theme:any;
};

const DoughnutChartOrg: FunctionComponent<props> = ({
  analyticsData,
  type,
  color,
  style,
  role,
  history,
  filter,
  contacts,
  user,
  organizationUsers,
  teamLeadUsers,
  branchUsers,
  theme
}) => {
  const [data, setData] = useState<any>({});
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);
  const [labels, setLabels] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  useEffect(() => {
    const temp: { [key: string]: number } = {};
    Object.values(analyticsData).forEach((analytics: any) => {
      Object.keys(analytics.leadAnalytics[type]).forEach((key) => {
        if (temp[key]) {
          temp[key] += analytics.leadAnalytics[type][key];
        } else {
          temp[key] = analytics.leadAnalytics[type][key];
        }
      });
    });
    setData(temp);
  }, [analyticsData]);

  useEffect(() => {
    const lablesArray:any = [];
    let labels = Object.keys(data);
    let series = Object.values(data);
    labels.forEach((key)=>{
      if(key === "Invalid Number"){
        lablesArray.push("Number Invalid");
      }else{
        lablesArray.push(key);
      }
    })
    setLabels(lablesArray);
    setSeries(series);
  }, [data]);

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: `Interested ${type}s`,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "#00008b",
          "#2FA2D3",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "#00008b",
          "#2FA2D3",
        ],
        barThickness: 40,
        borderWidth: 2,
        hoverOffset: 12,
        data: Object.values(data),
      },
    ],
  };
  const dispatcher = useDispatch();

  const barChartOptions = {
    series: [
      {
        name: "Total",
        data: series,
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false
        }
      },
      colors: [
        "#71CACC",
        "#87CBAC",
        "#3B6064",
        "#F88377",
        "#272944",
        "#aaf0d1",
        "#ff6961",
        "#93FCF8",
        "#00C9D2",
      ],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            position: 'top'
          }
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: theme ? ["#000"]:["#fff"],
      },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: labels,
        // labels: {
        //   show: false,
        // },
        labels: {
          show: true,
          maxHeight: 100,
          trim:true,
          style: {
            colors: theme ? "#000":"#fff",
        },
        },
      },
      yaxis: {
        categories: labels,
        // labels: {
        //   show: false,
        // },
        labels: {
          show: true,
          style: {
            colors: theme ? "#000":"#fff",
        },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      }
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands"
      //     }
      //   }
      // }
    },
  };

  const pieChartOptions:any = {
    series: series,
    options: {
      chart: {
        toolbar: {
          show: false
        }
      },
      labels: labels,
      dataLabels: {
        enabled: true,
        formatter: function (val:any) {
          return val.toFixed(0) + "%"
        },
      },
      colors: [
        "#71CACC",
        "#87CBAC",
        "#3B6064",
        "#F88377",
        "#272944",
        "#aaf0d1",
        "#ff6961",
        "#93FCF8",
        "#00C9D2",
      ],
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center', 
        floating: false,
        fontSize: '12px',
        labels: {
          colors: theme ? "#000":"#fff",
        }
      },
    },
  };

  const donutChartOptions:any = {
    series: series,
    options: {
      chart: {
        animations: {
          enabled: false,
          // easing: 'easeinout',
          // speed: 800,
          // animateGradually: {
          //     enabled: true,
          //     delay: 150
          // },
          // dynamicAnimation: {
          //     enabled: true,
          //     speed: 350
          // }
      },
        background: theme ? 'rgba(0, 0, 0, 0)' : 'rgba(26, 43, 60, 1)',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            // reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              // dateFormatter(timestamp) {
              //   return new Date(timestamp).toDateString()
              // }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val:any) {
          return val.toFixed(0) + "%"
        },
      },
      labels: labels,
      colors: [
        "#71CACC",
        "#87CBAC",
        "#3B6064",
        "#F88377",
        "#272944",
        "#aaf0d1",
        "#ff6961",
        "#93FCF8",
        "#00C9D2",
      ],
      legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center', 
      floating: false,
      fontSize: '12px',
      labels: {
        colors: theme ? "#000":"#fff",
      }
      },
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            size: "60%",
          },
          offsetY: 20,
        },
        stroke: {
          colors: undefined,
        },
      }
    },
  };



  return (
    // <div className={styles.graphBox} style={style}>
    //   <Doughnut
    //     data={chartData}
    //     options={{
    //       // title: {
    //       //   display: false,
    //       //   fontSize: 20,
    //       // },
          
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       plugins: {
    //         legend: {
    //           display: true,
    //           position: "bottom",
    //           align:"start",
    //         },
    //         datalabels: {
    //           formatter: (value: any, ctx: any) => {
    //             let sum = 0;
    //             let dataArr = ctx.chart.data.datasets[0].data;
    //             dataArr.map((data: any) => {
    //               sum += data;
    //             });
    //             let percentage = Math.floor((value * 100) / sum) + "%";
    //             return percentage;
    //           },
    //           color: "#fff",
    //         },
    //       },
    //     }}
    //   />
    // </div>
    <>
    {type === "lost_reason" || type === "call_back_reason"? (
      <Chart
        options={donutChartOptions.options}
        series={donutChartOptions.series}
        type="donut"
        width={"100%"}
        height={280}
      />
    ) : (
      <Chart
        options={donutChartOptions.options}
        series={donutChartOptions.series}
        type="donut"
        width={"100%"}
        height={280}
      />
    )}
  </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    role: state.user.role,
    contacts: state.contacts,
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    branchUsers: state.branchUsers.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(DoughnutChartOrg);
