import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import commonStyle from "../../Screens/common.module.css";
import {
  useTable,
  useSortBy,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { getFilterObject } from "../../Values/utils";
import { connect, useDispatch } from "react-redux";
import TableHeader from "../TableHeader/TableHeader";
import CustomToggle from "../CustomToggle";
import {
  updateUserImport,
  updateUserStatus,
} from "../../Services/users";

import {
  fetchApi,
  updateAPIStatus,
  updateOrganizationStatus,
  updateOrganizationAnalyticsData,
  updateOrganizationData,
} from "../../Services/organizations";
import ColumnManagerModal from "../Modals/ColumnManagerModal/ColumnManager";
import {
  setClearFilter,
  showSnackbarAction,
} from "../../Redux/actions";
import { useHistory } from "react-router";
import { FiEdit3 } from "react-icons/fi";
import Loading from "../Loading/Loading";

import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import AlertDialog from "../PopupDialogBox/Popup";
import DistributionModel from "../Modals/DistributionModel/DistributionModel";
import { MdDelete } from "react-icons/md";
import { themeColors } from "../../Components/theme";
import FilterListIcon from '@mui/icons-material/FilterList';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { auth } from "../../Firebase";
import { FiTrash2 } from 'react-icons/fi';


type props = {
  tableColumns: any[];
  tableRows: any[] | undefined;
  selectedRows: any[];
  setSelectedRows: (data: any[]) => void;
  tableType:
  | "Organization"
  | "Super admin"
  | "Dynamic header"
  | "User"
  | "Task"
  | "CallLogs"
  | "API"
  | "NEWS"
  | "LeadDistributor"
  | "DrillDown"
  | "Custom Button"
  | "All User List"
  | "NEWS VIEW";
  showColumnModal: boolean;
  hideColumnModal: () => void;
  selectedRowsData?: any[];
  setSelectedRowsData?: (data: any[]) => void;
  setSearchedItem?: (text: any) => void;
  organization: any;
  organizationUsers: any[];
  role: any;
  setBranch?: (text: boolean) => void;
  setUid?: (data: string) => void;
  user: any;
  setApiModal?: (data: boolean) => void;
  setApiEdit?: (data: boolean) => void;
  setApiData?: (data: any) => void;
  setPageNumber?: (data: any) => void;
  setPageCount?: (data: any) => void;
  theme: any;
  setToggleCondition?: any;
  handleDeleteOrgUser?: (data: any) => void;
  handleChangePassword?: (data: any) => void;
  handleDeleteLeadDistributor?: (data: any,index:any) => void;
};

const CustomTable: FunctionComponent<props> = ({
  tableColumns,
  tableRows,
  selectedRows,
  setSelectedRows,
  tableType,
  showColumnModal,
  hideColumnModal,
  selectedRowsData,
  setSelectedRowsData,
  setSearchedItem,
  organization,
  organizationUsers,
  role,
  setBranch,
  setUid,
  user,
  setApiModal,
  setApiEdit,
  setApiData,
  setPageNumber,
  setPageCount,
  theme,
  setToggleCondition,
  handleDeleteOrgUser,
  handleChangePassword,
  handleDeleteLeadDistributor
}) => {
  const dispatcher = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [showDistributionModal, setShowDistributionModal] =
    useState(false);
  const [load, setLoad] = useState(false);
  const [distributionData, setDistributionData] =
    useState();
  const [indexs, setIndex] = useState(0);

  const selectAll = (checked: boolean) => {
    if (
      tableType === "User" ||
      tableType === "Organization" ||
      tableType === "CallLogs" ||
      tableType === "Task" ||
      tableType === "API" ||
      tableType === "NEWS"
    ) {

      if (checked === true) {
        if (page) {

          let selectRowsIndex: any[] = [...selectedRows];

          Array.from(Array(page?.length).keys()).forEach(
            (index) => {
              selectRowsIndex.push(
                pageSize * pageIndex + index + 1
              );
            }
          );
          setSelectedRows(selectRowsIndex);
        }

        if (page) {
          if (selectedRowsData) {
            let selectRows: any[] = [...selectedRowsData];
            page.forEach((item: any) => {
              selectRows.push(item.original);
            });
            setSelectedRowsData &&
              setSelectedRowsData(selectRows);
          } else {
            let selectRows: any[] = [];
            page.forEach((item: any) => {
              selectRows.push(item.original);
            });
            setSelectedRowsData &&
              setSelectedRowsData(selectRows);
          }
        }
      } else {
        setSelectedRows([]);
        setSelectedRowsData && setSelectedRowsData([]);
      }
    } else {
      if (checked === true) {
        setSelectedRows(
          Array.from(Array(page?.length).keys())
        );
      } else {
        setSelectedRows([]);
      }
    }
  };

  const columns = useMemo(
    () => tableColumns,
    [tableColumns]
  );
  const data = useMemo(
    () => (tableRows ? tableRows : []),
    [tableRows]
  );
  const [currentLimit, setCurrentLimit] = useState(20);

  const [recordCounts, setRecordsCount] = useState<any[]>(
    []
  );
  const [CurrLeader, setCurrLeader] = useState(-1);
  const [active, setActiveUsers] = useState(0);
  const tableInstance: any = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    useColumnOrder,
    usePagination
  );

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    allColumns,

    //@ts-ignore
    setColumnOrder,
    //@ts-ignore

    toggleHideAllColumns,
  } = tableInstance;

  const { pageIndex, pageSize } = state;
  useEffect(() => {
    setPageSize(100);
    const savedColumns = localStorage.getItem("columns");
    if (savedColumns) {
      setColumnOrder(JSON.parse(savedColumns));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (organizationUsers) {
      let activeUsers = organizationUsers.filter(
        (item) => item.status === "ACTIVE"
      );
      setActiveUsers(activeUsers.length);
    }
  }, [organizationUsers]);

  useEffect(() => {
    let l = [10, 25, 50, 100, 200, 500];
    l.push(rows.length);
    setRecordsCount(l);
  }, [rows]);

  const setColumns = (columnList: any) => {
    setColumnOrder(columnList);
    localStorage.setItem(
      "columns",
      JSON.stringify(columnList)
    );
  };
  useEffect(() => {
    if (tableRows) {
      getFilterObject(tableRows, dispatcher);
    }
  }, [tableRows, dispatcher]);

  const checkStatus = (email: string) => {
    const l = data.filter(
      (item) => item.reporting_to === email
    );
    if (l.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onToggle = (event: any, row: any) => {
    if (tableType === "Organization") {
      setLoad(true);
      if (!event.target.checked) {
        let check = checkStatus(row.original.user_email);
        if (check === true) {
          updateUserStatus(
            row.original.uid,
            "INACTIVE",
            (data) => setLoad(data),
            dispatcher
          );
        } else {
          dispatcher(
            showSnackbarAction(
              "Some Users Are Reporting To This User!!",
              "warning"
            )
          );
          setLoad(false);
        }
      } else {
        let activeUsers = organizationUsers.filter(
          (item) => item.status === "ACTIVE"
        );

        if (organization.trial_period === true) {
          if (
            activeUsers.length ===
            Number(organization.org_trial_period_users_licenses)
          ) {
            dispatcher(
              showSnackbarAction(
                "Maximum active users limit reached in Trail Period, Please Renew to activate more users!!",
                "warning"
              )
            );
            setLoad(false);
          }
          else {
            updateUserStatus(
              row.original.uid,
              "ACTIVE",
              (data) => setLoad(data),
              dispatcher
            );
          }
        }
        else {
          if (
            activeUsers.length ===
            Number(organization.no_of_employees)
          ) {
            dispatcher(
              showSnackbarAction(
                "Maximum active users limit reached!!",
                "warning"
              )
            );
            setLoad(false);
          }
          else {
            updateUserStatus(
              row.original.uid,
              "ACTIVE",
              (data) => setLoad(data),
              dispatcher
            );
          }
        }
        // if (
        //   activeUsers.length ===
        //   Number(organization.no_of_employees)
        // ) {
        //   dispatcher(
        //     showSnackbarAction(
        //       "Maximum active users limit reached!!",
        //       "warning"
        //     )
        //   );
        //   setLoad(false);
        // }
        // else {
        //   updateUserStatus(
        //     row.original.uid,
        //     "ACTIVE",
        //     (data) => setLoad(data),
        //     dispatcher
        //   );
        // }
      }
    }
    if (tableType === "Super admin") {
      setLoad(true);
      if (!event.target.checked) {
        updateOrganizationStatus(
          row.original.organization_id,
          "INACTIVE",
          (data) => setLoad(data)
        );
      } else {
        updateOrganizationStatus(
          row.original.organization_id,
          "ACTIVE",
          (data) => setLoad(data)
        );
      }
    }
    if (tableType === "API") {
      setLoad(true);
      if (!event.target.checked) {
        updateAPIStatus(
          row.original.api_key,
          "INACTIVE",
          (data) => setLoad(data)
        );
      } else {
        updateAPIStatus(
          row.original.api_key,
          "ACTIVE",
          (data) => setLoad(data)
        );
      }
    }
  };

  const handleViewNews = (data: any) => {
    if (data?.link) {
      window.open(data.link, '_blank');
    } else {
      console.log("No link found in data");
    }
  }

  const changePassord = async (rowData: any) => {
    if (rowData.admin_email_id !== "") {
      try {
        if (rowData.admin_email_id) {
          setLoad(true);
          await auth.sendPasswordResetEmail(rowData.admin_email_id);
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password reset information have been sent on you registered email id",
              "success"
            )
          );
          // props.history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  };

  const onToggleUserList = (event: any, row: any) => {
    if (tableType === "All User List") {
      setLoad(true);
      if (!event.target.checked) {
        let check = checkStatus(row.original.user_email);
        if (check === true) {
          updateUserStatus(
            row.original.uid,
            "INACTIVE",
            (data) => setLoad(data),
            dispatcher
          );
        } else {
          dispatcher(
            showSnackbarAction(
              "Some Users Are Reporting To This User!!",
              "warning"
            )
          );
          setLoad(false);
        }
      } else {
        let activeUsers = organizationUsers.filter(
          (item) => item.status === "ACTIVE"
        );

        if (organization.trial_period === true) {
          if (
            activeUsers.length ===
            Number(organization.org_trial_period_users_licenses)
          ) {
            dispatcher(
              showSnackbarAction(
                "Maximum active users limit reached in Trail Period, Please Renew to activate more users!!",
                "warning"
              )
            );
            setLoad(false);
          }
          else {
            updateUserStatus(
              row.original.uid,
              "ACTIVE",
              (data) => setLoad(data),
              dispatcher
            );
          }
        }
        else {
          if (
            activeUsers.length ===
            Number(organization.no_of_employees)
          ) {
            dispatcher(
              showSnackbarAction(
                "Maximum active users limit reached!!",
                "warning"
              )
            );
            setLoad(false);
          }
          else {
            updateUserStatus(
              row.original.uid,
              "ACTIVE",
              (data) => setLoad(data),
              dispatcher
            );
          }
        }
      }
    }
  };

  const onToggleAnalytics = (event: any, row: any) => {
    setLoad(true);
    if (!event.target.checked) {
      updateOrganizationAnalyticsData(
        row.original.organization_id,
        { showAnalytics: false },
        (data) => setLoad(data)
      );
    } else {
      updateOrganizationAnalyticsData(
        row.original.organization_id,
        { showAnalytics: true },
        (data) => setLoad(data)
      );
    }
  };

  const onToggleShowData = (event: any, row: any) => {
    setLoad(true);
    if (!event.target.checked) {
      updateOrganizationData(
        row.original.organization_id,
        { allowDuplicateLeads: false },
        (data) => setLoad(data)
      );
    } else {
      updateOrganizationData(
        row.original.organization_id,
        { allowDuplicateLeads: true },
        (data) => setLoad(data)
      );
    }
  };

  const changeImport = (event: any, row: any) => {
    setLoad(true);
    if (event.target.checked) {
      updateUserImport(
        row.original.organization_id,
        row.original.user_email,
        true,
        dispatcher,
        (data) => setLoad(data)
      );
    } else {
      updateUserImport(
        row.original.organization_id,
        row.original.user_email,
        false,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const onCheck = (event: any, row: any, index: number) => {
    if (
      tableType === "User" ||
      tableType === "Organization" ||
      tableType === "Task" ||
      tableType === "API" ||
      tableType === "NEWS"
    ) {
      if (event.target.checked) {
        let data = [...selectedRows];

        data.push(index);
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          rowData.push(row);
          setSelectedRowsData &&
            setSelectedRowsData(rowData);
        }
      } else if (!event.target.checked) {
        let data = [...selectedRows];
        let item = selectedRows.indexOf(index);
        if (item > -1) {
          data.splice(item, 1);
        }
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          let val = selectedRowsData.indexOf(row);
          if (val > -1) {
            rowData.splice(val, 1);
          }
          setSelectedRowsData &&
            setSelectedRowsData(rowData);
        }
      }
    }

    if (event.target.checked) {
      let data = [...selectedRows];
      data.push(index);
      setSelectedRows(data);
    } else if (!event.target.checked) {
      let data = [...selectedRows];
      let item = selectedRows.indexOf(index);
      if (item > -1) {
        data.splice(item, 1);
      }
      setSelectedRows(data);
    }
  };

  const clearFilter = () => {
    setSearchedItem && setSearchedItem("");
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== "columns" && key !== 'isLightMode' && key !== 'totalMonths' && key !== 'lastAlertTime') {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    if (tableType === "User") {
      history.push("/");
    }
  };
  const location: any = useLocation();

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,

  }

  const footerStyles = {
    backgroundColor: theme ? themeColors.thBackgroundColor : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }

  const buttonStyles = {
    backgroundColor: theme ? themeColors.buttonPrimary : themeColors.buttonPrimaryDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }
  const trBorderStyles = {
    borderBottom: theme ? `1px solid ${themeColors.trBorderColor}` : `1px solid ${themeColors.trBorderColorDark}`,
  }


  return (
    <>
      <div className={commonStyle.child} id="scrollable">
        {load === true && <Loading />}
        <div style={{ width: "max-content" }}>
          <TableHeader
            headerGroups={headerGroups}
            selectAll={(data) => {
              selectAll(data);
              setChecked(data);
            }}
            panel={tableType}
            allCheck={checked}
          />
          <InfiniteScroll
            style={{ overflow: "visible", minHeight: "100vh", width: "100%", marginBottom: "-10rem", ...tdStyles }}
            dataLength={currentLimit}
            next={() => setCurrentLimit(currentLimit + 20)}
            hasMore={rows.length > currentLimit}
            loader={""}
            scrollableTarget="scrollable"
            className={commonStyle.infiniteScroll}
          >
            {tableRows && (
              <div {...getTableBodyProps()}>
                {page
                  .slice(0, currentLimit)
                  .map((row: any, index: number) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        style={
                          row.original.status === "INACTIVE"
                            ? {
                              // backgroundColor: "#D3D3D3",
                              // borderWidth: 2,
                              display: "flex",
                              flexDirection: "row",
                              ...trBorderStyles
                            }
                            : {
                              // backgroundColor: "#ffffff",
                              display: "flex",
                              flexDirection: "row",
                              ...trBorderStyles
                            }
                        }
                      >
                        <div className={commonStyle.sticky} style={tdStyles}>
                          {tableType === "Super admin" || tableType === "All User List" || tableType === "NEWS VIEW" ? <>
                            {/* <FiTrash2
                              size={15}
                              color="red"
                              style={{ marginRight: '7px', cursor: "pointer" }}
                              onClick={() => handleDeleteOrgUser && handleDeleteOrgUser(row.original)}
                            /> */}
                          </> : <>
                            <input
                              style={{ marginRight: "10px", accentColor: "#272944" }}
                              type="checkbox"
                              disabled={row.original?.createNewsProfile==="Super Admin" && role==="organization" ? true: false}
                              onChange={(e) => {
                                if (
                                  row.original
                                    .transfer_status === true
                                ) {
                                  dispatcher(
                                    showSnackbarAction(
                                      "This Lead Cannot be Transfered",
                                      "error"
                                    )
                                  );
                                } else {
                                  onCheck(
                                    e,
                                    row.original,
                                    pageSize * pageIndex +
                                    index +
                                    1
                                  );
                                }
                              }}
                              checked={selectedRows.includes(
                                pageSize * pageIndex +
                                index +
                                1
                              )}
                            />
                          </>}
                          {(tableType !== "Task" && tableType !== "NEWS VIEW" && tableType !=="NEWS") && (
                            <FiEdit3
                              size={15}
                              color="#272944"
                              style={{ marginRight: '7px' }}
                              onClick={() => {
                                if (
                                  tableType ===
                                  "Organization" || tableType === "All User List"
                                ) {
                                  history.push({
                                    pathname: "/addUsers",
                                    state: {
                                      detail: row.original,
                                      edit: true,
                                    },
                                  });
                                }
                                else if (
                                  tableType === "User"
                                ) {
                                  if (
                                    row.original
                                      .transfer_status ===
                                    true
                                  ) {
                                    dispatcher(
                                      showSnackbarAction(
                                        "This Lead Cannot be Edited",
                                        "error"
                                      )
                                    );
                                  } else {
                                    history.push({
                                      pathname:
                                        "/editContacts",
                                      state: {
                                        detail:
                                          row.original,
                                      },
                                    });
                                  }
                                }
                                else if (
                                  tableType ===
                                  "Super admin"
                                ) {
                                  history.push({
                                    pathname:
                                      "/editOrganizations",
                                    state: {
                                      detail: row.original,
                                    },
                                  });
                                }
                                else if (
                                  tableType ===
                                  "Dynamic header"
                                ) {
                                  history.push({
                                    pathname:
                                      "/editHeaders",
                                    state: {
                                      detail: row.original,
                                    },
                                  });
                                } else if (
                                  tableType ===
                                  "LeadDistributor"
                                ) {
                                  setShowDistributionModal(
                                    true
                                  );
                                  setDistributionData(
                                    row.original
                                  );
                                  setIndex(index);
                                } else if (
                                  tableType === "API"
                                ) {
                                  setApiEdit &&
                                    setApiEdit(true);
                                  setApiModal &&
                                    setApiModal(true);
                                  setApiData &&
                                    setApiData(
                                      row.original
                                    );
                                }
                              }}
                            />
                          )}
                          {showDistributionModal && (
                            history.push({
                              pathname: "/editDistributorLogic",
                              state: {
                                data: distributionData, // Passing the data
                                index: indexs, // Passing the index
                              },
                            })
                          
                          )} 
                          {pageSize * pageIndex + index + 1}
                        </div>

                        {/*  render table data all at a time */}
                        {row.cells.map((cell: any) => {
                          return (
                            <div
                              className={commonStyle.td}
                              {...cell.getCellProps()}
                              style={tableType === "LeadDistributor" ? { minWidth: "210px", maxWidth: "210px", ...tdStyles } : { ...tdStyles }}
                              // style={
                              // cell.column.Header ===
                              //   "News Link"
                              //   ? { minWidth: "490px" }
                              //   : {}
                              // }
                              onClick={() => {
                                if (
                                  cell.column.Header ===
                                  "Customer Name" &&
                                  tableType === "User"
                                ) {
                                  history.push({
                                    pathname:
                                      "/contactDetails",
                                    state: {
                                      detail: {
                                        ...cell.row
                                          .original,
                                        created_at:
                                          cell.row.original[
                                            "created_at"
                                          ].toDate(),
                                        lead_assign_time:
                                          cell.row.original[
                                            "lead_assign_time"
                                          ].toDate(),
                                        next_follow_up_date_time:
                                          cell.row.original[
                                            "next_follow_up_date_time"
                                          ]
                                            ? cell.row.original[
                                              "next_follow_up_date_time"
                                            ].toDate()
                                            : "",
                                        modified_at: cell
                                          .row.original[
                                          "modified_at"
                                        ]
                                          ? cell.row.original[
                                            "modified_at"
                                          ].toDate()
                                          : "",
                                        stage_change_at:
                                          cell.row.original[
                                            "stage_change_at"
                                          ]
                                            ? cell.row.original[
                                              "stage_change_at"
                                            ].toDate()
                                            : "",
                                      },
                                    },
                                  });
                                }
                              }}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}

                        {tableType === "Super admin" && (
                          <div
                            className={commonStyle.td}
                            style={{ ...tdStyles, marginLeft: "2%" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "10px",
                                justifyContent:
                                  "space-between",
                              }}
                            >
                              <CustomToggle
                                size="small"
                                onChange={(e) => {
                                  // console.log("chitrabbbb",e,row)
                                  onToggle(e, row)
                                }}
                                checked={
                                  row.original.status ===
                                    "ACTIVE"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        )}
                        {tableType === "Super admin" && (
                          <div
                            className={commonStyle.td}
                            style={{ ...tdStyles, marginLeft: "2%" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <CustomToggle
                                size="small"
                                onChange={(e) => {
                                  onToggleShowData(e, row)
                                }}
                                checked={
                                  row.original?.allowDuplicateLeads ===
                                    true
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        )}
                        {tableType === "Super admin" && (
                          <div
                            className={commonStyle.td}
                            style={{ ...tdStyles, marginLeft: "2%" }}

                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "10px",
                                justifyContent:
                                  "space-between",
                              }}
                            >
                              <CustomToggle
                                size="small"
                                onChange={(e) => {
                                  onToggleAnalytics(e, row)
                                }
                                }
                                checked={
                                  row.original.showAnalytics ===
                                    true
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        )}
                        {tableType === "Super admin" ? (
                          <div
                            style={{ paddingLeft: "30px", ...tdStyles }}
                          >
                            <button
                              className={
                                commonStyle.branchButton
                              }
                              onClick={() => {
                                handleChangePassword && handleChangePassword(row.original);
                              }}
                            >
                              Change Password
                            </button>
                          </div>
                        ) : null}

                        {tableType === "Super admin" ? (
                          <div
                            style={{ paddingLeft: "70px", ...tdStyles }}
                          >
                            <button
                              className={
                                commonStyle.branchButton
                              }
                              style={{ backgroundColor: "red" }}
                              onClick={() => handleDeleteOrgUser && handleDeleteOrgUser(row.original)}
                            >
                              Delete Organization
                            </button>
                          </div>
                        ) : null}

                        {tableType === "CallLogs" && (
                          <div
                            className={commonStyle.td}
                            style={tdStyles}
                          >
                            <p></p>
                          </div>
                        )}

                        {tableType === "All User List" && (
                          <div
                            className={commonStyle.td}
                            style={tdStyles}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "10px",
                                justifyContent:
                                  "space-between",
                              }}
                            >
                              {(tableType === "All User List") && (
                                <CustomToggle
                                  size="small"
                                  onChange={(e) =>
                                    onToggleUserList(e, row)
                                  }
                                  checked={
                                    row.original.status ===
                                      "ACTIVE"
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {tableType === "NEWS VIEW" ? (
                          <div
                            style={{ paddingLeft: "50px", marginTop: "6px", ...tdStyles }}
                          >
                            <button
                              className={
                                commonStyle.branchButton
                              }
                              onClick={() => {
                                handleViewNews && handleViewNews(row.original);
                              }}
                            >
                              View News
                            </button>
                          </div>
                        ) : null}

                        {tableType === "All User List" ? (
                          <div
                            style={{ paddingLeft: "95px", marginLeft: "10px", ...tdStyles }}
                          >
                            <button
                              className={
                                commonStyle.branchButton
                              }
                              onClick={() => {
                                handleChangePassword && handleChangePassword(row.original);
                              }}
                            >
                              Change Password
                            </button>
                          </div>
                        ) : null}


                        {tableType === "All User List" ? (
                          <div
                            style={{ paddingLeft: "70px", ...tdStyles }}
                          >
                            <button
                              className={
                                commonStyle.branchButton
                              }
                              style={{ backgroundColor: "red" }}
                              onClick={() => handleDeleteOrgUser && handleDeleteOrgUser(row.original)}
                            >
                              Delete User
                            </button>
                          </div>
                        ) : null}
                        {tableType === "Organization" && (
                          <div
                            className={commonStyle.td}
                            style={tdStyles}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "10px",
                                justifyContent:
                                  "space-between",
                              }}
                            >
                              {(tableType ===
                                "Organization" ||
                                tableType ===
                                "Super admin" ||
                                tableType === "API") && (
                                  <CustomToggle
                                    size="small"
                                    // onChange={(e) => {
                                    //   // console.log("chitrabbbb",e,row)
                                    //   onToggle(e, row)
                                    //   console.log("active user", e, row)
                                    // }}
                                    onChange={(e) =>
                                      onToggle(e, row)
                                    }
                                    checked={
                                      row.original.status ===
                                        "ACTIVE"
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                            </div>
                          </div>
                        )}
                        {/* {tableType === "Organization" ? (
                          <div
                            className={commonStyle.tdSmall}
                            style={{alignItems:"flex-end",justifyContent:"flex-end", minWidth:"150px",...tdStyles}}
                          >
                            {row.original.profile ===
                              "Lead Manager" ? (
                              <input
                                style={{ marginRight: "10px", accentColor: "#272944" }}
                                type="checkbox"
                                onChange={(e) =>
                                  changeImport(e, row)
                                }
                                checked={
                                  row.original.import ===
                                    true
                                    ? true
                                    : false
                                }
                              />
                            ) : null}
                          </div>
                        ) : null} */}

                        {tableType === "Organization" ? (
                          <div
                            // className={{}}
                            style={{ paddingLeft: "95px", marginLeft: "10px", ...tdStyles }}
                          >
                            {row.original.profile ===
                              "Lead Manager" ? (
                              <button
                                className={
                                  commonStyle.branchButton
                                }
                                onClick={() => {
                                  setBranch &&
                                    setBranch(true);
                                  setUid &&
                                    setUid(
                                      row.original.uid
                                    );
                                }}
                              >
                                Branch
                              </button>
                            ) : null}
                          </div>
                        ) : null}
                        {tableType === "LeadDistributor" ? (
                          <div
                            style={{ paddingLeft: "50px", ...tdStyles,marginTop:"5px"}}
                          >
                            <button
                              className={
                                commonStyle.branchButton
                              }
                              style={{ backgroundColor: "red" }}
                              onClick={() => handleDeleteLeadDistributor && handleDeleteLeadDistributor(row.original,index)}
                            >
                              Delete
                            </button>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: "center", borderRadius: " 0.5rem", padding: "0.5rem", marginTop: "1rem", marginBottom: "-1rem", ...footerStyles }}>

        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "start", flexDirection: "row", width: "28%" }}>
            <div>
              <button
                className={commonStyle.clearFilterEvent}
                // style={
                //   location.state?.detail ||
                //     localStorage.length > 0
                //     ? { color: "#fff", borderColor: "#fff" }
                //     : { color: "#555", borderColor: "#555" }
                // }
                onClick={() => {
                  clearFilter();
                  setChecked(false);
                  selectAll(false);
                }}
              >
                <FilterListIcon style={{ height: 15, width: 15, marginRight: '10px' }} />
                Clear Filter
              </button>
            </div>
          </div>
          <div>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to Page:{" "}
              <input
                type="number"
                className={commonStyle.nSelecct}
                defaultValue={pageIndex}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value)
                    : 0;
                  gotoPage(pageNumber - 1);
                  setPageNumber && setPageNumber(pageNumber);
                }}
                style={{ width: "50px" }}
              />
            </span>
            <select
              value={pageSize}
              className={commonStyle.dropDownSelecct}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageCount &&
                  setPageCount(Number(e.target.value));
              }}
            >
              {recordCounts.map((pagesize) => (
                <option key={pagesize} value={pagesize}>
                  Show {pagesize}
                </option>
              ))}
            </select>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                gotoPage(0);
                setChecked(false);
                setPageNumber && setPageNumber(1);
              }}
              disabled={!canPreviousPage}
              style={!canPreviousPage ? { backgroundColor: "#60606080" } : {}}
            >
              {"<<"}
            </button>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                previousPage();
                setChecked(false);
                setPageNumber && setPageNumber(pageIndex);
              }}
              style={!canPreviousPage ? { backgroundColor: "#60606080" } : {}}
              disabled={!canPreviousPage}
            >
              <ChevronLeftIcon />
            </button>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                nextPage();
                setChecked(false);
                setPageNumber && setPageNumber(pageIndex + 2);
              }}
              style={!canNextPage ? { backgroundColor: "#60606080" } : {}}
              disabled={!canNextPage}
            >
              <ChevronRightIcon />
            </button>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                gotoPage(pageCount - 1);
                setChecked(false);
                setPageNumber && setPageNumber(pageCount);
              }}
              style={!canNextPage ? { backgroundColor: "#60606080" } : {}}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>

        <div className={commonStyle.footer_table}>
          {/* <div>
            <button
              className={commonStyle.clearFilterEvent}
              // style={
              //   location.state?.detail ||
              //     localStorage.length > 0
              //     ? { color: "#fff", borderColor: "#fff" }
              //     : { color: "#555", borderColor: "#555" }
              // }
              onClick={() => {
                clearFilter();
                setChecked(false);
                selectAll(false);
              }}
            >
              <FilterListIcon style={{ height: 15, width: 15, marginRight: '10px' }} />
              Clear Filter
            </button>
          </div> */}
          <p
            className={commonStyle.countHead}
          >Selected Records: {selectedRows.length}</p>
          <p
            className={commonStyle.countHead}
          >Total Records: {rows.length ? rows.length : 0}</p>
        </div>
      </div>
      {showColumnModal === true && (
        <ColumnManagerModal
          columnsList={allColumns}
          open={showColumnModal}
          close={hideColumnModal}
          onChangeColumns={(data: any[]) =>
            setColumns(data)
          }
          toggleAllColumns={toggleHideAllColumns}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
    organization: state.organization,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(CustomTable);