import React, { FunctionComponent, useRef, useEffect } from "react";
import { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddContacts/AddContacts.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import { createLicensesCost, fetchLicensesCost } from "../../Services/resources";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";

type props = {
    history: any;
    user: any;
    theme: any;
};
const AddLicensesCost: FunctionComponent<props> = ({ history, user, theme }) => {
    const [load, setLoad] = useState(false);
    const [sNumber, setSNumber] = useState<any>("");
    const [tPNumber, setTPNumber] = useState<any>("");
    const [cost, setCost] = useState<any[] | undefined>(undefined);
    const dispatcher = useDispatch();
    const srNumberRef: any = useRef();
    const tpNumberRef: any = useRef();


    useEffect(() => {
        fetchLicensesCost((data) => setCost(data), (data) => setLoad(data));
    }, [])

    useEffect(() => {
        setSNumber(cost?.[0]?.licensesCost);
        setTPNumber(cost?.[0]?.no_employees);
    }, [cost])

    const handleCheck = () => {
        if (srNumberRef.current.value === "") {
            dispatcher(showSnackbarAction("Please Enter Licenses Cost !!", "error"));
            return false;
        }
        if (tpNumberRef.current.value === "") {
            dispatcher(showSnackbarAction("Please Enter Number of licenses (trial period)  !!", "error"));
            return false;
        }
        else {
            return true;
        }
    };
    const create = (e: any) => {
        e.preventDefault();
        const val = handleCheck();
        if (val) {
            setLoad(true);
            createLicensesCost(
                srNumberRef.current.value,
                tpNumberRef.current.value,
                dispatcher,
                (data: boolean) => setLoad(data),
                history
            );
        }
    };
    const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    return (
        <div className="container-fluid p-4 d-flex flex-column gap-4"  style={{...containerStyles,marginTop:"4%",minHeight:"100vh",overflow:"hidden"}}>
            {load ? <Loading /> : <>
                <h3>Licenses Cost</h3>
                <div className="w-100 d-flex flex-column gap-2" style={cardContainerStyles}>
                    <form className="card p-4 shadow rounded" style={cardContainerStyles}>
                        <div style={{ display: 'flex', gap: "0.5rem" }}>
                            <div className="mb-3 col-0.4">
                                <Label label="Licenses Cost" required />
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    ref={srNumberRef}
                                    min="1"
                                    onChange={(e) => setSNumber(e.target.value)}
                                    value={sNumber}
                                    // style={{...containerStyles}}
                                />
                            </div>
                            <div className="mb-3 col-0.4">
                                <Label label="Number of Licenses(trial period)" required />
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    ref={tpNumberRef}
                                    // style={{...containerStyles}}
                                    min="1"
                                    onChange={(e) => setTPNumber(e.target.value)}
                                    value={tPNumber}
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            // className="btn btn-primary mt-5"
                            // style={{ ...cardContainerStyles }}
                            style={{backgroundColor:theme?"#272944":"rgb(54, 56, 83)"}}
                            className={styles.button}
                            onClick={(e) => create(e)}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </>}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme: state.theme.isLightMode
    };
};

export default connect(mapStateToProps)(AddLicensesCost);