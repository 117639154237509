import React, { useRef, useState, useEffect } from "react";
import TextInput from "../../Components/TextInput/TextInput";
import styles from "../AddContacts/AddContacts.module.css";
import { FaUser } from "react-icons/fa";
import {
  emailValidate,
  phoneValidate,
} from "../../Values/validatorsnew";
import { connect, useDispatch } from "react-redux";

import {
  contactResources,
  createContacts,
  fetchConstants,
  fetchpropertySubType,
} from "../../Services/contacts";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import { showSnackbarAction } from "../../Redux/actions";
import { country_code, url, admin_mail } from "../../Values/constants";
import Select from "react-select";
import Dropdown from "../../Components/DropDown/Dropdown";
import { useLocation } from "react-router";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";
import axios from "axios";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const HelpAndSupport = ({
  history,
  user,
  organizationUsers,
  role,
  theme,
  conatctHeader,
  authToken,
  organizationData,
}: {
  history: any;
  user: any;
  organizationUsers: any;
  role: string;
  theme: any;
  conatctHeader: any;
  authToken:any;
  organizationData:any;
}) => {
  console.log("Single Organization Data printed :",organizationData);
  const dispatcher = useDispatch();
  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const numberRef: any = useRef();
  const emaileRef: any = useRef();
  const alternateRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState<
    string[]
  >([]);
  const [countryCode, setCountryCode] =
    useState<string>("");
  const [note, setNote] = useState("");
  const noteRef: any = useRef();
  const [projectSelected, setProjectSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

  const [locationSelected, setlocationSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
   const [queryTypeSelected, setQueryTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [budgetSelected, setBudgetSelected] = useState<any>(
    {
      label: "Select",
      value: "Select",
    }
  );
  const [propertyTypeSelected, setPropertyTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [leadTypeSelected, setLeadTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [propertyStageSelected, setPropertyStageSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [nextFollowSelected, setNextFollowSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [
    propertySubTypeSelected,
    setPropertySubTypeSelected,
  ] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadSourceSelected, setLeadSourceSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

  const [addSetSelected, setAddSetSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [campaignSetSelected, setCampaignSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [ownerSelected, setOwnerSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [usersList, setUsersList] = useState([]);

  const [projectsList, setProjectsList] = useState<any[]>(
    []
  );
  const [locationsList, setLocationsList] = useState<any[]>(
    []
  );
  const [queryType, setQueryType] = useState<any[]>(
    []
  );
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [leadTypeList, setleadTypeList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<
    any[]
  >([]);
  const [leadSourceList, setLeadSourceList] = useState<
    any[]
  >([]);
  const [uidList, setUidList] = useState<any>();
  const [addSet, setAddSet] = useState<any[]>([]);
  const [leadSource, setLeadSource] = useState<any[]>([]);
  const [campaign, setCampaign] = useState<any[]>([]);
  const [phoneCountryCode, setPhoneCountryCode] = useState('in');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [finalPhoneNumber, setFinalPhoneNumber] = useState('');

  const handlePhoneChange = (value: string,country:CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setFinalPhoneNumber(value);
    }
   setPhoneCountryCode(country?.dialCode);
    setPhoneNumber(value)
  }; 

  useEffect(() => {
    if(authToken){
      getLeadsFilterValues();
    }
  }, [authToken]);
  const getLeadsFilterValues = async () => {
    axios.defaults.headers.common['x-access-token'] = authToken
    // const res = await axios.post(url + '/leads/filterValues', {
    //   uid: user.uid,
    // });
    // setAddSet(res.data[0]?.addset);
    // setCampaign(res.data[0]?.campaign);
  }

  useEffect(() => {
    const code = "+"+phoneCountryCode; // Specify the code you want to find
    const result = countryCodeList.find(item => item.startsWith(code));
    if (result) {
      setCountryCode(result);
    }   
  }, [phoneCountryCode]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
    if (role === "Lead Manager" && organizationUsers) {
      let uids: { [key: string]: string } = {};
      let data: any = [];
      organizationUsers.map((item: any) => {
        data.push(
          `${item.user_first_name} ${item.user_last_name}-${item.user_email}`
        );
        uids[item.user_email] = item.uid;
      });
      setUsersList(data);
      setUidList(uids);
    }
  }, []);

  useEffect(() => {
    const unsub = contactResources(
      user.organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data),
    );

    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      (data) => setnextFollowUpList(data)
    );
    let data = ["Data", "Leads"];
    setleadTypeList(data);
    const unsubProperty = fetchpropertySubType(
      user.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );
    setQueryType(["Sales","Service"]);
    return () => {
      unsub();
      unsubConst();
      unsubProperty();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (propertyTypeSelected.value === "Commercial") {
      setPropertySubType(comTypes);
    } else if (
      propertyTypeSelected.value === "Residential"
    ) {
      setPropertySubType(resTypes);
    } else {
      setPropertySubType([]);
    }
  }, [propertyTypeSelected]);

  useEffect(() => {
    let data: any[] = [];
    country_code.forEach((item) => {
      data.push(item.dial_code + " " + item.name);
    });
    setCountryCodeList(data);
  }, []);

  useEffect(() => {
    let selected: string[] = [];
    if (user.country) {
      selected = countryCodeList.filter((item) =>
        item
          .toLowerCase()
          .includes(user.country.toLowerCase())
      );
    } else {
      selected = countryCodeList.filter((item) =>
        item.toLowerCase().includes("+91")
      );
    }
    if (selected.length !== 0) {
      setCountryCode(selected[0]);
    }
  }, [user.country, countryCodeList]);

  const choice = () => {
    let data = countryCodeList.map((item: any) => ({
      value: item,
      label: item,
    }));

    return data;
  };
  const location: any = useLocation();
  const create = async (e: any) => {
    e.preventDefault();
    if (user.organization_id) {
      if (firstNameRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "First Name Required!!",
            "error"
          )
        );
        return;
      } else if (lastNameRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "Last Name Required!!",
            "error"
          )
        );
        return;
      } else if (finalPhoneNumber === "") {
        dispatcher(
          showSnackbarAction(
            "Mobile Number Required!!",
            "error"
          )
        );
        return;
      } else if (
        emaileRef.current.value !== "" &&
        emailValidate(emaileRef.current.value) ===
        "Invalid Email"
      ) {
        dispatcher(
          showSnackbarAction("Invalid Email!!", "error")
        );
        return;
      } else if (countryCode === "") {
        dispatcher(
          showSnackbarAction(
            "Country Code Required!!",
            "error"
          )
        );
        return;
      }else if (queryTypeSelected.value === "Select") {
        dispatcher(
            showSnackbarAction(
              "Query type Required!!",
              "error"
            )
          );
      }
      setLoad(true);
      let l = ownerSelected.value.split("-");
      let email = l[1];

      const country = country_code.find(
        (el: any) =>
          el.dial_code === countryCode.split(" ")[0]
      );

      const finalData = {
        name:firstNameRef.current.value + " " + lastNameRef.current.value,
        email_id :emaileRef.current.value,
        contact_no:finalPhoneNumber,
        alternate_no:alternateRef.current.value,
        query_type :queryTypeSelected.value === "Select"
        ? ""
        : queryTypeSelected.value,
        country:country?.name,
        query:noteRef.current.value
      };
      console.log("Data send on Mail ID :",finalData);
      const queryEmailforAdmin = {
        "template_name": "Query_Support_Notification",
        "replaceable_params": [
            { "%#username#%": finalData.name},
            { "%#useremail#%": finalData.email_id},
            {"%#usermobileno#%":finalData.contact_no},
            {"%#useraltno#%":finalData.alternate_no},
            {"%#usercountry#%":finalData.country},
            {"%#querytype#%":finalData.query_type},
            {"%#userquery#%":finalData.query}
        ],
        "email": admin_mail
      }//url + '/email/sendEmail',
      await axios.post(
        url + '/email/sendEmail',
        queryEmailforAdmin
      );
      setLoad(false);
      dispatcher(
        showSnackbarAction(
          "Your query sent to Support Team. We will contact you soon.",
          "success"
        )
      );
      history.push("/");
    }
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "200px"};
  return (
    <div
      className={styles.parent}
      style={{...containerStyles,marginTop:"4%"}}
    >
      {load === true && <Loading />}
      <div
        className="d-flex flex-row w-100 px-4 pt-4"
      >
        <h5
        >
          {" "}
          {"Help And Support"}
        </h5>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/")}
        />
      </div>
      <div
        className="w-100 d-flex flex-column gap-2 px-4"
      >
        <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="First Name" required={true} />
              <div className={styles.inputContainer} style={{marginRight:"10px"}}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px",width:"150px" }}
                  ref={firstNameRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              <Label label="Last Name" required={true} />
              <div className={styles.inputContainer} style={{marginRight:"10px"}}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px",width:"150px" }}
                  ref={lastNameRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={"Email ID"} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px",width:"150px" }}
                  validator={emailValidate}
                  ref={emaileRef}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={"Contact No"} required={true} />
              <div className={styles.inputContainer}>
                <PhoneInput
                  country={phoneCountryCode}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputStyle={{ width: '150px',height:"45px"}}
                  containerStyle={{ height: "45px",color:"rgb(54, 56, 83" }}
                />
                {/* <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={numberRef}
                /> */}
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={"Country Code"} required={true} />
              <div style={{ color: "#000",width:"150px" }} >
                <Select
                  className={styles.select}
                  options={choice()}
                  onChange={(data) => {
                    if (data) {
                      setCountryCode(data.label);
                    }
                  }}
                  isSearchable={true}
                  value={{
                    label: countryCode,
                    value: countryCode,
                  }}
                  isDisabled={true}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={"Alternate No"} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px",width:"150px" }}
                  ref={alternateRef}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={"Query Type"} required={true} />
              <div style={{ width:"150px" }}>
                <Dropdown
                  option={queryType}
                  selectedValue={queryTypeSelected}
                  setSelectedValue={(value) => {
                    setQueryTypeSelected(value);
                  }}
                />
              </div>
            </div>

            {/* <div className={styles.divide}>
              <Label label={conatctHeader.find((obj:any) => obj.label === "project").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={projectsList}
                  selectedValue={projectSelected}
                  setSelectedValue={(value) => {
                    setProjectSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj:any) => obj.label === "property_type").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyTypeList}
                  selectedValue={propertyTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertyTypeSelected(value);
                  }}
                />
              </div>
            </div> */}
          </div>

          {/* <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj:any) => obj.label === "property_stage").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyStageList}
                  selectedValue={propertyStageSelected}
                  setSelectedValue={(value) => {
                    setPropertyStageSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj:any) => obj.label === "budget").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={budgetsList}
                  selectedValue={budgetSelected}
                  setSelectedValue={(value) => {
                    setBudgetSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj:any) => obj.label === "property_sub_type").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertySubType}
                  selectedValue={propertySubTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertySubTypeSelected(value);
                  }}
                />
              </div>
            </div>
          </div> */}

          <div className={styles.box2}>
            <div
              style={{ width: "100%" }}
            >
              <Label label="Query" required={false} />
              <div>
                <textarea
                  className="form-control"
                  rows={2}
                  placeholder={""}
                  ref={noteRef}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                ></textarea>
              </div>
            </div>
          </div>

          <button
            type="submit"
            style={{ backgroundColor: "#363853" }}
            className={styles.button}
            onClick={(e: any) => {
              console.log(user, "user");
              create(e);
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
    theme: state.theme.isLightMode,
    conatctHeader:state.contactHeaderList.data,
    authToken:state.user.authToken,
    organizationData:state.organization.organizationData,
  };
};

export default connect(mapStateToProps)(HelpAndSupport);
