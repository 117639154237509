import Modal from "react-modal";
import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./ImportModal.module.css";
import { IoIosClose } from "react-icons/io";
import {
  fetchContactsFile,
  uploadContactsFile,
} from "../../../Services/contacts";
import { useDispatch,connect } from "react-redux";
import Loading from "../../Loading/Loading";
import moment from "moment";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import {themeColors} from "../../theme";
import CSVGenerator from "./CSVGenerator";

type props = {
  open: boolean;
  close: () => void;
  organization_id: string;
  history: any;
  usersList: any[];
  user: any;
  conatctHeader: any;
  theme: any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

interface DataObject {
  [key: string]: any; // define your data object interface
}

// const dbData=[{"label": "customer_name","value": "Customer Name"},{"label": "country_code","value": "Country Code"},{"label": "contact_no","value": "Mobile No."},{"label": "alternate_no","value": "Alternate No."},{"label": "email","value": "Email Id"},{"label": "stage","value": "Stage"},{"label": "contact_owner_email","value": "Owner"},{"label": "lead_source","value": "Lead Source"},{"label": "budget","value": "Budget"},{"label": "call_back_reason","value": "Call back reason"},{"label": "project","value": "Project"},{"label": "property_type","value": "Property type"},{"label": "property_sub_type","value": "Property Sub Type"},{"label": "property_stage","value": "Property Stage"},{"label": "location","value": "Location"},{"label": "lost_reason","value": "Lost reason"},{"label": "not_int_reason","value": "Not Interested Reason"},{"label": "other_lost_reason","value": "Other Lost Reason"},{"label": "other_not_int_reason","value": "Other Not interested reason"},{"label": "next_follow_up_type","value": "Next follow up type"},{"label": "next_follow_up_date_time","value": "Next follow up date and time"},{"label": "created_by","value": "Created By"},{"label": "created_at","value": "Created At"},{"label": "lead_assign_at","value": "Lead Assign At"},{"label": "stage_change_at","value": "Stage Change At"},{"label": "addset","value": "Addset"},{"label": "campaign","value": "Campaign"},{"label": "inventory_type","value": "Inventory Type"},{"label": "notes","value": "Notes"},{"label": "lead_type","value": "Lead Type"}];
// const dbData = conatctHeader;
// dbData.map((list,index)=>{
//   dummyData.push({...list,list[value]})

// })
// const headerData = dbData.map(item => item.value);

// const dummyData: DataObject[] = [];
// dummyData.push(headerData);
// headerData.map(item => dummyData.push({item}))

// const dummyData: DataObject[] = [
//  { id: 2, name: "Jane", age: 25, stage: "Fresh" },
//   { id: 2, name: "Jane", age: 25, stage: "Fresh" },
//   { id: 3, name: "Bob", age: 40, stage: "Fresh" },
// ];

const NewImportContactModal: FunctionComponent<props> = ({
  open,
  close,
  organization_id,
  history,
  usersList,
  user,
  conatctHeader,
  theme
}) => {

  // const filtervalues = ["customer_name","country_code","contact_no","alternate_no","email","stage","contact_owner_email","lead_source","budget","call_back_reason","project","property_type","property_sub_type","property_stage","location","lost_reason","not_int_reason","other_lost_reason","other_not_int_reason","next_follow_up_type","next_follow_up_date_time","created_by","created_at","lead_assign_time","stage_change_at","addset","campaign","inventory_type","notes","lead_type","field_one","field_two","field_three","field_four","field_five","field_six"];
  const filtervalues = ["customer_name","country_code","contact_no","alternate_no","email","stage","contact_owner_email","lead_source","budget","call_back_reason","project","property_type","property_sub_type","property_stage","location","lost_reason","not_int_reason","other_lost_reason","other_not_int_reason","next_follow_up_type","next_follow_up_date_time","created_by","created_at","lead_assign_time","stage_change_at","addset","campaign","notes","lead_type","field_one","field_two","field_three","field_four","field_five","field_six"];
  
  const reqData = conatctHeader.filter((obj:any)=>filtervalues.includes(obj.label));
  const dbData = [...reqData,{
		"label": "notes",
		"value": "Notes"
	}]
const headerData = dbData.map((item:any) => item.value);

const contactHeaderData: DataObject[] = [];
contactHeaderData.push(headerData);
  const [load, setLoad] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const dispatcher = useDispatch();

  useEffect(() => {
    if (organization_id) {
      const unsub = fetchContactsFile(
        organization_id,
        user.uid,
        (data: any) => setUploadedFiles(data),
        dispatcher
      );
      return () => {
        unsub();
      };
    }
  }, [organization_id]);
  const upload = async (file: any) => {
    setLoad(true);
    uploadContactsFile(
      file,
      organization_id,
      user.uid,
      dispatcher,
      (data: boolean) => setLoad(data),
      user.user_first_name + " " + user.user_last_name
    );
  };
  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.cardBackgroundColor:themeColors.cardBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  }; 
  const containerStyles = { backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  const tdStyles = {
    backgroundColor:theme?themeColors.tdBackgroundColor:themeColors.tdBackgroundColorDark,
    color:theme?themeColors.textPrimary:themeColors.textPrimaryDark,
  }
  const thStyles = {
    backgroundColor:theme ? themeColors.thBackgroundColor:themeColors.thBackgroundColorDark,    color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
  }
  return (
    <>
           <Backdrop>
        <motion.div
        key="modal"
        className="card px-4 py-2 shadow rounded"
        style={{position: "absolute",
        ...containerStyles}}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
      {load === true && <Loading />}
      <div className={styles.child}>
        {load === true && <Loading />}
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
        <h5 
        // className={styles.contactForm}
        >Import Data for "Contact Form"</h5>
        <div className={styles.tableBox}>
          <table className={styles.table}>
            <thead className={styles.head}>
              <tr>
                <th className={styles.th} style={thStyles}>Sno.</th>
                <th className={styles.th} style={thStyles}>Request Id</th>
                <th className={styles.th} style={thStyles}>Created At</th>
                <th className={styles.th} style={thStyles}>Status</th>
                <th className={styles.th} style={thStyles}>Upload Count</th>
                <th className={styles.th} style={thStyles}>Uploaded File</th>
                <th className={styles.th} style={thStyles}>Processed File</th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.map((item: any, index: number) => (
                <tr key={index} className={styles.no}>
                  <td className={styles.td} style={tdStyles}>{index + 1}</td>
                  <td className={styles.td} style={tdStyles}>{item.request_id}</td>
                  <td className={styles.td} style={tdStyles}>
                    {moment(item.created_at.toDate()).format("MM/DD/YY HH:mm")}
                  </td>
                  <td className={styles.td} style={tdStyles}>{item.status}</td>
                  <td className={styles.td} style={tdStyles}>{item.uploadCount}</td>
                  <td className={styles.td} style={tdStyles}>
                    <a href={item.fileUrl}>Uploaded File</a>
                  </td>

                  <td className={styles.td} style={tdStyles}>
                    {item.responseURL !== "" && (
                      <a href={item.responseURL}>Processed File</a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.selectBox}>
          {/* <div className={styles.uploadButton}>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://firebasestorage.googleapis.com/v0/b/read-pro.appspot.com/o/contactsUploadData%2FcontactTemplate.csv?alt=media&token=638fb4f2-a08d-4068-b23c-f124252cb378"
            >
              Download Template
            </a>
          </div> */}
          <CSVGenerator data={contactHeaderData}/>
          <label htmlFor="file-input" className={styles.uploadButton}>
            Upload File
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={(e: any) => upload(e.target.files[0])}
          />
        </div>
        <div style={{textAlign: "center",marginTop:"1rem"}}>
                    <p><i className="fas fa-info-circle"></i>  Only .csv file type is supported.</p>
                    <p style={{marginTop:"-1rem"}}><i className="fas fa-info-circle"></i>  Please ensure filling all the mandatory fields.</p>
                </div>
      </div>
      </motion.div>
      </Backdrop>
    </>
  );
      {/* <Modal
      style={{ overlay: { zIndex: 100 } }}
      className={styles.parent}
      isOpen={open}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {load === true && <Loading />}
      <div className={styles.child}>
        {load === true && <Loading />}
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
        <p className={styles.contactForm}>Import Data for "Contact Form"</p>
        <div className={styles.tableBox}>
          <table className={styles.table}>
            <thead className={styles.head}>
              <tr>
                <th className={styles.th}>Sno.</th>
                <th className={styles.th}>Request Id</th>
                <th className={styles.th}>Created At</th>
                <th className={styles.th}>Status</th>
                <th className={styles.th}>Upload Count</th>
                <th className={styles.th}>Uploaded File</th>
                <th className={styles.th}>Processed File</th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.map((item: any, index: number) => (
                <tr key={index} className={styles.no}>
                  <td className={styles.td}>{index + 1}</td>
                  <td className={styles.td}>{item.request_id}</td>
                  <td className={styles.td}>
                    {moment(item.created_at.toDate()).format("MM/DD/YY HH:mm")}
                  </td>
                  <td className={styles.td}>{item.status}</td>
                  <td className={styles.td}>{item.uploadCount}</td>
                  <td className={styles.td}>
                    <a href={item.fileUrl}>Uploaded File</a>
                  </td>

                  <td className={styles.td}>
                    {item.responseURL !== "" && (
                      <a href={item.responseURL}>Processed File</a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.selectBox}>
          <div className={styles.uploadButton}>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://firebasestorage.googleapis.com/v0/b/read-pro.appspot.com/o/contactsUploadData%2FcontactTemplate.csv?alt=media&token=638fb4f2-a08d-4068-b23c-f124252cb378"
            >
              Download Template
            </a>
          </div>
          <label htmlFor="file-input" className={styles.uploadButton}>
            Upload File
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={(e: any) => upload(e.target.files[0])}
          />
        </div>
      </div>
    </Modal> */}
};

const mapStateToProps = (state: any) => {
  return {
    conatctHeader:state.contactHeaderList.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(NewImportContactModal);

