import React, { FunctionComponent, useState, useRef } from "react";
import { useDispatch,connect } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddOrganization/AddOrganization.module.css";
import { AiOutlineClose } from "react-icons/ai";
import TextInput from "../../Components/TextInput/TextInput";
import { emailValidate, phoneValidate } from "../../Values/validatorsnew";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { countryData } from "../../Values/constants";
import { useEffect } from "react";
import { editOrganization } from "../../Services/organizations";
import { themeColors } from "../../Components/theme";
import Label from "../../Components/Label";
import axios from "axios";
import { url } from "../../Values/constants";
import { showSnackbarAction } from "../../Redux/actions";

type props = {
  history: any;
  theme: any;
  user:any;
};

const EditHeaders: FunctionComponent<props> = ({ history,theme,user }) => {
    type MyObject = {
        label: string;
        value: string;
        is_mandatory: string;
        hide_field: string;
    };
  const dispatcher = useDispatch();
  const location: any = useLocation();
  const [load, setLoad] = useState(false);

  const organizationNameRef: any = useRef();
  const mobileNumberRef: any = useRef();
  const emailRef: any = useRef();
  const cityRef: any = useRef();
  const addressRef: any = useRef();
  const pincodeRef: any = useRef();
  const [inputValues, setInputValues] = useState<string[]>([]);

  const employeesRef: any = useRef();
  const myArray: MyObject[] =location.state.detail.headers;

  const handleInputChange = (index: number, value: string, label: string) => {
    setInputValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });
  };

//   const renderBatch = (batch: MyObject[]) => {
//     return batch.map((obj,index) => (
//       <>
//       {obj.hide_field==="false" ? (
//         <div style={{ padding: '5px',display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexWrap:"wrap",width:"18%",height:'auto' }}>
//             {location.state.detail.status !== "Launched"?<input
//                 type={obj.label}
//                 placeholder={obj.value}
//                 id={obj.label}
//                 value={inputValues[index] || ""}
//                 onChange={(e) => handleInputChange(index, e.target.value,obj.label)}
//                 style={{
//                     height:30,
//                     width:180,
//                     borderRadius:5,
//                     flexWrap:"wrap",
//                     border:obj.is_mandatory === "true" ?"2px solid red":""
//                 }}
//             />
//             :<input
//             type={obj.label}
//             placeholder={obj.value}
//             id={obj.label}
//             value={myArray[index].value || ""}
//             onChange={(e) => handleInputChange(index, e.target.value,obj.label)}
//             style={{
//                 height:30,
//                 width:180,
//                 borderRadius:5,
//                 flexWrap:"wrap",
//                 border:obj.is_mandatory === "true" ?"2px solid red":""
//             }}
//             readOnly
//         />

//   }
//             {/* <TextInput
//             key={obj.label}
//             title={obj.value}
//             style={{ backgroundColor: "#fff"}}
//             ref={adminLastNameRef}
//         /> */}
//         </div>
//       ) : (
//         <></>
//       )}
//       </>
//     ));
// }; 
      const renderBatch = (batch: MyObject[]) => {
        return batch.map((obj,index) => (
          <>
          {obj.hide_field==="false" ? (
            <div style={{ padding: '5px',display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexWrap:"wrap",width:"18%",height:'auto' }}>
            <input
                type={obj.label}
                placeholder={obj.value}
                id={obj.label}
                value={inputValues[index] || ""}
                onChange={(e) => handleInputChange(index, e.target.value,obj.label)}
                style={{
                    height:30,
                    width:180,
                    borderRadius:5,
                    flexWrap:"wrap",
                    border:obj.is_mandatory === "true" ?"2px solid red":""
                }}
             />
            </div>
          ) : (
            <></>
          )}
          </>
        ));
      };
  const editOrganizationDetails = async (e:any,status:any) => {
    e.preventDefault();
        setLoad(true);
       
        const updatedArray = myArray.map((obj, index) => ({
            ...obj,
            value: inputValues[index] !== undefined ? inputValues[index] : obj.value,
          }));
          const apiData = {
            industry_id:location.state.detail.industry_id,
            screen_name:location.state.detail.screen_name,
            modified_by:user.user_email,
            status: status,
            headers:updatedArray,
            modified_at: new Date()
          };
          try {
            // const res = await axios.patch("http://localhost:4001/headers/updateHeaders", apiData);
            const res = await axios.patch(url+'/headers/updateHeaders', apiData);
            if(res.status==200){
              dispatcher(showSnackbarAction(res.data, "success"));
              const updateIndRes = await axios.post(url+'/headers/updateIndustryStatus', {industry_id:location.state.detail.industry_id});
              history.replace("/headers")
              setLoad(false);
          }else{
              dispatcher(showSnackbarAction(res.data, "error"));
              setLoad(false);
          }
            setInputValues([]);
            setLoad(false);
          } catch (e) {
            setLoad(false);
          }
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };
  return (
    <div className={styles.parent} style={{...containerStyles,marginTop:"4%"}}>
    {load === true && <Loading />}
    <div 
      // className={styles.headerView}   
      className="d-flex flex-row w-100 px-4 pt-4">
        <h5 
        // className={styles.heading}
        >
           {"Edit Headers Name"}</h5>
          {/* {location.state.detail.status !== "Launched"?"Edit Headers Name": "View Headers Name"}</h5> */}
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/headers")}
        />
      </div>
    <div 
      // className={styles.child}
      className="w-100 d-flex flex-column gap-2 px-4"
    >
             <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>

             {/* <div className={styles.box2}>
                                {renderBatch(myArray)}
                    </div> */}
                    <div style={{display:'flex',flexDirection:'row',marginTop:'0.5rem',marginBottom:'0.5rem',flexWrap:'wrap',justifyContent:'flex-start',}}>
                    {renderBatch(myArray)}
                    </div>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'center',}}>
      {/* {location.state.detail.status !== "Launched" && <button
        style={{marginRight:'20px'}}
        className={styles.button}
        onClick={(e:any) => {
          editOrganizationDetails(e,"InReview");
        }}
      >
        Submit
      </button>}
      {location.state.detail.status !== "Launched" && <button
        className={styles.button}
        onClick={(e:any) => {
          editOrganizationDetails(e,"Launched");
        }}
      >
        Launch
      </button>} */}
       <button
        style={{marginRight:'20px'}}
        className={styles.button}
        onClick={(e:any) => {
          editOrganizationDetails(e,"InReview");
        }}
      >
        Submit
      </button>
      <button
        className={styles.button}
        onClick={(e:any) => {
          editOrganizationDetails(e,"Launched");
        }}
      >
        Launch
      </button>
      </div>
             </form>
    </div>
  </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
    user: state.user.data
  };
};

export default connect(mapStateToProps)(EditHeaders);

