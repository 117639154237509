import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  changeLeadStage,
  createTaskFirebase,
  fetchConstants,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TextInput from "../../TextInput/TextInput";
import { themeColors } from "../../theme";
import Label from "../../Label";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import axios from "axios";
import { url } from "../../../Values/constants";
type props = {
  contactDetail: any;
  close: () => void;
  open: boolean;
  tasksData: any[];
  user: any;
  notes: any[];
  theme: any;
  authToken: any;
  // tasksDataList?: any[];
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};


const CreateModal: FunctionComponent<props> = ({
  contactDetail,
  close,
  open,
  tasksData,
  user,
  notes,
  theme,
  authToken
  // tasksDataList
}) => {
  const dateRef: any = useRef();
  const noteRef: any = useRef();
  const [load, setLoad] = useState(false);
  // const [tasksListData] = useState(tasksDataList);
  const [uniqueMeeting, setUniqueMeeting] = useState<boolean>(false);
  const [uniqueMeetingList, setUniqueMeetingList] = useState<any[]>([]);
  const [uniqueSiteMeeting, setUniqueSiteMeeting] = useState<boolean>(false);
  const [uniqueSiteMeetingList, setUniqueSiteMeetingList] = useState<any[]>([]);
  const [nextFollowSelected, setNextFollowSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [existingTaskSelected, setExistingTaskSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [existingTaskStatus, setExistingTaskStatus] =
    useState(false);
    const [tasksListsData, setTasksListsData] = useState<any[]>([]);


  useEffect(() => {
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      (data) => setnextFollowUpList(data)
    );

    if (
      tasksData[0].status === "Pending" &&
      tasksData[0].type !== "Call Back"
    ) {
      setExistingTaskStatus(true);
    }

    return () => {
      unsubConst();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(authToken){
      getTasks();
    }
  },[authToken])

  const getTasks = async () => {
    const bodyData = {
      leadId: contactDetail.contactId,
    }
    axios.defaults.headers.common['x-access-token'] = authToken

    const getTasksRes = await axios.post(
      url + '/tasks/getTasksId',
      bodyData
    );
    setTasksListsData(getTasksRes?.data);
  }

  const dispatcher = useDispatch();
  const history = useHistory();

  const onSubmit = () => {
    if (nextFollowSelected.value === "Select") {
      dispatcher(
        showSnackbarAction(
          "Select Next Follow Up Type!!",
          "error"
        )
      );
    } else if (
      dateRef.current.value === "" ||
      dateRef.current.value < new Date()
    ) {
      dispatcher(
        showSnackbarAction("Enter A Valid Date!!", "error")
      );
    } else if (
      existingTaskStatus &&
      existingTaskSelected.value === "Select"
    ) {
      dispatcher(
        showSnackbarAction(
          "Select Exisiting Task Status!!",
          "error"
        )
      );
    } else {
      setLoad(true);
      const data = {
        due_date: moment(dateRef.current.value).toDate(),
        type: nextFollowSelected.value,
        status: "Pending",
        customer_name: contactDetail.customer_name,
      };
      if (noteRef.current.value.length !== 0) {
        addNoteFirebase(
          contactDetail.contactId,
          notes,
          noteRef.current.value,
          () => {},
          dispatcher,
          () => {},
          user.user_email
        );
      }
      createTaskFirebase(
        contactDetail.contactId,
        tasksData,
        data,
        {
          next_follow_up_type: nextFollowSelected.value,
          next_follow_up_date_time: moment(
            dateRef.current.value
          ).toDate(),
        },
        (value: boolean) => setLoad(value),
        dispatcher,
        user,
        close,
        history,
        existingTaskStatus &&
          existingTaskSelected.value === "Completed"
          ? true
          : false
      );
    }
    existingTaskStatus && existingTaskSelected.value === "Completed" && uniqueTaskUpdate()
  };
  const uniqueTaskUpdate = async () => {
    let unSiteVist=false;
    let unMeeting =false;
    tasksListsData?.filter(item => item?.type === "Meeting")?.map((list: any) => {
      if(list?.unique_meeting === true){
        return unMeeting=true;
      }
    });
    tasksListsData?.filter(item => item?.type === "Site Visit")?.map((list: any) => {
      if(list?.unique_site_visit === true)
      { 
         return unSiteVist=true;
      }
    });
    if (!unSiteVist && tasksListsData?.filter(item => item?.type === "Site Visit")?.some(list => list?.status === "Pending")) {
      const arrSiteMeetingList =  tasksListsData?.filter(item => item?.type === "Site Visit" && item?.status === "Pending")
      const bodyData = {
        id: arrSiteMeetingList[0]?._id,
        unique_meeting: false,
        unique_site_visit: true
      }
      axios.defaults.headers.common['x-access-token'] = authToken
      const uniqueTaskTypeUpdate = await axios.post(
        url + '/tasks/uniqueTaskTypeUpdate',
        bodyData
      );
    }
    if (!unMeeting && tasksListsData?.filter(item => item?.type === "Meeting")?.some(list => list?.status === "Pending")) {
      const arruniqueMeetingList =tasksListsData?.filter(item => item?.type === "Meeting" && item?.status === "Pending")
      const bodyData = {
        id: arruniqueMeetingList[0]?._id,
        unique_meeting: true,
        unique_site_visit: false
      }
      axios.defaults.headers.common['x-access-token'] = authToken
      const uniqueTaskTypeUpdate = await axios.post(
        url + '/tasks/uniqueTaskTypeUpdate',
        bodyData
      );
    }
  }
  const customStyles = {
    content: {
      backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <>
      {load === true && <Loading />}
      {open && <Backdrop>
        <motion.div
          key="modal"
          className="card p-4 shadow rounded"
          style={{
            position: "absolute", minWidth: "400px",
            ...containerStyles
          }}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className={styles.firstDiv}>
            <h5
            // className={styles.contactForm}
            >
              Create New Task
            </h5>
            <div className={styles.cross} onClick={close}>
              <IoIosClose size={35} color={"#808080"} />
            </div>
          </div>
          <div className={styles.line}></div>

          {existingTaskStatus && (
            <div className={styles.box2}>
              <div
                className={styles.divide}
                style={{ width: "100%" }}
              >
                {/* <div className={styles.title}>
              <p className={styles.one}>
                Exisiting Task Status
              </p>
              <p className={styles.two}>*</p>
            </div> */}
                <Label label="Existing Task Status" required={true} />
                <div style={{ marginTop: "7px" }}>
                  <Dropdown
                    option={["Completed", "Cancelled"]}
                    selectedValue={existingTaskSelected}
                    setSelectedValue={(value) => {
                      setExistingTaskSelected(value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className={styles.box2}>
            <div
              className={styles.divide}
              style={{ width: "100%" }}
            >
              {/* <div className={styles.title}>
            <p className={styles.one}>
              Next Follow Up Type
            </p>
            <p className={styles.two}></p>
          </div> */}
              <Label label="Next Follow Up Type" required={false} />
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  option={nextFollowUpList}
                  selectedValue={nextFollowSelected}
                  setSelectedValue={(value) => {
                    setNextFollowSelected(value);
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={styles.divide}
            style={{ width: "100%", marginTop: "1.5rem" }}
          >
            {/* <div className={styles.title}>
          <p className={styles.one}>
            Next Follow Up Date & Time
          </p>
          <p className={styles.two}></p>
        </div> */}
            <Label label="Next Follow Up Date & Time" required={false} />
            <div>
              <input
                type="datetime-local"
                placeholder="Enter Date"
                ref={dateRef}
                className={styles.dateInput}
              />
            </div>
          </div>

          <div className={styles.box2}>
            <div
              className={styles.divide}
              style={{ width: "100%" }}
            >
              {/* <div className={styles.title}>
            <p className={styles.one}>Note</p>
            <p className={styles.two}></p>
          </div> */}
              <Label label="Note" required={false} />
              <div>
                <TextInput
                  title={"Enter Note"}
                  style={{ backgroundColor: "#fff" }}
                  ref={noteRef}
                ></TextInput>
              </div>
            </div>
          </div>

          <button
            className={styles.apply}
            style={{ marginTop: "2rem" }}
            onClick={onSubmit}
          >
            Save
          </button>
        </motion.div>
      </Backdrop>}
    </>
    // <Modal
    //   className={styles.parent}
    //   isOpen={open}
    //   shouldCloseOnOverlayClick={true}
    //   overlayClassName={styles.overlay}
    //   shouldCloseOnEsc={true}
    //   onRequestClose={close}
    //   style={customStyles}
    // >
    //   {load === true && <Loading />}
    //   <div className={styles.firstDiv}>
    //     <h5 
    //     // className={styles.contactForm}
    //     >
    //       Create New Task
    //     </h5>
    //     <div className={styles.cross} onClick={close}>
    //       <IoIosClose size={35} color={"#808080"} />
    //     </div>
    //   </div>
    //   <div className={styles.line}></div>

    //   {existingTaskStatus && (
    //     <div className={styles.box2}>
    //       <div
    //         className={styles.divide}
    //         style={{ width: "100%" }}
    //       >
    //         {/* <div className={styles.title}>
    //           <p className={styles.one}>
    //             Exisiting Task Status
    //           </p>
    //           <p className={styles.two}>*</p>
    //         </div> */}
    //         <Label label="Existing Task Status" required={true} />
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={["Completed", "Cancelled"]}
    //             selectedValue={existingTaskSelected}
    //             setSelectedValue={(value) => {
    //               setExistingTaskSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   )}

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>
    //           Next Follow Up Type
    //         </p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Next Follow Up Type" required={false} />
    //       <div style={{ marginTop: "7px" }}>
    //         <Dropdown
    //           option={nextFollowUpList}
    //           selectedValue={nextFollowSelected}
    //           setSelectedValue={(value) => {
    //             setNextFollowSelected(value);
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   <div
    //     className={styles.divide}
    //     style={{ width: "100%", marginTop: "1.5rem" }}
    //   >
    //     {/* <div className={styles.title}>
    //       <p className={styles.one}>
    //         Next Follow Up Date & Time
    //       </p>
    //       <p className={styles.two}></p>
    //     </div> */}
    //     <Label label="Next Follow Up Date & Time" required={false} />
    //     <div>
    //       <input
    //         type="datetime-local"
    //         placeholder="Enter Date"
    //         ref={dateRef}
    //         className={styles.dateInput}
    //       />
    //     </div>
    //   </div>

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>Note</p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Note" required={false} />
    //       <div>
    //         <TextInput
    //           title={"Enter Note"}
    //           style={{ backgroundColor: "#fff" }}
    //           ref={noteRef}
    //         ></TextInput>
    //       </div>
    //     </div>
    //   </div>

    //   <button
    //     className={styles.apply}
    //     style={{ marginTop: "auto" }}
    //     onClick={onSubmit}
    //   >
    //     Save
    //   </button>
    // </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    authToken:state.user.authToken
  };
};

export default connect(mapStateToProps)(CreateModal);
