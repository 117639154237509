export const themeColors = {
   backgroundColor:"#fffafa",
   //backgroundColor:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,28,34,0.87718837535014) 51%, rgba(0,151,255,0.3561799719887955) 100%)",
   // backgroundColorDark:"#002029",
   backgroundColorDark:"#363853",
   thBackgroundColor:"#e9ecef",
   thBackgroundColorDark:"#272944",
   tdBackgroundColor:"#f8f9fa",
   tdBackgroundColorDark:"#272944",
   textPrimary:"#000",
   textPrimaryDark:"#fff",
   buttonPrimary:"#fff",
   buttonPrimaryDark:"#fff",
   cardBackgroundColor:"#fff",
   cardBackgroundColorDark:"#272944",
   dropdownBackgroundColor:"#fff",
   dropdownBackgroundColorDark:"#272944",
   navbarBackgroundColor:"#272944",
   navbarBackgroundColorDark:"#272944",
   sidebarBackgroundColor:"#272944",
   sidebarBackgroundColorDark:"#272944",
   danger:"red",
   iconColor:"#fff",
   iconColorDark:"#fff",
   iconColorAlternate:"#000",
   iconColorAlternateDark:"#fff",
   trBorderColor:"#e9ecef",
   trBorderColorDark:"#035268",
   thResourcesBackgroundColorDark:"#363853",
   thResourcesBackgroundColor:"#272944",
}













