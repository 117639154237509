import React, { FunctionComponent, useState, useEffect } from "react";
import Modal from "react-modal";
import styles from "./AddResourceModal.module.css";
import { IoIosClose } from "react-icons/io";
import { connect, useDispatch } from "react-redux";
import {themeColors} from "../../theme";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import ColorPicker from "react-pick-color";
import Dropdown from "../../DropDown/Dropdown";
import { fetchpropertyType } from "../../../Services/contacts";

type props = {
  open: boolean;
  close: () => void;
  heading: string;
  title: string;

  onSave: (v: any,c:any) => void;
  theme:any;
  user:any;
  conatctHeader:any;
  leadSourceColour?:any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const EditResources: FunctionComponent<props> = ({
  open,
  close,
  heading,
  title,
  onSave,
  theme,
  user,
  conatctHeader,
  leadSourceColour
}) => {
  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyTypeSelected, setPropertyTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [value, setValue] = useState(title);
  const [color, setColor] = useState(leadSourceColour);
  const [propertyTypeValue, setpropertyTypeValue] = useState("");
  useEffect(() => {
    setpropertyTypeValue(propertyTypeSelected.value);
  }, [propertyTypeSelected]);
  useEffect(() => {
    const unsubProperty = fetchpropertyType(
      user.organization_id,
      (data) => setPropertyTypeList(data)
    );

    return () => {
      unsubProperty();
    };
  }, []);
  const selectInputContainerStyles = { width: "240px", marginLeft: "15px" };
  const containerStyles ={ backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark} 
  return (
    // <Modal
    //   isOpen={open}
    //   className={styles.parent}
    //   overlayClassName={styles.overlay}
    //   onRequestClose={close}
    //   shouldCloseOnOverlayClick={true}
    //   shouldCloseOnEsc={true}
    //   style={customStyles}
    // >
    //      <div className={styles.headingContainer}>
    //      <h5 
    //     //  className={styles.heading}
    //      >{heading}</h5>
    //      <div className={styles.icon} onClick={close}>
    //     <IoIosClose size={35} color={"#808080"} />
    //   </div>
    //      </div>
    //   {/* <div className={styles.icon} onClick={close}>
    //     <IoIosClose size={35} color={"#808080"} />
    //   </div>
    //   <p className={styles.heading}>{heading}</p> */}
    //   <div className={styles.nameBox}>
    //     {/* <span className={styles.name}>{`Enter ${heading.split(" ")[1]}`}</span> */}
    //     <h6>{`Enter ${heading.split(" ")[1]}`}</h6>
    //     <input
    //       className={styles.input}
    //       onChange={(event) => setValue(event.target.value)}
    //       value={value}
    //     />
    //   </div>
    //   <div className={styles.button}>
    //     <button className={styles.cancel} onClick={close}>
    //       Cancel
    //     </button>
    //     <button className={styles.change} onClick={() => onSave(value)}>
    //       Save
    //     </button>
    //   </div>
    // </Modal>
      <Backdrop>
         <motion.div
       key="modal"
       className="card p-4 shadow rounded"
       style={{position: "absolute",
       ...containerStyles}}
       variants={dropIn}
       initial="hidden"
       animate="visible"
       exit="exit"
     >
        <div className={styles.headingContainer}>
        {/* conatctHeader.find((obj: any) => obj.label === "property_type").value */}
         
         {heading !== "Edit Property Type" && heading!=="Edit Property Sub Type" && (
           <h5>{heading}</h5>
          )
          }
          {heading === "Edit Property Type" && (
           <h5>{`Edit ${conatctHeader.find((obj: any) => obj.label === "property_type").value}`}</h5>
          )
          }
          {heading === "Edit Property Sub Type" && (
           <h5>{`Edit ${conatctHeader.find((obj: any) => obj.label === "property_sub_type").value}`}</h5>
          )
          }
         {/* <div className={styles.icon} onClick={close}>
        <IoIosClose size={35} color={"#808080"} />
      </div> */}
         </div>
      {/* <div className={styles.icon} onClick={close}>
        <IoIosClose size={35} color={"#808080"} />
      </div>
      <p className={styles.heading}>{heading}</p> */}
      <div className={styles.nameBox}>
        {/* <span className={styles.name}>{`Enter ${heading.split(" ")[1]}`}</span> */}
        
        {heading !== "Edit Property Type" && heading !== "Edit Property Sub Type" && (
           <h6>{`Enter ${heading.split(" ")[1]}`}</h6>
          )
          }
        {heading === "Edit Property Type" && (
           <h6>{`Enter ${conatctHeader.find((obj: any) => obj.label === "property_type").value.split(" ")[1]}`}</h6>
          )
          }
          {heading === "Edit Property Sub Type" && (
           <h6>{`Enter ${conatctHeader.find((obj: any) => obj.label === "property_sub_type").value}`}</h6>
          )
          }
        <input
          // className={styles.input}
          className="form-control"
          style={{  flex: 1,fontSize:"14px"}}
          onChange={(event) => setValue(event.target.value)}
          value={value}
        />
      </div>
      {heading==="Edit Lead Source" && (
        <div className={styles.nameBox}>
        {/* <span className={styles.name}>{title}</span> */}
        <h6>Edit Lead Source Colour:</h6>
        <ColorPicker color={color} onChange={(color) => setColor(color.hex)} />
      </div>
      )
      }
      {heading==="Edit Property Sub Type" && (
        <div className={styles.nameBox}>
        <h6>{`Choose ${conatctHeader.find((obj: any) => obj.label === "property_type").value}`}:</h6>
        <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyTypeList}
                  selectedValue={propertyTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertyTypeSelected(value);
                  }}
                />
              </div>
      </div>
      )
      }
      <div className={styles.button}>
      {heading!=="Edit Property Sub Type" && (
        <button className={styles.change} onClick={() => onSave(value,color)}>
          Save
        </button>
        )}
        {heading==="Edit Property Sub Type" && (
        <button className={styles.change} onClick={() => onSave(value,propertyTypeValue)}>
          Save
        </button>
        )}
        <button className={styles.cancel} onClick={close}>
          Cancel
        </button>
      </div>
     </motion.div>
      </Backdrop>
  );
};

const mapStateToProps = (state: any) => {
  return {
    contacts: state.contacts,
    theme: state.theme.isLightMode,
    user: state.user.data,
    conatctHeader: state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(EditResources);
