import React, { useEffect, useRef, useState } from "react";
import styles from "./EditContact.module.css";
import TextInput from "../../Components/TextInput/TextInput";

import { FaUser } from "react-icons/fa";
import { phoneValidate } from "../../Values/validatorsnew";
import { connect, useDispatch } from "react-redux";
import {
  contactResources,
  editContact,
  fetchConstants,
  fetchNewPropertySubType,
  fetchpropertySubType,
  fetchpropertyType,
} from "../../Services/contacts";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import Dropdown from "../../Components/DropDown/Dropdown";
import { useLocation } from "react-router";
import { showSnackbarAction } from "../../Redux/actions";
import { country_code, url } from "../../Values/constants";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";
import axios from "axios";

const EditContact = ({
  history,
  userRole,
  theme,
  user,
  conatctHeader,
  authToken
}: {
  history: any;
  userRole: any;
  theme: any;
  user: any;
  conatctHeader: any;
  authToken: any;
}) => {
  const location: any = useLocation();
  console.log("Contact headers details :", location.state.detail);
  const dispatcher = useDispatch();
  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const emailRef: any = useRef();
  const numberRef: any = useRef();
  const alternateNumberRef: any = useRef();

  const otherNotIntRef: any = useRef();

  const otherLostRef: any = useRef();
  const leadSourceRef: any = useRef();
  const oneFieldRef: any = useRef();
  const twoFieldRef: any = useRef();
  const threeFieldRef: any = useRef();
  const fourFieldRef: any = useRef();
  const fiveFieldRef: any = useRef();
  const sixFieldRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [notIntReasonList, setNotIntReasonList] = useState<any[]>([]);
  const [lostReasonList, setLostReasonList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<any[]>([]);
  const [oldPropertyTypeList, setOldPropertyTypeList] = useState<any[]>([]);
  const [allPropertySubTypeList, setAllPropertySubTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] = useState<any[]>([]);
  const [addSet, setAddSet] = useState<any[]>([]);
  const [addSetSelected, setAddSetSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [stageSelected, setStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [campaignSetSelected, setCampaignSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertyStageSelected, setPropertyStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [locationSelected, setlocationSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [budgetSelected, setBudgetSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [projectSelected, setProjectSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadTypeList, setleadTypeList] =
    useState<any[]>([]);
  // const [notIntSelected, setNotIntSelected] = useState<any>({
  //   label: "Select",
  //   value: "Select",
  // });
  // const [lostSelected, setLostSelected] = useState<any>({
  //   label: "Select",
  //   value: "Select",
  // });
  const [leadSourceSelected, setLeadSourceSelected] = useState<any>({
    label: location.state.detail.lead_source
      ? location.state.detail.lead_source
      : "Select",
    value: location.state.detail.lead_source
      ? location.state.detail.lead_source
      : "Select",
  });
  const [propertySubTypeSelected, setPropertySubTypeSelected] = useState<any>({
    label: location.state.detail.property_sub_type
      ? location.state.detail.property_sub_type
      : "Select",
    value: location.state.detail.property_sub_type
      ? location.state.detail.property_sub_type
      : "Select",
  });
  const [leadTypeSelected, setLeadTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState<string>("");
  const [countryCodeList, setCountryCodeList] = useState<string[]>([]);
  const [campaign, setCampaign] = useState<any[]>([]);

  const stage = [
    "FRESH",
    "WON",
    "CALL BACK",
    "INTERESTED",
    "LOST",
    "NOT-INTERESTED",
  ];
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.8); // Set an initial value, adjust as needed

  const handleResize = () => {
    // Update maxHeight when the window is resized
    setMaxHeight(window.innerHeight * 0.8); // Adjust the multiplier as needed
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (authToken) {
      getLeadsFilterValues();
    }
  }, [user.uid, authToken]);

  const getLeadsFilterValues = async () => {
    axios.defaults.headers.common['x-access-token'] =
      authToken
    const res = await axios.post(url + '/leads/filterValues', {
      uid: user.uid,
    });
    setAddSet(res.data[0]?.addset);
    setCampaign(res.data[0]?.campaign);
  }

  useEffect(() => {
    let data: any[] = [];
    country_code.forEach((item) => {
      data.push(item.dial_code + " " + item.name);
    });
    setCountryCodeList(data);
    if (location.state.detail.country_code) {
      setCountryCode(location.state.detail.country_code);
    }
  }, []);

  const choice = () => {
    let data = countryCodeList.map((item: any) => ({
      value: item,
      label: item,
    }));
    return data;
  };

  useEffect(() => {
    const unsub = contactResources(
      location.state.detail.organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data),
      (data) => setLeadSourceList(data)
    );
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setOldPropertyTypeList(data)
    );
    const unNewPropertyType = fetchpropertyType(
      user.organization_id,
      (data) => setPropertyTypeList(data)
    );
    const unNewPropertySubType = fetchNewPropertySubType(
      user.organization_id,
      (data) => setAllPropertySubTypeList(data)
    );
    let data = ["Data", "Leads"];
    setleadTypeList(data);
    const unsubProperty = fetchpropertySubType(
      location.state.detail.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );

    return () => {
      unsub();
      unsubConst();
      unsubProperty();
      unNewPropertyType();
      unNewPropertySubType();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("allPropertySubTypeListallPropertySubTypeList:", allPropertySubTypeList);
    const filteredArray = allPropertySubTypeList.filter(item => item.property_type === propertyTypeSelected.value);
    const propertySubTypes = filteredArray.map(item => item.property_sub_type);
    console.log("filtered propertySubTypes", propertySubTypes);
    if (propertySubTypes.length !== 0) {
      setPropertySubType(propertySubTypes);
    } else {
      setPropertySubType([]);
    }
    // if (propertyTypeSelected.value === "Commercial") {
    //   setPropertySubType(comTypes);
    // } else if (propertyTypeSelected.value === "Residential") {
    //   setPropertySubType(resTypes);
    // } else {
    //   setPropertySubType([]);
    // }
  }, [propertyTypeSelected]);

  useEffect(() => {
    setlocationSelected({
      label: location.state.detail.location,
      value: location.state.detail.location,
    });
    setBudgetSelected({
      label: location.state.detail.budget,
      value: location.state.detail.budget,
    });
    setProjectSelected({
      label: location.state.detail.project,
      value: location.state.detail.project,
    });
    setLeadTypeSelected({
      label: location.state.detail.lead_type,
      value: location.state.detail.lead_type,
    });
    // setNotIntSelected({
    //   label: location.state.detail.not_int_reason,
    //   value: location.state.detail.not_int_reason,
    // });
    // setLostSelected({
    //   label: location.state.detail.lost_reason,
    //   value: location.state.detail.lost_reason,
    // });
    setPropertyStageSelected({
      label: location.state.detail.property_stage,
      value: location.state.detail.property_stage,
    });
    setPropertyTypeSelected({
      label: location.state.detail.property_type,
      value: location.state.detail.property_type,
    });
    setCampaignSelected({
      label: location.state.detail.campaign,
      value: location.state.detail.campaign,
    });
    setAddSetSelected({
      label: location.state.detail.addset,
      value: location.state.detail.addset,
    });
    // eslint-disable-next-line
  }, [
    locationsList,
    budgetsList,
    notIntReasonList,
    projectsList,
    lostReasonList,
    propertyTypeList,
    propertyStageList,
    leadTypeList,
    addSet,
    campaign
  ]);
  const handleCheck = () => {

    const numberExp = /^[0-9]+$/;

    if (firstNameRef.current.value === "") {
      firstNameRef.current.value =
        location.state.detail.customer_name.split(" ")[0];
    }
    if (lastNameRef.current.value === "") {
      lastNameRef.current.value =
        location.state.detail.customer_name.split(" ")[1];
    }
    if (emailRef.current.value === "") {
      emailRef.current.value =location.state.detail.email;
    }
    if (numberRef.current.value === "") {
      numberRef.current.value = location.state.detail.contact_no;
    }
    if (alternateNumberRef.current.value === "") {
      alternateNumberRef.current.value = location.state.detail.alternate_no;
    }
    if (stageSelected.label === "Select") {
      stageSelected.label = location.state.detail.stage;
    }
    if (propertyTypeSelected.label === "Select") {
      propertyTypeSelected.label = location.state.detail.property_type;
    }
    if (propertyStageSelected.label === "Select") {
      propertyStageSelected.label = location.state.detail.property_stage;
    }

    if (locationSelected.label === "Select") {
      locationSelected.label = location.state.detail.location;
    }
    if (budgetSelected.label === "Select") {
      budgetSelected.label = location.state.detail.budget;
    }
    if (projectSelected.label === "Select") {
      projectSelected.label = location.state.detail.project;
    }
    if (campaignSetSelected.label === "Select") {
      campaignSetSelected.label = location.state.detail.campaign;
    }
    if (addSetSelected.label === "Select") {
      addSetSelected.label = location.state.detail.addset;
    }
    if (oneFieldRef.current.value !== "") {
      if (!numberExp.test(oneFieldRef.current.value)) {
        dispatcher(
          showSnackbarAction(
            `${conatctHeader.find((obj: any) => obj.label === "field_one").value} contain only number!!`,
            "error"
          )
        );
        return;
      }
    }
    if (twoFieldRef.current.value !== "") {
      if (!numberExp.test(twoFieldRef.current.value)) {
        dispatcher(
          showSnackbarAction(
            `${conatctHeader.find((obj: any) => obj.label === "field_two").value} contain only number!!`,
            "error"
          )
        );
        return;
      }
    }
    if (oneFieldRef.current.value === "") {
      oneFieldRef.current.value =
        location.state.detail.field_one;
    }
    if (twoFieldRef.current.value === "") {
      twoFieldRef.current.value =
        location.state.detail.field_two;
    }
    if (threeFieldRef.current.value === "") {
      threeFieldRef.current.value =
        location.state.detail.field_three;
    }
    if (fourFieldRef.current.value === "") {
      fourFieldRef.current.value =
        location.state.detail.field_four;
    }
    if (fiveFieldRef.current.value === "") {
      fiveFieldRef.current.value =
        location.state.detail.field_five;
    }
    if (sixFieldRef.current.value === "") {
      sixFieldRef.current.value =
        location.state.detail.field_six;
    }
    if (!(leadTypeSelected.value === "Leads" || leadTypeSelected.value === "Data")) {
      dispatcher(
        showSnackbarAction(
          "Lead Type Required!!",
          "error"
        )
      );
      return;
    }
    // if (notIntSelected.label === "Select" || notIntSelected.label === "") {
    //   notIntSelected.label = location.state.detail.not_int_reason;
    // }
    // if (otherNotIntRef.current.value === "") {
    //   otherNotIntRef.current.value = location.state.detail.other_not_int_reason;
    // }
    // if (lostSelected.label === "Select") {
    //   lostSelected.label = location.state.detail.lost_reason;
    // }
    // if (otherLostRef.current.value === "") {
    //   otherLostRef.current.value = location.state.detail.other_lost_reason;
    // }
    return true;
  };
  const edit = async (e: any) => {
    e.preventDefault();
    const val = handleCheck();
    if (val) {
      setLoad(true);
      editContact(
        location.state.detail.contactId,
        firstNameRef.current.value,
        lastNameRef.current.value,
        numberRef.current.value,
        alternateNumberRef.current.value,
        stageSelected.label,
        projectSelected.label,
        budgetSelected.label,
        locationSelected.label,
        propertyTypeSelected.label,
        propertyStageSelected.label,
        leadTypeSelected.value === "Select"
          ? "Data"
          : leadTypeSelected.value,
        leadSourceSelected.value,
        addSetSelected.label,
        campaignSetSelected.label,
        oneFieldRef.current.value,
        twoFieldRef.current.value,
        threeFieldRef.current.value,
        fourFieldRef.current.value,
        fiveFieldRef.current.value,
        sixFieldRef.current.value,
        // notIntSelected.label,
        // otherNotIntRef.current.value,
        // lostSelected.label,
        // otherLostRef.current.value,
        dispatcher,
        history,
        (data) => setLoad(data),
        numberRef.current.value === location.state.detail.contact_no && alternateNumberRef.current.value === location.state.detail.alternate_no
          ? false
          : true,
        location.state.detail.organization_id,
        propertySubTypeSelected.value === "Select"
          ? ""
          : propertySubTypeSelected.value,
        countryCode.split(" ")[0],
        numberRef.current.value === location.state.detail.contact_no ? false : true,
        alternateNumberRef.current.value === location.state.detail.alternate_no ? false : true,
        emailRef.current.value
      );
    }
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };
  return (
    // <div className={styles.parent}>
    //   {load && <Loading />}
    //   <div className={styles.child}>
    //     <div className={styles.headerView}>
    //       <p className={styles.heading}> Edit Contact Details</p>
    //       <AiOutlineClose
    //         className={styles.closeIcon}
    //         size={25}
    //         onClick={() => history.replace("/")}
    //       />
    //     </div>
    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>First Name</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter first name"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={firstNameRef}
    //             value={location.state.detail.customer_name.split(" ")[0]}
    //           >
    //             <FaUser color={"#808080"} />
    //           </TextInput>
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Last Name</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter last name"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={lastNameRef}
    //             value={location.state.detail.customer_name.slice(
    //               location.state.detail.customer_name.split(" ")[0].length
    //             )}
    //           >
    //             <FaUser color={"#808080"} />
    //           </TextInput>
    //         </div>
    //       </div>
    //     </div>
    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Mobile No.</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Number"}
    //             style={
    //               userRole !== "Lead Manager"
    //                 ? { backgroundColor: "#f5f5f5" }
    //                 : { backgroundColor: "#fff" }
    //             }
    //             ref={numberRef}
    //             disabled={userRole !== "Lead Manager" ? true : false}
    //             value={location.state.detail.contact_no}
    //           />
    //         </div>
    //       </div>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Alternate No.</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Number"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={alternateNumberRef}
    //             value={location.state.detail.alternate_no}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Stage</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={stage}
    //             disable={true}
    //             selectedValue={stageSelected}
    //             setSelectedValue={(value) => {
    //               setStageSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Project</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={projectsList}
    //             selectedValue={projectSelected}
    //             setSelectedValue={(value) => {
    //               setProjectSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Location</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={locationsList}
    //             selectedValue={locationSelected}
    //             setSelectedValue={(value) => {
    //               setlocationSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Budget</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={budgetsList}
    //             selectedValue={budgetSelected}
    //             setSelectedValue={(value) => {
    //               setBudgetSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Property Type</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={propertyTypeList}
    //             selectedValue={propertyTypeSelected}
    //             setSelectedValue={(value) => {
    //               setPropertyTypeSelected(value);
    //             }}
    //           />
    //         </div>
    //         </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Property Stage</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={propertyStageList}
    //             selectedValue={propertyStageSelected}
    //             setSelectedValue={(value) => {
    //               setPropertyStageSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     {/* <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Not Interested Reason</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={notIntReasonList}
    //             selectedValue={notIntSelected}
    //             setSelectedValue={(value) => {
    //               setNotIntSelected(value);
    //             }}
    //             disable={userRole !== "Lead Manager" ? true : false}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Other Not Interested Reason</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Other Reason"}
    //             style={
    //               userRole !== "Lead Manager"
    //                 ? { backgroundColor: "#f5f5f5" }
    //                 : { backgroundColor: "#fff" }
    //             }
    //             ref={otherNotIntRef}
    //             disabled={userRole !== "Lead Manager" ? true : false}
    //             value={location.state.detail.other_not_int_reason}
    //           ></TextInput>
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Lost Reason</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={lostReasonList}
    //             selectedValue={lostSelected}
    //             setSelectedValue={(value) => {
    //               setLostSelected(value);
    //             }}
    //             disable={userRole !== "Lead Manager" ? true : false}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Other Lost Reason</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Other Reason"}
    //             style={
    //               userRole !== "Lead Manager"
    //                 ? { backgroundColor: "#f5f5f5" }
    //                 : { backgroundColor: "#fff" }
    //             }
    //             ref={otherLostRef}
    //             disabled={userRole !== "Lead Manager" ? true : false}
    //             value={location.state.detail.other_lost_reason}
    //           ></TextInput>
    //         </div>
    //       </div>
    //     </div> */}

    //     <div className={styles.box2}>
    //       {userRole === "Lead Manager" && (
    //         <div className={styles.divide}>
    //           <div className={styles.title}>
    //             <p className={styles.one}>Lead Source</p>
    //             <p className={styles.two}></p>
    //           </div>
    //           <div style={{ marginTop: "7px" }}>
    //             <Dropdown
    //               option={leadSourceList}
    //               selectedValue={leadSourceSelected}
    //               setSelectedValue={(value) => {
    //                 setLeadSourceSelected(value);
    //               }}
    //             />
    //           </div>
    //         </div>
    //       )}

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Property Sub Type</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={propertySubType}
    //             selectedValue={propertySubTypeSelected}
    //             setSelectedValue={(value) => {
    //               setPropertySubTypeSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title} style={{ marginBottom: "3%" }}>
    //           <p className={styles.one}>Country Code</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <Select
    //             options={choice()}
    //             onChange={(data) => {
    //               if (data) {
    //                 setCountryCode(data.label);
    //               }
    //             }}
    //             isSearchable={true}
    //             value={{ label: countryCode, value: countryCode }}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //     <button className={styles.button} onClick={() => edit()}>
    //       Update
    //     </button>
    //   </div>
    // </div>
    <div className={styles.parent} style={{ ...containerStyles, marginTop: "4%" }}>
      {load && <Loading />}
      <div
        // className={styles.headerView}
        className="d-flex flex-row w-100 px-4 pt-4"
      >
        <h5
        // className={styles.heading}
        > Edit Contact Details</h5>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/")}
        />
      </div>
      <div
        // className={styles.child}
        className="w-100 d-flex flex-column gap-2 px-4"
        style={{ maxHeight: "700px" }}
      >
        <form className="card pt-1 pb-4 px-4 shadow rounded mt-2 pt-4" style={{
          ...cardContainerStyles,
          maxHeight: `${maxHeight}px`,
          overflowX: "hidden",
          overflowY: "scroll",
          marginBottom: "20px",
        }}>
          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>First Name</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label="First Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={firstNameRef}
                  value={location.state.detail.customer_name.split(" ")[0]}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Last Name</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label="Last Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={lastNameRef}
                  value={location.state.detail.customer_name.slice(
                    location.state.detail.customer_name.split(" ")[0].length
                  )}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Mobile No.</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "contact_no").value} required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={
                    userRole !== "Lead Manager"
                      ? { backgroundColor: "#f5f5f5", height: "45px", width: "140px" }
                      : { backgroundColor: "#fff", height: "45px", width: "140px" }
                  }
                  ref={numberRef}
                  disabled={userRole !== "Lead Manager" ? true : false}
                  value={location.state.detail.contact_no}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Alternate No.</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "alternate_no").value} required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={alternateNumberRef}
                  value={location.state.detail.alternate_no}
                />
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Stage</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "stage").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={stage}
                  disable={true}
                  selectedValue={stageSelected}
                  setSelectedValue={(value) => {
                    setStageSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Project</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "project").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={projectsList}
                  selectedValue={projectSelected}
                  setSelectedValue={(value) => {
                    setProjectSelected(value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Location</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "location").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={locationsList}
                  selectedValue={locationSelected}
                  setSelectedValue={(value) => {
                    setlocationSelected(value);
                  }}
                />
              </div>
            </div>

            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Budget</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "budget").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={budgetsList}
                  selectedValue={budgetSelected}
                  setSelectedValue={(value) => {
                    setBudgetSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Property Type</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "property_type").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyTypeList}
                  selectedValue={propertyTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertyTypeSelected(value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Property Stage</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "property_stage").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyStageList}
                  selectedValue={propertyStageSelected}
                  setSelectedValue={(value) => {
                    setPropertyStageSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
            <p className={styles.one}>Property Sub Type</p>
            <p className={styles.two}></p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "property_sub_type").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertySubType}
                  selectedValue={propertySubTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertySubTypeSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title} style={{ marginBottom: "3%" }}>
            <p className={styles.one}>Country Code</p>
            <p className={styles.two}>*</p>
          </div> */}
              <Label label={conatctHeader.find((obj: any) => obj.label === "country_code").value} required={true} />
              <div style={{ color: "#000", ...selectInputContainerStyles }}>
                <Select
                  className={styles.select}
                  options={choice()}
                  onChange={(data) => {
                    if (data) {
                      setCountryCode(data.label);
                    }
                  }}
                  isSearchable={true}
                  value={{ label: countryCode, value: countryCode }}
                />
              </div>
            </div>
          </div>
          {/* <div className={styles.box2}>
        <div className={styles.divide}>
          <div className={styles.title}>
            <p className={styles.one}>Not Interested Reason</p>
            <p className={styles.two}>*</p>
          </div>
          <div style={{ marginTop: "7px" }}>
            <Dropdown
              option={notIntReasonList}
              selectedValue={notIntSelected}
              setSelectedValue={(value) => {
                setNotIntSelected(value);
              }}
              disable={userRole !== "Lead Manager" ? true : false}
            />
          </div>
        </div>

        <div className={styles.divide}>
          <div className={styles.title}>
            <p className={styles.one}>Other Not Interested Reason</p>
            <p className={styles.two}>*</p>
          </div>
          <div>
            <TextInput
              title={"Enter Other Reason"}
              style={
                userRole !== "Lead Manager"
                  ? { backgroundColor: "#f5f5f5" }
                  : { backgroundColor: "#fff" }
              }
              ref={otherNotIntRef}
              disabled={userRole !== "Lead Manager" ? true : false}
              value={location.state.detail.other_not_int_reason}
            ></TextInput>
          </div>
        </div>
      </div>

      <div className={styles.box2}>
        <div className={styles.divide}>
          <div className={styles.title}>
            <p className={styles.one}>Lost Reason</p>
            <p className={styles.two}>*</p>
          </div>
          <div style={{ marginTop: "7px" }}>
            <Dropdown
              option={lostReasonList}
              selectedValue={lostSelected}
              setSelectedValue={(value) => {
                setLostSelected(value);
              }}
              disable={userRole !== "Lead Manager" ? true : false}
            />
          </div>
        </div>

        <div className={styles.divide}>
          <div className={styles.title}>
            <p className={styles.one}>Other Lost Reason</p>
            <p className={styles.two}>*</p>
          </div>
          <div>
            <TextInput
              title={"Enter Other Reason"}
              style={
                userRole !== "Lead Manager"
                  ? { backgroundColor: "#f5f5f5" }
                  : { backgroundColor: "#fff" }
              }
              ref={otherLostRef}
              disabled={userRole !== "Lead Manager" ? true : false}
              value={location.state.detail.other_lost_reason}
            ></TextInput>
          </div>
        </div>
      </div> */}

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "lead_type").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={leadTypeList}
                  selectedValue={leadTypeSelected}
                  setSelectedValue={(value) => {
                    setLeadTypeSelected(value);
                  }}
                />
              </div>
            </div>
            {userRole === "Lead Manager" && (
              <>
                <div className={styles.divide}>
                  <Label label={conatctHeader.find((obj: any) => obj.label === "lead_source").value} required={false} />
                  <div style={{ ...selectInputContainerStyles }}>
                    <Dropdown
                      option={leadSourceList}
                      selectedValue={leadSourceSelected}
                      setSelectedValue={(value) => {
                        setLeadSourceSelected(value);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.divide}>
                  <Label label={conatctHeader.find((obj: any) => obj.label === "addset").value} required={false} />
                  <div style={{ ...selectInputContainerStyles }}>
                    {console.log("addSetaddSet", addSet)}
                    <Dropdown
                      option={addSet}
                      selectedValue={addSetSelected}
                      setSelectedValue={(value) => {
                        setAddSetSelected(value);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.box2}>
          {userRole === "Lead Manager" && (
            <>
              
                <div className={styles.divide}>
                  <Label label={conatctHeader.find((obj: any) => obj.label === "campaign").value} required={false} />
                  <div style={{ ...selectInputContainerStyles }}>
                    <Dropdown
                      option={campaign}
                      selectedValue={campaignSetSelected}
                      setSelectedValue={(value) => {
                        setCampaignSelected(value);
                      }}
                    />
                  </div>
                </div>
              
            </>
          )}
          <div className={styles.divide}>
              <Label label="Email" required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px",width:"140px" }}
                  ref={emailRef}
                  value={location.state.detail.email}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_one").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={oneFieldRef}
                  value={location.state.detail.field_one}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_two").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={twoFieldRef}
                  value={location.state.detail.field_two}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_three").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={threeFieldRef}
                  value={location.state.detail.field_three}
                />
              </div>
            </div>
          </div>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_four").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fourFieldRef}
                  value={location.state.detail.field_four}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_five").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fiveFieldRef}
                  value={location.state.detail.field_five}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_six").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={sixFieldRef}
                  value={location.state.detail.field_six}
                />
              </div>
            </div>
          </div>
          <button
            // className={styles.button}
            style={{ backgroundColor: "#272944" }}
            className={styles.button}
            onClick={(e: any) => edit(e)}>
            Update
          </button>
        </form>

      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userRole: state.user.role,
    theme: state.theme.isLightMode,
    user: state.user.data,
    conatctHeader: state.contactHeaderList.data,
    authToken: state.user.authToken
  };
};

export default connect(mapStateToProps)(EditContact);
