import React, { FunctionComponent, useEffect, useState } from "react";
import ApiTopBar from "../../Components/TopBar/ApiTopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch, useSelector } from "react-redux";
import OwnerModal from "../../Components/Modals/ChangeOwnerModal/ChangeOwnerModal";
import ImportContactsModal from "../../Components/Modals/ImportModal/ImportContactsModal";
import {
  // ContactColumns,
  // CONTACT_COLUMNS,
  datesField,
  booleanObject,
  ContactColumns,
} from "../../Values/tables";
import Firebase from "firebase/app";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import { getDateImportString, getDateString, searchContacts } from "../../Values/utils";
import ApiCustomTable from "../../Components/CustomTable/ApiCustomTable";
import BulkEditModal from "../../Components/Modals/BulkEditModal/BulkEditModal";
import { useLocation } from "react-router";
import ImportCallLogsModal from "../../Components/Modals/ImportModal/ImportCallLogsModal";
import ImportNotesModal from "../../Components/Modals/ImportModal/ImportNotesModal";
import ImportTasksModal from "../../Components/Modals/ImportModal/ImportTasksModal";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import {
  setFilterObject,
  setRefreshContacts,
  showSnackbarAction,
  clearAllFilters,
  setContactHeaderLists,
  updateFilterStatus,
  setDeleteLeadsStatus
} from "../../Redux/actions";
import NewImportContactModal from "../../Components/Modals/ImportModal/NewImportContactModal";
import DeleteSelectedModal from "../../Components/Modals/DeleteSelectedModal/DeleteSelectedModal";
import { db, firestore } from "../../Firebase";
import { themeColors } from "../../Components/theme";

type props = {
  history: any;
  user: any;
  contacts: {
    data: any;
  };
  organizationUsers: any;
  role: any;
  teamLeadUsers: any;
  filterObject: any;
  filterSort: any;
  refreshContacts: boolean;
  searchString: string;
  theme: any;
  conatctHeader: any;
  authToken: any;
  clerFilterStatus: any
  deleteLeadsStatus: any
};
let usersList: any[] = [];
let isFinished = false;
const UserPanel: FunctionComponent<props> = ({
  history,
  user,
  contacts,
  organizationUsers,
  role,
  teamLeadUsers,
  filterObject,
  filterSort,
  refreshContacts,
  searchString,
  theme,
  conatctHeader,
  authToken,
  clerFilterStatus,
  deleteLeadsStatus
}) => {
  // let allcontactcolumns = ContactColumns();
  console.log("Print All COntact Columns :");
  const location: any = useLocation();
  const [showImportModal, setShowImportModal] = useState(false);
  const [callLogsImportModal, setCallLogsImportModal] = useState(false);
  const [notesImportModal, setNotesImportModal] = useState(false);
  const [tasksImportModal, setTasksImportModal] = useState(false);
  const [show, setShow] = useState(false);
  const [filterCount, setFilterCount] = useState<any>({});
  const [deletePopup, setDeletePopop] = useState(false);

  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [owner, setOwner] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [transferStatusTrueRows, setTransferStatusTrueRows] = useState<any[]>(
    []
  );
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [searchedItem, setsearchedItem] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [lastPage, setLastPage] = useState(-1);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allLeads, setAllLeads] = useState(false);
  const [apiCallFlag, setApiCallFlag] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>(undefined);
  const [userBranchMap, setBranchMap] = useState<any>(undefined);
  const [userTeamMap, setTeamMap] = useState<any>(undefined);

  const [freshFilterValue, setFreshFilterValue] = useState<any>();

  let dispatcher = useDispatch();

  // useEffect(()=>{
  //   dispatcher(clearAllFilters({}));
  // },[])



  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {

    if (isFinished === true) {
      return;
    } else {
      setLoad(true);
      const filters: { [key: string]: any[] } = {};
      let feild;
      filters["transfer_status"] = [false];
      Object.keys(filterObject).forEach((item) => {
        if (filterObject && filterObject[item].length > 0) {
          if (item === "is_button_called" || item === "transfer_status" || item === "associate_status" || item === "source_status") {
            if (filterObject[item].length === 2) {
              filters[item] = [true, false];
            } else {
              filters[item] =
                filterObject[item][0] === "True" ? [true] : [false];
            }
          } else {
            //FIXME: Here contact_no is getting set which is coming from behind
            //below code checks if item con_no but val not number, then use con_email instead
            if (item === "contact_no") {
            } else filters[item] = filterObject[item];
          }
        }
      });
      if (searchString === "") {
        feild = "customer_name";
      } else {
        if (searchString.match(/^[0-9]+$/) != null) {
          feild = "contact_no";
        } else {
          feild = "customer_name";
        }
      }
      setFilterCount(filters)
      let records = localPageSize ? localPageSize : pageSize;
      const apiData = {
        uid: user.uid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: "-1" }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        filter: filters,
      };
      try {
        axios.defaults.headers.common["x-access-token"] =
          authToken
        const res = await axios.post(url + "/leads/search", apiData);
        let data: any[] = res.data;
        if (data.length < records && paginate) {
          isFinished = true;
          setLastPage(page);
        }
        data.forEach((lead) => {
          lead.contactId = lead.Id;
          lead["reporting_to"] = userMap[lead.contact_owner_email];
          lead["branch"] = userBranchMap[lead.contact_owner_email];
          lead["team"] = userTeamMap[lead.contact_owner_email];
          delete lead["Id"];
          Object.keys(lead).forEach((key) => {
            if (datesField.includes(key) && lead[key] !== "") {
              if (lead[key] !== null) {
                lead[key] = Firebase.firestore.Timestamp.fromDate(
                  moment(lead[key]).toDate()
                );
              } else {
                lead[key] = "";
              }
            }
          });
        });
        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
        } else {
          setFilterData(data);
        }
        setLoad(false);
      } catch (e) {
        setLoad(false);
      }
    }
  };


  const createUsersList = (email: string, users: any[], uid: boolean) => {
    users.map((item: any) => {
      if (usersList.includes(item)) {
        return;
      } else {
        if (item.reporting_to === email && item.status === "ACTIVE") {
          if (!usersList.includes(item)) {
            usersList.push(item);
          }
          createUsersList(item.user_email, users, uid);
        }
      }
    });
  };

  useEffect(() => {
    let trueStatusList: any = [];
    selectedRowsData.forEach((row) => {
      Object.keys(row).forEach((item) => {
        if (item === "transfer_status") {
          trueStatusList.push(row[item]);
        }
      });
    });
    setTransferStatusTrueRows(trueStatusList);
  }, [selectedRowsData]);

  useEffect(() => {
    if (organizationUsers) {
      let uids: { [key: string]: string } = {};
      let uidsBranch: { [key: string]: string } = {};
      let uidsTeam: { [key: string]: string } = {};

      organizationUsers.forEach((user: any) => {
        uids[user.user_email] = user.reporting_to;
        uidsBranch[user.user_email] = user.branch;
        uidsTeam[user.user_email] = user.team;
      });
      setBranchMap(uidsBranch)
      setUserMap(uids);
      setTeamMap(uidsTeam)
    }
  }, [organizationUsers]);


  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if (userMap === undefined || userTeamMap === undefined || userBranchMap === undefined) {
      setFilterData([]);
      return;
    }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    const filterStageValue = filterObject.stage?.length === 1 && filterObject?.stage?.filter((item: any) => item === "FRESH");
    filterStageValue ? setFreshFilterValue(filterStageValue[0]) : setFreshFilterValue('');
    ////////////////////////////change code for refresh code ///////////
    const isAnyKeyArrayNotEmpty = Object.keys(filterObject).some(key => filterObject[key].length > 0);
    const refershFlag = localStorage.getItem("refresh");
    if (isAnyKeyArrayNotEmpty) {
      // setApiCallFlag(false);
      if ((authToken)) {
        setApiCallFlag(true);
        dispatcher(updateFilterStatus(false));
        callApi(false, pageSize, 1);
      }
    }
    else if (clerFilterStatus && apiCallFlag) {
      callApi(false, pageSize, 1);
    }
    else {
      if ((authToken && !apiCallFlag)) {
        dispatcher(updateFilterStatus(false));
        setApiCallFlag(true);
        callApi(false, pageSize, 1);
      }
    }
  }, [user, filterObject, filterSort, allLeads, userMap, userBranchMap, authToken]);

  useEffect(() => {
    if (searchString!="") {
      callApi(false, pageSize, 1);
    }
  }, [searchString]);

  useEffect(() => {
    if(deleteLeadsStatus===true){
      callApi(false, pageSize, 1);
      dispatcher(setDeleteLeadsStatus(false));
    }
  }, [deleteLeadsStatus]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        if (authToken) {
          callApi(false, pageSize);
        }
      } else {
        if (authToken) {
          callApi(true, pageSize);
        }
      }
    }
  }, [page, pageSize, authToken]);

  useEffect(() => {
    if (refreshContacts === true) {
      if (authToken) {
        callApi();
        getContactsFilter();
        dispatcher(setRefreshContacts(false));
        const filterStageValue = filterObject.stage?.length === 1 && filterObject?.stage?.filter((item: any) => item === "FRESH");
        filterStageValue ? setFreshFilterValue(filterStageValue[0]) : setFreshFilterValue('');
      }
    }
  }, [refreshContacts, authToken]);

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if (authToken) {
      contactCountApi()
    }
  }, [refreshContacts, user.uid, filterCount, authToken]);

  const contactCountApi = async () => {
    const { reporting_to, team, branch, ...otherFilterCount } = filterCount;
    axios.defaults.headers.common["x-access-token"] =
      authToken
    const res = await axios.post(url + "/leads/contacttotalcount", {
      uid: user.uid,
      leadFilter: otherFilterCount,
      leadUserFilter: {
        reporting_to,
        team,
        branch
      }
    });
    setTotalCounts(res.data.total);
  };

  const getContactsFilter = async () => {
    axios.defaults.headers.common["x-access-token"] =
      authToken
    const res = await axios.post(url + "/leads/filterValues", {
      uid: user.uid,
    });
    if (res.data[0]) {
      dispatcher(
        setFilterObject({
          ...res.data[0],
          'lead_type': ['Data', 'Leads'],
          ...booleanObject,
        })
      );
    } else {
      dispatcher(setFilterObject({}));
    }
  };
  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      return;
    }
    if (authToken) {
      getContactsFilter();
    }
  }, [user, authToken]);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  useEffect(() => {
    let mapReportingTo: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.forEach((item: any) => {
        if (mapReportingTo[item.uid] === undefined) {
          mapReportingTo[item.uid] = item.reporting_to;
        }
      });
    }

    if (allContacts) {
      allContacts.forEach((item: any) => {
        if (item.uid === "") {
          item["reporting_to"] = "";
        } else {
          item["reporting_to"] = mapReportingTo[item.uid]
            ? mapReportingTo[item.uid]
            : "";
        }
      });
    }

    // eslint-disable-next-line
  }, [allContacts]);

  const setUsersList = () => {
    if (role === "Lead Manager") {
      organizationUsers.map((item: any) => {
        if (usersList.includes(item)) {
          return;
        } else {
          if (item.status === "ACTIVE") {
            usersList.push(item);
          }
        }
      });
    } else {
      createUsersList(user.user_email, organizationUsers, false);
    }
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setOwner(true);
    }
    else if (selectedRows.length === 0) {
      setOwner(false);
    }
  }, [selectedRows]);

  const clearSelectedRowsData = () => {
    setSelectedRowsData([]);
    setSelectedRows([]);
  };
  const [notesList, setNotesList] = useState<[]>([]);

  const exportFile = () => {
    let data: any[] = [];
    if (selectedRowsData.length === 0) {
    } else {
      selectedRowsData.forEach((item, index) => {
        //Fetching Notes from firebase Code Start
        firestore
          .collection("contactResources")
          .doc(item.contactId)
          .onSnapshot((resources) => {
            if (resources) {
              const rData = resources.data();
              if (rData?.notes) {
                let allNotes: any = "";
                rData.notes.map((Mnote: any, noteIndex: any) => {
                  console.log("rData.notes", Mnote)
                  //add multiple notes
                  if (Mnote.note) {
                    let str: any = Mnote.note;
                    let str1: any = Mnote.userEmail;
                    let updatedNote = str.replace(/[\r\n]+/gm, " ");
                    let updatedEmail
                    { str1 ? updatedEmail = str1.replace(/[\r\n]+/gm, " ") : updatedEmail = '' }
                    { str1 ? allNotes += updatedNote + " ( " + updatedEmail + " )  " + "||  " : allNotes += updatedNote + " , " }
                  } else {
                    allNotes += "";
                  }
                });
                //adding Notes in selectedRowsData array
                item["Notes"] = allNotes;
              } else {
                item["Notes"] = "";
              }
            }
          })

        interface DataObj {
          label: any;
          value: any;
        }

        // const dbData=[{"label": "customer_name","value": "User Name"},{"label": "country_code","value": "Country Code"},{"label": "contact_no","value": "Mobile No."},{"label": "alternate_no","value": "Alternate No."},{"label": "email","value": "Email Id"},{"label": "stage","value": "Stage"},{"label": "contact_owner_email","value": "Owner"},{"label": "lead_source","value": "Lead Source"},{"label": "budget","value": "Budget"},{"label": "call_back_reason","value": "Call back reason"},{"label": "project","value": "Project"},{"label": "property_type","value": "Property type"},{"label": "property_sub_type","value": "Property Sub Type"},{"label": "property_stage","value": "Property Stage"},{"label": "location","value": "Location"},{"label": "lost_reason","value": "Lost reason"},{"label": "not_int_reason","value": "Not Interested Reason"},{"label": "other_lost_reason","value": "Other Lost Reason"},{"label": "other_not_int_reason","value": "Other Not interested reason"},{"label": "next_follow_up_type","value": "Next follow up type"},{"label": "next_follow_up_date_time","value": "Next follow up date and time"},{"label": "created_by","value": "Created By"},{"label": "created_at","value": "Created At"},{"label": "lead_assign_at","value": "Lead Assign At"},{"label": "stage_change_at","value": "Stage Change At"},{"label": "addset","value": "Addset"},{"label": "campaign","value": "Campaign"},{"label": "inventory_type","value": "Inventory Type"},{"label": "notes","value": "Notes"},{"label": "lead_type","value": "Lead Type"}];
        // console.log("Json pass for export:", conatctHeader);
        const dbData = conatctHeader;
        // console.log("DB Headers Data printed :",dbData);
        // const foundNotes: any = dbData.find((obj: DataObj) => obj.label === "notes")?.value;
        const foundCustomerName: any = dbData.find((obj: DataObj) => obj.label === "customer_name")?.value;
        const foundCountryCode: any = dbData.find((obj: DataObj) => obj.label === "country_code")?.value;
        const foundMobileNo: any = dbData.find((obj: DataObj) => obj.label === "contact_no")?.value;
        const foundAlternateMobileNo: any = dbData.find((obj: DataObj) => obj.label === "alternate_no")?.value;
        const foundEmailID: any = dbData.find((obj: DataObj) => obj.label === "email")?.value;
        const foundStage: any = dbData.find((obj: DataObj) => obj.label === "stage")?.value;
        const foundOwner: any = dbData.find((obj: DataObj) => obj.label === "contact_owner_email")?.value;
        const foundLeadType: any = dbData.find((obj: DataObj) => obj.label === "lead_type")?.value;
        const foundPropertyType: any = dbData.find((obj: DataObj) => obj.label === "property_type")?.value;
        const foundPropertySubType: any = dbData.find((obj: DataObj) => obj.label === "property_sub_type")?.value;
        const foundPropertyStage: any = dbData.find((obj: DataObj) => obj.label === "property_stage")?.value;
        const foundLocation: any = dbData.find((obj: DataObj) => obj.label === "location")?.value;
        const foundProject: any = dbData.find((obj: DataObj) => obj.label === "project")?.value;
        const foundNotInterestedReason: any = dbData.find((obj: DataObj) => obj.label === "not_int_reason")?.value;
        const foundLostreason: any = dbData.find((obj: DataObj) => obj.label === "lost_reason")?.value;
        const foundOtherNotInterestedReason: any = dbData.find((obj: DataObj) => obj.label === "other_not_int_reason")?.value;
        const foundOtherLostReason: any = dbData.find((obj: DataObj) => obj.label === "other_lost_reason")?.value;
        const foundNextfollowuptype: any = dbData.find((obj: DataObj) => obj.label === "next_follow_up_type")?.value;
        const foundNextFollowupDateAndTime: any = dbData.find((obj: DataObj) => obj.label === "next_follow_up_date_time")?.value;
        const foundAddset: any = dbData.find((obj: DataObj) => obj.label === "addset")?.value;
        const foundCampaign: any = dbData.find((obj: DataObj) => obj.label === "campaign")?.value;
        // const foundInventoryType: any = dbData.find((obj: DataObj) => obj.label === "inventory_type")?.value;
        const foundBudget: any = dbData.find((obj: DataObj) => obj.label === "budget")?.value;
        const foundLeadSource: any = dbData.find((obj: DataObj) => obj.label === "lead_source")?.value;
        const foundCreatedBy: any = dbData.find((obj: DataObj) => obj.label === "created_by")?.value;
        const foundCreatedAt: any = dbData.find((obj: DataObj) => obj.label === "created_at")?.value;
        const foundLeadAssignAt: any = dbData.find((obj: DataObj) => obj.label === "lead_assign_time")?.value;
        const foundFieldOne: any = dbData.find((obj: DataObj) => obj.label === "field_one")?.value;
        const foundFieldTwo: any = dbData.find((obj: DataObj) => obj.label === "field_two")?.value;
        const foundFieldThree: any = dbData.find((obj: DataObj) => obj.label === "field_three")?.value;
        const foundFieldFour: any = dbData.find((obj: DataObj) => obj.label === "field_four")?.value;
        const foundFieldFive: any = dbData.find((obj: DataObj) => obj.label === "field_five")?.value;
        const foundFieldSix: any = dbData.find((obj: DataObj) => obj.label === "field_six")?.value;
        const foundCallBackReason: any = dbData.find((obj: DataObj) => obj.label === "call_back_reason")?.value;
        const foundPreviousOwner: any = dbData.find((obj: DataObj) => obj.label === "previous_owner")?.value;
        const foundTransferStatus: any = dbData.find((obj: DataObj) => obj.label === "transfer_status")?.value;
        const foundTransferReason: any = dbData.find((obj: DataObj) => obj.label === "transfer_reason")?.value;
        const foundAssociateStatus: any = dbData.find((obj: DataObj) => obj.label === "associate_status")?.value;
        const foundSourceStatus: any = dbData.find((obj: DataObj) => obj.label === "source_status")?.value;
        const foundPreviousOwner1: any = dbData.find((obj: DataObj) => obj.label === "previous_owner_1")?.value;
        const foundPreviousOwner2: any = dbData.find((obj: DataObj) => obj.label === "previous_owner_2")?.value;
        const foundPreviousStage1: any = dbData.find((obj: DataObj) => obj.label === "previous_stage_1")?.value;
        const foundPreviousStage2: any = dbData.find((obj: DataObj) => obj.label === "previous_stage_2")?.value;
        const foundTransferBy1: any = dbData.find((obj: DataObj) => obj.label === "transfer_by_1")?.value;
        const foundTransferBy2: any = dbData.find((obj: DataObj) => obj.label === "transfer_by_2")?.value;
        const foundStageChangeAt: any = dbData.find((obj: DataObj) => obj.label === "stage_change_at")?.value;
        const foundCallResponseTime: any = dbData.find((obj: DataObj) => obj.label === "call_response_time")?.value;

        // const customerName="abcd"
        //Fetching Notes from firebase code End
        // console.log("Item element Print :",item);
        data.push({
          // [foundNotes]: item.Notes,
          [foundCustomerName]: item.customer_name,
          [foundCountryCode]: item.country_code,
          [foundMobileNo]: item.contact_no,
          [foundAlternateMobileNo]: item.alternate_no,
          [foundEmailID]: item.email,
          [foundStage]: item.stage,
          [foundOwner]: item.contact_owner_email,
          "Reporting To": item?.reporting_to,
          "Branch": item?.branch,
          "Team": item?.team,
          [foundLeadType]: item?.lead_type,
          [foundCallBackReason]: item.call_back_reason,
          [foundPropertyType]: item.property_type,
          [foundPropertySubType]: item.property_sub_type,
          [foundPropertyStage]: item.property_stage,
          [foundLocation]: item.location,
          [foundProject]: item.project,
          [foundNotInterestedReason]: item.not_int_reason,
          [foundLostreason]: item.lost_reason,
          [foundOtherNotInterestedReason]: item.other_not_int_reason,
          [foundOtherLostReason]: item.other_lost_reason,
          [foundPreviousOwner]: item.previous_owner,
          [foundNextfollowuptype]: item.next_follow_up_type,
          [foundNextFollowupDateAndTime]: getDateImportString(
            item.next_follow_up_date_time
          ),
          [foundAddset]: item.addset,
          [foundCampaign]: item.campaign,
          // [foundInventoryType]: item.inventory_type,
          [foundBudget]: item.budget,
          [foundLeadSource]: item.lead_source,
          [foundTransferStatus]: item.transfer_status,
          [foundTransferReason]: item.transfer_reason,
          [foundAssociateStatus]: item.associate_status,
          [foundSourceStatus]: item.source_status,
          [foundPreviousOwner1]: item.previous_owner_1,
          [foundPreviousOwner2]: item.previous_owner_2,
          [foundPreviousStage1]: item.previous_stage_1,
          [foundPreviousStage2]: item.previous_stage_2,
          [foundTransferBy1]: item.transfer_by_1,
          [foundTransferBy2]: item.transfer_by_2,
          "Modified At": getDateImportString(item.stage_change_at),
          [foundStageChangeAt]: getDateImportString(item.stage_change_at),
          [foundCreatedBy]: item.created_by,
          [foundCreatedAt]: getDateImportString(item.created_at),
          [foundLeadAssignAt]: getDateImportString(item.lead_assign_time),
          [foundCallResponseTime]: getDateImportString(item.call_response_time),
          [foundFieldOne]: item.field_one,
          [foundFieldTwo]: item.field_two,
          [foundFieldThree]: item.field_three,
          [foundFieldFour]: item.field_four,
          [foundFieldFive]: item.field_five,
          [foundFieldSix]: item.field_six,
          "Notes": item?.Notes,
        });
      });
    }
    console.log("After pass data console final data:", data);
    return data;
  };
  exportFile();

  const topBarStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, width: "97%" }
  const parentStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark }

  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "4%", ...parentStyles }}>
        {load === true && <Loading />}
        <div className={commonStyle.topBar} style={{ marginTop: "0px", ...topBarStyles }}>
          <ApiTopBar
            history={history}
            title={"Add Contacts"}
            path={"/addContacts"}
            onClick={() => {
              setShowImportModal(true);
            }}
            onChange={(val) => setsearchedItem(val)}
            owner={owner}
            deleteSelected={() => {
              setDeletePopop(true);
            }}
            changeOwner={() => {
              if (selectedRowsData.length > 200) {
                dispatcher(
                  showSnackbarAction(
                    'You can transfer a maximum of 200 records',
                    'error'
                  )
                );
              } else {
                setUsersList();
                setShow(true);
              }
            }}

            filterText={"Status"}
            setColumnModal={(data) => setColumnModal(data)}
            show={true}
            showStatusBox={true}
            onExport={exportFile}
            setBulkEdit={() => {
              setShowBulkEdit(true);
            }}
            onCallLogsImport={() => {
              setCallLogsImportModal(true);
            }}
            onNotesImport={() => {
              setNotesImportModal(true);
            }}
            onTasksImport={() => {
              setTasksImportModal(true);
            }}
            searchedString={searchString}
            setAllLeads={(data) => setAllLeads(data)}
            transferLeads={transferStatusTrueRows}
          />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
          <ApiCustomTable
            tableColumns={ContactColumns(conatctHeader)}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="User"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            selectedRowsData={selectedRowsData}
            setSelectedRowsData={(data) => setSelectedRowsData(data)}
            setPage={(val) => setPage(val)}
            setPageSize={(val) => setPageSize(val)}
            callApi={callApi}
            pageSize={pageSize}
            page={page}
            isFinished={isFinished}
            totalCount={totalCounts}
            lastPage={lastPage}
          />
          {show && (
            <OwnerModal
              open={show}
              close={() => {
                setShow(false);
              }}
              usersList={usersList}
              rowsSelected={selectedRowsData}
              clearSelectedRowsData={clearSelectedRowsData}
              organization_id={user.organization_id}
              freshFilterValue={freshFilterValue}

            />
          )}
          {deletePopup && (
            <DeleteSelectedModal
              data={selectedRowsData}
              clearSelectedRowsData={clearSelectedRowsData}
              open={deletePopup}
              history={history}
              close={() => {
                setDeletePopop(false);

              }}

            />
          )}
          {showImportModal && (
            <NewImportContactModal
              open={showImportModal}
              close={() => setShowImportModal(false)}
              organization_id={user.organization_id}
              history={history}
              usersList={organizationUsers}
              user={user}
            />
          )}
          {callLogsImportModal && (
            <ImportCallLogsModal
              open={callLogsImportModal}
              close={() => setCallLogsImportModal(false)}
              organization_id={user.organization_id}
              usersList={organizationUsers}
            />
          )}

          {notesImportModal && (
            <ImportNotesModal
              open={notesImportModal}
              close={() => setNotesImportModal(false)}
            />
          )}
          {tasksImportModal && (
            <ImportTasksModal
              open={tasksImportModal}
              close={() => setTasksImportModal(false)}
              organization_id={user.organization_id}
              usersList={organizationUsers}
              user={user}
            />
          )}
          {showBulkEdit && (
            <BulkEditModal
              open={showBulkEdit}
              organization_id={user.organization_id}
              close={() => {
                setShowBulkEdit(false);
                clearSelectedRowsData();
              }}
              rowsData={selectedRowsData}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    refreshContacts: state.refresh.refreshContacts,
    searchString: state.searchItem.contactsSearchString,
    theme: state.theme.isLightMode,
    conatctHeader: state.contactHeaderList.data,
    authToken: state.user.authToken,
    clerFilterStatus: state.user.filterClearStatus,
    deleteLeadsStatus: state.deleteLeadsStatusData.deleteLeadsStatus,
  };
};

export default connect(mapStateToProps)(UserPanel);
