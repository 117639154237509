import React, { useState, useEffect } from "react";
import styles from "./Resources.module.css";
import ImportImageModal from "../../Components/Modals/ImportModal/ImportImageModal";
import { connect, useDispatch } from "react-redux";
import AddResources from "../../Components/Modals/AddResourcesModal/AddResourceModal";
import {
  fetchResources,
  createLocation,
  createBudget,
  deleteCarouselImage,
  createLeadSource,
  deleteLocation,
  deleteBudget,
  deleteLeadSource,
  editLocation,
  editLeadSource,
  editBudget,
  createCommercialType,
  createResidentialType,
  editResType,
  editCommType,
  deleteResidential,
  deleteComType,
  createTransferReason,
  createPropertyType,
  createPropertyStage,
  createPropertySubType,
  editTransferReason,
  deleteTransferReasonProcess,
  editPropertyStage,
  deletePropertyStageProcess,
  editPropertyType,
  deletePropertyTypeProcess,
  deletePropertySubTypeProcess,
  editPropertySubType,
} from "../../Services/resources";
import { getDateString } from "../../Values/utils";
import { showSnackbarAction } from "../../Redux/actions";
import Loading from "../../Components/Loading/Loading";
import { MdDelete } from "react-icons/md";

import { FiEdit3 } from "react-icons/fi";
import EditResources from "../../Components/Modals/AddResourcesModal/EditResourceModal";
import * as ReactBootStrap from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import { themeColors } from "../../Components/theme";
import { CSVLink } from "react-csv";
import moment from "moment";
import ImportModelLoaction from './ImportLocationModal';
import ImportModelLeadSource from './ImportLeadSourceModal';
import ImportModelBudget from './ImportBudgetModal';
import ImportCommercialTypeModal from './ImportCommercialTypeModal';
import ImportResidentialTypeModal from './ImportResidentialTypeModal';
import ImportTransferReasonModal from './TransferReasonModel'
import ImportPropertyTypeModel from "./ImportPropertyTypeModel";
import ImportPropertyStageModal from "./ImportPropertyStageModal";
import ImportPropertySubTypeModal from "./ImportPropertySubTypeModal";

const Resources = ({ history, user, theme, conatctHeader }: any) => {
  console.log("Contact Headers Data :",conatctHeader);
  const [openLocation, setOpenLocation] = useState(false);
  const [openBudget, setOpenBudget] = useState(false);
  const [openLeadSource, setOpenLeadSource] = useState(false);
  const [openCommercial, setOpenCommercial] = useState(false);
  const [openResidential, setOpenResidential] = useState(false);
  const [openTransferReason, setOpenTransferReason] = useState(false);
  const [openPropertyType, setOpenPropertyType] = useState(false);
  const [openPropertyStage, setOpenPropertyStage] = useState(false);
  const [openPropertySubType, setOpenPropertySubType] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [editModalType, setEditModalType] = useState<
    "Location" | "Budget" | "Lead Source" | "Residential" | "Commercial" | "Transfer Reason" | "Property Stage" | "Property Type" | "Property Sub Type"
  >("Location");
  const [importImageModal, setImportImageModal] = useState(false);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [locationsListFormat, setLocationsListFormat] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [budgetsListFormat, setBudgetsListFormat] = useState<any[]>([]);
  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [leadSourceListFormat, setLeadSourceListFormat] = useState<any[]>([]);
  const [carouselList, setCarouselList] = useState<any[] | undefined>(
    undefined
  );
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [comTypesFormat, setComTypesFormat] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [resTypesFormat, setResTypesFormat] = useState<any[]>([]);
  const [transferReasons, setTransferReasons] = useState<any[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<any[]>([]);
  const [propertyStage, setPropertyStage] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<any[]>([]);
  const [transferReasonsFormat, setTransferReasonsFormat] = useState<any[]>([]);
  const [propertyTypeFormat, setPropertyTypeFormat] = useState<any[]>([]);
  const [propertyStageFormat, setPropertyStageFormat] = useState<any[]>([]);
  const [propertySubTypeFormat, setPropertySubTypeFormat] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [locationNames, setLocationNames] = useState<any[]>([]);
  const [showImportLoactionModal, setShowImportLocationModal] = useState(false);
  const [showImportLeadSourceModal, setShowImportLeadSourceModal] = useState(false);
  const [showImportCommercialTypeModal, setShowImportCommercialTypeModal] = useState(false);
  const [showImportBudgetModal, setShowImportBudgetModal] = useState(false);
  const [showImportResidentialTypeModal, setShowImportResidentialTypeModal] = useState(false);
  const [showImportTransferReasonModal, setShowImportTransferReasonModal] = useState(false);
  const [showImportPropertyTypeModal, setShowImportPropertyTypeModal] = useState(false);
  const [showImportPropertyStageModal, setShowImportPropertyStageModal] = useState(false);
  const [showImportPropertySubTypeModal, setShowImportPropertySubTypeModal] = useState(false);
  const [leadSourceColour, setLeadSourceColour] = useState("");

  useEffect(() => {
    if (" ") {
      const unsub = fetchResources(
        (data) => setLocationsList(data),
        (data) => setBudgetsList(data),
        (data) => setCarouselList(data),
        (data) => setLeadSourceList(data),
        (data) => setComTypes(data),
        (data) => setResTypes(data),

        " ",
        (data) => setTransferReasons(data),
        (data) => setPropertyTypes(data),
        (data) => setPropertyStage(data),
        (data) => setPropertySubType(data),
      );

      return () => {
        unsub();
      };
    }
  }, [user]);
  const dispatcher = useDispatch();
  const deleteImage = async (index: number, image_name: string) => {
    if (carouselList) {
      setLoad(true);
      await deleteCarouselImage(
        " ",
        image_name,
        carouselList,
        index,
        dispatcher
      );
      setLoad(false);
    }
  };

  const deleteLocationFirebase = (location: string, index: number) => {
    if (locationsList) {
      setLoad(true);
      deleteLocation(
        location,
        " ",
        locationsList,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteBudgetFirebase = (budget: string, index: number) => {
    if (budgetsList) {
      setLoad(true);

      deleteBudget(
        budget,
        " ",
        budgetsList,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteLeadSourceFirebase = (leadSource: string, index: number) => {
    if (leadSourceList) {
      setLoad(true);
      deleteLeadSource(
        leadSource,
        " ",
        leadSourceList,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteResidentialType = (residential: string, index: number) => {
    if (resTypes) {
      setLoad(true);
      deleteResidential(
        residential,
        " ",
        resTypes,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteCommercialType = (commercial: string, index: number) => {
    if (comTypes) {
      setLoad(true);
      deleteComType(
        commercial,
        " ",
        comTypes,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  const deleteTransferReason = (transferReason: string, index: number) => {
    if (transferReasons) {
      setLoad(true);
      deleteTransferReasonProcess(
        transferReason,
        " ",
        transferReasons,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  const deletePropertyStage = (propertySingleStage: string, index: number) => {
    if (propertyStage) {
      setLoad(true);
      deletePropertyStageProcess(
        propertySingleStage,
        " ",
        propertyStage,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  const deletePropertyType = (propertySingleType: string, index: number) => {
    if (propertyTypes) {
      setLoad(true);
      deletePropertyTypeProcess(
        propertySingleType,
        " ",
        propertyTypes,
        propertySubType,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  const deletePropertySubType = (propertySingleType: any, index: number) => {
    if (propertySubType) {
      setLoad(true);
      deletePropertySubTypeProcess(
        propertySingleType,
        " ",
        propertyTypes,
        propertySubType,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  const onEdit = (
    type: "Location" | "Budget" | "Lead Source" | "Residential" | "Commercial" | "Transfer Reason" | "Property Stage" | "Property Type" | "Property Sub Type",
    value: string
  ) => {
    setEditModalType(type);
    setEditModal(true);
    setEditValue(value);
  };

  useEffect(() => {
    let l: any = [];
    locationsList?.forEach((item) => {
      l.push(item.location_name);
    });
    setLocationNames(l);
    const formatData = locationsList?.map((list: any) => {
      return {
        ...list,
        created_at: getDateString(list.created_at),
      }
    })
    setLocationsListFormat(formatData);
  }, [locationsList]);


  useEffect(() => {
    const formatData = leadSourceList?.map((list: any) => {
      return {
        ...list,
        created_at: getDateString(list.created_at),
      }
    })
    setLeadSourceListFormat(formatData);
  }, [leadSourceList]);

  useEffect(() => {
    const formatData = budgetsList?.map((list: any) => {
      return {
        ...list,
        created_at: getDateString(list.created_at),
      }
    })
    setBudgetsListFormat(formatData);
  }, [budgetsList]);

  useEffect(() => {
    const formattedData = comTypes.map((type, index) => {
      return { "S No": index + 1, "Commercial Type": type };
    });
    setComTypesFormat(formattedData);
  }, [comTypes]);

  useEffect(() => {
    const formattedData = resTypes.map((type, index) => {
      return { "S No": index + 1, "Residential Type": type };
    });
    setResTypesFormat(formattedData);
  }, [resTypes]);

  useEffect(() => {
    const formattedData = transferReasons.map((type, index) => {
      return { "S No": index + 1, "Transfer Reason": type };
    });
    setTransferReasonsFormat(formattedData);
  }, [transferReasons]);

  useEffect(() => {
    const propTypeHeader = conatctHeader.find((obj: any) => obj.label === "property_type").value;
    const formattedData = propertyTypes.map((type, index) => {
      return { "S No": index + 1, [propTypeHeader]: type };
    });
    setPropertyTypeFormat(formattedData);
  }, [propertyTypes]);

  useEffect(() => {
    const propStageHeader = conatctHeader.find((obj: any) => obj.label === "property_stage").value;
    const formattedData = propertyStage.map((type, index) => {
      return { "S No": index + 1, [propStageHeader]: type };
    });
    setPropertyStageFormat(formattedData);
  }, [propertyStage]);

  useEffect(() => {
    const propTypeHeader = conatctHeader.find((obj: any) => obj.label === "property_type").value;
    const propSubTypeHeader = conatctHeader.find((obj: any) => obj.label === "property_sub_type").value;
    const formattedData = propertySubType.map((type, index) => {
      return { "S No": index + 1, [propTypeHeader]: type.property_type, [propSubTypeHeader]:type.property_sub_type };
    });
    setPropertySubTypeFormat(formattedData);
  }, [propertySubType]);


  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const thStyles = {
    backgroundColor: theme ? themeColors.thResourcesBackgroundColor : themeColors.thResourcesBackgroundColorDark
  }
  const thTextStyles = {
    color: theme ? themeColors.textPrimaryDark : themeColors.textPrimaryDark
  }
  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }
  return (
    <>
      {/* {carouselList === undefined && <Loading />} */}
      <div className={styles.parent} style={{...containerStyles,marginTop:'4%', height:"110%"}}>
      <div className={styles.child} style={{...containerStyles,marginBottom:"10%"}}>
          {carouselList === undefined && <Loading />}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "55%",
              justifyContent: "space-between",
              ...containerStyles
            }}
          >
            <div className="card p-2 shadow rounded" style={{ height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%"}}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable} >
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                          <th className={styles.th} style={thTextStyles}>Image</th>
                          <th className={styles.th} style={thTextStyles}>Image Name</th>
                          <th className={styles.th} style={thTextStyles}>Created At</th>
                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {carouselList?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td} style={tdStyles}>
                              <Image
                                src={item.url}
                                fluid
                                rounded
                                width="50%"
                              />
                            </td>
                            <td className={styles.td} style={tdStyles}>{item.image_name.slice(0, 15)}</td>
                            <td className={styles.td} style={tdStyles}>
                              {getDateString(item.created_at)}
                            </td>
                            <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                              <MdDelete
                                color={"#ff0000"}
                                size={20}
                                onClick={() => deleteImage(index, item.image_name)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addImageButton}
                      onClick={() => setImportImageModal(true)}
                    >
                      ADD IMAGE
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2 shadow rounded" style={{ marginTop: "20px", height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%" }}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable}>
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                          <th className={styles.th} style={thTextStyles}>Edit</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "lead_source").value}</th>
                          <th className={styles.th} style={thTextStyles}>Created At</th>
                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leadSourceList?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td} style={tdStyles}>
                            <FiEdit3
                                size={15}
                                color="#272944"
                                onClick={() => {
                                  onEdit("Lead Source", item.leadSource);
                                  setLeadSourceColour(item.leadSourceColor);
                                }}
                              />
                            </td>
                            <td className={styles.td} style={tdStyles}>{item.leadSource}</td>
                            <td className={styles.td} style={tdStyles}>
                              {getDateString(item.created_at)}
                            </td>
                            <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                              <MdDelete
                                color={"#ff0000"}
                                size={20}
                                onClick={() =>
                                  deleteLeadSourceFirebase(item.leadSource, index)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addButton}
                      onClick={() => setOpenLeadSource(true)}
                    >
                      Add {conatctHeader.find((obj: any) => obj.label === "lead_source").value}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2 shadow rounded" style={{ marginTop: "20px", height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%" }}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable}>
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                          <th className={styles.th} style={thTextStyles}>Edit</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "transfer_reason").value}</th>

                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transferReasons?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td} style={tdStyles}>
                            <FiEdit3
                                size={15}
                                color="#272944"
                                onClick={() => onEdit("Transfer Reason", item)}
                              />
                            </td>
                            <td className={styles.td} style={tdStyles}>{item}</td>
                            <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                            <MdDelete
                                color={"#ff0000"}
                                size={20}
                                onClick={() => {
                                  deleteTransferReason(item, index);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addButton}
                      onClick={() => setOpenTransferReason(true)}
                    >
                      Add {conatctHeader.find((obj: any) => obj.label === "transfer_reason").value}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2 shadow rounded" style={{ marginTop: "20px", height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%" }}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable}>
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                          <th className={styles.th} style={thTextStyles}>Edit</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "property_type").value}</th>

                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {propertyTypes?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td} style={tdStyles}>
                            <FiEdit3
                                size={15}
                                color="#272944"
                                onClick={() => onEdit("Property Type", item)}
                              />
                            </td>
                            <td className={styles.td} style={tdStyles}>{item}</td>
                            <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                            <MdDelete
                                color={"#ff0000"}
                                size={20}
                                onClick={() => {
                                  deletePropertyType(item, index);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addButton}
                      onClick={() => setOpenPropertyType(true)}
                    >
                      Add {conatctHeader.find((obj: any) => obj.label === "property_type").value}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{
            width: "55%",
            ...containerStyles
          }}>
            <div className="card p-2 shadow rounded" style={{ height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%" }}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable}>
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                          <th className={styles.th} style={thTextStyles}>Edit</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "location").value}</th>
                          <th className={styles.th} style={thTextStyles}>Created At</th>
                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {locationsList?.map((item: any, index: number) => (
                          <>
                            <tr key={index}>
                              <td className={styles.td} style={tdStyles}>
                                <FiEdit3
                                  size={15}
                                  color="#272944"
                                  onClick={() =>
                                    onEdit("Location", item.location_name)
                                  }
                                />
                              </td>
                              <td className={styles.td} style={tdStyles}>{item.location_name}</td>
                              <td className={styles.td} style={tdStyles}>
                                {getDateString(item.created_at)}
                              </td>
                              <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                                <MdDelete
                                  color={"#ff0000"}
                                  size={20}
                                  onClick={() =>
                                    deleteLocationFirebase(
                                      item.location_name,
                                      index
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addButton}
                      onClick={() => setOpenLocation(true)}
                    >
                      Add {conatctHeader.find((obj: any) => obj.label === "location").value}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2 shadow rounded" style={{ marginTop: "20px", height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%" }}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable}>
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                          <th className={styles.th} style={thTextStyles}>Edit</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "budget").value}</th>
                          <th className={styles.th} style={thTextStyles}>Created At</th>
                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {budgetsList?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td} style={tdStyles}>
                              <FiEdit3
                                size={15}
                                color="#272944"
                                onClick={() => onEdit("Budget", item.budget)}
                              />
                            </td>
                            <td className={styles.td} style={tdStyles}>{item.budget}</td>
                            <td className={styles.td} style={tdStyles}>
                              {getDateString(item.created_at)}
                            </td>
                            <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                              <MdDelete
                                color={"#ff0000"}
                                size={20}
                                onClick={() =>
                                  deleteBudgetFirebase(item.budget, index)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addButton}
                      onClick={() => setOpenBudget(true)}
                    >
                      Add {conatctHeader.find((obj: any) => obj.label === "budget").value}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2 shadow rounded" style={{ marginTop: "20px", height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%" }}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable}>
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                          <th className={styles.th} style={thTextStyles}>Edit</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "property_stage").value}</th>

                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {propertyStage?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td} style={tdStyles}>
                            <FiEdit3
                                size={15}
                                color="#272944"
                                onClick={() => onEdit("Property Stage", item)}
                              />
                            </td>
                            <td className={styles.td} style={tdStyles}>{item}</td>
                            <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                            <MdDelete
                                color={"#ff0000"}
                                size={20}
                                onClick={() => {
                                  deletePropertyStage(item, index);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addButton}
                      onClick={() => setOpenPropertyStage(true)}
                    >
                      Add {conatctHeader.find((obj: any) => obj.label === "property_stage").value}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-2 shadow rounded" style={{ marginTop: "20px", height: "65%", width: "96%", borderRadius: "20px", ...cardContainerStyles }}>
              <div className="card-body" style={{ height: "100%" }}>
                <div className={styles.locationContainer} style={cardContainerStyles}>
                  <div className={styles.imageTable}>
                    <Table striped bordered hover>
                      <thead className={styles.head} style={thStyles}>
                        <tr>
                        <th className={styles.th} style={thTextStyles}>Edit</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "property_type").value}</th>
                          <th className={styles.th} style={thTextStyles}>{conatctHeader.find((obj: any) => obj.label === "property_sub_type").value}</th>

                          <th className={styles.th} style={thTextStyles}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {propertySubType?.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td} style={tdStyles}>
                            <FiEdit3
                                size={15}
                                color="#272944"
                                onClick={() => onEdit("Property Sub Type", item.property_sub_type)}
                              />
                            </td>
                            <td className={styles.td} style={tdStyles}>
                            {item.property_type}
                            </td>
                            <td className={styles.td} style={tdStyles}>{item.property_sub_type}</td>
                            <td className={styles.td} style={{ width: "40px", ...tdStyles }}>
                            <MdDelete
                                color={"#ff0000"}
                                size={20}
                                onClick={() => {
                                  deletePropertySubType(item, index);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className={styles.addLocationContainer}>
                    <button
                      className={styles.addButton}
                      onClick={() => setOpenPropertySubType(true)}
                    >
                      Add {conatctHeader.find((obj: any) => obj.label === "property_sub_type").value}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openLocation && (
          <AddResources
            open={openLocation}
            close={() => setOpenLocation(false)}
            heading={"Location"}
            title={"Enter Location"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(showSnackbarAction("Enter Location!!", "error"));
              } else {
                setLoad(true);
                if (locationNames.includes(value)) {
                  dispatcher(
                    showSnackbarAction("This Location Already Exists!!", "info")
                  );
                  setLoad(false);
                  setOpenLocation(false);
                } else {
                  createLocation(
                    " ",
                    value,
                    dispatcher,
                    history,
                    (data) => {
                      setOpenLocation(data);
                      setLoad(data);
                    }
                  );
                }
              }
            }}
          />
        )}
        {openTransferReason && (
          <AddResources
            open={openTransferReason}
            close={() => setOpenTransferReason(false)}
            heading={"Transfer Reason"}
            title={"Enter Reason"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Transfer Reason!!", "error")
                );
              } else {
                setLoad(true);

                createTransferReason(
                  " ",
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenTransferReason(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openPropertyType && (
          <AddResources
            open={openPropertyType}
            close={() => setOpenPropertyType(false)}
            heading={conatctHeader.find((obj: any) => obj.label === "property_type").value}
            title={`Enter ${conatctHeader.find((obj: any) => obj.label === "property_type").value}`}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction(`Enter ${conatctHeader.find((obj: any) => obj.label === "property_type").value}`, "error")
                );
              } else {
                setLoad(true);
                createPropertyType(
                  " ",
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenPropertyType(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openPropertyStage && (
          <AddResources
            open={openPropertyStage}
            close={() => setOpenPropertyStage(false)}
            heading={"Property Stage"}
            title={"Enter Property Stage"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Property Stage!!", "error")
                );
              } else {
                setLoad(true);
                createPropertyStage(
                  " ",
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenPropertyStage(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openPropertySubType && (
          <AddResources
            open={openPropertySubType}
            close={() => setOpenPropertySubType(false)}
            heading={"Property Sub Type"}
            title={"Enter Property Sub Type"}
            onSave={(value: any,type: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Property Sub Type!!", "error")
                );
              }else if (type.value === "Select") {
                dispatcher(
                  showSnackbarAction("Enter Property Type!!", "error")
                );
              } else {
                setLoad(true);
                createPropertySubType(
                  " ",
                  value,
                  type.value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenPropertySubType(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openBudget && (
          <AddResources
            open={openBudget}
            close={() => setOpenBudget(false)}
            heading={"Budget"}
            title={"Enter Budget"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(showSnackbarAction("Enter Budget!!", "error"));
              } else {
                setLoad(true);
                createBudget(
                  " ",
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenBudget(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openLeadSource && (
          <AddResources
            open={openLeadSource}
            close={() => setOpenLeadSource(false)}
            heading={"Lead Source"}
            title={"Enter Lead Source"}
            onSave={(value: any,leadSourceColor:any) => {
              if (value === "") {
                dispatcher(showSnackbarAction("Enter Lead Source!!", "error"));
              } else {
                setLoad(true);

                createLeadSource(
                  " ",
                  value,
                  leadSourceColor,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenLeadSource(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openCommercial && (
          <AddResources
            open={openCommercial}
            close={() => setOpenCommercial(false)}
            heading={"Commercial Type"}
            title={"Enter Commercial Type"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Commercial Type!!", "error")
                );
              } else {
                setLoad(true);

                createCommercialType(
                  " ",
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenCommercial(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openResidential && (
          <AddResources
            open={openResidential}
            close={() => setOpenResidential(false)}
            heading={"Residential Type"}
            title={"Enter Residential Type"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Residential Type!!", "error")
                );
              } else {
                setLoad(true);

                createResidentialType(
                  " ",
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenResidential(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {importImageModal && (
          <ImportImageModal
            open={importImageModal}
            close={() => setImportImageModal(false)}
            organization_id={" "}
            history={history}
          />
        )}
        {editModal && (
          <EditResources
            open={editModal}
            title={`${editValue}`}
            heading={`Edit ${editModalType}`}
            close={() => setEditModal(false)}
            leadSourceColour={leadSourceColour}
            onSave={(newValue,newLeadSourceColor) => {
              setLoad(true);
              if (editModalType === "Budget") {
                editBudget(
                  " ",
                  editValue,
                  newValue,
                  budgetsList,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              }else if(editModalType === "Transfer Reason") {
                editTransferReason(
                  " ",
                  editValue,
                  newValue,
                  transferReasons,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              }else if(editModalType === "Property Stage") {
                editPropertyStage(
                  " ",
                  editValue,
                  newValue,
                  propertyStage,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else if(editModalType === "Property Type") {
                editPropertyType(
                  " ",
                  editValue,
                  newValue,
                  propertyTypes,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else if(editModalType === "Property Sub Type") {
                editPropertySubType(
                  " ",
                  editValue,
                  newValue,
                  newLeadSourceColor,
                  propertySubType,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              }else if (editModalType === "Lead Source") {
                editLeadSource(
                  " ",
                  editValue,
                  newValue,
                  newLeadSourceColor,
                  leadSourceList,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else if (editModalType === "Residential") {
                editResType(
                  " ",
                  editValue,
                  newValue,
                  resTypes,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else if (editModalType === "Commercial") {
                editCommType(
                  " ",
                  editValue,
                  newValue,
                  comTypes,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else {
                editLocation(
                  " ",
                  editValue,
                  newValue,
                  locationsList,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              }
            }}
          />
        )}
        {showImportLoactionModal && (
          <ImportModelLoaction
            open={showImportLoactionModal}
            close={() => setShowImportLocationModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />

        )}
        {showImportLeadSourceModal && (
          <ImportModelLeadSource
            open={showImportLeadSourceModal}
            close={() => setShowImportLeadSourceModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />

        )}
        {showImportBudgetModal && (
          <ImportModelBudget
            open={showImportBudgetModal}
            close={() => setShowImportBudgetModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />

        )}
         {showImportCommercialTypeModal && (
          <ImportCommercialTypeModal
            open={showImportCommercialTypeModal}
            close={() => setShowImportCommercialTypeModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />
        )}
        {showImportResidentialTypeModal && (
          <ImportResidentialTypeModal
            open={showImportResidentialTypeModal}
            close={() => setShowImportResidentialTypeModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />
        )}
         {showImportTransferReasonModal && (
          <ImportTransferReasonModal
            open={showImportTransferReasonModal}
            close={() => setShowImportTransferReasonModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />
        )}
        {showImportPropertyTypeModal && (
          <ImportPropertyTypeModel
            open={showImportPropertyTypeModal}
            close={() => setShowImportPropertyTypeModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />
        )}
        {showImportPropertyStageModal && (
          <ImportPropertyStageModal
            open={showImportPropertyStageModal}
            close={() => setShowImportPropertyStageModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />
        )}
        {showImportPropertySubTypeModal && (
          <ImportPropertySubTypeModal
            open={showImportPropertySubTypeModal}
            close={() => setShowImportPropertySubTypeModal(false)}
            organization_id={" "}
            history={history}
            //usersList={organizationUsers}
            user={user}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    contacts: state.contacts,
    theme: state.theme.isLightMode,
    conatctHeader: state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(Resources);
