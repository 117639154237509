import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar } from "@mui/material";
import {
  contactResources,
} from "../../Services/contacts";
import './booking.style.css';
import { FileUpload } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone'
import { green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { uploadAttachment } from "../../Services/booking";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { showSnackbarAction } from "../../Redux/actions";
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../Components/theme";
import AddIcon from '@mui/icons-material/Add';
import { BsPlus } from 'react-icons/bs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";

const CssTextField = styled(TextField)({
  fontSize: "0.8rem"
});
type props = {
  //   history: any;
  //   user: any;
  formikProps: any;
  theme: any;
};
const marritalArr = ["Married", "Unmarried"];
const genderArr = ["Male", "Female", "Other"];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EditAplicantDetails: FunctionComponent<props> = ({ formikProps, theme }) => {
  const dispatcher = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [indexDelete, SetIndexDelete] = React.useState();
  const location: any = useLocation();

  const useHelperTextStyles = makeStyles(() => ({
    inputRoot: {
      // "&$disabled": {
      //   color: "green"
      // }
    },
    disabled: {
    },
    root: {
      // backgroundColor:"#fff",
      // color: "red",
      // backgroundColor:props.theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark,
      // margin:0,
      // padding:0
      // "& .MuiInputBase-root.Mui-disabled": {
      //   color: "white" // (default alpha is 0.38)
      // },
      "& .MuiFormLabel-root": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiFormLabel-root.Mui-disabled": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiSelect-select": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiSelect-select.Mui-disabled": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiInputBase-input": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
        "-webkit-text-fill-color": `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiOutlinedInput-input": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiOutlinedInput-input.Mui-disabled": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiSvgIcon-root": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiSvgIcon-root.Mui-disabled": {
        color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      }
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark} !important`,
    },
    preview: {
      color: "black"
    },
    dropzone: {
      color: "black"
    },
    formHelperText: {
    },
  }));
  const helperTextStyles = useHelperTextStyles();

  const handleAddNewCoApplicants = (e: any) => {
    e.preventDefault();
    const newArr = [...formikProps?.values?.applicant_details.co_applicant,
    {
      name: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      phone_number1: "",
      phone_number2: "",
      email: "",
      gender: "",
      date_of_birth: "",
      marrital_status: "",
      occupation: "",
      company_name: "",
      designation: "",
      net_monthly_income: "",
      aadhaar_no: "",
      pan_no: ""
    }
    ];
    formikProps.setFieldValue('applicant_details.co_applicant', newArr)
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (index:any) => {
    setOpen(true);
    SetIndexDelete(index);
  };
  const handleDelete = () => {
    let arrayList = formikProps?.values?.applicant_details.co_applicant;
    arrayList.splice(indexDelete, 1);
    formikProps.setFieldValue('applicant_details.co_applicant', arrayList);
    handleClose();
  }
  const containerStyles = {backgroundColor:theme ? themeColors.navbarBackgroundColor:themeColors.navbarBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
      <div style={{
        border: "solid 1px #AEAEAE",
        boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        borderRadius: "10px",
        padding: "10px",
      }}>
        <Grid container className="booking_container" spacing={2} style={{ padding: "30px" }} >
          <Typography className="textTypography" variant="subtitle1">Applicants</Typography>
          <Grid container className='child-container' spacing={2}>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                label="Applicant Name"
                required
                size="small"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.name')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                label="Address line 1(Address)"
                required
                size="small"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.address_line1')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Address line 2(Address)"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.address_line2')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="City(Address)"
                required
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.city')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="State(Address)"
                required
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.state')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Country(Address)"
                required
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.country')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Postal Code(Address)"
                required
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.postal_code')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                label="Phone 1"
                required
                size="small"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.phone_number1')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                label="Phone 2"
                size="small"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.phone_number2')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="email"
                fullWidth
                label="Email ID"
                size="small"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.email')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                label="Gender"
                required
                select
                size="small"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.gender')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              >
                {genderArr?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CssTextField>
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="date"
                fullWidth
                label="Date Of Birth"
                size="small"
                InputLabelProps={{ shrink: true, required: true }}
                id="custom-css-outlined-input"
                defaultValue={moment(formikProps.values.applicant_details.applicant.date_of_birth).format("YYYY-MM-DD")}
                {...formikProps.getFieldProps('applicant_details.applicant.date_of_birth')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                label="Marrital Status"
                required
                select
                size="small"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.marrital_status')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              >
                {marritalArr?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CssTextField>
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Occupation"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.occupation')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Company Name"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.company_name')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Designation"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.designation')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Net Monthly Income"
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.net_monthly_income')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Aadhaar Number"
                required
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.aadhaar_no')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
            <Grid item xs={3}>
              <CssTextField
                type="text"
                fullWidth
                size="small"
                label="Pan Number"
                required
                id="custom-css-outlined-input"
                {...formikProps.getFieldProps('applicant_details.applicant.pan_no')}
                className={helperTextStyles.root}
                InputProps={{
                  classes: {
                    notchedOutline: helperTextStyles.notchedOutline
                  }
                }}
                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
              />
            </Grid>
          </Grid>
          <Typography className="textTypography" width="98%" variant="subtitle1">
            Co-Applicants
          </Typography>
          {
            formikProps?.values?.applicant_details.co_applicant?.map((list: any, index: any) => (
              <>
                <div style={{ display: 'flex' }}>
                  <div>
                    <Grid container className='child-container' key={index} spacing={2}>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          label="Applicant Name"
                          size="small"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].name`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Address line 1(Address)"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].address_line1`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Address line 2(Address)"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].address_line2`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="City(Address)"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].city`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="State(Address)"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].state`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Country(Address)"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].country`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Postal Code(Address)"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].postal_code`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Phone 1"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].phone_number1`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Phone 2"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].address_line2`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="email"
                          fullWidth
                          label="Email ID"
                          size="small"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].email`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          label="Gender"
                          size="small"
                          select
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].gender`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                          {genderArr?.map((option: any) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </CssTextField>
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="date"
                          fullWidth
                          label="Date Of Birth"
                          size="small"
                          InputLabelProps={{ shrink: true, required: true }}
                          id="custom-css-outlined-input"
                          // defaultValue={moment(formikProps.values.applicant_details.co_applicant.date_of_birth).format("YYYY-MM-DD")}
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].date_of_birth`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          size="small"
                          fullWidth
                          label="Marrital Status"
                          select
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].marrital_status`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                          {marritalArr?.map((option: any) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </CssTextField>
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          label="Occupation"
                          size="small"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].occupation`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          label="Company Name"
                          size="small"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].company_name`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          label="Designation"
                          size="small"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].designation`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Net Monthly Income"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].net_monthly_income`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          label="Aadhaar Number"
                          size="small"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].aadhaar_no`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CssTextField
                          type="text"
                          fullWidth
                          size="small"
                          label="Pan Number"
                          id="custom-css-outlined-input"
                          {...formikProps.getFieldProps(`applicant_details.co_applicant[${index}].pan_no`)}
                          className={helperTextStyles.root}
                          InputProps={{
                            classes: {
                              notchedOutline: helperTextStyles.notchedOutline
                            }
                          }}
                          sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{ marginTop: "7px", marginLeft: formikProps?.values?.applicant_details.co_applicant?.length === 1 ? "35px" : "3px" }}
                  >
                    {formikProps?.values?.applicant_details.co_applicant?.length <= 1 ? <></> : <>
                      <HighlightOffIcon
                        // onClick={(e) => handleDelete(e, index)}
                        onClick={()=>handleClickOpen(index)}
                        style={{ cursor: 'pointer',color:"red" }}
                      />
                    </>}
                  </div>
                </div>
              </>
            ))
          }
          <Typography className="textTypography" width="98%" variant="subtitle1">
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <div>
              </div>
              <div>
                <button
                  style={{ marginLeft: '10px' }}
                  className="button_addBox"
                  onClick={(e) => handleAddNewCoApplicants(e)}
                >
                  <BsPlus size={22} color={'#ffffff'} />
                </button>
              </div>
            </div>
          </Typography>
        </Grid>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={containerStyles}>
          {"Confirmation"}
        </DialogTitle>
        <DialogContent  style={containerStyles}>
          <DialogContentText id="alert-dialog-slide-description" style={containerStyles}>
            Are you sure you want to Co-Applicants details?
          </DialogContentText>
        </DialogContent>
        <DialogActions  style={containerStyles}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
           onClick={handleDelete} 
           color="primary">
            DELETE
          </Button>
        </DialogActions>
      </Dialog>   
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(EditAplicantDetails);