import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setClearFilter, updateStage } from '../../Redux/actions';
import styles from '../../Screens/Analytics/Analytics.module.css';
import {
  getDrillDownChartOrg,
  getPendingTaskDrillDown,
  getTaskDrillDownChartOrg,
  kpiLeadsDrilldownData,
  kpiTaskDrilldownData,
} from '../../Services/analytics';
import { changeDateForRoute } from '../../Services/contacts';
import {themeColors} from "../../Components/theme";

type props = {
  count: string | number;
  text: string;
  style: any;
  role: any;
  filterText?: string;
  history?: any;
  filter: 'MTD' | 'YTD' | 'PM' | 'All' | 'CUSTOM' | 'T' | 'Y';
  contacts: any;
  user: any;
  organizationUsers: any[];
  teamLeadUsers: any[];
  branchUsers: any;
  taskFilter?: any;
  leadFilter?: any;
  source: boolean;
  theme:any;
  selectOrgId:any;
};

const CountBox: FunctionComponent<props> = ({
  count,
  text,
  style,
  role,
  filterText,
  history,
  filter,
  contacts,
  user,
  organizationUsers,
  teamLeadUsers,
  branchUsers,
  taskFilter,
  leadFilter,
  source,
  theme,
  selectOrgId
}) => {
  const dispatcher = useDispatch();
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);
   const [selectedOrgId,setSelectedOrgId]=useState<any>("");

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  const setFilter = async () => {
    let mapReportingTo: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.forEach((item: any) => {
        if (mapReportingTo[item.uid] === undefined) {
          mapReportingTo[item.uid] = item.reporting_to;
        }
      });
    }
    if (filterText === 'Pending' || filterText === 'Completed') {
      const drillDownData = await kpiTaskDrilldownData(
        filter,
        user.uid || user?.auth_id || selectOrgId,
        filterText,
        taskFilter,
        leadFilter,
        true,
        source
      );
      const drillDownDataLocalStore = {
        ...drillDownData,
        source
      }
      localStorage.setItem('taskDrilldownData', JSON.stringify(drillDownDataLocalStore));
      // window.open('/taskDrilldownData', '_blank');
      history.push('/taskDrilldownData');
    }
    else {
      const drillDownData = await kpiLeadsDrilldownData(
        user.uid || user?.auth_id || selectOrgId,
        filter,
        filterText,
        taskFilter,
        leadFilter,
        true
      );
      const drillDownDataLocalStore = {
        ...drillDownData,
        organizationid: user.organization_id || selectOrgId,
        source
      }
      localStorage.setItem('drilldownData', JSON.stringify(drillDownDataLocalStore));
      // window.open('/drilldownData');
      history.push('/drilldownData');
    }
  };

  const setTotalLeadFilter = async () => {
    let mapReportingTo: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.forEach((item: any) => {
        if (mapReportingTo[item.uid] === undefined) {
          mapReportingTo[item.uid] = item.reporting_to;
        }
      });
    }
    if (filterText === 'Pending' || filterText === 'Completed') {
      const drillDownData = await kpiTaskDrilldownData(
        filter,
        user.uid || user?.auth_id || selectOrgId,
        filterText,
        taskFilter,
        leadFilter,
        true,
        source
      );
      const drillDownDataLocalStore = {
        ...drillDownData,
        source
      }
      localStorage.setItem('taskDrilldownData', JSON.stringify(drillDownDataLocalStore));
      // window.open('/taskDrilldownData', '_blank');
      history.push('/taskDrilldownData');
    }
    else {
      const drillDownData = await kpiLeadsDrilldownData(
        user.uid || user?.auth_id || selectOrgId,
        filter,
        filterText,
        taskFilter,
        leadFilter,
        true
      );
      const drillDownDataLocalStore = {
        ...drillDownData,
        organizationid: user.organization_id || selectOrgId,
        source
      }
      const totalLeadReq = {
        uid:  user.uid || user?.auth_id || selectOrgId,
        leadFilter: leadFilter,
        taskFilter: taskFilter,
        role: true,
        organizationid: user.organization_id || selectOrgId,
        source: false
      }
      localStorage.setItem('drilldownData', JSON.stringify(totalLeadReq));
      // window.open('/drilldownData');
      history.push('/drilldownData');
    }
  };

  const countBoxStyles = {backgroundColor:theme ? themeColors.cardBackgroundColor:themeColors.cardBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  const countStyles = {color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
      {text === "Total Leads" ? <>
        <div className={styles.countBox1}
          style={{ borderLeft: `4px solid ${style.color}`,...countBoxStyles,cursor:'pointer'}}
          onClick={setTotalLeadFilter}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

            }}
          >
            <p className={styles.leadText} style={style}>
              {text}
            </p>
          </div>
          <p className={styles.leadCount} style={countStyles}>{count}</p>
        </div>
      </> : <>
        <div className={styles.countBox}
          style={{ borderLeft: `4px solid ${style.color}`,...countBoxStyles }}
          onClick={setFilter}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

            }}
          >
            <p className={styles.leadText} style={style}>
              {text}
            </p>
          </div>
          <p className={styles.leadCount} style={countStyles}>{count}</p>
        </div>
      </>
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    role: state.user.role,
    contacts: state.contacts,
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    branchUsers: state.branchUsers.data,
    theme: state.theme.isLightMode,
    selectOrgId:state.selectOrganizationId.data
  };
};

export default connect(mapStateToProps)(CountBox);
