import Modal from "react-modal";
import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./ImportModal.module.css";
import { IoIosClose } from "react-icons/io";
import Loading from "../../Components/Loading/Loading";
import moment from "moment";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { connect, useDispatch } from "react-redux";
import Backdrop from "../../Components/Backdrop/Backdrop";
import { themeColors } from "../../Components/theme";
import CSVGenerator from "../../Components/Modals/ImportModal/CSVGenerator";
import {fetchPropertyStageFile, fetchPropertyTypeFile, fetchTransferReasonFile, uploadPropertyStageFile, uploadPropertyTypesFile, uploadTransferReasonTypeFile} from "../../Services/contacts";
import { Circles } from "react-loader-spinner";

type props = {
    theme?: any;
    open?: boolean;
    close?: () => void;
    organization_id?: string;
    history?: any;
    usersList?: any[];
    user?: any;
    conatctHeader:any;
};

interface DataObject {
    [key: string]: any; // define your data object interface
}

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 1,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
};

const ImportPropertyStageModal: FunctionComponent<props> = ({
    theme,
    open,
    close,
    organization_id,
    history,
    usersList,
    user,
    conatctHeader
}) => {
    const [load, setLoad] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    console.log("Uploaded File Data pass :-",uploadedFiles);
    const dispatcher = useDispatch();

    const ResidentialTypeHeaderData: DataObject[] = [];
    ResidentialTypeHeaderData.push([conatctHeader.find((obj: any) => obj.label === "property_stage").value]);

    useEffect(() => {
        if (organization_id) {
            const unsub = fetchPropertyStageFile(
                organization_id,
                (data: any) => setUploadedFiles(data),
                dispatcher
            );
            return () => {
                unsub();
            };
        }
    }, [organization_id]);

    const upload = async (file: any) => {
        const orgId: any = organization_id
        setLoad(true);
        uploadPropertyStageFile(
            file,
            orgId,
            dispatcher,
            (data: boolean) => setLoad(data),
            user.user_first_name + " " + user.user_last_name
        );
    };
    const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const tdStyles = {
        backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
        color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    }
    const thStyles = {
        backgroundColor: theme ? themeColors.thBackgroundColor : themeColors.thBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
    }
    return (
        <>
            {/* {load === true && <Loading />} */}
            <Backdrop>
                <motion.div
                    key="modal"
                    className="card px-4 py-2 shadow rounded"
                    style={{
                        position: "absolute",
                        ...containerStyles
                    }}
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div className={styles.child}>
                        {/* {load === true && <Loading />} */}
                        <div className={styles.cross} onClick={close}>
                            <IoIosClose size={35} color={"#808080"} />
                        </div>
                        <h5
                        // className={styles.contactForm}
                        >Import Data for "{conatctHeader.find((obj: any) => obj.label === "property_stage").value} Form"</h5>
                        <div className={styles.tableBox}>
                            <table className={styles.table}>
                                <thead className={styles.head} >
                                    <tr>
                                        <th className={styles.th} style={thStyles}>Sno.</th>
                                        <th className={styles.th} style={thStyles}>Request Id</th>
                                        <th className={styles.th} style={thStyles}>Created At</th>
                                        <th className={styles.th} style={thStyles}>Status</th>
                                        <th className={styles.th} style={thStyles}>Upload Count</th>
                                        <th className={styles.th} style={thStyles}>Uploaded File</th>
                                        <th className={styles.th} style={thStyles}>Processed File</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {uploadedFiles.map((item: any, index: number) => (
                                        <tr key={index} className={styles.no}>
                                            <td className={styles.td} style={tdStyles}>{index + 1}</td>
                                            <td className={styles.td} style={tdStyles}>{item.request_id}</td>
                                            <td className={styles.td} style={tdStyles}>
                                                {moment(item.created_at.toDate()).format("MM/DD/YY HH:mm")}
                                            </td>
                                            <td className={styles.td} style={tdStyles}>{item.status}</td>
                                            <td className={styles.td} style={tdStyles}>{item.uploadCount}</td>
                                            <td className={styles.td} style={tdStyles}>
                                                <a href={item.fileUrl}>Uploaded File</a>
                                            </td>

                                            <td className={styles.td} style={tdStyles}>
                                                {item.responseURL !== "" && (
                                                    <a href={item.responseURL}>Processed File</a>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.selectBox}>
                            <CSVGenerator data={ResidentialTypeHeaderData} />
                            <label htmlFor="file-input" className={styles.uploadButton}>
                                {/* Upload File */}
                                {load ?
                                <span style={{display:'flex',textAlign:"center",justifyContent:"center"}}><Circles
                                   color="#fff" height={20}  width='100%' /></span>: ' Upload File'}
                            </label>
                            <input
                                id="file-input"
                                className={styles.select}
                                type="file"
                                accept=".csv,.xlsx,.xls"
                                onChange={(e: any) => upload(e.target.files[0])}
                            />
                        </div>
                        <div style={{ textAlign: "center", marginTop: "1rem" }}>
                            <p><i className="fas fa-info-circle"></i>  Only .csv file type is supported.</p>
                            <p style={{ marginTop: "-1rem" }}><i className="fas fa-info-circle"></i>  Please ensure filling all the mandatory fields.</p>
                        </div>
                    </div>
                </motion.div>
            </Backdrop>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.theme.isLightMode,
        projectHeader: state.projectHeaderList.data,
        conatctHeader: state.contactHeaderList.data,
    };
};
export default connect(mapStateToProps)(ImportPropertyStageModal);
