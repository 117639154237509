import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import styles from "./DeleteSelectedModal.module.css";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";
import { deleteProject, fetchProjects } from "../../../Services/contacts";
import { showSnackbarAction } from "../../../Redux/actions";
import { db, firestore, functions, storage } from "../../../Firebase";
import Firebase from "firebase/app";
import { deleteRecords } from "../../../Services/contacts";
import axios from "axios";
import { themeColors } from "../../theme";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import { url } from "../../../Values/constants";


type props = {
    open: boolean;
    close: () => void;
    data: any;
    totalData: any;
    organization_id: any;
    clearSelectedRowsData?: () => void;
    theme: any;
};
const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 1,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
};

const DeleteProjectSelectedModal: FunctionComponent<props> = ({
    open,
    close,
    clearSelectedRowsData,
    data,
    totalData,
    organization_id,
    theme
}) => {
    const [load, setLoad] = useState(false);
    const dispatcher = useDispatch();
    const deleteProjectFirebase = () => {
        if (data) {
            let notLaunchFilter: any[] = [];
            let filterData: any[] = [];
            data.map((item: any) => {
                if (item.project_status === "Pre Launch") {
                    notLaunchFilter.push(item);
                }
                else {
                    filterData.push(item)
                }
            })
            setLoad(true);
            let newIndex: number;
            let finalIndex = [];
            for (let item of notLaunchFilter) {
                newIndex = totalData.findIndex((i: { project_name: any; }) => i.project_name === item.project_name);
                finalIndex.push(newIndex);
            }
            finalIndex.sort();
            // for (let item of data) {
            deleteProject(
                // item.project_name,
                organization_id,
                totalData,
                finalIndex,
                dispatcher,
                (item) => setLoad(item)
            );
            // }
        }
        setTimeout(() => {
            close();
            setLoad(false);
            dispatcher(showSnackbarAction("Record Deleted", "success"));
            clearSelectedRowsData && clearSelectedRowsData();
            window.location.reload();
        }, 2500);
    };
    const customStyles = {
        content: {
            backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
        },
    };
    const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    return (
        <>
            {load && <Loading />}
            <Backdrop>
                <motion.div
                    key="modal"
                    className="card p-4 shadow rounded"
                    style={{
                        position: "absolute", maxHeight: "400px",
                        ...containerStyles
                    }}
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <h6>Delete Selected Records</h6>
                    <div className={styles.container}>
                        <ol>
                            {data.filter((i: any) => i.project_status === "Pre Launch")?.map((dat: any) => (
                                <li>{dat.project_name}</li>
                            ))}
                        </ol>
                    </div>
                    <div className={styles.buttonView}>
                        <button className={styles.cancelButton} onClick={close}>
                            Cancel
                        </button>
                        <button className={styles.saveButton} onClick={deleteProjectFirebase}>
                            Delete
                        </button>
                    </div>
                </motion.div>
            </Backdrop>
            {/* <Modal
                isOpen={open}
                className={styles.parent}
                overlayClassName={styles.overlay}
                onRequestClose={close}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                style={customStyles}
            >
                {load && <Loading />} */}
            {/* <div className={styles.headingContainer}>
                    <p className={styles.heading}>Delete Selected Records</p>
                </div> */}
            {/* <h6>Delete Selected Records</h6>
                <div className={styles.container}> */}
            {/* <div className={styles.subheading}>
                        <p className={styles.optionHeading}>Selected Options</p>
                    </div> */}
            {/* <ol>
                        {data.map((dat: any) => (
                            <li>{dat.project_name}</li>
                        ))}
                    </ol>
                </div>

                <div className={styles.buttonView}>
                    <button className={styles.cancelButton} onClick={close}>
                        Cancel
                    </button>
                    <button className={styles.saveButton} onClick={deleteProjectFirebase}>
                        Delete
                    </button>
                </div>
            </Modal> */}
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        theme: state.theme.isLightMode
    };
};
export default connect(mapStateToProps)(DeleteProjectSelectedModal);
