import React, { FunctionComponent, useState } from "react";
import styles from "./ProfileImageModal.module.css";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import { uploadUserImage } from "../../../Services/users";
import Loading from "../../Loading/Loading";
import {themeColors} from "../../../Components/theme";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";

type props = {
  user: any;
  open: boolean;
  close: () => void;
  theme:any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const ProfileImageModal: FunctionComponent<props> = ({ user, open, close,theme }) => {
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const upload = async (data: FileList | null) => {
    if (data) {
      setLoad(true);
      await uploadUserImage(
        data[0],
        user.uid,
        user.user_image === "" ? false : true,
        dispatcher
      );
      setLoad(false);
    }
  };

  const containerStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}

  return (
    <>
    <Backdrop>
    <motion.div
                   key="modal"
                   className="card p-4 shadow rounded"
                   style={{position: "absolute",
                   ...containerStyles}}
                   variants={dropIn}
                   initial="hidden"
                   animate="visible"
                   exit="exit"
                 >
    {load && <Loading />}
    <div className={styles.headingContainer}>
      {/* <p className={styles.heading}>Change Profile Image</p> */}
      <h6>Change Profile Image</h6>
    </div>
    <div className={styles.detailContainer}>
      {user.user_image && user.user_image !== "" ? (
        <img
          alt="user"
          src={user.user_image}
          className={styles.image}
          onClick={() => {}}
        />
      ) : (
        <FaUserCircle color="#C0C0C0" size={50} />
      )}
      <p
        className={styles.name}
      >{`${user.user_first_name} ${user.user_last_name}`}</p>
    </div>
    <div className={styles.uploadContainer} style={{marginTop:"2rem"}}>
      <div className={styles.selectBox}>
        <label htmlFor="file-input" className={styles.uploadButton}>
          Upload Image
        </label>
        <input
          id="file-input"
          className={styles.select}
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={(e) => upload(e.target.files)}
        />
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={() => close()}>
          Cancel
        </button>
      </div>
    </div>
                 </motion.div>
    </Backdrop>
    </>
  //   <Modal
  //   isOpen={open}
  //   className={styles.parent}
  //   overlayClassName={styles.overlay}
  //   onRequestClose={close}
  //   shouldCloseOnOverlayClick={true}
  //   shouldCloseOnEsc={true}
  // >
  //   {load && <Loading />}
  //   <div className={styles.headingContainer}>
  //     <p className={styles.heading}>Change Profile Image</p>
  //   </div>
  //   <div className={styles.detailContainer}>
  //     {user.user_image && user.user_image !== "" ? (
  //       <img
  //         alt="user"
  //         src={user.user_image}
  //         className={styles.image}
  //         onClick={() => {}}
  //       />
  //     ) : (
  //       <FaUserCircle color="#C0C0C0" size={50} />
  //     )}
  //     <p
  //       className={styles.name}
  //     >{`${user.user_first_name} ${user.user_last_name}`}</p>
  //   </div>
  //   <div className={styles.uploadContainer}>
  //     <div className={styles.selectBox}>
  //       <label htmlFor="file-input" className={styles.uploadButton}>
  //         Upload Image
  //       </label>
  //       <input
  //         id="file-input"
  //         className={styles.select}
  //         type="file"
  //         accept=".png,.jpg,.jpeg"
  //         onChange={(e) => upload(e.target.files)}
  //       />
  //     </div>

  //     <div className={styles.buttonContainer}>
  //       <button className={styles.button} onClick={() => close()}>
  //         Cancel
  //       </button>
  //     </div>
  //   </div>
  // </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(ProfileImageModal);
