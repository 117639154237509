const analyticsPanelReducer = (
  state = {
    data: "Contacts",
  },
  action: {
    type:"SET_ANALYTICS_PANEL"
    payload: any;
  }
) => {
  switch (action.type) {
    case "SET_ANALYTICS_PANEL":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default analyticsPanelReducer;
