import React, { useState, FunctionComponent, useEffect } from 'react';
import { BsColumnsGap } from 'react-icons/bs';
import Modal from 'react-modal';
import styles from './KanbanViewModal.module.css';
import axios from 'axios';
import { url } from '../../../Values/constants';
import { connect, useDispatch } from 'react-redux';
import { dateFieldList } from '../../../Values/tables';
import moment from 'moment';
import Select from 'react-select'
import Multiselect from 'multiselect-react-dropdown';
import { FaFilter } from 'react-icons/fa';
import { themeColors } from "../../theme";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import { IoIosClose } from "react-icons/io";

type props = {
  panel: 'Contacts' | 'Tasks' | 'Call Logs';
  user: any;
  setTaskFilters: (data: any) => void;
  setContactsFilter: (data: any) => void;
  setCallsFilter: (data: any) => void;
  clearFilter: boolean;
  usersList: any[];
  setClearFilter?: (data: boolean) => void;
  hideDateFilter: any;
  theme: any;
  authToken: any;
  organizationSelectId?:any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const KanbanViewFilter: FunctionComponent<props> = ({
  panel,
  user,
  setTaskFilters,
  setCallsFilter,
  setContactsFilter,
  clearFilter,
  usersList,
  setClearFilter,
  hideDateFilter,
  theme,
  authToken,
  organizationSelectId
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filObj, setFilObj] = useState<any>({});
  const [contactFilterObject, setContactFilterObject] = useState<any>({});
  const [taskFilterObject, setTaskFilterObject] = useState<any>({});
  const [callFilterObject, setCallFilterObject] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState<any>({});

  useEffect(() => {
    const contactFilterObj = localStorage.getItem("contactFilterObj") || "";
    const taskFilterObj = localStorage.getItem("taskFilterObj") || "";
    const callFilterObj = localStorage.getItem("callFilterObj") || "";
    const contactObj = contactFilterObj ? JSON.parse(contactFilterObj) : {}
    setContactFilterObject(contactObj);
    const taskObj = taskFilterObj ? JSON.parse(taskFilterObj) : {}
    setTaskFilterObject(taskObj);
    const callObj = callFilterObj ? JSON.parse(callFilterObj) : {}
    setCallFilterObject(callObj);
    setDateArray([]);
  }, [panel]);

  const taskList = ['completed_at', 'due_date', 'type', 'status',];
  const callsList = ['created_at', 'duration'];
  const contactsList = [
    'transfer_status',
    'stage',
    'contact_owner_email',
    'lead_source',
    'budget',
    'lead_type',
    'call_back_reason',
    'project',
    'property_type',
    'property_stage',
    'property_sub_type',
    'location',
    'next_follow_up_type',
    'next_follow_up_date_time',
    'created_at',
    'lead_assign_time',
    'stage_change_at',
    'addset',
    'campaign',
    // 'inventory_type',
    'team',
    'branch',
    'previous_owner_1',
    'previous_owner_2',
    'previous_stage_1',
    'previous_stage_2',
    'transfer_by_1',
    'transfer_by_2',
    'reporting_to',
    'not_int_reason',
    'lost_reason',
  ];
  const taskFilters: any = {
    'Transfer Status': 'transfer_status',
    Stage: 'stage',
    Owner: 'contact_owner_email',
    'Lead Source': 'lead_source',
    Budget: 'budget',
    'Call Back Reason': 'call_back_reason',
    Project: 'project',
    'Property Type': 'property_type',
    'Property Sub Type': 'property_sub_type',
    'Property Stage': 'property_stage',
    Location: 'location',
    Addset: 'addset',
    Campaign: 'campaign',
    // 'Inventory Type': 'inventory_type',
    Team: 'team',
    Branch: 'branch',
    'Reporting To': 'reporting_to',
    'Not Interested Reason': 'not_int_reason',
    'Lost Reason': 'lost_reason',
    'Completion Time': 'completed_at',
    'Task Type': 'type',
    // 'Due Date': 'due_date',
    'Created At': 'created_at',
    "Due Date & Time": 'due_date',
    'Lead Assign Date & Time': 'lead_assign_time',
    'Stage Change Date & Time': 'stage_change_at',
    Status: 'status',
  };

  const contactFilters: any = {
    'Transfer Status': 'transfer_status',
    Stage: 'stage',
    Owner: 'contact_owner_email',
    'Lead Source': 'lead_source',
    Budget: 'budget',
    "Lead Type": "lead_type",
    'Call Back Reason': 'call_back_reason',
    Project: 'project',
    'Property Type': 'property_type',
    'Property Sub Type': 'property_sub_type',
    'Property Stage': 'property_stage',
    Location: 'location',
    'Next Follow Up Type': 'next_follow_up_type',
    'Next Follow Up Date & Time': 'next_follow_up_date_time',
    'Created At': 'created_at',
    'Lead Assign At': 'lead_assign_time',
    'Call Response Time': 'call_response_time',
    'Stage Change At': 'stage_change_at',
    Addset: 'addset',
    Campaign: 'campaign',
    // 'Inventory Type': 'inventory_type',
    Team: 'team',
    Branch: 'branch',
    'Previous Owner 1': 'previous_owner_1',
    'Previous Owner 2': 'previous_owner_2',
    'Stage 1': 'previous_stage_1',
    'Stage 2': 'previous_stage_2',
    'Transfer By 1': 'transfer_by_1',
    'Transfer By 2': 'transfer_by_2',
    'Reporting To': 'reporting_to',
    'Not Interested Reason': 'not_int_reason',
    'Lost Reason': 'lost_reason',
    'Completion Time': 'completed_at',
    'Task Type': 'type',
    Status: 'status',
  };

  const callFilters: any = {
    'Transfer Status': 'transfer_status',
    Stage: 'stage',
    Owner: 'contact_owner_email',
    'Lead Source': 'lead_source',
    Budget: 'budget',
    Project: 'project',
    'Property Type': 'property_type',
    'Property Sub Type': 'property_sub_type',
    'Property Stage': 'property_stage',
    Location: 'location',
    Addset: 'addset',
    Campaign: 'campaign',
    // 'Inventory Type': 'inventory_type',
    Team: 'team',
    Branch: 'branch',
    'Reporting To': 'reporting_to',
    'Not Interested Reason': 'not_int_reason',
    'Lost Reason': 'lost_reason',
    'Created At': 'created_at',
    'Call Duration': 'duration',
  };
  const dispatcher = useDispatch();
  const [filterValue, setFilterValue] = useState<any>();
  const [dateArray, setDateArray] = useState<
    Array<{ item: string; from?: Date; to?: Date }>
  >([]);


  const getContactsFilter = async () => {
    let finalFilter = {};
    axios.defaults.headers.common['x-access-token'] = authToken
    const res = await axios.post(url + '/leads/filterValues', {
      uid: user.uid || user?.auth_id || organizationSelectId?.value,
    });
    let l: any[] = [];
    if (res.data[0]) {
      l = Object.keys(res.data[0]);
      finalFilter = res.data[0];
    }
    axios.defaults.headers.common['x-access-token'] = authToken
    const taskRes = await axios.post(url + '/tasks/filterValues', {
      uid: user.uid|| user?.auth_id || organizationSelectId?.value,
    });

    let tasks: { [key: string]: [] } = {};
    if (taskRes.data[0]) {
      Object.keys(taskRes.data[0]).forEach((key) => {
        if (!l.includes(key)) {
          tasks[key] = taskRes.data[0][key];
          l.push(key);
        }
      });
    }

    axios.defaults.headers.common['x-access-token'] = authToken
    const callRes = await axios.post(url + '/callLogs/filterValues', {
      uid: user.uid || user?.auth_id || organizationSelectId?.value,
    });
    let calls: { [key: string]: [] } = {};
    if (callRes.data[0]) {
      Object.keys(callRes.data[0]).forEach((key) => {
        if (!l.includes(key)) {
          calls[key] = callRes.data[0][key];
          l.push(key);
        }
      });
    }
    let otherFilters = {
      transfer_status: ["True", "False"],
      lead_type: ['Data', 'Leads'],
      reporting_to: usersList,
      previous_owner_1: usersList,
      previous_owner_2: usersList,
      transfer_by_1: usersList,
      transfer_by_2: usersList,
      previous_stage_1: [
        'FRESH',
        'INTERESTED',
        'LOST',
        'WON',
        'NOT INTERESTED',
        'CALL BACK',
      ],
      previous_stage_2: [
        'FRESH',
        'INTERESTED',
        'LOST',
        'WON',
        'NOT INTERESTED',
        'CALL BACK',
      ],
      status: ['Completed', 'Pending', 'Overdue'],
    };

    finalFilter = { ...finalFilter, ...tasks, ...calls, ...otherFilters };
    setFilterValue(finalFilter);
  };
  useEffect(() => {
    if (user && modalIsOpen && authToken) {
      getContactsFilter();
    }
  }, [user, usersList, modalIsOpen, authToken]);

  useEffect(() => {
    if (filterValue) {
      let contacts: { [key: string]: [] } = {};
      let obj = { ...contactFilters, ...taskFilters, ...callFilters };
      Object.keys(obj).forEach((key: any) => {
        let s = obj[key];
        contacts[key] = filterValue[s]?.map((va: any) => {
          return { "value": va, "label": va }
        });
      });
      setFilObj(contacts);
    }
  }, [filterValue]);

  useEffect(() => {
    if (clearFilter === true) {
      setDateArray([]);
      setTaskFilterObject({});
      setContactFilterObject({});
      setCallFilterObject({});
    }
  }, [clearFilter]);

  const onSave = () => {
    localStorage.setItem('contactFilterObj', JSON.stringify(contactFilterObject));
    localStorage.setItem('taskFilterObj', JSON.stringify(taskFilterObject));
    localStorage.setItem('callFilterObj', JSON.stringify(callFilterObject));

    let contactFilObj: { [key: string]: [] } = {};
    Object.entries(contactFilterObject).map(([key, value]) => {
      if (contactFilterObject[key].length > 0) {
        if (key === "transfer_status") {
          contactFilObj[key] = contactFilterObject[key]?.map((va: any) => {
            return va?.value ? va?.value === "False" ? false : true : va === "False" ? false : true;
          });
        } else {
          contactFilObj[key] = contactFilterObject[key]?.map((va: any) => {
            return va?.value ? va?.value : va;
          });
        }
      }
      else {
        return {};
      }
    })
    setContactsFilter(contactFilObj);
    let taskFilObj: { [key: string]: [] } = {};
    Object.entries(taskFilterObject).map(([key, value]) => {
      if (taskFilterObject[key].length > 0) {
        taskFilObj[key] = taskFilterObject[key]?.map((va: any) => {
          return va?.value ? va?.value : va;
        });
      }
      else {
        return {};
      }
    })
    setTaskFilters(taskFilObj);

    let callFilObj: { [key: string]: [] } = {};
    Object.entries(callFilterObject).map(([key, value]) => {
      if (callFilterObject[key].length > 0) {
        callFilObj[key] = callFilterObject[key]?.map((va: any) => {
          return va?.value ? va?.value : va;
        });
      }
      else {
        return {};
      }
    })
    setCallsFilter(callFilObj);
    setModalIsOpen(false);
  };

  const clearKanbanFilters = () => {
    localStorage.clear();
    setDateArray([]);
    setModalIsOpen(false);
  };

  const setDateFilter = (
    date: Date | null,
    type: 'from' | 'to',
    item: string
  ) => {
    if (date === null) {
      return;
    }
    let tempDateArray = dateArray;
    if (type === 'from') {
      const newDate = moment(date).startOf('day').toDate();
      if (!tempDateArray.some((temp) => temp.item === item)) {
        tempDateArray.push({ item, from: newDate });
      } else {
        tempDateArray.forEach((temp) => {
          if (temp.item === item) temp.from = newDate;
        });
      }
    } else {
      const newDate = moment(date).endOf('day').toDate();
      if (!tempDateArray.some((temp) => temp.item === item))
        tempDateArray.push({ item, to: newDate });
      else {
        tempDateArray.forEach((temp) => {
          if (temp.item === item) temp.to = newDate;
        });
      }
    }
    setDateArray(tempDateArray);
    if (
      tempDateArray.some((temp) =>
        Object.keys(temp).includes('item' && 'to' && 'from')
      )
    ) {
      let data = tempDateArray.find((temp) => temp.item === item);
      if (panel === 'Contacts') {
        let contObj = { ...contactFilterObject };
        let taskObj: any = { ...taskFilterObject };
        let callObj: any = { ...callFilterObject };
        if (taskList.includes(contactFilters[item])) {
          taskObj[contactFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setTaskFilterObject(taskObj);
        } else if (contactsList.includes(contactFilters[item])) {
          contObj[contactFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setContactFilterObject(contObj);
        } else {
          callObj[contactFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setCallFilterObject(callObj);
        }
      }
      if (panel === 'Tasks') {
        let contObj = { ...contactFilterObject };
        let taskObj: any = { ...taskFilterObject };
        let callObj: any = { ...callFilterObject };
        if (taskList.includes(taskFilters[item])) {
          taskObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setTaskFilterObject(taskObj);
        } else if (contactsList.includes(taskFilters[item])) {
          contObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setContactFilterObject(contObj);
        } else {
          callObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setCallFilterObject(callObj);
        }
      }
      if (panel === 'Call Logs') {
        let contObj = { ...contactFilterObject };
        let taskObj: any = { ...taskFilterObject };
        let callObj: any = { ...callFilterObject };
        if (callsList.includes(callFilters[item])) {
          callObj[callFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setCallFilterObject(callObj);
        } else if (contactsList.includes(callFilters[item])) {
          contObj[callFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setContactFilterObject(contObj);
        } else {
          taskObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setTaskFilterObject(callObj);
        }
      }
    }
  };
  const handleModel = () => {
    setModalIsOpen(true);
    hideDateFilter(false);
  }
  const customStyles = {
    content: {
      backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <>
      <button
        className={styles.filterBox}
        onClick={handleModel}
      >
        <FaFilter
          color={'#272944'}
          size="10"
          style={{ marginRight: '10px' }}
        />
        Analytics Filter
      </button>
      {modalIsOpen && <Backdrop>
        <motion.div
          key="modal"
          className="card p-4 shadow rounded"
          style={{
            position: "absolute", zIndex: 1000,
            ...containerStyles
          }}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', cursor: "pointer" }}
              onClick={() => { setModalIsOpen(false) }}
            >
              <IoIosClose size={35} color={"#808080"} />
            </div>
            <div className={styles.header}>
              {/* <p className={styles.heading}>Set Filters</p> */}
              <h5>Set Filters</h5>
            </div>
            <div className={styles.tableHeader}>
              {/* <p className={styles.columnName}>{`${panel} Filter`}</p>
          <p className={styles.columnName}>Values</p> */}
              <h6>{`${panel} Filter`}</h6>
              <h6>Values</h6>
            </div>
            <div className={styles.body} style={{ overflowY: "auto", maxHeight: "400px" }}>
              {panel === 'Contacts' &&
                Object.keys(contactFilters).map((item) => (
                  <>
                    <div className={styles.tableContent} >
                      <p
                        className={styles.item}
                      >{item}</p>
                      {dateFieldList.includes(item) === true ? (
                        <>
                          <div className={styles.dateInputContainer}>
                            <p
                              className={styles.optionText}
                              style={{ marginTop: '5px', marginBottom: '4px' }}
                            >
                              From:
                            </p>
                            <input
                              type="date"
                              id="from"
                              name="from"
                              className={styles.dateInput}
                              value={
                                dateArray.some((i) => i.item === item && i.from)
                                  ? moment(
                                    dateArray.find((temp) => temp.item === item)
                                      ?.from
                                  ).format('YYYY-MM-DD')
                                  : ''
                              }
                              onChange={(e) => {
                                setDateFilter(e.target.valueAsDate, 'from', item);
                              }}
                            />
                            <p
                              className={styles.optionText}
                              style={{ marginTop: '12px', marginBottom: '4px' }}
                            >
                              To:
                            </p>
                            <input
                              type="date"
                              id="to"
                              name="to"
                              className={styles.dateInput}
                              value={
                                dateArray.some((i) => i.item === item && i.to)
                                  ? moment(
                                    dateArray.find((temp) => temp.item === item)
                                      ?.to
                                  ).format('YYYY-MM-DD')
                                  : ''
                              }
                              onChange={(e) => {
                                setDateFilter(e.target.valueAsDate, 'to', item);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <Select
                            isMulti
                            className={styles.multiselect_fields_value}
                            options={
                              filObj[item]
                            }
                            onChange={(val) => {
                              let contObj = { ...contactFilterObject };
                              let taskObj: any = { ...taskFilterObject };
                              let callObj: any = { ...callFilterObject };
                              if (taskList.includes(contactFilters[item])) {
                                taskObj[contactFilters[item]] = [...val];
                                setTaskFilterObject(taskObj);
                              }
                              else if (
                                contactsList.includes(contactFilters[item])
                              ) {
                                contObj[contactFilters[item]] = [...val];
                                setContactFilterObject(contObj);
                              } else {
                                callObj[contactFilters[item]] = [...val];
                                setCallFilterObject(callObj);
                              }
                            }}
                            value={contactFilterObject[contactFilters[item]] || taskFilterObject[contactFilters[item]] || callFilterObject[contactFilters[item]]}
                          />
                        </>
                      )}
                    </div>
                    {/* <div className={styles.line}></div> */}
                  </>
                ))}
              {panel === 'Tasks' &&
                Object.keys(taskFilters).map((item) => (
                  <>
                    <div className={styles.tableContent}>
                      <p className={styles.item}>{item}</p>
                      {dateFieldList.includes(item) ? (
                        <>
                          {console.log("itemitem", item)}
                          <div className={styles.dateInputContainer}>
                            <p
                              className={styles.optionText}
                              style={{ marginTop: '5px', marginBottom: '4px' }}
                            >
                              From:
                            </p>
                            <input
                              type="date"
                              id="from"
                              name="from"
                              className={styles.dateInput}
                              value={
                                dateArray.some((i) => i.item === item && i.from)
                                  ? moment(
                                    dateArray.find((temp) => temp.item === item)
                                      ?.from
                                  ).format('YYYY-MM-DD')
                                  : ''
                              }
                              onChange={(e) => {
                                setDateFilter(e.target.valueAsDate, 'from', item);
                              }}
                            />
                            <p
                              className={styles.optionText}
                              style={{ marginTop: '12px', marginBottom: '4px' }}
                            >
                              To:
                            </p>
                            <input
                              type="date"
                              id="to"
                              name="to"
                              className={styles.dateInput}
                              value={
                                dateArray.some((i) => i.item === item && i.to)
                                  ? moment(
                                    dateArray.find((temp) => temp.item === item)
                                      ?.to
                                  ).format('YYYY-MM-DD')
                                  : ''
                              }
                              onChange={(e) => {
                                setDateFilter(e.target.valueAsDate, 'to', item);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <Select
                          isMulti
                          className={styles.multiselect_fields_value}
                          options={
                            filObj[item]
                          }
                          onChange={(val) => {
                            let contObj = { ...contactFilterObject };
                            let taskObj: any = { ...taskFilterObject };
                            let callObj: any = { ...callFilterObject };
                            if (taskList.includes(taskFilters[item])) {
                              taskObj[taskFilters[item]] = [...val];
                              setTaskFilterObject(taskObj);
                            } else if (contactsList.includes(taskFilters[item])) {
                              contObj[taskFilters[item]] = [...val];
                              setContactFilterObject(contObj);
                            } else {
                              callObj[taskFilters[item]] = [...val];
                              setCallFilterObject(callObj);
                            }
                          }}
                          value={contactFilterObject[contactFilters[item]] || taskFilterObject[contactFilters[item]] || callFilterObject[contactFilters[item]]}
                        />
                      )}
                    </div>
                    {/* <div className={styles.line}></div> */}
                  </>
                ))}
              {panel === 'Call Logs' &&
                Object.keys(callFilters).map((item) => (
                  <>
                    <div className={styles.tableContent}>
                      <p className={styles.item}>{item}</p>
                      {dateFieldList.includes(item) ? (
                        <>
                          <div className={styles.dateInputContainer}>
                            <p
                              className={styles.optionText}
                              style={{ marginTop: '5px', marginBottom: '4px' }}
                            >
                              From:
                            </p>
                            <input
                              type="date"
                              id="from"
                              name="from"
                              className={styles.dateInput}
                              value={
                                dateArray.some((i) => i.item === item && i.from)
                                  ? moment(
                                    dateArray.find((temp) => temp.item === item)
                                      ?.from
                                  ).format('YYYY-MM-DD')
                                  : ''
                              }
                              onChange={(e) => {
                                setDateFilter(e.target.valueAsDate, 'from', item);
                              }}
                            />
                            <p
                              className={styles.optionText}
                              style={{ marginTop: '12px', marginBottom: '4px' }}
                            >
                              To:
                            </p>
                            <input
                              type="date"
                              id="to"
                              name="to"
                              className={styles.dateInput}
                              value={
                                dateArray.some((i) => i.item === item && i.to)
                                  ? moment(
                                    dateArray.find((temp) => temp.item === item)
                                      ?.to
                                  ).format('YYYY-MM-DD')
                                  : ''
                              }
                              onChange={(e) => {
                                setDateFilter(e.target.valueAsDate, 'to', item);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <Select
                          isMulti
                          className={styles.multiselect_fields_value}
                          options={
                            filObj[item]
                          }
                          onChange={(val) => {
                            let contObj = { ...contactFilterObject };
                            let taskObj: any = { ...taskFilterObject };
                            let callObj: any = { ...callFilterObject };
                            if (taskList.includes(callFilters[item])) {
                              taskObj[callFilters[item]] = [...val];
                              setTaskFilterObject(taskObj);
                            } else if (contactsList.includes(callFilters[item])) {
                              contObj[callFilters[item]] = [...val];
                              setContactFilterObject(contObj);
                            } else {
                              callObj[callFilters[item]] = [...val];
                              setCallFilterObject(callObj);
                            }
                          }}
                          value={contactFilterObject[contactFilters[item]] || taskFilterObject[contactFilters[item]] || callFilterObject[contactFilters[item]]}
                        />
                      )}
                    </div>
                    {/* <div className={styles.line}></div> */}
                  </>
                ))}
            </div>
            <div className={styles.bottom}>
              <button className={styles.save}
                onClick={() => setClearFilter && setClearFilter(true) || clearKanbanFilters()}
              >
                Clear Filters
              </button>
              <button className={styles.save} onClick={onSave}>
                Save
              </button>
            </div>
          </div>
        </motion.div>
      </Backdrop>}
    </>
    // <div>
    //   <button
    //     className={styles.filterBox}
    //     onClick={handleModel}
    //   >
    //     <FaFilter
    //       color={'#272944'}
    //       size="10"
    //       style={{ marginRight: '10px' }}
    //     />
    //     Analytics Filter
    //   </button>
    //   <Modal
    //     className={styles.parent}
    //     isOpen={modalIsOpen}
    //     shouldCloseOnOverlayClick={true}
    //     overlayClassName={styles.overlay}
    //     shouldCloseOnEsc={true}
    //     style={customStyles}
    //     onRequestClose={() => setModalIsOpen(false)}
    //   >
    //     <div className={styles.header}>
    //       {/* <p className={styles.heading}>Set Filters</p> */}
    //       <h5>Set Filters</h5>
    //     </div>
    //     <div className={styles.tableHeader}>
    //       {/* <p className={styles.columnName}>{`${panel} Filter`}</p>
    //       <p className={styles.columnName}>Values</p> */}
    //       <h6>{`${panel} Filter`}</h6>
    //       <h6>Values</h6>
    //     </div>
    //     <div className={styles.body}>
    //       {panel === 'Contacts' &&
    //         Object.keys(contactFilters).map((item) => (
    //           <>
    //             <div className={styles.tableContent} >
    //               <p 
    //               className={styles.item}
    //               >{item}</p>
    //               {dateFieldList.includes(item) === true ? (
    //                 <>
    //                   <div className={styles.dateInputContainer}>
    //                     <p
    //                       className={styles.optionText}
    //                       style={{ marginTop: '5px', marginBottom: '4px' }}
    //                     >
    //                       From:
    //                     </p>
    //                     <input
    //                       type="date"
    //                       id="from"
    //                       name="from"
    //                       className={styles.dateInput}
    //                       value={
    //                         dateArray.some((i) => i.item === item && i.from)
    //                           ? moment(
    //                             dateArray.find((temp) => temp.item === item)
    //                               ?.from
    //                           ).format('YYYY-MM-DD')
    //                           : ''
    //                       }
    //                       onChange={(e) => {
    //                         setDateFilter(e.target.valueAsDate, 'from', item);
    //                       }}
    //                     />
    //                     <p
    //                       className={styles.optionText}
    //                       style={{ marginTop: '12px', marginBottom: '4px' }}
    //                     >
    //                       To:
    //                     </p>
    //                     <input
    //                       type="date"
    //                       id="to"
    //                       name="to"
    //                       className={styles.dateInput}
    //                       value={
    //                         dateArray.some((i) => i.item === item && i.to)
    //                           ? moment(
    //                             dateArray.find((temp) => temp.item === item)
    //                               ?.to
    //                           ).format('YYYY-MM-DD')
    //                           : ''
    //                       }
    //                       onChange={(e) => {
    //                         setDateFilter(e.target.valueAsDate, 'to', item);
    //                       }}
    //                     />
    //                   </div>
    //                 </>
    //               ) : (
    //                 <>
    //                   <Select
    //                     isMulti
    //                     className={styles.multiselect_fields_value}
    //                     options={
    //                       filObj[item]
    //                     }
    //                     onChange={(val) => {
    //                       let contObj = { ...contactFilterObject };
    //                       let taskObj: any = { ...taskFilterObject };
    //                       let callObj: any = { ...callFilterObject };
    //                       if (taskList.includes(contactFilters[item])) {
    //                         taskObj[contactFilters[item]] = [...val];
    //                         setTaskFilterObject(taskObj);
    //                       }
    //                       else if (
    //                         contactsList.includes(contactFilters[item])
    //                       ) {
    //                         contObj[contactFilters[item]] = [...val];
    //                         setContactFilterObject(contObj);
    //                       } else {
    //                         callObj[contactFilters[item]] = [...val];
    //                         setCallFilterObject(callObj);
    //                       }
    //                     }}
    //                     value={contactFilterObject[contactFilters[item]] || taskFilterObject[contactFilters[item]] || callFilterObject[contactFilters[item]]}
    //                   />
    //                 </>
    //               )}
    //             </div>
    //             {/* <div className={styles.line}></div> */}
    //           </>
    //         ))}
    //       {panel === 'Tasks' &&
    //         Object.keys(taskFilters).map((item) => (
    //           <>
    //             <div className={styles.tableContent}>
    //               <p className={styles.item}>{item}</p>
    //               {dateFieldList.includes(item) ? (
    //                 <>
    //                   <div className={styles.dateInputContainer}>
    //                     <p
    //                       className={styles.optionText}
    //                       style={{ marginTop: '5px', marginBottom: '4px' }}
    //                     >
    //                       From:
    //                     </p>
    //                     <input
    //                       type="date"
    //                       id="from"
    //                       name="from"
    //                       className={styles.dateInput}
    //                       value={
    //                         dateArray.some((i) => i.item === item && i.from)
    //                           ? moment(
    //                             dateArray.find((temp) => temp.item === item)
    //                               ?.from
    //                           ).format('YYYY-MM-DD')
    //                           : ''
    //                       }
    //                       onChange={(e) => {
    //                         setDateFilter(e.target.valueAsDate, 'from', item);
    //                       }}
    //                     />
    //                     <p
    //                       className={styles.optionText}
    //                       style={{ marginTop: '12px', marginBottom: '4px' }}
    //                     >
    //                       To:
    //                     </p>
    //                     <input
    //                       type="date"
    //                       id="to"
    //                       name="to"
    //                       className={styles.dateInput}
    //                       value={
    //                         dateArray.some((i) => i.item === item && i.to)
    //                           ? moment(
    //                             dateArray.find((temp) => temp.item === item)
    //                               ?.to
    //                           ).format('YYYY-MM-DD')
    //                           : ''
    //                       }
    //                       onChange={(e) => {
    //                         setDateFilter(e.target.valueAsDate, 'to', item);
    //                       }}
    //                     />
    //                   </div>
    //                 </>
    //               ) : (
    //                 <Select
    //                   isMulti
    //                   className={styles.multiselect_fields_value}
    //                   options={
    //                     filObj[item]
    //                   }
    //                   onChange={(val) => {
    //                     let contObj = { ...contactFilterObject };
    //                     let taskObj: any = { ...taskFilterObject };
    //                     let callObj: any = { ...callFilterObject };
    //                     if (taskList.includes(taskFilters[item])) {
    //                       taskObj[taskFilters[item]] = [...val];
    //                       setTaskFilterObject(taskObj);
    //                     } else if (contactsList.includes(taskFilters[item])) {
    //                       contObj[taskFilters[item]] = [...val];
    //                       setContactFilterObject(contObj);
    //                     } else {
    //                       callObj[taskFilters[item]] = [...val];
    //                       setCallFilterObject(callObj);
    //                     }
    //                   }}
    //                   value={contactFilterObject[contactFilters[item]] || taskFilterObject[contactFilters[item]] || callFilterObject[contactFilters[item]]}
    //                 />
    //               )}
    //             </div>
    //             {/* <div className={styles.line}></div> */}
    //           </>
    //         ))}
    //       {panel === 'Call Logs' &&
    //         Object.keys(callFilters).map((item) => (
    //           <>
    //             <div className={styles.tableContent}>
    //               <p className={styles.item}>{item}</p>
    //               {dateFieldList.includes(item) ? (
    //                 <>
    //                   <div className={styles.dateInputContainer}>
    //                     <p
    //                       className={styles.optionText}
    //                       style={{ marginTop: '5px', marginBottom: '4px' }}
    //                     >
    //                       From:
    //                     </p>
    //                     <input
    //                       type="date"
    //                       id="from"
    //                       name="from"
    //                       className={styles.dateInput}
    //                       value={
    //                         dateArray.some((i) => i.item === item && i.from)
    //                           ? moment(
    //                             dateArray.find((temp) => temp.item === item)
    //                               ?.from
    //                           ).format('YYYY-MM-DD')
    //                           : ''
    //                       }
    //                       onChange={(e) => {
    //                         setDateFilter(e.target.valueAsDate, 'from', item);
    //                       }}
    //                     />
    //                     <p
    //                       className={styles.optionText}
    //                       style={{ marginTop: '12px', marginBottom: '4px' }}
    //                     >
    //                       To:
    //                     </p>
    //                     <input
    //                       type="date"
    //                       id="to"
    //                       name="to"
    //                       className={styles.dateInput}
    //                       value={
    //                         dateArray.some((i) => i.item === item && i.to)
    //                           ? moment(
    //                             dateArray.find((temp) => temp.item === item)
    //                               ?.to
    //                           ).format('YYYY-MM-DD')
    //                           : ''
    //                       }
    //                       onChange={(e) => {
    //                         setDateFilter(e.target.valueAsDate, 'to', item);
    //                       }}
    //                     />
    //                   </div>
    //                 </>
    //               ) : (
    //                 <Select
    //                   isMulti
    //                   className={styles.multiselect_fields_value}
    //                   options={
    //                     filObj[item]
    //                   }
    //                   onChange={(val) => {
    //                     let contObj = { ...contactFilterObject };
    //                     let taskObj: any = { ...taskFilterObject };
    //                     let callObj: any = { ...callFilterObject };
    //                     if (taskList.includes(callFilters[item])) {
    //                       taskObj[callFilters[item]] = [...val];
    //                       setTaskFilterObject(taskObj);
    //                     } else if (contactsList.includes(callFilters[item])) {
    //                       contObj[callFilters[item]] = [...val];
    //                       setContactFilterObject(contObj);
    //                     } else {
    //                       callObj[callFilters[item]] = [...val];
    //                       setCallFilterObject(callObj);
    //                     }
    //                   }}
    //                   value={contactFilterObject[contactFilters[item]] || taskFilterObject[contactFilters[item]] || callFilterObject[contactFilters[item]]}
    //                 />
    //               )}
    //             </div>
    //             {/* <div className={styles.line}></div> */}
    //           </>
    //         ))}
    //     </div>
    //     <div className={styles.bottom}>
    //       <button className={styles.save}
    //         onClick={() => setClearFilter && setClearFilter(true) || clearKanbanFilters()}
    //       >
    //         Clear Filters
    //       </button>
    //       <button className={styles.save} onClick={onSave}>
    //         Save
    //       </button>
    //     </div>
    //   </Modal>
    // </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    authToken: state.user.authToken,
  };
};
export default connect(mapStateToProps)(KanbanViewFilter);
