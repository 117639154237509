import React, { FunctionComponent, useEffect, useState } from "react";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import './booking.style.css';
import { Button } from "@mui/material";
import { BsPlus } from 'react-icons/bs';
import { showSnackbarAction } from "../../Redux/actions";
import { themeColors } from "../../Components/theme";
import MenuIcon from '@mui/icons-material/Menu';
import ListSharpIcon from '@mui/icons-material/ListSharp';

// const handlePDF = async (params: any) => {
// const res = await axios.post(url+'/payments/createpdf', 
// {
//     logo: "https://thumbs.dreamstime.com/b/laundry-basket-icon-trendy-design-style-isolated-white-background-vector-simple-modern-flat-symbol-web-site-mobile-135748439.jpg",
//     name: "Ajit Name",
//     address1: "BASEMENT, 1/5, SARVPRIYA VIHAR",
//     address2: "NEW DELHI 110017 India",
//     orderId: "INV-001",
//     customerName: "Bhutani",
//     gst_no:"GSTIN 07AAYFB9706G1ZY",
//     date: "Oct 2, 2022",
//     paymentTerms: "Delivery Items Receipt",
//     items: [
//         {
//             "name": "1.Information Technologies(IT):For Licensing Services",
//             "subheading": "72 Licenses @ Rs 500/- each for the period of Sep 2022",
//             "hsn": 997331,
//             "qty": 1,
//             "rate": "20000",
//             "percentage": "18",
//             "amount": "3600",
//             "total": "23600"
//         }
//     ],
//     total: "23600",
//     balanceDue: "23600",
//     notes: "Thanks for being an awesome customer!",
//     terms: "This invoice is auto generated at the time of delivery. If there is any issue, Contact provider"
// }
// );
// }

const columns: GridColDef[] = [
  { field: 'id', headerName: 'S.NO', align: "center" },
  { field: 'currency', headerName: 'Currency',flex:1 },
  // { field: 'booking_id', headerName: 'Booking Id', width: 90 },
  // { field: 'razorpay_order_id', headerName: 'Razorpay Order Id', width: 130, },
  // { field: 'razorpay_payment_id', headerName: 'Razorpay Payment Id', width: 132 },
  // { field: 'razorpay_signature', headerName: 'Razorpay Signature', width: 115 },
  // { field: 'uid', headerName: 'UID', width: 110 },
  { field: 'contact_no', headerName: 'Contact Number',flex:1},
  { field: 'amount', headerName: 'Amount',flex:1},
  {
    field: 'verified_at',
    headerName: 'Verified At',
    flex:1,
    valueGetter: (params: GridValueGetterParams) =>
      `${moment(params.row.date_booking).format("YYYY-MMM-DD")}`,
  },
  { field: 'receipt', headerName: 'Receipt Id',flex:2 },
  { field: 'contact_owner_email', headerName: 'User Email',flex:1 },
  // { field: 'organization_id', headerName: 'Organization Id', width: 120 },
  { field: 'status', headerName: 'Payment Status',flex:1 },
  // { field: 'invoice', headerName: 'Invoice', width: 120,    
  //   renderCell: (params) => {
  //     return <Button onClick={()=>handlePDF(params)}>Download</Button>;
  //   } 
  // },
  // { field: 'scheme', headerName: 'Scheme', width: 130 },
  // { field: 'booking_attachment', headerName: 'Booking Attachment', width: 130 },
  // { field: 'Kyc_attachment', headerName: 'Kyc Attachment', width: 130 },

];
type props = {
  history: any;
  user: any;
  theme: any;
  authToken: any;
};
const TransactionHistory: FunctionComponent<props> = ({
  history,
  user,
  theme,
  authToken
}) => {
  const dispatcher = useDispatch();
  const [bookigDetails, setBookingDetails] = useState<any[]>([]);
  const [tableFilterData, setTableFilterData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(5);
  const [load, setLoad] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (user.uid) {
      if(authToken){
        getAllPayments();
      }
    }
  }, [user,authToken])
  useEffect(() => {
    if (user.uid) {
      if(authToken){
        getAllPayments();
      }
    }
  }, [tableFilterData,authToken])
  const getAllPayments = async () => {  
    let filterData: any[] = [];
    let bodyData;
    if(tableFilterData.length==0){
      bodyData = {
        filter: {},
        page: 1,
        pageSize: 40,
        searchString: value,
        sort: {},
        uid: user?.uid,
        skip: 0,
        limit: 40
      }
    }else{
      var object = tableFilterData.reduce(
        (obj, item) => Object.assign(obj, { [item.columnField]: item.value }), {});
      
      bodyData = {
        filter: object,
        page: 1,
        pageSize: 40,
        searchString: value,
        sort: {},
        uid: user?.uid,
        skip: 0,
        limit: 40
      }
    }
   
    try {
      setLoad(true);
      axios.defaults.headers.common['x-access-token'] =
        authToken
      const getBookingRes = await axios.post(
        url + '/payments/search',
        bodyData
      );
      // getBookingRes?.data?.payments?.map((val: any, index: any) => {
      //   return filterData.push(
      //     ...val.bookingDetails?.map((list: any) => {
      //       return { "organization_id": val.organization_id, "uid": val.uid, "customer_name": val.contactDetails?.customer_name, "contact_owner_email": val.contactDetails?.contact_owner_email, "contact_no": val.contact_no, ...list }
      //     })
      //   )
      // })
      const filterAllBookingData = getBookingRes?.data?.payments?.reverse().map((va: any, index: any) => {
        return { "id": index + 1, ...va}
      })
      setBookingDetails(filterAllBookingData);
      setLoad(false);
    }
    catch {
      setLoad(false);
      dispatcher(showSnackbarAction('Please Try Again!!', 'error'));
    }

  }
  // const handleOnCellClick = (params: any) => {
  //   if (user?.profile === "Operation Manager") {
  //     history.push({
  //       pathname: '/editBooking',
  //       state: { "paramsData": params?.row }
  //     })
  //   }
  //   else {
  //     history.push({
  //       pathname: '/viewBookingDetails',
  //       state: { "data": params?.row, "key": "view" }
  //     })
  //   }
  // };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };
  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }
  const thStyles = {
    backgroundColor: theme ? themeColors.thBackgroundColor : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
  }

  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "0px", ...containerStyles }}>
        {load === true && <Loading />}
        {user?.profile === "Operation Manager" ? <div
          className="box"
        ></div> : <> <div
          className="box"
        >
          {/* <button
            className="addBox"
            onClick={() => { setOpen(true) }}
          >
            <BsPlus size={22} color={'#ffffff'} />
            {"Add Re-Booking"}
          </button> */}
        </div>
        </>}
        <div className="d-flex w-25 gap-2" style={{marginLeft: "auto", padding: "0px 1.5%"}}>
          <input
              className="form-control"
              placeholder="Search"
              // style={{
              //   marginLeft:"70%",
              //   position:'relative',
              //   display:'inline-block',
              //   // border:'0',
              //   fontSize:'20px',
              //   boxSizing:'border-box',
              //   borderRadius:"5%"
              // }}
              value={value}
              onChange={(val) => {
                setValue(val.target.value);
              }}
            />
            <button className="btn btn-outline-primary" onClick={()=>getAllPayments()}>Search</button>
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px", marginTop:"2%" }}>
          <div className="booking-table" style={{ height: 600, width: '100%', ...tdStyles }}>
            <DataGrid
              rows={bookigDetails}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[5,10,20]}
              onPageSizeChange={(newPageSize)=>setPageSize(newPageSize)}
              // onCellClick={(celldata) => console.log(celldata)} //on click full row data found
              onFilterModelChange={(filtervalues) => setTableFilterData(filtervalues.items)}
              // onCellClick={handleOnCellClick}
              style={tdStyles}
              // disableSelectionOnClick
              components={{
                ColumnMenuIcon: ListSharpIcon
              }}
              // checkboxSelection
              sx={{
                ".MuiTablePagination-displayedRows": {
                  color: theme ? "black" : "white",
                },
                ".css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled": {
                  color: theme ? "black" : "white",
                },
                "& .MuiDataGrid-columnHeaders": {
                  ...thStyles,
                },
                ".MuiDataGrid-sortIcon": {
                  color: theme ? "black" : "white"
                },
                ".MuiDataGrid-iconSeparator": {
                  // color: theme ? "black" : "white",
                  visibility: 'hidden',
                },
                ".css-ptiqhd-MuiSvgIcon-root": {
                  color: theme ? "black" : "white",
                },
                '&.MuiDataGrid-root': {
                  border: 'none',
                },
              }}
            // checkboxSelection
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    theme: state.theme.isLightMode,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    refreshContacts: state.refresh.refreshContacts,
    searchString: state.searchItem.contactsSearchString,
    authToken: state.user.authToken,
  };
};

export default connect(mapStateToProps)(TransactionHistory);
