import React, { FunctionComponent } from "react";
import { useState } from "react";
import Modal from "react-modal";
import styles from "./ProjectDocumentModal.module.css";
import { IoMdDocument } from "react-icons/io";
import { uploadProjectResources } from "../../../Services/resources";
import { connect, useDispatch } from "react-redux";
import Loading from "react-loading";
import {themeColors} from "../../theme";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";

type props = {
  open: boolean;
  close: () => void;
  title: string;
setLoad:(data:boolean)=>void;
  projectData: any;
  type: string;
  theme: any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};


const ProjectDocumentModal: FunctionComponent<props> = ({
  open,
  close,
  title,
setLoad,
  projectData,
  type,
  theme
}) => {
  const dispatcher = useDispatch();
  
  const upload = async (file: any) => {
    
    let name:any=document.getElementById('file-input')
    let nameList=name.value.split("\\")
    let fileName=nameList[nameList.length-1]
    close();
    setLoad(true);
    await uploadProjectResources(
      file[0],
      projectData,
      type,
      fileName,
      dispatcher
    );
    setLoad(false);
    
  };
  const containerStyles ={ backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark} 
  return (
    <>
    
    {/* <Modal
      isOpen={open}
      onRequestClose={close}
      className={styles.parent}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      
      <p className={styles.title}>{title}</p>
      <div
        className={styles.dragBox}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          upload(e.dataTransfer.files);
        }}
      >
        <IoMdDocument size={80} color={"#808080"} />
        <p>Drag And Drop</p>
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={close}>
          Cancel
        </button>
        <div className={styles.selectBox}>
          <label htmlFor="file-input" className={styles.uploadButton}>
            Upload File
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept={type==="images"?".png,.jpeg,.jpg":".pdf,.png,.jpg,.png"}
            onChange={(e: any) => upload(e.target.files)}
          />
        </div>
      </div>
    </Modal> */}
      <Backdrop>
      <motion.div
        key="modal"
        className="card p-4 shadow rounded"
        style={{position: "absolute",
        ...containerStyles}}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
      
      <h6 
      // className={styles.title}
      >{title}</h6>
      <div
        className={styles.dragBox}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          upload(e.dataTransfer.files);
        }}
      >
        <IoMdDocument size={80} color={"#808080"} />
        <p>Drag And Drop</p>
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={close}>
          Cancel
        </button>
        <div className={styles.selectBox}>
          <label htmlFor="file-input" className={styles.uploadButton}>
            Upload File
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept={type==="images"?".png,.jpeg,.jpg":".pdf,.png,.jpg,.png"}
            onChange={(e: any) => upload(e.target.files)}
          />
        </div>
      </div>
      </motion.div>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(ProjectDocumentModal);


