import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  changeLeadStage,
  fetchConstants,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import { useHistory } from "react-router-dom";
import TextInput from "../../TextInput/TextInput";
import {themeColors} from "../../theme";
import Label from "../../Label";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";

type props = {
  contactDetail: any;
  close: () => void;
  open: boolean;
  tasksData: any[];
  notes: any[];
  user: any;
  theme: any;
  conatctHeader: any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const NotInterestedLeadModal: FunctionComponent<props> = ({
  contactDetail,
  close,
  open,
  tasksData,
  notes,
  user,
  theme,
  conatctHeader,
}) => {
  const otherRef: any = useRef();
  const noteRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [notIntSelected, setNotIntSelected] = useState<any>(
    {
      label: contactDetail.not_int_reason
        ? contactDetail.not_int_reason
        : "Select",
      value: contactDetail.not_int_reason
        ? contactDetail.not_int_reason
        : "Select",
    }
  );

  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [other, setOther] = useState("");

  useEffect(() => {
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      (data) => setnextFollowUpList(data)
    );

    return () => {
      unsubConst();
    };
    // eslint-disable-next-line
  }, []);

  const dispatcher = useDispatch();
  const history = useHistory();
  const onSubmit = () => {
    if (notIntSelected.value === "Select") {
      dispatcher(
        showSnackbarAction(
          "Please Select Not Interested Reason",
          "error"
        )
      );
    } else if (
      notIntSelected.value === "Other" &&
      other === ""
    ) {
      dispatcher(
        showSnackbarAction(
          "Please Mention Other Not Interested Reason",
          "error"
        )
      );
    } else {
      if (noteRef.current.value.length !== 0) {
        addNoteFirebase(
          contactDetail.contactId,
          notes,
          noteRef.current.value,
          () => {},
          dispatcher,
          () => {},
          user.user_email
        );
      }
      changeLeadStage(
        contactDetail.contactId,
        {
          not_int_reason: notIntSelected.value,
          stage: "NOT INTERESTED",
          other_not_int_reason: other === "" ? "" : other,
        },
        (data) => setLoad(data),
        dispatcher,
        close,
        history,
        tasksData
      );
    }
  };
  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
       {load === true && <Loading />}
       {open && <Backdrop>
        <motion.div
                   key="modal"
                   className="card p-4 shadow rounded"
                   style={{position: "absolute",minWidth: "400px",
                   ...containerStyles}}
                   variants={dropIn}
                   initial="hidden"
                   animate="visible"
                   exit="exit"
                 >
                     <div className={styles.firstDiv}>
        <h5 
        // className={styles.contactForm}
        >
          Not Interested Details
        </h5>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      <div className={styles.line}></div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          {/* <div className={styles.title}>
            <p className={styles.one}>
              Not Interested Reason
            </p>
            <p className={styles.two}></p>
          </div> */}
          <Label label={conatctHeader.find((obj:any) => obj.label === "not_int_reason").value} required={false} />
          <div style={{ marginTop: "7px" }}>
            <Dropdown
              option={notIntReasonList}
              selectedValue={notIntSelected}
              setSelectedValue={(value) => {
                setNotIntSelected(value);
                if (value !== "Other") {
                  setOther("");
                }
              }}
            />
          </div>
        </div>
      </div>

      {notIntSelected.value === "Other" && (
        <div className={styles.box2}>
          <div
            className={styles.divide}
            style={{ width: "100%" }}
          >
            {/* <div className={styles.title}>
              <p className={styles.one}>
                Other Not Interested Reason
              </p>
              <p className={styles.two}></p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "other_not_int_reason").value} required={false} />
            <textarea
              style={{
                width: "100%",
                resize: "none",
                marginTop: "17px",
              }}
              rows={4}
              cols={10}
              placeholder={"Enter Other Reason"}
              ref={otherRef}
              onChange={(e) => setOther(e.target.value)}
              value={other}
            ></textarea>
          </div>
        </div>
      )}

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          {/* <div className={styles.title}>
            <p className={styles.one}>Note</p>
            <p className={styles.two}></p>
          </div> */}
          <Label label="Note" required={false} />
          <div>
            <TextInput
              title={"Enter Note"}
              style={{ backgroundColor: "#fff" }}
              ref={noteRef}
            ></TextInput>
          </div>
        </div>
      </div>

      <button
        className={styles.apply}
        style={{ marginTop: "2rem" }}
        onClick={onSubmit}
      >
        Save
      </button>
                 </motion.div>
        </Backdrop>}
    </>
    // <Modal
    //   className={styles.parent}
    //   isOpen={open}
    //   shouldCloseOnOverlayClick={true}
    //   overlayClassName={styles.overlay}
    //   shouldCloseOnEsc={true}
    //   onRequestClose={close}
    //   style={customStyles}
    // >
    //   {load === true && <Loading />}
    //   <div className={styles.firstDiv}>
    //     <h5 
    //     // className={styles.contactForm}
    //     >
    //       Not Interested Details
    //     </h5>
    //     <div className={styles.cross} onClick={close}>
    //       <IoIosClose size={35} color={"#808080"} />
    //     </div>
    //   </div>
    //   <div className={styles.line}></div>

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>
    //           Not Interested Reason
    //         </p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Not Interested Reason" required={false} />
    //       <div style={{ marginTop: "7px" }}>
    //         <Dropdown
    //           option={notIntReasonList}
    //           selectedValue={notIntSelected}
    //           setSelectedValue={(value) => {
    //             setNotIntSelected(value);
    //             if (value !== "Other") {
    //               setOther("");
    //             }
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   {notIntSelected.value === "Other" && (
    //     <div className={styles.box2}>
    //       <div
    //         className={styles.divide}
    //         style={{ width: "100%" }}
    //       >
    //         {/* <div className={styles.title}>
    //           <p className={styles.one}>
    //             Other Not Interested Reason
    //           </p>
    //           <p className={styles.two}></p>
    //         </div> */}
    //         <Label label="Other Not Interested Reason" required={false} />
    //         <textarea
    //           style={{
    //             width: "100%",
    //             resize: "none",
    //             marginTop: "17px",
    //           }}
    //           rows={4}
    //           cols={10}
    //           placeholder={"Enter Other Reason"}
    //           ref={otherRef}
    //           onChange={(e) => setOther(e.target.value)}
    //           value={other}
    //         ></textarea>
    //       </div>
    //     </div>
    //   )}

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>Note</p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Note" required={false} />
    //       <div>
    //         <TextInput
    //           title={"Enter Note"}
    //           style={{ backgroundColor: "#fff" }}
    //           ref={noteRef}
    //         ></TextInput>
    //       </div>
    //     </div>
    //   </div>

    //   <button
    //     className={styles.apply}
    //     style={{ marginTop: "auto" }}
    //     onClick={onSubmit}
    //   >
    //     Save
    //   </button>
    // </Modal>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    conatctHeader:state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(
  NotInterestedLeadModal
);
