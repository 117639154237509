import React, { FunctionComponent, useEffect, useState } from "react";
import commonStyles from "../../CommonStyles/CommonGraphStyles.module.css";
import IntBarChartOrg from "../IntBarChartOrg/IntBarChartOrg";
import IntBarTable from "../IntBarTable/IntBarTable";
import { Pie } from "react-chartjs-2";
import { colour_code } from '../../Values/constants';
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { DownloadReportCsv } from "../CommanDownloadFunction";
import { themeColors } from "../../Components/theme";

type props = {
  analyticsData: any;
  usersList: any;
  checked: boolean;
  teamsData: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  source: boolean;
  taskFilter?: any;
  leadFilter?: any;
  feedbackData?: any;
  theme: any;
};

const FeedbackGraph: FunctionComponent<props> = ({
  analyticsData,
  usersList,
  checked,
  teamsData,
  history,
  filter,
  source,
  taskFilter,
  leadFilter,
  feedbackData,
  theme
}) => {
  const [feedbackReportData, setFeedBackReportData] = useState<any>([]);
  const [feedbackReportHeader, setFeedBackReportHeader] = useState<any>([]);

  useEffect(() => {
    let headerArray: any[] = [];
    feedbackData?.map((list: any) => {
      return list?.stage?.map((li: any) => {
        return headerArray.push(li.stage);
      })
    })
    setFeedBackReportHeader(headerArray)
  }, [feedbackData]);

  const downLoadReport = () => {
    const csvReportData = DownloadReportCsv(feedbackData, feedbackReportHeader, usersList, "stage");
    setFeedBackReportData(csvReportData);
  }
  const tableContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const tableHeaderBorder = { borderBottom: theme ? "1px solid black" : "1px solid white" }
  return (
    <>
      {/* <p style={{ marginTop: "10px", fontWeight: 'bold', marginBottom: "0.5rem", fontSize: "0.9rem" }}>Feedback Summary<CSVLink onClick={downLoadReport} data={feedbackReportData}> <svg style={{ cursor: 'pointer',color:"#272944" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
      </svg></CSVLink></p>
      <div className="row" style={{
        display: "flex",
        width: "100%",
      }}>
        <div id="feedbackBar" className={commonStyles.graphContainer} style={{
          width: "65%",
        }}>
          <IntBarTable
            type={"stage"}
            data={analyticsData}
            heading={"Feedback Summary"}
            usersList={usersList}
            checked={checked}
            teamsData={teamsData}
            style={{ width: "100%" }}
            filter={filter}
            history={history}
            source={source}
            taskFilter={taskFilter}
            leadFilter={leadFilter}
          />
        </div>
        <div id="feedback" className={commonStyles.graphContainer} style={{
          width: "32%",
          marginLeft: "15px"
        }}>
          <IntBarChartOrg
            analyticsData={analyticsData}
            heading={"Feedback"}
            type={"stage"}
            color={colour_code}
            GraphType={Pie}
            style={{ width: "100%", height: "350px" }}
            history={history}
            filter={filter}
          />
        </div>
      </div> */}
      <div
        className="d-flex flex-column mb-5"
      // style={{
      //   display: "flex",
      //   width: "100%",
      // }}
      >
        <h6 className={commonStyles.heading}>
          Feedback Summary
          <CSVLink onClick={downLoadReport} data={feedbackReportData}>
            {" "}
            <svg
              style={{ cursor: "pointer", color: "rgb(39, 159, 159)"}}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </CSVLink>
        </h6>
        <div className="d-flex flex-row gap-4">
          <div
            id="feedbackBar"
            // className={commonStyles.graphContainer}
            // style={{
            //   width: "65%",
            // }}
            className="card p-4 shadow rounded w-100"
            style={{ minHeight: "350px", maxHeight: "350px", ...tableContainerStyles }}
          >
            <IntBarTable
              type={"stage"}
              data={analyticsData}
              heading={"Feedback Summary"}
              usersList={usersList}
              checked={checked}
              teamsData={teamsData}
              style={{ width: "100%" }}
              filter={filter}
              history={history}
              source={source}
              taskFilter={taskFilter}
              leadFilter={leadFilter}
            />
          </div>
          <div
            className="card p-4 shadow rounded"
            style={{
              minWidth: "40%",
              maxWidth: "40%",
              minHeight: "350px",
              maxHeight: "350px",
              ...tableContainerStyles
            }}
          >
            <div
              id="feedback"
              // className={commonStyles.graphContainer}
              // style={{
              //   width: "32%",
              //   marginLeft: "15px",
              // }}
              style={{
                minWidth: "100%",
                maxWidth: "100%",
                overflowX: "auto",
                ...tableContainerStyles,
                color: "#000"
              }}
            >
              <IntBarChartOrg
                analyticsData={analyticsData}
                heading={"Feedback"}
                type={"stage"}
                color={colour_code}
                GraphType={Pie}
                style={{ width: "100%", height: "350px" }}
                history={history}
                filter={filter}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    feedbackData: state.feedbackReportData.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(FeedbackGraph);
