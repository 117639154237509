import React, {FunctionComponent, ReactChild} from 'react';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import allReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user','contactHeaderList','taskHeaderList','organizationIdNameData','projectHeaderList','calllogsHeaderList','apiHeaderList','selectOrganizationId','faceBookUserPagesData','deleteLeadsStatusData'], // only 'user' part of state will be persisted
};
const persistedReducer = persistReducer(persistConfig, allReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);
type props = {
  children: ReactChild;
};

const GlobalState: FunctionComponent<props> = ({children}) => {
  return <Provider store={store}><PersistGate loading={null} persistor={persistor}>
  {children}
</PersistGate></Provider>;
};

export default GlobalState;