import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { connect, useDispatch } from "react-redux";
import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  changeLeadStage,
  fetchConstants,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { showSnackbarAction } from "../../../Redux/actions";
import { useHistory } from "react-router-dom";
import {themeColors} from "../../theme";
import Label from "../../Label";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";

type props = {
  notes: any[];
  contactDetail: any;
  close: () => void;
  open: boolean;
  userEmail?: string;
  theme:any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const NotesModal: FunctionComponent<props> = ({
  notes,
  contactDetail,
  close,
  open,
  userEmail,
  theme
}) => {
  const [load, setLoad] = useState(false);
  const [note, setNote] = useState("");

  const noteRef: any = useRef();
  const dispatcher = useDispatch();
  const history = useHistory();
  const onSubmit = () => {
    if (noteRef.current.value === "") {
      dispatcher(
        showSnackbarAction("Please Add Note!!", "error")
      );
    } else {
      addNoteFirebase(
        contactDetail.contactId,
        notes,
        noteRef.current.value,
        (data) => setLoad(data),
        dispatcher,
        close,
        userEmail
      );
    }
  };
  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
      {load === true && <Loading />}
      {open && <Backdrop>
        <motion.div
                   key="modal"
                   className="card p-4 shadow rounded"
                   style={{position: "absolute",minWidth: "400px",
                   ...containerStyles}}
                   variants={dropIn}
                   initial="hidden"
                   animate="visible"
                   exit="exit"
                 >
      <div className={styles.firstDiv}>
        <h5 
        // className={styles.contactForm}
        >
          Create New Note
        </h5>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      <div className={styles.line}></div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          {/* <div className={styles.title}>
            <p className={styles.one}>Add Note</p>
            <p className={styles.two}></p>
          </div> */}
          <Label label="Add Note" required={false} />
          <div style={{ marginTop: "7px" }}>
            <textarea
            className="form-control"
              style={{
                width: "100%",
                resize: "none",
                // marginTop: "17px",
              }}
              rows={4}
              cols={10}
              placeholder={"Enter Note"}
              ref={noteRef}
              onChange={(e) => setNote(e.target.value)}
              value={note}
            ></textarea>
          </div>
        </div>
      </div>

      <button
        className={styles.apply}
        style={{ marginTop: "2rem" }}
        onClick={onSubmit}
      >
        Save
      </button>
                 </motion.div>
        </Backdrop>}
    </>
    // <Modal
    //   className={styles.parent}
    //   isOpen={open}
    //   shouldCloseOnOverlayClick={true}
    //   overlayClassName={styles.overlay}
    //   shouldCloseOnEsc={true}
    //   onRequestClose={close}
    //   style={customStyles}
    // >
    //   {load === true && <Loading />}
    //   <div className={styles.firstDiv}>
    //     <h5 
    //     // className={styles.contactForm}
    //     >
    //       Create New Note
    //     </h5>
    //     <div className={styles.cross} onClick={close}>
    //       <IoIosClose size={35} color={"#808080"} />
    //     </div>
    //   </div>
    //   <div className={styles.line}></div>

    //   <div className={styles.box2}>
    //     <div
    //       className={styles.divide}
    //       style={{ width: "100%" }}
    //     >
    //       {/* <div className={styles.title}>
    //         <p className={styles.one}>Add Note</p>
    //         <p className={styles.two}></p>
    //       </div> */}
    //       <Label label="Add Note" required={false} />
    //       <div style={{ marginTop: "7px" }}>
    //         <textarea
    //         className="form-control"
    //           style={{
    //             width: "100%",
    //             resize: "none",
    //             // marginTop: "17px",
    //           }}
    //           rows={4}
    //           cols={10}
    //           placeholder={"Enter Note"}
    //           ref={noteRef}
    //           onChange={(e) => setNote(e.target.value)}
    //           value={note}
    //         ></textarea>
    //       </div>
    //     </div>
    //   </div>

    //   <button
    //     className={styles.apply}
    //     style={{ marginTop: "auto" }}
    //     onClick={onSubmit}
    //   >
    //     Save
    //   </button>
    // </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(NotesModal);