import React, { useState, useEffect, FunctionComponent } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { NEWS_COLUMNS } from "../../Values/tables";

import { filterStatus, searchAPIItem } from "../../Values/utils";

import CustomTable from "../../Components/CustomTable/CustomTable";
import styles from '../Analytics/Analytics.module.css'
import { fetchNews } from "../../Services/organizations";
import NewsModal from "../../Components/Modals/NewsModal/NewsModal";
import DeleteSelectedModal from "../../Components/Modals/DeleteSelectedModal/DeleteSelectedModal";
import { themeColors } from "../../Components/theme";
import Loading from "../../Components/Loading/Loading";

type props = {
  history: any;
  user: any;
  theme:any;
};

const NewsPanel: FunctionComponent<props> = ({ history, user,theme }) => {
  const [searchQuery, setsearchQuery] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [newsModal, setNewsModal] = useState(false);
  const [news, setNews] = useState<any[] | undefined>(undefined);
  const [status, setStatus] = useState("ALL");
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [owner, setOwner] = useState(false);
  const [deletePopup, setDeletePopop] = useState(false);

  useEffect(() => {
    if (user.organization_id) {
      fetchNews(user.organization_id, (val) => setNews(val));
    }
  }, [user.organization_id]);

  const clearSelectedRowsData = () => {
    setSelectedRowsData([]);
    setSelectedRows([]);
  };

  useEffect(() => {
    if (news) {
      let data: any[] = [];
      news.forEach((item) => {
        data.push(item);
      });
      setFilterData(data);
    }
  }, [news]);
  useEffect(() => {
    if (selectedRows.length > 0) {
      setOwner(true);
    } else if (selectedRows.length === 0) {
      setOwner(false);
    }
  }, [selectedRows]);
 
  const topBarStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, width: "97%" }
  const parentStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark }
  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "4%",...parentStyles}}>
        <div className={commonStyle.topBar} style={{ marginTop: "0px",...topBarStyles}}>
        {news === undefined && <Loading />}
          <Topbar
            history={history}
            title={"View News"}
            path={"/addUsers"}
            onChange={(val) => setsearchQuery(val)}
            filterText="Status"
            setColumnModal={(data) => setColumnModal(data)}
            show={false}
            owner={owner}
            deleteSelected={() => {
              setDeletePopop(true);
            }}
            showStatusBox={true}
            setApiModal={(data) => setNewsModal(data)}
            setStatus={(status) => setStatus(status)}
            status={status}
          />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
          <CustomTable
            tableColumns={NEWS_COLUMNS}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="NEWS VIEW"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            selectedRowsData={selectedRowsData}
            setSelectedRowsData={(data) => setSelectedRowsData(data)}
            setSearchedItem={(val) => setsearchQuery(val)}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    theme: state.theme.isLightMode,
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(NewsPanel);