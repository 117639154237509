import React, { useCallback, useEffect, useState } from "react";
import styles from "./TrailPeriodAlert.module.css";
import { themeColors } from "../theme";
import { connect, useDispatch } from "react-redux";
import { subscriptionDetailsData } from "../mockData";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { useHistory } from "react-router";

const TrailPreriodAlert = ({ expirationDate, role,organizationData }: any) => {
  const history = useHistory();


  return (
    <div className="d-flex flex-column align-items-center m-auto p-2 alert alert-danger">
      <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
        <p>
          {`Trial period allows maximum ${organizationData?.org_trial_period_users_licenses} users, ending in 7 days. Please make payment to avoid account deactivation.`}
        </p>
        {role === "Admin" && (
          <button
            onClick={() => {
              history.push("/paymentDetails");
            }}
            className={styles.button}
            // style={{margin:"0px 10px 0px 10px"}}
          >
            Renew Now
          </button>
        )}
      </div>

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
    expirationDate: state.organization.expirationDate,
    organizationData: state.organization.organizationData,
  };
};

export default connect(mapStateToProps)(TrailPreriodAlert);
