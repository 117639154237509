export const organizationIdNameReducer = (
    state = {
      data: [],
    },
    action: {
      type: "ORGANIZATION_ID_NAME";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "ORGANIZATION_ID_NAME":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };