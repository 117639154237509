import React, { useEffect, useState } from "react";
import styles from "./TextInput.module.css";
import { convertTimestampToDate } from "../../Values/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeInput = (props) => {
  const { title, style,length, disabled, value, ref,setTillDate } = props;
  const [errorMsg, setErrorMsg] = useState("");
  const [val, setVal] = useState(null); // State to hold selected date

  useEffect(() => {
    if (value) {
      const timestampToDate = convertTimestampToDate(value);
      const originalDateTime = new Date(timestampToDate);
      const formattedDateTimeString = originalDateTime.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
      console.log("Converted date:", formattedDateTimeString);
      setVal(formattedDateTimeString);
      setTillDate(formattedDateTimeString);
    }
  }, [value]);

  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().slice(0, 16);

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
      setTillDate(formattedDate);
      setVal(formattedDate);
    }
  };
  return (
    <>
    <div className={styles.input}>
      <DatePicker
        selected={val ? new Date(val) : null} // Convert string to Date for DatePicker
        onChange={handleDateChange} // Update state on change
        disabled={disabled}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd/MM /yyyy, h:mm a"
        className="form-control"
        ref={ref}
        minDate={new Date(formattedCurrentDate)} // Set min date
      />
    </div>
    {errorMsg.length !== 0 && <p className={styles.error}>{errorMsg}</p>}
  </>
  );
};

export default DateTimeInput;
