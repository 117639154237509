import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import commonStyle from "../../Screens/common.module.css";
import {
  useTable,
  useSortBy,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { getFilterObject } from "../../Values/utils";
import { connect, useDispatch } from "react-redux";
import TableHeader from "../TableHeader/TableHeader";
import CustomToggle from "../CustomToggle";
import {
  updateUserImport,
  updateUserStatus,
} from "../../Services/users";

import {
  fetchApi,
  updateAPIStatus,
  updateOrganizationStatus,
} from "../../Services/organizations";
import ColumnManagerModal from "../Modals/ColumnManagerModal/ColumnManager";
import {
  setClearFilter,
  showSnackbarAction,
} from "../../Redux/actions";
import { useHistory } from "react-router";
import { FiEdit3 } from "react-icons/fi";
import Loading from "../Loading/Loading";

import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import AlertDialog from "../PopupDialogBox/Popup";
import DistributionModel from "../Modals/DistributionModel/DistributionModel";
import { MdDelete } from "react-icons/md";
import { themeColors } from "../../Components/theme";
import FilterListIcon from '@mui/icons-material/FilterList';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type props = {
  tableColumns: any[];
  tableRows: any[] | undefined;
  selectedRows: any[];
  setSelectedRows: (data: any[]) => void;
  tableType:
  | "Organization"
  | "Super admin"
  | "User"
  | "Task"
  | "CallLogs"
  | "API"
  | "NEWS"
  | "LeadDistributor"
  | "DrillDown"
  | "Custom Button"
  ;
  showColumnModal: boolean;
  hideColumnModal: () => void;
  selectedRowsData?: any[];
  setSelectedRowsData?: (data: any[]) => void;
  setSearchedItem?: (text: any) => void;
  organization: any;
  organizationUsers: any[];
  role: any;
  setBranch?: (text: boolean) => void;
  setUid?: (data: string) => void;
  user: any;
  setApiModal?: (data: boolean) => void;
  setApiEdit?: (data: boolean) => void;
  setApiData?: (data: any) => void;
  setPageNumber?: (data: any) => void;
  setPageCount?: (data: any) => void;
  theme: any;
  setToggleCondition?: any;
};

const ProjectCustomTable: FunctionComponent<props> = ({
  tableColumns,
  tableRows,
  selectedRows,
  setSelectedRows,
  tableType,
  showColumnModal,
  hideColumnModal,
  selectedRowsData,
  setSelectedRowsData,
  setSearchedItem,
  organization,
  organizationUsers,
  role,
  setBranch,
  setUid,
  user,
  setApiModal,
  setApiEdit,
  setApiData,
  setPageNumber,
  setPageCount,
  theme,
  setToggleCondition
}) => {
  const dispatcher = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [showDistributionModal, setShowDistributionModal] =
    useState(false);
  const [load, setLoad] = useState(false);
  const [distributionData, setDistributionData] =
    useState();
  const [indexs, setIndex] = useState(0);
  const selectAll = (checked: boolean) => {

    if (
      tableType === "API"
    ) {

      if (checked === true) {
        if (page) {

          let selectRowsIndex: any[] = [...selectedRows];

          Array.from(Array(page?.length).keys()).forEach(
            (index) => {
              selectRowsIndex.push(
                pageSize * pageIndex + index + 1
              );
            }
          );
          setSelectedRows(selectRowsIndex);
        }

        if (page) {
          if (selectedRowsData) {
            let selectRows: any[] = [...selectedRowsData];
            page.forEach((item: any) => {
              selectRows.push(item.original);
            });
            setSelectedRowsData &&
              setSelectedRowsData(selectRows);
          } else {
            let selectRows: any[] = [];
            page.forEach((item: any) => {
              selectRows.push(item.original);
            });
            setSelectedRowsData &&
              setSelectedRowsData(selectRows);
          }
        }
      } else {
        setSelectedRows([]);
        setSelectedRowsData && setSelectedRowsData([]);
      }
    } else {
      if (checked === true) {
        setSelectedRows(
          Array.from(Array(page?.length).keys())
        );
      } else {
        setSelectedRows([]);
      }
    }
  };

  const columns = useMemo(
    () => tableColumns,
    [tableColumns]
  );
  const data = useMemo(
    () => (tableRows ? tableRows : []),
    [tableRows]
  );
  const [currentLimit, setCurrentLimit] = useState(20);

  const [recordCounts, setRecordsCount] = useState<any[]>(
    []
  );
  const [CurrLeader, setCurrLeader] = useState(-1);
  const [active, setActiveUsers] = useState(0);
  const tableInstance: any = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    useColumnOrder,
    usePagination
  );

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    allColumns,
    //@ts-ignore
    setColumnOrder,
    //@ts-ignore
    toggleHideAllColumns,
  } = tableInstance;

  const { pageIndex, pageSize } = state;
  useEffect(() => {
    setPageSize(100);
    const savedColumns = localStorage.getItem("columns");
    if (savedColumns) {
      setColumnOrder(JSON.parse(savedColumns));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (organizationUsers) {
      let activeUsers = organizationUsers.filter(
        (item) => item.status === "ACTIVE"
      );
      setActiveUsers(activeUsers.length);
    }
  }, [organizationUsers]);

  useEffect(() => {
    let l = [10, 25, 50, 100, 200, 500];
    l.push(rows.length);
    setRecordsCount(l);
  }, [rows]);

  const setColumns = (columnList: any) => {
    setColumnOrder(columnList);
    localStorage.setItem(
      "columns",
      JSON.stringify(columnList)
    );
  };
  useEffect(() => {
    if (tableRows) {
      getFilterObject(tableRows, dispatcher);
    }
  }, [tableRows, dispatcher]);

  const onCheck = (event: any, row: any, index: number) => {
    if (
      tableType === "API"
    ) {
      if (event.target.checked) {
        let data = [...selectedRows];

        data.push(index);
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          rowData.push(row);
          setSelectedRowsData &&
            setSelectedRowsData(rowData);
        }
      } else if (!event.target.checked) {
        let data = [...selectedRows];
        let item = selectedRows.indexOf(index);
        if (item > -1) {
          data.splice(item, 1);
        }
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          let val = selectedRowsData.indexOf(row);
          if (val > -1) {
            rowData.splice(val, 1);
          }
          setSelectedRowsData &&
            setSelectedRowsData(rowData);
        }
      }
    }

    if (event.target.checked) {
      let data = [...selectedRows];
      data.push(index);
      setSelectedRows(data);
    } else if (!event.target.checked) {
      let data = [...selectedRows];
      let item = selectedRows.indexOf(index);
      if (item > -1) {
        data.splice(item, 1);
      }
      setSelectedRows(data);
    }
  };
  const clearFilter = () => {
    setSearchedItem && setSearchedItem("");
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== "columns" && key !== 'isLightMode' && key !== 'lastAlertTime') {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    if (tableType === 'API') {
      history.push('/projects');
    }
  };
  const location: any = useLocation();

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }

  const footerStyles = {
    backgroundColor: theme ? themeColors.thBackgroundColor : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }

  const buttonStyles = {
    backgroundColor: theme ? themeColors.buttonPrimary : themeColors.buttonPrimaryDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }
  const trBorderStyles = {
    borderBottom: theme ? `1px solid ${themeColors.trBorderColor}` : `1px solid ${themeColors.trBorderColorDark}`,
  }


  return (
    <>
      <div className={commonStyle.child} id="scrollable">
        {load === true && <Loading />}
        <div style={{ width: "max-content" }}>
          <TableHeader
            headerGroups={headerGroups}
            selectAll={(data) => {
              selectAll(data);
              setChecked(data);
            }}
            panel={tableType}
            allCheck={checked}
          />
          <InfiniteScroll
            style={{ overflow: "visible", minHeight: "100vh", width: "100%", marginBottom: "-10rem", ...tdStyles }}
            dataLength={currentLimit}
            next={() => setCurrentLimit(currentLimit + 20)}
            hasMore={rows.length > currentLimit}
            loader={""}
            scrollableTarget="scrollable"
            className={commonStyle.infiniteScroll}
          >
            {tableRows && (
              <div {...getTableBodyProps()}>
                {page
                  .slice(0, currentLimit)
                  .map((row: any, index: number) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        style={
                          row.original.status === "INACTIVE"
                            ? {
                              // backgroundColor: "#D3D3D3",
                              // borderWidth: 2,
                              display: "flex",
                              flexDirection: "row",
                              ...trBorderStyles
                            }
                            : {
                              // backgroundColor: "#ffffff",
                              display: "flex",
                              flexDirection: "row",
                              ...trBorderStyles
                            }
                        }
                      >
                        <div className={commonStyle.sticky} style={tdStyles}>
                          <input
                            style={{ marginRight: "10px", accentColor: "#272944" }}
                            type="checkbox"
                            onChange={(e) => {
                              if (
                                row.original
                                  .transfer_status === true
                              ) {
                                dispatcher(
                                  showSnackbarAction(
                                    "This Lead Cannot be Transfered",
                                    "error"
                                  )
                                );
                              } else {
                                onCheck(
                                  e,
                                  row.original,
                                  pageSize * pageIndex +
                                  index +
                                  1
                                );
                              }
                            }}
                            disabled={row.original.project_status === "Launched" ? true : false}
                            checked={selectedRows.includes(
                              pageSize * pageIndex +
                              index +
                              1
                            )}
                          />
                          {tableType !== "Task" && (
                            <FiEdit3
                              size={15}
                              color="#272944"
                              style={{ marginRight: '7px' }}
                              onClick={() => {
                                if (tableType === "API") {
                                  if (row.original.transfer_status === true) {
                                    dispatcher(
                                      showSnackbarAction(
                                        'Lead With Transfer Status TRUE Cannot be Edited!!',
                                        'error'
                                      )
                                    );
                                  } else {
                                    let data = { ...row.original };
                                    history.push({
                                      pathname: '/editProjects',
                                      state: {
                                        detail: data,
                                      },
                                    });
                                  }
                                }
                              }}
                            />
                          )}
                          {showDistributionModal && (
                            <DistributionModel
                              open={showDistributionModal}
                              organization_id={
                                user.organization_id
                              }
                              close={() => {
                                setShowDistributionModal(
                                  false
                                );
                              }}
                              data={distributionData}
                              index={indexs}
                            />
                          )}
                          {pageSize * pageIndex + index + 1}
                        </div>
                        {/*  render table data all at a time */}
                        {row.cells.map((cell: any) => {
                          return (
                            <div
                              className={commonStyle.td}
                              {...cell.getCellProps()}
                              style={
                                tableType === "LeadDistributor"
                                  ? { minWidth: "210px", maxWidth: "210px", ...tdStyles }
                                  : { ...tdStyles }
                              }
                              onClick={() => {
                                console.log("Single Headers Name :", cell.column.Header);

                                if (
                                  cell.column.Header === 'Project Resources' &&
                                  tableType === 'API'
                                ) {
                                  if (cell.row.original.transfer_status === true) {
                                    dispatcher(
                                      showSnackbarAction(
                                        'This is a Transferred Lead!!',
                                        'error'
                                      )
                                    );
                                  } else {
                                    history.push({
                                      pathname: "/projectDetails",
                                      state: {
                                        AllProjectdata: tableRows,
                                        detail: {
                                          ...cell.row.original,
                                          created_at: cell.row.original["created_at"]
                                            ? cell.row.original["created_at"].toDate()
                                              ? cell.row.original["created_at"].toDate()
                                              : ""
                                            : "",
                                        },
                                      },
                                    });
                                  }
                                }
                              }}
                            >
                              {cell.column.Header === 'Project Resources' &&
                                tableType === 'API' ? (
                                <button type="button" style={{ ...footerStyles, height: 30, width: 70, borderRadius: 10 }}>Upload</button>
                              ) : (
                                cell.render("Cell")
                              )}
                            </div>
                          );
                        })}

                      </div>
                    );
                  })}
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: "center", borderRadius: " 0.5rem", padding: "0.5rem", marginTop: "1rem", marginBottom: "-1rem", ...footerStyles }}>
      <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
          <div style={{display:"flex",justifyContent:"flex-start",textAlign:"start",flexDirection:"row",width:"37%"}}>
            <div>
              <button
                className={commonStyle.clearFilterEvent}
                onClick={() => {
                  clearFilter();
                  setChecked(false);
                  selectAll(false);
                }}
              >
                <FilterListIcon style={{ height: 15, width: 15, marginRight: '10px' }} />
                Clear Filter
              </button>
            </div>
          </div>
          <div>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to Page:{" "}
              <input
                type="number"
                className={commonStyle.nSelecct}
                defaultValue={pageIndex}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value)
                    : 0;
                  gotoPage(pageNumber - 1);
                  setPageNumber && setPageNumber(pageNumber);
                }}
                style={{ width: "50px" }}
              />
            </span>
            <select
              value={pageSize}
              className={commonStyle.dropDownSelecct}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageCount &&
                  setPageCount(Number(e.target.value));
              }}
            >
              {recordCounts.map((pagesize) => (
                <option key={pagesize} value={pagesize}>
                  Show {pagesize}
                </option>
              ))}
            </select>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                gotoPage(0);
                setChecked(false);
                setPageNumber && setPageNumber(1);
              }}
              disabled={!canPreviousPage}
              style={!canPreviousPage ? { backgroundColor: "#60606080" } : {}}
            >
              {"<<"}
            </button>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                previousPage();
                setChecked(false);
                setPageNumber && setPageNumber(pageIndex);
              }}
              style={!canPreviousPage ? { backgroundColor: "#60606080" } : {}}
              disabled={!canPreviousPage}
            >
              <ChevronLeftIcon />
            </button>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                nextPage();
                setChecked(false);

                setPageNumber && setPageNumber(pageIndex + 2);
              }}
              style={!canNextPage ? { backgroundColor: "#60606080" } : {}}
              disabled={!canNextPage}
            >
              <ChevronRightIcon />
            </button>
            <button
              className={commonStyle.addBox}
              onClick={() => {
                gotoPage(pageCount - 1);
                setChecked(false);
                setPageNumber && setPageNumber(pageCount);
              }}
              style={!canNextPage ? { backgroundColor: "#60606080" } : {}}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>

        <div className={commonStyle.footer_table}>

          <p
            className={commonStyle.countHead}
          >Selected Records: {selectedRows.length}</p>
          <p
            className={commonStyle.countHead}
          >Total Records: {rows.length ? rows.length : 0}</p>
        </div>
      </div>
      {showColumnModal === true && (
        <ColumnManagerModal
          columnsList={allColumns}
          open={showColumnModal}
          close={hideColumnModal}
          onChangeColumns={(data: any[]) =>
            setColumns(data)
          }
          toggleAllColumns={toggleHideAllColumns}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
    organization: state.organization,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(ProjectCustomTable);
