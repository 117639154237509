const facebookPagesReducer = (
    state = {
        data: [],
    },
    action: {
        type: "SET_FACEBOOK_PAGES_DATA";
        payload: any;
    }
) => {
    switch (action.type) {
        case "SET_FACEBOOK_PAGES_DATA":
            return { ...state, data: action.payload };
        default:
            return state;
    }
};

export default facebookPagesReducer;