import React, { FunctionComponent, useEffect, useRef } from "react";
import { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddContacts/AddContacts.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import { fetchFAQ, updateFAQ } from "../../Services/resources";
import { useLocation } from "react-router-dom";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";

type props = {
  history: any;
  user: any;
  propsData: any;
  theme: any;
};
const EditFAQ: FunctionComponent<props> = ({ history, user, propsData, theme }) => {
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [faqList, setfaqList] = useState<any[] | undefined>(undefined);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [sNumber, setSNumber] = useState("");
  const [number, setNumber] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [faqId, setFaqId] = useState("");
  const [allData, setAllData] = useState<any[]>([]);
  const dispatcher = useDispatch();
  const questionRef: any = useRef();
  const answerRef: any = useRef();
  const srNumberRef: any = useRef();
  const videoUrlRef: any = useRef();

  useEffect(() => {
    if (location.state) {
      const data: any = location?.state;
      setAllData(data.allData)
      setFaqId(data.item.id);
      setQuestion(data.item.question);
      setAnswer(data.item.answer);
      setSNumber(data.item.serialNumber);
      setNumber(data.item.serialNumber);
      setVideoUrl(data.item.videoUrl);
    }
  }, [location]);

  useEffect(() => {
    if (user.organization_id) {
      fetchFAQ(user.organization_id, (data) => setfaqList(data));
    }
  }, [user]);
  
  const handleCheck = () => {
    if (questionRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Question!!", "error"));
      return false;
    } 
    // else if (videoUrlRef.current.value === "") {
    //   dispatcher(showSnackbarAction("Please Enter Video URL!!", "error"));
    //   return false;
    // }
    else if (answerRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Answer!!", "error"));
      return false;
    }
    else if (srNumberRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Serial Number!!", "error"));
      return false;
    }
    // else if (srNumberRef.current.value) {
    //   const numberS = faqList?.some(m => m.serialNumber === srNumberRef.current.value);
    //   alert(numberS);
    //   if (numberS) {
    //     dispatcher(showSnackbarAction("Serial Number already exists  !!", "error"));
    //     return false;
    //   }
    //   return true;
    // }
    else {
      return true;
    }
  };

  const update = (e: any) => {
    e.preventDefault();
    let filterDataArr: Array<Object> = [];
    const val = handleCheck();
    if (val) {
        const filterData = allData?.filter((list: any) => list.id != faqId && list?.profile !== "Super Admin");
        filterDataArr.push({ "answer": answer, "id": faqId, "question": question, "serialNumber": sNumber,"videoUrl":videoUrl}, ...filterData);
        setLoad(true);
        updateFAQ(
            user.organization_id,
            filterDataArr, // Added missing comma here
            dispatcher,
            (data: boolean) => setLoad(data),
            history,
        );
    }
};

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

  return (
    <div className="container-fluid p-4 d-flex flex-column gap-4" style={containerStyles}>
      <h3>Edit FAQ</h3>
      <div className="w-100 d-flex flex-column gap-2" style={cardContainerStyles}>
        <form className="card p-4 shadow rounded" style={cardContainerStyles}>
        <div style={{display:'flex',justifyContent:"space-between",gap:"0.5rem"}}>
          <div className="mb-3 col-0.4">
            <Label label="S.NO" required />
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              ref={srNumberRef}
              onChange={(e) => setSNumber(e.target.value)}
              value={sNumber}
            />
          </div>
          <div className="mb-3 col-11">
            <Label label="Question" required />
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              ref={questionRef}
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
              placeholder={"Enter Question"}
            />
          </div>
          </div>
          <div className="mb-3">
            <Label label="Video Link"/>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              ref={videoUrlRef}
              onChange={(e) => setVideoUrl(e.target.value)}
              value={videoUrl}
            />
          </div>
          <div className="mb-3">
            <Label label="Answer" required />
            <textarea
              className="form-control"
              id="exampleInputPassword1"
              rows={5}
              ref={answerRef}
              onChange={(e) => setAnswer(e.target.value)}
              value={answer}
            />
          </div>
          <button
            type="submit"
            // className="btn btn-primary mt-5"
            className={styles.button}
            style={{ backgroundColor: "#272944" }}
            onClick={(e) => update(e)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(EditFAQ);