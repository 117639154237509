export const feedbackReportReducer = (
  state = {
    data: [],
  },
  action: {
    type: "FEEDBACK_REPORT_DATA";
    payload: any;
  }
) => {

  switch (action.type) {
    case "FEEDBACK_REPORT_DATA":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export const interestedBudgetReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "INTERESTED_BUDGET_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "INTERESTED_BUDGET_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

 export const interesteProjectReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "INTERESTED_PROJECT_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "INTERESTED_PROJECT_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const interestedLocationReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "INTERESTED_LOCATION_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "INTERESTED_LOCATION_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const interestedPropertyTypeReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "INTERESTED_PROPERTY_TYPE_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "INTERESTED_PROPERTY_TYPE_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const interestedPropertyStageReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "INTERESTED_PROPERTY_STAGE_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "INTERESTED_PROPERTY_STAGE_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const lostReasonReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "LOST_REASON_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "LOST_REASON_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const notInterestedReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "NOT_INTERESTED_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "NOT_INTERESTED_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const completedTaskReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "COMPLETE_TASK_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "COMPLETE_TASK_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const pendingTaskReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "PENDING_TASK_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "PENDING_TASK_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const overDueReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "OVERDUE_TASK_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "OVERDUE_TASK_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export const callBackReasonReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "CALL_BACK_REASON_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "CALL_BACK_REASON_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };
  export const callLogsReportReducer = (
    state = {
      data: [],
    },
    action: {
      type: "CALL_BACK_REPORT_DATA";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "CALL_BACK_REPORT_DATA":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };
 
 
  
  