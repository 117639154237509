import React, { FunctionComponent, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AnalyticsGraph from "../AnalyticsGraph/AnalyticsGraph";
import IntBarTable from "../IntBarTable/IntBarTable";
import commonStyles from "../../CommonStyles/CommonGraphStyles.module.css";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import {themeColors} from "../../Components/theme";
import { CSVLink } from "react-csv";
import { DownloadReportCsv } from "../CommanDownloadFunction";


type props = {
  state: any;
  interestedState: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";

  analyticsData: any;
  usersList: any;
  checked: boolean;
  teamsData: any;

  source: boolean;
  taskFilters?: any;
  leadFilters?: any;
  callFilters?: any;
  durationData?: any;
  theme:any;
  showTrendAnalytics?: boolean;
  setShowTrendAnalytics?: any;
};

const Trends: FunctionComponent<props> = ({
  state,
  interestedState,
  history,
  filter,
  analyticsData,
  usersList,
  checked,
  teamsData,
  source,
  taskFilters,
  leadFilters,
  callFilters,
  durationData,
  theme,
  showTrendAnalytics,
  setShowTrendAnalytics
}) => {
  const [durationReportData, setDurationReportData] = useState<any>([]);
  const [durationReportHeader, setDurationReportHeader] = useState<any>([]);

  useEffect(() => {
    let headerArray: any[] = [];
    durationData?.map((list: any) => {
      return list?.duration?.map((li: any) => {
        return headerArray.push(li.duration);
      })
    })
    setDurationReportHeader(headerArray)
  }, [durationData]);
  const downLoadReport = () => {
    let filterData: any[] = [];
    const csvReportData = DownloadReportCsv(durationData, durationReportHeader, usersList, "duration");
    csvReportData.map((list: any) => {
      return filterData.push({ "name": list.name, "total": list.total, "(0)sec": list[0], "(0-30)sec": list[30], "(31-60)sec": list[60], "(61-120)sec": list[120], "(>120)sec": list.Other })
    })
    setDurationReportData(filterData);
  }
  const areaChartOptions:any = {
    options: {
      chart: {
        animations: {
          enabled: false,
          // easing: 'easeinout',
          // speed: 800,
          // animateGradually: {
          //     enabled: true,
          //     delay: 150
          // },
          // dynamicAnimation: {
          //     enabled: true,
          //     speed: 350
          // }
      },
        background: theme ? 'rgba(0, 0, 0, 0)' : 'rgba(26, 43, 60, 1)',
        id: "apexchart-example",
        toolbar:{
          show:true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            // selection: true,
            // zoom: true,
            // zoomin: true,
            // zoomout: true,
            // pan: true,
            // reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              // dateFormatter(timestamp) {
              //   return new Date(timestamp).toDateString()
              // }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        }
      },
      xaxis: {
        categories: state.labels,
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          style: {
            colors: theme ? "#000":"#fff",
        },
        },
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          style: {
            colors: theme ? "#000":"#fff",
        },
        },
      },
      colors: ["#71CACC"],
    },
    series: [
      {
        name: "Calls",
        data: state.datasets[0].data,
      },
    ],
  };
  const areaChartOptions2:any = {
    options: {
      chart: {
        animations: {
          enabled: false,
          // easing: 'easeinout',
          // speed: 800,
          // animateGradually: {
          //     enabled: true,
          //     delay: 150
          // },
          // dynamicAnimation: {
          //     enabled: true,
          //     speed: 350
          // }
      },
        background: theme ? 'rgba(0, 0, 0, 0)' : 'rgba(26, 43, 60, 1)',
        id: "apexchart-example",
        toolbar:{
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            // selection: true,
            // zoom: true,
            // zoomin: true,
            // zoomout: true,
            // pan: true,
            // reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              // dateFormatter(timestamp) {
              //   return new Date(timestamp).toDateString()
              // }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        }
      },
      xaxis: {
        categories: interestedState.labels,
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          style: {
            colors: theme ? "#000":"#fff",
        },
        },
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          style: {
            colors: theme ? "#000":"#fff",
        },
        },
      },
      colors: ["#87CBAC"],
    },
    series: [
      {
        name: "Interested Leads",
        data: interestedState.datasets[0].data,
      },
    ],
  };
  // const lineChartOptions = {
  //   options: {
  //     chart: {
  //       id: "apexchart-example",
  //     },
  //     colors: ["#71CACC"],
  //     xaxis: {
  //       categories: interestedState.labels,
  //       tooltip: {
  //         enabled: false,
  //       },
  //     },
  //     // fill: {
  //     //   type: "gradient",
  //     //   gradient: {
  //     //     shade: "dark",
  //     //     gradientToColors: ["#FDD835"],
  //     //     shadeIntensity: 1,
  //     //     type: "horizontal",
  //     //     opacityFrom: 1,
  //     //     opacityTo: 1,
  //     //     stops: [0, 100, 100, 100],
  //     //   },
  //     // },
  //   },
  //   series: [
  //     {
  //       name: "Calls",
  //       data: interestedState.datasets[0].data,
  //     },
  //   ],
  // };
  
  const calculateWidth = () => {
    if( areaChartOptions.series[0].data.length > 10){
      return areaChartOptions.series[0].data.length * 100;
    }else{
      return "100%";
    }
  }

  const calculateWidth2 = () => {
    if( areaChartOptions2.series[0].data.length > 10){
      return areaChartOptions2.series[0].data.length * 100;
    }else{
      return "100%";
    }
  }

  const tableContainerStyles = {backgroundColor:theme ? themeColors.cardBackgroundColor:themeColors.cardBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
      {/* <div id="trends">
        <AnalyticsGraph
          GraphType={Line}
          data={state}
          Heading={"Call Log Trends Summary"}
          title={"Customer Name"}
          value={"Follow Up Type"}
          filter={filter}
          type="other"
          history={history}
        />
      </div>
      {source === false && (
        <div id="trends" className={commonStyles.graphContainer}>
          <IntBarTable
            type={"callLogs"}
            data={analyticsData}
            heading={"Call Logs Summary"}
            usersList={usersList}
            checked={checked}
            teamsData={teamsData}
            style={{ width: "100%" }}
            show={false}
            filter={filter}
            history={history}
            source={source}
            taskFilter={taskFilters ? taskFilters : {}}
            leadFilter={leadFilters ? leadFilters : {}}
            callFilter={callFilters ? callFilters : {}}
          />
        </div>
      )}
      <div id="trends">
        <AnalyticsGraph
          GraphType={Line}
          data={interestedState}
          Heading={"Interested Lead Trends Summary"}
          title={"Customer Name"}
          value={"Follow Up Type"}
          filter={filter}
          type="other"
          history={history}
        />
      </div> */}
      <div className="d-flex flex-column mb-2">
      <h6 className={commonStyles.heading}>Calling Trend Summary</h6>
      <div
          className="card p-4 shadow rounded"
          style={{
            minWidth:"100%",
            maxWidth:"100%",
            minHeight: "400px",
            maxHeight: "400px",
            ...tableContainerStyles
          }}
        >
          <div
            id="trends"
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              overflowX: "auto",
              color:"#000"
            }}
          >
            {/* <AnalyticsGraph
            GraphType={Line}
            data={state}
            Heading={"Call Log Trends Summary"}
            title={"Customer Name"}
            value={"Follow Up Type"}
            filter={filter}
            type="other"
            history={history}
          /> */}
            <Chart
              options={areaChartOptions.options}
              series={areaChartOptions.series}
              type="area"
              width={calculateWidth()}
              height={280}
            />
          </div>
        </div>
        {source === false && (
          <>
            <h6 className={commonStyles.heading} style={{marginTop:"2.5rem"}}>Call Log Summary<CSVLink onClick={downLoadReport} data={durationReportData}><svg style={{ cursor: 'pointer', color: "rgb(39, 159, 159)" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
          </svg></CSVLink></h6>
          <div
            id="trends"
            className="card p-4 shadow rounded w-100 mt-2 "
            style={{ minHeight: "400px", maxHeight: "400px",marginTop:"-2rem",...tableContainerStyles }}
          >
            <IntBarTable
              type={"callLogs"}
              data={analyticsData}
              heading={"Call Logs Summary"}
              usersList={usersList}
              checked={checked}
              teamsData={teamsData}
              style={{ width: "100%" }}
              show={false}
              filter={filter}
              history={history}
              source={source}
              taskFilter={taskFilters ? taskFilters : {}}
              leadFilter={leadFilters ? leadFilters : {}}
              callFilter={callFilters ? callFilters : {}}
            />
          </div>
          </>
        )}
      {/* <div id="trends">
        <AnalyticsGraph
          GraphType={Line}
          data={interestedState}
          Heading={"Interested Lead Trends Summary"}
          title={"Customer Name"}
          value={"Follow Up Type"}
          filter={filter}
          type="other"
          history={history}
        />
      </div> */}
      {
        showTrendAnalytics && (
          <>
      <h6 className={commonStyles.heading} style={{marginTop:"2.5rem"}}>Interested Lead Trend Summary</h6>
      <div className="card p-4 shadow rounded" style={tableContainerStyles}>
        <div
          id="trends"
          style={{
            minWidth: "100%",
            maxWidth: "100%",
            overflowX: "auto",
            color:"#000"
          }}
        >
          {/* <AnalyticsGraph
          GraphType={Line}
          data={state}
          Heading={"Call Log Trends Summary"}
          title={"Customer Name"}
          value={"Follow Up Type"}
          filter={filter}
          type="other"
          history={history}
        /> */}
          <Chart
            options={areaChartOptions2.options}
            series={areaChartOptions2.series}
            type="area"
            width={calculateWidth2()}
            height={280}
          />
        </div>
      </div>
      </>
        )
      }
      {!showTrendAnalytics && (
        <div className="text-center mt-5">
        <button className={commonStyles.filterBox2}  onClick={()=>{setShowTrendAnalytics(!showTrendAnalytics)}}>Show More</button>
        </div>
      )}
    </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    durationData: state.callLogReportData.data?.report,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(Trends);
