import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./PasswordReset.module.css";
import background from "../../Assets/Images/backgrounrsignup.jpg";
import PasswordInput from "../../Components/Password/PasswordInput";
import { RiLockPasswordFill } from "react-icons/ri";
import qs from "qs";
import { connect, useDispatch } from "react-redux";
import { resetPassword } from "../../Services/auth";
import { passwordValidate } from "../../Values/validatorsnew";
import LeadRubixLogo from "../../Assets/Images/leadrubixlogo.png";
import PlayStore from "../../Assets/Images/playstore.svg"
import AppStore from "../../Assets/Images/appstore.svg";
import { Circles} from 'react-loader-spinner';

type props = {
  history: any;
  firstLogin: boolean;
};

let oobCode: any;

const PasswordReset: FunctionComponent<props> = ({ history, firstLogin }) => {
  const confirmPasswordRef: any = useRef();
  const passwordRef: any = useRef();
  const dispatcher = useDispatch();
  const [reset, setReset] = useState(false);
  const [load, setLoad] = useState(false);

  const Reset = async (password: string, confirmPassword: string) => {
    resetPassword(
      password,
      confirmPassword,
      dispatcher,
      oobCode,
      (value) => setLoad(value),
      (val) => setReset(val),
      firstLogin,
      history
    );
  };

  useEffect(() => {
    const data = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    if (data.oobCode === undefined) {
      if (firstLogin !== true) {
        history.push("/");
      }
    }
    oobCode = data.oobCode;
  }, [history, firstLogin]);
  return (<>
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundColor: "rgba(0, 0, 0, 0.02)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div style={{ display: "flex", height: "80vh", alignItems: "center", justifyContent: "center", width: "40%" }}>
        <div className={styles.box2} style={{
          background: "#363853"
        }}>
          <div style={{ display: "flex", width: "100%", height: "100px" }}>
            <img
              src={LeadRubixLogo}
              alt=""
              width="90%"
              height="50px"
              style={{ marginTop: "15px", marginLeft: "16px" }}
            />
          </div>
          <div className={styles.form}>
            <div className={styles.header}>
              <h4 style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px", color: "white" }}>Welcome</h4>
              <p className={styles.headerText} style={{ fontSize: "14px", marginTop: "20px" }}>
                Reset Password for your Your Account.
              </p>
            </div>
            <div>
              {reset === true ? (
                <>
                  <p className={styles.completeText}>Password Reset Successfully</p>
                </>
              ) : (
                <div className={styles.inputContainer}>
                  <div className={styles.form}>
                    <div className={styles.input}>
                      <PasswordInput
                        title={"Password"}
                        ref={passwordRef}
                        validator={passwordValidate}
                      >
                        <RiLockPasswordFill color={"#808080"} />
                      </PasswordInput>
                    </div>
                    <div className={styles.input}>
                      <PasswordInput
                        title={"Confirm Password"}
                        ref={confirmPasswordRef}
                        validator={passwordValidate}
                        onPress={() =>
                          Reset(
                            passwordRef.current?.value,
                            confirmPasswordRef.current?.value
                          )
                        }
                      >
                        <RiLockPasswordFill color={"#808080"} />
                      </PasswordInput>
                    </div>
                  </div>
                  <div className={styles.buttonView}>
                    <button
                      className={styles.button}
                      onClick={() =>
                        Reset(
                          passwordRef.current?.value,
                          confirmPasswordRef.current?.value
                        )
                      }
                    >
                       {load ?
                        <span style={{display:'flex',textAlign:"center",justifyContent:"center"}}><Circles
                        color="#fff" height={20}  width='100%' /></span> : 'Reset'
                      }
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.storeIcons}>
              <div className={styles.iconContainer1}>
                <a
                //  href="https://play.google.com/store/apps/details?id=com.readpro" target="_blank"
                 >
                  <img
                    src={PlayStore}
                    alt={"logo"}
                    width={"25px"}
                  />
                </a>
              </div>
              <div className={styles.iconContainer2}>
                <a 
                // href="https://apps.apple.com/in/app/read-pro/id1566457452" target="_blank"
                >
                  <img
                    src={AppStore}
                    alt={"logo"}
                    width={"40px"}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firstLogin: state.user.firstLogin,
  };
};

export default connect(mapStateToProps)(PasswordReset);
