import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./ContactDetails.module.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import { BiPencil } from "react-icons/bi";
import ContactButton from "../../Components/Button/ContactButton";
import { useLocation } from "react-router";
import {
  changeLeadStage,
  fetchContactResources,
  fetchLeadTasks,
  toggleis_button_called,
} from "../../Services/contacts";
import {
  getDateAndTime,
  properFormat,
} from "../../Values/utils";
import {
  HiVideoCamera,
  HiOutlinePhotograph,
  HiOutlineMicrophone,
  HiPhone,
} from "react-icons/hi";
import { ImFileText2 } from "react-icons/im";
import InterestedLeadModal from "../../Components/Modals/InterestedLeadModal/InterestedLeadModal";
import NotInterestedLeadModal from "../../Components/Modals/NotInterestedModal/NotInterestedModal";
import CallBackModal from "../../Components/Modals/CallBackModal/CallBackModal";
import LostModal from "../../Components/Modals/LostModal/LostModal";
import CreateModal from "../../Components/Modals/CreateModal/CreateModal";
import NotesModal from "../../Components/Modals/NotesModal/NotesModal";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RescheduleModal from "../../Components/Modals/RescheduleModal/RescheduleModal";
import ImportModal from "../../Components/Modals/ImportModal/ImportContactsModal";
import ProfileImageModal from "../../Components/Modals/ProfileImageModal/ProfileImageModal";
import AttachmentModal from "../../Components/Modals/AttachmentModal/AttachmentModal";
import Loading from "../../Components/Loading/Loading";
import OwnerModal from "../../Components/Modals/ChangeOwnerModal/ChangeOwnerModal";
import axios from "axios";
import { fetchCustomButtons } from "../../Services/organizations";
import { showSnackbarAction } from "../../Redux/actions";
import { themeColors } from "../../Components/theme";
import { url } from "../../Values/constants";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


type props = {
  user: any;
  organizationUsers: any;
  theme: any;
  authToken: any;
  contactHeader: any;
};

let usersList: any[] = [];
const ContactDetails: FunctionComponent<props> = ({
  user,
  organizationUsers,
  theme,
  authToken,
  contactHeader,
}) => {
  const location: any = useLocation();
  if (location.state == undefined) {
    // window.location.href = "https://login1.read-pro.com/";
    console.log("Extra work remaining...");
  }
  const [tasksList, setTasksList] = useState<
    any[] | undefined
  >(undefined);
  const [notesList, setNotesList] = useState<
    any[] | undefined
  >(undefined);
  const [attachmentsList, setAttachmentsList] = useState<
    any[] | undefined
  >(undefined);
  const [load, setLoad] = useState(false);

  // to open the custom button dropdwon if there are more than 2 custom buttoms
  const [open, setOpen] = useState(false);
  const drop = useRef(null);

  // top css property for dropdown custom button
  const top = 30;
  // margin top css property for dropdown custom button
  const topMargin = 5;

  const [callLogsList, setCallLogsList] = useState<
    any[] | undefined
  >(undefined);
  const [customButtons, setCustomButtons] = useState<
    any[] | undefined
  >(undefined);
  const [title, setTitle] = useState("");
  const [interestedModal, setInterestedModal] =
    useState(false);
  const [callbackModal, setCallbackModal] = useState(false);
  const [notInterestedModal, setNotInterestedModal] =
    useState(false);
  const [lostModal, setLostModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] =
    useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [picModal, setPicModal] = useState(false);
  const [vidModal, setVidModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [ownerButton, setOwnerButton] = useState(false);
  const [freshFilterValue, setFreshFilterValue] = useState<any>();
  const [bookingIdData, setBookingIdData] = useState<any>();


  useEffect(()=>{
    handleBookingContactId();
  },[]);
  const createUsersList = (
    email: string,
    users: any[],
    uid: boolean
  ) => {
    users.map((item: any) => {
      if (usersList.includes(item)) {
        return;
      } else {
        if (
          item.reporting_to === email &&
          item.status === "ACTIVE"
        ) {
          if (!usersList.includes(item)) {
            usersList.push(item);
          }

          createUsersList(item.user_email, users, uid);
        }
      }
    });
  };

  useEffect(() => {
    const subuser = fetchLeadTasks(
      (data) => setTasksList(data),
      location.state.detail.contactId
    );
    const subcontacts = fetchContactResources(
      (data) => setNotesList(data),
      (data) => setAttachmentsList(data),
      (data) => setCallLogsList(data),
      location.state.detail.contactId
    );
    const subToggleButtons = fetchCustomButtons(
      user.organization_id,
      (data) => setCustomButtons(data)
    );
    sessionStorage.setItem("org", user.organization_id)
    // localStorage.setItem("currentPage",location.state?.Currentpage);
    return () => {
      subuser();
      subcontacts();
      subToggleButtons();
    };
  }, [location.state, user]);
  const dispatcher = useDispatch();
  const history = useHistory();

  // useEffect(()=>{

  // },[])
  // console.log("locationstate",location.state)
  const callCustomButton = async (
    url: string,
    parameters: any,
    headers: any,
    type: string
  ) => {


    const isObjectEmpty = (object: any) => {
      var isEmpty = true;
      for (let keys in object) {
        isEmpty = false;
        break; // exiting since we found that the object is not empty
      }
      return isEmpty;
    };

    let res;
    if (type.toLowerCase() === "post") {
      for (let key in parameters) {
        let wildcard = parameters[key].split("$");
        if (wildcard.length > 1) {
          parameters[key] =
            location.state.detail[wildcard[1]];
        }
      }

      if (!isObjectEmpty(headers)) {
        const config = {
          headers: headers,
        };
        res = await axios.post(url, parameters, config);
      } else {
        res = await axios.post(url, parameters);
      }
      dispatcher(
        showSnackbarAction(
          "Custom Button Action Successful",
          "success"
        )
      );
    } else if (type.toLowerCase() === "get") {
      res = await axios.get(url);
    }

    toggleis_button_called(location.state.detail.contactId);
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }

  const handleBookingContactId = async () => {
    const bodyData = {
      contactId: location.state.detail.contactId,
      uid: location.state.detail.uid,
    }
    axios.defaults.headers.common['x-access-token'] =
      authToken

    const getBookingRes = await axios.post(
      url + '/bookings/contactId',
      bodyData
    );
    if (getBookingRes.data) {
      setBookingIdData(getBookingRes.data);
    }
  }

  const handleBooking = async () => {
    const bodyData = {
      organization_id: location.state.detail.organization_id,
      uid: location.state.detail.uid,
      contact_no: location.state.detail.contact_no,
    }
    axios.defaults.headers.common['x-access-token'] =
      authToken

    const getBookingContactNORes = await axios.post(
      url + '/bookings/details',
      bodyData
    );
    if (getBookingContactNORes.data) {
      history.push({
        pathname: '/addBooking',
        state: { "booking_type": "REBOOKING", "reBookingData": bodyData }
      })
    }
    else {
      history.push({
        pathname: '/addBooking',
        state: { "Contact_details": location.state.detail, "notesList": notesList, "callLogsList": callLogsList, "attachmentsList": attachmentsList }
      })
    }
  }
  return (
    <div className={styles.parent} style={{ marginTop: "5%", ...containerStyles }}>
      {/* <div className={styles.backButtonContainer}>
          <button
            style={{ display: 'inline' }}
            className={styles.addBox}
            onClick={history.goBack}
          >
            Back
          </button>
          {location.state.detail?.stage === "WON" && <button
            style={{ marginLeft: '30px', width: "15%",display: 'inline' }}
            className={styles.addBox}
            onClick={() => handleBooking()}
          >
            Booking
          </button>}
      </div> */}
      <div
        className={styles.child}
        style={containerStyles}
        
      >
        <div
          className={styles.detailsContainer}
          // style={containerStyles}
          style={{maxHeight:"95vh", overflowX:"hidden",overflowY:"scroll",marginBottom:"10px",...containerStyles}}
        >
          {/* <button
            // style={{ marginLeft: '30px' }}
            className={styles.addBox}
            onClick={history.goBack}
          >
            Back
          </button> */}
          <div
            className={styles.contactDetails}
            style={cardContainerStyles}
          >
            <div className={styles.firstContainer}
            >

              <div className={styles.nameDiv}>
                {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                  >
                    <div>
                      <button
                          className={styles.dropdownButton}
                          onClick={() => {
                            setOwnerButton(true);
                            createUsersList(
                                user.user_email,
                                organizationUsers,
                                false
                            );
                          }}
                      >
                        Change Owner
                      </button>
                    </div>
                    {customButtons &&
                    customButtons.length === 1 ? (
                        <button
                            className={styles.ownerButton}
                            onClick={() => {
                              callCustomButton(
                                  customButtons[0]?.url,
                                  customButtons[0]?.parameters,
                                  customButtons[0]?.headers,
                                  customButtons[0]?.type
                              );
                            }}
                        >
                          {customButtons[0]?.name}
                        </button>
                    ) : (
                        <div
                            className="dropdown"
                            ref={drop}
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                        >
                          <button
                              className={styles.dropdownButton}
                              onClick={() =>
                                  setOpen((open) => !open)
                              }
                          >
                            {!open ? "Open" : "Close"} Custom
                            buttons dropdown
                          </button>
                          {open && (
                              <>
                                {customButtons &&
                                    customButtons.map(
                                        (tb: any, index: any) => (
                                            <button
                                                style={{
                                                  position: "absolute",
                                                  top: top * (index + 1),
                                                  marginTop:
                                                      topMargin *
                                                      (2 * (index + 1) - 1),
                                                }}
                                                className={
                                                  styles.dropdownButton
                                                }
                                                onClick={() => {
                                                  callCustomButton(
                                                      tb.url,
                                                      tb.parameters,
                                                      tb.headers,
                                                      tb.type
                                                  );
                                                  setOpen(false);
                                                }}
                                            >
                                              {tb.name}
                                            </button>
                                        )
                                    )}
                              </>
                          )}
                        </div>
                    )}
                  </div> */}
                <button
                  // style={{ marginLeft: '30px' }}
                  className={styles.addBoxBlk}
                  onClick={history.goBack}
                >
                  <ArrowBackIcon />
                </button>
                <h6
                // className={styles.name}
                >
                  Name : {location.state.detail.customer_name
                    .toLowerCase()
                    .split(" ")
                    .map(function (word: any) {
                      return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1)
                      );
                    })
                    .join(" ")}
                </h6>
                <p
                // className={styles.customerEmail}
                >
                  {location.state.detail.email}
                </p>
              </div>

              <div className={styles.buttonContainer}>
                {properFormat(
                  location.state.detail.stage
                ) === "Fresh" ? (
                  <>
                    <ContactButton
                      backgroundColor="#29ab87"
                      color="#fff"
                      title="Interested"
                      clicked={() => {
                        setInterestedModal(true);
                      }}
                    />
                    <ContactButton
                      backgroundColor="#f7d560"
                      color="#fff"
                      title="Call Back"
                      clicked={() => {
                        setCallbackModal(true);
                      }}
                    />
                    <ContactButton
                      backgroundColor="#ff6961"
                      color="#fff"
                      title="Not-Interested"
                      clicked={() => {
                        setNotInterestedModal(true);
                      }}
                    />
                  </>
                ) : properFormat(
                  location.state.detail.stage
                ) === "Call Back" ? (
                  <>
                    <ContactButton
                      backgroundColor="#29ab87"
                      color="#fff"
                      title="Interested"
                      clicked={() => {
                        setInterestedModal(true);
                      }}
                    />
                    <ContactButton
                      backgroundColor="#f7d560"
                      color="#fff"
                      title="Re-Callback"
                      clicked={() => {
                        setCallbackModal(true);
                      }}
                    />
                    <ContactButton
                      backgroundColor="#ff6961"
                      color="#fff"
                      title="Not-Interested"
                      clicked={() => {
                        setNotInterestedModal(true);
                      }}
                    />
                  </>
                ) : properFormat(
                  location.state.detail.stage
                ) === "Interested" ? (
                  <>
                    <ContactButton
                      backgroundColor="#29ab87"
                      color="#fff"
                      title="Won"
                      clicked={() => {
                        changeLeadStage(
                          location.state.detail.contactId,
                          { stage: "WON" },
                          () => { },
                          dispatcher,
                          () => { },
                          history,
                          tasksList
                        );
                        history.push({
                          pathname: '/addBooking',
                          // pathname: '/',
                          state: { "Contact_details": location.state.detail, "notesList": notesList, "callLogsList": callLogsList, "attachmentsList": attachmentsList }
                        })
                      }}
                    />
                    <ContactButton
                      backgroundColor="#e34234"
                      color="#fff"
                      title="Lost"
                      clicked={() => {
                        setLostModal(true);
                      }}
                    />
                    <ContactButton
                      backgroundColor="#f7d560"
                      color="#fff"
                      title="Re Schedule"
                      clicked={() => {
                        setRescheduleModal(true);
                      }}
                    />
                    <ContactButton
                      backgroundColor="#272944"
                      color="#fff"
                      title="Create"
                      clicked={() => {
                        setCreateModal(true);
                      }}
                    />
                  </>
                ) : properFormat(
                  location.state.detail.stage
                ) === "Won" ? (
                  <>
                {bookingIdData && bookingIdData?.length === 0 && (
                 <>
                    <ContactButton
                      backgroundColor="#e34234"
                      color="#fff"
                      title="Lost"
                      clicked={() => setLostModal(true)}
                    />
                    <ContactButton
                      backgroundColor="#f7d560"
                      color="#fff"
                      title="Re Schedule"
                      clicked={() => setRescheduleModal(true)}
                    />
                    <ContactButton
                      backgroundColor="#272944"
                      color="#fff"
                      title="Create"
                      clicked={() => setCreateModal(true)}
                    />
                 </>
                )}
                  </>
                ):(
                  <></>
                )}
              </div>

              <div className={styles.data}>
                <p
                  className={styles.heading}
                >
                  {contactHeader.find((obj: any) => obj.label === "alternate_no").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.alternate_no}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>{contactHeader.find((obj: any) => obj.label === "location").value} :</p>
                <p className={styles.text}>
                  {location.state.detail.location}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "property_type").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.property_type}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "property_stage").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.property_stage}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>{contactHeader.find((obj: any) => obj.label === "budget").value} :</p>
                <p className={styles.text}>
                  {location.state.detail.budget}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>{contactHeader.find((obj: any) => obj.label === "project").value} :</p>
                <p className={styles.text}>
                  {location.state.detail.project}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "not_int_reason").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.not_int_reason}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "lost_reason").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.lost_reason}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "other_lost_reason").value} :
                </p>
                <p className={styles.text}>
                  {
                    location.state.detail
                      .other_not_init_reason
                  }
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>{contactHeader.find((obj: any) => obj.label === "addset").value} :</p>
                <p className={styles.text}>
                  {location.state.detail.addset}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "stage_change_at").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.stage_change_at !==
                    ""
                    ? getDateAndTime(
                      location.state.detail
                        .stage_change_at,
                      "LLL"
                    )
                    : ""}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "field_four").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.field_four}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "field_five").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.field_five}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "field_six").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.field_six}
                </p>
              </div>
            </div>

            <div className={styles.secondContainer}>
              <div className={styles.emailContainer}>
                <h6
                // className={styles.email}
                >
                  {contactHeader.find((obj: any) => obj.label === "email").value} : {
                    location.state.detail
                      .contact_owner_email
                  }
                </h6>
                <h6
                // className={styles.email}
                >
                  {contactHeader.find((obj: any) => obj.label === "contact_no").value} : {location.state.detail.contact_no}
                </h6>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "next_follow_up_type").value} :
                </p>
                <p className={styles.text}>
                  {
                    location.state.detail
                      .next_follow_up_type
                  }
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "next_follow_up_date_time").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail
                    .next_follow_up_date_time !== ""
                    ? getDateAndTime(
                      location.state.detail
                        .next_follow_up_date_time,
                      "LLL"
                    )
                    : ""}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "lead_source").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.lead_source}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  Reporting To :
                </p>
                <p className={styles.text}>
                  {location.state.detail.reporting_to}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "property_sub_type").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.property_sub_type}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "call_back_reason").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.call_back_reason}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "other_lost_reason").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.other_lost_reason}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>{contactHeader.find((obj: any) => obj.label === "created_by").value} :</p>
                <p className={styles.text}>
                  {location.state.detail.created_by}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "created_at").value} :
                </p>
                <p className={styles.text}>
                  {getDateAndTime(
                    location.state.detail.created_at,
                    "LLL"
                  )}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "lead_assign_time").value} :
                </p>
                <p className={styles.text}>
                  {getDateAndTime(
                    location.state.detail.lead_assign_time,
                    "LLL"
                  )}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>{contactHeader.find((obj: any) => obj.label === "campaign").value} :</p>
                <p className={styles.text}>
                  {location.state.detail.campaign}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "modified_at").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.modified_at !== ""
                    ? getDateAndTime(
                      location.state.detail.modified_at,
                      "LLL"
                    )
                    : ""}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "field_one").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.field_one}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "field_two").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.field_two}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.heading}>
                  {contactHeader.find((obj: any) => obj.label === "field_three").value} :
                </p>
                <p className={styles.text}>
                  {location.state.detail.field_three}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.taskDetails} style={{marginBottom:"40px"}}>
            <h6
            // className={styles.task}
            >Tasks</h6>
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead className={styles.head}>
                  <tr>
                    <th className={styles.th}>Tasks</th>
                    <th className={styles.th}>Date</th>
                    <th className={styles.th}>Time</th>
                    <th className={styles.th}>
                      Status Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tasksList?.map(
                    (item: any, index: number) => (
                      <tr key={index} style={tdStyles}>
                        <td className={styles.td} style={tdStyles}>
                          {item.type}
                        </td>
                        <td className={styles.td} style={tdStyles}>
                          {item.due_date.toDate
                            ? getDateAndTime(
                              item.due_date.toDate(),
                              "DD MMM YY"
                            )
                            : ""}
                        </td>
                        <td className={styles.td} style={tdStyles}>
                          {item.due_date.toDate
                            ? getDateAndTime(
                              item.due_date.toDate(),
                              "LT"
                            )
                            : ""}
                        </td>
                        <td className={styles.td} style={tdStyles}>
                          {item.status}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={styles.notesContainer}
          style={containerStyles}
        >
          <div className={styles.childNotes}
            style={containerStyles}
          >
            <div className={styles.notesSection} style={cardContainerStyles}>
              <div className={styles.notesHead}>
                <h6
                // className={styles.notes}
                >Notes</h6>
                <IoIosAddCircleOutline
                  color="#272944"
                  size="20"
                  onClick={() => setNoteModal(true)}
                />
              </div>
              <div className={styles.container}>
                {notesList?.map(
                  (item: any, index: number) => (
                    <div className={styles.noteDisplay}>
                      <div className={styles.box}>
                        <div className={styles.innerBox}>
                          <div
                            className={
                              styles.dateAndCreatedByBox
                            }
                          >
                            <p className={styles.date}>
                              {getDateAndTime(
                                item.created_at.toDate(),
                                "LLL"
                              )}
                            </p>
                            <p className={styles.createdBy}>
                              {item.userEmail}
                            </p>
                          </div>
                          <p className={styles.note}>
                            {item.note}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
                {notesList?.length === 0 && (
                  <p
                    style={{ textAlign: "center" }}
                  // className={styles.noContent}
                  >
                    No Notes{" "}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.attachmentSection} style={cardContainerStyles}>
              <div className={styles.notesHead}>
                <h6
                // className={styles.notes}
                >Attachments</h6>
                {/* <IoIosAddCircleOutline color="#272944" size="20" /> */}
                <div className={styles.attachmentItems}>
                  <div style={{ padding: "0 10px" }}>
                    <HiVideoCamera
                      size={15}
                      color="#272944"
                      onClick={() => {
                        setVidModal(true);
                        setTitle("Upload Video");
                      }}
                    />
                  </div>
                  <div style={{ padding: "0 10px" }}>
                    <HiOutlinePhotograph
                      size={15}
                      color="#272944"
                      onClick={() => {
                        setPicModal(true);
                        setTitle("Upload Image");
                      }}
                    />
                  </div>
                  <div style={{ padding: "0 10px" }}>
                    <ImFileText2
                      size={15}
                      color="#272944"
                      onClick={() => {
                        setFileModal(true);
                        setTitle("Upload Files");
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.attachmentContainer}>
                {load && <Loading />}
                {attachmentsList?.map(
                  (item: any, index: number) => (
                    <div
                      className={styles.attachmentBox}
                      key={index}
                    >
                      {item.type === "video" && (
                        <HiVideoCamera
                          size={15}
                          color="#272944"
                        />
                      )}
                      {item.type === "photo" && (
                        <HiOutlinePhotograph
                          size={15}
                          color="#272944"
                        />
                      )}
                      {item.type === "audio" && (
                        <HiOutlineMicrophone
                          size={15}
                          color="#272944"
                        />
                      )}
                      {item.type === "file" && (
                        <ImFileText2
                          size={15}
                          color="#272944"
                        />
                      )}
                      <p className={styles.note}>
                        <a href={item.url}>{item.name}</a>
                      </p>
                    </div>
                  )
                )}
                {attachmentsList?.length === 0 && (
                  <p
                    style={{ textAlign: "center" }}
                  // className={styles.noContent}
                  >
                    No Attachments{" "}
                  </p>
                )}
              </div>
            </div>

            <div className={styles.notesSection} style={cardContainerStyles}>
              <div className={styles.notesHead}>
                <h6
                // className={styles.notes}
                >Call Logs</h6>
                <IoIosAddCircleOutline
                  color="#272944"
                  size="20"
                />
              </div>
              <div className={styles.attachmentContainer}>
                {callLogsList?.map(
                  (item: any, index: number) => (
                    <div
                      className={styles.attachmentBox}
                      key={index}
                    >
                      <HiPhone size={50} color="#272944" />

                      <p className={styles.note}>
                        {item.callTime}
                      </p>
                      <p
                        className={styles.note}
                        style={{ marginLeft: "auto" }}
                      >
                        {getDateAndTime(
                          item.created_at.toDate(),
                          "LLL"
                        )}
                      </p>
                    </div>
                  )
                )}
                {callLogsList?.length === 0 && (
                  <p
                    style={{ textAlign: "center" }}
                  // className={styles.noContent}
                  >
                    No Call Logs{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {ownerButton && (
          <OwnerModal
            open={ownerButton}
            close={() => {
              setOwnerButton(false);
              history.push("/");
            }}
            usersList={usersList}
            rowsSelected={[location.state.detail]}
            organization_id={user.organization_id}
            freshFilterValue={freshFilterValue}
          />
        )}

        {picModal && (
          <AttachmentModal
            open={picModal}
            close={() => setPicModal(false)}
            title={title}
            type={"photo"}
            contactDetail={location.state.detail}
            attachmentsList={
              attachmentsList ? attachmentsList : []
            }
          />
        )}
        {vidModal && (
          <AttachmentModal
            open={vidModal}
            close={() => setVidModal(false)}
            title={title}
            type={"video"}
            contactDetail={location.state.detail}
            attachmentsList={
              attachmentsList ? attachmentsList : []
            }
          />
        )}
        {fileModal && (
          <AttachmentModal
            open={fileModal}
            close={() => setFileModal(false)}
            title={title}
            type={"file"}
            contactDetail={location.state.detail}
            attachmentsList={
              attachmentsList ? attachmentsList : []
            }
          />
        )}
        {interestedModal === true && (
          <InterestedLeadModal
            contactDetail={location.state.detail}
            close={() => setInterestedModal(false)}
            open={interestedModal}
            notes={notesList ? notesList : []}
            tasksData={tasksList ? tasksList : []}
          />
        )}
        {notInterestedModal === true && (
          <NotInterestedLeadModal
            contactDetail={location.state.detail}
            close={() => setNotInterestedModal(false)}
            open={notInterestedModal}
            tasksData={tasksList ? tasksList : []}
            notes={notesList ? notesList : []}
          />
        )}
        {callbackModal === true && (
          <CallBackModal
            contactDetail={location.state.detail}
            close={() => setCallbackModal(false)}
            open={callbackModal}
            tasksData={tasksList ? tasksList : []}
            notes={notesList ? notesList : []}
          />
        )}
        {lostModal === true && (
          <LostModal
            contactDetail={location.state.detail}
            close={() => setLostModal(false)}
            open={lostModal}
            tasksData={tasksList ? tasksList : []}
            notes={notesList ? notesList : []}
          />
        )}
        {createModal === true && (
          <CreateModal
            contactDetail={location.state.detail}
            close={() => setCreateModal(false)}
            open={createModal}
            tasksData={tasksList ? tasksList : []}
            notes={notesList ? notesList : []}
          />
        )}
        {rescheduleModal === true && (
          <RescheduleModal
            contactDetail={location.state.detail}
            close={() => setRescheduleModal(false)}
            open={rescheduleModal}
            tasksData={tasksList ? tasksList : []}
            notes={notesList ? notesList : []}
          />
        )}
        {noteModal === true && (
          <NotesModal
            notes={notesList ? notesList : []}
            contactDetail={location.state.detail}
            close={() => setNoteModal(false)}
            open={noteModal}
            userEmail={user.user_email}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    theme: state.theme.isLightMode,
    authToken: state.user.authToken,
    contactHeader: state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(ContactDetails);