import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import styles from "./ProjectVideoModal.module.css";
import Modal from "react-modal";
import { IoMdDocument } from "react-icons/io";
import { connect, useDispatch } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import { uploadUserImage } from "../../../Services/users";
import Loading from "../../Loading/Loading";
import {themeColors} from "../../theme";
import Label from "../../Label";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";


import { uploadAttachment } from "../../../Services/contacts";
import { uploadProjectResources } from "../../../Services/resources";
type props = {
  open: boolean;
  close: () => void;
  title: string;
  projectData: any;
  type: string;
  attachmentsList: any[];
  user: any;
  theme:any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const AttachmentModal: FunctionComponent<props> = ({
  user,
  open,
  close,
  title,
  projectData,
  type,
  attachmentsList,
  theme
}) => {
  const dispatcher = useDispatch();
  const attachmentRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [file, setFile] = useState<any>();


  const upload = async (file: any) => {
    
    let name:any=document.getElementById('file-input')
    let nameList=name.value.split("\\")
    let fileName=nameList[nameList.length-1]
    close();
    setLoad(true);
    await uploadProjectResources(
      file[0],
      projectData,
      type,
      fileName,
      dispatcher
    );
    setLoad(false);
    
  };

  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}

  return (
    <>
    {load && <Loading />}
    {open && <Backdrop>
    <motion.div
                   key="modal"
                   className="card p-4 shadow rounded"
                   style={{position: "absolute",
                   ...containerStyles}}
                   variants={dropIn}
                   initial="hidden"
                   animate="visible"
                   exit="exit"
                 >
      <div className={styles.headingContainer}>
        <h5 
        // className={styles.heading}
        >{title}</h5>
      </div>
      <div
        className={styles.dragBox}
        style={containerStyles}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          upload(e.dataTransfer.files);
          setLoad(true);
        }}
      >
        <IoMdDocument size={80} color={"#808080"} />
        <p>Drag And Drop</p>
      </div>

      <div className={styles.uploadContainer}>
        <div className={styles.selectBox}>
          <label htmlFor="file-input" className={styles.uploadButton}>
            {title}
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept=".png,.jpg,.jpeg,.pdf,.docs"
            onChange={(e) => upload(e.target.files)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={() => close()}>
            Cancel
          </button>
        </div>
      </div>
                 </motion.div>
    </Backdrop>}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(AttachmentModal);
