const filterObjectReducer = (
  state = {},
  action: {
    type: "SET_OBJECT" | "CLEAR_ALL_FILTERS";
    payload: any;
  }
) => {
  switch (action.type) {
    case "SET_OBJECT":
      return { ...state, ...action.payload };
    case "CLEAR_ALL_FILTERS":
      return {};
    default:
      return state;
  }
};

export default filterObjectReducer;
