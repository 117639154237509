import React, { useState, useEffect } from 'react';
import commonStyle from '../common.module.css';
import Topbar from '../../Components/TopBar/TopBar';
import { booleanObject, datesField, TASK_COLUMNS, TasksColumns } from '../../Values/tables';
import { connect, useDispatch } from 'react-redux';
import { fetchTasks } from '../../Services/contacts';
import {
  getFilterObject,
  searchTaskItem,
  filterTaskStatus,
} from '../../Values/utils';
import Loading from '../../Components/Loading/Loading';
import CustomTable from '../../Components/CustomTable/CustomTable';
import { getDateString, searchContacts } from '../../Values/utils';
import styles from '../Analytics/Analytics.module.css'
import Cookies from 'universal-cookie';

import axios from 'axios';

import moment from 'moment';

import { url } from '../../Values/constants';
import {
  setClearFilter,
  setFilterObject,
  setRefreshTasks,
  showSnackbarAction,
} from '../../Redux/actions';
import ApiCustomTable from '../../Components/CustomTable/ApiCustomTable';
import ApiTopBar from '../../Components/TopBar/ApiTopBar';
import { themeColors } from '../../Components/theme';

let isFinished = false;
const TaskDrilldown = ({
  history,
  user,
  branchUsers,
  filterObject,
  searchString,
  filterSort,
  refreshTasks,
  theme,
  taskHeader,
  authToken
}: any) => {
  const [tasksList, setTasksList] = useState<any[] | undefined>(undefined);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [searchQuery, setsearchQuery] = useState('');
  const [status, setStatus] = useState('ALL');
  const [columnModal, setColumnModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [uidMap, setUidMap] = useState<any>();

  const [lastPage, setLastPage] = useState(-1);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allTasks, setAllTasks] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>({});
  const [searchedItem, setsearchedItem] = useState('');
  const [drillRole, setDrillRole] = useState<any>({});
  const [filterApiCount, SetFilterApiCount] = useState<any>({});
  const [drillDownCount, SetDrillDownCount] = useState<any>({});

  const dispatcher = useDispatch();
  const cookies = new Cookies();

  let userId = cookies.get("uId");

  const [localStorageData, setLocalStorageData] = useState<any>({});

  useEffect(() => {
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== 'columns' && key !== 'taskDrilldownData' && key !== 'isLightMode' && key !== 'totalMonths' && key !== 'lastAlertTime') {
        localStorage.removeItem(key);
      }
    }

    dispatcher(setClearFilter(true));
    const savedData = localStorage.getItem('taskDrilldownData');
    if (savedData) {
      setLocalStorageData(JSON.parse(savedData));
    }
  }, []);
  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {
    if (isFinished === true) {
      return;
    } else {
      setLoad(true);
      const filters: { [key: string]: any[] } = { ...localStorageData.taskFilter };
      let feild;
      setDrillRole(localStorageData.role);
      Object.keys(filterObject).forEach((item) => {
        if (filterObject && filterObject[item].length > 0) {
          filters[item] = filterObject[item];
        }
      });
      if (searchString === "") {
        feild = "contact_no";
      } else {
        if (searchString.match(/^[0-9]+$/) != null) {
          feild = "contact_no";
        } else {
          feild = "customer_name";
        }
      }
      let records = localPageSize ? localPageSize : pageSize;

      const { created_at, ...leadFilterObject } = localStorageData.leadFilter;
      const apiData = {
        uid: localStorageData.source === true && localStorageData.role === false ? userId : localStorageData.uid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: '-1' }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        taskFilter: localStorageData.source === true && localStorageData.role === false ? { ...filters, created_at, "source": [localStorageData.uid] } : { ...filters, created_at },
        leadFilter: leadFilterObject,
        role: localStorageData.source === true && localStorageData.role === false ? true : localStorageData.role,
      };
      SetDrillDownCount(filters);
      SetFilterApiCount(apiData);
      try {
        axios.defaults.headers.common['x-access-token'] =
          authToken
        const res = await axios.post(url + '/tasks/drillDownSearch', apiData);
        let data: any[] = res.data;
        if (data.length < records && paginate) {
          isFinished = true;
          setLastPage(page);
        }

        data.forEach((task) => {
          task.contactId = task.leadId;

          delete task['Id'];
          Object.keys(task).forEach((key) => {
            if (datesField.includes(key) && task[key] !== '') {
              if (task[key] !== null) {
                task[key] = moment(task[key]);
              } else {
                task[key] = '';
              }
            }
          });
        });
        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
          setLoad(false);
        } else {
          setFilterData(data);
          setLoad(false);
        }
      } catch {
        setFilterData([]);
        setLoad(false);
        dispatcher(showSnackbarAction('Please Try Again!!', 'error'));
      }
    }
  };

  useEffect(() => {
    if (localStorageData.uid === undefined) {
      setFilterData([]);
      return;
    }
    if (userMap === undefined) {
      setFilterData([]);
      return;
    }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    if (authToken) {
      callApi(false, pageSize, 1);
    }
  }, [localStorageData, filterObject, authToken]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        if (authToken) {
          callApi(false, pageSize);
        }
      } else {
        if (authToken) {
          callApi(true, pageSize);
        }
      }
    }
  }, [page, pageSize, authToken]);

  // useEffect(() => {
  //   if (user === undefined || user.uid === undefined) {
  //     setFilterData([]);
  //     return;
  //   }
  //   if (authToken) {
  //     callCountApi();
  //   }
  // }, [refreshTasks, user.uid, drillDownCount, filterApiCount, authToken]);
  useEffect(() => {
    if (localStorageData.uid === undefined) {
      setFilterData([]);
      return;
    }
    if(authToken){
      callCountApi();
    }
  }, [refreshTasks, localStorageData.uid, drillDownCount, filterApiCount,authToken]);

  const callCountApi = async () => {
    const { page, pageSize, searchString, sort, ...objFilterData } = filterApiCount;
    if (Object.keys(objFilterData).length !== 0) {
    axios.defaults.headers.common['x-access-token'] =
      authToken
      const res = await axios.post(url + '/tasks/drillDownCount', objFilterData);
      setTotalCounts(res?.data[0]?.total);
    }

  };
  useEffect(() => {
    if (refreshTasks === true) {
      if (authToken) {
        callApi();
        getTasksFilter();
        dispatcher(setRefreshTasks(false));
      }
    }
  }, [refreshTasks, authToken]);

  const getTasksFilter = async () => {
    const uId = localStorageData.uid;
    axios.defaults.headers.common['x-access-token'] =
      authToken
    const res = await axios.post(url + '/tasks/filterValues', {
      uid: user.uid || uId,
    });
    if (res.data[0]) {
      dispatcher(setFilterObject({ ...res.data[0], ...booleanObject }));
    } else {
      dispatcher(setFilterObject({}));
    }
  };

  // useEffect(() => {
  //   if (user === undefined || user.uid === undefined) {
  //     return;
  //   }
  //   if (authToken) {
  //     getTasksFilter();
  //   }
  // }, [user, authToken]);
  // console.log("localStorageData",localStorageData.uid);

    useEffect(() => {
      if (localStorageData.uid === undefined) {
        return;
      }
      if(authToken){
        getTasksFilter();
      }
    }, [localStorageData.uid,authToken]);

  const exportFile = () => {
    let data: any[] = [];
    interface DataObj {
      label: any;
      value: any;
    }
    // const dbData = [{"label": "leadId","value": "Lead Id"},{"label": "customer_name","value": "Customer Name"},{"label": "type","value": "Task Type"},{"label": "contact_owner_email","value": "Owner"},{"label": "stage","value": "Stage"},{"label": "project","value": "Project"},{"label": "location","value": "Location"},{"label": "budget","value": "Budget"},{"label": "contact_no","value": "Contact No"},{"label": "inventory_type","value": "Inventory Type"},{"label": "source","value": "Source"},{"label": "transfer_status","value": "Transfer Status"},{"label": "due_date","value": "Due Date and Time"},{"label": "completed_date_and_time","value": "Completed Date and Time"},{"label": "call_back_reason","value": "Call Reason"},{"label": "status","value": "Status"},{"label": "created_by","value": "Created By"},{"label": "created_at","value": "Created Date and Time"}];
    const dbData = taskHeader;
    const drillTaskHeaderCustomerName: any = dbData.find((obj: DataObj) => obj.label === "customer_name")?.value;
    const drillTaskHeaderType: any = dbData.find((obj: DataObj) => obj.label === "type")?.value;
    const drillTaskHeaderOwner: any = dbData.find((obj: DataObj) => obj.label === "contact_owner_email")?.value;
    const drillTaskHeaderStage: any = dbData.find((obj: DataObj) => obj.label === "stage")?.value;
    const drillTaskHeaderProject: any = dbData.find((obj: DataObj) => obj.label === "project")?.value;
    const drillTaskHeaderLocation: any = dbData.find((obj: DataObj) => obj.label === "location")?.value;
    const drillTaskHeaderBudget: any = dbData.find((obj: DataObj) => obj.label === "budget")?.value;
    const drillTaskHeadeContactNo: any = dbData.find((obj: DataObj) => obj.label === "contact_no")?.value;
    // const drillTaskHeadeInventoryType: any = dbData.find((obj: DataObj) => obj.label === "inventory_type")?.value;
    const drillTaskHeadeSource: any = dbData.find((obj: DataObj) => obj.label === "source")?.value;
    const drillTaskTransferStatus: any = dbData.find((obj: DataObj) => obj.label === "transfer_status")?.value;
    const drillTaskDueDateandTime: any = dbData.find((obj: DataObj) => obj.label === "due_date")?.value;
    const drillTaskCompletedDateandTime: any = dbData.find((obj: DataObj) => obj.label === "completed_date_and_time")?.value;
    const drillTaskCallReason: any = dbData.find((obj: DataObj) => obj.label === "call_back_reason")?.value;
    const drillTaskStatus: any = dbData.find((obj: DataObj) => obj.label === "status")?.value;
    const drillTaskCreatedBy: any = dbData.find((obj: DataObj) => obj.label === "created_by")?.value;
    const drillTaskCreatedDateandTime: any = dbData.find((obj: DataObj) => obj.label === "created_at")?.value;
    if (selectedRowsData.length === 0) {
    } else {
      selectedRowsData.forEach((item, index) => {
        data.push(
          {
            // "Id": item?._id,
            // "LeadId":item?.leadId ,
            "S.NO": index + 1,
            [drillTaskHeaderCustomerName]: item?.customer_name,
            [drillTaskHeaderType]: item?.type,
            [drillTaskHeaderOwner]: item?.contact_owner_email,
            [drillTaskHeaderStage]: item?.stage,
            [drillTaskHeaderProject]: item?.project,
            [drillTaskHeaderLocation]: item?.location,
            [drillTaskHeaderBudget]: item?.budget,
            [drillTaskHeadeContactNo]: item?.contact_no,
            // [drillTaskHeadeInventoryType]: item?.inventory_type,
            [drillTaskHeadeSource]: item?.source,
            [drillTaskTransferStatus]: item?.transfer_status,
            [drillTaskDueDateandTime]: getDateString(item?.due_date),
            [drillTaskCompletedDateandTime]: getDateString(item?.completed_at),
            [drillTaskCallReason]: item?.call_back_reason,
            [drillTaskStatus]: item?.status,
            [drillTaskCreatedBy]: item?.created_by,
            [drillTaskCreatedDateandTime]: getDateString(item?.created_at),
            // "uid": item?.uid,
            // "OrganizationId":item?.organization_id,
            // "V": item?.__v,
            // "ContactId": item?.contactId
          }
        );
      });
    }

    return data;
  };
  const topBarStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, width: "97%" }
  const parentStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark }
  // const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <div className={styles.parent} style={{ display: "block", marginTop: "8%", ...parentStyles }}>
      {load === true && <Loading />}
    <div className={commonStyle.topBar} style={{ marginTop: "0px", ...topBarStyles }}>
        {/* <ApiTopBar
          history={history}
          title={'Add Task'}
          path={'/addTasks'}
          onClick={() => {
            setShowImportModal(true);
          }}
          onChange={(val) => setsearchedItem(val)}
          filterText={'Status'}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={false}
          onExport={exportFile}
          searchedString={searchString}
          titleDrillDown={'Drill Down'}
        /> */}
      </div>
      <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
      {/* <div className={commonStyle.parent} style={{ height: "81%", ...containerStyles }}> */}
        {/* {load === true && <Loading />} */}
        <ApiCustomTable
          tableColumns={TasksColumns(taskHeader)}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="Task"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setPage={(val) => setPage(val)}
          setPageSize={(val) => setPageSize(val)}
          callApi={callApi}
          pageSize={pageSize}
          page={page}
          isFinished={isFinished}
          totalCount={totalCounts}
          lastPage={lastPage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    branchUsers: state.branchUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    searchString: state.searchItem.tasksSearchString,
    refreshTasks: state.refresh.refreshTasks,
    theme: state.theme.isLightMode,
    taskHeader: state.taskHeaderList.data,
    authToken: state.user.authToken
  };
};

export default connect(mapStateToProps)(TaskDrilldown);
