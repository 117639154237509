import React, { useRef, useState } from "react";
import styles from "./ForgotPassword.module.css";
import Logo from "../../Assets/Images/readPro_logo.png";
import Logo2 from "../../Assets/Images/ify_logo.png";
import TextInput from "../../Components/TextInput/TextInput";
import { MdEmail } from "react-icons/md";
import { auth } from "../../Firebase";
import { emailValidate } from "../../Values/validatorsnew";
import { useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import Loading from "../../Components/Loading/Loading";
import landingBackground from "../../Assets/Images/landing_background.svg";
import NewBackground from "../../Assets/Images/new_background.jpg";
import LatestBackground from "../../Assets/Images/signin_background_latest.svg";
import AppStore from "../../Assets/Images/appstore.svg";
import LogoOld from "../../Assets/Images/head.svg";
import PlayStore from "../../Assets/Images/playstore.svg";
import background from "../../Assets/Images/backgrounrsignup.jpg";
import LeadRubixLogo from "../../Assets/Images/leadrubixlogo.png";

const ForgotPassword = (props: any) => {

  const emailRef: any = useRef();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);

  const send = async (email: string) => {

    if (email !== "") {
      try {
        if (email) {
          setLoad(true);
          await auth.sendPasswordResetEmail(email);
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password reset information have been sent on you registered email id",
              "success"
            )
          );
          props.history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  };
  return (
    <div
    className={styles.container}
    style={{
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      // background:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,28,34,0.87718837535014) 51%, rgba(0,151,255,0.3561799719887955) 100%)",

      width: "100%",
      backgroundSize: "auto",
      backgroundPosition: "center",
      backgroundColor: "rgba(0, 0, 0, 0.02)",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
      // backgroundColor: "#272944",
    }}
  >
      {/* <img src={LeadRubixLogo} alt="logo" className={styles.logo} /> */}
      <div style={{ display: "flex", height: "80vh" ,alignItems:"center",justifyContent:"center" }}>
        {/* <div className={styles.box1}>
          <img
            src={LatestBackground}
            alt=""
            width="100%"
            height="100%"
          // className={styles.logo}
          />
        </div> */}
        <div className={styles.box2} style={{ 
          // background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,28,34,0.87718837535014) 51%, rgba(0,151,255,0.3561799719887955) 100%)"
          background:"#363853"
          }}>
           <div style={{ display: "flex", width: "100%", height: "100px" }}>
                  <img
                    src={LeadRubixLogo}
                    alt=""
                    width="90%"
                    height="50px"
                    style={{ marginTop: "15px", marginLeft: "16px" }}
                  />
          </div>
          <div className={styles.form}>
            <div className={styles.header}>
              <h4 style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px",color:"white"}}>Forgot Password?</h4>
              <p className={styles.headerText} style={{ fontSize: "14px", marginTop: "20px" }}>
                Enter your registered email below to receive password reset
                instructions
              </p>
            </div>
            <div className={styles.inputs}>
              <TextInput title={"Email"} ref={emailRef} validator={emailValidate}>
                <MdEmail color={"#053535"} />
              </TextInput>
            </div>
            <div className={styles.buttons}>
              <button
                className={styles.button}
                onClick={() => send(emailRef.current?.value)}
              >
                Send
              </button>
              <div
                className={styles.forgetBox}
                onClick={() => props.history.push("/")}
              >
                <p style={{ marginBottom: "20px", marginTop: "10px", fontSize: "14px",color:"white" }}>Remember Password?</p>
                <p className={styles.loginText} style={{ marginBottom: "20px", marginTop: "10px", fontSize: "14px" }}>Login</p>
              </div>
            </div>
            <div className={styles.storeIcons}>
              <div className={styles.iconContainer1}>
                <a
                //  href="https://play.google.com/store/apps/details?id=com.readpro" target="_blank"
                 >
                  <img
                    src={PlayStore}
                    alt={"logo"}
                    width={"25px"}
                  />
                </a>
              </div>
              <div className={styles.iconContainer2}>
                <a 
                // href="https://apps.apple.com/in/app/read-pro/id1566457452" target="_blank"
                >
                  <img
                    src={AppStore}
                    alt={"logo"}
                    width={"40px"}
                  />
                </a>
              </div>
            </div>

            {/* <p style={{ textAlign: "center", marginTop: "24px", fontSize: "14px" }}>Powered by</p>
            <div className={styles.socialIcons}>
              <img
                src={Logo2}
                alt={"logo"}
                height={"35px"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
