export const organizationSelectOrganizationIdReducer = (
    state = {
      data: {},
    },
    action: {
      type: "SET_SELECT_ORGANIZATION_ID";
      payload: any;
    }
  ) => {
    switch (action.type) {
      case "SET_SELECT_ORGANIZATION_ID":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };