import Firebase from "firebase/app";
import { firestore, functions, storage } from "../Firebase";

export const uploadAttachment = async (
    leadId: string,
    file: any,
    type: string,
    filename: string,
  ) => {
    const path = `${leadId}/${type}/${filename}`;
    const reference = storage.ref(path);
    await reference.put(file);
    const url = await reference.getDownloadURL();
    return url;
  }

// export const numberWithCommas=(num:any) => {
//   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }
export const numberWithCommas=(num:any) => {
  if (num == undefined || num == null || isNaN(num)) {
    return '';
  }
  // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  var x=12345652457.557;
    num=num.toString();
    var afterPoint = '';
    if(num.indexOf('.') > 0)
       afterPoint = num.substring(num.indexOf('.'),num.length);
       num = Math.floor(num);
       num=num.toString();
    var lastThree = num.substring(num.length-3);
    var otherNumbers = num.substring(0,num.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    
    return res;
}