import React, { useState, useEffect, FunctionComponent } from 'react';
import styles from './Analytics.module.css';
import { Line, Chart } from 'react-chartjs-2';
import { connect, useDispatch } from 'react-redux';
import AnalyticsCountContainer from '../../AnalyticsCountContainer/AnalyticsCountContainer';
import AnalyticsSideNav from '../../Components/AnalyticsSideNav/AnalyticsSideNav';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FeedbackGraph from '../../Components/Graphs/FeedbackGraph';
import Trends from '../../Components/Graphs/Trends';
import InterestedBudget from '../../Components/Graphs/InterestedBudget';
import InterestedProject from '../../Components/Graphs/InterestedProject';
import InterestedLocation from '../../Components/Graphs/InterestedLocation';
import InterestedPropertyType from '../../Components/Graphs/InterestedPropertyType';
import InterestedPropertyStage from '../../Components/Graphs/InterestedPropertyStage';
import CompletedTask from '../../Components/Graphs/CompletedTask';
import OverdueTask from '../../Components/Graphs/OverdueTask';
import PendingTask from '../../Components/Graphs/PendingTask';
import NotIntReason from '../../Components/Graphs/NotIntReason';
import LostReason from '../../Components/Graphs/LostReason';
import { FaFilter } from 'react-icons/fa';
import { FaBeer } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaBook } from 'react-icons/fa';
import AnalyticsFilter from '../../Components/AnalyticsFilter/AnalyticsFilter';
import axios from 'axios';
import Loading from '../../Components/Loading/Loading';
import moment from 'moment';
import Firebase from 'firebase/app';
import { url } from '../../Values/constants';
import KanbanViewFilter from '../../Components/Modals/KanbanViewModal/KanbanViewModal';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import './tabs.style.css';
import {
  feedbackReportData,
  interestedBudgetReportData,
  interestedLocationReportData,
  interestedProjectReportData,
  interestedPropertyTypeReportData,
  interestedPropertyStageReportData,
  lostReasonReportData,
  notInterestedReportData,
  completeTaskReportData,
  pendingReportData,
  overDueReportData,
  callBackReasonReportData,
  callBackReportData,
  setOrganizationSelectedID
} from '../../Redux/actions';
import { themeColors } from "../../Components/theme";
import CallBackReason from '../../Components/Graphs/CallBackReason';
import { sortDates } from '../../Services/analytics';
import Select from "react-select";
import OriginationFilter from '../../Components/AnalyticsFilter/OriginationFilter';
import { getAllHeadersDataOrg } from '../../Services/auth';
import { fetchOrganizationsUsersAuthId } from '../../Services/users';

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
  color: '#FFFFFF',
});

// all from redux store
type props = {
  user: any;
  organizationUsers: any;
  teamLeadUsers: any;
  role: any;
  history: any;
  branchUsers: any;
  clearfiltersideBar: any;
  theme: any;
  authToken: any;
  organizationIdNameData: any;
  conatctHeader: any;
  analyticsPanel:any;
};

const SalesAnalytics: FunctionComponent<props> = ({
  user,
  organizationUsers,
  teamLeadUsers,
  role,
  history,
  branchUsers,
  clearfiltersideBar,
  theme,
  authToken,
  organizationIdNameData,
  conatctHeader,
  analyticsPanel
}) => {
  //filter show, hide
  const [showFilter, setShowFilter] = useState(false);
  const [showOrgFilter, setShowOrgFilter] = useState(false);
  const [callLogAnalytics, setCallLogAnalytics] = useState({});
  const [analyticsData, setAnalyticsData] = useState<any>({});
  const [apiAnalyticsData, setApiAnalyticsData] = useState<any>({});
  const [apiCallLogAnalyticsData, setCallLogApiAnalyticsData] = useState<any>(
    {}
  );
  const [taskAnalyticsData, setTaskAnalyticsData] = useState<any>({});
  // check to show show data team wise or for all team members
  const [checked, setChecked] = useState(false);
  const [load, setLoad] = useState(false);
  // to check if the status is source or associate
  const [source, setSource] = useState(false);
  const [interestedTrendAnalytics, setInterestedTrendAnalytics] = useState<any>({});
  const [analyticsType, setAnalyticsType] = useState('associate');
  // kanban view panels
  // const [analyticsPanel, setPanel] = useState<'Contacts' | 'Tasks' | 'Call Logs'>(
  //   'Contacts'
  // );
  const [clearFilter, setClearFilter] = useState(false);
  const [stage, setStage] = useState<any>({
    INTERESTED: 0,
    FRESH: 0,
    CALLBACK: 0,
    WON: 0,
    LOST: 0,
    Pending: 0,
    Completed: 0,
    'NOT INTERESTED': 0,
  });
  const [taskStage, setTaskStage] = useState<any>({
    Pending: 0,
    Completed: 0,
  });
  const [feedback, setFeedback] = useState<any>({
    INTERESTED: 0,
    FRESH: 0,
    CALLBACK: 0,
    WON: 0,
    LOST: 0,
    'NOT INTERESTED': 0,
  });
  const [total, setTotal] = useState(0);
  const [overdueTrend, setOverdueTrend] = useState<{
    keys: any[];
    values: any[];
  }>({ keys: [], values: [] });
  const [completeTrend, setCompleteTrend] = useState<{
    keys: any[];
    values: any[];
  }>({ keys: [], values: [] });
  const [callLogData, setCallLogData] = useState<{ data: any[]; label: any[] }>(
    { data: [], label: [] }
  );
  const [interestedData, setInterestedData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [usersList, setUsersList] = useState<any>({});
  const [usersEmail, setUsersEmail] = useState<any[]>([]);
  const [teamsData, setTeamsData] = useState<any>({});
  const [filterType, setFilterType] = useState<any>('All');
  const [contactsFilter, setContactsFilter] = useState<any>({});
  const [tasksFilter, setTasksFilter] = useState<any>({});
  const [callsFilter, setCallsFilter] = useState({});
  const [passOnTaskFilters, setPassOnTaskFilter] = useState<any>({});
  const [passOnContactFilters, setPassOnContactFilters] = useState<any>({});
  const [value, setValue] = React.useState(0);
  const [showMoreContactAnalytics, setShowMoreContactAnalytics] = useState(false);
  const [showTrendAnalytics, setShowTrendAnalytics] = useState(false);
  const [organizationSelectId, setOrganizationSelectId] = useState<any>(organizationIdNameData[0]);
  const dispatcher = useDispatch();

  useEffect(() => {
    const contactFilterObj = localStorage.getItem("contactFilterObj") || "";
    const taskFilterObj = localStorage.getItem("taskFilterObj") || "";
    const callFilterObj = localStorage.getItem("callFilterObj") || "";
    const contactObj = contactFilterObj ? JSON.parse(contactFilterObj) : {};

    let contactFilObj: { [key: string]: [] } = {};
    Object.entries(contactObj).map(([key, value]) => {
      if (contactObj[key].length > 0) {

        if (key === "transfer_status") {
          contactFilObj[key] = contactObj[key]?.map((va: any) => {
            return va?.value ? va?.value === "False" ? false : true : va === "False" ? false : true;
          });
        } else {
          contactFilObj[key] = contactObj[key]?.map((va: any) => {
            return va?.value ? va?.value : va;
          });
        }
      }
      else {
        return {};
      }
    })
    // setContactsFilter({ ...contactFilObj, "transfer_status": [false] });
    setContactsFilter({ ...contactFilObj });
    const taskObj = taskFilterObj ? JSON.parse(taskFilterObj) : {};

    let taskFilObj: { [key: string]: [] } = {};
    Object.entries(taskObj).map(([key, value]) => {
      if (taskObj[key].length > 0) {
        taskFilObj[key] = taskObj[key]?.map((va: any) => {
          return va?.value ? va?.value : va;
        });
      }
      else {
        return {};
      }
    })
    setTasksFilter(taskFilObj);
    const callObj = callFilterObj ? JSON.parse(callFilterObj) : {};
    let callFilObj: { [key: string]: [] } = {};
    Object.entries(callObj).map(([key, value]) => {
      if (callObj[key].length > 0) {
        callFilObj[key] = callObj[key]?.map((va: any) => {
          return va?.value ? va?.value : va;
        });
      }
      else {
        return {};
      }
    })
    setCallsFilter(callFilObj);
  }, [analyticsPanel]);

  useEffect(() => {
    let teams: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.map((user: any) => {
        teams[user.uid] = user.team;
      });
      setTeamsData(teams);
    }
  }, [user, teamLeadUsers, organizationUsers]);

  useEffect(() => {
    if (analyticsType === 'associate' && authToken) {
      callAnalyticsApi();
    }
    else if (analyticsType === 'source' && authToken) {
      callSourceAnalyticsApi();
    }
  }, [
    user.uid,
    organizationUsers,
    analyticsType,
    filterType,
    contactsFilter,
    tasksFilter,
    callsFilter,
    analyticsPanel,
    authToken
  ]);
  // function to call the call logs analytics api
  const callAnalyticsApi = async () => {
    let startDate;
    let endDate;
    setLoad(true);
    setShowOrgFilter(false);
    // Month till date
    if (filterType === 'MTD') {
      const startOfMonth = moment().startOf('month').toDate();
      startDate = startOfMonth;
      endDate = Firebase.firestore.Timestamp.now().toDate();
    }
    // previous month
    else if (filterType === 'PM') {
      const prevMonth = moment().subtract(1, 'M');
      const prevMonthStart = prevMonth.startOf('month').toDate();
      const prevMonthEnd = prevMonth.endOf('month').toDate();
      startDate = prevMonthStart;
      endDate = prevMonthEnd;
    }
    // today
    else if (filterType === 'T') {
      const todayStart = moment().startOf('day').toDate();
      const todayEnd = moment().endOf('day').toDate();
      startDate = todayStart;
      endDate = todayEnd;
    }
    // yesterday
    else if (filterType === 'Y') {
      const prevDay = moment().subtract(1, 'day');
      const prevDayStart = prevDay.startOf('day').toDate();
      const prevDayEnd = prevDay.endOf('day').toDate();
      startDate = prevDayStart;
      endDate = prevDayEnd;
    }
    // we have three modules - call logs, tasks and contacts
    // if we want filters of other modules in current modules, we need to send the api data such that it includes the module name before the filter attribute
    // as for the same module filter, we can send the filter attribute name as it is
    if (analyticsPanel === 'Call Logs') {
      let filters: { [key: string]: any } = {};
      Object.keys(contactsFilter).forEach((key) => {
        filters[`leads.${key}`] = contactsFilter[key];
      });
      setPassOnContactFilters(filters);
      let Tasksfilters: { [key: string]: any } = {};
      Object.keys(tasksFilter).forEach((key) => {
        Tasksfilters[`tasks.${key}`] = tasksFilter[key];
      });
      setPassOnTaskFilter(Tasksfilters);

      // access token for api call
      axios.defaults.headers.common['x-access-token'] =
        authToken

      const { reporting_to, team, branch, transfer_status, ...otherContactFilter } = contactsFilter;
      // interested trend chart api data
      const interestedTrendApiData = {
        uid: user.uid,
        parameter: 'stage_change_at',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: { ...callsFilter, transfer_status },
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken


      // call logs report api data
      const callLogsApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: {},
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: { ...callsFilter, transfer_status },
      };

      // // interested trend chart api call
      //   const interestedTrendRes = await axios.post(
      //   url + '/leads/interestedReport/associate',
      //   interestedTrendApiData
      //   );
      // // call logs report api call
      // const callLogsRes = await axios.post(
      //   url + '/callLogs/callingReport',
      //   callLogsApiData
      // );

      const interestedTrendPromise = await axios.post(
        url + '/leads/interestedReport/associate',
        interestedTrendApiData
      );
      const callingReportPromise = await axios.post(
        url + '/callLogs/callingReport',
        callLogsApiData
      );
      // let interestedTrendRes:any = {};
      // let callLogsRes:any = {};
      Promise.all([interestedTrendPromise, callingReportPromise]).then((data) => {
        let interestedTrendRes: any = data[0];
        let callLogsRes: any = data[1];

        setInterestedTrendAnalytics(interestedTrendRes.data.ChartCount);
        dispatcher(callBackReportData(callLogsRes.data));
        // // setting call logs report data in required format for our chart and report
        if (callLogsRes.data) {
          const obj = Object.assign({}, ...callLogsRes.data.ChartCount);
          let callsData: { [key: string]: { callLogAnalytics: {} } } = {};
          callLogsRes.data.report.forEach((item: any) => {
            let data: {
              '0 sec': number;
              '0-30 sec': number;
              '31-60 sec': number;
              '61-120 sec': number;
              '>120 sec': number;
            } = { '0 sec': 0, '0-30 sec': 0, '31-60 sec': 0, '61-120 sec': 0, '>120 sec': 0 };

            item.duration.forEach((val: any) => {
              if (val.duration === 0) {
                data['0 sec'] = val.count;
              } else if (val.duration === 30) {
                data['0-30 sec'] = val.count;
              } else if (val.duration === 60) {
                data['31-60 sec'] = val.count;
              } else if (val.duration === 120) {
                data['61-120 sec'] = val.count;
              } else {
                data['>120 sec'] = val.count;
              }
            });
            callsData[item.owner] = { callLogAnalytics: data };
          });
          setCallLogApiAnalyticsData(callsData);
          setCallLogAnalytics(obj);
        }
      });
    }
    if (analyticsPanel === 'Contacts' || analyticsPanel === 'Tasks') {
      let filters: { [key: string]: any } = {};

      Object.keys(tasksFilter).forEach((key) => {
        filters[`tasks.${key}`] = tasksFilter[key];
      });
      setPassOnTaskFilter(filters);
      let analytics: {
        [key: string]: {
          leadAnalytics: {
            budget: {};
            stage: {};
            location: {};
            project: {};
            propertyType: {};
            propertyStage: {};
            lost_reason: {};
            not_int_reason: {};
            call_back_reason: {};
          };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const { reporting_to, team, branch, ...otherContactFilter } = contactsFilter;
      // feedback chart api data
      const feedbackApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: { ...otherContactFilter, },
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      //call back reason  api integration
      axios.defaults.headers.common['x-access-token'] =
        authToken
      const callBackReasonApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      // budget chart and report api data

      const budgetApiData = {
        uid: user.uid,
        parameter: 'budget',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      axios.defaults.headers.common['x-access-token'] =
        authToken

      // location report and chart api data
      const locationApiData = {
        uid: user.uid,
        parameter: 'location',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken
      const projectApiData = {
        uid: user.uid,
        parameter: 'project',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const propertyTypeApiData = {
        uid: user.uid,
        parameter: 'property_type',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const propertyStageApiData = {
        uid: user.uid,
        parameter: 'property_stage',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      // const { reporting_to, team, branch, ...otherContactFilter } = contactsFilter;
      const lostReasonApiData = {
        uid: user.uid,
        parameter: 'lost_reason',
        stage: 'LOST',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const notIntApiData = {
        uid: user.uid,
        parameter: 'not_int_reason',
        stage: 'NOT INTERESTED',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      // feedback chart api call
      const feedbackPromise = await axios.post(
        url + '/leads/feedbackReport/associate',
        feedbackApiData
      );
      const callBackPromise = await axios.post(
        url + '/leads/callBackReasonReport/associate',
        callBackReasonApiData
      );
      // budget chart and report api call
      const budgetPromise = await axios.post(
        url + '/leads/interestedReport/associate',
        budgetApiData
      );
      // location chart and report api call
      const locationPromise = await axios.post(
        url + '/leads/interestedReport/associate',
        locationApiData
      );
      const projectPromise = await axios.post(
        url + '/leads/interestedReport/associate',
        projectApiData
      );
      const propertyTypePromise = await axios.post(
        url + '/leads/interestedReport/associate',
        propertyTypeApiData
      );
      const propertyStagePromise = await axios.post(
        url + '/leads/interestedReport/associate',
        propertyStageApiData
      );
      const lostReasonPromise = await axios.post(
        url + '/leads/reasonReport/associate',
        lostReasonApiData
      );
      const notIntPromise = await axios.post(
        url + '/leads/reasonReport/associate',
        notIntApiData
      );

      Promise.all([feedbackPromise, callBackPromise, budgetPromise, locationPromise, projectPromise, propertyTypePromise, propertyStagePromise, lostReasonPromise, notIntPromise]).then((data) => {
        let feedbackRes: any = data[0];
        let callBackReasonRes: any = data[1];
        let budgetRes = data[2];
        let locationRes = data[3];
        let projectRes = data[4];
        let propertyTypeRes = data[5];
        let propertyStageRes = data[6];
        let lostReasonRes = data[7];
        let notIntRes = data[8];
        dispatcher(feedbackReportData(feedbackRes.data.report));
        // converting feedback report data in required format for our chart and report
        if (feedbackRes.data.report) {
          feedbackRes.data.report.forEach((item: any) => {
            let stage: { [key: string]: number } = {};
            item.stage.forEach((val: any) => {
              stage[val.stage] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                stage: stage,
                budget: {},
                location: {},
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: {}
              },
            };
          });
        }

        dispatcher(callBackReasonReportData(callBackReasonRes.data.report));
        if (callBackReasonRes.data.report) {
          callBackReasonRes.data.report.forEach((item: any) => {
            let call_back_reason: { [key: string]: number } = {};
            item.call_back_reason.forEach((val: any) => {
              call_back_reason[val.call_back_reason] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                budget: {},
                location: {},
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                call_back_reason: call_back_reason,
              },
            };
          });
        }

        dispatcher(interestedBudgetReportData(budgetRes.data.report));
        // converting budget report data in required format for our chart and report
        if (budgetRes.data.report) {
          budgetRes.data.report.forEach((item: any) => {
            let budget: { [key: string]: number } = {};
            item.budget.forEach((val: any) => {
              budget[val.budget] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                budget: budget,
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                location: {},
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.owner].leadAnalytics.call_back_reason },
              },
            };
          });
        }

        dispatcher(interestedLocationReportData(locationRes.data.report));
        // converting location report data in required format for our chart and report
        if (locationRes.data.report) {
          locationRes.data.report.forEach((item: any) => {
            let location: { [key: string]: number } = {};
            item.location.forEach((val: any) => {
              location[val.location] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                location: location,
                budget: { ...analytics[item.owner].leadAnalytics.budget },
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.owner].leadAnalytics.call_back_reason },
              },
            };
          });
        }

        dispatcher(interestedProjectReportData(projectRes.data.report));
        if (projectRes.data.report) {
          projectRes.data.report.forEach((item: any) => {
            let project: { [key: string]: number } = {};
            item.project.forEach((val: any) => {
              project[val.project] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                project: project,
                location: { ...analytics[item.owner].leadAnalytics.location },
                budget: { ...analytics[item.owner].leadAnalytics.budget },
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.owner].leadAnalytics.call_back_reason },
              },
            };
          });
        }

        dispatcher(interestedPropertyTypeReportData(propertyTypeRes.data.report));
        if (propertyTypeRes.data.report) {
          propertyTypeRes.data.report.forEach((item: any) => {
            let propertyType: { [key: string]: number } = {};
            item.property_type.forEach((val: any) => {
              propertyType[val.property_type] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                propertyType: propertyType,
                project: { ...analytics[item.owner].leadAnalytics.project },
                location: { ...analytics[item.owner].leadAnalytics.location },
                budget: { ...analytics[item.owner].leadAnalytics.budget },
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.owner].leadAnalytics.call_back_reason },
              },
            };
          });
        }

        dispatcher(interestedPropertyStageReportData(propertyStageRes.data.report));
        if (propertyStageRes.data.report) {
          propertyStageRes.data.report.forEach((item: any) => {
            let propertyStage: { [key: string]: number } = {};
            item.property_stage.forEach((val: any) => {
              propertyStage[val.property_stage] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                propertyStage: propertyStage,
                propertyType: {
                  ...analytics[item.owner].leadAnalytics.propertyType,
                },
                project: { ...analytics[item.owner].leadAnalytics.project },
                location: { ...analytics[item.owner].leadAnalytics.location },
                budget: { ...analytics[item.owner].leadAnalytics.budget },
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.owner].leadAnalytics.call_back_reason },
              },
            };
          });
        }
        dispatcher(lostReasonReportData(lostReasonRes.data.report));
        if (lostReasonRes.data.report) {
          lostReasonRes.data.report.forEach((item: any) => {
            let lost_reason: { [key: string]: number } = {};
            item.lost_reason.forEach((val: any) => {
              lost_reason[val.lost_reason] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                lost_reason: lost_reason,
                propertyStage: {
                  ...analytics[item.owner].leadAnalytics.propertyStage,
                },
                propertyType: {
                  ...analytics[item.owner].leadAnalytics.propertyType,
                },
                project: { ...analytics[item.owner].leadAnalytics.project },
                location: { ...analytics[item.owner].leadAnalytics.location },
                budget: { ...analytics[item.owner].leadAnalytics.budget },
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                not_int_reason: {},
                call_back_reason: { ...analytics[item.owner].leadAnalytics.call_back_reason },
              },
            };
          });
        }

        dispatcher(notInterestedReportData(notIntRes.data.report));
        if (notIntRes.data.report) {
          notIntRes.data.report.forEach((item: any) => {
            let not_int_reason: { [key: string]: number } = {};
            item.not_int_reason.forEach((val: any) => {
              not_int_reason[val.not_int_reason] = val.count;
            });
            analytics[item.owner] = {
              leadAnalytics: {
                not_int_reason: not_int_reason,
                lost_reason: {
                  ...analytics[item.owner].leadAnalytics.lost_reason,
                },
                propertyStage: {
                  ...analytics[item.owner].leadAnalytics.propertyStage,
                },
                propertyType: {
                  ...analytics[item.owner].leadAnalytics.propertyType,
                },
                project: { ...analytics[item.owner].leadAnalytics.project },
                location: { ...analytics[item.owner].leadAnalytics.location },
                budget: { ...analytics[item.owner].leadAnalytics.budget },
                stage: { ...analytics[item.owner].leadAnalytics.stage },
                call_back_reason: { ...analytics[item.owner].leadAnalytics.call_back_reason },
              },
            };
          });
        }
        setApiAnalyticsData(analytics);
      })

    }
    if (analyticsPanel === 'Tasks' || analyticsPanel === 'Contacts') {
      let filters: { [key: string]: any } = {};
      Object.keys(contactsFilter).forEach((key) => {
        if (key === "created_at") {
          filters[key] = contactsFilter[key];
        }
        else {
          filters[`leads.${key}`] = contactsFilter[key];
        }
      });

      setPassOnContactFilters(filters);

      let taskAnalytics: {
        [key: string]: {
          totalTaskCount: { Pending: {}; Overdue: {}; Completed: {} };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        authToken

      // pending task chart and report api data

      const { reporting_to, team, branch, lead_source, transfer_status, ...otherContactFilter } = contactsFilter;
      const pendingTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Pending',
        start_date: startDate,
        end_date: endDate,
        taskFilter: { ...tasksFilter, transfer_status },

        leadFilter: { 'source': lead_source, ...otherContactFilter },

        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      // overdue chart and report api data
      const overdueTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Overdue',
        start_date: startDate,
        end_date: endDate,
        taskFilter: { ...tasksFilter, transfer_status },
        // leadFilter: filters,
        leadFilter: { 'source': lead_source, ...otherContactFilter },
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      axios.defaults.headers.common['x-access-token'] =
        authToken

      // completed task api data
      const completedTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Completed',
        start_date: startDate,
        end_date: endDate,
        taskFilter: { ...tasksFilter, transfer_status },
        // leadFilter: filters,
        leadFilter: { 'source': lead_source, ...otherContactFilter },

        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      // pending task chart and report api call
      const pendingTaskPromise = await axios.post(
        url + '/tasks/tasksReport/associate',
        pendingTaskApiData
      );

      // overdue chart and report api call
      const overdueTaskPromise = await axios.post(
        url + '/tasks/tasksReport/associate',
        overdueTaskApiData
      );

      // completed task api call
      const completedTaskPromise = await axios.post(
        url + '/tasks/tasksReport/associate',
        completedTaskApiData
      );

      Promise.all([pendingTaskPromise, overdueTaskPromise, completedTaskPromise]).then((data) => {
        let pendingTaskRes = data[0];
        let overdueTaskRes = data[1];
        let completedTaskRes = data[2];
        dispatcher(pendingReportData(pendingTaskRes.data.report));
        let pendingCount = 0;

        // converting pending task report data in required format for our chart and report
        if (pendingTaskRes.data.report) {
          pendingTaskRes.data.report.forEach((item: any) => {
            let pending: { [key: string]: number } = {};
            item?.type?.forEach((val: any) => {
              pending[val.type] = val.count;
              if (val.type === 'Meeting' || val.type === 'Site Visit') {
                pendingCount = pendingCount + val.count;
              }
            });
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: pending,
                Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              },
            };
          });
        }

        dispatcher(overDueReportData(overdueTaskRes.data.report));
        // converting overdue task report data in required format for our chart and report
        if (overdueTaskRes.data.report) {
          overdueTaskRes.data.report.forEach((item: any) => {
            let overdue: { [key: string]: number } = {};
            item?.type?.forEach((val: any) => {
              overdue[val.type] = val.count;
              if (val.type === 'Meeting' || val.type === 'Site Visit') {
                pendingCount = pendingCount + val.count;
              }
            });
            if (taskAnalytics[item.owner] === undefined) {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                  Overdue: overdue,
                  Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                },
              };
            } else {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: {
                    ...taskAnalytics[item.owner].totalTaskCount.Pending,
                  },
                  Overdue: overdue,
                  Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                },
              };
            }
          });
        }

        dispatcher(completeTaskReportData(completedTaskRes.data.report));
        let completedCount = 0;
        // converting completed task report data in required format for our chart and report
        if (completedTaskRes.data.report) {
          completedTaskRes.data.report.forEach((item: any) => {
            let completed: { [key: string]: number } = {};
            item?.type?.forEach((val: any) => {
              completed[val.type] = val.count;
              if (val.type === 'Meeting' || val.type === 'Site Visit') {
                completedCount = completedCount + val.count;
              }
            });
            if (taskAnalytics[item.owner] === undefined) {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                  Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                  Completed: completed,
                },
              };
            } else {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: {
                    ...taskAnalytics[item.owner].totalTaskCount.Pending,
                  },
                  Overdue: {
                    ...taskAnalytics[item.owner].totalTaskCount.Overdue,
                  },
                  Completed: completed,
                },
              };
            }
          });
        }

        setTaskStage({ Completed: completedCount, Pending: pendingCount });
        setTaskAnalyticsData(taskAnalytics);
      })
    }
    setLoad(false);
  };

  // same as above, just for source type
  const callSourceAnalyticsApi = async () => {
    let startDate;
    let endDate;
    setLoad(true);
    setShowOrgFilter(false)
    if (filterType === 'MTD') {
      const startOfMonth = moment().startOf('month').toDate();
      startDate = startOfMonth;
      endDate = Firebase.firestore.Timestamp.now().toDate();
    } else if (filterType === 'PM') {
      const prevMonth = moment().subtract(1, 'M');
      const prevMonthStart = prevMonth.startOf('month').toDate();
      const prevMonthEnd = prevMonth.endOf('month').toDate();
      startDate = prevMonthStart;
      endDate = prevMonthEnd;
    } else if (filterType === 'T') {
      const todayStart = moment().startOf('day').toDate();
      const todayEnd = moment().endOf('day').toDate();
      startDate = todayStart;
      endDate = todayEnd;
    } else if (filterType === 'Y') {
      const prevDay = moment().subtract(1, 'day');
      const prevDayStart = prevDay.startOf('day').toDate();
      const prevDayEnd = prevDay.endOf('day').toDate();
      startDate = prevDayStart;
      endDate = prevDayEnd;
    }

    if (analyticsPanel === 'Call Logs') {
      let filters: { [key: string]: any } = {};
      const { reporting_to, team, branch, transfer_status, ...otherContactFilter } = contactsFilter;

      Object.keys(contactsFilter).forEach((key) => {
        filters[`leads.${key}`] = contactsFilter[key];
      });


      setPassOnContactFilters(filters);

      let Tasksfilters: { [key: string]: any } = {};

      Object.keys(tasksFilter).forEach((key) => {
        Tasksfilters[`tasks.${key}`] = tasksFilter[key];
      });

      setPassOnTaskFilter(Tasksfilters);

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const interestedTrendApiData = {
        uid: user.uid,
        parameter: 'stage_change_at',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: { ...callsFilter, transfer_status },
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken
      const callLogsApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: {},
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: { ...callsFilter, transfer_status },
      };
      const interestedTrendPromise = await axios.post(
        url + '/leads/interestedReport/source',
        interestedTrendApiData
      );
      const callLogsPromise = await axios.post(
        url + '/callLogs/callingReport',
        callLogsApiData
      );
      Promise.all([interestedTrendPromise, callLogsPromise]).then(data => {
        let interestedTrendRes = data[0];
        let callLogsRes = data[1];
        setInterestedTrendAnalytics(interestedTrendRes.data.ChartCount);
        dispatcher(callBackReportData(callLogsRes.data));
        if (callLogsRes.data) {
          const obj = Object.assign({}, ...callLogsRes.data.ChartCount);
          let callsData: { [key: string]: { callLogAnalytics: {} } } = {};
          callLogsRes.data.report.forEach((item: any) => {
            let data: { [key: string]: number } = {};
            item.duration.forEach((val: any) => {
              if (val.duration === 'Other') {
                data['>120'] = val.count;
              } else {
                data[val.duration] = val.count;
              }
            });
            callsData[item.owner] = { callLogAnalytics: data };
          });

          setCallLogApiAnalyticsData(callsData);
          setCallLogAnalytics(obj);
        }
      })
    }
    if (analyticsPanel === 'Contacts') {
      const { reporting_to, team, branch, ...otherContactFilter } = contactsFilter;

      let Tasksfilters: { [key: string]: any } = {};

      Object.keys(tasksFilter).forEach((key) => {
        Tasksfilters[`tasks.${key}`] = tasksFilter[key];
      });
      setPassOnTaskFilter(Tasksfilters);
      let analytics: {
        [key: string]: {
          leadAnalytics: {
            budget: {};
            stage: {};
            location: {};
            project: {};
            propertyType: {};
            propertyStage: {};
            lost_reason: {};
            not_int_reason: {};
            call_back_reason: {};
          };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const feedbackApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      //call back reason  api integration
      axios.defaults.headers.common['x-access-token'] =
        authToken

      const callBackReasonApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      axios.defaults.headers.common['x-access-token'] =
        authToken

      const budgetApiData = {
        uid: user.uid,
        parameter: 'budget',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      axios.defaults.headers.common['x-access-token'] =
        authToken

      const locationApiData = {
        uid: user.uid,
        parameter: 'location',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      axios.defaults.headers.common['x-access-token'] =
        authToken

      const projectApiData = {
        uid: user.uid,
        parameter: 'project',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const propertyTypeApiData = {
        uid: user.uid,
        parameter: 'property_type',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      axios.defaults.headers.common['x-access-token'] =
        authToken

      const propertyStageApiData = {
        uid: user.uid,
        parameter: 'property_stage',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const lostReasonApiData = {
        uid: user.uid,
        parameter: 'lost_reason',
        stage: 'LOST',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const notIntApiData = {
        uid: user.uid,
        parameter: 'not_int_reason',
        stage: 'NOT INTERESTED',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: otherContactFilter,
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      const feedbackPromise = await axios.post(
        url + '/leads/feedbackReport/source',
        feedbackApiData
      );
      const callBackReasonPromise = await axios.post(
        url + '/leads/callBackReasonReport/source',
        callBackReasonApiData
      );
      const budgetPromise = await axios.post(
        url + '/leads/interestedReport/source',
        budgetApiData
      );
      const locationPromise = await axios.post(
        url + '/leads/interestedReport/source',
        locationApiData
      );
      const projectPromise = await axios.post(
        url + '/leads/interestedReport/source',
        projectApiData
      );

      const propertyTypePromise = await axios.post(
        url + '/leads/interestedReport/source',
        propertyTypeApiData
      );
      const propertyStagePromise = await axios.post(
        url + '/leads/interestedReport/source',
        propertyStageApiData
      );
      const lostReasonPromise = await axios.post(
        url + '/leads/reasonReport/source',
        lostReasonApiData
      );
      const notIntPromise = await axios.post(
        url + '/leads/reasonReport/source',
        notIntApiData
      );
      Promise.all([feedbackPromise, callBackReasonPromise, budgetPromise, locationPromise, projectPromise, propertyTypePromise, propertyStagePromise, lostReasonPromise, notIntPromise]).then(data => {
        let feedbackRes = data[0];
        let callBackReasonRes = data[1];
        let budgetRes = data[2];
        let locationRes = data[3];
        let projectRes = data[4];
        let propertyTypeRes = data[5];
        let propertyStageRes = data[6];
        let lostReasonRes = data[7];
        let notIntRes = data[8];
        dispatcher(feedbackReportData(feedbackRes.data.report));
        if (feedbackRes.data.report) {
          feedbackRes.data.report.forEach((item: any) => {
            let stage: { [key: string]: number } = {};
            item.stage.forEach((val: any) => {
              stage[val.stage] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                stage: stage,
                budget: {},
                location: {},
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: {}
              },
            };
          });
        }

        dispatcher(callBackReasonReportData(callBackReasonRes.data.report));
        if (callBackReasonRes.data.report) {
          callBackReasonRes.data.report.forEach((item: any) => {
            let call_back_reason: { [key: string]: number } = {};
            item.call_back_reason.forEach((val: any) => {
              call_back_reason[val.call_back_reason] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                budget: {},
                location: {},
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                call_back_reason: call_back_reason,
              },
            };
          });
        }

        dispatcher(interestedBudgetReportData(budgetRes.data.report));
        if (budgetRes.data.report) {
          budgetRes.data.report.forEach((item: any) => {
            let budget: { [key: string]: number } = {};
            item.budget.forEach((val: any) => {
              budget[val.budget] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                budget: budget,
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                location: {},
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.lead_source].leadAnalytics.call_back_reason },
              },
            };
          });
        }

        dispatcher(interestedLocationReportData(locationRes.data.report));
        if (locationRes.data.report) {
          locationRes.data.report.forEach((item: any) => {
            let location: { [key: string]: number } = {};
            item.location.forEach((val: any) => {
              location[val.location] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                location: location,
                budget: { ...analytics[item.lead_source].leadAnalytics.budget },
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                project: {},
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.lead_source].leadAnalytics.call_back_reason }
              },
            };
          });
        }

        dispatcher(interestedProjectReportData(projectRes.data.report));
        if (projectRes.data.report) {
          projectRes.data.report.forEach((item: any) => {
            let project: { [key: string]: number } = {};
            item.project.forEach((val: any) => {
              project[val.project] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                project: project,
                location: {
                  ...analytics[item.lead_source].leadAnalytics.location,
                },
                budget: { ...analytics[item.lead_source].leadAnalytics.budget },
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                propertyType: {},
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.lead_source].leadAnalytics.call_back_reason }
              },
            };
          });
        }

        dispatcher(interestedPropertyTypeReportData(propertyTypeRes.data.report));
        if (propertyTypeRes.data.report) {
          propertyTypeRes.data.report.forEach((item: any) => {
            let propertyType: { [key: string]: number } = {};
            item.property_type.forEach((val: any) => {
              propertyType[val.property_type] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                propertyType: propertyType,
                project: { ...analytics[item.lead_source].leadAnalytics.project },
                location: {
                  ...analytics[item.lead_source].leadAnalytics.location,
                },
                budget: { ...analytics[item.lead_source].leadAnalytics.budget },
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                propertyStage: {},
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.lead_source].leadAnalytics.call_back_reason },
              },
            };
          });
        }

        dispatcher(interestedPropertyStageReportData(propertyStageRes.data.report));
        if (propertyStageRes.data.report) {
          propertyStageRes.data.report.forEach((item: any) => {
            let propertyStage: { [key: string]: number } = {};
            item.property_stage.forEach((val: any) => {
              propertyStage[val.property_stage] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                propertyStage: propertyStage,
                propertyType: {
                  ...analytics[item.lead_source].leadAnalytics.propertyType,
                },
                project: { ...analytics[item.lead_source].leadAnalytics.project },
                location: {
                  ...analytics[item.lead_source].leadAnalytics.location,
                },
                budget: { ...analytics[item.lead_source].leadAnalytics.budget },
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                lost_reason: {},
                not_int_reason: {},
                call_back_reason: { ...analytics[item.lead_source].leadAnalytics.call_back_reason }
              },
            };
          });
        }

        dispatcher(lostReasonReportData(lostReasonRes.data.report));
        if (lostReasonRes.data.report) {
          lostReasonRes.data.report.forEach((item: any) => {
            let lost_reason: { [key: string]: number } = {};
            item.lost_reason.forEach((val: any) => {
              lost_reason[val.lost_reason] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                lost_reason: lost_reason,
                propertyStage: {
                  ...analytics[item.lead_source].leadAnalytics.propertyStage,
                },
                propertyType: {
                  ...analytics[item.lead_source].leadAnalytics.propertyType,
                },
                project: { ...analytics[item.lead_source].leadAnalytics.project },
                location: {
                  ...analytics[item.lead_source].leadAnalytics.location,
                },
                budget: { ...analytics[item.lead_source].leadAnalytics.budget },
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                call_back_reason: { ...analytics[item.lead_source].leadAnalytics.call_back_reason },
                not_int_reason: {},
              },
            };
          });
        }

        dispatcher(notInterestedReportData(notIntRes.data.report));
        if (notIntRes.data.report) {
          notIntRes.data.report.forEach((item: any) => {
            let not_int_reason: { [key: string]: number } = {};
            item.not_int_reason.forEach((val: any) => {
              not_int_reason[val.not_int_reason] = val.count;
            });
            analytics[item.lead_source] = {
              leadAnalytics: {
                not_int_reason: not_int_reason,
                lost_reason: {
                  ...analytics[item.lead_source].leadAnalytics.lost_reason,
                },
                propertyStage: {
                  ...analytics[item.lead_source].leadAnalytics.propertyStage,
                },
                propertyType: {
                  ...analytics[item.lead_source].leadAnalytics.propertyType,
                },
                project: { ...analytics[item.lead_source].leadAnalytics.project },
                location: {
                  ...analytics[item.lead_source].leadAnalytics.location,
                },
                budget: { ...analytics[item.lead_source].leadAnalytics.budget },
                stage: { ...analytics[item.lead_source].leadAnalytics.stage },
                call_back_reason: { ...analytics[item.lead_source].leadAnalytics.call_back_reason }
              },
            };
          });
        }
        setApiAnalyticsData(analytics);
      })
    }
    if (analyticsPanel === 'Tasks' || analyticsPanel === 'Contacts') {
      const { reporting_to, team, branch, lead_source, ...otherContactFilter } = contactsFilter;
      let filters: { [key: string]: any } = {};
      Object.keys(contactsFilter).forEach((key) => {
        if (key === "created_at") {
          filters[key] = contactsFilter[key];
        }
        else {
          filters[`leads.${key}`] = contactsFilter[key];
        }
      });
      setPassOnContactFilters(filters);
      let taskAnalytics: {
        [key: string]: {
          totalTaskCount: { Pending: {}; Overdue: {}; Completed: {} };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        authToken

      const pendingTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Pending',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,

        leadFilter: { 'source': lead_source, ...otherContactFilter },
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      let pendingCount = 0;



      axios.defaults.headers.common['x-access-token'] =
        authToken

      const overdueTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Overdue',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,

        leadFilter: { 'source': lead_source, ...otherContactFilter },
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };


      axios.defaults.headers.common['x-access-token'] =
        authToken

      const completedTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Completed',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: { 'source': lead_source, ...otherContactFilter },
        leadUserFilter: {
          reporting_to,
          team,
          branch
        },
        callFilter: callsFilter,
      };

      const pendingTaskPromise = await axios.post(
        url + '/tasks/tasksReport/source',
        pendingTaskApiData
      );
      const overdueTaskPromise = await axios.post(
        url + '/tasks/tasksReport/source',
        overdueTaskApiData
      );
      const completedTaskPromise = await axios.post(
        url + '/tasks/tasksReport/source',
        completedTaskApiData
      );

      Promise.all([pendingTaskPromise, overdueTaskPromise, completedTaskPromise]).then(data => {
        let pendingTaskRes = data[0];
        let overdueTaskRes = data[1];
        let completedTaskRes = data[2];
        dispatcher(pendingReportData(pendingTaskRes.data.report));
        if (pendingTaskRes.data.report) {
          pendingTaskRes.data.report.forEach((item: any) => {
            let pending: { [key: string]: number } = {};
            item?.type?.forEach((val: any) => {
              pending[val.type] = val.count;
              if (val.type === 'Meeting' || val.type === 'Site Visit') {
                pendingCount = pendingCount + val.count;
              }
            });
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: pending,
                Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              },
            };
          });
        }

        dispatcher(overDueReportData(overdueTaskRes.data.report));
        if (overdueTaskRes.data.report) {
          overdueTaskRes.data.report.forEach((item: any) => {
            let overdue: { [key: string]: number } = {};
            item?.type?.forEach((val: any) => {
              overdue[val.type] = val.count;
              if (val.type === 'Meeting' || val.type === 'Site Visit') {
                pendingCount = pendingCount + val.count;
              }
            });
            if (taskAnalytics[item.owner] === undefined) {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                  Overdue: overdue,
                  Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                },
              };
            } else {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: {
                    ...taskAnalytics[item.owner].totalTaskCount.Pending,
                  },
                  Overdue: overdue,
                  Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                },
              };
            }
          });
        }
        ;

        dispatcher(completeTaskReportData(completedTaskRes.data.report));
        let completedSum = 0;
        if (completedTaskRes.data.report) {
          completedTaskRes.data.report.forEach((item: any) => {
            let completed: { [key: string]: number } = {};
            item?.type?.forEach((val: any) => {
              completed[val.type] = val.count;
              if (val.type === 'Meeting' || val.type === 'Site Visit') {
                completedSum = completedSum + val.count;
              }
            });
            if (taskAnalytics[item.owner] === undefined) {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                  Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                  Completed: completed,
                },
              };
            } else {
              taskAnalytics[item.owner] = {
                totalTaskCount: {
                  Pending: {
                    ...taskAnalytics[item.owner].totalTaskCount.Pending,
                  },
                  Overdue: {
                    ...taskAnalytics[item.owner].totalTaskCount.Overdue,
                  },
                  Completed: completed,
                },
              };
            }
          });
        }
        setTaskStage({ Completed: completedSum, Pending: pendingCount });
        setTaskAnalyticsData(taskAnalytics);
      })
    }
    setLoad(false);
  };


  useEffect(() => {
    if (organizationUsers) {
      let l: any[] = [];
      const users: { [key: string]: string } = {};

      organizationUsers.map((user: any) => {
        users[user.uid] = user.user_first_name + ' ' + user.user_last_name;
        l.push(user.user_email);
      });

      setUsersList(users);
      setUsersEmail(l);
    }
  }, [organizationUsers]);

  useEffect(() => {
    if (callLogAnalytics) {
      const tempData: any[] = [];
      Object.values(callLogAnalytics).forEach((val: any) => {
        tempData.push(val);
      });
      setCallLogData({ data: tempData.reverse(), label: Object.keys(callLogAnalytics).reverse() });
    }
  }, [callLogAnalytics]);
  useEffect(() => {
    if (interestedTrendAnalytics) {
      const sortedDates = Object.keys(interestedTrendAnalytics).sort(
        sortDates,
      );
      const values: any[] = [];
      sortedDates.forEach((key) => {
        values.push(interestedTrendAnalytics[key]);
      });
      setInterestedData({
        data: values.reverse(),
        label: sortedDates.reverse(),
      });
    }
  }, [interestedTrendAnalytics]);

  const state = {
    labels: callLogData.label,
    datasets: [
      {
        label: 'Calling Trend',
        backgroundColor: '#4FCE5D',
        borderColor: '#4FCE5D',
        borderWidth: 2,
        data: callLogData.data,
        pointRadius: 6,
      },
    ],
  };

  const interestedState = {
    labels: interestedData.label,
    datasets: [
      {
        label: 'Interested Lead Trend',
        backgroundColor: '#1589FF',
        borderColor: '#1589FF',
        borderWidth: 2,
        data: interestedData.data,
        pointRadius: 6,
      },
    ],
  };
  const data = {
    labels: overdueTrend.keys,
    datasets: [
      {
        label: 'Completed Task',
        data: completeTrend.values,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-1',
        pointRadius: 6,
      },
      {
        label: 'Overdue Task',
        data: overdueTrend.values,
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
        yAxisID: 'y-axis-2',
        pointRadius: 6,
      },
    ],
  };
  // useEffect(() => {
  //   setShowFilter(false);
  // }, [])
  useEffect(() => {
    if (apiAnalyticsData) {

      let total = 0;
      let tempCounts: { [key: string]: number } = {
        FRESH: 0,
        INTERESTED: 0,
        CALLBACK: 0,
        WON: 0,
        'NOT INTERESTED': 0,
        LOST: 0,
      };
      Object.values(apiAnalyticsData).forEach((userAnalytics: any) => {
        Object.keys(userAnalytics.leadAnalytics.stage).forEach((key) => {
          if (key !== 'Pending' && key !== 'Completed') {
            total += userAnalytics.leadAnalytics.stage[key];
          }
        });
        const analyticsCount = userAnalytics.leadAnalytics.stage;
        Object.keys(tempCounts).forEach((key) => {
          if (analyticsCount[key]) {
            tempCounts[key] += analyticsCount[key];
          }
        });
      });

      setStage(tempCounts);
      setTotal(total);
    }
  }, [apiAnalyticsData]);

  useEffect(() => {
  }, [contactsFilter, tasksFilter, callsFilter]);

  useEffect(() => {
    if (clearFilter === true) {
      setContactsFilter({});
      setTasksFilter({});
      setCallsFilter({});
      setClearFilter(false);
    }

  }, [clearFilter]);
  useEffect(() => {
    setShowMoreContactAnalytics(false);
    setShowTrendAnalytics(false);
  }, [analyticsPanel])
  const handleClearFilter = () => {
    setClearFilter(true);
    setFilterType({});

    localStorage.clear();
  }

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const analyticsTabs = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark }
  const buttonStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const tabs = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, borderRadius: "0.25rem", color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

  return (
    <div className={styles.parent} style={{ marginTop: '3%' }}>
      {load === true && <Loading />}
      <div className={styles.container} style={containerStyles}>
        {/* <div
          // className={styles.analyticsPanel}
          className="card p-1 shadow rounded d-flex flex-row justify-content-evenly"
          style={{ ...analyticsTabs }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className={styles.contactsButton}
              style={buttonStyles}
              onClick={() => setPanel('Contacts')}
            >
              Contacts Analytics
            </button>
            {analyticsPanel === 'Contacts' && <div className={styles.line}></div>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className={styles.tasksButton}
              style={buttonStyles}
              onClick={() => setPanel('Tasks')}
            >
              Tasks Analytics
            </button>
            {analyticsPanel === 'Tasks' && <div className={styles.line}></div>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className={styles.callsButton}
              style={buttonStyles}
              onClick={() => setPanel('Call Logs')}
            >
              Call Logs Analytics
            </button>
            {analyticsPanel === 'Call Logs' && <div className={styles.line}></div>}
          </div>
        </div> */}
        <div className={styles.type}>
        <Box className='tabbutt' sx={{ maxWidth: { xs: 320, sm: 480 } }}
            style={{...tabs}}
          >
           <button className={styles.filterBox2} onClick={() => {
              setAnalyticsType('associate');
              setSource(false);
            }}>
               <FaUsers
                    color={'#272944'}
                    size="10"
                    style={{ marginRight: '10px' }}
                  />
              Team</button>
            <button className={styles.filterBox2} style={{ marginLeft: "0.5rem" }} onClick={() => {
              setAnalyticsType('source');
              setSource(true);
            }}>
              <FaBook
                    color={'#272944'}
                    size="10"
                    style={{ marginRight: '10px' }}
                  />
              Source</button>
          </Box>
          <div className={styles.filters}>
            <div style={{ display: "flex" }}>
              <div className='checkBoxteamWise'>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setChecked(!checked);
                      }}
                      name="checkedB"
                      style={{
                        color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
                      }}
                    />
                  }
                  label={<span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Team Wise</span>}
                />
              </div>
              <div style={{ marginLeft: "5px", marginRight: "5px", marginTop: "8px" }}>
                <button
                  className={styles.filterBox}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FaFilter
                    color={'#272944'}
                    size="10"
                    style={{ marginRight: '10px' }}
                  />
                  Date Filter
                </button>
                {showFilter === true && (
                  <div className={styles.filter}>
                    <AnalyticsFilter
                      setFilterState={(data) => setFilterType(data)}
                      filterUsed={filterType}
                      showFilter={(data) => setShowFilter(data)}
                    />
                  </div>
                )}
              </div>
            </div>
            <KanbanViewFilter
              panel={analyticsPanel}
              setCallsFilter={(data) => setCallsFilter(data)}
              setContactsFilter={(data) => setContactsFilter(data)}
              setTaskFilters={(data) => setTasksFilter(data)}
              setClearFilter={(data) => setClearFilter(data)}
              clearFilter={clearFilter}
              usersList={usersEmail}
              hideDateFilter={setShowFilter}
              organizationSelectId={organizationSelectId}
            />
            {role === "superAdmin" ? <> <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              <button
                className={styles.filterBox}
                onClick={() => setShowOrgFilter(!showOrgFilter)}
              >
                <FaFilter
                  color={'#272944'}
                  size="10"
                  style={{ marginRight: '10px' }}
                />
                Organization Filter
              </button>
            </div>
            </> : null}
            <div style={{ marginLeft: "5px" }}>
              <button
                className={styles.filterBox}
                onClick={() => handleClearFilter()}
              >
                <FaBeer
                  color={'#272944'}
                  size="10"
                  style={{ marginRight: '10px' }}
                />
                Clear Filter
              </button>
            </div>
          </div>
        </div>
        <div className={styles.topContainer}>
          <AnalyticsCountContainer
            stage={stage}
            history={history}
            filter={filterType}
            taskStage={taskStage}
            taskFilter={passOnTaskFilters}
            leadFilter={contactsFilter}
            otherContactFilter={passOnContactFilters}
            otherTaskFilter={tasksFilter} source={source} total={total}
          />
        </div>
        <div className={styles.graphs}>
          {analyticsPanel === 'Contacts' && (
            <>
              <FeedbackGraph
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
              <CallBackReason
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
            </>
          )}

          {analyticsPanel === 'Call Logs' && (
            <Trends
              state={state}
              interestedState={interestedState}
              history={history}
              filter={filterType}
              analyticsData={apiCallLogAnalyticsData}
              usersList={usersList}
              checked={checked}
              teamsData={teamsData}
              source={source}
              taskFilters={passOnTaskFilters}
              leadFilters={passOnContactFilters}
              callFilters={callsFilter}
              showTrendAnalytics={showTrendAnalytics}
              setShowTrendAnalytics={setShowTrendAnalytics}
            />
          )}

          {analyticsPanel === 'Contacts' && showMoreContactAnalytics ?
            <>
              <InterestedBudget
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
              <InterestedLocation
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
              <InterestedProject
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
              {/* <InterestedPropertyType
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              /> */}

              {/* <InterestedPropertyStage
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              /> */}
              <LostReason
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
              <NotIntReason
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
            </> : null
          }

          {analyticsPanel === 'Contacts' && !showMoreContactAnalytics ? (
            <div style={{ textAlign: "center" }}>
              <button className={styles.filterBox2} onClick={() => { setShowMoreContactAnalytics(!showMoreContactAnalytics) }}>Show More</button>
            </div>
          ) : null}

          {analyticsPanel === 'Tasks' && (
            <>
              {' '}
              <CompletedTask
                analyticsData={taskAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                passOnFilters={passOnContactFilters}
                tasksFilters={tasksFilter}
              />
              <PendingTask
                analyticsData={taskAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                passOnFilters={passOnContactFilters}
                tasksFilters={tasksFilter}
              />
              <OverdueTask
                analyticsData={taskAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                passOnFilters={passOnContactFilters}
                tasksFilters={tasksFilter}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    role: state.user.role,
    branchUsers: state.branchUsers.data,
    clearfiltersideBar: state.clearFilter.clear,
    theme: state.theme.isLightMode,
    authToken: state.user.authToken,
    conatctHeader: state.contactHeaderList.data,
    organizationIdNameData: state.organizationIdNameData.data,
    analyticsPanel:state.analyticsPanel.data
  };
};
export default connect(mapStateToProps)(SalesAnalytics);