import React, { useState, useEffect } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { USERLIST_COLUMNS, USER_COLUMNS } from "../../Values/tables";
import Loading from "../../Components/Loading/Loading";
import {
    filterStatus,
    getDateString,
    getFilterBranch,
    searchUserItem,
} from "../../Values/utils";
import styles from '../Analytics/Analytics.module.css'
import ImportUsersModal from "../../Components/Modals/ImportModal/ImportUsersModal";
import CustomTable from "../../Components/CustomTable/CustomTable";
import ListViewModal from "../../Components/Modals/ListViewModal/ListViewModal";
import BranchModal from "../../Components/Modals/BranchModal/BranchModal";
import ChangeStageModal from "../../Components/Modals/ChangeStageModal/ChangeStageModal";
import { setTeamLeadUsersList, showSnackbarAction } from "../../Redux/actions";
import { setBranchLists } from "../../Redux/actions";
import DeleteRecordsModal from "../../Components/Modals/DeleteRecordsModal/DeleteRecordsModal";
import { themeColors } from "../../Components/theme";
import { fetchAllUserList } from "../../Services/organizations";
import axios from "axios";
import ChangePasswordModal from '../SuperAdminPanel/PasswordChangeModel'
import { url } from "../../Values/constants";
import { deleteUser } from "../../Services/users";

const UserList = ({
    history,
    organizationId,
    organizationUsers,
    user,
    theme
}: any) => {
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [allUserList, setAllUserList] = useState<any[] | undefined>(undefined);
    const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
    const [temporaryData, setTemporaryData] = useState<any[]>([]);
    const [searchQuery, setsearchQuery] = useState("");
    const [status, setStatus] = useState("ALL");
    const [showImportModal, setShowImportModal] = useState(false);
    const [columnModal, setColumnModal] = useState(false);
    const [toggleCondition, setToggleCondition] = useState<boolean>(false);
    const [listViewModal, setListViewModal] = useState(false);
    const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
    const [branchModal, setBranchModal] = useState(false);
    const [branchList, setBranchList] = useState<any[]>([]);
    const [uid, setUid] = useState("");
    const [load, setLoad] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [rowChangePassData, setRowChangePassData] = useState<any>();

    const [changeStageModal, setChangeStageModal] =
        useState(false);
    const [deleteRecordsModal, setDeleteRecordsModal] =
        useState(false);

    const dispatcher = useDispatch();

    useEffect(() => {
        if (toggleCondition) {
            setsearchQuery("");
            setToggleCondition(false);
        }
    }, [toggleCondition]);


    useEffect(() => {
        const unsub = fetchAllUserList(
            (data: any) => setAllUserList(data),
            dispatcher
        );
        return () => {
            unsub();
        };
    }, []);

    useEffect(() => {
        if (allUserList === undefined) {
        } else {
            setFilterData(allUserList);
            const filterData = getFilterBranch(allUserList);
            if (filterData.branch) {
                setBranchList(filterData.branch);
                dispatcher(setBranchLists(filterData.branch));
            } else {
                setBranchList([]);
            }
        }
    }, [allUserList, dispatcher]);


    useEffect(() => {
        if (status === "ALL") {
            if (allUserList) {
                const data = searchUserItem(
                    allUserList,
                    searchQuery
                );
                setTemporaryData(data);
                setFilterData(data);
            }
        }
        else {
            const data = searchUserItem(
                temporaryData,
                searchQuery
            );
            setFilterData(data);
        }
        // eslint-disable-next-line
    }, [searchQuery, allUserList]);

    useEffect(() => {
        if (searchQuery === "") {
            if (allUserList) {
                const data = filterStatus(
                    allUserList,
                    status
                );
                setTemporaryData(data);
                setFilterData(data);
            }
        } else {
            const data = filterStatus(temporaryData, status);
            setFilterData(data);
        }
        // eslint-disable-next-line
    }, [status, allUserList]);

    const openPasswordModal = (data: any) => {
        setShowPasswordModal(true);
        setRowChangePassData(data);
    };

    const closePasswordModal = () => {
        setShowPasswordModal(false);
    };

    const handleDeleteUserOrg = (data: any) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            setLoad(true);
            deleteUser(
                data?.uid,
                (value) => setLoad(value),
                dispatcher
            );
        }
    }

    const changePasswordUser = () => {
        if (newPassword === "") {
            dispatcher(
                showSnackbarAction(
                    "New Password is required!!",
                    "error"
                )
            );
            return;
        } else if (confirmPassword === "") {
            dispatcher(
                showSnackbarAction(
                    "Confirm Password is required!!",
                    "error"
                )
            );
            return;
        }
        // updateUserAuth()
        if (newPassword === confirmPassword) {
            updateUserAuth();
        }
        else {
            dispatcher(
                showSnackbarAction(
                    "Passwords do not match!!",
                    "error"
                )
            );
            return;
        }
    };

    const updateUserAuth = async () => {
        const bodyData = {
            uid: rowChangePassData?.uid,
            newEmail: rowChangePassData?.user_email,
            newPassword: newPassword
        }
        // console.log("bodyDataDeleteUser", bodyData);
        try {
            const repUpdateUserAuth = await axios.post(`${url}/utils/firebase-updateUser`, bodyData);
            // handle response
            console.log("repUpdateUserAuth", repUpdateUserAuth);
            if (repUpdateUserAuth?.status == 200) {
                dispatcher(
                    showSnackbarAction(
                        "Password changed successfully!",
                        "success"
                    )
                );
                closePasswordModal();
            }
        } catch (error) {
            // handle error
            closePasswordModal();
        }
    };

    const topBarStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, width: "97%" }
    const parentStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark }
    return (
        <>
            <div className={styles.parent} style={{ display: "block", marginTop: "4%", ...parentStyles }}>
                <div className={commonStyle.topBar} style={{ marginTop: "0px", ...topBarStyles }}>
                    {allUserList === undefined && <Loading />}
                    {load && <Loading />}
                    <Topbar
                        history={history}
                        title={"All User List"}
                        path={"/addUsers"}
                        onClick={() => {
                            setShowImportModal(true);
                        }}
                        onChange={(val) => setsearchQuery(val)}
                        setStatus={(status) => setStatus(status)}
                        filterText="Status"
                        status={status}
                        setColumnModal={(data) => setColumnModal(data)}
                        show={true}
                        showStatusBox={true}
                        toggleCondition={toggleCondition}
                        setListViewModal={(data) =>
                            setListViewModal(data)
                        }
                        setLoad={(data) => setLoad(data)}
                        setChangeStageModal={(data) =>
                            setChangeStageModal(data)
                        }
                        setDeleteRecordsModal={(data) =>
                            setDeleteRecordsModal(data)
                        }
                    />
                </div>
                <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
                    {/* {(allUserList === undefined ||
                        load === true) && <Loading />} */}
                    <CustomTable
                        tableColumns={USERLIST_COLUMNS}
                        tableRows={filterData}
                        selectedRows={selectedRows}
                        setSelectedRows={(data) => setSelectedRows(data)}
                        tableType="All User List"
                        // tableType="User List"
                        showColumnModal={columnModal}
                        hideColumnModal={() => setColumnModal(false)}
                        selectedRowsData={selectedRowsData}
                        setSelectedRowsData={(data) =>
                            setSelectedRowsData(data)
                        }
                        setSearchedItem={(val) => setsearchQuery(val)}
                        setBranch={(val) => setBranchModal(val)}
                        setUid={(data) => setUid(data)}
                        setToggleCondition={setToggleCondition}
                        handleDeleteOrgUser={handleDeleteUserOrg}
                        handleChangePassword={openPasswordModal}
                    />
                    {showImportModal === true && (
                        <ImportUsersModal
                            open={showImportModal}
                            close={() => setShowImportModal(false)}
                            organization_id={organizationId}
                            history={history}
                            usersList={
                                organizationUsers ? organizationUsers : []
                            }
                            user={user}
                        />
                    )}
                    {listViewModal === true && (
                        <ListViewModal
                            open={listViewModal}
                            close={() => setListViewModal(false)}
                        />
                    )}
                    {branchModal === true && (
                        <BranchModal
                            open={branchModal}
                            close={() => setBranchModal(false)}
                            branchList={branchList}
                            uid={uid}
                        />
                    )}
                    {changeStageModal === true && (
                        <ChangeStageModal
                            open={changeStageModal}
                            close={() => setChangeStageModal(false)}
                        />
                    )}
                    {deleteRecordsModal && (
                        <DeleteRecordsModal
                            open={deleteRecordsModal}
                            close={() => setDeleteRecordsModal(false)}
                            filterData={filterData}
                        />
                    )}
                    {showPasswordModal && (
                        <ChangePasswordModal
                            open={showPasswordModal}
                            close={closePasswordModal}
                            setNewPassword={setNewPassword}
                            setConfirmPassword={setConfirmPassword}
                            newPassword={newPassword}
                            confirmPassword={confirmPassword}
                            changePasswordUser={changePasswordUser}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        organizationId: state.organization.id,
        organizationUsers: state.organizationUsers.data,
        filterObject: state.filterObject,
        organization: state.organization,
        role: state.user.role,
        theme: state.theme.isLightMode,
    };
};

export default connect(mapStateToProps)(UserList);