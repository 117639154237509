import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FunctionComponent } from 'react';
import { connect, useDispatch } from 'react-redux';
import styles from '../../CommonStyles/CommonGraphStyles.module.css';
import { setClearFilter, updateStage } from '../../Redux/actions';
import {
  apiCallDrilldown,
  apiContactsDrilldown,
  drillDownTableOrg,
} from '../../Services/analytics';
import { changeDateForRoute } from '../../Services/contacts';
import { properFormat, sortAnalytics } from '../../Values/utils';
import * as ReactBootStrap from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './IntBar.style.css';
import { themeColors } from '../../Components/theme';
import { CSVLink } from "react-csv";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type props = {
  data: any;
  type:
  | 'project'
  | 'location'
  | 'budget'
  | 'stage'
  | 'lost_reason'
  | 'call_back_reason'
  | 'not_int_reason'
  | 'propertyType'
  | 'propertyStage'
  | 'callLogs';
  heading: string;
  usersList: any;
  checked: boolean;
  teamsData: any;
  style?: any;
  show?: boolean;
  contacts: any;
  filter: 'MTD' | 'YTD' | 'PM' | 'All' | 'CUSTOM' | 'T' | 'Y';
  organizationUsers: any;
  history: any;
  user: any;
  teamLeadUsers: any;
  branchUsers: any;
  taskFilter?: any;
  leadFilter?: any;
  callFilter?: any;
  source?: boolean;
  theme: any;
  selectOrgId:any;
};

const IntBarTable: FunctionComponent<props> = ({
  data,
  heading,
  type,
  usersList,
  checked,
  teamsData,
  style,
  show,
  contacts,
  filter,
  organizationUsers,
  history,
  user,
  teamLeadUsers,
  branchUsers,
  taskFilter,
  leadFilter,
  callFilter,
  source,
  theme,
  selectOrgId
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [sortTableData, setSortTableData] = useState<any[]>([]);
  const [header, setHeader] = useState<any[]>([]);
  const [sortData, setSort] = useState<any[]>([]);
  const [sortTable, setSortTable] = useState<boolean>(false);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  useEffect(() => {
    const tempHeader: string[] = ['Associate', 'Total'];
    const tempData: any[] = [];
    if (type === 'callLogs') {
      Object.keys(data).forEach((key) => {
        const analytics = data[key].callLogAnalytics;
        let sum = 0;
        Object.keys(analytics).forEach((key) => {
          if (
            !tempHeader.includes(key) &&
            key !== 'Pending' &&
            key !== 'Completed'
          ) {
            tempHeader.push(key);
          }
        });
        Object.keys(analytics).forEach((key) => {
          if (key !== 'Pending' && key !== 'Completed') {
            sum += analytics[key];
          }
        });
        tempData.push({
          name: checked ? key : source ? key : usersList[key],
          uid: key,
          Total: sum,
          ...analytics,
        });
      });
    } else {
      Object.keys(data).forEach((key) => {
        const analytics = data[key].leadAnalytics[type];
        let sum = 0;
        Object.keys(analytics).forEach((key) => {
          if (
            !tempHeader.includes(key) &&
            key !== 'Pending' &&
            key !== 'Completed'
          ) {
            tempHeader.push(key);
          }
        });
        Object.keys(analytics).forEach((key) => {
          if (key !== 'Pending' && key !== 'Completed') {
            sum += analytics[key];
          }
        });
        tempData.push({
          name: checked ? key : source ? key : usersList[key],
          uid: key,
          Total: sum,
          ...analytics,
        });
      });
    }
    let teamTempData: any[] = [];
    if (checked) {
      tempData.forEach((user) => {
        const team = teamsData[user.name];
        let existing = teamTempData.filter((item) => item.name === team);
        if (existing.length !== 0) {
          Object.keys(user).forEach((key) => {
            if (key !== 'name') {
              if (existing[0][key]) {
                existing[0][key] += user[key];
              } else {
                existing[0][key] = user[key];
              }
            }
          });
        } else {
          teamTempData.push({ ...user, name: team });
        }
      });
    }
    let gt: { [key: string]: any } = {};
    const finalData = checked ? teamTempData : source ? tempData : tempData;
    finalData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'name') {
          if (gt[key]) {
            gt[key] += item[key];
          } else {
            gt[key] = item[key];
          }
        }
      });
    });
    finalData.sort(sortAnalytics);
    finalData.unshift({ name: 'Grand Total', ...gt });
    if (type !== 'callLogs') {
      tempHeader.forEach((item) => {
        if (item === 'Other') {
          const i = tempHeader.indexOf('Other');
          tempHeader.splice(i, 1);
          tempHeader.push('Other');
        }
      });
    }

    if (type === 'stage') {
      tempHeader.forEach((item) => {
        setHeader([
          'Associate',
          'Total',
          'FRESH',
          'CALLBACK',
          'INTERESTED',
          'WON',
          'NOT INTERESTED',
          'LOST',
        ]);
      });
    } else {
      setHeader(tempHeader);
    }
    setTableData(finalData.filter((item) => item.name !== undefined));
    setSortTableData(finalData.filter((item) => item.name !== undefined));
    setSort(finalData.filter((item) => item.name !== undefined));
  }, [data, checked]);

  const sortFunction = (item: any) => {
    setSortTable(true)
    const grandTotalObj = tableData.filter((i: any) => i.name === "Grand Total");
    let sortData: any;
    switch (item) {
      case item:
         sortData = tableData?.filter((i: any) => i.name !== "Grand Total")?.sort((a:any, b:any) => {
          const valueA = a[item] || 0;
          const valueB = b[item] || 0;
          return valueA - valueB;
         })
        break;
      default:
        break;
    }
    setSort([...grandTotalObj, ...sortData]);
    
  }
  const handleSortTableData=(item:any)=>{
    setSortTable(false)
    const grandTotalObj = tableData.filter((i: any) => i.name === "Grand Total");
    let sortData: any;
    switch (item) {
      case item:
         sortData = tableData?.filter((i: any) => i.name !== "Grand Total")?.sort((a:any, b:any) => {
          const valueA = a[item] || 0;
          const valueB = b[item] || 0;
          return valueB - valueA;
         })
        break;
      default:
        break;
    }
    setSort([...grandTotalObj, ...sortData]);
  }
  useEffect(() => {
    setTableData(sortData);
  }, [sortData])

  
  const dispatcher = useDispatch();
  // when a user clicks on the table data which is not zero, it opens up a drill down page, the api data for which is stored in the local storage and the necessary call is made from that page
  const getDrillDownData = async (key: string, item: any) => {
    if (type === 'callLogs') {
      const drillDownData = await apiCallDrilldown(
        filter,
        item.name === 'Grand Total' ? user.uid || selectOrgId: item.uid,
        key,
        taskFilter,
        leadFilter,
        callFilter,
        item.name === 'Grand Total' ? true : false
      );
      const newDrollDown = {
        ...drillDownData,
        organizationid: user.organization_id || selectOrgId
      }
      localStorage.setItem('callDrilldownData', JSON.stringify(newDrollDown));
      window.open('/callDrilldownData', '_blank');


    } else {
      const typeData = type === 'lost_reason'
        ? 'LOST'
        : type === 'not_int_reason'
          ? 'NOT INTERESTED'
          : 'INTERESTED';
      const drillDownData = await apiContactsDrilldown(
        item.name === 'Grand Total' ? user.uid || selectOrgId: item.uid,
        type === 'propertyStage'
          ? 'property_stage'
          : type === 'propertyType'
            ? 'property_type'
            : type,
        key,
        type === 'call_back_reason'
          ? 'CALLBACK'
          : typeData,
        filter,
        taskFilter,
        leadFilter,
        item.name === 'Grand Total' ? true : false
      );
      if (item.name === 'Grand Total') {
        const newDrollDown = {
          uid: user.uid || selectOrgId,
          leadFilter: drillDownData.leadFilter,
          taskFilter: drillDownData.taskFilter,
          role: drillDownData.role,
          organizationid: user.organization_id,
          source: source
        }
        localStorage.setItem('drilldownData', JSON.stringify(newDrollDown));
        window.open('/drilldownData', '_blank');
      } else {
        const newDrollDown = {
          ...drillDownData,
          organizationid: user.organization_id || selectOrgId,
          source: source
        }
        localStorage.setItem('drilldownData', JSON.stringify(newDrollDown));
        window.open('/drilldownData', '_blank');
      }
    }
  };

  
  const tableTextStyles = { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <>
      {/* <h6 className={styles.heading}>
        {heading}
      </h6> */}
      <div className={styles.detailsBox} style={style} >
        {/* {type==="callLogs" ?<p className={styles.graphHeading}>{heading}</p>:null} */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th
                style={{ width: "auto", textAlign: "center", ...tableTextStyles }}
              >
                S.No
              </th>
              {header.map((item, index) => (
                <>
                  <th
                    style={{textAlign: item === 'Associate' ? 'left' : 'center', ...tableTextStyles }}
                  >
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{properFormat(item)} {item !=="Associate" && ( sortTable ?<ExpandLessIcon style={{ cursor: "pointer" }} onClick={() => handleSortTableData(item)} /> : <> <KeyboardArrowDownIcon style={{ cursor: "pointer" }} onClick={() => sortFunction(item)} /></>)}</div>
                    {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{properFormat(item)}</div> */}
                  </th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <>
                <tr key={index} style={{ backgroundColor: index === 0 ? "#272944" : '' }} >
                  <th style={{ width: "auto", textAlign: "center", ...tableTextStyles }}>{index > 0 ? <>{index}</> : null}</th>
                  <th
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      color: index === 0 ? "white" : theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
                    }}
                  >
                    {properFormat(item.name)}
                  </th>
                  {header.slice(1).map((key: string, sIndex) => (
                    <>
                      <th
                        key={String(index) + String(sIndex)}
                        style={{
                          fontSize: '0.8rem',
                          cursor: item[key] ? 'pointer' : 'not-allowed',
                          textAlign: 'center',
                          fontWeight: index === 0 ? "bold" : 'normal',
                          color: index === 0 ? "white" : theme ? themeColors.textPrimary : themeColors.textPrimaryDark
                        }}
                        // onClick={() => {
                        //   item[key] && getDrillDownData(key, item);
                        // }}
                      >
                        {item[key] ? properFormat(item[key]) : 0}
                      </th>
                    </>
                  ))}
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    user: state.user.data,
    teamLeadUsers: state.teamLeadUsers.data,
    branchUsers: state.branchUsers.data,
    theme: state.theme.isLightMode,
    selectOrgId:state.selectOrganizationId.data
  };
};
export default connect(mapStateToProps)(IntBarTable);
