import React, { useState, useEffect, FunctionComponent } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { APIDATA_COLUMNS,ApiDataColumns } from "../../Values/tables";

import { filterStatus, searchAPIItem } from "../../Values/utils";
import moment from "moment";
import { getDateString, searchContacts } from "../../Values/utils";
import CustomTable from "../../Components/CustomTable/CustomTable";

import ApiModal from "../../Components/Modals/ApiModal/ApiModal";
import { fetchApiData, fetchApiFilterData } from "../../Services/organizations";
import NewImportProjectModal from "../../Components/Modals/ImportModal/NewImportProjectModal";
import ApiTopBar from "../../Components/TopBar/ApiTopBar";
import NewImportApiModal from "../../Components/Modals/ImportModal/NewImportApiModal";
import ApiCustomTable from "../../Components/CustomTable/ApiCustomTable";
import styles from '../Analytics/Analytics.module.css';
import {themeColors} from "../../Components/theme";
import Loading from "../../Components/Loading/Loading";


type props = {
  history: any;
  organizationId: any;
  user: any;
  apiHeaders:any;
  theme: any;
};

const ApiDataPanel: FunctionComponent<props> = ({
  history,
  organizationId,
  user,
  apiHeaders,
  theme
}) => {
  const dispatcher = useDispatch();
  const [searchQuery, setsearchQuery] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [apiModal, setApiModal] = useState(false);
  const [API, setAPI] = useState<any[] | undefined>(undefined);
  const [status, setStatus] = useState("ALL");
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [apiFilter, setApiFilter] = useState("7");
  const [showImportModal, setShowImportModal] = useState(false);
  const [searchedItem, setsearchedItem] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [lastPage, setLastPage] = useState(-1);

  useEffect(() => {
    if (user.organization_id) {
      if (apiFilter === "7") {
        let prevDate = moment().subtract(7, "day").toDate();
        let currentDate = moment().toDate();
        fetchApiFilterData(
          user.organization_id,
          (val) => setAPI(val),
          prevDate,
          currentDate
        );
      } else if (apiFilter === "30") {
        let prevDate = moment().subtract(30, "day").toDate();
        let currentDate = moment().toDate();
        fetchApiFilterData(
          user.organization_id,
          (val) => setAPI(val),
          prevDate,
          currentDate
        );
      } else {
        fetchApiData(user.organization_id, (val) => setAPI(val));
      }
    }
  }, [user, apiFilter]);

  useEffect(() => {
    if (API) {
      let data: any[] = [];
      API.forEach((item) => {
        data.push(item);
      });
      setFilterData(data);
    }
  }, [API]);

  useEffect(() => {
    if (status === "ALL") {
      if (API) {
        const data = searchAPIItem(API, searchQuery);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchAPIItem(temporaryData, searchQuery);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchQuery, API]);

  useEffect(() => {
    if (searchQuery === "") {
      if (API) {
        const data = filterStatus(API, status);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, API]);

  let isFinished = false;
  const exportFile = () => {
    interface DataObj {
      label: any;
      value: any;
    }
    
    // const dbData=[{"label": "budget","value": "Budget"},{"label": "created_by","value": "Created By"},{"label": "property_stage","value": "Property Stage"},{"label": "location","value": "Location"},{"label": "customer_name","value": "Customer Name"},{"label": "contact_no","value": "Contact No"},{"label": "alternate_no","value": "Alternate No"},{"label": "country_code","value": "Country Code"},{"label": "associate_contact","value": "Associate Contact"},{"label": "email","value": "Email"},{"label": "campaign","value": "Campaign"},{"label": "add_set","value": "Add Set"},{"label": "contact_owner_email","value": "Owner"},{"label": "status","value": "Status"},{"label": "created_at","value": "Created At"},{"label": "lead_assign_time","value": "Lead Assign Time"},{"label": "lead_id","value": "Lead Id"},{"label": "property_type","value": "Property Type"},{"label": "stage","value": "Stage"},{"label": "fail_reason","value": "Fail Reason"},{"label": "project","value": "Project"},{"label": "lead_source","value": "Lead Source"}];
    const dbData=apiHeaders;
    const apiHeaderBudget :any = dbData.find((obj: DataObj) => obj.label === "budget")?.value;
    const apiHeaderCreatedBy :any = dbData.find((obj: DataObj) => obj.label === "created_by")?.value;
    const apiHeaderPropertyStage :any = dbData.find((obj: DataObj) => obj.label === "property_stage")?.value;
    const apiHeaderLocation :any = dbData.find((obj: DataObj) => obj.label === "location")?.value;
    const apiHeaderCustomerName :any = dbData.find((obj: DataObj) => obj.label === "customer_name")?.value;
    const apiHeaderContactNo :any = dbData.find((obj: DataObj) => obj.label === "contact_no")?.value;
    const apiHeaderAlternateNo :any = dbData.find((obj: DataObj) => obj.label === "alternate_no")?.value;
    const apiHeaderCountryCode :any = dbData.find((obj: DataObj) => obj.label === "country_code")?.value;
    const apiHeaderAssociateContact :any = dbData.find((obj: DataObj) => obj.label === "associate_contact")?.value;
    const apiHeaderEmail :any = dbData.find((obj: DataObj) => obj.label === "email")?.value;
    const apiHeaderCampaign :any = dbData.find((obj: DataObj) => obj.label === "campaign")?.value;
    const apiHeaderAddSet :any = dbData.find((obj: DataObj) => obj.label === "add_set")?.value;
    const apiHeaderOwner :any = dbData.find((obj: DataObj) => obj.label === "contact_owner_email")?.value;
    const apiHeaderStatus :any = dbData.find((obj: DataObj) => obj.label === "status")?.value;
    const apiHeaderCreatedAt :any = dbData.find((obj: DataObj) => obj.label === "created_at")?.value;
    const apiHeaderLeadAssignTime :any = dbData.find((obj: DataObj) => obj.label === "lead_assign_time")?.value;
    const apiHeaderLeadId :any = dbData.find((obj: DataObj) => obj.label === "lead_id")?.value;
    const apiHeaderPropertyType :any = dbData.find((obj: DataObj) => obj.label === "property_type")?.value;
    const apiHeaderStage :any = dbData.find((obj: DataObj) => obj.label === "stage")?.value;
    const apiHeaderFailReason :any = dbData.find((obj: DataObj) => obj.label === "fail_reason")?.value;
    const apiHeaderProject :any = dbData.find((obj: DataObj) => obj.label === "project")?.value;
    const apiHeaderLeadSource :any = dbData.find((obj: DataObj) => obj.label === "lead_source")?.value;
    let data: any[] = [];
    if (selectedRowsData.length !== 0) {
      selectedRowsData.forEach((item) => {
        data.push({
          [apiHeaderBudget]: item.budget,
          [apiHeaderCreatedBy]: item.created_by,
          [apiHeaderPropertyStage]: item.property_stage,
          [apiHeaderLocation]: item.location,
          [apiHeaderCustomerName]: item.customer_name,
          [apiHeaderContactNo]: item.contact_no,
          [apiHeaderAlternateNo]: item.alternate_no,
          [apiHeaderCountryCode]: item.country_code,
          [apiHeaderAssociateContact]: item.associate_contact,
          [apiHeaderEmail]: item.email,
          [apiHeaderCampaign]: item.campaign,
          [apiHeaderAddSet]: item.add_set,
          [apiHeaderOwner]: item.contact_owner_email,
          [apiHeaderStatus]: item.status,
          [apiHeaderCreatedAt]: getDateString(item.created_at),
          [apiHeaderLeadAssignTime]: getDateString(item.lead_assign_time),
          [apiHeaderLeadId]: item.lead_id,
          [apiHeaderPropertyType]: item.property_type,
          [apiHeaderStage]: item.stage,
          [apiHeaderFailReason]: item.fail_reason,
          [apiHeaderProject]: item.project,
          [apiHeaderLeadSource]: item.lead_source,
        });
      });
    }
    return data;
  };

  const containerStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}

  return (
    <>
     <div className={styles.parent} style={{ display: "block", marginTop: "4%",...containerStyles}}>
     
      <div className={commonStyle.topBar} style={{ marginTop: "0px",...containerStyles }}> 
      {API === undefined  && <Loading />}
        <Topbar
          history={history}
          title={"Add Api Data"}
          path={"/addUsers"}
          onChange={(val) => setsearchQuery(val)}
          filterText="Status"
          setColumnModal={(data) => setColumnModal(data)}
          showStatusBox={true}
          setApiModal={(data) => setApiModal(data)}
          setStatus={(status) => setStatus(status)}
          status={status}
          setApiFilter={(data) => setApiFilter(data)}
          apiFilterData={apiFilter}
          onClick={() => {
            setShowImportModal(true);
          }}
          show={true}
          onExport={exportFile}
        />
      </div>
      <div className={commonStyle.parent} style={{minHeight:"450px",...containerStyles}}>
        <CustomTable
          tableColumns={ApiDataColumns(apiHeaders)}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="API"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setSearchedItem={(val) => setsearchQuery(val)}
        />
      </div>
      {showImportModal && (
        <NewImportApiModal
          open={showImportModal}
          close={() => setShowImportModal(false)}
          organization_id={user.organization_id}
          history={history}
          usersList={organizationId}
          user={user}
        />
      )}
      {apiModal === true && (
        <ApiModal open={apiModal} close={() => setApiModal(false)} />
      )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    user: state.user.data,
    apiHeaders: state.apiHeaderList.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(ApiDataPanel);
