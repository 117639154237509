import React, { FunctionComponent } from "react";
import styles from "./Loading.module.css";
import { connect } from "react-redux";

type props = {
  logo?: boolean;
  progress: any;
  theme:any;
  role:any;
  subtext?: boolean;
};

const Loading: FunctionComponent<props> = ({ logo, progress,theme,role,subtext }) => {
  return (
    <div className={styles.parent} style={{ backgroundColor: theme ? "#F5F5F5":"#000000e1",
    width:"100vw",
    height:"100%",
    marginTop: "4rem",
    zIndex: 10000}}
    >
    {/* <ReactLoading
        type={"spinningBubbles"}
        color={"#272944"}
        height={90}
        width={90}
        className={styles.loader}
      /> */}
       {/* <div className={styles.spinnerContainer}> */}
        <div className={styles.spinner} style={{marginBottom:"6%",marginRight:"13%"}}>
          <div className={styles.innerSpinner}>
          </div>
        </div>
        {/* <img className={styles.logo} src="https://i.stack.imgur.com/pC1Tv.jpg" alt="" /> */}
      {/* </div> */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    progress: state.progress,
    theme: state.theme.isLightMode,
    role: state.user.role,
  };
};

export default connect(mapStateToProps)(Loading);
