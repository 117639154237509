import React from "react";
import { firestore, functions, db, storage } from "../../Firebase";
import Firebase from "firebase/app";
import moment from "moment";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const getDate = (date: Date) => {
  const utcDate = moment.utc(date);
  return utcDate.utcOffset("+05:30").format("DD-MM-YYYY");
};

const yearDocName = (type: "tasks" | "calllogs", uid: string, date: Date) => {
  const utcDate = moment.utc(date);
  const localDate = moment(utcDate).local();
  const yearStart = Math.floor(localDate.year() / 10) * 10;
  const yearEnd = yearStart + 10;
  return `${type}${uid}${yearStart}${yearEnd}`;
};
const mapSeconds = (time: string) => {
  var a = time.split(":");
  var seconds = 0;
  if (a.length === 3) {
    seconds = 3600 * Number(a[0]) + 60 * Number(a[1]) + Number(a[2]);
  } else {
    seconds = 60 * Number(a[1]) + Number(a[1]);
  }
  if (seconds < 10) {
    return ">0";
  } else if (seconds < 30) {
    return ">10";
  } else if (seconds < 60) {
    return ">30";
  } else {
    return ">60";
  }
};

const Reports = ({ props, user }: any) => {
  const cresteUsersList = async () => {};

  return (
    <div>
      <p>Reports</p>
      <button onClick={cresteUsersList}>Back</button>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(Reports);
