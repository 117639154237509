import React, { useEffect, useState } from "react";
import styles from "./TextInput.module.css";

type props = {
  title: string;
  children?: any;
  style?: any;
  validator?: (value: string) => string;
  length?: boolean;
  disabled?: boolean;
  value?: string;
  phoneCode?: any;
  mobileType?: boolean;
};

const TextInput = React.forwardRef(
  (
    { title, children, style, validator, length, disabled, value, phoneCode, mobileType }: props,
    ref: any
  ) => {
    const [errorMsg, setErrorMsg] = useState("");
    const [val, setVal] = useState("");

    useEffect(() => {
      if (value) setVal(value);
    }, [value]);

    return (
      <>
         <div
            className={styles.input}
            style={{ paddingLeft: style?.paddingLeft, width: style?.width }}
          >
            <input
              style={{ fontSize: "14px" }}
              className="form-control"
              type="text"
              name="name"
              placeholder={title}
              ref={ref}
              onBlur={(event) => {
                if (validator) {
                  setErrorMsg(validator(ref.current.value));
                }
              }}
              autoComplete="nope"
              maxLength={length === true ? 10 : undefined}
              readOnly={disabled}
              onChange={(e) => setVal(e.target.value)}
              value={val}
            />
            {errorMsg.length !== 0 && <p className={styles.error}>{errorMsg}</p>}
          </div>
      </>
    );
  }
);

export default TextInput;
