import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PreviewVideo(props:any) {
  const classes = useStyles();
  return (
    <div 
       className={classes.modal}
      >
        <ReactPlayer
          className="VideoInput_video"
          width="70%"
          height={400}
          controls
          url={props.source}
        />
      </div>
  );
}