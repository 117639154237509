import React, { FunctionComponent, useState, useRef } from "react";
import styles from "../ListViewModal/ListViewModal.module.css";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";

import Loading from "../../Loading/Loading";

import {createNewsSuperAdmin } from "../../../Services/organizations";
import { showSnackbarAction } from "../../../Redux/actions";
import TextInput from "../../TextInput/TextInput";
import { themeColors } from "../../theme";

type props = {
  open: boolean;
  close: () => void;
  organizationId: any;
  organizationUsers: any;
  theme?:any
};

const NewsModal: FunctionComponent<props> = ({
  organizationId,
  open,
  close,
  theme
}) => {
  const areaRef: any = useRef();
  const linkRef: any = useRef();
  const [load, setLoad] = useState(false);
  const dispatcher = useDispatch();
  const onSave = () => {
    if (areaRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Area Name!!", "error"));
    } else if (linkRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter News Link!!", "error"));
    } else {
      setLoad(true);
      createNewsSuperAdmin(
        areaRef.current.value,
        linkRef.current.value,
        dispatcher,
        (val) => setLoad(val),
        close
      );
    }
  };
  const modalStyle = {
    content: {
      backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark,
      color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
      // Add any other styles you want to apply to the modal content here
    },
  };
  return (
    <Modal
      isOpen={open}
      className={styles.parent}
      overlayClassName={styles.overlay}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      style={modalStyle}
    >
      {load && <Loading />}
      <div className={styles.headingContainer}>
        <p className={styles.heading}  style={{color:theme?"#272944":"white"}}>Create News Link</p>
      </div>
      <div className={styles.profileContainer}>
        <p className={styles.profile}>Enter Area Name</p>
        <TextInput title={"Enter Area Name"} ref={areaRef} disabled={false} />
        <p className={styles.profile} style={{ marginTop: "25px" }}>
          Enter Link
        </p>
        <TextInput title={"Enter Link"} ref={linkRef} disabled={false} />
      </div>

      <div className={styles.buttonView}>
        <button className={styles.cancelButton} onClick={close}>
          Cancel
        </button>
        <button className={styles.saveButton} onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(NewsModal);
