import React, { useEffect, useState } from "react";
import styles from "./SubscriptionDetails.module.css";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { numberWithCommas } from "../../Services/booking";
import { showSnackbarAction } from "../../Redux/actions";
import Loading from "../../Components/Loading/Loading";

const SubscriptionDetails = ({ theme, validTill, organizationData }: any) => {
  const dispatcher = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  let totalLicenses = organizationData && organizationData.no_of_employees;
  let costPerLicense = organizationData && organizationData.cost_per_license;
  let totalAmount = totalLicenses * costPerLicense;
  const history = useHistory();
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const tdStyles = {
    backgroundColor: theme
      ? themeColors.tdBackgroundColor
      : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
    // textAlign: "center"
  };
  const trBorderStyles = {
    borderBottomColor: theme
      ? `1px solid ${themeColors.trBorderColor}`
      : `1px solid ${themeColors.trBorderColorDark}`,
  };
  const thStyles = {
    backgroundColor: theme
      ? themeColors.thBackgroundColor
      : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
  };
  const handleUpgradeUser = () => {
    if (organizationData.trial_period === true) {
      dispatcher(showSnackbarAction("You are in a trial period. Please renew first to upgrade your access.", "error"));
    }
    else {
      history.push("/upgradeUsers");
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.parent} style={{ ...containerStyles, marginTop: "4%" }}>
      {showLoader && <Loading/>}
      <div className={styles.container}>
        <div
          className="card p-4 shadow-sm"
          style={{
            borderBottomLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            ...cardContainerStyles,
          }}
        >
          <h6>
            {" "}
            <i className="fas fa-info-circle"></i> Your account has been
            associated with the partner, LEADS RUBIX
            {/* <a href="https://www.itsforyou.live/" target="_blank">Know More</a> */}
          </h6>
          <h6>
            {" "}
            <i className="fas fa-info-circle"></i> Your Renewal Date is on <b>{validTill}</b> , To Enjoy Uninterrupted Services, Please renew your
            subscription.
          </h6>
          <h6>
            {" "}
            <i className="fas fa-info-circle"></i> Please go through this link to check our Terms and Conditions
            {/* <a href="https://merchant.razorpay.com/policy/KjQKaoFmBuFUJA/terms" target="_blank">Know More</a> */}
          </h6>
          <button
            className={styles.button}
            onClick={() => {
              history.push("/paymentDetails");
            }}
            style={{ margin: "auto" }}
          >
            Renew Now
          </button>
        </div>
        {/* <h6 className="mx-auto mt-4">Subscription ID: RPCW277000118297318</h6> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="mt-4" style={{ width: "60%" }}>
            <h5 className="fw-bold">Subscription Details</h5>
            <div className="w-100">
              <table
                className="table shadow rounded bg-white"
                style={{ backgroundColor: "red" }}
              >
                <thead className="bg-light">
                  <tr>
                    <th style={thStyles}>Item</th>
                    <th style={thStyles}>Units</th>
                    <th style={thStyles}>Cost Per License  (In INR)</th>
                    <th style={thStyles}>Amount (In INR)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-bottom">
                    <td style={tdStyles}>
                      <p className="fw-bold mb-1">Leads Rubix Licenses</p>
                    </td>
                    <td style={tdStyles}>
                      <p className="fw-normal mb-1">{totalLicenses}</p>
                    </td>
                    <td style={tdStyles}>
                      <p className="fw-normal mb-1">{numberWithCommas(costPerLicense)}</p>
                    </td>
                    <td style={tdStyles}>
                      <p className="fw-normal mb-1">{numberWithCommas(totalAmount)} (Exl. Tax)</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-4" style={{ width: "35%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h6 className="fw-bold">Next Payment</h6>
              {/* <h6
                className={styles.historyLink}
                onClick={() => {
                  history.push("/transactionHistory");
                }}
              >
                Payment History
              </h6> */}
            </div>
            <div
              className="card p-4 shadow d-flex flex-row justify-content-between"
              style={cardContainerStyles}
            >
              <div>
                <h6 className="fw-bold">{validTill}</h6>
                <h6>(Monthly)</h6>
              </div>
              <div>
                <h4> ₹ {numberWithCommas(totalAmount)}</h4>
                <p className="text-end">*Excluding Tax</p>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end mt-3">
              <button
                className={styles.button}
                onClick={() =>
                  handleUpgradeUser()
                }
              >
                Upgrade User/ Add-Ons
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card px-4 pt-2 w-100 d-flex flex-row justify-content-between position-absolute bottom-0 end-0"
        style={cardContainerStyles}
      >
        <div>
          {/* <h6>accounts@itsforyou.live</h6> */}
          <p>(For payments and billing questions)</p>
        </div>
        <div>
          {/* <h6>8755964699</h6> */}
          <p>(Toll Free 24 hours - Monday to Friday)</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
    validTill: state.organization.validTill,
    organizationData: state.organization.organizationData,
  };
};

export default connect(mapStateToProps)(SubscriptionDetails);
