import Modal from "react-modal";
import React, { FunctionComponent, useState } from "react";
import styles from "./AddModal.module.css";
import { IoIosClose } from "react-icons/io";
import { connect } from "react-redux";
import { themeColors } from "../../theme";

Modal.setAppElement("#root");

type props = {
  show: boolean;
  heading: string;
  subHeading: string;
  close: () => void;
  onSave: (v: any) => void;
  theme?:any
};

const AddModal: FunctionComponent<props> = ({
  show,
  heading,
  subHeading,
  close,
  onSave,
  theme
}) => {
  const [value, setValue] = useState("");
  const modalStyle = {
    content: {
      backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark,
      color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
      // Add any other styles you want to apply to the modal content here
      margin:"200px",
      width:"45%"
    },
  };
  return (
    <Modal
      isOpen={show}
      className={styles.parent}
      overlayClassName={styles.overlay}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      style={modalStyle}
    >
      <div className={styles.icon}   onClick={close}>
        <IoIosClose size={35} style={{color:theme?"#272944":"white"}} />
      </div>
      <span className={styles.heading}  style={{color:theme?"#272944":"white"}}>{heading}</span>
      <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <div className={styles.nameBox}>
        <span className={styles.name}>{`${subHeading} : `}</span>
        <input
          className={styles.input}
          placeholder={`${subHeading}`}
          onChange={(event) => setValue(event.target.value)}
        />
      </div>
      <button className={styles.button} style={{marginLeft:'10px'}} onClick={() => onSave(value)}>
        Save
      </button>
      </div>
    </Modal>
  );
};
const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(AddModal);
// export default AddModal;
