import React, { useRef, FunctionComponent, useState, useEffect } from "react";
import styles from "./AddHeaders.module.css";
import TextInput from "../../Components/TextInput/TextInput";

import { createOrganization } from "../../Services/organizations";

import { emailValidate, phoneValidate } from "../../Values/validatorsnew";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { countryData } from "../../Values/constants";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { url } from "../../Values/constants";
import { showSnackbarAction } from "../../Redux/actions";

type props = {
    history: any;
    theme: any;
    user:any;
};
const AddHeaders: FunctionComponent<props> = ({ history, theme,user}) => {
    const dispatcher = useDispatch();
    const [load, setLoad] = useState(false);
    const [state, setState] = useState("");
    const [selectedScreen, setSelectedScreen] = useState("");
    const [selectedIndustries, setSelectedIndustries] = useState("");
    const [visible, setVisible] = useState(false);

    const [country, setCountry] = useState("India");
    const [email, setEmail] = useState("");
    const organizationNameRef: any = useRef();
    const mobileNumberRef: any = useRef();
    const emailRef: any = useRef();
    const cityRef: any = useRef();
    const addressRef: any = useRef();
    const pincodeRef: any = useRef();
    const adminFirstNameRef: any = useRef();
    const adminLastNameRef: any = useRef();
    const adminContactNumberRef: any = useRef();
    const adminEmailRef: any = useRef();
    const employeesRef: any = useRef();
    // const [allIndustries, setallIndustries] = useState([]);
    const [allIndustries, setallIndustries] = useState<{ industry_id: string,status:string,industry_name:string, screens: string[] }[]>([]);
    const [inputValues, setInputValues] = useState<string[]>([]);
    const [defaultHeaders, setdefaultHeaders] = useState<MyObject[]>([]);
    const [selectScreen, setSelectScreen] = useState<string[]>([]);
    console.log("ajit kumar console:",selectScreen);


    type MyObject = {
        label: string;
        value: string;
        is_mandatory: string;
        hide_field: string;
    };

    interface MyValueObject {
        index: number;
        value: string;
        label: string;
      }

      const myArray: MyObject[] =defaultHeaders;

    const handleInputChange = (index: number, value: string, label: string) => {
        setInputValues(prevValues => {
          const newValues = [...prevValues];
          newValues[index] = value;
          return newValues;
        });
      };
    // let feildValueStore = "";
    // const handleInputChange = (index: number, value: string, lable : string) => {
    //     feildValueStore +=value;
    //     // setInputValue(feildValueStore);
    //     console.log("Index number:",index);
    //     console.log("Index value:",feildValueStore);
    //     console.log("Index lable:",lable);
    //     // setInputValues((prevValues) => {
    //     //   const newValues:any = [...prevValues];
    //     //   newValues[index] = { value };
    //     //   return newValues;
    //     // });
    //   };


    const renderBatch = (batch: MyObject[]) => {
        return batch.map((obj,index) => (
            <>
            {obj.hide_field==="false" ? (
            <div style={{ padding: '5px',display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexWrap:"wrap",width:"18%",height:'auto' }}>
                {/* {obj.is_mandatory === "true" ? <p style={{ color: "red", marginBottom: "0", fontSize: "15px", marginRight: "5px", flexShrink: 0 }}>*</p>:<p style={{ color: "red", marginBottom: "0", fontSize: "15px", marginRight: "5px", flexShrink: 0 }}></p>} */}
                {/* <p style={{color:"red",marginBottom:"0",fontSize:"25px",marginLeft:"90%"}}>*</p> */}
                <input
                    type={obj.label}
                    placeholder={obj.value}
                    id={obj.label}
                    value={inputValues[index] || ""}
                    onChange={(e) => handleInputChange(index, e.target.value,obj.label)}
                    style={{
                        height:30,
                        width:180,
                        borderRadius:5,
                        flexWrap:"wrap",
                        border:obj.is_mandatory === "true" ?"2px solid red":""
                    }}
                />
                {/* <TextInput
                key={obj.label}
                title={obj.value}
                style={{ backgroundColor: "#fff"}}
                ref={adminLastNameRef}
            /> */}
            </div>
            ) : (
                <></>
            )}
            </>
        ));
    };

    const batches: MyObject[][] = [];
    const batchSize = 10;
    for (let i = 0; i < myArray.length; i += batchSize) {
        batches.push(myArray.slice(i, i + batchSize));
    }


    const handleScreenChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedScreen(event.target.value);
    };

    const handleIndustriesChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedIndustries(event.target.value);
    };

    useEffect(() => {
        getAllIndustries();
        // window.onbeforeunload = function () {
        //     return "Are you really want to perform the action?";
        // };
    }, []);

    useEffect(() => {
        if(selectedIndustries != ""){
            const selectScreen = allIndustries.filter((industry: any) => industry.industry_id=== selectedIndustries);
            setSelectScreen(selectScreen[0]?.screens);
        }
    }, [selectedIndustries]);

    useEffect(() => {
        if (selectedScreen !== "" && selectedIndustries !== "") {
            if(selectedScreen === "DefaultContacts"){
               const result =  checkScreens(selectedIndustries,setVisible);
               console.log("Result Value Printed :",result);
               if(!result){
                setVisible(true);
                getDefaultHeaders(selectedIndustries,selectedScreen);
               }
            }else{
                setVisible(true);
                getDefaultHeaders(selectedIndustries,selectedScreen);
            }
        }
    }, [selectedScreen, selectedIndustries]);

    const create = async (e: any) => {
        let screen = "";
        e.preventDefault();
        setLoad(true);
       
        const updatedArray = myArray.map((obj, index) => ({
            ...obj,
            value: inputValues[index] !== undefined ? inputValues[index] : obj.value,
          }));
          if(selectedScreen == "DefaultContacts"){
            screen = "Contacts";
          }else if(selectedScreen == "DefaultProjects"){
            screen = "Projects";
          }else if(selectedScreen == "DefaultTasks"){
            screen = "Tasks";
          }else if(selectedScreen == "DefaultApi"){
            screen = "Api";
          }else if(selectedScreen == "DefaultCallLogs"){
            screen = "CallLogs";
          }else{
            screen = "Default";
          }
          const getSingleInd = allIndustries.filter((obj: any) => obj.industry_id === selectedIndustries);
          const apiData = {
            industry_id:selectedIndustries,
            industry_name: getSingleInd[0]['industry_name'],
            created_by: user.user_email,
            headers:updatedArray,
            screen_name: screen
          };
          try {
            // axios.defaults.headers.common["x-access-token"] =
            //   authToken
            // const res = await axios.post("http://localhost:4001/headers/insert", apiData);
            const res = await axios.post(url+'/headers/insert', apiData);
            // let data: any[] = res.data;
            setInputValues([]);
            if(res.status==200){
                if(res.data==="Headers already exist"){
                    dispatcher(showSnackbarAction(res.data, "error"));
                }
                else{
                    dispatcher(showSnackbarAction(res.data, "success"));
                }
                history.replace("/headers")
                setLoad(false);
            }else{
                dispatcher(showSnackbarAction(res.data, "error"));
                setLoad(false);
            }
            // dispatcher(showSnackbarAction("", "success"));
            setLoad(false);
          } catch (e) {
            setLoad(false);
          }
        //   setLoad(false);
        // createOrganization(
        //     organizationNameRef.current?.value,
        //     mobileNumberRef.current?.value,
        //     emailRef.current?.value,
        //     addressRef.current?.value,
        //     country,
        //     state,
        //     cityRef.current?.value,
        //     pincodeRef.current?.value,
        //     adminFirstNameRef.current?.value,
        //     adminLastNameRef.current?.value,
        //     adminContactNumberRef.current?.value,
        //     adminEmailRef.current?.value,
        //     employeesRef.current?.value,
        //     history,
        //     dispatcher,
        //     window,
        //     (data: boolean) => setLoad(data)
        // );
    };

    const getDefaultHeaders = async (industry:any,screen:any) => {
        setLoad(true);
          const apiData = {
            industry_id:"ind001",
            screen_name: screen
          };
          try {
            // axios.defaults.headers.common["x-access-token"] =
            //   authToken
            // const res = await axios.post("http://localhost:4001/headers/getHeaders", apiData);
            const res = await axios.post(url+'/headers/getHeaders', apiData);
            setdefaultHeaders(res?.data?.headers);
            // let data: any[] = res.data;
            setLoad(false);
          } catch (e) {
            setLoad(false);
          }
    };

    const checkScreens = (industry_id:any,setVisible:any) => {
        const singleIndustryData = allIndustries.find(item => item.industry_id === industry_id);
        if(singleIndustryData){
            if(!singleIndustryData.screens.includes("Projects") || !singleIndustryData.screens.includes("Tasks") ){
                setVisible(false);
                dispatcher(showSnackbarAction("Please create Projects and Tasks screen headers before creating Contacts !", "error"));
                return true;
            }else{
                return false;
            }
        }
    };

    const getAllIndustries = async () => {
        setLoad(true);
          const apiData = {
            status: "Pending"
          };
          try {
            // axios.defaults.headers.common["x-access-token"] =
            //   authToken
            // const res = await axios.post("http://localhost:4001/headers/getAllIndustries",apiData);
            const res = await axios.post(url+'/headers/getAllIndustries', apiData);
            setallIndustries(res.data);
            // const selectScreen = res.data.filter((industry: any) => industry.industry_id=== "temp006");
            // setSelectScreen(selectScreen[0].screens);
            setLoad(false);
          } catch (e) {
            console.log("Provide Exception By get all industries :",e);
            setLoad(false);
          }
    };

    const choice = () => {
        let data = countryData[country].map((item: any) => ({
            value: item,
            label: item,
        }));

        return data;
    };

    const countryChoice = () => {
        let data = Object.keys(countryData).map((item: any, index: number) => ({
            value: item,
            label: item,
        }));

        return data;
    };
    const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const selectInputContainerStyles = { width: "140px", marginRight: "5px" };
    return (

        <div className={styles.parent} style={{...containerStyles,marginTop:"4%"}}>
            {load === true && <Loading />}
            <div
                // className={styles.headerView}
                className="d-flex flex-row w-100 px-4 pt-4"
            >
                <h5
                // className={styles.heading}
                > Add Headers Details</h5>
                <AiOutlineClose
                    className={styles.closeIcon}
                    size={25}
                    onClick={() => history.replace("/headers")}
                />
            </div>
            <div
                // className={styles.headerView}
                className="d-flex flex-row w-100 px-4 pt-4"
            >
                <p>(**Before creating and launching the organization, create headers for all five screens to establish a cohesive and consistent visual identity)</p>
            </div>
            {/* (**Before creating and launching the organization, create headers for all five screens to establish a cohesive and consistent visual identity) */}
            <div
                // className={styles.child}
                className="w-100 d-flex flex-column gap-2 px-4"
            >
                <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>

                    <div className={styles.box2}>

                        <div className={styles.divide}>
                            <Label label="Select Industries" required={true} />
                            <div className={styles.inputContainer}>
                                <select id="dropdown"
                                 value={selectedIndustries}
                                 onChange={handleIndustriesChange}
                                 style={{
                                    // Add your inline CSS styles here
                                    backgroundColor: '#f0f0f0',
                                    color: '#333',
                                    fontSize: '16px',
                                    padding: '10px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    width: '200px',
                                    // Add any other styles you want
                                  }}>
                                    <option value="">-- Please select an Industries --</option>
                                    {allIndustries?.map((item: any) => {
                                        return <option value={item.industry_id}>{item.industry_name}</option>
                                    })}
                                    {/* <option value="ind003">Service</option>
                                    <option value="ind002">Insurance</option>
                                    <option value="ind004">Real State</option> */}
                                </select>
                                {/* <TextInput
                                    title={"Enter Last Name"}
                                    style={{ backgroundColor: "#fff" }}
                                    ref={adminLastNameRef}
                                /> */}
                            </div>
                        </div>
                        <div className={styles.divide} style={{marginLeft:"200px"}}>
                            <Label label="Select Screen" required={true} />
                            <div className={styles.inputContainer}>
                                <select 
                                    id="dropdown" 
                                    value={selectedScreen} 
                                    onChange={handleScreenChange}
                                    style={{
                                        // Add your inline CSS styles here
                                        backgroundColor: '#f0f0f0',
                                        color: '#333',
                                        fontSize: '16px',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        width: '200px',
                                        // Add any other styles you want
                                      }}>
                                <option value="">-- Please select a Screen --</option>
                                    {
                                        selectScreen.includes("Projects") ? null : <option value="DefaultProjects">Projects</option>
                                    }
                                    {selectScreen.includes("Tasks") ? null : <option value="DefaultTasks">Tasks</option>}
                                    {
                                        selectScreen.includes("Contacts") ? null : <option value="DefaultContacts">Contacts</option>
                                    } 
                                    {/* <option value="DefaultContacts">Contacts</option> */}
                                    
                                
                                    {/* {selectScreen.includes("Api") ? null : <option value="DefaultApi">Api</option>} */}
                                    
                                    {/* {selectScreen.includes("CallLogs") ? null : <option value="DefaultCallLogs">CallLogs</option>
                                    } */}
                                    {/* <option value="">-- Please select a Screen --</option>
                                    <option value="DefaultContacts">Contacts</option>
                                    <option value="DefaultProjects">Projects</option>
                                    <option value="DefaultTasks">Tasks</option>
                                    <option value="DefaultApi">Api</option>
                                    <option value="DefaultCallLogs">CallLogs</option> */}
                                </select>
                            </div>
                        </div>

                        {/* <div className={styles.divide}>
                            <div className={styles.inputContainer}>
                            
                            </div>
                        </div> */}
                    </div>

                    {/* <button className={styles.button} onClick={(e: any) => create(e)}>
                        Submit
                    </button> */}
                </form>
            </div>

            {visible && <div
                // className={styles.child}
                className="w-100 d-flex flex-column gap-2 px-4"
            >
                <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>
                    <div className={styles.box2}>
                                {renderBatch(myArray)}
                    </div>

                    <button className={styles.button}   style={{backgroundColor:theme?"#272944":"rgb(54, 56, 83)"}} onClick={(e: any) => create(e)}>
                        Submit
                    </button>
                </form>
            </div>}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.theme.isLightMode,
        user: state.user.data
    };
};

export default connect(mapStateToProps)(AddHeaders);
