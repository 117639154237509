import React, { useEffect, useState} from "react";
import "./SideNav.css";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { IoIosClose, IoMdArrowDropleftCircle, IoMdArrowDroprightCircle } from "react-icons/io";
import UserDetails from "../UserDetails/LogOut";
import { FaChevronDown, FaChevronRight, FaChevronUp } from 'react-icons/fa';
import { setAnalyticsPanel } from "../../Redux/actions";
import { useDispatch } from "react-redux";

// import {themeColors} from "../../Components/theme";

const SideNav = (props) => {
  const dispatcher = useDispatch();
  const history=useHistory();
  const [open, setOpen] = useState(true);
  const [openModel, setOpenModel] = useState(false);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [leadDistributorOpen, setLeadDistributorOpen] = useState(false);
  const [userPanel, setUserPanel] = useState('Contacts');

  useEffect(()=>{
   if(history.location.pathname==="/analytics"){
    setAnalyticsOpen(true);
    }
  if(history.location.pathname==="/leadDistributor" || history.location.pathname === "/leadDistributorLogic"){
    setLeadDistributorOpen(true);
    }
  },[history.location.pathname])
  
  const leadMenu = [
    {
      text: "Analytics",
      icon: "icons/analytics-svgrepo-com.svg",
      link: "/analytics",
      subMenu: [
        {
          text: "Contacts Analytics",
          // link: "/analytics",
          icon: "icons/data-analytics.png",
          panel:"Contacts"
        },
        {
          text: "Tasks Analytics",
          icon: "icons/data-analytics.png",
           panel:"Tasks"
        },
        {
          text: "Call Logs Analytics",
          icon: "icons/data-analytics.png",
           panel:"Call Logs"
        }

      ]
    },
    {
      text: "Contacts",
      icon: "icons/contacts-svgrepo-com.svg",
      link: "/",
    },
    // {
    //   text: "Projects",
    //   icon: "icons/buildings-svgrepo-com.svg",
    //   link: "/projects",
    // },
    // {
    //   text: "Resources",
    //   icon: "icons/earth-svgrepo-com.svg",
    //   link: "/resources",
    // },
    // {
    //   text: "Lead Distribution",
    //   icon: "icons/people-svgrepo-com.svg",
    //   link: "/leadDistributor",
    // },
    {
      text: "Bookings",
      icon: "icons/book-svgrepo-com.svg",
      link: "/booking",
    },
    {
      text: "Call Logs",
      icon: "icons/call-svgrepo-com.svg",
      link: "/callLogs",
    },
    {
      text: "Tasks",
      icon: "icons/tasks-list-svgrepo-com.svg",
      link: "/tasks",
    },
    // {
    //   text: "API",
    //   icon: "icons/cloud-upload-svgrepo-com.svg",
    //   link: "/apiData",
    // },
    {
      text: "News",
      icon: "icons/news-svgrepo-com.svg",
      link: "/news",
    },
    {
      text: "FAQ",
      icon: "icons/about-faq-help-question-svgrepo-com.svg",
      link: "/faq",
    },
    {
      text: "Help & Support",
      icon: "icons/credit-card-svgrepo-com.svg",
      link: "/viewHelpSupport",
    }
  ];

  const adminMenu = [
    {
      text: "Analytics",
      icon: "icons/analytics-svgrepo-com.svg",
      link: "/analytics",
    },
    {
      text: "Headers",
      icon: "icons/credit-card-svgrepo-com.svg",
      link: "/headers",
    },
    {
      text: "Organization",
      icon: "icons/buildings-svgrepo-com.svg",
      link: "/",
    },
    {
      text: "User List",
      icon: "icons/profile-image-round-svgrepo-com.svg",
      link: "/userlist",
    },
    {
      text: "Resources",
      icon: "icons/earth-svgrepo-com.svg",
      link: "/resources",
    },
    {
      text: "Licenses Cost",
      icon: "icons/book-svgrepo-com.svg",
      link: "/addCost",
    },
    {
      text: "FAQ",
      icon: "icons/about-faq-help-question-svgrepo-com.svg",
      link: "/faq",
    },
    {
      text: "News",
      icon: "icons/news-svgrepo-com.svg",
      link: "/news",
    },
  ];

  const orgAdminMenu = [
    {
      text: "Analytics",
      icon: "icons/analytics-svgrepo-com.svg",
      link: "/analytics",
      subMenu: [
        {
          text: "Contacts Analytics",
          icon: "icons/data-analytics.png",
          panel:"Contacts",
          AnalyticShow:true
        },
        {
          text: "Tasks Analytics",
          icon: "icons/data-analytics.png",
          panel:"Tasks",
          AnalyticShow:true
        },
        {
          text: "Call Logs Analytics",
          icon: "icons/data-analytics.png",
          panel:"Call Logs",
          AnalyticShow:true
        }

      ]
    },
    {
      text: "Users",
      icon: "icons/people-svgrepo-com.svg",
      link: "/",
    },
    {
      text: "API",
      icon: "icons/cloud-upload-svgrepo-com.svg",
      link: "/apis",
    },
    {
      text: "Projects",
      icon: "icons/buildings-svgrepo-com.svg",
      link: "/projects",
    },
    {
      text: "Resources",
      icon: "icons/earth-svgrepo-com.svg",
      link: "/resources",
    },
    {
      text: "Lead Distribution",
      icon: "icons/people-svgrepo-com.svg",
      link: "/leadDistributor",
      subMenu: [
        {
          text: "Lead Distribution List",
          icon: "icons/listIcon.png",
          link: "/leadDistributor",
          DistributorShow:true
        },
        {
          text: "Distribution Logic",
          icon: "icons/logicIcon.png",
          link: "/leadDistributorLogic",
          DistributorShow:true
        }
      ]
    },
    {
      text: "Integrations",
      icon: "icons/iconsintgration.png",
      link: "/integrations",
    },
    {
      text: "Subscription",
      icon: "icons/credit-card-svgrepo-com.svg",
      link: "/subscriptionDetails",
    },
    {
      text: "API Data",
      icon: "icons/cloud-upload-svgrepo-com.svg",
      link: "/apiData",
    },
    {
      text: "Booking",
      icon: "icons/book-svgrepo-com.svg",
      link: "/booking",
    },
    {
      text: "News",
      icon: "icons/news-svgrepo-com.svg",
      link: "/news",
    },
    {
      text: "FAQ",
      icon: "icons/about-faq-help-question-svgrepo-com.svg",
      link: "/faq",
    },
    {
      text: "Help & Support",
      icon: "icons/credit-card-svgrepo-com.svg",
      link: "/viewHelpSupport",
    },
    {
      text: "Update Password",
      icon: "icons/password-svgrepo-com.svg",
      link: "/updateUserPassword",
    }
  ]

  // {
  //   text: "Analytics",
  //   icon: "icons/people-svgrepo-com.svg",
  //   link: "/analytics",
  // }
  const salesMenu = [
    {
      text: "Analytics",
      icon: "icons/analytics-svgrepo-com.svg",
      link: "/analytics",
      subMenu: [
        {
          text: "Contacts Analytics",
          // link: "/analytics",
          icon: "icons/data-analytics.png",
          panel:"Contacts"
        },
        {
          text: "Tasks Analytics",
          icon: "icons/data-analytics.png",
           panel:"Tasks"
        },
        {
          text: "Call Logs Analytics",
          icon: "icons/data-analytics.png",
           panel:"Call Logs"
        }

      ]
    },
    {
      text: "Contacts",
      icon: "icons/contacts-svgrepo-com.svg",
      link: "/",
    },
    {
      text: "Tasks",
      icon: "icons/tasks-list-svgrepo-com.svg",
      link: "/tasks",
    },
    {
      text: "Booking",
      icon: "icons/book-svgrepo-com.svg",
      link: "/booking",
    },
    {
      text: "Call Logs",
      icon: "icons/call-svgrepo-com.svg",
      link: "/callLogs",
    },

    {
      text: "Help & Support",
      icon: "icons/credit-card-svgrepo-com.svg",
      link: "/viewHelpSupport",
    },
  ];

  const teamLeadMenu = [
    {
      text: "Analytics",
      icon: "icons/analytics-svgrepo-com.svg",
      link: "/analytics",
      subMenu: [
        {
          text: "Contacts Analytics",
          // link: "/analytics",
          icon: "icons/data-analytics.png",
          panel:"Contacts"
        },
        {
          text: "Tasks Analytics",
          icon: "icons/data-analytics.png",
           panel:"Tasks"
        },
        {
          text: "Call Logs Analytics",
          icon: "icons/data-analytics.png",
           panel:"Call Logs"
        }

      ]
    },
    {
      text: "Contacts",
      icon: "icons/contacts-svgrepo-com.svg",
      link: "/",
    },
    {
      text: "Tasks",
      icon: "icons/tasks-list-svgrepo-com.svg",
      link: "/tasks",
    },
    {
      text: "Booking",
      icon: "icons/book-svgrepo-com.svg",
      link: "/booking",
    },
    {
      text: "Call Logs",
      icon: "icons/call-svgrepo-com.svg",
      link: "/callLogs",
    },
    
    {
      text: "Help & Support",
      icon: "icons/credit-card-svgrepo-com.svg",
      link: "/viewHelpSupport",
    },
  ];

  const omMenu = [
    {
      text: "Bookings",
      icon: "icons/book-svgrepo-com.svg",
      link: "/",
    }
  ];
  // console.log(111,props.user);
  // console.log("history", props);
  const hadleLogOut = () => {
    setOpenModel(true);
  }
  const handleModelClose = () => {
    setOpenModel(false);
  }
  const handleSubMenu = (text) => {
    if (text === "Contacts Analytics") {
      setUserPanel('Contacts');
      dispatcher(setAnalyticsPanel("Contacts"));
    } else if (text === "Tasks Analytics") {
      setUserPanel('Tasks');
      dispatcher(setAnalyticsPanel("Tasks"));
    } else if (text === "Call Logs Analytics") {
      setUserPanel('Call Logs');
      dispatcher(setAnalyticsPanel("Call Logs"));
    }
  }
  const handleSubBarOrg=(text)=>{
    if(text === "Analytics"){
      setAnalyticsOpen(!analyticsOpen);
      setLeadDistributorOpen(false);
    }
    else if(text==="Lead Distribution"){
      setLeadDistributorOpen(!leadDistributorOpen);
      setAnalyticsOpen(false);
    }
    else{
      setAnalyticsOpen(false);
      setLeadDistributorOpen(false);
    }
  }
  return (
    <div
      className={open ? "side-container" : "side-container side-container-NX"}
      style={{ backgroundColor: props.Tcolor.backgroundColor }}
    >
      <div className="nav-upper">
        {/* <div onClick={() => setOpen(!open)} style={open ?{marginLeft:'10px',marginTop:"50px"}:{marginRight:'10px',marginTop:"50px"}}>
           {!open ? <IoMdArrowDroprightCircle size={35} color={"#ffffff"} /> : <IoMdArrowDropleftCircle size={35} color={"#ffffff"} />}
           </div> */}
        <div className="nav-heading" style={{ overflow: "hidden", position: "relative", marginRight: open ? "70px" : "20px", marginTop: "50px" }}>
          {/* <div onClick={() => setOpen(!open)} style={open ?{marginLeft:'10px'}:{marginRight:'10px'}}>
           {!open ? <IoMdArrowDroprightCircle size={35} color={"#ffffff"} /> : <IoMdArrowDropleftCircle size={35} color={"#ffffff"} />}
           </div> */}
          <button
            className={!open ? "hamburger hamburger-in" : "hamburger hamburger-out"}
            onClick={() => setOpen(!open)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className="nav-menu" style={{ marginBottom: "10px" }}>
          {props.user === "Lead Manager"
            ? leadMenu.map(({ text, icon, link, subMenu }) => (
              <>
                <Link
                  to={link}
                  className={open ? "menu-item" : "menu-item menu-item-NX"}
                  style={{ color: props.pathname === link ? "rgb(0, 0, 0)" : "white", backgroundColor: props.pathname === link ? "white" : "" }}
                  onClick={() => text === "Analytics" ? setAnalyticsOpen(!analyticsOpen):setAnalyticsOpen(false)}
                >
                  <img
                    className={props.Tcolor.color === "#fff" ? props.pathname === link ? "rgb(0, 0, 0)" : "white-img" : props.pathname === link ? "rgb(0, 0, 0)" : "white-img"}
                    src={icon}
                    alt=""
                  />
                  {open && (
                    <div className="menu-text-with-icon">
                      <p>{text}</p>
                      {text === "Analytics" && (
                        <span className="menu-arrow-icon">
                          {analyticsOpen ? <FaChevronDown size={16} /> : <FaChevronRight size={16} />}
                        </span>
                      )}
                    </div>
                  )}
                  {/* {!open && <div className="tooltip_nav">{text}</div>} */}
                </Link>
                {subMenu && open && analyticsOpen && (
                  <div className="submenu" style={{ paddingBottom: "20%", textAlign: "left", marginTop: "-25%", paddingLeft: "21%" }}>
                    {subMenu.map(({ text,icon,panel }) => (
                      // <Link
                      //   key={text}
                      //   to={link}
                      //   className="submenu-item"
                      //   style={{ color: props.pathname === link ? "white" : "white", backgroundColor: props.pathname === link ? "white" : "" }}
                      // >
                      <>
                      <p style={{ fontSize: "12px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => handleSubMenu(text)} >
                        <img
                          src={icon}
                          alt={text}
                          width={14}
                          style={{ filter: "invert(1)", marginRight: "8px" }} // Make icon white and add spacing
                        />
                        {text}
                      </p>
                      {userPanel === panel && <span className="line"></span>}
                      </>
                      // </Link>
                    ))}
                  </div>
                )}
              </>
            ))
            : props.sadmin === "info@leadsrubix.com"
              ? adminMenu.map(({ text, icon, link }) => (
                <Link
                  to={link}
                  className={open ? "menu-item" : "menu-item menu-item-NX"}
                  style={{ color: props.pathname === link ? "rgb(0, 0, 0)" : "white", backgroundColor: props.pathname === link ? "white" : "" }}
                >
                  <img
                    className={props.Tcolor.color === "#fff" ? props.pathname === link ? "rgb(0, 0, 0)" : "white-img" : props.pathname === link ? "rgb(0, 0, 0)" : "white-img"}
                    src={icon}
                    alt=""
                  />
                  {open && <p>{text}</p>}
                  {/* {!open && <div className="tooltip_nav">{text}</div>} */}
                </Link>
              ))
              : props.user === "Sales"
                ? salesMenu.map(({ text, icon, link,subMenu }) => (
                  <>
                  <Link
                    to={link}
                    className={open ? "menu-item" : "menu-item menu-item-NX"}
                    style={{ color: props.pathname === link ? "rgb(0, 0, 0)" : "white", backgroundColor: props.pathname === link ? "white" : "" }}
                    onClick={() => text === "Analytics" ? setAnalyticsOpen(!analyticsOpen):setAnalyticsOpen(false)}
                  >
                    <img
                      className={props.Tcolor.color === "#fff" ? props.pathname === link ? "rgb(0, 0, 0)" : "white-img" : props.pathname === link ? "rgb(0, 0, 0)" : "white-img"}
                      src={icon}
                      alt=""
                    />
                    {open && (
                    <div className="menu-text-with-icon">
                      <p>{text}</p>
                      {text === "Analytics" && (
                        <span className="menu-arrow-icon">
                          {analyticsOpen ? <FaChevronDown size={16} /> : <FaChevronRight size={16} />}
                        </span>
                      )}
                    </div>
                  )}
                  </Link>
                   {subMenu && open && analyticsOpen && (
                    <div className="submenu" style={{ paddingBottom: "20%", textAlign: "left", marginTop: "-25%", paddingLeft: "21%" }}>
                      {subMenu.map(({ text,icon,panel }) => (
                        <>
                        <p style={{ fontSize: "12px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => handleSubMenu(text)} >
                          <img
                            src={icon}
                            alt={text}
                            width={14}
                            style={{ filter: "invert(1)", marginRight: "8px" }} // Make icon white and add spacing
                          />
                          {text}
                        </p>
                        {userPanel === panel && <span className="line"></span>}
                        </>
                      ))}
                    </div>
                  )}
                  </>
                ))
                : props.user === "Team Lead"
                  ? teamLeadMenu.map(({ text, icon, link,subMenu }) => (
                    <>
                    <Link
                      to={link}
                      className={open ? "menu-item" : "menu-item menu-item-NX"}
                      style={{ color: props.pathname === link ? "rgb(0, 0, 0)" : "white", backgroundColor: props.pathname === link ? "white" : "" }}
                      onClick={() => text === "Analytics" ? setAnalyticsOpen(!analyticsOpen):setAnalyticsOpen(false)}
                    >
                      <img
                        className={props.Tcolor.color === "#fff" ? props.pathname === link ? "rgb(0, 0, 0)" : "white-img" : props.pathname === link ? "rgb(0, 0, 0)" : "white-img"}
                        src={icon}
                        alt=""
                      />
                    {open && (
                    <div className="menu-text-with-icon">
                      <p>{text}</p>
                      {text === "Analytics" && (
                        <span className="menu-arrow-icon">
                          {analyticsOpen ? <FaChevronDown size={16} /> : <FaChevronRight size={16} />}
                        </span>
                      )}
                    </div>
                  )}
                    </Link>
                       {subMenu && open && analyticsOpen && (
                        <div className="submenu" style={{ paddingBottom: "20%", textAlign: "left", marginTop: "-25%", paddingLeft: "21%" }}>
                          {subMenu.map(({ text,icon,panel }) => (
                            // <Link
                            //   key={text}
                            //   to={link}
                            //   className="submenu-item"
                            //   style={{ color: props.pathname === link ? "white" : "white", backgroundColor: props.pathname === link ? "white" : "" }}
                            // >
                            <>
                            <p style={{ fontSize: "12px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => handleSubMenu(text)} >
                              <img
                                src={icon}
                                alt={text}
                                width={14}
                                style={{ filter: "invert(1)", marginRight: "8px" }} // Make icon white and add spacing
                              />
                              {text}
                            </p>
                            {userPanel === panel && <span className="line"></span>}
                            </>
                            // </Link>
                          ))}
                        </div>
                      )}
                      </>
                  ))
                  : props.user === "Operation Manager"
                    ? omMenu.map(({ text, icon, link }) => (
                      <Link
                        to={link}
                        className={open ? "menu-item" : "menu-item menu-item-NX"}
                        style={{ color: props.pathname === link ? "rgb(0, 0, 0)" : "white", backgroundColor: props.pathname === link ? "white" : "" }}
                      >
                        <img
                          className={props.Tcolor.color === "#fff" ? props.pathname === link ? "rgb(0, 0, 0)" : "white-img" : props.pathname === link ? "rgb(0, 0, 0)" : "white-img"}
                          src={icon}
                          alt=""
                        />
                        {open && <p>{text}</p>}
                        {/* {!open && <div className="tooltip_nav">{text}</div>} */}
                      </Link>
                    ))
                    : props.user !== "Lead Manager" && props.user !== "Sales" && props.user !== "Team Lead" && props.sadmin !== "info@leadsrubix.com"
                      ? orgAdminMenu.map(({ text, icon, link,subMenu}) => (
                        <>
                        <Link
                          to={link}
                          className={open ? "menu-item" : "menu-item menu-item-NX"}
                          style={{ color: props.pathname === link || (props.pathname==="/leadDistributorLogic" && link==="/leadDistributor") ? "rgb(0, 0, 0)" : "white", backgroundColor: props.pathname === link || (props.pathname==="/leadDistributorLogic" && link==="/leadDistributor") ? "white" : "" }}
                          onClick={() => handleSubBarOrg(text)}
                        >
                          <img
                            className={props.Tcolor.color === "#fff" ? props.pathname === link || (props.pathname==="/leadDistributorLogic" && link==="/leadDistributor") ? "rgb(0, 0, 0)" : "white-img" : props.pathname === link || (props.pathname==="/leadDistributorLogic" && link==="/leadDistributor") ? "rgb(0, 0, 0)" : "white-img"}
                            src={icon}
                            alt=""
                          />
                          {open && (
                            <div className="menu-text-with-icon">
                              <p>{text}</p>
                              {text === "Analytics" && (
                                <span className="menu-arrow-icon">
                                  {analyticsOpen ? <FaChevronDown size={16} /> : <FaChevronRight size={16} />}
                                </span>
                              )}
                              {text === "Lead Distribution" && (
                                <span className="menu-arrow-icon">
                                  {leadDistributorOpen ? <FaChevronDown size={16} /> : <FaChevronRight size={16} />}
                                </span>
                              )}
                            </div>
                          )}
                        </Link>
                         {subMenu && open && analyticsOpen && (
                          <div className="submenu" style={{ paddingBottom: "20%", textAlign: "left", marginTop: "-25%", paddingLeft: "21%" }}>
                            {subMenu.map(({ text,icon,panel,AnalyticShow }) => (
                              <>
                              {AnalyticShow===true && <>
                                <p style={{ fontSize: "12px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => handleSubMenu(text)} >
                                <img
                                  src={icon}
                                  alt={text}
                                  width={14}
                                  style={{ filter: "invert(1)", marginRight: "8px" }} // Make icon white and add spacing
                                />
                                {text}
                              </p>
                              {userPanel === panel && <span className="line"></span>}
                              </>}
                              </>
                            ))}
                          </div>
                        )}
                         {subMenu && open && leadDistributorOpen && (
                          <div className="submenu" style={{ paddingBottom: "20%", textAlign: "left", marginTop: "-25%", paddingLeft: "21%" }}>
                            {subMenu.map(({ text,icon,link,DistributorShow }) => (
                              <>
                              {DistributorShow===true && <>
                                <p style={{ fontSize: "12px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => handleSubMenu(text)} >
                                <img
                                  src={icon}
                                  alt={text}
                                  width={14}
                                  style={{ filter: "invert(1)", marginRight: "8px" }} // Make icon white and add spacing
                                />
                                <span onClick={()=>history.push(link)}>{text}</span>
                              </p>
                              {console.log("historylocationpathname link",link)}
                              {console.log("historylocationpathname",history.location.pathname)}
                              {link === history.location.pathname && <span className="line"></span>}
                              </>}
                              </>
                            ))}
                          </div>
                        )}
                        </>
                      ))
                      : null}
          {<p
            onClick={() => hadleLogOut()}
            className={open ? "menu-item" : "menu-item menu-item-NX"}
            style={{ cursor: "pointer" }}
          >
            <img
              src={"icons/logout.svg"}
              alt=""
            />
            {open && <p>{"LogOut"}</p>}
            {/* {!open && <div className="tooltip_nav">{"LogOut"}</div>} */}
          </p>}
          {openModel && (
            <UserDetails
              openM={openModel}
              close={handleModelClose}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SideNav;
