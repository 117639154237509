import React, { FunctionComponent } from "react";
import styles from "./AnalyticsFilter.module.css";

import AnalyticsFilterValue from "./AnalyticsFilterValue";
import {themeColors} from "../../Components/theme";
import { connect, useDispatch } from "react-redux";

type props = {
  setFilterState: (data: "All" | "YTD" | "PM" | "MTD" | "T" | "Y") => void;
  filterUsed: string;
  
  showFilter: (data: boolean) => void;
  theme:any;
};
const AnalyticsFilter: FunctionComponent<props> = ({
  setFilterState,
  filterUsed,
  showFilter,
  theme
}) => {
  const containerStyles = {backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <div className={styles.parent} style={containerStyles}>
      <div className={styles.headContainer}>
        <p 
        // className={styles.heading}
        >Filter Value</p>
        <p 
        className={styles.resetText}
          style={{color:themeColors.danger}}
         onClick={() => setFilterState("All")}>
          Reset
        </p>
      </div>
      <AnalyticsFilterValue
        title="Month Till Date"
        setFilterState={(data) => setFilterState(data)}
        filterType="MTD"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />
      <AnalyticsFilterValue
        title="Previous Month"
        setFilterState={(data) => setFilterState(data)}
        filterType="PM"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />
      <AnalyticsFilterValue
        title="All"
        setFilterState={(data) => setFilterState(data)}
        filterType="All"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />
      <AnalyticsFilterValue
        title="Today"
        setFilterState={(data) => setFilterState(data)}
        filterType="T"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />
      <AnalyticsFilterValue
        title="Yesterday"
        setFilterState={(data) => setFilterState(data)}
        filterType="Y"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(AnalyticsFilter);
