import React, { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { fetchCallLogs, fetchUserCallLogs } from '../../Services/contacts';

import TopBar from '../../Components/TopBar/TopBar';
import commonStyle from '../common.module.css';
import Loading from '../../Components/Loading/Loading';
import {
  booleanObject,
  CALLLOG_COLUMNS,
  datesField,
} from '../../Values/tables';
import { getDateString, searchCallLogs } from '../../Values/utils';
import CustomTable from '../../Components/CustomTable/CustomTable';
import axios from 'axios';
import moment from 'moment';
import {
  setClearFilter,
  setFilterObject,
  setRefreshCallLogs,
  showSnackbarAction,
} from '../../Redux/actions';
import ApiCustomTable from '../../Components/CustomTable/ApiCustomTable';
import ApiTopBar from '../../Components/TopBar/ApiTopBar';
import { url } from '../../Values/constants';
import { themeColors } from '../../Components/theme';

type props = {
  user: any;
  contacts: any;
  history: any;
  role: any;
  organizationUsers: any;
  teamLeadUsers: any;
  filterObject: any;
  filterSort: any;
  refreshCallLogs: boolean;
  searchString: string;
  theme: any;
  callLogHeaders: any;
  authToken:any;
};

let isFinished = false;

const DrilldownCallLogsPanel: FunctionComponent<props> = ({
  user,
  contacts,
  history,
  role,
  organizationUsers,
  teamLeadUsers,
  filterObject,
  searchString,
  filterSort,
  refreshCallLogs,
  theme,
  callLogHeaders,
  authToken
}) => {
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [callList, setCallList] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [searchedItem, setsearchedItem] = useState('');
  const [columnModal, setColumnModal] = useState(false);
  const [originalData, setOriginalData] = useState<any[] | undefined>(
    undefined
  );
  const [contactsMap, setcontactsMap] = useState<any>(undefined);

  const [lastPage, setLastPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allCallLogs, setAllCallLogs] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>({});
  const [showImportModal, setShowImportModal] = useState(false);
  const [localStorageData, setLocalStorageData] = useState<any>({});
  const [filterApiCount, SetFilterApiCount] = useState<any>({});
  const [drillDownCount, SetDrillDownCount] = useState<any>({});
  const dispatcher = useDispatch();

  useEffect(() => {
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== 'columns' && key !== 'callDrilldownData'  && key !== 'isLightMode' && key !== 'totalMonths' && key !== 'lastAlertTime') {
        localStorage.removeItem(key);
      }
    }

    dispatcher(setClearFilter(true));
    const savedData = localStorage.getItem('callDrilldownData');
    if (savedData) {
      setLocalStorageData(JSON.parse(savedData));
    }
  }, []);

  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {
    if (isFinished === true) {
      return;
    } else {
      setLoad(true);
      const filters: { [key: string]: any[] } = { ...localStorageData.callFilter };
      let ownerEmailArr;
      Object.keys(filterObject).forEach((item) => {
        if (filterObject && filterObject[item].length > 0) {
          filters[item] = filterObject[item];
        }
      });
      for (const [key, value] of Object.entries(localStorageData.leadFilter)) {
        ownerEmailArr = value;
      }
      let records = localPageSize ? localPageSize : pageSize;
      const apiData = {
        uid: localStorageData.uid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: '-1' }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        taskFilter: {},
        leadFilter: {},
        callFilter: localStorageData.role === true ? { ...filters, "contact_owner_email": ownerEmailArr } : filters,
        role: localStorageData.role,
      };
      SetDrillDownCount(filters);
      SetFilterApiCount(apiData);
      try {
        axios.defaults.headers.common['x-access-token'] =
          authToken
        const res = await axios.post(
          url + '/callLogs/drillDownSearch',
          apiData
        );

        let data: any[] = res.data;
        if (data.length < records && paginate) {
          isFinished = true;
          setLastPage(page);
        }

        data.forEach((task) => {
          task.contactId = task.leadId;
          task.reporting_to = userMap[task.contact_owner_email];
          delete task['Id'];
          Object.keys(task).forEach((key) => {
            if (datesField.includes(key) && task[key] !== '') {
              if (task[key] !== null) {
                task[key] = moment(task[key]);
              } else {
                task[key] = '';
              }
            }
          });
        });

        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
        } else {
          setFilterData(data);
        }
        setLoad(false);
      } catch {
        setFilterData([]);
        setLoad(false);
        dispatcher(showSnackbarAction('Please Try Again!!', 'error'));
      }
    }
  };

  useEffect(() => {
    if (localStorageData.uid === undefined) {
      setFilterData([]);
      return;
    }
    if (userMap === undefined) {
      setFilterData([]);
      return;
    }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    if(authToken){
      callApi(false, pageSize, 1);
    }
  }, [localStorageData, filterObject,authToken]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        if(authToken){
          callApi(false, pageSize);
        }
      } else {
        if(authToken){
          callApi(true, pageSize);
        }
      }
    }
  }, [page, pageSize,authToken]);



  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if(authToken){
      callCountApi();
    }
  }, [refreshCallLogs, user.uid, filterApiCount, drillDownCount,authToken]);

  useEffect(() => {
    if (refreshCallLogs === true) {
      if(authToken){
        callApi();
      getCallLogsFilter();
      dispatcher(setRefreshCallLogs(false));
      }
    }
  }, [refreshCallLogs,authToken]);

  const callCountApi = async () => {

    const { page, pageSize, searchString, sort, ...objFilterData } = filterApiCount;
    axios.defaults.headers.common['x-access-token'] =
      authToken
    const res = await axios.post(url + '/callLogs/callLogsDrillDownCount', objFilterData);
    setTotalCounts(res.data[0].total);
  };

  const getCallLogsFilter = async () => {
    axios.defaults.headers.common['x-access-token'] =
      authToken
    const res = await axios.post(url + '/callLogs/filterValues', {
      uid: localStorageData.uid,
    });

    if (res.data[0]) {
      dispatcher(setFilterObject({ ...res.data[0], ...booleanObject }));
    } else {
      dispatcher(setFilterObject({}));
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      return;
    }
    if(authToken){
      getCallLogsFilter();
    }
  }, [user,authToken]);
  const exportFile = () => {
    let data: any[] = [];
    interface DataObj {
      label: any;
      value: any;
    }
    // const dbData = [{"label": "customer_name","value": "Customer Name"},{"label": "contact_owner_email","value": "Contact Owner Email"},{"label": "stage","value": "Stage"},{"label": "project","value": "Project"},{"label": "location","value": "Location"},{"label": "budget","value": "Budget"},{"label": "contact_no","value": "Contact No"},{"label": "inventory_type","value": "Inventory Type"},{"label": "transfer_status","value": "Transfer Status"},{"label": "created_by","value": "Created By"},{"label": "duration","value": "Duration"},{"label": "created_at","value": "Created At"}];
    const dbData = callLogHeaders;
    const drillLogsHeaderCustomerName :any = dbData.find((obj: DataObj) => obj.label === "customer_name")?.value;
    const drillLogsHeaderContactOwnerEmail :any = dbData.find((obj: DataObj) => obj.label === "contact_owner_email")?.value;
    const drillLogsHeaderStage :any = dbData.find((obj: DataObj) => obj.label === "stage")?.value;
    const drillLogsHeaderProject :any = dbData.find((obj: DataObj) => obj.label === "project")?.value;
    const drillLogsHeaderLocation :any = dbData.find((obj: DataObj) => obj.label === "location")?.value;
    const drillLogsHeaderBudget :any = dbData.find((obj: DataObj) => obj.label === "budget")?.value;
    const drillLogsHeaderContactNo :any = dbData.find((obj: DataObj) => obj.label === "contact_no")?.value;
    // const drillLogsHeaderInventoryType :any = dbData.find((obj: DataObj) => obj.label === "inventory_type")?.value;
    const drillLogsHeaderTransferStatus :any = dbData.find((obj: DataObj) => obj.label === "transfer_status")?.value;
    const drillLogsHeaderCreatedBy :any = dbData.find((obj: DataObj) => obj.label === "created_by")?.value;
    const drillLogsHeaderDuration :any = dbData.find((obj: DataObj) => obj.label === "duration")?.value;
    const drillLogsHeaderCreatedAt :any = dbData.find((obj: DataObj) => obj.label === "created_at")?.value;
    if (selectedRowsData.length !== 0) {
      selectedRowsData.forEach((item, index) => {
        data.push({
          "S.No":index+1,
          [drillLogsHeaderCustomerName]:item?.customer_name,
          [drillLogsHeaderContactOwnerEmail]:item?.contact_owner_email,
          [drillLogsHeaderStage]:item?.contact_no,
          [drillLogsHeaderProject]:item?.project,
          [drillLogsHeaderLocation]:item?.location,
          [drillLogsHeaderBudget]:item?.budget,
          [drillLogsHeaderContactNo]: item?.contact_no,
          // [drillLogsHeaderInventoryType]:item?.inventory_type,
          [drillLogsHeaderTransferStatus]: item?.transfer_status,
          [drillLogsHeaderCreatedBy]:item?.created_by,
          [drillLogsHeaderDuration]: item?.duration,
          [drillLogsHeaderCreatedAt]:getDateString(item?.created_at),
        });
      });
    }
    return data;
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <div style={{ height: "100vh", ...containerStyles }}>
      <div className={commonStyle.topBar} style={containerStyles}>
        <ApiTopBar
          history={history}
          title={'Add Call Logs'}
          path={'/addContacts'}
          onClick={() => {
            setShowImportModal(true);
          }}
          onChange={(val) => setsearchedItem(val)}
          filterText={'Status'}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={true}
          searchedString={searchString}
          onExport={exportFile}
          titleDrillDown={'Drill Down'}
        />
      </div>
      <div className={commonStyle.parent} style={{ height: "81%", ...containerStyles }}>
        {filterData === undefined && <Loading />}
        <ApiCustomTable
          tableColumns={CALLLOG_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="CallLogs"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setPage={(val) => setPage(val)}
          setPageSize={(val) => setPageSize(val)}
          callApi={callApi}
          pageSize={pageSize}
          page={page}
          isFinished={isFinished}
          totalCount={totalCounts}
          lastPage={lastPage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    searchString: state.searchItem.callLogsSearchString,
    refreshCallLogs: state.refresh.refreshCallLogs,
    theme: state.theme.isLightMode,
    callLogHeaders:state.calllogsHeaderList.data,
    authToken:state.user.authToken,
  };
};
export default connect(mapStateToProps)(DrilldownCallLogsPanel);
