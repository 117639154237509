import React, { FunctionComponent, useState,useEffect } from "react";
import Modal from "react-modal";
import styles from "./AddResourceModal.module.css";
import { IoIosClose } from "react-icons/io";
import { connect, useDispatch } from "react-redux";
import {themeColors} from "../../theme";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import ColorPicker from "react-pick-color";
import Dropdown from "../../DropDown/Dropdown";
import { fetchpropertyType } from "../../../Services/contacts";

type props = {
  open: boolean;
  close: () => void;
  heading: string;
  title: string;
  onSave: (v: any,c:any) => void;
  theme:any;
  user:any;
  conatctHeader:any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const AddResources: FunctionComponent<props> = ({
  open,
  close,
  heading,
  title,
  onSave,
  theme,
  user,
  conatctHeader,
}) => {
  const [value, setValue] = useState("");
  const [color, setColor] = useState("#262944");
  const selectInputContainerStyles = { width: "240px", marginLeft: "15px" };
  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyTypeSelected, setPropertyTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

    useEffect(() => {
      if(user.user_email==="info@leadsrubix.com"){
        const unsubProperty = fetchpropertyType(
          " ",
          (data) => setPropertyTypeList(data)
        );
        return () => {
          unsubProperty();
        };
      }
      else{
        const unsubProperty = fetchpropertyType(
          user.organization_id,
          (data) => setPropertyTypeList(data)
        );
        return () => {
          unsubProperty();
        };
      }
    }, []);
  const containerStyles ={ backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark} 
  return (
    // <Modal
    //   isOpen={open}
    //   className={styles.parent}
    //   overlayClassName={styles.overlay}
    //   onRequestClose={close}
    //   shouldCloseOnOverlayClick={true}
    //   shouldCloseOnEsc={true}
    //   style={customStyles}
    //   // closeTimeoutMS={500}
    //   closeTimeoutMS={2000}
    // >
    //   <div className={styles.headingContainer}>
    //   <h5 
    //   // className={styles.heading}
    //   >{heading}</h5>
    //   <div className={styles.icon} onClick={close}>
    //     <IoIosClose size={35} color={"#808080"} />
    //   </div>
    //   </div>
    //   <div className={styles.nameBox}>
    //     {/* <span className={styles.name}>{title}</span> */}
    //     <h6>{title}:</h6>
    //     <input
    //       className={styles.input}
    //       placeholder={title}
    //       onChange={(event) => setValue(event.target.value)}
    //     />
    //   </div>
    //   <div className={styles.button}>
    //     <button className={styles.cancel} onClick={close}>
    //       Cancel
    //     </button>
    //     <button className={styles.change} onClick={() => onSave(value)}>
    //       Save
    //     </button>
    //   </div>
    // </Modal>

     <Backdrop>
      <motion.div
        key="modal"
        className="card p-4 shadow rounded"
        style={{position: "absolute",
        ...containerStyles}}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
      <div className={styles.headingContainer}>
      <h5>{heading}</h5>
      {/* <div className={styles.icon} onClick={close}>
        <IoIosClose size={35} color={"#808080"} />
      </div> */}
      </div>
      <div className={styles.nameBox}>
        {/* <span className={styles.name}>{title}</span> */}
        {heading !== "Property Type" && heading !== "Property Sub Type" && (
           <h6>{title}:</h6>
          )
          }
          {heading === "Property Type" && (
           <h6>{`Enter ${conatctHeader.find((obj: any) => obj.label === "property_type").value}`}</h6>
          )
          }
          {heading === "Property Sub Type" && (
           <h6>{`Enter ${conatctHeader.find((obj: any) => obj.label === "property_sub_type").value}`}</h6>
          )
          }
        <input
          // className={styles.input}
          className="form-control"
          style={{  flex: 1,fontSize:"14px"}}
          placeholder={""}
          onChange={(event) => setValue(event.target.value)}
        />
      </div>
      {heading==="Lead Source" && (
        <div className={styles.nameBox}>
        {/* <span className={styles.name}>{title}</span> */}
        <h6>Choose Lead Source Colour:</h6>
        <ColorPicker color={color} onChange={(color) => setColor(color.hex)} />
      </div>
      )
      }
      {heading==="Property Sub Type" && (
        <div className={styles.nameBox}>
        <h6>{`Choose ${conatctHeader.find((obj: any) => obj.label === "property_type").value}`}:</h6>
        <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyTypeList}
                  selectedValue={propertyTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertyTypeSelected(value);
                  }}
                />
              </div>
      </div>
      )
      }
      <div className={styles.button}>
      {heading!=="Property Sub Type" &&(
        <button className={styles.change} onClick={() => onSave(value,color)}>
        Save
      </button>
      )}
      {heading==="Property Sub Type" &&(
        <button className={styles.change} onClick={() => onSave(value,propertyTypeSelected)}>
        Save
      </button>
      )}
        <button className={styles.cancel} onClick={close}>
          Cancel
        </button>
      </div>
      </motion.div>
     </Backdrop>
  );
};



const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    contacts: state.contacts,
    theme: state.theme.isLightMode,
    conatctHeader: state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(AddResources);

