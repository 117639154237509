import React, { FunctionComponent } from "react";
import { useMemo, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "./HelpSupport.module.css";
import { themeColors } from "../../Components/theme";


type props = {
    user: any;
    contacts: any;
    history: any;
    role: any;
    organizationUsers: any;
    teamLeadUsers: any;
    theme: any;
};

const HelpSupport: FunctionComponent<props> = ({
    user,
    contacts,
    history,
    role,
    organizationUsers,
    teamLeadUsers,
    theme
}) => {
    const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

    return (
        <>
            <div
                className="container-fluid p-4 d-flex flex-column gap-4"
                style={{ minHeight: "100vh", height: "fit-content", ...containerStyles }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "auto" }}>
                    <div
                        // className="d-flex justify-content-between"
                        style={{ width: "50%", border: "1px soild black", height: "100%" }}
                    >
                        <h5
                        // className={styles.title}
                        >Feel free to contact us.</h5>
                    </div>
                    <div
                        style={{ width: "50%", border: "1px soild black", height: "100%" }}
                    // className="d-flex justify-content-between"
                    >
                        <h5
                        // className={styles.title}
                        >Map</h5>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        role: state.user.role,
        contacts: state.contacts,
        organizationUsers: state.organizationUsers.data,
        teamLeadUsers: state.teamLeadUsers.data,
        theme: state.theme.isLightMode
    };
};
export default connect(mapStateToProps)(HelpSupport);
