import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { connect, useDispatch } from "react-redux";
import { hideSnackbar } from "../Redux/actions";

const CustomSnackbar = ({ snackbarData }: any) => {
  const dispatcher = useDispatch();
  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    dispatcher(hideSnackbar());
  };

  return (
    <Snackbar
      open={snackbarData.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state: any) => {
  return {
    snackbarData: state.snackbar,
  };
};

export default connect(mapStateToProps)(CustomSnackbar);
