const organizationReducer = (
  state = {
    id: "",
    teams: [],
    designations: [],
    showDeactivationAlert: false,
    expirationDate: "",
    paymentStatus: true,
    validTill:"",
    organizationData:{}
  },
  action: {
    type:
      | "SET_ORGANIZATION"
      | "SET_ID"
      | "UPDATE_TEAMS"
      | "UPDATE_DESIGNATIONS"
      | "SET_DEACTIVATION_ALERT"
      | "SET_PAYMENT_STATUS"
      | "SET_ORGANIZATION_DATA"
    payload: any;
  }
) => {
  switch (action.type) {
    case "SET_ORGANIZATION":
      return { ...state, ...action.payload };
    case "SET_ID":
      return { ...state, id: action.payload };
    case "UPDATE_TEAMS":
      return { ...state, teams: [...state.teams, action.payload] };
    case "UPDATE_DESIGNATIONS":
      return {
        ...state,
        designations: [...state.designations, action.payload],
      };
    case "SET_DEACTIVATION_ALERT":
      return {
        ...state,
        showDeactivationAlert: action.payload.showAlert,
        expirationDate: action.payload.expirationDate,
        validTill: action.payload.validTill,
    };
    case "SET_PAYMENT_STATUS":
      return {
        ...state,
        paymentStatus: action.payload.paymentStatus
    };
    case "SET_ORGANIZATION_DATA":
      return {
        ...state,
        organizationData: action.payload.organizationData
    };
    default:
      return state;
  }
};

export default organizationReducer;
