import React, { useEffect, useState } from "react";
import commonStyle from "../common.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import { fetchOrganizations } from "../../Services/organizations";
import Loading from "../../Components/Loading/Loading";
import { ORGANIZATION_COLUMNS,HEADERS_COLUMNS } from "../../Values/tables";

import { connect, useDispatch } from "react-redux";
import { filterStatus, searchOrganizationItem } from "../../Values/utils";
import styles from '../Analytics/Analytics.module.css'
import CustomTable from "../../Components/CustomTable/CustomTable";
import axios from "axios";
import { url } from "../../Values/constants";
import { themeColors } from "../../Components/theme";

const Headers = ({ history,theme }: any) => {
  const [organizationData, setOrganizationData] =
    useState<any[] | undefined>(undefined);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    getDefaultHeaders();
    // const unSub = fetchOrganizations((data) => setOrganizationData(data));
    // return unSub;
  }, []);

  useEffect(() => {
    if (organizationData !== undefined) {
      setFilterData(organizationData);
    }
  }, [organizationData, dispatcher]);

  useEffect(() => {
    if (status === "ALL") {
      if (organizationData) {
        const data = searchOrganizationItem(organizationData, searchQuery);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchOrganizationItem(temporaryData, searchQuery);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchQuery, organizationData]);

  useEffect(() => {
    if (searchQuery === "") {
      if (organizationData) {
        const data = filterStatus(organizationData, status);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, organizationData]);

  const getDefaultHeaders = async () => {
    setLoad(true);
      try {
        // axios.defaults.headers.common["x-access-token"] =
        //   authToken
        // const res = await axios.get("http://localhost:4001/headers/getAllHeaders");
        const res = await axios.get(url+'/headers/getAllHeaders');
        let resSortData=res.data.sort((a:any, b:any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        const filteredArray = resSortData.filter((obj:any) => obj.is_visible === "true");
        setOrganizationData(filteredArray);
        setLoad(false);
      } catch (e) {
        setLoad(false);
      }
};
const topBarStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,width:"97%"}
const parentStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark}
  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "4%",...parentStyles}}>
      {load === true && <Loading />}
        <div className={commonStyle.topBar} style={{ marginTop: "0px",...topBarStyles}}>
          <TopBar
            history={history}
            title={"Add Headers"}
            path={"addHeaders"}
            onChange={(val) => setsearchQuery(val)}
            setStatus={(status) => setStatus(status)}
            filterText="Status"
            status={status}
            setColumnModal={(data) => setColumnModal(data)}
            show={true}
            showStatusBox={true}
          />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
          {/* {organizationData === undefined && <Loading />} */}
          <CustomTable
            tableColumns={HEADERS_COLUMNS}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="Dynamic header"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            setSearchedItem={(val) => setsearchQuery(val)}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
  
  };
};
export default connect(mapStateToProps)(Headers);
// export default Headers;
