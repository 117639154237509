import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  contactResources,
  createTaskFirebase,
  fetchConstants,
  fetchNewPropertySubType,
  fetchpropertySubType,
  fetchpropertyType,
  validateInterested,
} from "../../../Services/contacts";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";
import TextInput from "../../TextInput/TextInput";
import { FaUser } from "react-icons/fa";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import {themeColors} from "../../theme";
import Label from "../../Label";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";

type props = {
  contactDetail: any;
  close: () => void;
  open: boolean;
  notes: any[];
  tasksData: any[];
  user: any;
  theme:any;
  conatctHeader:any;
};


const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const InterestedLeadModal: FunctionComponent<props> = ({
  contactDetail,
  close,
  open,
  notes,
  tasksData,
  user,
  theme,
  conatctHeader,
}) => {
  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const dateRef: any = useRef();
  const noteRef: any = useRef();
  const alternateRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [projectSelected, setProjectSelected] =
    useState<any>({
      label: contactDetail.project
        ? contactDetail.project
        : "Select",
      value: contactDetail.project
        ? contactDetail.project
        : "Select",
    });

  const [locationSelected, setlocationSelected] =
    useState<any>({
      label: contactDetail.location
        ? contactDetail.location
        : "Select",
      value: contactDetail.location
        ? contactDetail.location
        : "Select",
    });
  const [budgetSelected, setBudgetSelected] = useState<any>(
    {
      label: contactDetail.budget
        ? contactDetail.budget
        : "Select",
      value: contactDetail.budget
        ? contactDetail.budget
        : "Select",
    }
  );
  const [propertyTypeSelected, setPropertyTypeSelected] =
    useState<any>({
      label: contactDetail.property_type
        ? contactDetail.property_type
        : "Select",
      value: contactDetail.property_type
        ? contactDetail.property_type
        : "Select",
    });
  const [propertyStageSelected, setPropertyStageSelected] =
    useState<any>({
      label: contactDetail.property_stage
        ? contactDetail.property_stage
        : "Select",
      value: contactDetail.property_stage
        ? contactDetail.property_stage
        : "Select",
    });
  const [nextFollowSelected, setNextFollowSelected] =
    useState<any>({
      label: contactDetail.next_follow_up_type
        ? contactDetail.next_follow_up_type
        : "Select",
      value: contactDetail.next_follow_up_type
        ? contactDetail.next_follow_up_type
        : "Select",
    });
  const [
    propertySubTypeSelected,
    setPropertySubTypeSelected,
  ] = useState<any>({
    label: contactDetail.property_sub_type
      ? contactDetail.property_sub_type
      : "Select",
    value: contactDetail.property_sub_type
      ? contactDetail.property_sub_type
      : "Select",
  });
  const [leadSourceSelected, setLeadSourceSelected] =
    useState<any>({
      label: contactDetail.lead_source
        ? contactDetail.lead_source
        : "Select",
      value: contactDetail.lead_source
        ? contactDetail.lead_source
        : "Select",
    });

  const [projectsList, setProjectsList] = useState<any[]>(
    []
  );
  const [locationsList, setLocationsList] = useState<any[]>(
    []
  );
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [oldPropertyTypeList, setOldPropertyTypeList] = useState<
    any[]
  >([]);
  const [allPropertySubTypeList, setAllPropertySubTypeList] = useState<
    any[]
  >([]);
  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<
    any[]
  >([]);
  const [leadSourceList, setLeadSourceList] = useState<
    any[]
  >([]);

  useEffect(() => {
    const unsub = contactResources(
      contactDetail.organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data)
      
    );
    const unNewPropertyType = fetchpropertyType(
      user.organization_id,
      (data) => setPropertyTypeList(data)
    );
    const unNewPropertySubType = fetchNewPropertySubType(
      user.organization_id,
      (data) => setAllPropertySubTypeList(data)
    );
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setOldPropertyTypeList(data),
      (data) => setnextFollowUpList(data)
    );
    const unSubProperty = fetchpropertySubType(
      contactDetail.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );

    return () => {
      unsub();
      unsubConst();
      unSubProperty();
      unNewPropertyType();
      unNewPropertySubType();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filteredArray = allPropertySubTypeList.filter(item => item.property_type === propertyTypeSelected.value);
    const propertySubTypes = filteredArray.map(item => item.property_sub_type);
    console.log("filtered propertySubTypes",propertySubTypes);
    if(propertySubTypes.length!==0){
      setPropertySubType(propertySubTypes);
    }else{
      setPropertySubType([]);
    }
    // if (
    //   propertyTypeSelected.value === "Commercial" ||
    //   contactDetail.property_type === "Commercial"
    // ) {
    //   setPropertySubType(comTypes);
    // } else if (
    //   propertyTypeSelected.value === "Residential" ||
    //   contactDetail.property_type === "Residential"
    // ) {
    //   setPropertySubType(resTypes);
    // } else {
    //   setPropertySubType([]);
    // }
  }, [propertyTypeSelected]);

  const dispatcher = useDispatch();
  const history = useHistory();
  const location: any = useLocation();

  const onSubmit = () => {
    const data = validateInterested(
      firstNameRef.current.value,
      lastNameRef.current.value,
      propertyTypeSelected.value,
      propertySubTypeSelected.value === "Select"
        ? propertySubTypeSelected.value
        : "",
      propertyStageSelected.value,
      locationSelected.value,
      projectSelected.value,
      budgetSelected.value,
      nextFollowSelected.value,
      dateRef.current.value,
      leadSourceSelected.value === "Select"
        ? ""
        : leadSourceSelected.value,
      alternateRef.current.value,
      propertySubTypeSelected.value === "Select"
        ? ""
        : propertySubTypeSelected.value,
      dispatcher
    );
    const taskData = {
      call_back_reason: "",
      due_date: moment(dateRef.current.value).toDate(),
      type: nextFollowSelected.value,
      status: "Pending",
      customer_name:
        firstNameRef.current.value +
        " " +
        lastNameRef.current.value,
    };
    if (data != undefined) {
      if (noteRef.current.value.length !== 0) {
        addNoteFirebase(
          contactDetail.contactId,
          notes,
          noteRef.current.value,
          () => {},
          dispatcher,
          () => {},
          user.user_email
        );
      }
      createTaskFirebase(
        contactDetail.contactId,
        tasksData,
        taskData,
        data,
        (value: boolean) => setLoad(value),
        dispatcher,
        user,
        close,
        history
      );

      for (let p in data) {
        let key = p;
        //@ts-ignore
        location.state.detail[`${key}`] = data[p];
      }
      location.state.detail.modified_at =
        data.modified_at.toDate();
      location.state.detail.stage_change_at =
        data.stage_change_at.toDate();
    }
  };
  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
      {load === true && <Loading />}
      {open && <Backdrop>
        <motion.div
                   key="modal"
                   className="card p-4 shadow rounded"
                   style={{position: "absolute",
                   ...containerStyles}}
                   variants={dropIn}
                   initial="hidden"
                   animate="visible"
                   exit="exit"
                 >
        <div className={styles.firstDiv}>
          {/* <p className={styles.contactForm}>
            Interested Details
          </p> */}
          <h5>Interested Details</h5>
          <div className={styles.cross} onClick={close}>
            <IoIosClose size={35} color={"#808080"} />
          </div>
        </div>
        <div className={styles.line}></div>
        
        <div style={{maxHeight: "400px",overflowY:"auto"}}>

          
        <div className={styles.box2}>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>First Name</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label="First Name" required={true} />
            <div>
              <TextInput
                title={"Enter first name"}
                style={{ backgroundColor: "#fff" }}
                ref={firstNameRef}
                value={contactDetail.customer_name
                  .split(" ")[0]
                  .trim()}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>

          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Last Name</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label="Last Name" required={true} />
            <div>
              <TextInput
                title={"Enter last name"}
                style={{ backgroundColor: "#fff" }}
                ref={lastNameRef}
                value={contactDetail.customer_name
                  .slice(
                    contactDetail.customer_name.split(
                      " "
                    )[0].length
                  )
                  .trim()}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Location</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "location").value} required={true} />
            <div 
            style={{ marginTop: "7px" }}
            >
              <Dropdown
                option={locationsList}
                selectedValue={locationSelected}
                setSelectedValue={(value) => {
                  setlocationSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Project</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "project").value} required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={projectsList}
                selectedValue={projectSelected}
                setSelectedValue={(value) => {
                  setProjectSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>
                Next Follow Up Type
              </p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "next_follow_up_type").value} required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={nextFollowUpList}
                selectedValue={nextFollowSelected}
                setSelectedValue={(value) => {
                  setNextFollowSelected(value);
                }}
              />
            </div>
          </div>

          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Budget</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "budget").value} required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={budgetsList}
                selectedValue={budgetSelected}
                setSelectedValue={(value) => {
                  setBudgetSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Property Type</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "property_type").value} required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyTypeList}
                selectedValue={propertyTypeSelected}
                setSelectedValue={(value) => {
                  setPropertyTypeSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Property Stage</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "property_stage").value} required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyStageList}
                selectedValue={propertyStageSelected}
                setSelectedValue={(value) => {
                  setPropertyStageSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>
                Next Follow Up Date & Time
              </p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "next_follow_up_date_time").value} required={true} />
            <div>
              <input
                type="datetime-local"
                placeholder="Enter Date"
                ref={dateRef}
                className={styles.dateInput}
              />
            </div>
          </div>

          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Note</p>
              <p className={styles.two}></p>
            </div> */}
            <Label label="Note" required={false} />
            <div>
              <TextInput
                title={"Enter Note"}
                style={{ backgroundColor: "#fff" }}
                ref={noteRef}
              ></TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>
                Property Sub Type
              </p>
              <p className={styles.two}></p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "property_sub_type").value} required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertySubType}
                selectedValue={propertySubTypeSelected}
                setSelectedValue={(value) => {
                  setPropertySubTypeSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Lead Source</p>
              <p className={styles.two}>*</p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "lead_source").value} required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={leadSourceList}
                selectedValue={leadSourceSelected}
                setSelectedValue={(value) => {
                  setLeadSourceSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            {/* <div className={styles.title}>
              <p className={styles.one}>Alternate No.</p>
              <p className={styles.two}></p>
            </div> */}
            <Label label={conatctHeader.find((obj:any) => obj.label === "alternate_no").value} required={false} />
            <div>
              <TextInput
                title={"Enter Alternate Number"}
                style={{ backgroundColor: "#fff" }}
                ref={alternateRef}
                value={contactDetail.alternate_no}
                length={true}
              ></TextInput>
            </div>
          </div>
        </div>
        </div>

        <button
          className={styles.apply}
          style={{ marginTop: "2rem" }}
          onClick={onSubmit}
        >
          Save
        </button>
                 </motion.div>
        </Backdrop>}
      {/* <Modal
        className={styles.parent}
        isOpen={open}
        shouldCloseOnOverlayClick={true}
        overlayClassName={styles.overlay}
        shouldCloseOnEsc={true}
        onRequestClose={close}
        style={customStyles}
      >
        <div className={styles.firstDiv}> */}
          {/* <p className={styles.contactForm}>
            Interested Details
          </p> */}
          {/* <h5>Interested Details</h5>
          <div className={styles.cross} onClick={close}>
            <IoIosClose size={35} color={"#808080"} />
          </div>
        </div>
        <div className={styles.line}></div>

        <div className={styles.box2}>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>First Name</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="First Name" required={true} />
            <div>
              <TextInput
                title={"Enter first name"}
                style={{ backgroundColor: "#fff" }}
                ref={firstNameRef}
                value={contactDetail.customer_name
                  .split(" ")[0]
                  .trim()}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>

          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Last Name</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Last Name" required={true} />
            <div>
              <TextInput
                title={"Enter last name"}
                style={{ backgroundColor: "#fff" }}
                ref={lastNameRef}
                value={contactDetail.customer_name
                  .slice(
                    contactDetail.customer_name.split(
                      " "
                    )[0].length
                  )
                  .trim()}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Location</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Location" required={true} />
            <div 
            style={{ marginTop: "7px" }}
            >
              <Dropdown
                option={locationsList}
                selectedValue={locationSelected}
                setSelectedValue={(value) => {
                  setlocationSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Project</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Project" required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={projectsList}
                selectedValue={projectSelected}
                setSelectedValue={(value) => {
                  setProjectSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>
                Next Follow Up Type
              </p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Next Follow Up Type" required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={nextFollowUpList}
                selectedValue={nextFollowSelected}
                setSelectedValue={(value) => {
                  setNextFollowSelected(value);
                }}
              />
            </div>
          </div>

          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Budget</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Budget" required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={budgetsList}
                selectedValue={budgetSelected}
                setSelectedValue={(value) => {
                  setBudgetSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Property Type</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Property Type" required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyTypeList}
                selectedValue={propertyTypeSelected}
                setSelectedValue={(value) => {
                  setPropertyTypeSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Property Stage</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Property Stage" required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyStageList}
                selectedValue={propertyStageSelected}
                setSelectedValue={(value) => {
                  setPropertyStageSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>
                Next Follow Up Date & Time
              </p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Next Follow Up Date & Time" required={true} />
            <div>
              <input
                type="datetime-local"
                placeholder="Enter Date"
                ref={dateRef}
                className={styles.dateInput}
              />
            </div>
          </div>

          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Note</p>
              <p className={styles.two}></p>
            </div> */}
            {/* <Label label="Note" required={false} />
            <div>
              <TextInput
                title={"Enter Note"}
                style={{ backgroundColor: "#fff" }}
                ref={noteRef}
              ></TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>
                Property Sub Type
              </p>
              <p className={styles.two}></p>
            </div> */}
            {/* <Label label="Property Sub Type" required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertySubType}
                selectedValue={propertySubTypeSelected}
                setSelectedValue={(value) => {
                  setPropertySubTypeSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Lead Source</p>
              <p className={styles.two}>*</p>
            </div> */}
            {/* <Label label="Lead Source" required={true} />
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={leadSourceList}
                selectedValue={leadSourceSelected}
                setSelectedValue={(value) => {
                  setLeadSourceSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}> */}
            {/* <div className={styles.title}>
              <p className={styles.one}>Alternate No.</p>
              <p className={styles.two}></p>
            </div> */}
            {/* <Label label="Alternate No." required={false} />
            <div>
              <TextInput
                title={"Enter Alternate Number"}
                style={{ backgroundColor: "#fff" }}
                ref={alternateRef}
                value={contactDetail.alternate_no}
                length={true}
              ></TextInput>
            </div>
          </div>
        </div>

        <button
          className={styles.apply}
          style={{ marginTop: "2rem" }}
          onClick={onSubmit}
        >
          Save
        </button> */}
      {/* </Modal> */}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    conatctHeader:state.contactHeaderList.data,
  };
};
export default connect(mapStateToProps)(
  InterestedLeadModal
);
