import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar } from "@mui/material";
import {
    contactResources,
} from "../../Services/contacts";
import './booking.style.css';
import { FileUpload } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone'
import { green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { uploadAttachment } from "../../Services/booking";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { showSnackbarAction } from "../../Redux/actions";
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../Components/theme";

const sourceFund = ["Self Funding", "Self Loan Process"];

const CssTextField = styled(TextField)({
    fontSize: "0.8rem"
});
const arrPaymentPlan = [
    {
        value: 'CLP',
        label: 'CLP'
    },
    {
        value: 'DP',
        label: 'DP'
    },
    {
        value: 'TLP',
        label: 'TLP'
    },
    {
        value: 'Others',
        label: 'Others'
    },
    {
        value: 'Subvention 10:90',
        label: 'Subvention 10:90'
    },
    {
        value: '50:25:25',
        label: '50:25:25'
    },
    {
        value: '10:40:50',
        label: '10:40:50'
    },
    {
        value: '50:50',
        label: '50:50'
    },
    {
        value: '80:20',
        label: '80:20'
    },
    {
        value: '30:30:30:10',
        label: '30:30:30:10'
    },
    {
        value: '70:30',
        label: '70:30'
    },
    {
        value: '9:16:25:25:20:5',
        label: '9:16:25:25:20:5'
    },
    {
        value: '25:25:25:25',
        label: '25:25:25:25'
    },
    {
        value: 'Special Plan (25:10:10:10:25:20)',
        label: 'Special Plan (25:10:10:10:25:20)'
    },
    {
        value: 'DP (30:70)',
        label: 'DP (30:70)'
    },
];
const arrBSPDiscountingType = [
    {
        value: 'On Form Developer',
        label: 'On Form Developer'
    },
    {
        value: 'On Form Director',
        label: 'On Form Director'
    },
    {
        value: 'Debit Note Developer',
        label: 'Debit Note Developer'
    },
    {
        value: 'Debit Note Organization',
        label: 'Debit Note Organization'
    },
    {
        value: 'Debit Note Developers',
        label: 'Debit Note Developers'
    }
];
type props = {
    formikProps: any;
    theme:any;
};
const EditPropertyDetailsBSP: FunctionComponent<props> = ({ formikProps,theme }) => {
  // # New start
  const [isBspDiscountFocused, setIsBspDiscountFocused] = useState(false);
  const [isBspDiscountAmountFocused, setIsBspDiscountAmountFocused] = useState(false);
    const { bsp,bsp_discount_amount,bsp_discount, ...obj } = formikProps.values.property_details_BSP;
    useEffect(() => {
        if (bsp != "") {
          if (bsp_discount_amount != "" && isBspDiscountAmountFocused){
            const bSPDiscount = bsp_discount_amount / bsp * 100;
            const bspAfterDiscount = bsp - bsp_discount_amount;
            formikProps.setFieldValue("property_details_BSP.bsp_discount", bSPDiscount.toFixed(2));
            formikProps.setFieldValue("property_details_BSP.net_bsp_after_discount", bspAfterDiscount);
          }
          if (bsp_discount_amount === ""){
          const bSPDiscount = 0;
          const bspAfterDiscount = bsp - 0;
          formikProps.setFieldValue("property_details_BSP.bsp_discount", bSPDiscount.toFixed(2));
          formikProps.setFieldValue("property_details_BSP.net_bsp_after_discount", bspAfterDiscount);
          }
      }
    }, [bsp,bsp_discount_amount]);
// # New start
    useEffect(()=>{
      if (bsp_discount != ""  && isBspDiscountFocused){
        const bsp_discount_amount = bsp_discount /100 *bsp;
        const bspAfterDiscount = bsp - (bsp_discount /100 *bsp);
        formikProps.setFieldValue("property_details_BSP.bsp_discount_amount", bsp_discount_amount.toFixed(2));
        formikProps.setFieldValue("property_details_BSP.net_bsp_after_discount", bspAfterDiscount);
        }
        if (bsp_discount === "" ){
          const bsp_discount_amount = 0;
          const bspAfterDiscount = bsp - 0;
          formikProps.setFieldValue("property_details_BSP.bsp_discount_amount", bsp_discount_amount.toFixed(2));
          formikProps.setFieldValue("property_details_BSP.net_bsp_after_discount", bspAfterDiscount);
          }
    },[bsp,bsp_discount])
// # New start

    const useHelperTextStyles = makeStyles(() => ({
        inputRoot: {
          // "&$disabled": {
          //   color: "green"
          // }
        },
        disabled: {
        },
        root: {
          // backgroundColor:"#fff",
          // color: "red",
          // backgroundColor:props.theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark,
          // margin:0,
          // padding:0
          // "& .MuiInputBase-root.Mui-disabled": {
          //   color: "white" // (default alpha is 0.38)
          // },
          "& .MuiFormLabel-root": {
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiFormLabel-root.Mui-disabled": {
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiSelect-select":{
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiSelect-select.Mui-disabled":{
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiInputBase-input":{
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiInputBase-input.Mui-disabled":{
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            "-webkit-text-fill-color":  `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiOutlinedInput-input":{
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiOutlinedInput-input.Mui-disabled":{
            color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiSvgIcon-root":{
              color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          },
          "& .MuiSvgIcon-root.Mui-disabled":{
              color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
          }
        },
        notchedOutline:{
          borderWidth: "1px",
          borderColor: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark} !important`,
        },
        preview: {
          color:"black"
        },
        dropzone:{
          color:"black"
        },
        formHelperText:{
        },
      }));
      const helperTextStyles = useHelperTextStyles();
  
    return (
        <>
            <div style={{
                border: "solid 1px #AEAEAE",
                boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                borderRadius: "10px",
                padding: "10px",
            }}>
                <Grid container className="booking_container" spacing={2} style={{ padding: "30px" }} >
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="Tower/Block"
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.tower_block')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="Floor"
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.floor')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="Payment Plan"
                            select
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.payment_plan')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrPaymentPlan.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="BSP"
                            required
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.bsp')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="BSP Discounting Type"
                            select
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.bsp_discounting_type')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrBSPDiscountingType.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="BSP Discount Amount"
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.bsp_discount_amount')}
                            // # New start
                            onBlur={() => setIsBspDiscountAmountFocused(false)}
                            onFocus={() => setIsBspDiscountAmountFocused(true)}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="BSP Discount %"
                            size="small"
                            // disabled
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.bsp_discount')}
                            className={helperTextStyles.root}
                            // # New start
                            onBlur={() => setIsBspDiscountFocused(false)}
                            onFocus={() =>  setIsBspDiscountFocused(true)}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label="Net BSP After Discount"
                            size="small"
                            disabled
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('property_details_BSP.net_bsp_after_discount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme:state.theme.isLightMode
    };
};
export default connect(mapStateToProps)(EditPropertyDetailsBSP);