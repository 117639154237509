import React, { useRef, FunctionComponent, useState, useEffect } from "react";
import styles from "./AddOrganization.module.css";
import TextInput from "../../Components/TextInput/TextInput";

import { autoFacebookCreateApi, createOrganization } from "../../Services/organizations";

import { emailValidate, nameValidate, noValidate, phoneValidate, pincodeValidate } from "../../Values/validatorsnew";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { countryData, url } from "../../Values/constants";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import axios from "axios";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { fetchLicensesCost } from "../../Services/resources";

type props = {
  history: any;
  theme: any;
};
const AddOrganization: FunctionComponent<props> = ({ history, theme }) => {
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [state, setState] = useState<object>({});
  const [industry, setIndustry] = useState<object>({});
  const [booleanSendEmail, setBooleanSendEmail] = useState(false);
  const [pwd, setPwd] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [phoneCode, setPhoneCode] = useState();
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [country, setCountry] = useState<object>({});
  const [orgId, setOrgId] = useState();
  const [authId, setAuthId] = useState();
  const [cost, setCost] = useState<any>("");
  const [noEmp, setNoEmp] = useState<any>("");
  const [costLicensesData, setCostLicensesData] = useState<any[] | undefined>(undefined);

  const [cities, setCities] = useState<object>({});
  const organizationNameRef: any = useRef();
  const mobileNumberRef: any = useRef();
  const emailRef: any = useRef();
  const cityRef: any = useRef();
  const addressRef: any = useRef();
  const pincodeRef: any = useRef();
  const adminFirstNameRef: any = useRef();
  const adminLastNameRef: any = useRef();
  const adminContactNumberRef: any = useRef();
  const adminEmailRef: any = useRef();
  const employeesRef: any = useRef();
  const [phoneCountryCode, setPhoneCountryCode] = useState('in');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [finalPhoneNumber, setFinalPhoneNumber] = useState('');
  const [adminPhoneCountryCode, setAdminPhoneCountryCode] = useState('in');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [adminFinalPhoneNumber, setAdminFinalPhoneNumber] = useState('');
  const handlePhoneChange = (value: string,country:CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setFinalPhoneNumber(value);
    }
   setPhoneCountryCode(country?.dialCode);
    setPhoneNumber(value)
  }; 
  const handleAdminPhoneChange = (value: string,country:CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setAdminFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setAdminFinalPhoneNumber(value);
    }
    setAdminPhoneCountryCode(country?.dialCode);
    setAdminPhoneNumber(value)
  }; 
  // useEffect(() => {
  //   const code = "+"+phoneCountryCode; // Specify the code you want to find
  //   const result = countryList.find(item => item.startsWith(code));
  //   if (result) {
  //     setCountry(result);
  //   }   
  // }, [phoneCountryCode]);
  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
  }, []);

  useEffect(() => {
    fetchLicensesCost((data) => setCostLicensesData(data));
  }, [])

useEffect(() => {
  setCost(costLicensesData?.[0]?.licensesCost);
  setNoEmp(costLicensesData?.[0]?.no_employees);
}, [costLicensesData]);

  const create = (e: any) => {
    e.preventDefault();
    if (organizationNameRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Organization Name Required!!",
          "error"
        )
      );
      return;
    }
    else if (finalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (emailRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Email Required!!",
          "error"
        )
      );
      return;
    }
    
    else if (addressRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Address Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(country).length === 0) {
      dispatcher(
        showSnackbarAction(
          "Country Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(state).length === 0) {
      dispatcher(
        showSnackbarAction(
          "State Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(cities).length === 0) {
      dispatcher(
        showSnackbarAction(
          "City Required!!",
          "error"
        )
      );
      return;
    }
    else if (pincodeRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Pincode Required!!",
          "error"
        )
      );
      return;
    }
    
    else if (adminFirstNameRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Name Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminLastNameRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Last Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminFinalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminEmailRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin Email Required!!",
          "error"
        )
      );
      return;
    }
    else if (employeesRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "No of Employees Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(industry).length === 0) {
      dispatcher(
        showSnackbarAction(
          "Industry Id Required!!",
          "error"
        )
      );
      return;
    }
    setLoad(true);
    createOrganization(
      organizationNameRef.current?.value,
      finalPhoneNumber,
      phoneCountryCode=="in"?91:parseInt(phoneCountryCode),
      emailRef.current?.value,
      addressRef.current?.value,
      (country as any).label,
      (country as any).value,
      (state as any).label,
      (state as any).value,
      (cities as any).label,
      (cities as any).value,
      pincodeRef.current?.value,
      adminFirstNameRef.current?.value,
      adminLastNameRef.current?.value,
      adminFinalPhoneNumber,
      adminPhoneCountryCode=="in"?91:parseInt(adminPhoneCountryCode),
      adminEmailRef.current?.value,
      employeesRef.current?.value,
      history,
      dispatcher,
      window,
      (data: boolean) => setLoad(data),
      (data: boolean) => setBooleanSendEmail(data),
      (data: any) => setPwd(data),
      (industry as any).value,
      (data: any) => setOrgId(data),
      (data: any) => setAuthId(data),
      cost,
      noEmp
    );
  };

  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: "165px", // Set your desired width
    }),
  };

  useEffect(() => {
    if (booleanSendEmail) {
      sendResetPasswordEmail();
      createOrganizationDb();
      autoFacebookCreateApi(
        orgId,
       "Facebook",
       (country as any).value,
       adminEmailRef.current?.value?.toLowerCase(),
       adminPhoneCountryCode == "in" ? 91 : parseInt(adminPhoneCountryCode),
       adminFinalPhoneNumber,
       adminFirstNameRef.current?.value,
       adminLastNameRef.current?.value,
     );
    }
  }, [booleanSendEmail]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryRes = await axios.post(
          `${url}/country/countries`);
        // handle response
        setCountryList(countryRes.data);
      } catch (error) {
        // handle error
      }
    };
    fetchData();
  }, [])

  useEffect(() => {
    if (Object.keys(country).length !== 0) {
      const fetchData = async () => {
        try {
          const statesRes = await axios.post(
            `${url}/country/states`, { iso3: (country as any).value});
          // handle response
          setStatesList(statesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();

    }
  }, [country]);

  // useEffect(() => {
  //   if (country !== "") {
  //     const phonecode = countryList.find((list: any) => list?.iso3 === country);
  //     setPhoneCode(phonecode);
  //   }
  // }, [country]);

  useEffect(() => {
    if (Object.keys(country).length !== 0 && Object.keys(state).length !== 0) {
      const fetchData = async () => {
        try {
          const citiesRes = await axios.post(
            `${url}/country/cities`, { iso3: (country as any).value, state_code: (state as any).value });
          // handle response
          setCitiesList(citiesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();
    }
  }, [country, state])

  const createOrganizationDb = async () => {
    const bodyData = {
      organization_id: orgId,
      address: addressRef.current?.value,
      admin_contact_number: adminFinalPhoneNumber,
      admin_email_id: adminEmailRef.current?.value.toLowerCase(),
      admin_first_name: adminFirstNameRef.current?.value,
      admin_last_name: adminLastNameRef.current?.value,
      auth_id: authId,
      city: (cities as any).label,
      country: (country as any).label,
      created_at: "",
      designations: [],
      email_id: emailRef.current?.value.toLowerCase(),
      mobile_number: finalPhoneNumber,
      no_of_employees: employeesRef.current?.value,
      organization_name: organizationNameRef.current?.value,
      state: (state as any).label,
      status: "ACTIVE",
      teams: [],
      industry_id: (industry as any).value,
      payment_status:true,
      grace_period:7,
      showData:true,
      trial_period: true,
    }
    const createOrg = await axios.post(
      url + '/organizations/create', bodyData
    );
   
  }
  const sendResetPasswordEmail = async () => {
    let email = adminEmailRef.current?.value.toLowerCase()
    let firstName = adminFirstNameRef.current?.value;
    let lastName = adminLastNameRef.current?.value;
    if (email !== "") {
      try {
        if (email) {
          setLoad(true);
          const resetPasswordEmailApiData = {
            "template_name": "Password_Reset_Notification",
            "replaceable_params": [
              { "%#username#%": `${firstName} ${lastName}` },
              { "%#password#%": pwd }
            ],
            "email": email
          }
          await axios.post(
            url + '/email/sendEmail',
            resetPasswordEmailApiData
          );
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password reset information have been sent on you registered email id",
              "success"
            )
          );
          history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  }

  const choice = () => {
    let optionArr = statesList?.map((list: { state_code: any, name: any }) => ({
      value: list.state_code,
      label: list.name
    }));
    return optionArr;
  };

  const countryChoice = () => {
    let optionArr = countryList?.map((list: { iso3: any, name: any, phone_code: any }) => ({
      value: list.iso3,
      label: `${list.name}`
    }));
    return optionArr;
  };

  const citiesChoice = () => {
    let optionArr = citiesList?.map((list: { name: any }) => ({
      value: list.name,
      label: list.name
    }));
    return optionArr;
  };
  useEffect(() => {
    getIndustry();
  }, []);

  const getIndustry = async () => {
    const industryRes = await axios.post(
      url + '/headers/getAllIndustries',
      {
        "status": "Launched"
      }
    );
    setIndustryList(industryRes.data);
  }
  const choiceIndustry = () => {
    let optionArr = industryList?.map((list: { industry_id: any, industry_name: any }) => ({
      value: list.industry_id,
      label: list.industry_name
    }));
    return optionArr;
  }
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "6px" };
  const noValidateEmp = (empNo: string) => {
    console.log("phone", Number(empNo));
    const re = /^[0-9]*$/;
    const valid = re.test(empNo);
    if (valid) {
      if (Number(empNo) > noEmp) {
        return `Trial period allows ${noEmp} licences.`
      }
      else {
        return "";
      }
    }
    else {
      return "Allow only Number.";
    }
  };

  return (
    
    //Running Component
    <div className={styles.parent} style={{...containerStyles,marginTop:'4%'}}>
      {load === true && <Loading />}
      <div
        // className={styles.headerView}
        className="d-flex flex-row w-100 px-4 pt-4"
      >
        <h5
        // className={styles.heading}
        > Add Organization Details</h5>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/")}
        />
      </div>
      
      <div
        // className={styles.child}
        className="w-100 d-flex flex-column gap-2 px-4"
      >
        <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="Organization Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Name"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px"}}
                  ref={organizationNameRef}
                ></TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Mobile Number" required={true} />
              <div className={styles.inputContainer}>
              <PhoneInput
                  country={phoneCountryCode}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputStyle={{ width: '165px',height:"45px"}}
                  containerStyle={{ height: "45px",color:"rgb(54, 56, 83" }}
                />
                {/* <TextInput
                  title={"Enter Number"}
                  style={{ backgroundColor: "#fff" }}
                  ref={mobileNumberRef}
                  validator={phoneValidate}
                  length={true}
                /> */}
              </div>
            </div>

            <div className={styles.divide}>
              <Label label="Email Id" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Email"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px" }}
                  ref={emailRef}
                  validator={emailValidate}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="Address" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Address"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px" }}
                  ref={addressRef}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Country" required={true} />
              <div style={{ color: "#000",width: "140px", marginRight: "13px" }} >
                <Select
                  options={countryChoice()}
                  onChange={(data) => {
                    if (data != null) {
                      setCountry(data);
                    }
                  }}
                  styles={customStyles}
                  isSearchable={true}
                  // style={{...containerStyles}}
                />
              </div>
            </div>

            <div className={styles.divide}>
              <Label label="State" required={true} />
              <div style={{ ...selectInputContainerStyles }} >
                <Select
                  options={choice()}
                  onChange={(data) => {
                    if (data != null) {
                      setState(data);
                    }
                  }}
                  styles={customStyles}
                  isSearchable={true}
                  isDisabled={Object.keys(country).length === 0 ?true:false}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="City" required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Select
                  // className={styles.inputText1}
                  placeholder="City *"
                  options={citiesChoice()}
                  onChange={(data) => {
                    if (data != null) {
                      setCities(data);
                    }
                  }}
                  styles={customStyles}
                  isSearchable={true}
                  isDisabled={Object.keys(state).length === 0 ?true:false}
                />
              </div>
            </div>

            <div className={styles.divide}>
              <Label label="Pin Code" required={true} />
              <div className={styles.inputContainer} style={{marginRight:'10px'}}>
                <TextInput
                  title={"Enter Pincode"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px"}}
                  ref={pincodeRef}
                  validator={pincodeValidate}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Admin First Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter First Name"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px" }}
                  ref={adminFirstNameRef}
                  validator={nameValidate}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>

            <div className={styles.divide}>
              <Label label="Admin Last Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Last Name"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px" }}
                  ref={adminLastNameRef}
                  validator={nameValidate}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Admin Contact No" required={true} />
              <div className={styles.inputContainer}>
              <PhoneInput
                  country={adminPhoneCountryCode}
                  value={adminPhoneNumber}
                  onChange={handleAdminPhoneChange}
                  inputStyle={{ width: '165px',height:"45px"}}
                  containerStyle={{ height: "45px",color:"rgb(54, 56, 83" }}
                />
                {/* <TextInput
                  title={"Enter Number"}
                  style={{ backgroundColor: "#fff" }}
                  ref={adminContactNumberRef}
                  validator={phoneValidate}
                  length={true}
                /> */}
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Admin Email Id" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Email"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px" }}
                  ref={adminEmailRef}
                  validator={emailValidate}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="Number of Employees" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={"Enter Number Of Employees"}
                  style={{ backgroundColor: "#fff",height:"45px",width:"165px" }}
                  ref={employeesRef}
                  validator={noValidateEmp}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Industry Id " required={true} />
              <div className={styles.inputContainer} style={{...selectInputContainerStyles}}>
                <Select
                  className={styles.inputText}
                  placeholder="Industry Id *"
                  options={choiceIndustry()}
                  onChange={(data) => {
                    if (data != null) { // Check if the value is not null or undefined
                      setIndustry(data); // Set the industry state to the selected value
                    }
                  }}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
            </div>
          </div>
          <button className={styles.button}   style={{backgroundColor:theme?"#272944":"rgb(54, 56, 83)"}} onClick={(e: any) => create(e)}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(AddOrganization);
