import Modal from "react-modal";
import React, {
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import styles from "./ChangeOwnerModal.module.css";
import { IoIosClose } from "react-icons/io";
import {
  changeDateForRoute,
  changeOwner,
} from "../../../Services/contacts";
import {
  setRefreshContacts,
  showSnackbarAction,
} from "../../../Redux/actions";
import Loading from "../../Loading/Loading";
import { useHistory } from "react-router";
import { connect, useDispatch } from 'react-redux';
import Dropdown from "../../../Components/DropDown/Dropdown";
import { functions } from "../../../Firebase";
import moment from "moment";
import { fetchTransferReason } from "../../../Services/resources";
import { datesField } from "../../../Values/tables";
import { themeColors } from "../../theme";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";

type props = {
  open: boolean;
  close: () => void;
  usersList: any;
  rowsSelected: any[];
  organization_id: string;
  clearSelectedRowsData?: () => void;
  freshFilterValue: string;
  theme: any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const OwnerModal: FunctionComponent<props> = ({
  open,
  close,
  usersList,
  rowsSelected,
  clearSelectedRowsData,
  organization_id,
  freshFilterValue,
  theme
}) => {
  const [userIndex, setUserIndex] = useState(0);
  const [load, setLoad] = useState(false);
  const dispatcher = useDispatch();
  const [buttonType, setButtonType] = useState<any>("Next");
  const [fresh, setFresh] = useState(false);
  const [tasks, setTasks] = useState(false);
  const [notes, setNotes] = useState(false);
  const [attachments, setAttachments] = useState(false);
  const [contactDetails, setContactDetails] =
    useState(false);
  const [reasonSelected, setReasonSelected] = useState<any>(
    {
      label: "Select",
      value: "Select",
    }
  );
  const [ownerSelected, setOwnerSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadTypeList, setleadTypeList] =
    useState<any[]>(["Data", "Leads"]);
  const [leadTypeSelected, setLeadTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [ownerList, setOwnerList] = useState<any[]>([]);
  const [leadsToTransfer, setLeadsToTransfer] = useState<
    any[]
  >([]);
  const history = useHistory();

  useEffect(() => {
    if (freshFilterValue === "FRESH" || fresh === true) {
      setFresh(true);
      setContactDetails(true);
    }
    else {
      setFresh(false);

      setContactDetails(false);
    }
  }, [fresh, freshFilterValue]);

  useEffect(() => {
    let data: any = [];
    usersList.map((item: any, index: number) =>
      data.push(
        `${item.user_first_name} ${item.user_last_name} (${item.user_email})`
      )
    );

    setOwnerList(data);
  }, [usersList]);

  useEffect(() => {
    if (ownerSelected.value !== "") {
      let owner: any;
      usersList.map((item: any, index: number) => {
        owner = `${item.user_first_name} ${item.user_last_name} (${item.user_email})`;
        if (String(owner) === String(ownerSelected.value)) {
          setUserIndex(index + 1);
        }
      });
    }
  }, [ownerSelected]);

  const Next = () => {
    setButtonType("Change Owner");
    return {
      OwnerModal,
    };
  };

  const [reasons, setReasons] = useState<any[]>([]);

  useEffect(() => {
    const unsub = fetchTransferReason(
      organization_id,
      (data) => setReasons(data)
    );

    return () => {
      unsub();
    };
  }, [organization_id]);

  useEffect(() => {
    let rowsList: any[] = [];

    rowsSelected.forEach((row) => {
      let newRow = { ...row };
      datesField.forEach((feild) => {
        if (
          newRow[feild] &&
          newRow[feild].toDate !== undefined
        ) {
          newRow[feild] = moment(
            newRow[feild].toDate()
          ).toString();
        }
      });
      rowsList.push(newRow);
    });
    setLeadsToTransfer(rowsList);
  }, [rowsSelected]);
  const submit = async () => {
    if (userIndex === 0) {
      dispatcher(
        showSnackbarAction("Select a User !!", "error")
      );
    } else if (
      reasonSelected.value === "" ||
      reasonSelected.value === "Select"
    ) {
      dispatcher(
        showSnackbarAction(
          "Please Select A Reason!!",
          "error"
        )
      );
    }
    else if (
      leadTypeSelected.value === "" ||
      leadTypeSelected.value === "Select"
    ) {
      dispatcher(
        showSnackbarAction(
          "Please Select Lead Type!!",
          "error"
        )
      );
    }  
    else {
      setLoad(true);
      functions
        .httpsCallable("transferLead")({
          leadsData: leadsToTransfer,
          options: {
            fresh: fresh,
            notes: notes,
            attachments: attachments,
            task: tasks,
            contactDetails: contactDetails,
          },
          owner: {
            email:
              usersList[
                userIndex - 1
              ].user_email.toLowerCase(),
            uid: usersList[userIndex - 1].uid,
            organization_id:
              usersList[userIndex - 1].organization_id,
          },
          reason: reasonSelected.value,
          leadType: leadTypeSelected.value,
        })
        .then(() => {
          dispatcher(
            showSnackbarAction("Owner Updated!!", "success")
          );
          setLoad(false);
          close();
          clearSelectedRowsData && clearSelectedRowsData();
          setTimeout(() => {
            dispatcher(setRefreshContacts(true));
          }, 2500);
          window.location.reload();
        })
        .catch((error) => {
          console.log("owner change error:", error);
        });
    }
  };

  const customStyles = {
    content: {
      backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
    },
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

  return (
    <>
      {load && <Loading />}
      {/* {buttonType == "Change Owner" && (
        <Modal
          isOpen={open}
          className={styles.parent}
          overlayClassName={styles.overlay}
          onRequestClose={close}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          style={customStyles}
        >
          <div style={{display: 'flex',alignItems: 'center',justifyContent: 'space-between',marginTop:"1rem"}}>
          <h6 
          // className={styles.heading}
          >
            Select A Reason To Transfer
          </h6>
          <div 
            // className={styles.icon}
           onClick={close}>
            <IoIosClose size={35} color={"#808080"} />
          </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Dropdown
              option={reasons}
              selectedValue={reasonSelected}
              setSelectedValue={(value) => {
                setReasonSelected(value);
              }}
            />
          </div>

          <div
            className={styles.button}
            style={{ marginTop: "100px" }}
          >
            <button
              className={styles.cancel}
              style={{ marginRight: "80px" }}
              onClick={close}
            >
              Cancel
            </button>

            <button
              className={styles.change}
              onClick={submit}
            >
              Change Owner
            </button>
          </div>
        </Modal>
      )}

      {buttonType == "Next" && (
        <Modal
          isOpen={open}
          className={styles.parent}
          overlayClassName={styles.overlay}
          onRequestClose={close}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          style={customStyles}
        >
          <div style={{display: 'flex',alignItems: 'center',justifyContent: 'space-between',marginTop:"1rem"}}>
              <h6 
              // className={styles.heading}
              >Change Owner</h6>
                <div className={styles.icon} onClick={close}>
                  <IoIosClose size={35} color={"#808080"} />
                </div>
          </div>
          <div className={styles.nameBox}>
            <p
              // className={styles.name}
              style={{ marginRight: "10px" }}
            >
              Choose Owner: 
            </p>
            <div 
            style={{ flex:1 }}
            >
              <Dropdown
                option={ownerList}
                selectedValue={ownerSelected}
                setSelectedValue={(value) => {
                  setOwnerSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.nameBox}>
            <div 
            // style={{ margin: "10px 0" }}
            >
             <p> Do you want to transfer lead(s) As Fresh?</p>
            </div>
            <input
              onChange={(e) => {
                setFresh(e.target.checked);
                if (e.target.checked === true) {
                  setTasks(false);
                }
              }}
              type="checkbox"
              style={{
                right: "20px",
                position: "absolute",
              }}
              name="check"
              checked={fresh}
              disabled={freshFilterValue ? true : false}
            />
          </div>

          <div style={{marginTop: "1.5rem"}}>
          <h6 
          // className={styles.optionHeading}
          >
            Select Options
          </h6>
          <div 
          // style={{ width: "40%" }}
          >
            {fresh === false && (
              <div className={styles.options}>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setTasks(e.target.checked);
                  }}
                />
                <p className={styles.optionText}>
                  Open Tasks
                </p>
              </div>
            )}

            <div className={styles.options}>
              <input
                type="checkbox"
                onChange={(e) => {
                  setNotes(e.target.checked);
                }}
               
              />
              <p className={styles.optionText}>Notes</p>
            </div>
            <div className={styles.options}>
              <input
                type="checkbox"
                onChange={(e) => {
                  setAttachments(e.target.checked);
                }}
               
              />
              <p className={styles.optionText}>
                Attachments
              </p>
            </div>
            {fresh === true && (
              <div className={styles.options}>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setContactDetails(e.target.checked);
                  }}
                  checked={contactDetails || fresh}
                  disabled={freshFilterValue ? true : false || fresh}
                />
                <p className={styles.optionText}>
                  Contact Details
                </p>
              </div>
            )}
          </div>
          </div>
          <div className={styles.button}>
            <button
              className={styles.cancel}
              onClick={close}
            >
              Cancel
            </button>
            <button
              className={styles.change}
              onClick={Next}
            >
              Next
            </button>
          </div>
        </Modal>
      )} */}
      <Backdrop>
        {buttonType == "Change Owner" && (
          <motion.div
            key="modal"
            className="card p-4 shadow rounded"
            style={{
              position: "absolute",
              ...containerStyles
            }}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: "1rem" }}>
              <h6
              // className={styles.heading}
              >
                Select A Reason To Transfer
              </h6>
              <div
                // className={styles.icon}
                onClick={close}>
                <IoIosClose size={35} color={"#808080"} />
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Dropdown
                option={reasons}
                selectedValue={reasonSelected}
                setSelectedValue={(value) => {
                  setReasonSelected(value);
                }}
              />
            </div>
            <h6
              // className={styles.heading}
              style={{ marginTop: "1rem" }}
            >
              Please Select Lead Type
            </h6>
            <div style={{ marginTop: "1rem" }}>
              <Dropdown
                option={leadTypeList}
                selectedValue={leadTypeSelected}
                setSelectedValue={(value) => {
                  setLeadTypeSelected(value);
                }}
              />
            </div>

            <div
              className={styles.button}
            >
              <button
                className={styles.cancel}
                style={{ marginRight: "80px" }}
                onClick={close}
              >
                Cancel
              </button>

              <button
                className={styles.change}
                onClick={submit}
              >
                Change Owner
              </button>
            </div>
          </motion.div>
        )}

        {buttonType == "Next" && (
          <motion.div
            key="modal"
            className="card p-4 shadow rounded"
            style={{
              position: "absolute",
              ...containerStyles
            }}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: "1rem" }}>
              <h6
              // className={styles.heading}
              >Change Owner</h6>
              <div className={styles.icon} onClick={close}>
                <IoIosClose size={35} color={"#808080"} />
              </div>
            </div>
            <div className={styles.nameBox}>
              <p
                // className={styles.name}
                style={{ marginRight: "10px" }}
              >
                Choose Owner:
              </p>
              <div
                style={{ flex: 1 }}
              >
                <Dropdown
                  option={ownerList}
                  selectedValue={ownerSelected}
                  setSelectedValue={(value) => {
                    setOwnerSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.nameBox}>
              <div
              // style={{ margin: "10px 0" }}
              >
                <p> Do you want to transfer lead(s) As Fresh?</p>
              </div>
              <input
                onChange={(e) => {
                  setFresh(e.target.checked);
                  if (e.target.checked === true) {
                    setTasks(false);
                  }
                }}
                type="checkbox"
                style={{
                  right: "20px",
                  position: "absolute",
                }}
                name="check"
                checked={fresh}
                disabled={freshFilterValue ? true : false}
              />
            </div>

            <div style={{ marginTop: "1.5rem" }}>
              <h6
              // className={styles.optionHeading}
              >
                Select Options
              </h6>
              <div
              // style={{ width: "40%" }}
              >
                {fresh === false && (
                  <div className={styles.options}>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setTasks(e.target.checked);
                      }}
                    />
                    <p className={styles.optionText}>
                      Open Tasks
                    </p>
                  </div>
                )}

                <div className={styles.options}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setNotes(e.target.checked);
                    }}

                  />
                  <p className={styles.optionText}>Notes</p>
                </div>
                <div className={styles.options}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setAttachments(e.target.checked);
                    }}

                  />
                  <p className={styles.optionText}>
                    Attachments
                  </p>
                </div>
                {fresh === true && (
                  <div className={styles.options}>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setContactDetails(e.target.checked);
                      }}
                      checked={contactDetails || fresh}
                    // disabled={freshFilterValue ? true : false || fresh}
                    />
                    <p className={styles.optionText}>
                      Contact Details
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.button}>
              <button
                className={styles.cancel}
                onClick={close}
              >
                Cancel
              </button>
              <button
                className={styles.change}
                onClick={Next}
              >
                Next
              </button>
            </div>
          </motion.div>
        )}
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(OwnerModal);

