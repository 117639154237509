import { combineReducers } from "redux";
import branchListReducer from "./branchReducer";
import contactHeaderReducer from "./contactHeaderReducer";
import branchUsersReducer from "./branchUsersReducer";
import clearFilterReducer from "./clearFilterReducer";
import contactsReducer from "./contactsReducer";
import filterObjectReducer from "./filterObjectReducer";
import filterReducer from "./filterReducer";
import filterSortReducer from "./filterSortReducer";
import organizationReducer from "./organizationReducer";
import organizationUserReducer from "./organizationUserReducer";
import progressReducer from "./progressReducer";
import refreshReducer from "./refreshReducer";
import searchItemReducer from "./searchItemReducer";
import snackbarReducer from "./snackbarReducer";
import stageReducer from "./stageReducer";
import teamLeadUsersReducer from "./teamLeadUsersReducer";
import userReducer from "./userReducer";
import {organizationIdNameReducer} from "./organizationIdNameReducer";
import themeReducer from "./themeReducer";
import { interestedBudgetReportReducer, 
  feedbackReportReducer,
  interestedLocationReportReducer,
  interesteProjectReportReducer,
  interestedPropertyTypeReportReducer,
  interestedPropertyStageReportReducer,
  lostReasonReportReducer,
  notInterestedReportReducer,
  completedTaskReportReducer,
  pendingTaskReportReducer,
  overDueReportReducer,
  callBackReasonReportReducer,
  callLogsReportReducer,
} from './reportsReducer'
import tasksHeaderReducer from "./tasksHeaderReducer";
import projectHeaderReducer from "./projectHeaderReducer";
import callLogsHeadersReducer from "./callLogsHeadersReducer";
import apiHeadersReducer from "./apiHeadersReducer";
import {organizationCreateDateNameReducer} from "./organizationCreateDate"
import {organizationSelectOrganizationIdReducer} from './organizationSelectedId'
import facebookPagesReducer from "./facebookPagesReducer" 
import deleteLeadsReducer from './deleteLeadsReducer'
import analyticsPanelReducer from './AnalyticsPanel'


const allReducer = combineReducers({
  user: userReducer,
  snackbar: snackbarReducer,
  contacts: contactsReducer,
  organization: organizationReducer,
  filterObject: filterReducer,
  organizationUsers: organizationUserReducer,
  teamLeadUsers: teamLeadUsersReducer,
  clearFilter: clearFilterReducer,
  progress: progressReducer,
  leadsStage: stageReducer,
  branchList: branchListReducer,
  contactHeaderList:contactHeaderReducer,
  taskHeaderList:tasksHeaderReducer,
  projectHeaderList:projectHeaderReducer,
  calllogsHeaderList:callLogsHeadersReducer,
  apiHeaderList:apiHeadersReducer,
  branchUsers: branchUsersReducer,
  filter: filterObjectReducer,
  filterSort: filterSortReducer,
  refresh: refreshReducer,
  searchItem: searchItemReducer,
  feedbackReportData: feedbackReportReducer,
  interestedBudgetdata: interestedBudgetReportReducer,
  interestedLocationdata: interestedLocationReportReducer,
  interesteProjectData:interesteProjectReportReducer,
  interestedPropertyTypeData:interestedPropertyTypeReportReducer,
  interestedPropertyStageData:interestedPropertyStageReportReducer,
  lostReasonReportData:lostReasonReportReducer,
  notInterestedReportData: notInterestedReportReducer,
  completedTaskReportData:completedTaskReportReducer,
  pendingTaskReportData:pendingTaskReportReducer,
  overDueReportData:overDueReportReducer,
  callBackReasonReportData:callBackReasonReportReducer,
  callLogReportData:callLogsReportReducer,
  theme: themeReducer,
  organizationIdNameData:organizationIdNameReducer,
  orgCreateDate:organizationCreateDateNameReducer,
  selectOrganizationId:organizationSelectOrganizationIdReducer,
  faceBookUserPagesData:facebookPagesReducer,
  deleteLeadsStatusData:deleteLeadsReducer,
  analyticsPanel:analyticsPanelReducer
});

export default allReducer;