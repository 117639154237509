import React, { useCallback } from "react";
import styles from "./SubscriptionAlert.module.css";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import { subscriptionDetailsData } from "../../Components/mockData";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { useHistory } from "react-router";

const SubscriptionAlert = ({ paymentStatus, theme, expirationDate, role }: any) => {
  const history = useHistory();
  return (
    <div style={{display:'flex',justifyContent:'center',width:'100%',padding:"3px"}}>
    <div className="d-flex flex-column align-items-center m-auto p-2 alert alert-danger" style={{zIndex:1000,width:"65%",height:"50px",position:'absolute',display: 'flex', alignItems: "center", justifyContent: 'center'}}>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
      {paymentStatus === false ? (
        <p className="text-center" style={{ marginLeft: "200px", marginTop: "4%" }}>
          Your Subscription has expired and all of your active users have been
          deactivated, To Enjoy Uninterrupted Services, Please renew your
          subscription
        </p>
      ) : (
        <p className="text-center">
          Your Subscription has expired and all of your active users will be
          automatically deactivated on {expirationDate}, To Enjoy Uninterrupted
          Services, Please renew your subscription
        </p>
      )}<br/>
      {role === "Admin" &&
      <div>
        <button
          onClick={() => {
            history.push("/paymentDetails");
          }}
          className={styles.button}
          style={{height:"35px"}}
        >
          Renew Now
        </button>
        </div>
      }
      </div>
    </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
    expirationDate: state.organization.expirationDate,
  };
};

export default connect(mapStateToProps)(SubscriptionAlert);
