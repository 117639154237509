import React, { FunctionComponent, useState, useEffect } from 'react';
import styles from './ProjectDetails.module.css';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { ImFilePdf } from 'react-icons/im';
import { useLocation } from 'react-router-dom';
import { getDateAndTime } from '../../Values/utils';
import ProjectDocumentModal from '../../Components/Modals/ProjectDocumentModal/ProjectDocumentModal';
import {
  deleteProjectBrochures,
  deleteProjectForms,
  deleteProjectImage,
  deleteProjectLayouts,
  deleteProjectPriceLists,
  deleteProjectVideo,
  fetchProjectDocuments,
  setProjectImage,
} from '../../Services/resources';
import { connect, useDispatch } from 'react-redux';
import { showSnackbarAction } from '../../Redux/actions';
import Loading from '../../Components/Loading/Loading';
import { MdDelete } from 'react-icons/md';
import { firestore } from '../../Firebase';
import { fetchProjects } from '../../Services/contacts';
import { themeColors } from '../../Components/theme';
import ProjectVideoModal from '../../Components/Modals/ProjectVideoModal/ProjectVideoModal';
import { HiVideoCamera } from 'react-icons/hi';
type props = { user: any, theme: any };
const ProjectDetails: FunctionComponent<props> = ({ user, theme }) => {
  const location: any = useLocation();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [vidModal, setVidModal] = useState(false);
  const [brochures, setBrochures] = useState<any[] | undefined>(undefined);
  const [priceLists, setPriceLists] = useState<any[] | undefined>(undefined);
  const [layouts, setLayouts] = useState<any[] | undefined>(undefined);
  const [forms, setForms] = useState<any[] | undefined>(undefined);
  const [images, setImages] = useState<any[] | undefined>(undefined);
  const [load, setLoad] = useState(false);
  const [attachmentsList, setAttachmentsList] = useState<
    any[] | undefined
  >(undefined);
  const [allProjectData, setAllProjectData] = useState<any[]>([]);
  const [projectsList, setProjectsList] = useState<any[] | undefined>(
    undefined
  );

  const dispatcher = useDispatch();
  useEffect(() => {
    if (location.state.detail) {
      fetchProjectDocuments(
        location.state.detail.project_id,
        (data) => setBrochures(data),
        (data) => setPriceLists(data),
        (data) => setLayouts(data),
        (data) => setForms(data),
        (data) => setAttachmentsList(data),
        (data) => setImages(data)
      );
    }
  }, []);

  const deleteImage = async (fileName: string, index: number) => {

    if (images) {
      await deleteProjectImage(
        'images',
        images,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deleteVideo = async (fileName: string, index: number) => {

    if (attachmentsList) {

      await deleteProjectVideo(
        'video',
        attachmentsList,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deleteBrochures = async (fileName: string, index: number) => {

    if (brochures) {
      await deleteProjectBrochures(
        'brochures',
        brochures,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deletePriceLists = async (fileName: string, index: number) => {

    if (priceLists) {
      await deleteProjectPriceLists(
        'priceLists',
        priceLists,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deleteLayouts = async (fileName: string, index: number) => {

    if (layouts) {
      await deleteProjectLayouts(
        'layouts',
        layouts,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deleteForms = async (fileName: string, index: number) => {

    if (forms) {
      await deleteProjectForms(
        'forms',
        forms,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };

  const upload = async (data: FileList | null) => {
    if (data && user.organization_id) {
      setLoad(true);

      await setProjectImage(
        user.organization_id,
        data[0],
        location.state.AllProjectdata,
        location.state.detail,
        location.state.detail.project_image ? true : false,
        dispatcher
      );
      setLoad(false);
      dispatcher(showSnackbarAction('Thumbnail Uploaded!!', 'success'));
    }
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }

  return (
    <>
      {load === true && <Loading />}
      <div className={styles.parent} style={{...containerStyles,marginTop:30}}>
        <div className={styles.child} style={containerStyles}>
          <div className={styles.documentContainer}>
            <h5
            // className={styles.heading}
            >Project Documents</h5>
            <div className={styles.docContainer} style={cardContainerStyles}>
              <div className={styles.addContainer}>
                <h6
                // className={styles.title}
                >Images</h6>
                <IoIosAddCircleOutline
                  color="#272944"
                  size="20"
                  onClick={() => {
                    setOpen(true);
                    setTitle('Project Image');
                    setType('images');
                  }}
                />
              </div>
              <div className={styles.allDocuments}>
                {images?.map((item, index) => (
                  <div className={styles.document}>
                    <ImFilePdf color="#808080" size="10" />
                    <p className={styles.name}>
                      {' '}
                      <a href={item.link}>{item.name}</a>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                      }}
                    >
                      <MdDelete
                        color={'#ff0000'}
                        style={{}}
                        size={16}
                        onClick={() => {
                          deleteImage(item.name, index);
                        }}
                      />
                    </div>
                  </div>
                ))}
                {images?.length === 0 && (
                  <p
                  // style={{ margin: '0px', fontSize: '12px' }}
                  >
                    No Document Added
                  </p>
                )}
              </div>
            </div>

            {/* Upload Video Work On project  */}
            <div className={styles.docContainer} style={cardContainerStyles}>
              <div className={styles.addContainer}>
                <h6
                // className={styles.title}
                >Upload Video</h6>
                <HiVideoCamera
                  size={15}
                  color="#272944"
                  onClick={() => {
                    setVidModal(true);
                    setTitle("Upload Video");
                  }}
                />
              </div>
              <div className={styles.allDocuments}>
                {attachmentsList?.map((item, index) => (
                  <div className={styles.document}>
                    <ImFilePdf color="#808080" size="10" />
                    <p className={styles.name}>
                      {' '}
                      <a href={item.link}>{item.name}</a>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                      }}
                    >
                      <MdDelete
                        color={'#ff0000'}
                        style={{}}
                        size={16}
                        onClick={() => {
                          deleteVideo(item.name, index);
                        }}
                      />
                    </div>
                  </div>
                ))}
                {attachmentsList?.length === 0 && (
                  <p
                  // style={{ margin: '0px', fontSize: '12px' }}
                  >
                    No Video Added
                  </p>
                )}
              </div>
            </div>
            {/* Upload Video Work On project  */}

            <div className={styles.docContainer} style={cardContainerStyles}>
              <div className={styles.addContainer}>
                <h6
                // className={styles.title}
                >Brochures</h6>
                <IoIosAddCircleOutline
                  color="#272944"
                  size="20"
                  onClick={() => {
                    setOpen(true);
                    setTitle('Brochures Document');
                    setType('brochures');
                  }}
                />
              </div>
              <div className={styles.allDocuments}>
                {brochures?.map((item, index) => (
                  <div className={styles.document}>
                    <ImFilePdf color="#808080" size="10" />
                    <p className={styles.name}>
                      {' '}
                      <a href={item.link}>{item.name}</a>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                      }}
                    >
                      <MdDelete
                        color={'#ff0000'}
                        style={{}}
                        size={16}
                        onClick={() => {
                          deleteBrochures(item.name, index);
                        }}
                      />
                    </div>
                  </div>
                ))}
                {brochures?.length === 0 && (
                  <p
                  // style={{ margin: '0px', fontSize: '12px' }}
                  >
                    No Document Added
                  </p>
                )}
              </div>
            </div>

            <div className={styles.docContainer} style={cardContainerStyles}>
              <div className={styles.addContainer}>
                <h6
                // className={styles.title}
                >Pricelists</h6>
                <IoIosAddCircleOutline
                  color="#272944"
                  size="20"
                  onClick={() => {
                    setOpen(true);
                    setTitle('PriceLists Document');
                    setType('priceLists');
                  }}
                />
              </div>
              <div className={styles.allDocuments}>
                {priceLists?.map((item, index) => (
                  <div className={styles.document}>
                    <ImFilePdf color="#808080" size="10" />
                    <p className={styles.name}>
                      {' '}
                      <a href={item.link}>{item.name}</a>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                      }}
                    >
                      <MdDelete
                        color={'#ff0000'}
                        style={{}}
                        size={16}
                        onClick={() => {
                          deletePriceLists(item.name, index);
                        }}
                      />
                    </div>
                  </div>
                ))}
                {priceLists?.length === 0 && (
                  <p
                  // style={{ margin: '0px', fontSize: '12px' }}
                  >
                    No Document Added
                  </p>
                )}
              </div>
            </div>

            <div className={styles.docContainer} style={cardContainerStyles}>
              <div className={styles.addContainer}>
                <h6
                // className={styles.title}
                >Layouts</h6>
                <IoIosAddCircleOutline
                  color="#272944"
                  size="20"
                  onClick={() => {
                    setOpen(true);
                    setTitle('Layouts Document');
                    setType('layouts');
                  }}
                />
              </div>
              <div className={styles.allDocuments}>
                {layouts?.map((item, index) => (
                  <div className={styles.document}>
                    <ImFilePdf color="#808080" size="10" />
                    <p className={styles.name}>
                      {' '}
                      <a href={item.link}>{item.name}</a>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                      }}
                    >
                      <MdDelete
                        color={'#ff0000'}
                        style={{}}
                        size={16}
                        onClick={() => {
                          deleteLayouts(item.name, index);
                        }}
                      />
                    </div>
                  </div>
                ))}
                {layouts?.length === 0 && (
                  <p
                  // style={{ margin: '0px', fontSize: '12px' }}
                  >
                    No Document Added
                  </p>
                )}
              </div>
            </div>

            <div className={styles.docContainer} style={cardContainerStyles}>
              <div className={styles.addContainer}>
                <h6
                // className={styles.title}
                >Forms</h6>
                <IoIosAddCircleOutline
                  color="#272944"
                  size="20"
                  onClick={() => {
                    setOpen(true);
                    setTitle('Forms Document');
                    setType('forms');
                  }}
                />
              </div>
              <div className={styles.allDocuments}>
                {forms?.map((item, index) => (
                  <div className={styles.document}>
                    <ImFilePdf color="#808080" size="10" />
                    <p className={styles.name}>
                      {' '}
                      <a href={item.link}>{item.name}</a>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: 'auto',
                      }}
                    >
                      <MdDelete
                        color={'#ff0000'}
                        style={{}}
                        size={16}
                        onClick={() => {
                          deleteForms(item.name, index);
                        }}
                      />
                    </div>
                  </div>
                ))}
                {forms?.length === 0 && (
                  <p
                  // style={{ margin: '0px', fontSize: '12px' }}
                  >
                    No Document Added
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.projectDetails}>
            <h5
            // className={styles.detailsHeading}
            >Project Details</h5>
            <div className={styles.projectDataContainer} style={cardContainerStyles}>
              <div className={styles.data}>
                <p className={styles.head}>Project Name :</p>
                <p className={styles.text}>
                  {location.state.detail.project_name}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.head}>Developer Name :</p>
                <p className={styles.text}>
                  {location.state.detail.developer_name}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.head}>Address :</p>
                <p className={styles.text}>{location.state.detail.address}</p>
              </div>
              <div className={styles.data}>
                <p className={styles.head}>Property Type :</p>
                <p className={styles.text}>
                  {location.state.detail.property_type}
                </p>
              </div>
              <div className={styles.data}>
                <p className={styles.head}>RERA Link :</p>
                <p className={styles.text}>
                  {location.state.detail.rera_link}</p>
              </div>

              <div className={styles.data}>
                <p className={styles.head}>Walkthrough Link:</p>
                <p className={styles.text}>
                  {location.state.detail.walkthrough_link}</p>
              </div>

              {/* <div className={styles.data}>
                <p className={styles.head}>Walkthrough Link:</p>
                <div style={{width:"80%",display:"flex",height:"auto",flexWrap:"wrap"}}>
                <p className={styles.text} style={{ hyphens: "auto", maxWidth: "200px" }}>
                  {location.state.detail.walkthrough_link}
                </p>
                </div> */}
                {/* <p className={styles.text}>
                  {location.state.detail.walkthrough_link}
                </p> */}
              {/* </div> */}
              <div className={styles.data}>
                <p className={styles.head}>Created By :</p>
                <p className={styles.text}>{location.state.detail.created_by}</p>
              </div>
              <div className={styles.data}>
                <p className={styles.head}>Created Date & Time :</p>
                <p className={styles.text}>
                  {getDateAndTime(location.state.detail.created_at, 'LLL')}
                </p>
              </div>

              <div className={styles.uploadContainer}>
                <div className={styles.selectBox}>
                  <label htmlFor="file" className={styles.uploadButton}>
                    Upload Thumbnail
                  </label>
                  <input
                    id="file"
                    className={styles.select}
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={(e) => upload(e.target.files)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {open === true && (
          <ProjectDocumentModal
            open={open}
            close={() => setOpen(false)}
            title={title}
            projectData={location.state.detail}
            type={type}
            setLoad={(data) => setLoad(data)}
          />
        )}
        {vidModal && (
          <ProjectVideoModal
            open={vidModal}
            close={() => setVidModal(false)}
            title={title}
            type={"video"}
            projectData={location.state.detail}
            attachmentsList={
              attachmentsList ? attachmentsList : []
            }
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(ProjectDetails);
