import React from "react";
import { Route, Link } from "react-router-dom";
import SignIn from "../Screens/SignIn/SignIn";
import Password from "../Screens/ForgotPassword/ForgotPassword";
import { connect } from "react-redux";
import PasswordReset from "../Screens/PasswordReset/PasswordReset";
import SignUp from "../Screens/SignUp/SignUp";
import AddOrganization from "../Screens/AddOrganization/AddOrganization";
import RegistrationForm from "../Screens/RegistrationForm/RegistrationForm";

const AuthRoutes = ({
  history,
  firstLogin,
}: {
  history: any;
  firstLogin: boolean;
}) => {

  return (
    <>
      {String(history.location.pathname).startsWith("/resetpassword") !==
        true && (
          <>
            <Route path="/forgetpassword" component={Password} />
            {firstLogin === true && (
            <Route path={"/"} component={PasswordReset} />
          )}
            {firstLogin === false && (
            <Route exact path={"/"} component={SignIn} />
          )}
            {/* <Route exact path="/" component={SignIn} /> */}
            {/* <Route exact path="/passwordReset" component={PasswordReset} /> */}
            <Route exact path="/signup" component={SignUp} />
            <Route path="/addOrganizations" component={AddOrganization} />
            <Route path="/registrationForm" component={RegistrationForm} />
          </>
        )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firstLogin: state.user.firstLogin,
  };
};
export default connect(mapStateToProps)(AuthRoutes);
