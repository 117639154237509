import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar } from "@mui/material";
import {
    contactResources,
} from "../../Services/contacts";
import './booking.style.css';
import { FileUpload } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone'
import { green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { uploadAttachment } from "../../Services/booking";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { showSnackbarAction } from "../../Redux/actions";
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../Components/theme";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const sourceFund = ["Self Funding", "Self Loan Process"];
const CssTextField = styled(TextField)({
    fontSize: "0.8rem"
});
type props = {
    //   history: any;
    //   user: any;
    formikProps: any;
    theme: any;
};
const EditAttachment: FunctionComponent<props> = ({ formikProps, theme }) => {
    const viewAttachment = (e: any, value: any) => {
        e.preventDefault()
        window.open(value)
    }
    const handleDelete = (type: any) => {
        if (type === "DEVELOPERRECEIVING") {
            formikProps.setFieldValue('attachments.developer_receiving', '')
        }
    }
    return (
        <>
            <div style={{
                border: "solid 1px #AEAEAE",
                boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                borderRadius: "10px",
                padding: "10px",
            }}>
                <Grid container className="booking_container" spacing={2} style={{ padding: "30px" }} >
                    <Grid item xs={12}>
                        {(formikProps.values.attachments?.developer_receiving) && typeof formikProps.values.attachments?.developer_receiving !== 'object' && formikProps.values.attachments?.developer_receiving !== null ? <>
                            <div
                                style={{ display: "flex" }}
                            >
                                <div>
                                    <button
                                        className="addBox"
                                        onClick={(e) => viewAttachment(e, formikProps.values.attachments.developer_receiving)}
                                    >
                                        <PreviewIcon />
                                        {`View Developer Receiving`}
                                    </button>
                                </div>
                                <div>
                                    <DeleteForeverIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => handleDelete("DEVELOPERRECEIVING")}
                                    />
                                </div>
                            </div>
                        </>
                            : <>
                                <span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Upload your Developer Receiving</span>
                                <DropzoneArea
                                    dropzoneParagraphClass="dropzone-text"
                                    filesLimit={1}
                                    showAlerts={true}
                                    showPreviews={true}
                                    showPreviewsInDropzone={false}
                                    maxFileSize={80000000000}
                                    acceptedFiles={['text/csv', 'application/pdf', 'image/jpeg', 'image/png']}
                                    onDrop={(files) => formikProps.setFieldValue('attachments.developer_receiving', files[0])}
                                    onDelete={() => formikProps.setFieldValue('attachments.developer_receiving', "")}
                                /></>}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme: state.theme.isLightMode
    };
};
export default connect(mapStateToProps)(EditAttachment);