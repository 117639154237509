import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./SignIn.module.css";
import Logo from "../../Assets/Images/dr-logo.png";
import noteg_website_logo from "../../Assets/Images/noteg_website_logo.svg"
// import background from "../../Assets/Images/background.svg";
import background from "../../Assets/Images/backgrounrsignup.jpg";
import TextInput from "../../Components/TextInput/TextInput";
import SignInTextInput from "../../Components/SignInTextInput/SignInTextInput";
import PasswordInput from "../../Components/Password/PasswordInput";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { signInFirebase } from "../../Services/auth";
import { emailValidate, passwordValidate } from "../../Values/validatorsnew";
import { useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import LeadRubixLogo from "../../Assets/Images/leadrubixlogo.png";
import SideLogo from "../../Assets/Images/logowithbackground2.png";
import Cookies from "universal-cookie";
import LatestBackground from "../../Assets/Images/login_banner.svg";
import AppStore from "../../Assets/Images/appstore.svg";
import PlayStore from "../../Assets/Images/playstore.svg";
import { setContactHeaderLists, setTaskHeaderLists, setapiHeaderLists, setcalllogsHeaderLists, setprojectHeaderLists } from "../../Redux/actions";
import axios from "axios";
import { url } from "../../Values/constants";
import { Circles, Audio, BallTriangle } from 'react-loader-spinner';

type props = {
  history: any;
};

const SignIn: FunctionComponent<props> = ({ history }) => {
  const emailRef: any = useRef();
  const passwordRef: any = useRef();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userProfileUid, setUserProfileUid] = useState<any>();
  const cookies = new Cookies();
  const expires = new Date();
  //Increse Expire time limit 30 Days 
  expires.setDate(expires.getDate() + 30);
  cookies.set("uId", userProfileUid, { expires });
  const changeLoad = (value: boolean) => {
    setLoad(value);
  };
  useEffect(() => {
    setShowLoader(false);
  }, [history]);
  useEffect(() => {
    setShowLoader(false);
  }, []);
  const login = async (email: string, password: string) => {
    localStorage.setItem("isLightMode", "true")
    setShowLoader(true);
    signInFirebase(
      email,
      password,
      history,
      dispatcher,
      setShowLoader,
      changeLoad,
      setUserProfileUid
    );
    // setShowLoader(false);
  };
  const handleRedirectNoteG=()=>{
    const urlToOpen = 'https://notegtechnologies.com';
    // Open the URL in a new tab
    window.open(urlToOpen, '_blank');
  }
  const handleRedirectLeads=()=>{
    const urlToOpen = 'https://digitalrubix.com';
    // Open the URL in a new tab
    window.open(urlToOpen, '_blank');
  }
  return (
    <div
      className={styles.container}
    // style={{
    //   backgroundImage: `url(${background})`,
    //   backgroundRepeat: "no-repeat",
    //   // background:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,28,34,0.87718837535014) 51%, rgba(0,151,255,0.3561799719887955) 100%)",
    //   width: "100%",
    //   backgroundSize: "auto",
    //   backgroundPosition: "center",
    //   backgroundColor: "rgba(0, 0, 0, 0.02)",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center"
    //   // backgroundColor: "#272944",
    // }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "auto", flexDirection: "column" }}>
        {/* logo block */}
        {/* <div style={{ width: "100%", display: "flex", height: "auto" }}>
          <img
            src={SideLogo}
            alt="logo"
            width="100px"
            height="80px"
          // className={styles.logo} 
          />
        </div> */}
        {/* form and image main block */}
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "auto" }}>
          {/* image block */}
          <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={LatestBackground}
              alt=""
              width="100%"
              height="100%"
            />
          </div>
          {/* form block */}
          <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ display: "flex", height: "90vh", width: "100%" }}>
              {/* <div className={styles.box1}>
          <img
            src={LatestBackground}
            alt=""
            width="100%"
            height="100%"
          />
        </div> */}
              <div className={styles.box2} style={{
                // background: "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(120,28,14,0.87718837535014) 51%, rgba(0,128,250,0.3561799719887955) 100%)" 
                background: "#363853"
              }} >
                <div style={{ display: "flex", width: "100%", height: "100px" }}>
                  <img
                    src={LeadRubixLogo}
                    alt=""
                    width="90%"
                    height="50px"
                    style={{ marginTop: "15px", marginLeft: "16px" }}
                  // height="90%"
                  />
                </div>
                <div className={styles.form}>
                  <div className={styles.header}>
                    <h4 style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px", color: "white" }}>Welcome</h4>
                    <p className={styles.headerText} style={{ fontSize: "14px", color: "white" }}>Login to continue</p>
                  </div>
                  <div className={styles.inputs}>
                    <SignInTextInput title={"Email"} ref={emailRef} validator={emailValidate}>
                      <MdEmail color={"#053535"} />
                    </SignInTextInput>
                    <PasswordInput
                      title={"Password"}
                      ref={passwordRef}
                      validator={passwordValidate}
                      onPress={() =>
                        login(emailRef.current?.value, passwordRef.current?.value)
                      }
                    >
                      <RiLockPasswordFill color={"#053535"} />
                    </PasswordInput>
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.button}
                      onClick={() =>
                        login(emailRef.current?.value, passwordRef.current?.value)
                      }
                    >
                      {showLoader ?
                        <span style={{display:'flex',textAlign:"center",justifyContent:"center"}}><Circles
                        color="#fff" height={20}  width='100%' /></span>: 'Login'}
                    </button>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      className={styles.forgetPassword}
                      onClick={() => history.push("/forgetpassword")}
                      style={{ width: "50%", justifyContent: "flex-start" }}
                    >
                      <p style={{ marginBottom: "20px", marginTop: "10px", fontSize: "14px", color: "white" }}>Forgot Password?</p>
                    </div>
                    {/* <div
                      className={styles.forgetPassword}
                      onClick={() => history.push("/registrationForm")}
                      style={{ width: "50%", justifyContent: "flex-start" }}
                    >
                      <p style={{ marginBottom: "20px", marginTop: "10px", fontSize: "14px", color: "white" }}>SignUp</p>
                    </div> */}
                    <div
                      className={styles.forgetPassword}
                      onClick={() => history.push("/registrationForm")}
                      style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}
                    >
                      <p style={{ marginBottom: "20px", marginTop: "10px", fontSize: "14px", color: "white" }}>
                        SignUp
                      </p>
                    </div>
                  </div>
                  {/* <div className={styles.storeIcons}>
                    <div className={styles.iconContainer1}>
                      <a
                        // href="https://play.google.com/store/apps/details?id=com.readpro"
                        target="_blank">
                        <img
                          src={PlayStore}
                          alt={"logo"}
                          width={"25px"}
                        />
                      </a>
                    </div>
                    <div className={styles.iconContainer2}>
                      <a
                        //  href="https://apps.apple.com/in/app/read-pro/id1566457452"
                        target="_blank">
                        <img
                          src={AppStore}
                          alt={"logo"}
                          width={"40px"}
                        />
                      </a>
                    </div>
                  </div> */}
                  {/* <div style={{display:"flex",width:"100%"}}> */}
                    {/* <div style={{display:"flex",width:"50%",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"}}> */}
                      <p style={{ textAlign: "center", marginTop: "24px", color: 'white', fontSize: "14px" }}>Powered by</p>
                      <div className={styles.socialIcons} style={{cursor:"pointer"}} onClick={handleRedirectLeads}>
                        <img
                          src={Logo}
                          alt={"logo"}
                          height={"45px"}
                        />
                      {/* </div> */}
                    {/* </div> */}
                    {/* <div style={{display:"flex",justifyContent:"flex-end",width:"50%",flexDirection:"column",alignItems:"flex-end"}}>
                      <p style={{ textAlign: "center", marginTop: "24px", color: 'white', fontSize: "14px" }}>Developed by</p>
                      <div className={styles.socialIcons} style={{cursor:"pointer"}} onClick={handleRedirectNoteG}>
                        <img
                          src={noteg_website_logo}
                          alt={"logo"}
                          height={"45px"}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default SignIn;