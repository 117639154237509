import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import styles from "./DeleteSelectedModal.module.css";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";
import { setDeleteLeadsStatus, showSnackbarAction } from "../../../Redux/actions";
import { db, firestore, functions, storage } from "../../../Firebase";
import Firebase from "firebase/app";
import { deleteRecords } from "../../../Services/contacts";
import axios from "axios";

import { url } from "../../../Values/constants";
import { themeColors } from "../../theme";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import { deleteNews } from "../../../Services/organizations";

type props = {
  open: boolean;
  close: () => void;
  data: any;
  clearSelectedRowsData?: () => void;
  setOwner?: any;
  theme: any;
  tableType?: any;
  newsData?: any;
  user: any;
  organizationId: any;
  history?: any;
  authToken: any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const DeleteSelectedModal: FunctionComponent<props> = ({
  open,
  close,
  clearSelectedRowsData,
  setOwner,
  data,
  theme,
  newsData,
  tableType,
  user,
  organizationId,
  history,
  authToken
}) => {
  const [load, setLoad] = useState(false);
  const dispatcher = useDispatch();
  const deleteselectedRecord = async () => {
    if (tableType === "NEWS") {
      const myArrayFiltered = newsData.filter((el: any) => {
        return el.createNewsProfile !== "Super Admin" && !data.some((f: any) => {
          return f.name === el.name;
        });
      });
      deleteNews(
        organizationId,
        myArrayFiltered,
        dispatcher,
      );
      setOwner(false);
      close();
    }
     else if (tableType === "NEWS SUPER ADMIN") {
      const myArrayFiltered = newsData.filter((el: any) => {
        return !data.some((f: any) => {
          return f.name === el.name;
        });
      });
      deleteNews(
        " ",
        myArrayFiltered,
        dispatcher,
      );
      setOwner(false);
      close();
    }
    else {
      const newId: string[] = [];
      data.forEach((element: any) => {
        newId.push(element.contactId);
      });
      
      if(newId.length > 200) {
        dispatcher(showSnackbarAction("Maximum 200 records allowed", "error"));
        return
      }
      setLoad(true);
      // let outcome:any = deleteRecords(newId);
      let outcome:any = deleteRecords(data);
      if (outcome) {
        // try {
        //   // for (const element of data) {

        //   // }
        //   axios.defaults.headers.common['x-access-token'] =
        //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
        //   const callLogs = await axios.delete(url + "/callLogs/deleteMultipleCallLogs", { data: { leadId: newId } });
        //   axios.defaults.headers.common['x-access-token'] =
        //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
        //   const task = await axios.delete(url + "/tasks/deleteMultipleTasks", { data: { leadId: newId } });
        // }
        // catch (err) {
        //   console.log(err);
        // }
        try {
          for (const element of data) {
            axios.defaults.headers.common['x-access-token'] = authToken
            const callLogs = await axios.delete(url + "/callLogs/deleteCallLogs", { data: { leadId: element.contactId } });
            axios.defaults.headers.common['x-access-token'] = authToken
            const task = await axios.delete(url + "/tasks/deleteTask", { data: { leadId: element.contactId } });
          }

        }
        catch (err) {
          console.log(err);
        }
        setTimeout(() => {
          close();
          clearSelectedRowsData && clearSelectedRowsData();
          dispatcher(setDeleteLeadsStatus(true));
          setLoad(false);
          dispatcher(showSnackbarAction("Record Deleted", "success"));
          // window.location.reload();
        }, 1500);

      } else {
        setTimeout(() => {
          close();
          setLoad(false);
          dispatcher(showSnackbarAction("Failed", "error"));
        }, 1500);
      }
    }
  };
  const customStyles = {
    content: {
      backgroundColor: theme ? themeColors.dropdownBackgroundColor : themeColors.dropdownBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
    },
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const parentStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark }
 
  return (
    <>
    <div className={styles.parentP} style={{ display: "block", marginTop:"4%", ...parentStyles}}>
       {load === true && <Loading />}
      {/* <Modal
        isOpen={open}
        className={styles.parent}
        overlayClassName={styles.overlay}
        onRequestClose={close}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        style={customStyles}
      >
        {load && <Loading />} */}
      {/* <div className={styles.headingContainer}>
          <h6 className={styles.heading}>Delete Selected Records</h6>
        </div> */}
      {/* <h6 
            className={styles.heading}
            >Delete Selected Records</h6>
        <div className={styles.container}> */}
      {/* <div className={styles.subheading}>
            <p className={styles.optionHeading}>Selected Options</p>
          </div> */}
      {/* <ol>
            {data.map((dat: any) => (
              <li>{dat.customer_name}</li>
            ))}
          </ol> */}
      {/* <ul className="list-group">
              <li className="list-group-item">An item</li>
              <li className="list-group-item">A second item</li>
              <li className="list-group-item">A third item</li>
              <li className="list-group-item">A fourth item</li>
              <li className="list-group-item">And a fifth one</li>
          </ul> */}
      {/* </div> */}

      {/* <div className={styles.buttonView}>
          <button className={styles.cancelButton} onClick={close}>
            Cancel
          </button> 
           <button className={styles.saveButton} onClick={deleteselectedRecord}>
            Save
          </button>
        </div>
      </Modal> */}
      <Backdrop>
        <motion.div
          key="modal"
          className="card p-4 shadow rounded"
          style={{
            position: "absolute", maxHeight: "320px",maxWidth: "320px",
            ...containerStyles
          }}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {/* <div className={styles.headingContainer}>
          <h6 className={styles.heading}>Delete Selected Records</h6>
        </div> */}
          <h6
            // className={styles.heading}
          >Are you sure you want to delete the selected records?</h6>
          <div className={styles.container}>
            {/* <div className={styles.subheading}>
            <p className={styles.optionHeading}>Selected Options</p>
          </div> */}
            <ol>
              {data.map((dat: any) => (
                <li>{dat.customer_name || dat.name}</li>
              ))}
            </ol>
            {/* <ul className="list-group">
              <li className="list-group-item">An item</li>
              <li className="list-group-item">A second item</li>
              <li className="list-group-item">A third item</li>
              <li className="list-group-item">A fourth item</li>
              <li className="list-group-item">And a fifth one</li>
          </ul> */}
          </div>

          <div className={styles.buttonView}>
            <button className={styles.cancelButton} onClick={close}>
              Cancel
            </button>
            <button className={styles.saveButton} onClick={deleteselectedRecord}>
              Delete
            </button>
          </div>
        </motion.div>
      </Backdrop>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    organizationId: state.organization.id,
    authToken:state.user.authToken
  };
};
export default connect(mapStateToProps)(DeleteSelectedModal);
