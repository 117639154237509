import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar } from "@mui/material";
import {
    contactResources,
} from "../../Services/contacts";
import './booking.style.css';
import { FileUpload } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone'
import { green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { uploadAttachment } from "../../Services/booking";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { showSnackbarAction } from "../../Redux/actions";
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../Components/theme";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { BsPlus } from 'react-icons/bs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";

const CssTextField = styled(TextField)({
    fontSize: "0.8rem"
});
const arrPaymentInstalments = [
    {
        value: 'Booking Amount',
        label: 'Booking Amount'
    },
    {
        value: '1st Instalment',
        label: '1st Instalment'
    },
    {
        value: '2nd Instalment',
        label: '2nd Instalment'
    },
    {
        value: '3rd Instalment',
        label: '3rd Instalment'
    },
    {
        value: '4th Instalment',
        label: '4th Instalment'
    },
    {
        value: '5th Instalment',
        label: '5th Instalment'
    },
    {
        value: '6th Instalment',
        label: '6th Instalment'
    },
    {
        value: '7th Instalment',
        label: '7th Instalment'
    },
    {
        value: '8th Instalment',
        label: '8th Instalment'
    },
    {
        value: '9th Instalment',
        label: '9th Instalment'
    },
    {
        value: '10th Instalment',
        label: '10th Instalment'
    },
    {
        value: '11th Instalment',
        label: '11th Instalment'
    },
    {
        value: '12th Instalment',
        label: '12th Instalment'
    },
    {
        value: '13th Instalment',
        label: '13th Instalment'
    },
    {
        value: '14th Instalment',
        label: '14th Instalment'
    },
    {
        value: '15th Instalment',
        label: '15th Instalment'
    },
    {
        value: '16th Instalment',
        label: '16th Instalment'
    },
    {
        value: '17th Instalment',
        label: '17th Instalment'
    },
    {
        value: '18th Instalment',
        label: '18th Instalment'
    },
    {
        value: '19th Instalment',
        label: '19th Instalment'
    },
    {
        value: '20th Instalment',
        label: '20th Instalment'
    },
    {
        value: 'On Possession',
        label: 'On Possession'
    },
    {
        value: 'On Registration',
        label: 'On Registration'
    }
];
const arrPaymentSource = [
    {
        value: 'IMPS/RTGS/NEFT',
        label: 'IMPS/RTGS/NEFT'
    },
    {
        value: 'Card Swipe',
        label: 'Card Swipe'
    },
    {
        value: 'Cheque',
        label: 'Cheque'
    },
    {
        value: 'DD',
        label: 'DD'
    },
    {
        value: 'Wallet',
        label: 'Wallet'
    }
];
const arrPaymentStatus = [
    {
        value: 'Awaited',
        label: 'Awaited'
    },
    {
        value: 'Received',
        label: 'Received'
    },
    {
        value: 'Cleared',
        label: 'Cleared'
    },
    {
        value: 'Bounced',
        label: 'Bounced'
    },
    {
        value: 'Due',
        label: 'Due'
    }
];
const arrFundingSource = [
    {
        value: 'Self Funding',
        label: 'Self Funding'
    },
    {
        value: 'Self Loan Proces',
        label: 'Self Loan Proces'
    },
    {
        value: 'Loan Stone',
        label: 'Loan Stone'
    }
];
const arrOccupation = [
    {
        value: 'NA',
        label: 'NA'
    },
    {
        value: 'Salaried',
        label: 'Salaried'
    },
    {
        value: 'Self Employed',
        label: 'Self Employed'
    },
    {
        value: 'Salaried Professionals',
        label: 'Salaried Professionals'
    },
    {
        value: 'Self - Employed Professionals',
        label: 'Self - Employed Professionals'
    }
];
type props = {
    //   history: any;
    //   user: any;
    formikProps: any;
    theme: any;
};
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const EditPaymentPlan: FunctionComponent<props> = ({ formikProps, theme }) => {
    const dispatcher = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [indexDelete, SetIndexDelete] = React.useState();
    const [payment, SetPayment] = React.useState(0);
    const [view, SetView] = React.useState(false);
    const location: any = useLocation();
    let pay =0
    let a :any
    let last_index :any
    let currentAmount:any
    const totalAmount = formikProps.values.consolidated_costing?.net_property_cost_applicant
    // const Amount = formikProps?.values?.payment_plan?.net_property_cost_applicant

    const useHelperTextStyles = makeStyles(() => ({
        inputRoot: {
            // "&$disabled": {
            //   color: "green"
            // }
        },
        disabled: {
        },
        root: {
            "& .MuiFormLabel-root": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiFormLabel-root.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSelect-select": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSelect-select.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiInputBase-input": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiInputBase-input.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
                "-webkit-text-fill-color": `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiOutlinedInput-input": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiOutlinedInput-input.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSvgIcon-root": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSvgIcon-root.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            }
        },
        notchedOutline: {
            borderWidth: "1px",
            borderColor: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark} !important`,
        },
        preview: {
            color: "black"
        },
        dropzone: {
            color: "black"
        },
        formHelperText: {
        },
    }));
    const helperTextStyles = useHelperTextStyles();
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = (index: any) => {
        setOpen(true);
        SetIndexDelete(index);
    };

    const viewAttachment = (e: any, value: any) => {
        e.preventDefault()
        window.open(value)
    }
    const handleDelete = (type: any,index:any) => {
        if (type === "PAYMENTRECEIVING") {
            formikProps.setFieldValue(`payment_plan${index}.payment_receiving_proof`, '')
        }
        else if (type === "PAYMENTCLEARANCE") {
            formikProps.setFieldValue(`payment_plan${index}.payment_clearance_proof`, '')
        }
        else if (type === "PAYMENTPROOF") {
            formikProps.setFieldValue(`payment_plan${index}.payment__proof`, '')
        }
    }
    const handleAddNewPaymentInstalments = (e: any) => {
        SetView(true)
        e.preventDefault();
        const newArr = [...formikProps?.values?.payment_plan,
        {
            payment_instalments: '',
            payment: '',
            payment_amount: '',
            payment_date: '',
            payment_reference: '',
            payment_source: '',
            bank_name: '',
            payment_receiving_proof: '',
            payment_clearance_proof: '',
            payment__proof: '',
            Payment_Status: ''
        }
        ];
        formikProps.setFieldValue('payment_plan', newArr)
    }
    const handleDeletePaymentPlan = () => {
        let arrayList = formikProps?.values?.payment_plan;
        arrayList.splice(indexDelete, 1);
        formikProps.setFieldValue('payment_plan', arrayList);
        handleClose();
    }
    useEffect(() => {
        a=(pay/totalAmount)*100
        SetPayment(a)
        // if(view===true){formikProps.setFieldValue(`payment_plan[${last_index-1}].payment`, a); SetView(false)}
      }, [pay,currentAmount,formikProps])
    useEffect(() => {
        formikProps.setFieldValue(`payment_plan[${last_index}].payment`, payment.toFixed(2));
      }, [payment])

    const containerStyles = { backgroundColor: theme ? themeColors.navbarBackgroundColor : themeColors.navbarBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    return (
        <>
            <div style={{
                border: "solid 1px #AEAEAE",
                boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                borderRadius: "10px",
                padding: "10px",
            }}>
                <Grid container className="booking_container" spacing={2} style={{ padding: "30px" }} >
                    <Typography className="textTypography" width="98%" variant="subtitle1">
                    Payment Plans
                    </Typography>
                    {
                        formikProps?.values?.payment_plan?.map((list: any, index: any) => (
                            <>
                            {currentAmount=list.payment_amount}
                            {last_index=index}
                            {
                                pay+=Number(list.payment_amount)
                                
                            }
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <Grid container className='child-container' key={index} spacing={2}>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="text"
                                                    fullWidth
                                                    select
                                                    label="Payment Instalments"
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].payment_instalments`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                    {arrPaymentInstalments.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="text"
                                                    fullWidth
                                                    label="Total Payment %"
                                                    disabled
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].payment`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="text"
                                                    fullWidth
                                                    label="Payment Amount"
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].payment_amount`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="date"
                                                    fullWidth
                                                    label="Payment Date"
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    InputLabelProps={{ shrink: true, required: true }}
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].payment_date`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="text"
                                                    fullWidth
                                                    label="Payment Reference"
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].payment_reference`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="text"
                                                    fullWidth
                                                    select
                                                    label="Payment Source"
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].payment_source`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                    {arrPaymentSource.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="text"
                                                    fullWidth
                                                    label="Bank Name"
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].bank_name`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CssTextField
                                                    type="text"
                                                    fullWidth
                                                    select
                                                    label="Payment Status"
                                                    size="small"
                                                    id="custom-css-outlined-input"
                                                    {...formikProps.getFieldProps(`payment_plan[${index}].Payment_Status`)}
                                                    className={helperTextStyles.root}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: helperTextStyles.notchedOutline
                                                        }
                                                    }}
                                                    sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                                                >
                                                    {arrPaymentStatus.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField>
                                            </Grid>
                                            <Grid item xs={4}>
       
                                                {(formikProps.values.payment_plan[index]?.payment_receiving_proof) && typeof formikProps.values.payment_plan[index]?.payment_receiving_proof !== 'object' && formikProps.values.payment_plan[index]?.payment_receiving_proof !== null ? <>
                                                    <div
                                                        style={{ display: "flex" }}
                                                    >
                                                        <div>
                                                            <button
                                                                className="addBox"
                                                                onClick={(e) => viewAttachment(e, formikProps.values.payment_plan[index]?.payment_receiving_proof)}
                                                            >
                                                                <PreviewIcon />
                                                                {`View Payment Receiving`}
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <DeleteForeverIcon
                                                                style={{ cursor: "pointer"}}
                                                                onClick={(e) => handleDelete("PAYMENTRECEIVING",index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                    : <>
                                                        <span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Upload your Payment Receiving Proof</span>
                                                        <DropzoneArea
                                                            dropzoneParagraphClass="dropzone-text"
                                                            filesLimit={1}
                                                            showAlerts={true}
                                                            showPreviews={true}
                                                            showPreviewsInDropzone={false}
                                                            maxFileSize={80000000000}
                                                            acceptedFiles={['text/csv', 'application/pdf', 'image/jpeg', 'image/png']}
                                                            onDrop={(files) => formikProps.setFieldValue(`payment_plan[${index}].payment_receiving_proof`, files[0])}
                                                            onDelete={() => formikProps.setFieldValue(`payment_plan[${index}].payment_receiving_proof`, "")}
                                                        /></>}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {(formikProps.values.payment_plan[index]?.payment_clearance_proof) && typeof formikProps.values.payment_plan[index]?.payment_clearance_proof !== 'object' && formikProps.values.payment_plan[index]?.payment_clearance_proof !== null ? <>
                                                    <div
                                                        style={{ display: "flex" }}
                                                    >
                                                        <div>
                                                            <button
                                                                className="addBox"
                                                                onClick={(e) => viewAttachment(e, formikProps.values.payment_plan[index]?.payment_clearance_proof)}
                                                            >
                                                                <PreviewIcon />
                                                                {`View Payment Clearance`}
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <DeleteForeverIcon
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e) => handleDelete("PAYMENTCLEARANCE",index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                    : <>
                                                        <span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Upload your Payment Receiving Proof</span>
                                                        <DropzoneArea
                                                            dropzoneParagraphClass="dropzone-text"
                                                            filesLimit={1}
                                                            showAlerts={true}
                                                            showPreviews={true}
                                                            showPreviewsInDropzone={false}
                                                            maxFileSize={80000000000}
                                                            acceptedFiles={['text/csv', 'application/pdf', 'image/jpeg', 'image/png']}
                                                            onDrop={(files) => formikProps.setFieldValue(`payment_plan[${index}].payment_clearance_proof`, files[0])}
                                                            onDelete={() => formikProps.setFieldValue(`payment_plan[${index}].payment_clearance_proof`, "")}
                                                        /></>}
                                            </Grid>
                                            {/* <Grid item xs={4}>
                                            <span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Upload your Payment Proof</span>
                                            <DropzoneArea
                                                dropzoneParagraphClass="dropzone-text"
                                                filesLimit={1}
                                                showAlerts={true}
                                                showPreviews={true}
                                                showPreviewsInDropzone={false}
                                                maxFileSize={80000000000}
                                                acceptedFiles={['text/csv', 'application/pdf', 'image/jpeg', 'image/png']}
                                                onDrop={(files) => formikProps.setFieldValue('payment_plan.payment__proof', files[0])}
                                            />
                                        </Grid> */}
                                            <Grid item xs={4}>
                                                {(formikProps.values.payment_plan[index]?.payment__proof) && typeof formikProps.values.payment_plan[index]?.payment__proof !== 'object' && formikProps.values.payment_plan[index]?.payment__proof !== null ? <>
                                                    <div
                                                        style={{ display: "flex" }}
                                                    >
                                                        <div>
                                                            <button
                                                                className="addBox"
                                                                onClick={(e) => viewAttachment(e,formikProps.values.payment_plan[index]?.payment__proof)}
                                                            >
                                                                <PreviewIcon />
                                                                {`View Payment Proof`}
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <DeleteForeverIcon
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e) => handleDelete("PAYMENTPROOF",index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                    : <>
                                                        <span style={{ color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>Upload your Payment Proof</span>
                                                        <DropzoneArea
                                                            dropzoneParagraphClass="dropzone-text"
                                                            filesLimit={1}
                                                            showAlerts={true}
                                                            showPreviews={true}
                                                            showPreviewsInDropzone={false}
                                                            maxFileSize={80000000000}
                                                            acceptedFiles={['text/csv', 'application/pdf', 'image/jpeg', 'image/png']}
                                                            onDrop={(files) => formikProps.setFieldValue(`payment_plan[${index}].payment__proof`, files[0])}
                                                            onDelete={() => formikProps.setFieldValue(`payment_plan[${index}].payment__proof`, "")}
                                                        /></>}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div
                                        style={{ marginTop: "7px", marginLeft: formikProps?.values?.applicant_details.co_applicant?.length === 1 ? "35px" : "3px" }}
                                    >
                                        {formikProps?.values?.payment_plan?.length <= 1 ? <></> : <>
                                            <HighlightOffIcon
                                                onClick={() => handleClickOpen(index)}
                                                style={{ cursor: 'pointer',color:"red" }}
                                            />
                                        </>}
                                    </div>
                                </div>
                            </>
                        ))
                    }
                    <Typography className="textTypography" width="98%" variant="subtitle1">
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <div>
                            </div>
                            <div>
                                <button
                                    style={{ marginLeft: '10px' }}
                                    className="button_addBox"
                                    onClick={(e) => handleAddNewPaymentInstalments(e)}
                                >
                                    <BsPlus size={22} color={'#ffffff'} />
                                </button>
                            </div>
                        </div>
                    </Typography>
                    <Typography className="textTypography" variant="subtitle1">Source of Fund</Typography>
                    <Grid container className='child-container' spacing={2}>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                select
                                required
                                label="Funding Source"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('source_of_fund.funding_source')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                                {arrFundingSource.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                select
                                required
                                label="Occupation"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('source_of_fund.Occupation')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                                {arrOccupation.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                required
                                label="Gross Monthly Income"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('source_of_fund.gross_monthly_income')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Annual Bonus / Incentive"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('source_of_fund.annual_bonus_incentive')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Rental Income"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('source_of_fund.rental_income')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                            </CssTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <CssTextField
                                type="text"
                                fullWidth
                                label="Existing EMI"
                                size="small"
                                id="custom-css-outlined-input"
                                {...formikProps.getFieldProps('source_of_fund.existing_EMI')}
                                className={helperTextStyles.root}
                                InputProps={{
                                    classes: {
                                        notchedOutline: helperTextStyles.notchedOutline
                                    }
                                }}
                                sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                            >
                            </CssTextField>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" style={containerStyles}>
                    {"Confirmation"}
                </DialogTitle>
                <DialogContent style={containerStyles}>
                    <DialogContentText id="alert-dialog-slide-description" style={containerStyles}>
                        Are you sure you want to Additional Charges details?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={containerStyles}>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeletePaymentPlan}
                        color="primary">
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme: state.theme.isLightMode
    };
};
export default connect(mapStateToProps)(EditPaymentPlan);