import React, { FunctionComponent, useState, useRef } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { themeColors } from "../../Components/theme";


type props = { history: any; user: any, theme: any };
const PrivacyPolicy: FunctionComponent<props> = ({ history, user, theme }) => {


    const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    return (
        <div
            // className={styles.parent}
            className="container-fluid p-4 d-flex flex-column gap-4"
            style={{ minHeight: "100vh", height: "fit-content", ...containerStyles, marginTop: '5%' }}>
            <div
                // className={styles.topBar}
                className="d-flex justify-content-between"
            >
                <h5
                // className={styles.title}
                >Privacy policy</h5>
            </div>
            <div
                // className={styles.child}
                className="d-flex flex-column gap-4"
                style={containerStyles}>
                <span>
                    At LeadsRubix Private Limited, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our mobile app, leadsRubix.
                </span>
                <h6>Information We Collect</h6>
                <span>
                    When you use our app, we may collect the following types of personal information:
                </span>
                <li>
                Name, email address, and other contact details
                </li>
                <li>
                Usernames and passwords
                </li>
                <li>
                Location information, if you choose to enable this feature
                </li>
                <h6>Use of Information</h6>
                <span>
                We may use your personal information for the following purposes:
                </span>
                <li>
                To provide and improve our app
                </li>
                <li>
                To personalize your experience
                </li>
                <li>
                To communicate with you, including to respond to your inquiries and provide customer support
                </li>
                <li>
                To send you marketing and promotional materials, subject to your consent
                </li>
                <li>
                To comply with legal obligations
                </li>
                <h6>
                Disclosure of Information
                </h6>
                <span>
                We may disclose your personal information to the following third parties:
                </span>
                <li>
                Law enforcement authorities, regulators, or other government officials, when required by law or to protect our rights or the rights of others
                </li>
                <li>
                Other users of our app, when you choose to share information with them through the app Security
                </li>
                <li>
                We take reasonable measures to protect your personal information from unauthorized access, use, and disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.
                </li>
                <h6>
                Security
                </h6>
                <span>
                We take reasonable measures to protect your personal information from unauthorized access, use, and disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.
                </span>
                <h6>
                Retention
                </h6>
                <span>
                We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law.
                </span>
                <h6>Your Rights</h6>
                <span>
                You may have certain rights with respect to your personal information, such as the right to access, correct, or delete your information. If you wish to exercise these rights, please contact us using the information provided on Contact us section.
                </span>
                <h6>
                Changes to this Policy
                </h6>
                <span>
                We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will post the updated Privacy Policy on our website and notify you of any material changes.
                </span>
                <h6>Contact us</h6>
                <span>
                If you have any questions or concerns about this Privacy Policy or our practices, please contact us at info@leadsrubix.com
                </span>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme: state.theme.isLightMode
    };
};

export default connect(mapStateToProps)(PrivacyPolicy);