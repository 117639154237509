import React, { useMemo, useState, useEffect } from "react";
import styles from "./ProjectsPanel.module.css";
import commonStyle from "../common.module.css";
import Topbar from "../../Components/TopBar/TopBar";
import { PROJECT_COLUMNS,ProjectColumns } from "../../Values/tables";
import { useTable, useSortBy, useFilters, useColumnOrder } from "react-table";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { deleteProject, fetchProjects } from "../../Services/contacts";
import ImportContactsModal from "../../Components/Modals/ImportModal/ImportContactsModal";
import { connect, useDispatch } from "react-redux";
import {
  getFilterObject,
  filterProjectStatus,
  searchProjectItem,
} from "../../Values/utils";
import Loading from "../../Components/Loading/Loading";
import ColumnManagerModal from "../../Components/Modals/ColumnManagerModal/ColumnManager";

import { MdDelete } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import ApiTopBar from "../../Components/TopBar/ApiTopBar";
import ApiCustomTable from "../../Components/CustomTable/ApiCustomTable";
import ImportProjectModal from "../../Components/Modals/ImportModal/ImportProjectModal";
import NewImportProjectModal from "../../Components/Modals/ImportModal/NewImportProjectModal";
import DeleteSelectedModal from "../../Components/Modals/DeleteSelectedModal/DeleteSelectedModal";
import DeleteProjectSelectedModal from "../../Components/Modals/DeleteSelectedModal/DeleteProjectSelectedModal";
import {themeColors} from "../../Components/theme";
import CustomTable from "../../Components/CustomTable/CustomTable";
import ProjectCustomTable from "../../Components/CustomTable/ProjectCustomTable";



const Project = ({ history, user, organizationUsers,theme,projectHeader,searchString }: any) => {
  console.log("All Projets Headers printed :",projectHeader);
  const dispatcher = useDispatch();
  const [projectsList, setProjectsList] = useState<any[] | undefined>(
    undefined
  );
  const [tasksImportModal, setTasksImportModal] = useState(false);
  const [notesImportModal, setNotesImportModal] = useState(false);
  const [callLogsImportModal, setCallLogsImportModal] = useState(false);
  const [lastPage, setLastPage] = useState(-1);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [searchedItem, setsearchedItem] = useState("");
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [owner, setOwner] = useState(false);
  const [deletePopup, setDeletePopop] = useState(false);


  useEffect(() => {
    if (user.organization_id) {
      const unsub = fetchProjects(
        (data) => setProjectsList(data),
        user.organization_id
      );
      return () => {
        unsub();

      };
    }
  }, [user]);

  useEffect(() => {
    if (projectsList !== undefined) {
      setFilterData(projectsList);
    }
  }, [projectsList, dispatcher]);
  useEffect(() => {
    if (status === "ALL") {
      if (projectsList) {
        const data = searchProjectItem(projectsList, searchedItem);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchProjectItem(temporaryData, searchedItem);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchedItem, projectsList]);
  useEffect(() => {
    if (searchedItem === "") {
      if (projectsList) {
        const data = filterProjectStatus(projectsList, status);
        setTemporaryData(data);
        setFilterData(data);
        setTotalCounts(data.length);
      }
    } else {
      const data = filterProjectStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, projectsList]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setOwner(true);
    } else if (selectedRows.length === 0) {
      setOwner(false);
    }
  }, [selectedRows]);

  const selectAll = (checked: boolean) => {
    if (checked === true) {
      setSelectedRows(Array.from(Array(filterData?.length).keys()));
    } else {
      setSelectedRows([]);
    }
  };

  const columns = useMemo(() => PROJECT_COLUMNS, []);
  const data = useMemo(() => (filterData ? filterData : []), [filterData]);

  const tableInstance: any = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    useColumnOrder
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    toggleHideAllColumns,

    //@ts-ignore
    setAllFilters,
  } = tableInstance;


  const setColumns = (columnList: any) => {
    setColumnOrder(columnList);
  };

  useEffect(() => {
    if (filterData) {
      getFilterObject(filterData, dispatcher);
    }
  }, [filterData, dispatcher]);

  const clearSort = () => {
    headerGroups[0].headers.forEach((header: any) => {
      header.clearSortBy();
    });
  };
  const clearSelectedRowsData = () => {
    setSelectedRowsData([]);
    setSelectedRows([]);
  };
  let isFinished = false;

  const exportFile = () => {
    interface DataObj {
      label: any;
      value: any;
    }
    // projectHeader
    // const dbTaskHeader=[{"label": "address","value": "Address"},{"label": "created_at","value": "Created At"},{"label": "created_by","value": "Created By"},{"label": "developer_name","value": "Developer Name"},{"label": "project_id","value": "Project Id"},{"label": "project_name","value": "Name"},{"label": "project_status","value": "Project Status"},{"label": "property_stage","value": "Property Stage"},{"label": "property_type","value": "Property Type"},{"label": "rera_link","value": "Rera Link"},{"label": "walkthrough_link","value": "Walkthrough Link"},{"label": "status","value": "Status"},{"label": "profile","value": "Profile"},{"label": "project_image","value": "Project image"}];
    const dbTaskHeader=projectHeader;
    const dbHeaderAddress :any = dbTaskHeader.find((obj: DataObj) => obj.label === "address")?.value;
    const dbHeaderCreatedAt :any = dbTaskHeader.find((obj: DataObj) => obj.label === "created_at")?.value;
    const dbHeaderCreatedBy :any = dbTaskHeader.find((obj: DataObj) => obj.label === "created_by")?.value;
    const dbHeaderDeveloperName :any = dbTaskHeader.find((obj: DataObj) => obj.label === "developer_name")?.value;
    const dbHeaderProjectId :any = dbTaskHeader.find((obj: DataObj) => obj.label === "project_id")?.value;
    const dbHeaderProjectName :any = dbTaskHeader.find((obj: DataObj) => obj.label === "project_name")?.value;
    const dbHeaderProjectStatus :any = dbTaskHeader.find((obj: DataObj) => obj.label === "project_status")?.value;
    const dbHeaderPropertyStage :any = dbTaskHeader.find((obj: DataObj) => obj.label === "property_stage")?.value;
    const dbHeaderPropertyType :any = dbTaskHeader.find((obj: DataObj) => obj.label === "property_type")?.value;
    const dbHeaderReraLink :any = dbTaskHeader.find((obj: DataObj) => obj.label === "rera_link")?.value;
    const dbHeaderWalkthroughLink :any = dbTaskHeader.find((obj: DataObj) => obj.label === "walkthrough_link")?.value;
    const dbHeaderStatus :any = dbTaskHeader.find((obj: DataObj) => obj.label === "status")?.value;
    const dbHeaderProfile :any = dbTaskHeader.find((obj: DataObj) => obj.label === "profile")?.value;
    const dbHeaderProjectImage :any = dbTaskHeader.find((obj: DataObj) => obj.label === "project_image")?.value;
    let data: any[] = [];
    if (selectedRowsData.length !== 0) {
      selectedRowsData.forEach((item) => {
        data.push({
          [dbHeaderProjectName]: item.project_name,
          [dbHeaderProjectStatus]:item?.project_status,
          [dbHeaderDeveloperName]: item.developer_name,
          [dbHeaderAddress]: item.address,
          [dbHeaderReraLink]: item.rera_link,
          [dbHeaderWalkthroughLink]: item.walkthrough_link,
          [dbHeaderPropertyType]: item.property_type,
          [dbHeaderStatus]: item.status,
          [dbHeaderCreatedBy]: item.created_by,
          [dbHeaderProfile]: item.profile,
          [dbHeaderProjectId]: item.project_id,
          [dbHeaderProjectImage]: item.project_image,

        });
      });
    }
    return data;
  };
const containerStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
     <div className={styles.parent} style={{display:"block",marginTop:"4%",...containerStyles}}>
      {(projectsList === undefined || load === true) && <Loading />}
      <div className={commonStyle.topBar} style={{marginTop:"0px",...containerStyles}}>
      <ApiTopBar
          history={history}
          title={"Add Project"}
          path={"/addProjects"}
          owner={owner}
          deleteSelected={() => {
            setDeletePopop(true);
          }}
          onClick={() => {
            setShowImportModal(true);
          }}
          onChange={(val) => setsearchedItem(val)}
          filterText={"Status"}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={true}
          onExport={exportFile}
          onCallLogsImport={() => {
            setCallLogsImportModal(true);
          }}
          onNotesImport={() => {
            setNotesImportModal(true);
          }}
          onTasksImport={() => {
            setTasksImportModal(true);
          }}
          searchedString={searchString}
        />
      </div>
      <div className={commonStyle.parentp} style={{minHeight:"450px",...containerStyles}}>
        {/* <ApiCustomTable
          tableColumns={PROJECT_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="Project"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setPage={(val) => setPage(val)}
          setPageSize={(val) => setPageSize(val)}
          pageSize={pageSize}
          page={page}
          isFinished={isFinished}
          totalCount={totalCounts}
          lastPage={lastPage}
        /> */}
         <ProjectCustomTable
          tableColumns={ProjectColumns(projectHeader)}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="API"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setSearchedItem={(val) => setsearchQuery(val)}
        />
      </div>
      {showImportModal && (
        <NewImportProjectModal
          open={showImportModal}
          close={() => setShowImportModal(false)}
          organization_id={user.organization_id}
          history={history}
          usersList={organizationUsers}
          user={user}
        />
      )}

      {deletePopup && (
        <DeleteProjectSelectedModal
          data={selectedRowsData}
          clearSelectedRowsData={clearSelectedRowsData}
          open={deletePopup}
          totalData={projectsList}
          organization_id={user.organization_id}
          close={() => {
            setDeletePopop(false);
          }}
        />
      )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    theme: state.theme.isLightMode,
    projectHeader:state.projectHeaderList.data,
    searchString: state.searchItem.projectSearchString,
  };
};

export default connect(mapStateToProps)(Project);