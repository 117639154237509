import React, { useRef, useState, useEffect } from "react";
import styles from "./AddProject.module.css";
import { AiOutlineClose } from "react-icons/ai";
import TextInput from "../../Components/TextInput/TextInput";
import { connect, useDispatch } from "react-redux";
import { createProject, fetchpropertyStage, fetchpropertyType } from "../../Services/contacts";
import Dropdown from "../../Components/DropDown/Dropdown";
import Loading from "../../Components/Loading/Loading";
import { showSnackbarAction } from "../../Redux/actions";
import { themeColors } from "../../Components/theme";
import Label from "../../Components/Label";

const propertyStatus=["Launched","Pre Launch","Intermediate Occupation"];

const AddProject = ({ history, user, theme, projectHeader }: any) => {
  const dispatcher = useDispatch();
  const projectNameRef: any = useRef();
  const developerNameRef: any = useRef();
  const reraLinkRef: any = useRef();
  const locationRef: any = useRef();

  const walkthroughLinkRef: any = useRef();
  const addressRef: any = useRef();
  const budgetRef: any = useRef();


  const [propertyStageSelected, setPropertyStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [projectStatusSelected, setProjectStatusSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertyStages, setPropertyStages] = useState<string[]>([]);




  const [propertySubTypeSelected, setPropertySubTypeSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertySubTypes, setPropertySubTypes] = useState<string[]>([]);


  useEffect(() => {
    let data = ["Office", "Mypod", "Vila", "Dupex", "Flat"];
    setPropertySubTypes(data);
  }, []);



  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    // fetchNewPropertySubType
    const unNewPropertyType = fetchpropertyType(
      user.organization_id,
      (data) => setPropertyTypes(data)
    );
    return () => {
      unNewPropertyType();
    };
  }, []);

  useEffect(() => {
    // fetchNewPropertySubType
    const getPropertyState = fetchpropertyStage(
      user.organization_id,
      (data) => setPropertyStages(data)
    );
    return () => {
      getPropertyState();
    };
  }, []);

  // useEffect(() => {
  //   let data = ["Ready to move in", "Under Construction"];
  //   setPropertyStages(data);
  // }, []);

  // useEffect(() => {
  //   let data = ["Commercial", "Residential"];
  //   setPropertyTypes(data);
  // }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
  }, []);

  const checkProject = () => {
    if (projectNameRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Project Name", "error"));
      return false;
    }
     else if (developerNameRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Developer Name", "error"));
      return false;
    }
    else if (walkthroughLinkRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Walkthrough Link", "error"));
      return false;
    }
    else if (addressRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Address", "error"));
      return false;
    }
    else if (
      propertyTypeSelected.label === "Select" ||
      propertyTypeSelected.label === ""
    ) {
      dispatcher(showSnackbarAction("Please Enter Property Type", "error"));
      return false;
    }
    else if (
      propertyStageSelected.label === "Select" ||
      propertyStageSelected.label === ""
    ) {
      dispatcher(showSnackbarAction("Please Enter Property Stage", "error"));
      return false;
    }
    else if (
      projectStatusSelected.label === "Select" ||
      projectStatusSelected.label === ""
    ) {
      dispatcher(showSnackbarAction("Please Enter Project Status", "error"));
      return false;
    }
    else {
      return true;
    }
  };

  const create = (e: any) => {
    e.preventDefault();
    setLoad(true);
    let check = false;
    check = checkProject();
    if (check === true) {
      createProject(
        user.organization_id,
        projectNameRef.current.value,
        developerNameRef.current.value,
        addressRef.current.value,
        reraLinkRef.current.value,
        propertyStageSelected.label,
        projectStatusSelected.label,
        // locationRef.current.value,
        walkthroughLinkRef.current.value,
        // budgetRef.current.value,
        propertyTypeSelected.label,
        // propertySubTypeSelected.label,
        user.user_first_name + " " + user.user_last_name,
        dispatcher,
        history,
        (data: boolean) => setLoad(data)

      )

    } else {
      setLoad(false);
    }
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }

  return (
    <div className={styles.parent} style={{...containerStyles,marginTop:"4%"}}>
      {load === true && <Loading />}
      <div
        // className={styles.headerView}
        className="d-flex flex-row w-100 px-4 pt-4"
      >
        <h5
        // className={styles.heading}
        > Add Project Details</h5>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/projects")}
        />
      </div>
      <div
        // className={styles.child}
        className="w-100 d-flex flex-column gap-2 px-4"
      // style={cardContainerStyles}
      >
        <form className="card pt-1 pb-4 px-4 shadow rounded mt-2 pt-4" style={cardContainerStyles}>
          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Developer Name</p>
              <p className={styles.two}>*</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "developer_name").value} required={true} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px" }}
                  ref={developerNameRef}
                ></TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Project Name</p>
              <p className={styles.two}>*</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "project_name").value} required={true} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px" }}
                  ref={projectNameRef}
                ></TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Property Type</p>
              <p className={styles.two}>*</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "property_type").value} required={true} />
              <div style={{ width: "140px", marginRight: "5px" }}>
                <Dropdown
                  option={propertyTypes}
                  selectedValue={propertyTypeSelected}
                  setSelectedValue={(value) => setPropertyTypeSelected(value)}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>

            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Address</p>
              <p className={styles.two}>*</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "address").value} required={true} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px" }}
                  ref={addressRef}
                />
              </div>
            </div>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Rera Link</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "rera_link").value} required={false} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px" }}
                  ref={reraLinkRef}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={projectHeader.find((obj:any) => obj.label === "walkthrough_link").value} required={true} />
              <div style={{ width: "150px" }}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff",height:"45px" }}
                  ref={walkthroughLinkRef}
                />
              </div>
            </div>
          </div>
          <div className={styles.box2}>
            <div className={styles.divide}>
              {/* <div className={styles.title}>
              <p className={styles.one}>Property Stage</p>
              <p className={styles.two}>*</p>
            </div> */}
              <Label label={projectHeader.find((obj:any) => obj.label === "property_stage").value} required={true} />
              <div style={{ width: "140px", marginRight: "5px" }}>
                <Dropdown
                  option={propertyStages}
                  selectedValue={propertyStageSelected}
                  setSelectedValue={(value) => setPropertyStageSelected(value)}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={projectHeader.find((obj:any) => obj.label === "project_status").value} required={true} />
              <div style={{ width: "140px", marginRight: "5px" }}>
                <Dropdown
                  option={propertyStatus}
                  selectedValue={projectStatusSelected}
                  setSelectedValue={(value) => setProjectStatusSelected(value)}
                />
              </div>
            </div>
          </div>
          <button className={styles.button} onClick={(e: any) => create(e)}>
            Submit
          </button>
        </form>
      </div>


    </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    projectHeader:state.projectHeaderList.data,
  };
};

export default connect(mapStateToProps)(AddProject);
