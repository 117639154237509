import React, { FunctionComponent, useState } from "react";
import styles from "./BranchModal.module.css";
import Modal from "react-modal";
import { useEffect } from "react";
import { fetchBranchList, setBranchPermission } from "../../../Services/users";
import { useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";

type props = {
  open: boolean;
  close: () => void;
  branchList: any[];
  uid: string;
};

const BranchModal: FunctionComponent<props> = ({
  open,
  close,
  branchList,
  uid,
}) => {
  const [branchSelected, setBranchSelected] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const dispatcher = useDispatch();

  useEffect(() => {
    fetchBranchList(uid, (data) => setBranchSelected(data));
  }, []);

  const onCheck = (event: any, value: string) => {
    if (value === "All") {
      if (event.target.checked) {
        setBranchSelected(["All"]);
      } else if (!event.target.checked) {
        let data = [...branchSelected];
        let item = branchSelected.indexOf("All");
        if (item > -1) {
          data.splice(item, 1);
        }
        setBranchSelected(data);
      }
    } else {
      if (event.target.checked) {
        let data = [...branchSelected];
        data.push(value);
        setBranchSelected(data);
      } else if (!event.target.checked) {
        let data = [...branchSelected];
        let item = branchSelected.indexOf(value);
        if (item > -1) {
          data.splice(item, 1);
        }
        setBranchSelected(data);
      }
    }
  };

  const onsave = () => {
    setLoad(true);
    setBranchPermission(
      uid,
      dispatcher,
      (data) => setLoad(data),
      branchSelected,
      close
    );
  };

  return (
    <Modal
      isOpen={open}
      className={styles.parent}
      overlayClassName={styles.overlay}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {load === true && <Loading />}
      <div className={styles.headingContainer}>
        <p className={styles.heading}>Select Branch</p>
      </div>
      {branchList.length !== 0 ? (
        branchList.map((item, index) => (
          <div key={index} className={styles.detail}>
            <input
              type="checkbox"
              onChange={(e) => {
                onCheck(e, item);
              }}
              checked={branchSelected.includes(item) ? true : false}
            />
            <p className={styles.option}>{item}</p>
          </div>
        ))
      ) : (
        <p className={styles.heading}>No Branch Added!!</p>
      )}
      <div className={styles.detail}>
        <input
          type="checkbox"
          onChange={(e) => {
            onCheck(e, "All");
          }}
          checked={branchSelected.includes("All") ? true : false}
        />
        <p className={styles.option}>All</p>
      </div>

      <div className={styles.buttonView}>
        <button className={styles.cancelButton} onClick={close}>
          Cancel
        </button>
        <button className={styles.saveButton} onClick={() => onsave()}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default BranchModal;
