import React, { FunctionComponent } from "react";
import { useMemo, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { PROJECT_COLUMNS } from "../../Values/tables";
import {
  fetchCallLogs,
  fetchUserCallLogs,
  fetchLeadDistributions,
  editLeadDistribution,
  deleteLeadDistribution,
} from "../../Services/contacts";
import { useTable, useSortBy, useFilters, useColumnOrder } from "react-table";
import TopBar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import Loading from "../../Components/Loading/Loading";
import { LEAD_DISTRIBUTOR_COLUMNS } from "../../Values/tables";
import { searchCallLogs } from "../../Values/utils";
import CustomTable from "../../Components/CustomTable/CustomTable";
import DistributionModel from "../../Components/Modals/DistributionModel/DistributionModel";
import styles from '../Analytics/Analytics.module.css';
import {themeColors} from "../../Components/theme";


type props = {
  user: any;
  contacts: any;
  history: any;
  role: any;
  organizationUsers: any;
  teamLeadUsers: any;
  theme: any;
};

const LeadDistributor: FunctionComponent<props> = ({
  user,
  contacts,
  history,
  role,
  organizationUsers,
  teamLeadUsers,
  theme
}) => {
  const dispatcher = useDispatch();
  const [filterData, setFilterData] = useState<any[]>([]);
  // const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [searchedItem, setsearchedItem] = useState("");
  const [columnModal, setColumnModal] = useState(false);

  useEffect(() => {
    if (user.organization_id) {
      const unsub = fetchLeadDistributions(user.organization_id, (data) =>
        setFilterData(data)
      );

      return () => {
        unsub();
      };
    }
  }, [user]);

  const handleDeleteLeadDistributor=(data:any,index:any)=>{
    if (window.confirm('Are you sure you want to delete this Lead Distribution Logic?')) {
      if (index !== undefined) {
        const newList = [...filterData];
        newList.splice(index, 1);
        setFilterData(newList);
        deleteLeadDistribution(
          user.organization_id,
          newList,
          dispatcher,
        );
      }
    }
  }

  const containerStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}

  return (
    <>
     <div className={styles.parent} style={{ display: "block", marginTop: "30px",...containerStyles }}>
     {filterData === undefined && <Loading />}
      <div className={styles.topBar} style={{ marginTop: "30px",...containerStyles }}>
        <TopBar
          history={history}
          title={"Lead Distribution"}
          path={"/addContacts"}
          onChange={(val) => setsearchedItem(val)}
          filterText={"Status"}
          setColumnModal={(data) => setColumnModal(data)}
          show={false}
          showStatusBox={false}
        />
      </div>
      <div className={commonStyle.parent} style={{minHeight:"450px",...containerStyles}}>
        <CustomTable
          tableColumns={LEAD_DISTRIBUTOR_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="LeadDistributor"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setSearchedItem={(val) => setsearchedItem(val)}
          handleDeleteLeadDistributor={handleDeleteLeadDistributor}
        />
      </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(LeadDistributor);
