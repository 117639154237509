import React, { FunctionComponent, useEffect, useState } from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Analytics from '../Screens/Analytics/Analytics';
import Reports from '../Screens/Reports/Reports';
import { Route } from 'react-router-dom';
import UserPanel from '../Screens/UserPanel/UserPanel';
import AddContacts from '../Screens/AddContacts/AddContacts';
import ContactDetails from '../Screens/ContactDetails/ContactDetails';
import EditContact from '../Screens/EditContact/EditContact';
import { connect, useDispatch } from 'react-redux';
import { fetchOrganizationsUsers } from '../Services/users';
import CallLogsPanel from '../Screens/CallLogsPanel/CallLogsPanel';
import Task from '../Screens/TasksPanel/TaskPanel';
import styles from '../Screens/Analytics/Analytics.module.css'
import AnalyticsSideNav from "../Components/AnalyticsSideNav/AnalyticsSideNav";
import AddBooking from '../Screens/Booking/AddBooking';
import HelpSupport from '../Screens/HelpSupport/HelpAndSupport';
import Booking from '../Screens/Booking/Booking'
import AppUpdateAlert from "../Components/AppUpdateAlert/AppUpdateAlert";
import TrailPreriodAlert from '../Components/TrialPeriodAlert/TrailPreriodAlert';
import SubscriptionAlert from '../Components/SubscriptionAlert/SubscriptionAlert';
import { logOut } from '../Services/auth';
import { useHistory } from "react-router";
import TeamLeadAnalytics from '../Screens/Analytics/TeamLeadAnalytics';
import SalesAnalytics from '../Screens/Analytics/SalesAnalytics';
// import HelpSupport from '../Screens/HelpSupport/HelpSupport';

type props = {
  user: any;
  history: any;
  leadsStage: any;
  showDeactivationAlert: any;
  paymentStatus: any;
  trialPeriodStatus: any;
  organizationUsers:any;
};

const UserRoute: FunctionComponent<props> = ({
  history,
  user,
  leadsStage,
  showDeactivationAlert,
  paymentStatus,
  trialPeriodStatus,
  organizationUsers
}) => {
  const dispatcher = useDispatch();
  const htry = useHistory();
  const [showAppUpdateAlert, setShowAppUpdateAlert] = useState(false);
  const [passFlag, setPassFlag] = useState(false);

  useEffect(() => {
    if (user.organization_id) {
      const unSub = fetchOrganizationsUsers(
        dispatcher,
        user.organization_id,
        user,
        setPassFlag
      );
      return () => {
        unSub();
      };
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user && organizationUsers) {
      if (passFlag === true) {
        const filterUser = organizationUsers?.filter((list: any) => list.uid === user.uid);
        if (filterUser[0]?.latestUpdateProfile === true) {
          logOut(htry, dispatcher);
        }
      }
    }
  }, [user, organizationUsers]);

  console.log("organizationUsers",organizationUsers);

  useEffect(() => {
    let lastAlertTime: any = localStorage.getItem('lastAlertTime');

    // If the last alert time was more than 6 hours ago or is null (i.e. it has never been shown)
    if (!lastAlertTime || Date.now() - lastAlertTime > 3 * 60 * 60 * 1000) {
      // Show the alert
      setShowAppUpdateAlert(true);
      let currentTime: any = Date.now();

      // Update the last alert time in localStorage
      localStorage.setItem('lastAlertTime', currentTime);
    }
  }, [])
  console.log("showDeactivationAlert",showDeactivationAlert);
  return (
    <>
      <Navbar
        props={{ history }}
        title={'Contacts'}
        path={'/'}
        leadManger={false}
        callLogs={'/callLogs'}
        task={'/tasks'}
      />
      {/* {((trialPeriodStatus && paymentStatus) && history.location.pathname !== "/subscriptionDetails")
        &&
        <TrailPreriodAlert role={"user"} />
      } */}
      {((trialPeriodStatus && paymentStatus) && history.location.pathname !== "/subscriptionDetails")
        &&
        <div style={{ position: 'fixed', zIndex: 1500,display:'flex',width:"100%",height:'auto',justifyContent:'center',alignItems:"center",marginTop:'5px'}}>
          <div style={{display:'flex',width:"63%",height:'20px',justifyContent:'center',alignItems:"center"}}>
          <TrailPreriodAlert />
          </div>
        </div>
      }
      {((showDeactivationAlert || paymentStatus === false) && history.location.pathname !== "/subscriptionDetails")
        &&
        <SubscriptionAlert paymentStatus={paymentStatus} />
      }
      {/* {showAppUpdateAlert && <AppUpdateAlert setShowAppUpdateAlert={setShowAppUpdateAlert} />} */}
      <div className={styles.parent}>
        <AnalyticsSideNav
          props={{ history }}
          title={'Contacts'}
          path={'/'}
          leadManger={false}
          operationManger={false}
          callLogs={'/callLogs'}
          task={'/tasks'}
        />
         <Route path="/helpSupport" component={HelpSupport} />
        {
          paymentStatus &&
          <>
            <Route exact path="/" component={UserPanel} />
            <Route
              path="/analytics"
              component={user.profile !== 'Sales' ? TeamLeadAnalytics : SalesAnalytics}
            />
            <Route path="/reports" component={Reports} />
            <Route path="/addContacts" component={AddContacts} />
            <Route path="/contactDetails" component={ContactDetails} />
            <Route path="/editContacts" component={EditContact} />
            <Route path="/callLogs" component={CallLogsPanel} />
            <Route path="/tasks" component={Task} />
            <Route path="/addBooking" component={AddBooking} />
            <Route path="/booking" component={Booking} />
            <Route path="/viewBookingDetails" component={AddBooking} />
            <Route path="/viewHelpSupport" component={HelpSupport} />
          </>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    leadsStage: state.leadsStage.stage,
    showDeactivationAlert: state.organization.showDeactivationAlert,
    paymentStatus: state.organization.paymentStatus,
    trialPeriodStatus: state.organization.organizationData.trial_period,
    organizationUsers: state.organizationUsers.data
  };
};

export default connect(mapStateToProps)(UserRoute);
