import React, { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { fetchCallLogs, fetchUserCallLogs } from '../../Services/contacts';

import TopBar from '../../Components/TopBar/TopBar';
import commonStyle from '../common.module.css';
import Loading from '../../Components/Loading/Loading';
import {
  booleanObject,
  CALLLOG_COLUMNS,
  datesField,
  CallLogColumns,
} from '../../Values/tables';
import { getDateString, searchCallLogs } from '../../Values/utils';
import CustomTable from '../../Components/CustomTable/CustomTable';
import axios from 'axios';
import moment from 'moment';
import {
  setFilterObject,
  setRefreshCallLogs,
  showSnackbarAction,
  clearAllFilters
} from '../../Redux/actions';
import ApiCustomTable from '../../Components/CustomTable/ApiCustomTable';
import ApiTopBar from '../../Components/TopBar/ApiTopBar';
import { url } from '../../Values/constants';
import styles from '../Analytics/Analytics.module.css';
import {themeColors} from "../../Components/theme";

type props = {
  user: any;
  contacts: any;
  history: any;
  role: any;
  organizationUsers: any;
  teamLeadUsers: any;
  filterObject: any;
  filterSort: any;
  refreshCallLogs: boolean;
  searchString: string;
  theme: any;
  callLogHeaders:any;
  authToken:any;
};

let isFinished = false;

const CallLogsPanel: FunctionComponent<props> = ({
  user,
  contacts,
  history,
  role,
  organizationUsers,
  teamLeadUsers,
  filterObject,
  searchString,
  filterSort,
  refreshCallLogs,
  theme,
  callLogHeaders,
  authToken
}) => {
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [callList, setCallList] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [searchedItem, setsearchedItem] = useState('');
  const [columnModal, setColumnModal] = useState(false);
  const [originalData, setOriginalData] = useState<any[] | undefined>(
    undefined
  );
  const [contactsMap, setcontactsMap] = useState<any>(undefined);

  const [lastPage, setLastPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allCallLogs, setAllCallLogs] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>({});
  const [filterCount, setFilterCount] = useState<any>({});
  const [showImportModal, setShowImportModal] = useState(false);

  const dispatcher = useDispatch();

  // useEffect(()=>{
  //   dispatcher(clearAllFilters({}));
  // },[])

  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {
    if (isFinished === true) {
      return;
    } else {
      setLoad(true);
      const filters: { [key: string]: any[] } = {};
      let feild;
      filters["transfer_status"] = [false];
      Object.keys(filterObject).forEach((item) => {
        if (filterObject && filterObject[item].length > 0) {
          if (item === "is_button_called" || item === "transfer_status" || item === "associate_status" || item === "source_status") {
            if (filterObject[item].length === 2) {
              filters[item] = [true, false];
            } else {
              filters[item] =
                filterObject[item][0] === "True" ? [true] : [false];
            }
          } else {
            if (item === "contact_no") {
            } else filters[item] = filterObject[item];
          }
        }
      });

      if (searchString === '') {
        feild = 'contact_no';
      } else {
        if (searchString.match(/^[0-9]+$/) != null) {
          feild = 'contact_no';
        } else {
          feild = 'customer_name';
        }
      }
      let records = localPageSize ? localPageSize : pageSize;
      const apiData = {
        uid: user.uid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: '-1' }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        filter: filters,
      };
      setFilterCount(filters);

      try {
        axios.defaults.headers.common['x-access-token'] =
authToken
        const res = await axios.post(url + '/callLogs/search', apiData);
 
        let data: any[] = res.data;
        if (data.length < records && paginate) {
          isFinished = true;
          setLastPage(page);
        }

        data.forEach((task) => {
          task.contactId = task.leadId;
          task.reporting_to = userMap[task.contact_owner_email];
          delete task['Id'];
          Object.keys(task).forEach((key) => {
            if (datesField.includes(key) && task[key] !== '') {
              if (task[key] !== null) {
                task[key] = moment(task[key]);
              } else {
                task[key] = '';
              }
            }
          });
        });

        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
        } else {
          setFilterData(data);
        }
        setLoad(false);
      } catch {
        setFilterData([]);
        setLoad(false);
        dispatcher(showSnackbarAction('Please Try Again!!', 'error'));
      }
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if (userMap === undefined) {
      setFilterData([]);
      return;
    }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    if(authToken){
      callApi(false, pageSize, 1);
    }
  }, [user, filterObject, filterSort, searchString, allCallLogs,authToken]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        if(authToken){
          callApi(false, pageSize);
        }
      } else {
        if(authToken){
          callApi(true, pageSize);
        }
      }
    }
  }, [page, pageSize,authToken]);

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if(authToken){
      callCountApi();
    }
  }, [refreshCallLogs, user.uid, filterCount,authToken]);

  const callCountApi = async () => {
    axios.defaults.headers.common['x-access-token'] =
authToken
    const res = await axios.post(url + '/callLogs/callLogCount', {
      uid: user.uid,
      leadFilter: filterCount
    });
    setTotalCounts(res.data.total);
  };



  useEffect(() => {
    if (refreshCallLogs === true && authToken) {
      callApi();
      getCallLogsFilter();
      dispatcher(setRefreshCallLogs(false));
    }
  }, [refreshCallLogs,authToken]);

  const getCallLogsFilter = async () => {
    axios.defaults.headers.common['x-access-token'] =
authToken
    const res = await axios.post(url + '/callLogs/filterValues', {
      uid: user.uid,
    });

    if (res.data[0]) {
      dispatcher(setFilterObject({ ...res.data[0], ...booleanObject }));
    } else {
      dispatcher(setFilterObject({}));
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      return;
    }
    if(authToken){
      getCallLogsFilter();
    }
  }, [user,authToken]);

  const exportFile = () => {
    let data: any[] = [];
    interface DataObj {
      label: any;
      value: any;
    }
    // const dbData= [{"label": "leadId","value": "Lead ID"},{"label": "customer_name","value": "Customer Name"},{"label": "contact_no","value": "Mobile No"},{"label": "stage","value": "Stage"},{"label": "contact_owner_email","value": "Contact Owner Email"},{"label": "location","value": "Location"},{"label": "project","value": "Project"},{"label": "budget","value": "Budget"},{"label": "transfer_status","value": "Transfer Status"},{"label": "created_by","value": "Created BY"},{"label": "created_at","value": "Created Date & Time"},{"label": "inventory_type","value": "Inventory Type"},{"label": "duration","value": "Duration"},{"label": "uid","value": "User ID"},{"label": "organization_id","value": "Organization ID"}];
    const dbData = callLogHeaders;
    const callHeaderLeadId :any = dbData.find((obj: DataObj) => obj.label === "leadId")?.value;
    const callHeaderCustomerName :any = dbData.find((obj: DataObj) => obj.label === "customer_name")?.value;
    const callHeaderOwner :any = dbData.find((obj: DataObj) => obj.label === "contact_owner_email")?.value;
    const callHeaderStage :any = dbData.find((obj: DataObj) => obj.label === "stage")?.value;
    const callHeaderProject :any = dbData.find((obj: DataObj) => obj.label === "project")?.value;
    const callHeaderLocation :any = dbData.find((obj: DataObj) => obj.label === "location")?.value;
    const callHeaderBudget :any = dbData.find((obj: DataObj) => obj.label === "budget")?.value;
    const callHeaderContactNo :any = dbData.find((obj: DataObj) => obj.label === "contact_no")?.value;
    // const callHeaderInventoryType :any = dbData.find((obj: DataObj) => obj.label === "inventory_type")?.value;
    const callHeaderTransferStatus :any = dbData.find((obj: DataObj) => obj.label === "transfer_status")?.value;
    const callHeaderCreatedBy :any = dbData.find((obj: DataObj) => obj.label === "created_by")?.value;
    const callHeaderCreatedAt :any = dbData.find((obj: DataObj) => obj.label === "created_at")?.value;
    const callHeaderDuration :any = dbData.find((obj: DataObj) => obj.label === "duration")?.value;
    if (selectedRowsData.length === 0) {
    } else {
      selectedRowsData.forEach((item) => {
        data.push({
          [callHeaderLeadId]: item.leadId,
          [callHeaderCustomerName]: item.customer_name,
          [callHeaderOwner]: item.contact_owner_email,
          [callHeaderStage]: item.stage,
          [callHeaderProject]: item.project,
          [callHeaderLocation]: item.location,
          [callHeaderBudget]: item.budget,
          [callHeaderContactNo]: item.contact_no,
          // [callHeaderInventoryType]: item.inventory_type,
          // Source: item.source,
          [callHeaderTransferStatus]: item.transfer_status,
          Status: item.status,
          [callHeaderCreatedBy]: item.created_by,
          [callHeaderDuration]: item.duration,
          [callHeaderCreatedAt]: getDateString(item.created_at),
        });
      });
    }

    return data;
  };

  const containerStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}

  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "4%", ...containerStyles }}>
      {filterData === undefined && <Loading />}
        <div className={commonStyle.topBar} style={{ marginTop: "0px", ...containerStyles }}>
          <ApiTopBar
            history={history}
            title={'Add Call Logs'}
            path={'/addContacts'}
            onClick={() => {
              setShowImportModal(true);
            }}
            onChange={(val) => setsearchedItem(val)}
            filterText={'Status'}
            setColumnModal={(data) => setColumnModal(data)}
            show={true}
            showStatusBox={true}
            searchedString={searchString}
            onExport={exportFile}
          />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px",...containerStyles }}>
        
          <ApiCustomTable
            tableColumns={CallLogColumns(callLogHeaders)}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="CallLogs"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            selectedRowsData={selectedRowsData}
            setSelectedRowsData={(data) => setSelectedRowsData(data)}
            setPage={(val) => setPage(val)}
            setPageSize={(val) => setPageSize(val)}
            callApi={callApi}
            pageSize={pageSize}
            page={page}
            isFinished={isFinished}
            totalCount={totalCounts}
            lastPage={lastPage}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    searchString: state.searchItem.callLogsSearchString,
    refreshCallLogs: state.refresh.refreshCallLogs,
    theme: state.theme.isLightMode,
    callLogHeaders:state.calllogsHeaderList.data,
    authToken:state.user.authToken,
  };
};
export default connect(mapStateToProps)(CallLogsPanel);
