import React, { useState, useEffect } from "react";
import commonStyle from "../common.module.css";
import Topbar from "../../Components/TopBar/TopBar";
import {
  booleanObject,
  datesField,
  TASK_COLUMNS,
  TasksColumns
} from "../../Values/tables";
import { connect, useDispatch } from "react-redux";
import { fetchTasks } from "../../Services/contacts";
import {
  getFilterObject,
  searchTaskItem,
  filterTaskStatus,
} from "../../Values/utils";
import styles from '../Analytics/Analytics.module.css';
import Loading from "../../Components/Loading/Loading";
import CustomTable from "../../Components/CustomTable/CustomTable";
import {
  getDateString,
  searchContacts,
} from "../../Values/utils";

import axios from "axios";

import moment from "moment";

import { url } from "../../Values/constants";
import {
  setFilterObject,
  setRefreshTasks,
  showSnackbarAction,
  clearAllFilters
} from "../../Redux/actions";
import ApiCustomTable from "../../Components/CustomTable/ApiCustomTable";
import ApiTopBar from "../../Components/TopBar/ApiTopBar";
import {themeColors} from "../../Components/theme";

let isFinished = false;
const Task = ({
  history,
  user,
  branchUsers,
  filterObject,
  searchString,
  filterSort,
  refreshTasks,
  theme,
  taskHeader,
  authToken
}: any) => {
  const [tasksList, setTasksList] = useState<
    any[] | undefined
  >(undefined);
  const [filterData, setFilterData] = useState<
    any[] | undefined
  >(undefined);
  const [temporaryData, setTemporaryData] = useState<any[]>(
    []
  );
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>(
    []
  );
  const [selectedRowsData, setSelectedRowsData] = useState<
    any[]
  >([]);
  const [showImportModal, setShowImportModal] =
    useState(false);
  const [uidMap, setUidMap] = useState<any>();

  const [lastPage, setLastPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allTasks, setAllTasks] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>({});
  const [filterCount, setFilterCount] = useState<any>({})
  const [searchedItem, setsearchedItem] = useState("");

  const dispatcher = useDispatch();

  // useEffect(()=>{
  //   dispatcher(clearAllFilters({}));
  // },[])


  useEffect(() => {
  }, [searchString]);

  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {
    if (isFinished === true) {
      return;
    } else {
      setLoad(true);
      const filters: { [key: string]: any[] } = {};
      let feild;
      filters["transfer_status"] = [false];
      Object.keys(filterObject).forEach((item) => {
        if (filterObject && filterObject[item].length > 0) {
          if (item === "is_button_called" || item === "transfer_status" || item === "associate_status" || item === "source_status" || item === "unique_meeting" || item === "unique_site_visit") {
            if (filterObject[item].length === 2) {
              filters[item] = [true, false];
            } else {
              filters[item] =
                filterObject[item][0] === "True" ? [true] : [false];
            }
          } else {
            if (item === "contact_no") {
            } else filters[item] = filterObject[item];
          }
        }
      });
      if (searchString === "") {
        feild = "contact_no";
      } else {
        if (searchString.match(/^[0-9]+$/) != null) {
          feild = "contact_no";
        } else {
          feild = "customer_name";
        }
      }
      let records = localPageSize
        ? localPageSize
        : pageSize;
      const apiData = {
        uid: user.uid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: "-1" }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        filter: filters,
      };
      setFilterCount(filters)

      try {
        axios.defaults.headers.common["x-access-token"] =
          authToken
        const res = await axios.post(
          url + "/tasks/search",
          apiData
        );
        let data: any[] = res.data;

        if (data.length < records && paginate) {
          isFinished = true;
          setLastPage(page);
        }

        data.forEach((task) => {
          if (task.status === "Pending" && task.due_date < new Date().toISOString()) {
            task.status = "Overdue";
          }
          task.contactId = task.leadId;

          task.reporting_to =
            userMap[task.contact_owner_email];
          delete task["Id"];
          Object.keys(task).forEach((key) => {
            if (
              datesField.includes(key) &&
              task[key] !== ""
            ) {
              if (task[key] !== null) {
                task[key] = moment(task[key]);
              } else {
                task[key] = "";
              }
            }
          });
        });
        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
          setLoad(false);
        } else {
          setFilterData(data);
          setLoad(false);
        }
      } catch {
        setFilterData([]);
        setLoad(false);
        dispatcher(
          showSnackbarAction("Please Try Again!!", "error")
        );
      }
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if (userMap === undefined) {
      setFilterData([]);
      return;
    }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    if(authToken){
      callApi(false, pageSize, 1);
    }
  }, [
    user,
    filterObject,
    filterSort,
    searchString,
    allTasks,
    authToken
  ]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        if(authToken){
          callApi(false, pageSize);
        }
      } else {
        if(authToken){
          callApi(true, pageSize);
        }
      }
    }
  }, [page, pageSize,authToken]);

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if(authToken){
      callCountApi();
    }
  }, [refreshTasks, user.uid, filterCount,authToken]);

  const callCountApi = async () => {
    axios.defaults.headers.common["x-access-token"] =
      authToken
    const res = await axios.post(url + "/tasks/taskCount", {
      uid: user.uid,
      leadFilter: filterCount
    });

    setTotalCounts(res.data.total);
  };

  useEffect(() => {
    if (refreshTasks === true) {
      if(authToken){
        callApi();
      getTasksFilter();
      dispatcher(setRefreshTasks(false));
      }
    }
  }, [refreshTasks,authToken]);

  const getTasksFilter = async () => {
    axios.defaults.headers.common["x-access-token"] =
      authToken
    const res = await axios.post(
      url + "/tasks/filterValues",
      {
        uid: user.uid,
      }
    );
    if (res.data[0]) {
      dispatcher(
        setFilterObject({
          ...res.data[0],
          ...booleanObject,
          status: ["Pending", "Overdue", "Completed"],
        })
      );
    } else {
      dispatcher(setFilterObject({}));
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      return;
    }
    if(authToken){
      getTasksFilter();
    }
  }, [user,authToken]);

  const exportFile = () => {
    let data: any[] = [];
    interface DataObj {
      label: any;
      value: any;
    }
    
    // const dbTaskHeader=[{"label": "leadId","value": "Lead Id"},{"label": "customer_name","value": "Customer Name"},{"label": "type","value": "Task Type"},{"label": "contact_owner_email","value": "Owner"},{"label": "stage","value": "Stage"},{"label": "project","value": "Project"},{"label": "location","value": "Location"},{"label": "budget","value": "Budget"},{"label": "contact_no","value": "Contact No"},{"label": "inventory_type","value": "Inventory Type"},{"label": "source","value": "Source"},{"label": "transfer_status","value": "Transfer Status"},{"label": "due_date","value": "Due Date and Time"},{"label": "completed_date_and_time","value": "Completed Date and Time"},{"label": "call_back_reason","value": "Call Reason"},{"label": "status","value": "Status"},{"label": "created_by","value": "Created By"},{"label": "created_at","value": "Created Date and Time"}];
    const dbTaskHeader=taskHeader;
    const dbHeaderLeadId :any = dbTaskHeader.find((obj: DataObj) => obj.label === "leadId")?.value;
    const dbHeaderCustomerName :any = dbTaskHeader.find((obj: DataObj) => obj.label === "customer_name")?.value;
    const dbHeaderTaskType :any = dbTaskHeader.find((obj: DataObj) => obj.label === "type")?.value;
    const dbHeaderOwner :any = dbTaskHeader.find((obj: DataObj) => obj.label === "contact_owner_email")?.value;
    const dbHeaderStage :any = dbTaskHeader.find((obj: DataObj) => obj.label === "stage")?.value;
    const dbHeaderProject :any = dbTaskHeader.find((obj: DataObj) => obj.label === "project")?.value;
    const dbHeaderLocation :any = dbTaskHeader.find((obj: DataObj) => obj.label === "location")?.value;
    const dbHeaderBudget :any = dbTaskHeader.find((obj: DataObj) => obj.label === "budget")?.value;
    const dbHeaderContactNo :any = dbTaskHeader.find((obj: DataObj) => obj.label === "contact_no")?.value;
    // const dbHeaderInventoryType :any = dbTaskHeader.find((obj: DataObj) => obj.label === "inventory_type")?.value;
    const dbHeaderSource :any = dbTaskHeader.find((obj: DataObj) => obj.label === "source")?.value;
    const dbHeaderTransferStatus :any = dbTaskHeader.find((obj: DataObj) => obj.label === "transfer_status")?.value;
    const dbHeaderDueDateandTime :any = dbTaskHeader.find((obj: DataObj) => obj.label === "due_date")?.value;
    const dbHeaderCompletedDateandTime :any = dbTaskHeader.find((obj: DataObj) => obj.label === "completed_date_and_time")?.value;
    const dbHeaderCallReason :any = dbTaskHeader.find((obj: DataObj) => obj.label === "call_back_reason")?.value;
    const dbHeaderStatus :any = dbTaskHeader.find((obj: DataObj) => obj.label === "status")?.value;
    const dbHeaderCreatedBy :any = dbTaskHeader.find((obj: DataObj) => obj.label === "created_by")?.value;
    const dbHeaderCreatedDateandTime :any = dbTaskHeader.find((obj: DataObj) => obj.label === "created_at")?.value;
    if (selectedRowsData.length === 0) {
    } else {
      selectedRowsData.forEach((item) => {
        data.push({
          [dbHeaderLeadId]: item.leadId,
          [dbHeaderCustomerName]: item.customer_name,
          [dbHeaderTaskType]: item.type,
          [dbHeaderOwner]: item.contact_owner_email,
          [dbHeaderStage]: item.stage,
          [dbHeaderProject]: item.project,
          [dbHeaderLocation]: item.location,
          [dbHeaderBudget]: item.budget,
          [dbHeaderContactNo]: item.contact_no,
          // [dbHeaderInventoryType]: item.inventory_type,
          [dbHeaderSource]: item.source,
          [dbHeaderTransferStatus]: item.transfer_status,
          [dbHeaderDueDateandTime]: getDateString(item.due_date),
          [dbHeaderCompletedDateandTime]: getDateString(
            item.completed_at
          ),
          [dbHeaderCallReason]: item.call_back_reason,
          [dbHeaderStatus]: item.status,
          [dbHeaderCreatedBy]: item.created_by,
          [dbHeaderCreatedDateandTime]: getDateString(
            item.created_at
          ),
        });
      });
    }

    return data;
  };

  const containerStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "4%",...containerStyles}}>
      {load === true && <Loading/>}
        <div className={commonStyle.topBar} style={{ marginTop: "0px", ...containerStyles }}>
          <ApiTopBar
            history={history}
            title={"Add Task"}
            path={"/addTasks"}
            onClick={() => {
              setShowImportModal(true);
            }}
            onChange={(val) => setsearchedItem(val)}
            filterText={"Status"}
            setColumnModal={(data) => setColumnModal(data)}
            show={true}
            showStatusBox={false}
            onExport={exportFile}
            searchedString={searchString}
          />
        </div>
        <div className={commonStyle.parent} style={{minHeight:"450px",...containerStyles}}>
          <ApiCustomTable
            tableColumns={TasksColumns(taskHeader)}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="Task"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            selectedRowsData={selectedRowsData}
            setSelectedRowsData={(data) =>
              setSelectedRowsData(data)
            }
            setPage={(val) => setPage(val)}
            setPageSize={(val) => setPageSize(val)}
            callApi={callApi}
            pageSize={pageSize}
            page={page}
            isFinished={isFinished}
            totalCount={totalCounts}
            lastPage={lastPage}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    branchUsers: state.branchUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    searchString: state.searchItem.tasksSearchString,
    refreshTasks: state.refresh.refreshTasks,
    theme: state.theme.isLightMode,
    taskHeader:state.taskHeaderList.data,
    authToken:state.user.authToken,
  };
};

export default connect(mapStateToProps)(Task);
