import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  contactResources,
  createContacts,
  fetchConstants,
  fetchpropertySubType,
  bulkEdit,
  fetchLeadTasks
} from "../../../Services/contacts";
import { connect, useDispatch } from 'react-redux';
import Loading from "../../Loading/Loading";
import Firebase from "firebase/app";
import { setRefreshContacts } from "../../../Redux/actions";
import Label from "../../../Components/Label";
import {themeColors} from "../../theme";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import { country_code, url } from "../../../Values/constants";
import Select from "react-select";
import { useLocation } from "react-router";

type props = {
  organization_id: string;
  close: () => void;
  open: boolean;
  rowsData: any[];
  theme: any;
  user: any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const BulkEditModal: FunctionComponent<props> = ({
  organization_id,
  close,
  open,
  rowsData,
  theme,
  user
}) => {
  const location: any = useLocation();
  const [stageSelected, setStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [projectSelected, setProjectSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });

  const [locationSelected, setlocationSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [budgetSelected, setBudgetSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertyStageSelected, setPropertyStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [notIntSelected, setNotIntSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [lostSelected, setLostSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadSourceSelected, setLeadSourceSelected] =
  useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadTypeSelected, setLeadTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
    const [countryCodeList, setCountryCodeList] = useState<
    string[]
  >([]);
    const [countryCode, setCountryCode] =
    useState<string>("");
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<any[]>([]);
  const [propertyStageList, setPropertyStageList] = useState<any[]>([]);
  const [notIntReasonList, setNotIntReasonList] = useState<any[]>([]);
  const [lostReasonList, setLostReasonList] = useState<any[]>([]);
  const [leadSourceList, setLeadSourceList] = useState<
  any[]
>([]);
const [leadTypeList, setleadTypeList] =
useState<any[]>([]);
const [comTypes, setComTypes] = useState<any[]>([]);
const [resTypes, setResTypes] = useState<any[]>([]);
  const [changeData, setChangeData] = useState<{ [key: string]: string }>({});
  const [load, setLoad] = useState(false);
  const [tasksList, setTasksList] = useState<
    any[] | undefined
  >(undefined);

  const dispatcher = useDispatch();
  const stage = [
    "FRESH",
    "WON",
    "CALL BACK",
    "INTERESTED",
    "LOST",
    "NOT-INTERESTED",
  ];

  useEffect(() => {
    let data: any[] = [];
    country_code.forEach((item) => {
      data.push(item.dial_code + " " + item.name);
    });
    setCountryCodeList(data);
  }, []);

  const choice = () => {
    let data = countryCodeList.map((item: any) => ({
      value: item,
      label: item,
    }));

    return data;
  };

  useEffect(() => {
    const unsub = contactResources(
      user.organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data),
    );

    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      // (data) => setnextFollowUpList(data)
    );
    let data = ["Data", "Leads"];
    setleadTypeList(data);
    const unsubProperty = fetchpropertySubType(
      user.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );

    return () => {
      unsub();
      unsubConst();
      unsubProperty();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    rowsData.forEach((item) =>{
      fetchLeadTasks(
      (data) => setTasksList(data),
      item.contactId
    );
  })
  }, [rowsData]);

  const apply = async () => {
    setLoad(true);
    
    
    await bulkEdit(
      { ...changeData, modified_at: Firebase.firestore.Timestamp.now() },
      rowsData,
      dispatcher,
      tasksList,
      (data) => setLoad(data),
      close,
    );
    setLoad(false);
    setTimeout(() => {
      dispatcher(setRefreshContacts(true));
    }, 2500);
    close();
  };
  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  };

  const containerStyles ={ backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark,maxHeight:"600px",overflowY:"auto"} 

  return (
  //   <Modal
  //   className={styles.parent}
  //   isOpen={open}
  //   shouldCloseOnOverlayClick={true}
  //   overlayClassName={styles.overlay}
  //   shouldCloseOnEsc={true}
  //   onRequestClose={close}
  //   style={customStyles}
  // >
  //   {load === true && <Loading />}
  //   <div className={styles.firstDiv}>
  //     {/* <p className={styles.contactForm}>Contact Form</p> */}
  //     <h5 
  //     // className={styles.contactForm}
  //     >Contact Form</h5>
  //     <div className={styles.cross} onClick={close}>
  //       <IoIosClose size={35} color={"#808080"} />
  //     </div>
  //   </div>
  //   <div className={styles.line}></div>
  //   <p 
  //   className={styles.text}
  //   >
  //     Bulk edit allows you to apply same value to multiple records.
  //   </p>
  //   <div className={styles.box2}>
  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Stage</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //       <Label label="Stage" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={stage}
  //           selectedValue={stageSelected}
  //           setSelectedValue={(value) => {
  //             console.log(value);
  //             setStageSelected(value);
  //             changeData["stage"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //           disable={true}
  //         />
  //       </div>
  //     </div>
  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Project</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //       <Label label="Project" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={projectsList}
  //           selectedValue={projectSelected}
  //           setSelectedValue={(value) => {
  //             setProjectSelected(value);
  //             changeData["project"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </div>

  //   <div className={styles.box2}>
  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Location</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //        <Label label="Location" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={locationsList}
  //           selectedValue={locationSelected}
  //           setSelectedValue={(value) => {
  //             setlocationSelected(value);
  //             changeData["location"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //         />
  //       </div>
  //     </div>

  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Budget</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //       <Label label="Budget" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={budgetsList}
  //           selectedValue={budgetSelected}
  //           setSelectedValue={(value) => {
  //             setBudgetSelected(value);
  //             changeData["budget"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </div>

  //   <div className={styles.box2}>
  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Property Type</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //       <Label label="Property Type" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={propertyTypeList}
  //           selectedValue={propertyTypeSelected}
  //           setSelectedValue={(value) => {
  //             setPropertyTypeSelected(value);
  //             changeData["property_type"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //         />
  //       </div>
  //     </div>
  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Property Stage</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //       <Label label="Property Stage" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={propertyStageList}
  //           selectedValue={propertyStageSelected}
  //           setSelectedValue={(value) => {
  //             setPropertyStageSelected(value);
  //             changeData["property_stage"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </div>

  //   <div className={styles.box2}>
  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Not Interested Reason</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //       <Label label="Not Interested Reason" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={notIntReasonList}
  //           selectedValue={notIntSelected}
  //           setSelectedValue={(value) => {
  //             setNotIntSelected(value);
  //             changeData["not_init_reason"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //         />
  //       </div>
  //     </div>

  //     <div className={styles.divide}>
  //       {/* <div className={styles.title}>
  //         <p className={styles.one}>Lost Reason</p>
  //         <p className={styles.two}></p>
  //       </div> */}
  //       <Label label="Lost Reason" required={false} />
  //       <div style={{ marginTop: "7px" }}>
  //         <Dropdown
  //           option={lostReasonList}
  //           selectedValue={lostSelected}
  //           setSelectedValue={(value) => {
  //             setLostSelected(value);
  //             changeData["lost_reason"] = value.value;
  //             setChangeData(changeData);
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </div>

  //   <button className={styles.apply} onClick={apply}>
  //     Apply
  //   </button>
  // </Modal>
  <>
  {load === true && <Loading />}
    <Backdrop>
        <motion.div
                   key="modal"
                   className="card p-4 shadow rounded"
                   style={{position: "absolute",
                   ...containerStyles}}
                   variants={dropIn}
                   initial="hidden"
                   animate="visible"
                   exit="exit"
                 >
    <div className={styles.firstDiv}>
      {/* <p className={styles.contactForm}>Contact Form</p> */}
      <h5 
      // className={styles.contactForm}
      >Mass Update Leads</h5>
      <div className={styles.cross} onClick={close}>
        <IoIosClose size={35} color={"#808080"} />
      </div>
    </div>
    <div className={styles.line}></div>
    <p 
    className={styles.text}
    >
      Bulk edit allows you to apply same value to multiple records.
    </p>
    <div className={styles.box2}>
      {/* <div className={styles.divide}> */}
        {/* <div className={styles.title}>
          <p className={styles.one}>Stage</p>
          <p className={styles.two}></p>
        </div> */}
        {/* <Label label="Stage" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={stage}
            selectedValue={stageSelected}
            setSelectedValue={(value) => {
              console.log(value);
              setStageSelected(value);
              changeData["stage"] = value.value;
              setChangeData(changeData);
            }}
            disable={true}
          />
        </div>
      </div> */}
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Project</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Project" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={projectsList}
            selectedValue={projectSelected}
            setSelectedValue={(value) => {
              setProjectSelected(value);
              changeData["project"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Not Interested Reason</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Country Code" required={false} />
        <div style={{ marginTop: "7px" }}>
           <Select
                  className={styles.select}
                  options={choice()}
                  onChange={(data) => {
                    if (data) {
                      // setCountryCode(data.label);
                      setCountryCode(data.label);
                      let countryCode = data.label.split(" ")[0]
                      changeData["country_code"] = countryCode;
                      setChangeData(changeData);
                    }
                  }}
                  isSearchable={true}
                  value={{
                    label: countryCode,
                    value: countryCode,
                  }}
                  menuPlacement="auto"
                  maxMenuHeight={150}
                />
        </div>
      </div>
    </div>

    <div className={styles.box2}>
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Location</p>
          <p className={styles.two}></p>
        </div> */}
         <Label label="Location" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={locationsList}
            selectedValue={locationSelected}
            setSelectedValue={(value) => {
              setlocationSelected(value);
              changeData["location"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>

      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Budget</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Budget" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={budgetsList}
            selectedValue={budgetSelected}
            setSelectedValue={(value) => {
              setBudgetSelected(value);
              changeData["budget"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>
    </div>

    <div className={styles.box2}>
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Property Type</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Property Type" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={propertyTypeList}
            selectedValue={propertyTypeSelected}
            setSelectedValue={(value) => {
              setPropertyTypeSelected(value);
              changeData["property_type"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Property Stage</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Property Stage" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={propertyStageList}
            selectedValue={propertyStageSelected}
            setSelectedValue={(value) => {
              setPropertyStageSelected(value);
              changeData["property_stage"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>
    </div>

    {/* <div className={styles.box2}>
      <div className={styles.divide}>
        <Label label="Not Interested Reason" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={notIntReasonList}
            selectedValue={notIntSelected}
            setSelectedValue={(value) => {
              setNotIntSelected(value);
              changeData["not_int_reason"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>

      <div className={styles.divide}>
        <Label label="Lost Reason" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={lostReasonList}
            selectedValue={lostSelected}
            setSelectedValue={(value) => {
              setLostSelected(value);
              changeData["lost_reason"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>
    </div> */}

    <div className={styles.box2}>
      <div className={styles.divide}>
        {/* <div className={styles.title}>
          <p className={styles.one}>Not Interested Reason</p>
          <p className={styles.two}></p>
        </div> */}
        <Label label="Lead Source" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={leadSourceList}
            selectedValue={leadSourceSelected}
            setSelectedValue={(value) => {
              setLeadSourceSelected(value);
              changeData["lead_source"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>

      <div className={styles.divide}>
        <Label label="Lead Type" required={false} />
        <div style={{ marginTop: "7px" }}>
          <Dropdown
            option={leadTypeList}
            selectedValue={leadTypeSelected}
            setSelectedValue={(value) => {
              setLeadTypeSelected(value);
              changeData["lead_type"] = value.value;
              setChangeData(changeData);
            }}
          />
        </div>
      </div>
      
    </div>

    <button className={styles.apply} onClick={apply}>
      Apply
    </button>
                 </motion.div>
    </Backdrop>
  </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(BulkEditModal);

