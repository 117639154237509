import React, {
    FunctionComponent,
    useEffect
  } from "react";
import styles from "./DarkModeSwitch.module.css";
import { connect, useDispatch} from "react-redux";
import {updateTheme} from "../../Redux/actions";

type props = {
    theme:any;
  };

const DarkModeSwitch: FunctionComponent<props> = ({
    theme
  }) => {
    const dispatch = useDispatch();
    const handleChange = (e:any) => {
        if (e.target.checked) {
          dispatch(updateTheme(true))
          localStorage.setItem("isLightMode","true")
        } else {
          dispatch(updateTheme(false))
          localStorage.setItem("isLightMode","false")
        }
    };
  return (
    <div>
    <input type="checkbox" checked={theme} onChange={handleChange} className={styles.checkbox} id="checkbox" />
  <label htmlFor="checkbox" className={styles.label}>
    <i className="fas fa-moon" style={{color:"white"}}></i>
    <i className='fas fa-sun' style={{color:"yellow"}}></i>
    <div className={styles.ball}></div>
  </label>
</div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    snackbarData: state.snackbar,
    theme:state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(DarkModeSwitch);
