import React, { FunctionComponent } from "react";
import styles from "./StatusFilter.module.css";
import classNames from "classnames";
import {themeColors} from "../theme";
import { connect, useDispatch } from 'react-redux';

type props = {
  setStatus: (status: string) => void;
  status?: string;
  taskFilter?: boolean;
  projectFilter?: boolean;
  setExpand: (data: boolean) => void;
  expand: boolean;
  theme: any;
};

const StatusFilter: FunctionComponent<props> = ({
  setStatus,
  status,
  taskFilter,
  projectFilter,
  setExpand,
  expand,
  theme,
}) => {
  const filterClass = classNames(styles.parent, {
    [styles.showFilterAnim]: expand === true,
  });
  const containerStyles = {backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  return (
    <div className={filterClass} style={containerStyles}>
      <div className={styles.child}>
        {taskFilter === true ? (
          <>
            <p
              className={styles.text}
              // style={
              //   status === "Completed"
              //     ? { color: "#272944" }
              //     : { color: "#000" }
              // }
              onClick={() => {
                setStatus("Completed");
                setExpand(false);
              }}
            >
              COMPLETED
            </p>
            {/* <div className={styles.line}></div> */}
            <p
              className={styles.text}
              // style={
              //   status === "Pending" ? { color: "#272944" } : { color: "#000" }
              // }
              onClick={() => {
                setStatus("Pending");
                setExpand(false);
              }}
            >
              PENDING
            </p>
            {/* <div className={styles.line}></div> */}
            <p
              className={styles.text}
              // style={
              //   status === "Overdue" ? { color: "#272944" } : { color: "#000" }
              // }
              onClick={() => {
                setStatus("Overdue");
                setExpand(false);
              }}
            >
              OVERDUE
            </p>
            {/* <div className={styles.line}></div> */}
            <p
              className={styles.text}
              // style={
              //   status === "ALL" ? { color: "#272944" } : { color: "#000" }
              // }
              onClick={() => {
                setStatus("ALL");
                setExpand(false);
              }}
            >
              ALL
            </p>
          </>
        ) : projectFilter === true ? (
          <>
            <p
              className={styles.text}
              // style={
              //   status === "Commercial"
              //     ? { color: "#272944" }
              //     : { color: "#000" }
              // }
              onClick={() => {
                setStatus("Commercial");
                setExpand(false);
              }}
            >
              COMMERCIAL
            </p>
            {/* <div className={styles.line}></div> */}
            <p
              className={styles.text}
              // style={
              //   status === "Residential"
              //     ? { color: "#272944" }
              //     : { color: "#000" }
              // }
              onClick={() => {
                setStatus("Residential");
                setExpand(false);
              }}
            >
              RESIDENTIAL
            </p>
            {/* <div className={styles.line}></div> */}
            <p
              className={styles.text}
              // style={
              //   status === "ALL" ? { color: "#272944" } : { color: "#000" }
              // }
              onClick={() => {
                setStatus("ALL");
                setExpand(false);
              }}
            >
              ALL
            </p>
          </>
        ) : (
          <>
            <p
              className={styles.text}
              // style={
              //   status === "ACTIVE" ? { color: "#272944" } : { color: "#000" }
              // }
              onClick={() => {
                setStatus("ACTIVE");
                setExpand(false);
              }}
            >
              ACTIVE
            </p>
            {/* <div className={styles.line}></div> */}
            <p
              className={styles.text}
              // style={
              //   status === "INACTIVE" ? { color: "#272944" } : { color: "#000" }
              // }
              onClick={() => {
                setStatus("INACTIVE");
                setExpand(false);
              }}
            >
              INACTIVE
            </p>
            {/* <div className={styles.line}></div> */}
            <p
              className={styles.text}
              // style={
              //   status === "ALL" ? { color: "#272944" } : { color: "#000" }
              // }
              onClick={() => {
                setStatus("ALL");
                setExpand(false);
              }}
            >
              ALL
            </p>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};
export default connect(mapStateToProps)(StatusFilter);


