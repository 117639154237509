import React, { FunctionComponent, useRef,useEffect } from "react";
import { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddContacts/AddContacts.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import { createFAQ, fetchFAQ } from "../../Services/resources";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";

type props = {
  history: any;
  user: any;
  theme: any;
};
const AddFAQ: FunctionComponent<props> = ({ history, user, theme }) => {
  const [load, setLoad] = useState(false);
  const [question, setQuestion] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [sNumber, setSNumber] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqList, setfaqList] = useState<any[] | undefined>(undefined);
  const dispatcher = useDispatch();
  const questionRef: any = useRef();
  const videoUrlRef: any = useRef();
  const answerRef: any = useRef();
  const srNumberRef: any = useRef();
  useEffect(() => {
    if (user.organization_id) {
      fetchFAQ(user.organization_id, (data) => setfaqList(data));
    }
  }, [user]);

  const handleCheck = () => {
    if (questionRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Question!!", "error"));
      return false;
    } 
    // else if (videoUrlRef.current.value === "") {
    //   dispatcher(showSnackbarAction("Please Enter Video URL!!", "error"));
    //   return false;
    // }
    else if (answerRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Answer!!", "error"));
      return false;
    }
    else if (srNumberRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Serial Number!!", "error"));
      return false;
    }
    else if (srNumberRef.current.value) {
      const numberS = faqList?.some(m => m.serialNumber === srNumberRef.current.value);
      if (numberS) {
        dispatcher(showSnackbarAction("Serial Number already exists  !!", "error"));
        return false;
      }
      return true;
    }
     else {
      return true;
    }
  };
  const create = (e: any) => {
    e.preventDefault();
    const val = handleCheck();
    if (val) {
      setLoad(true);
      createFAQ(
        user.organization_id,
        questionRef.current.value,
        answerRef.current.value,
        srNumberRef.current.value,
        videoUrlRef.current.value,
        dispatcher,
        (data: boolean) => setLoad(data),
        history
      );
    }
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <div className="container-fluid p-4 d-flex flex-column gap-4" style={containerStyles}>
      <h3>Add FAQ</h3>
      <div className="w-100 d-flex flex-column gap-2" style={cardContainerStyles}>
        <form className="card p-4 shadow rounded" style={cardContainerStyles}>
         <div style={{display:'flex',justifyContent:"space-between",gap:"0.5rem"}}>
          <div className="mb-3 col-0.4">
            <Label label="S.NO" required />
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
            aria-describedby="emailHelp"
            ref={srNumberRef}
            onChange={(e) => setSNumber(e.target.value)}
            value={sNumber}
            />
          </div>
          <div className="mb-3 col-11">
            <Label label="Question" required />
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              ref={questionRef}
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
            />
          </div>
          </div>
          <div className="mb-3">
            <Label label="Video Link"/>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              ref={videoUrlRef}
              onChange={(e) => setVideoUrl(e.target.value)}
              value={videoUrl}
            />
          </div>
          <div className="mb-3">
            <Label label="Answer" required />
            <textarea
              className="form-control"
              id="exampleInputPassword1"
              rows={5}
              ref={answerRef}
              onChange={(e) => setAnswer(e.target.value)}
              value={answer}
            />
          </div>
          <button
            type="submit"
            // className="btn btn-primary mt-5"
            style={{ backgroundColor: "#272944" }}
            className={styles.button}
            onClick={(e) => create(e)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(AddFAQ);