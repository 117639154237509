import React, { FunctionComponent, useState, useRef } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { deleteFAQAdmin, fetchFAQ, fetchFAQAdmin } from "../../Services/resources";
import { deleteFAQ } from "../../Services/resources";
import styles from "./FAQPanel.module.css";
import Lottie from "react-lottie";
import noData from "../../Assets/animations/no-data.json";
import { themeColors } from "../../Components/theme";
import topBarStyles from "../../Components/TopBar/TopBar.module.css";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import PreviewVideo from "../FAQPanel/VideoPreview";


type props = { history: any; user: any, theme: any };
const FAQPanel: FunctionComponent<props> = ({ history, user, theme }) => {
  const csvLink: any = useRef();
  const [faqList, setfaqList] = useState<any[] | undefined>(undefined);
  const [faqListArr, setfaqListArr] = useState<any[]>([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.8);
  const dispatcher = useDispatch();

  const handleResize = () => {
    // Update maxHeight when the window is resized
    setMaxHeight(window.innerHeight * 0.8); // Adjust the multiplier as needed
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
      fetchFAQAdmin((data) => setfaqList(data));
  }, []);

  const toggleAccordion = (id: string) => {
    if (id === expandedId) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  const handleDelete = (deleteData: any) => {
    let faqId = deleteData;
    const filterData = faqList?.filter((list: any) => list.id != faqId?.id)
    deleteFAQAdmin(
      filterData,
      dispatcher,
      history,
    );
  }
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: noData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const headers = [
    { label: "S.No", key: "serialNumber" },
    { label: "Question", key: "question" },
    { label: "Answer", key: "answer" }
  ];

  useEffect(() => {
    let faqListArr: any[] = [];
    faqList?.map((list: any) => {
      faqListArr.push({ "serialNumber": list.serialNumber, "question": list.question, "answer": list.answer })
    })
    setfaqListArr(faqListArr);
  }, [faqList]);

  const handleVideoUrl = (event:any,item:any) => {
    event.preventDefault();
    if (item) {
      window.open(item, '_blank');
    }
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <div
      // className={styles.parent}
      className="container-fluid p-4 d-flex flex-column gap-4"
      style={{ minHeight: "100vh", height: "fit-content", ...containerStyles, marginTop: '4%' }}>
      {/* {faqList === undefined && <Loading />} */}
      <div
        // className={styles.topBar}
        className="d-flex justify-content-between"
      >
        <h5
        // className={styles.title}
        >FAQ Section</h5>
        <div className="d-flex justify-content-between ">
          <button
            className={styles.addButton}
            style={{ marginRight: "13px",width:"80px"}}
            onClick={() => history.push("/addFAQ")}
          >
            Add
          </button>
        </div>
      </div>
      <div
        className="d-flex flex-column gap-4"
        style={{ ...containerStyles, maxHeight: `${maxHeight}px`, overflowX: "hidden", overflowY: "scroll", paddingBottom: "5%" }}>
        {faqList && faqList?.length > 0 ? (
          faqList?.map((item, index) => (
            <div
              className="card p-4 shadow rounded"
              style={cardContainerStyles}
              key={index}>
              <div className={styles.headerContainer}>
                <h6 className={styles.heading}>{`Question : ${item.serialNumber}. ${item.question}`}</h6>
                <button className={styles.accordionButton} onClick={() => toggleAccordion(item.id)}>
                  {expandedId === item.id ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />}
                </button>
              </div>
              <div className={styles.line}></div>
              <p>{item.answer}</p>
              {expandedId === item.id &&
                <>
                  {item?.videoUrl === "" || item?.videoUrl === undefined ? <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize:"16px",
                    fontWeight:'500'
                  }}>Video not available</div> : <>
                    <PreviewVideo source={item.videoUrl} />
                  </>}
                </>
              }
              {user?.profile === "Lead Manager" ? <></> : <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}>
                  <div style={{ padding: "5px" }}><button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "rgb(254, 53, 95)",
                      border: "rgb(254, 53, 95)",
                      color: "white",
                      width: "80px",
                      height: "30px",
                      borderRadius: "0.25rem",
                      // fontFamily: "sans-serif",
                      boxSizing: "border-box"
                    }} type="button"
                    onClick={() => {
                      handleDelete(item)
                    }}
                    >
                    Delete</button></div>
                  <div style={{ padding: "5px" }}><button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#fe355f",
                      border: "rgb(254, 53, 95)",
                      color: "white",
                      width: "80px",
                      height: "30px",
                      borderRadius: "0.25rem",
                      // fontFamily: "sans-serif",
                      boxSizing: "border-box"
                    }} type="button"
                    onClick={() => history.push({
                      pathname: '/editFAQ',
                      state: { "allData": faqList, "item": item }
                    })}
                  >Edit</button></div>
                </div>
              </>}

            </div>
          ))
        ) : (
          <div>
            <Lottie options={defaultOptions} height={300} width={300} />
            <h5 className="text-center">No FAQ Available</h5>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(FAQPanel);