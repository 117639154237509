import React from "react";

const Label = ({
   label,
   required
  }: {
    label: any;
    required?: any;
  }) => {
  return (
    <label htmlFor="exampleInputEmail1" className="form-label" style={{fontSize:"14px",fontFamily:'poppins'}}>
        {label} {required && <span style={{ color: "#dc3545" }}>*</span>}
    </label>
  );
};

// const mapStateToProps = (state: any) => {
//   return {
//     snackbarData: state.snackbar,
//   };
// };

export default Label;

// export default connect(mapStateToProps)(CustomSnackbar);
