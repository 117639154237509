const deleteLeadsReducer = (
  state = {
    deleteLeadsStatus: false,
  },
  action: {
    type:
      | "SET_DELETE_LEAD_STATUS"
    payload: boolean;
  }
) => {
  switch (action.type) {
    case "SET_DELETE_LEAD_STATUS":
      return { ...state, deleteLeadsStatus: action.payload };

    default:
      return state;
  }
};

export default deleteLeadsReducer;

