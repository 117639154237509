import React, { FunctionComponent, useState } from "react";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { connect, useDispatch } from "react-redux";
import {themeColors} from "../../Components/theme";
import { motion,AnimatePresence } from "framer-motion/dist/framer-motion";
import Backdrop from "../../Components/Backdrop/Backdrop";
import successImage from "../../Assets/Images/success-tick.png";
import failureImage from "../../Assets/Images/failure-cross.png";
import { useHistory } from "react-router";

type props = {
  isSuccess: boolean;
  open: boolean;
  close: () => void;
  theme:any;
};

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 1,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};

const SuccessAndFailureModal: FunctionComponent<props> = ({
  isSuccess,
  open,
  close,
  theme
}) => {

  const history = useHistory();
  const containerStyles ={ backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark,maxWidth:"350px"} 
  return (
      <Backdrop>
         <motion.div
       key="modal"
       className="card p-2 shadow rounded"
       style={{position: "absolute",
       top:"20%",
       ...containerStyles}}
       variants={dropIn}
       initial="hidden"
       animate="visible"
       exit="exit"
     >
      <div style={{cursor:"pointer"}} className="w-100 d-flex justify-content-end align-items-end" onClick={
        ()=>{
          close();
          history.push("/");
        }
      }>
      <IoIosClose size={35} color={"#808080"} />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={isSuccess ? successImage : failureImage} alt="Success" width="50px" />
      {isSuccess ? <h3>Success</h3> : <h3>Failure</h3>}
      {isSuccess ? <p className="text-center">Request Successful, You can continue using Leads Rubix</p> : <p className="text-center">An error occured and your transaction failed, please try again</p>}
      </div>
     </motion.div>
      </Backdrop>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    contacts: state.contacts,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(SuccessAndFailureModal);
