import { auth, firestore, functions } from "../Firebase";
import {
  setRole,
  showSnackbarAction,
  updateUserStatus,
  setUser,
  clearUser,
  setOrganization,
  setOrganizationId,
  setFirstLogin,
  setPaymentStatus,
  setContactHeaderLists,
  setTaskHeaderLists,
  setprojectHeaderLists,
  setcalllogsHeaderLists,
  setapiHeaderLists,
  setOrganizationCreateDate
} from "../Redux/actions";
import { emailValidate, passwordValidate } from "../Values/validatorsnew";
import { url, admin_mail } from "../Values/constants";
import axios from "axios";
import { useDispatch } from "react-redux";


export const handleUser = (organizationId: any, user: any, dispatcher: any) => {
  const subscriber = firestore
    .collection('organizations')
    .doc(organizationId)
    .onSnapshot((data) => {
      if (data === null) {
        return;
      }
      const orgData = data.data();
      if (orgData) {
        // dispatcher(updateUser(data));
        // setUsersList({...user, ...data}, dispatcher);
        if (orgData.payment_status === false) {

          // firestore()
          //   .collection('users')
          //   .doc(user.uid).get().then((user)=>{
          //     let data = user.data();
          //     if(data && data.status === 'INACTIVE'){
          //       signOutFirebase(data.uid, navigation, dispatcher);
          //       Alert.alert('Inactive User', 'Please Contact Your Admin', [
          //         {
          //           text: 'Okay',
          //           onPress: () => {
          //             return;
          //           },
          //         },
          //       ]);
          //     }
          //   })
          if (user.profile !== "Lead Manager") {
            dispatcher(showSnackbarAction("Your Leads Rubix subscription has expired, Please contact your admin", "error"));
            dispatcher(setPaymentStatus({ paymentStatus: false }));
            auth.signOut();
          } else {
            dispatcher(setPaymentStatus({ paymentStatus: false }));
          }
        } else {
          dispatcher(setPaymentStatus({ paymentStatus: true }));
        }
      }
    });
  return subscriber;
};

export const checkUser = (dispatcher: any, history: any) => {
  auth.onAuthStateChanged(function (user) {
    if (user) {
      if (user.email === admin_mail) {
        // console.log("Check user function call...");
        // console.log("Super admin mail :",user.email);
        user.getIdTokenResult().then((result) => {
          if (
            result.claims.firstLogin === true ||
            result.claims.firstLogin === undefined
          ) {
            console.log("Result printed :",result);
            dispatcher(updateUserStatus(false));
            dispatcher(setFirstLogin(true));
          } else {
            console.log("Result Else Part:",result);
            if (result.claims.role) {
              if (result.claims.role === "superAdmin") {
                dispatcher(
                  setUser({
                    user_first_name: "SuperAdmin",
                    user_last_name: "",
                    user_email: user.email,
                  })
                );
              }
              // console.log("Super Admin Role set");
              if (result.claims.role === "organization") {
                if (user.email) {
                  fetchOrganizationData(user.email, result.claims.role, dispatcher);
                }
              } else if (result.claims.role !== "superAdmin") {
                fetchUserData(user.uid, result.claims.role, dispatcher);
              }

              dispatcher(setRole(result.claims.role));
              dispatcher(updateUserStatus(true));
              dispatcher(setFirstLogin(false));
            } else {
              // console.log("Unauthorized User...");
              dispatcher(setRole("unauthorized"));
              auth.signOut();
              dispatcher(showSnackbarAction("Unauthorized User !!", "error"));
            }
          }
        });
      }
      else{
      let docRef = firestore.collection("users").doc(user?.uid);
      docRef.get().then(async (doc) => {
        console.log("User Id Pass :",user?.uid);
        console.log("User Find successfully...",doc.data());
        if (doc.exists) {
          const data = doc.data();
          console.log("check user Active or Inactive:",doc.data());
          if (data?.status != "ACTIVE" || data?.latestUpdateProfile===true) {
            await auth.signOut();

            dispatcher(showSnackbarAction("User is Inactive Please contact Administrator", "error"));
          } else {
            let orgRef = firestore.collection("organizations").doc(data?.organization_id);
            orgRef.get().then(async (orgdoc) => {
              if (doc.exists) {
                const orgdata = orgdoc.data();
                dispatcher(setOrganizationCreateDate({ "created_at": orgdata?.created_at, "trialPeriod": orgdata?.trialPeriod }));
                if (orgdata?.status != "ACTIVE") {
                  await auth.signOut();
                  dispatcher(showSnackbarAction("Your organization is Inactive Please contact Administrator", "error"));
                } else {
                  getAllHeadersData(orgdata?.industry_id, dispatcher, user);
                  // user.getIdTokenResult().then((result) => {
                  //   if (
                  //     result.claims.firstLogin === true ||
                  //     result.claims.firstLogin === undefined
                  //   ) {
                  //     dispatcher(updateUserStatus(false));
                  //     dispatcher(setFirstLogin(true));
                  //   } else {
                  //     if (result.claims.role) {
                  //       if (result.claims.role === "superAdmin") {
                  //         dispatcher(
                  //           setUser({
                  //             user_first_name: "SuperAdmin",
                  //             user_last_name: "",
                  //             user_email: user.email,
                  //           })
                  //         );
                  //       }
                  //       if (result.claims.role === "organization") {
                  //         if (user.email) {
                  //           fetchOrganizationData(user.email, dispatcher);
                  //         }
                  //       } else if (result.claims.role !== "superAdmin") {
                  //         fetchUserData(user.uid, dispatcher);
                  //       }

                  //       dispatcher(setRole(result.claims.role));
                  //       dispatcher(updateUserStatus(true));
                  //       dispatcher(setFirstLogin(false));
                  //     } else {
                  //       dispatcher(setRole("unauthorized"));
                  //       auth.signOut();
                  //       dispatcher(showSnackbarAction("Unauthorized User !!", "error"));
                  //     }
                  //   }
                  // });
                }
                // Do something with the data, e.g. console.log(data);
              } else {
                console.log("No such document!");
              }
            }).catch((error) => {
              console.log("Error getting document:", error);
            });
          }
          // Do something with the data, e.g. console.log(data);
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("User collection Exceptions...");
        console.log("Error getting document:", error);
      });
    }
    } else {
      dispatcher(setFirstLogin(false));
      dispatcher(clearUser());
      dispatcher(updateUserStatus(false));
    }
  });
};
// export const fetchOrganizationData = (email: string, userRole: string, dispatcher: any,uid?:string) => {
//   firestore
//     .collection("organizations")
//     .where("admin_email_id", "==", email)
//     .get()
//     .then((organization) => {
//       if (organization) {
//         const data = organization.docs[0].data();
//         if (data.status === "ACTIVE") {
        
//           dispatcher(setOrganization(organization.docs[0].data()));
//           dispatcher(setOrganizationId(organization.docs[0].id));
          
//           dispatcher(
//             setUser({
//               user_first_name: data.admin_first_name,
//               user_last_name: data.admin_last_name,
//               user_email: data.admin_email_id,
//               organization_id: "u67o5FbsNukXsLqpxQe1",
//               admin_contact_number:data.admin_contact_number,
//               auth_id:data.auth_id
//             })
//           );
//           dispatcher(setRole(userRole));
//           dispatcher(updateUserStatus(true));
//           dispatcher(setFirstLogin(false));
//         } else {
//           dispatcher(
//             showSnackbarAction("User is INACTIVE! Contact Admin!!", "error")
//           );
//           auth.signOut();
//         }
//       } else {
//         console.log("org not found");
//       }
//     })
//     .catch((err) => console.log("fetch organization error", err));
// };
export const fetchOrganizationData = (email: string, userRole: string, dispatcher: any, uid?: string) => {
  firestore
    .collection("organizations")
    .where("admin_email_id", "==", email)
    .get()
    .then((organization) => {
      if (organization) {
        const data = organization.docs[0].data();
        if (data.status === "ACTIVE") {
          dispatcher(setOrganization(organization.docs[0].data()));
          dispatcher(setOrganizationId(organization.docs[0].id));
          
          // Fetch organization ID from "users" collection
          firestore
            .collection("users")
            .doc(uid)
            .get()
            .then((userDoc) => {
              if (userDoc.exists) {
                const userData = userDoc.data();
                dispatcher(
                  setUser({
                    user_first_name: data.admin_first_name,
                    user_last_name: data.admin_last_name,
                    user_email: data.admin_email_id,
                    organization_id: userData?.organization_id, // Use the document ID as organization ID
                    admin_contact_number: data.admin_contact_number,
                    auth_id: data.auth_id
                  })
                );
                dispatcher(setRole(userRole));
                dispatcher(updateUserStatus(true));
                dispatcher(setFirstLogin(false));
              } else {
                console.log("User document not found");
              }
            })
            .catch((err) => console.log("fetch user document error", err));
        } else {
          dispatcher(
            showSnackbarAction("User is INACTIVE! Contact Admin!!", "error")
          );
          auth.signOut();
        }
      } else {
        console.log("org not found");
      }
    })
    .catch((err) => console.log("fetch organization error", err));
};


export const fetchUserData = (uid: string, userRole: string, dispatcher: any) => {
  firestore
    .collection("users")
    .doc(uid)
    .get()
    .then((user) => {
      if (user) {
        let data: any = user.data();
        if (data.status === "ACTIVE") {
          dispatcher(setUser(user.data()));
          dispatcher(setRole(userRole));
          dispatcher(updateUserStatus(true));
          dispatcher(setFirstLogin(false));
        } else {
          auth.signOut();
          dispatcher(
            showSnackbarAction("User is INACTIVE! Contact Admin!!", "error")
          );
        }
      } else {
        console.log("user not found");
      }
    })
    .catch(() => console.log("fetch user error"));
};


export const signInFirebase = async (
  email: string,
  password: string,
  history: any,
  dispatcher: any,
  setShowLoader: (value: boolean) => void,
  setLoad: (value: boolean) => void,
  setUserProfileUid: any,
) => {
  let check = emailValidate(email);
  if (check.length === 0) {
    check = passwordValidate(password);
  }

  if (check.length === 0) {
    setLoad(true);
    try {
      if (email && password) {
        const useData = await auth.signInWithEmailAndPassword(email, password);
        setUserProfileUid(useData?.user?.uid);
        if (useData?.user?.email !== admin_mail) {
          localStorage.setItem("isLightMode","true")
          firestore
            .collection('users')
            .doc(useData?.user?.uid)
            .set(
              {
                latestUpdateProfile: false
              },
              { merge: true }
            )
        }
        setLoad(false);
      }
    } catch (error: any) {
      let errorMsg = "";
      setShowLoader(false);
      console.log("Sign In Error", error);
      if (error.code === "auth/wrong-password") {
        setShowLoader(false);
        errorMsg = "Invalid Password";
      } else if (error.code === "auth/user-not-found") {
        setShowLoader(false);
        errorMsg = "User Not Found";
      } else {
        setShowLoader(false);
        errorMsg = "Error! Try Again";
      }
      setShowLoader(false);
      dispatcher(showSnackbarAction(errorMsg, "error"));
      setLoad(false);
    }
  } else {
    dispatcher(showSnackbarAction(check, "error"));
    setLoad(false);
    setShowLoader(false);
  }
};

export const resetPassword = async (
  password: string,
  confirmPassword: string,
  dispatcher: any,
  oobCode: any,
  setLoad: (value: boolean) => void,
  setReset: (value: boolean) => void,
  firstLogin: boolean,
  history: any
) => {
  if (password === "" || confirmPassword === "") {
    dispatcher(showSnackbarAction("Please Enter Password", "error"));
  } else if (password !== confirmPassword) {
    dispatcher(showSnackbarAction("Password does not match", "error"));
  } else {
    if (oobCode) {
      try {
        setLoad(true);
        await auth.confirmPasswordReset(oobCode, confirmPassword);
        setLoad(false);
        setReset(true);
      } catch (e) {
        console.log("error:", e);
        setLoad(false);
      }
    } else if (firstLogin === true) {
      setLoad(true);
      const user = auth.currentUser;
      user
        ?.updatePassword(password)
        .then(() => {
          functions
            .httpsCallable("setFirstLogin")()
            .then(() => {
              dispatcher(showSnackbarAction("Password Updated !!", "success"));
              auth.signOut().then(() => {
                setLoad(false);
                history.push("/");
              });
            });
        })
        .catch((error) => {
          setLoad(false);
          console.log("password reset error", error);
          auth.signOut();
          dispatcher(
            showSnackbarAction("Login Again to Reset Password!!", "error")
          );

          history.push("/");
        });
    }
  }
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
  userEmail: string,
  dispatcher: any,
  // oobCode: any,
  setLoad: (value: boolean) => void,
  // setReset: (value: boolean) => void,
  // firstLogin: boolean,
  history: any,
  authToken: any,
) => {
  try {
    const bodyData = {
      old_password: oldPassword,
      new_password: newPassword,
      user_email: userEmail,
    }
    axios.defaults.headers.common['x-access-token'] =
      authToken
    const res = await axios.post(url + '/updateUserPassword', bodyData);
    setLoad(false);
    history.push("/");
    dispatcher(
      showSnackbarAction("Password Updated", "success")
    );
  } catch (error) {
    setLoad(false);
    dispatcher(
      showSnackbarAction("Wrong Password", "error")
    );
  }

};

export const getAllHeadersData = (
  industryId: string,
  dispatcher: any,
  user: any,
) => {
  console.log("Hit Get All Headers Api");
  try {
    const bodyData = {
      industry_id: industryId,
      status: "Launched",
    };
    console.log("Hit Get All Headers Api", bodyData);
    fetch(url + "/headers/getAllHeadersByIndustryId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((allHeaders) => {
        console.log("All Headers Data printed :-", allHeaders);
        const contactHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Contacts"
        );
        const tasksHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Tasks"
        );
        const projectHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Projects"
        );
        const callogHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "CallLogs"
        );
        const apiHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Api"
        );
        console.log("Response Return By Headers :", apiHeader.headers);
        dispatcher(setContactHeaderLists(contactHeader.headers));
        dispatcher(setTaskHeaderLists(tasksHeader.headers));
        dispatcher(setprojectHeaderLists(projectHeader.headers));
        dispatcher(setcalllogsHeaderLists(callogHeader.headers));
        dispatcher(setapiHeaderLists(apiHeader.headers));

        user.getIdTokenResult().then((result: { claims: { firstLogin: boolean | undefined; role: string; }; }) => {
          if (
            result.claims.firstLogin === true ||
            result.claims.firstLogin === undefined
          ) {
            dispatcher(updateUserStatus(false));
            dispatcher(setFirstLogin(true));
          } else {
            if (result.claims.role) {
              if (result.claims.role === "superAdmin") {
                dispatcher(
                  setUser({
                    user_first_name: "SuperAdmin",
                    user_last_name: "",
                    user_email: user.email,
                  })
                );
              }
              if (result.claims.role === "organization") {
                if (user.email) {
                //  console.log("useruserdata",user);
                  fetchOrganizationData(user.email, result.claims.role, dispatcher,user.uid);
                }
              } else if (result.claims.role !== "superAdmin") {
                console.log("fetchUserData function called :", result.claims.role);
                fetchUserData(user.uid, result.claims.role, dispatcher);
              }
              // console.log("After Set User Data set role and user status:",result.claims.role);
              // dispatcher(setRole(result.claims.role));
              // dispatcher(updateUserStatus(true));
              // dispatcher(setFirstLogin(false));
            } else {
              dispatcher(setRole("unauthorized"));
              auth.signOut();
              dispatcher(showSnackbarAction("Unauthorized User !!", "error"));
            }
          }
        });
        // history.push("/");
        // dispatcher(
        //   showSnackbarAction("Password Updated", "success")
        // );
      })
      .catch((error) => {
        console.log("Exception provided by get header data:", error);
        // dispatcher(
        //   showSnackbarAction("Wrong Password", "error")
        // );
      });
  } catch (error) {
    console.log("Exception provided by get header data:", error);
  }
};

export const getAllHeadersDataOrg = (
  industryId: string,
  dispatcher: any,
) => {
  try {
    const bodyData = {
      industry_id: industryId,
      status: "Launched",
    };
    fetch(url + "/headers/getAllHeadersByIndustryId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((allHeaders) => {
        console.log("All Headers Data printed :-", allHeaders);
        const contactHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Contacts"
        );
        const tasksHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Tasks"
        );
        const projectHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Projects"
        );
        const callogHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "CallLogs"
        );
        const apiHeader = allHeaders.find(
          (obj: { screen_name: string }) => obj.screen_name === "Api"
        );
        console.log("Response Return By Headers :", apiHeader.headers);
        dispatcher(setContactHeaderLists(contactHeader.headers));
        dispatcher(setTaskHeaderLists(tasksHeader.headers));
        dispatcher(setprojectHeaderLists(projectHeader.headers));
        dispatcher(setcalllogsHeaderLists(callogHeader.headers));
        dispatcher(setapiHeaderLists(apiHeader.headers));
      })
      .catch((error) => {
        console.log("Exception provided by get header data:", error);
      });
  } catch (error) {
    console.log("Exception provided by get header data:", error);
  }
};

export const logOut = async (history: any, dispatcher: any) => {
  await auth.signOut();
  dispatcher(showSnackbarAction("Password has been changed please login again", "error"));
  history.push("/");
}