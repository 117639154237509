import React from "react";
import styles from "./ImportModal.module.css";

interface DataObject {
  [key: string]: any; // define your data object interface
}

interface Props {
  data: DataObject[]; // data to be included in the CSV file
// data:[];
}

const generateCSV = (data: DataObject[]): string => {
  if (!data.length) {
    return "";
  }

//   const headers = Object.keys(data[1]); // get the header information dynamically
const headers = data[0];
// const rows = data.map((row) =>
//     headers.map((header) => JSON.stringify(row[header])).join(",")
//   );

//   return [headers.join(","), ...rows].join("\n");
return [headers.join(",")].join("\n");
};

const CSVGenerator: React.FC<Props> = ({ data }) => {
  const handleDownload = () => {
    const csvData = generateCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
       <button onClick={handleDownload} style={{ border:'none', backgroundColor:'#272944', color:'#fff', borderRadius:5 }}>Download Template</button>     
  );
};

export default CSVGenerator;
