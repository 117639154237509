import React, {
  useState,
  useEffect,
  FunctionComponent,
} from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { CUSTOM_BUTTONS_COLUMNS } from "../../Values/tables";

import {
  filterStatus,
  searchAPIItem,
} from "../../Values/utils";

import CustomTable from "../../Components/CustomTable/CustomTable";
import {
  fetchApi,
  fetchCustomButtons,
} from "../../Services/organizations";
import styles from '../Analytics/Analytics.module.css';
import AddCustomButtonModal from "../../Components/Modals/AddCustomButtonModal/AddCustomButtonModal";

type props = {
  history: any;
  organizationId: any;
};

const CustomButtonPanel: FunctionComponent<props> = ({
  history,
  organizationId,
}) => {
  const [searchQuery, setsearchQuery] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<
    any[] | undefined
  >(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>(
    []
  );
  const [selectedRowsData, setSelectedRowsData] = useState<
    any[]
  >([]);
  const [customButtonModal, setCustomButtonModal] =
    useState(false);
  const [customButtons, setCustomButtons] = useState<
    any[] | undefined
  >(undefined);
  const [status, setStatus] = useState("ALL");
  const [temporaryData, setTemporaryData] = useState<any[]>(
    []
  );
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (organizationId) {
      fetchCustomButtons(organizationId, (val) =>
        setCustomButtons(val)
      );
    }
  }, [organizationId]);

  useEffect(() => {
    customButtons?.forEach((item: any) => {
      item.parameters = JSON.stringify(item.parameters);
      item.headers = JSON.stringify(item.headers);
    });

    setFilterData(customButtons);
  }, [customButtons]);

  return (
    <>
     <div className={styles.parent} style={{ display: "block", marginTop: "0px", backgroundColor: "white" }}>
        <div className={commonStyle.topBar} style={{ marginTop: "0px", backgroundColor: "white" }}>
        <Topbar
          history={history}
          title={"Add Custom Button"}
          path={"/addUsers"}
          filterText="Status"
          show={false}
          setCustomButtonModal={(data) =>
            setCustomButtonModal(data)
          }
        />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
        <CustomTable
          tableColumns={CUSTOM_BUTTONS_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="Custom Button"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) =>
            setSelectedRowsData(data)
          }
        />
        </div>
      </div>
      {customButtonModal === true && (
        <AddCustomButtonModal
          open={customButtonModal}
          close={() => setCustomButtonModal(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
  };
};
export default connect(mapStateToProps)(CustomButtonPanel);
