import React, { FunctionComponent, useEffect, useRef } from "react";
import { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddContacts/AddContacts.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";
import { useHistory } from 'react-router';
import TextInput from "../../Components/TextInput/TextInput";
import { FaUser } from "react-icons/fa";
import { showSnackbarAction } from "../../Redux/actions";
import {
  emailValidate,
  phoneValidate,
} from "../../Values/validatorsnew";
import { updatePassword } from "../../Services/auth";
import { fetchOrganizationsUserDropDown } from "../../Services/organizations";
import axios from "axios";
import { url } from "../../Values/constants";

type props = {
  theme: any;
  authToken: any;
  user: any;
};
const UpdatePassword: FunctionComponent<props> = ({ theme, authToken, user }) => {
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const history = useHistory();
  const emailRef: any = useRef();
  const oldPasswordRef: any = useRef();
  const newPasswordRef: any = useRef();
  const confirmNewPasswordRef: any = useRef();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [finalDataList, setFinalDataList] = useState<any[]>([]);
  const [emailSelected, setEmailSelected] = useState<any | null>(null); 

  useEffect(() => {
    fetchOrganizationsUserDropDown(
      user.organization_id,
      (data) => setFinalDataList(data),
    );
  }, [user.uid])
  
  const handleEmailSelection = (e:any) => {
    const selectedUid = e.target.value;
    const selectedEmail = finalDataList.find(option => option.uid === selectedUid);
    setEmailSelected(selectedEmail); // Set the selected email object
  };

  const create = async (e: any) => {
    e.preventDefault();
    if (emailSelected === "") {
      dispatcher(
        showSnackbarAction(
          "User Email Required!!",
          "error"
        )
      );
      return;
    }
    // else if (emailRef.current.value !== "" &&
    //   emailValidate(emailRef.current.value) ===
    //   "Invalid Email") {
    //   dispatcher(
    //     showSnackbarAction(
    //       "Invalid Email",
    //       "error"
    //     )
    //   );
    //   return;
    // } 
    // else if (oldPasswordRef.current.value === "") {
    //   dispatcher(
    //     showSnackbarAction(
    //       "Old Password Required!!",
    //       "error"
    //     )
    //   );
    //   return;
    // } 
    else if (newPasswordRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "New Password Required!!",
          "error"
        )
      );
      return;
    }
    else if (confirmNewPasswordRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Confirm New Password Required!!",
          "error"
        )
      );
      return;
    }
    else if (confirmNewPasswordRef.current.value !== newPasswordRef.current.value) {
      dispatcher(
        showSnackbarAction(
          "Confirm Password and New Password should be same",
          "error"
        )
      );
      return;
    }
    // else if (oldPasswordRef.current.value == newPasswordRef.current.value) {
    //   dispatcher(
    //     showSnackbarAction(
    //       "Old password and new password cannot be the same",
    //       "error"
    //     )
    //   );
    //   return;
    // }
    setLoad(true);
    updateUserAuth();
  };

  const updateUserAuth = async () => {
    const bodyData = {
        uid: emailSelected.uid,
        newEmail:emailSelected.user_email,
        newPassword: newPasswordRef.current.value
    }
    try {
      const repUpdateUserAuth = await axios.post(url+'/utils/firebase-updateUser', bodyData);
        if (repUpdateUserAuth?.status == 200) {
          setLoad(false);
          dispatcher(showSnackbarAction("Password changed successfully!!", "success"));
          history.push("/");
        }
    } catch (error) {
      setLoad(false);
    }
};

  return (
    <div
      className={styles.parent}
      // className="container-fluid p-4 d-flex flex-column gap-4"
      style={{ ...containerStyles, marginTop: "4%" }}
    >
      {load === true && <Loading />}
      <div
        //  className={styles.headerView}
        className="d-flex flex-row w-100 px-4 pt-4"
      >
        <h5
        // className={styles.heading}
        >
          {" "}
          {"Update Password"}
        </h5>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/")}
        />
      </div>
      <div
        // className={styles.child}
        className="w-100 d-flex flex-column gap-2 px-4"
      >
        <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="User Email" required={true} />
              <div className={styles.inputContainer}>
                {/* <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={emailRef}
                // validator={emailValidate}
                >
                  <FaUser color={"#808080"} />
                </TextInput> */}
                <select
                 value={emailSelected ? emailSelected.uid : ""}
                  style={{ height: "33px", width: "100%", borderRadius: "5px", cursor: "pointer" }}
                  onChange={handleEmailSelection}
                >
                  <option value="">Select</option>
                  {finalDataList.map((option, index) => (
                    <option key={index} value={option?.uid}>
                      {option?.user_email}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.divide}>
            <Label label="New Password" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  // validator={emailValidate}
                  ref={newPasswordRef}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label="Confirm Password" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  // validator={emailValidate}
                  ref={confirmNewPasswordRef}
                />
              </div>
            </div>
          </div>
          {/* <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="Confirm Password" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  // validator={emailValidate}
                  ref={confirmNewPasswordRef}
                />
              </div>
            </div>
          </div> */}
          <button
            type="submit"
            // className="btn btn-primary mt-5"
            // style={{ backgroundColor: "#272944" }}
            className={styles.button}
            style={{ backgroundColor: theme ? "#272944" : "rgb(54, 56, 83)" }}
            onClick={(e: any) => {
              create(e);
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    authToken: state.user.authToken
  };
};

export default connect(mapStateToProps)(UpdatePassword);