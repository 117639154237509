import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import { FacebookProvider, LoginButton } from 'react-facebook';
import styles from "./Integration.module.css";
import { themeColors } from "../../Components/theme";
import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookLogin from "react-facebook-login";
import { FacebookProvider, LoginButton, Like } from 'react-facebook';
// import LinkedInPage from "../IntegrationLinkedin/IntegrationLinkedin";
import axios from "axios";
import {deleteKeyFromApiTokenFB, fetchApi, getFbsslsValueFromApiTokenFB, updateApiFBPageId, updateApiFBPageIdUnScr, updateApiTokenFB, updatedFBAllPage } from "../../Services/organizations";
import FormDialogFB from "./DialogFB";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  showSnackbarAction
} from "../../Redux/actions";
import { Link } from 'react-router-dom';
import { Circles, Audio, BallTriangle} from 'react-loader-spinner';
import FaceBookCrm from "../../Assets/Images/fbtocrm1.png";
import FaceBookCrmDark from "../../Assets/Images/fbtocrm1.png"
import Loading from "../../Components/Loading/Loading";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type props = {
  user: any;
  history: any;
  theme: any;
};
const loginButtonStyles = {
  backgroundColor: '#272944',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};
const IntegrationFacebook: FunctionComponent<props> = ({
  user,
  history,
  theme,
}) => {
  const dispatcher = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const [API, setAPI] = useState<any[] | undefined>(undefined);
  const [filterActivePages, setFilterActivePages] = useState<any[] | undefined>(undefined);
  const [filterDeactivePages, setFilterDeactivePages] = useState<any[] | undefined>(undefined);
  const [filterAPI, setFilterAPI] = useState<any[] | undefined>(undefined);
  const [allFacebookPages, setAllFacebookPages] = useState<any[] | undefined>(undefined);
  const [accessUserToken, setAccessUserToken] = React.useState('');
  const [rightOpen, setRightOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [userData, setUserData] = useState<any>();
  const [userPages, setUserPages] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const [progress, setProgress] = useState(0);
  

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  // const responseFacebook = (response: any) => {
  //   console.log("response", response);
  // };

  // const handleSubscriptionsOpenModel = () => {
  //   setOpen(true);
  // }
  useEffect(() => {
    if (user.organization_id) {
      fetchApi(user.organization_id, (val) => setAPI(val));
    }
  }, [user.organization_id]);

  useEffect(() => {
    // setLoad(true);
    if (API) {
      const filteredData = API?.filter((item: any) => item.source.toLowerCase() === 'facebook');
      setFilterAPI(filteredData);
      const pageIdArr: any = filteredData[0]?.page_id;
      const FacebookAllPages: any = filteredData[0]?.facebook_pages;
      setAllFacebookPages(FacebookAllPages);
      loginStatusCheck(FacebookAllPages);
      console.log('user FacebookAllPages ', FacebookAllPages)
      console.log("pageIdArr", pageIdArr)
      if (pageIdArr) {
        const filteredDataActiveData = FacebookAllPages?.filter((item: any) => pageIdArr.includes(item.id));
        const filteredDataDiactiveData = FacebookAllPages?.filter((item: any) => !pageIdArr.includes(item.id));
        console.log("filteredDataActiveData", filteredDataDiactiveData);
        console.log("filtActiveData", filteredDataActiveData);
        setFilterDeactivePages(filteredDataDiactiveData);
        setFilterActivePages(filteredDataActiveData);
        // setLoad(false);
      }
      else {
        console.log("DeactivePages", FacebookAllPages);
        setFilterDeactivePages(FacebookAllPages);
        // setLoad(false);
      }
    }
  }, [API]);

  // useEffect(() => {
  //   loginStatusCheck();
  // }, [])
  const loginStatusCheck = (allFacebookPages:any) => {
    window.FB.getLoginStatus(function (response: any) {
      if (response.status === 'connected') {
        setLoginStatus(true);
        console.log("check user Login", response);
        // if (!allFacebookPages || allFacebookPages.length === 0) {
        //   console.log("allFacebookPages in check if condition",allFacebookPages);
        //   fetchUserPages(response.authResponse.accessToken);
        // }
        window.FB.api('/me', { fields: 'name,picture' }, function (userData: any) {
          setUserData(userData);
          console.log('User data with profile useEffect:', userData);
        });
      } else if (response.status === 'not_authorized') {
        console.log('Not Authorized user');
      }
      else {
        console.log('user does not logged in to Facebook');
      }
    });
  }

  const handleFacebookLogin = () => {
    // Check if FB object is available (Facebook SDK is loaded)
    if (window.FB) {
      // Set the permissions you need for page access
      const permissions = ['public_profile', 'email', 'pages_show_list', 'pages_read_engagement', 'pages_manage_metadata', 'pages_manage_ads', 'leads_retrieval', 'ads_management', 'business_management'];
      // Trigger the Facebook login dialog
      window.FB.login(
        function (response: any) {
          if (response.authResponse) {
            // User has successfully authenticated
            console.log('User logged in:', response);
            if (response.status === 'connected') {
              setLoginStatus(true);
              setLoad(true);
              // setLoader(true);
            }
            // Fetch the granted permissions using the user's access token
            window.FB.api('/me/permissions', { access_token: response.authResponse.accessToken }, function (permsResponse: any) {
              const grantedPermissions = permsResponse.data.map((permission: any) => permission.permission);
              const missingPermissions = permissions.filter(permission => !grantedPermissions.includes(permission));
              if (missingPermissions.length === 0) {
                // The user has granted all necessary permissions
                console.log("missingPermissionstoken", response?.authResponse);
                APICallAccessToken(response?.authResponse);
                window.FB.api('/me', { fields: 'name,picture' }, function (userData: any) {
                  console.log('User data with profile:', userData);
                  setUserData(userData);
                });
                fetchUserPages(response.authResponse.accessToken);
              }
              else if (missingPermissions.includes("business_management")) {
                APICallAccessToken(response?.authResponse);
                window.FB.api('/me', { fields: 'name,picture' }, function (userData: any) {
                  setUserData(userData);
                });
                fetchUserPages(response.authResponse.accessToken);
              }
              else {
                // The user did not grant all necessary permissions
                console.log('Missing required permissions:', missingPermissions);
              }
            });
          } else {
            // User canceled the login or did not fully authorize
            console.log('User cancelled login or did not fully authorize.');
          }
        },
        { scope: permissions.join(',') } // Request the permissions you need
      );
    } else {
      // Handle the case where the FB object is not available (Facebook SDK failed to load)
      console.error('Facebook SDK not loaded.');
    }
  };
  const APICallAccessToken = async (response: any) => {
    try {
      const res = await axios.get(`https://graph.facebook.com/oauth/access_token`, {
        params: {
          grant_type: "fb_exchange_token",
          client_id: "296542553118517",
          client_secret: "143f8ed7ddec986f25598654d8b686f6",
          fb_exchange_token: response.accessToken
        }
      });
      if (res.status === 200) {
        console.log('Facebook API response Token:', res);
        const longToken = res?.data?.access_token;
        updateApiTokenFB(
          user.organization_id,
          longToken,
          dispatcher
        );
      }
    } catch (error) {
      console.error('Error posting to Facebook API:', error);
    }
  };
  const fetchUserPages = async (accessToken: any) => {
    // Make a call to the Facebook Graph API to get the user's pages
    window.FB.api('/me/accounts', { access_token: accessToken, limit: 500 }, async function (
      response: any
    ) {
      if (response.data) {
        // Check if the user has any pages
        const totalPages = response.data.length;
        if (totalPages > 0) {
          // User has pages, proceed with further actions
          try {
            const updatedUserPages = await Promise.all(
              response.data.map(async (item: any,index:any) => {
                const res = await axios.get(
                  `https://graph.facebook.com/v17.0/${item?.id}/leadgen_forms`,
                  {
                    params: {
                      access_token: item?.access_token,
                    },
                  }
                );
                if (res.status === 200) {
                  const countActive = res.data?.data?.length || 0;
                  if (item.id === item?.id) {
                    // Add formcount to the object if id matches
                    return {
                      ...item,
                      formcount: countActive,
                    };
                  } else {
                    return item;
                  }
                }
              })
            );
            console.log('updatedUserPages', updatedUserPages);
            setUserPages(updatedUserPages);
            updatedFBAllPage(
              user.organization_id,
              updatedUserPages
            )
          } catch (error) {
            console.error('Error posting to Facebook API:', error);
          }
          handlesubscribed(response.data);
          console.log('User has pages:', response.data);
        } else {
          // User does not have any pages
          console.log('User does not have any pages.');
        }
      } else {
        // Error occurred while fetching pages
        console.error('Error fetching user pages:', response.error);
      }
    });
  };

  const handlesubscribed = async (pagesdata: any) => {
    // setProgress(0); 
    let updateSubscribed: any[] = [];
    const totalSubscriptions = pagesdata.length;
    try {
      for (const [index, list] of pagesdata.entries()) {
        try {
          const res = await axios.post(`https://graph.facebook.com/${list?.id}/subscribed_apps`, {
            subscribed_fields: "leadgen",
            access_token: list?.access_token
          });
          setProgress(((index + 1) / totalSubscriptions) * 100);
          if (res.status === 200) {
            setLoader(true);
            setLoad(false);
            const page_id = list?.id;
            updateSubscribed.push(page_id);
            console.log('Facebook API response Token:', res);
          }
        } catch (error) {
          console.error('Error posting to Facebook API:', error);
        }
        // Update progress after each subscription
      }
      setLoader(false);
      setProgress(0);
      dispatcher(showSnackbarAction("Page add successfully!!", "success"));
      updateApiFBPageId(user.organization_id, updateSubscribed);
    } catch (outerError) {
      setLoader(false);
      console.error('Error iterating through userPages:', outerError);
    }
  }
  const handleUnsubscribed = async (id: any) => {
    const selectedObject = userPages?.find((option: any) => option.id === id);
    try {
      const res = await axios.delete(`https://graph.facebook.com/${selectedObject?.id}/subscribed_apps`, {
        params: {
          subscribed_fields: "leadgen",
          access_token: selectedObject?.access_token
        }
      });
      if (res.status === 200) {
        console.log("selectedObject", selectedObject);
        const page_id = selectedObject?.id;
        updateApiFBPageIdUnScr(
          user.organization_id,
          page_id
        );
        dispatcher(
          showSnackbarAction("Page unsubscribed successfully!!", "success")
        );
      }
    } catch (error) {
      console.error('Error unsubscribing from Facebook API:', error);
    }
  };

  const handleFacebookLogout = () => {
    // Check if FB object is available (Facebook SDK is loaded)
    if (window.FB) {
      window.FB.logout(function (response: any) {
        // User has been logged out successfully
        console.log('User logged out:', response);
        setLoginStatus(false);
        setUserData(null); // Clear user data
        clearFacebookSessionCookies();
        deleteKeyFromApiTokenFB(user.organization_id);
        // window.location.reload();
      });
    } else {
      // Handle the case where the FB object is not available (Facebook SDK failed to load)
      console.error('Facebook SDK not loaded.');
    }
  };

  const clearFacebookSessionCookies = () => {
    try {
      const expirationDate = new Date(0).toUTCString();
      document.cookie = `fr=; expires=${expirationDate}; path=/; domain=.facebook.com`;
      document.cookie = `xs=; expires=${expirationDate}; path=/; domain=.facebook.com`;
      document.cookie = `fbsr=; expires=${expirationDate}; path=/; domain=.facebook.com`;
      // Repeat the same for any other Facebook cookies if needed
      console.log("Facebook cookies cleared successfully.");
    } catch (error) {
      console.error("Error clearing Facebook cookies:", error);
    }
  };

  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  const buttonStyle = {
    /* Add your custom styles here */
    backgroundColor: 'blue',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };
  const rotation = progress === 0 ? 'rotate(1turn)' : 'rotate(0.25turn)';
  return (
    <>
      <div
        className={styles.parent}
        style={{ ...containerStyles, marginTop: "4%", overflow: "hidden" }}
      >
        {load === true && <Loading />}
        {loginStatus === true ? <>
          <div style={{ width: "90%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", margin: "20px 0px 20px 50px" }}>
            <div className="d-flex flex-row w-100">
              <a onClick={() => history.push("/integrations")}
                style={{ cursor: "pointer", textDecoration: "underline" }}>{"Integrations"}</a>
              <ArrowForwardIosIcon fontSize="small" />{"Facebook"}
            </div>
            <div style={{ ...containerStyles, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: "90%", justifyContent: "flex-start", alignItems: "flex-start", height: "660px", background: "white", paddingBottom: "50px", marginTop: "10px",borderRadius:"10px" }}>
              {/* sub block */}
              <div style={{ width: "auto", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column" }}>
                <div style={{ ...containerStyles, width: "96%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", marginLeft: "2%", height: "55px", background: "white" }}>
                  <div style={{ width: "90%", height: "50%", padding: "10px" }}>
                    <h6 style={{ fontSize: "17px", fontWeight: "600" }}>Facebook Integration</h6>
                  </div>
                  <div style={{ width: "15%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <button className={styles.buttoncancel} style={{ marginTop: "10px" }} onClick={() => { handleFacebookLogout() }}>LogOut</button>
                  </div>
                </div>
                {/* Lead content block */}
                <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap"}}>
                  {/* Lead block */}
                  <section style={{ marginLeft: "2%", width: "96%", height: "auto", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    {/* left side section */}
                    <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                      <div style={{ width: "100%", height: "80px", display: "flex", alignItems: "center", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "5px" }}>
                        {/* image block */}
                        <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <div style={{ width: "70px", height: "70px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid rgb(39, 41, 68)" }}>
                            <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={userData?.picture?.data?.url} />
                          </div>
                        </div>
                        {/* name block */}
                        <div style={{ width: "60%", height: "auto", display: "flex", flexDirection: "column", lineHeight: "2px" }}>
                          <h1 style={{ fontSize: "16px", fontWeight: "600", color: theme ? "rgb(39, 41, 68)" : "white", }}>{userData?.name}</h1>
                          <p style={{ color: "#106ebe", fontSize: '12px', fontWeight: "600" }}>Connected</p>
                        </div>
                        {/* icon block */}
                      </div>
                      {/* list of block start*/}
                      <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", zIndex: 1200, margin: "10px 0px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "5px",backgroundColor: loader==true ? "gainsboro" : ""}}>
                        <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                          {loader==true ? <> </>:<>
                            <div>
                            <h6 style={{ padding: "10px", fontSize: "18px", fontWeight: "600" }}>Connected Pages {`(${filterActivePages?.length})`}</h6>
                          </div>
                          <div>
                            <h6 style={{ padding: "10px", fontSize: "14px", fontWeight: "600", color: "var(--bs-link-color)", cursor: "pointer" }} onClick={handleFacebookLogin}> Manage Facebook Pages</h6>
                          </div>
                          </>}
                        </div>
                        <div style={{ maxHeight: '420px', overflowY: 'scroll', width: "100%" }}>
                          {loader === true ? 
                          <div style={{ marginBottom: "10px",display:'flex',justifyContent:"center",textAlign:'center'}}>
                            <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbar
                              value={progress}
                              maxValue={100}
                              text={`${Math.round(progress)}%`}
                              styles={{
                                root: {},
                                path: {
                                  stroke: `rgba(255, 255, 255, ${progress / 100})`,
                                  strokeLinecap: 'butt',
                                  transition: 'stroke-dashoffset 0.5s ease 0s',
                                  transform: rotation, // Apply rotation based on progress
                                  transformOrigin: 'center center',
                                },
                                trail: {
                                  stroke: '#262944',
                                  strokeLinecap: 'butt',
                                  transform: rotation, // Apply the same rotation to the trail
                                  transformOrigin: 'center center',
                                },
                                text: {
                                  fill: '#262944',
                                  fontSize: '20px',
                                  fontWeight:"bold"
                                },
                                background: {
                                  fill: '#FFFFFF',
                                },
                              }}
                            />
                          </div>
                            {/* <Circles color="rgb(39, 41, 68)" height={40} width='100%' /> */}
                            </div> : <>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                              <thead>
                                <tr>
                                  <th style={{
                                    padding: '10px', border: '1px solid #ddd', borderBottom: '2px solid #ddd',
                                    backgroundColor: '#f2f2f2',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: "rgb(39, 41, 68)"
                                  }}>Id</th>
                                  <th style={{
                                    padding: '10px', border: '1px solid #ddd', borderBottom: '2px solid #ddd',
                                    backgroundColor: '#f2f2f2',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: "rgb(39, 41, 68)"
                                  }}>Category</th>
                                  <th style={{
                                    padding: '10px', border: '1px solid #ddd', borderBottom: '2px solid #ddd',
                                    backgroundColor: '#f2f2f2',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: "rgb(39, 41, 68)"
                                  }}>Name</th>
                                  <th style={{
                                    padding: '10px', border: '1px solid #ddd', borderBottom: '2px solid #ddd',
                                    backgroundColor: '#f2f2f2',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: "rgb(39, 41, 68)"
                                  }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filterActivePages?.map((page: any, index: any) => (
                                  <tr key={index}>
                                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{page?.id}</td>
                                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                      {page?.category}
                                    </td>
                                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                      {page?.name}<br></br>
                                      <span style={{ fontWeight: "600", fontSize: "12px" }}> {`${page?.formcount} lead form connected`}</span>
                                    </td>
                                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                      <a style={{ color: "var(--bs-link-color)", cursor: "pointer" }} onClick={() => handleUnsubscribed(page?.id)}>remove</a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>}
                        </div>
                      </div>
                      {/* list of block end*/}
                    </div>
                  </section>
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
          :
          <>
            <div className="d-flex flex-row w-100 px-4 pt-4" style={{ marginLeft: "2%" }}>
              <a onClick={() => history.push("/integrations")} style={{ cursor: "pointer", textDecoration: "underline" }}>{"Integrations"}</a><ArrowForwardIosIcon fontSize="small" />{"Facebook"}
            </div>
            <div className="d-flex flex-row w-100 px-4 pt-3" style={{ marginLeft: "2%" }}>
              <h4> {"Facebook Integration"}</h4>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "90%", marginLeft: "2%"}}>
              <div
                style={{
                  ...containerStyles,
                  padding: "10px 20px",
                  width: "100%",
                  height: "75%",
                  marginLeft: "2%",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginRight: "10%"
                }}
              >
                <img src={theme ? FaceBookCrm : FaceBookCrmDark}
                  width={250}
                  className="mx-auto my-6 w-60">
                </img>
                <div className="mb-4 mt-4" style={{ fontSize: "14px" }}>
                  Receive new leads from your Facebook Lead Ads into
                  your Lead Rubix account.
                </div>
                <div className="mb-4" style={{ fontSize: "14px" }}>
                  Tap 'Login with Facebook' and select the Facebook Pages you want to receive leads from.
                </div>
                <button className="bg-brand-fb rounded font-medium py-2 px-3 select-none focus:outline-none focus:ring-0 my-4 text-white" style={{ backgroundColor: "#272944" }} onClick={handleFacebookLogin}>
                  <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="mr-1 svg-inline--fa fa-facebook fa-w-16 fa-lg">
                    <path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                      className=""></path>
                  </svg>
                  Login with Facebook
                </button>
              </div>
            </div>
          </>}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
  };
};
export default connect(mapStateToProps)(IntegrationFacebook);
