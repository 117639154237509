import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/database";

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyD7okrmP8vX3WUDditqQu8Qd_J7vVTTLVA",
//   authDomain: "digitalrubixcrm.firebaseapp.com",
//   projectId: "digitalrubixcrm",
//   storageBucket: "digitalrubixcrm.appspot.com",
//   messagingSenderId: "42476766984",
//   appId: "1:42476766984:web:472cae0263d195628a4ebd",
//   measurementId: "G-26PXT0XWKB"
// });

const app = firebase.initializeApp({
  apiKey: "AIzaSyAFM8WokoOZCVLYcKY2t3bkSNM25sW7IPw",
  authDomain: "leads-rubix.firebaseapp.com",
  projectId: "leads-rubix",
  storageBucket: "leads-rubix.appspot.com",
  messagingSenderId: "822678315299",
  appId: "1:822678315299:web:0f09820ee2f9decd32ec96",
  measurementId: "G-452GMVRXMR"
});

export const auth = app.auth();
export const firestore = app.firestore();
export const functions = app.functions();
export const storage = app.storage();
export const db = app.database();
export default app;
