import React, { FunctionComponent, useEffect, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Reports from "../Screens/Reports/Reports";
import { Route } from "react-router-dom";
import UserPanel from "../Screens/UserPanel/UserPanel";
import AddContacts from "../Screens/AddContacts/AddContacts";
import Task from "../Screens/TasksPanel/TaskPanel";
import Project from "../Screens/ProjectsPanel/ProjectsPanel";
// import AddProject from "../Screens/AddProject/AddProject";
import AddTasks from "../Screens/AddTasks/AddTasks";
// import Resources from "../Screens/Resources/Resources";
import ContactDetails from "../Screens/ContactDetails/ContactDetails";
import { connect, useDispatch } from "react-redux";
import { fetchOrganizationsUsers } from "../Services/users";
import EditContact from "../Screens/EditContact/EditContact";

import CallLogsPanel from "../Screens/CallLogsPanel/CallLogsPanel";
import ProjectDetails from "../Screens/ProjectDetails/ProjectDetails";
import ProjectEditModal from "../Components/Modals/ProjectEditModal/ProjectEditModal";
import AddFAQ from "../Screens/AddFAQ/AddFAQ";
import FAQPanel from "../Screens/FAQPanel/FAQPanel";
import LeadManagerAnalytics from "../Screens/Analytics/LeadManagerAnalytics";
// import ApiDataPanel from "../Screens/ApiDataPanel/ApiDataPanel";
// import LeadDistributor from "../Screens/LeadDistributor/LeadDistributor";
import "axios";
import axios from "axios";
import { setBranchUsersList } from "../Redux/actions";
import styles from "../Screens/Analytics/Analytics.module.css";
import EditFAQ from "../Screens/EditFAQ/EditFAQ";
// import MiniDrawer from "../Components/AnalyticsSideNav/SideNavBar";
import AnalyticsSideNav from "../Components/AnalyticsSideNav/AnalyticsSideNav";
import Booking from "../Screens/Booking/Booking";
import AddBooking from "../Screens/Booking/AddBooking";
import SubscriptionDetails from "../Screens/SubscriptionDetails/SubscriptionDetails";
import TransactionHistory from "../Screens/TransactionHistory/TransactionHistory";
import PaymentDetails from "../Screens/PaymentDetails/PaymentDetails";
import UpgradeUser from "../Screens/UpgradeUser/UpgradeUser";
import SubscriptionAlert from "../Components/SubscriptionAlert/SubscriptionAlert";
import AppUpdateAlert from "../Components/AppUpdateAlert/AppUpdateAlert";
import { boolean } from "yup";
import ReEnquiredApiDataPanel from "../Screens/ReEnquiredApiDataPanel/ReEnquiredApiDataPanel";
import TrailPreriodAlert from "../Components/TrialPeriodAlert/TrailPreriodAlert";
import { logOut } from "../Services/auth";
import { useHistory } from "react-router";
import HelpSupport from '../Screens/HelpSupport/HelpAndSupport';
import { themeColors } from "../Components/theme";
import NewsPanelLM from "../Screens/NewsPanel/NewsPanelLM";

type props = {
  user: any;
  history: any;
  leadsStage: any;
  organizationUsers: any;
  showDeactivationAlert: any;
  paymentStatus: any;
  trialPeriodStatus: any;
  theme:any;
};

const LeadManagerRoute: FunctionComponent<props> = ({
  history,
  user,
  leadsStage,
  organizationUsers,
  showDeactivationAlert,
  paymentStatus,
  trialPeriodStatus,
  theme
}) => {
  // const [lastAlertTime, setLastAlertTime]:any = useState(null);
  const [showAppUpdateAlert, setShowAppUpdateAlert] = useState(false);
  const [passFlag, setPassFlag] = useState(false);
  const dispatcher = useDispatch();
  const htry = useHistory();

  useEffect(() => {
    if (user.organization_id) {
      const unSub = fetchOrganizationsUsers(
        dispatcher,
        user.organization_id,
        user,
        setPassFlag
      );
      return () => {
        unSub();
      };
    }
  }, [user, dispatcher]);

  useEffect(() => {
    if (user && organizationUsers) {
      if (passFlag === true) {
        const filterUser = organizationUsers?.filter((list: any) => list.uid === user.uid);
        if (filterUser[0]?.latestUpdateProfile === true) {
          logOut(htry, dispatcher);
        }
      }
      let uidList: any[] = [];
      if (user.branchPermission && !user.branchPermission.includes("All")) {
        uidList.push(user.uid);
        organizationUsers.forEach((users: any) => {
          if (users.branch) {
            if (user.branchPermission.includes(users.branch)) {
              uidList.push(users.uid);
            }
          }
        });

        dispatcher(setBranchUsersList(uidList));
      }
      if (user.branchPermission && user.branchPermission.includes("All")) {
        uidList.push(user.uid);
        organizationUsers.forEach((users: any) => {
          uidList.push(users.uid);
        });
        dispatcher(setBranchUsersList(uidList));
      }
    }
  }, [user, organizationUsers]);


  // useEffect(() => {
  //   if (passFlag === true) {
  //     const filterUser = organizationUsers?.filter((list: any) => list.uid === user.uid);
  //     if (filterUser[0]?.latestUpdateProfile === true) {
  //       logOut(htry, dispatcher);
  //     }
  //   }
  // }, [passFlag])

  useEffect(() => {
    let lastAlertTime: any = localStorage.getItem('lastAlertTime');

    // If the last alert time was more than 6 hours ago or is null (i.e. it has never been shown)
    if (!lastAlertTime || Date.now() - lastAlertTime > 3 * 60 * 60 * 1000) {
      // Show the alert
      setShowAppUpdateAlert(true);
      let currentTime: any = Date.now();

      // Update the last alert time in localStorage
      localStorage.setItem('lastAlertTime', currentTime);
    }
  }, [])
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <>
      <Navbar
        props={{ history }}
        title={"Contacts"}
        path={"/"}
        leadManger={true}
        // project={"/projects"}
        task={"/tasks"}
        // resources={"/resources"}
        callLogs={"/callLogs"}
        faq={"/faq"}
        news={"/news"}
        // apiData={"/apiData"}
        // leadDistributor={"/leadDistributor"}
      />
      {/* {((trialPeriodStatus && paymentStatus) && history.location.pathname !== "/subscriptionDetails")
        &&
        <TrailPreriodAlert />
      } */}
      {((trialPeriodStatus && paymentStatus) && history.location.pathname !== "/subscriptionDetails")
        &&
        <div style={{ position: 'fixed', zIndex: 1500,display:'flex',width:"100%",height:'auto',justifyContent:'center',alignItems:"center",marginTop:'5px'}}>
          <div style={{display:'flex',width:"63%",height:'20px',justifyContent:'center',alignItems:"center"}}>
          <TrailPreriodAlert />
          </div>
        </div>
      }
      {((showDeactivationAlert || paymentStatus === false) && history.location.pathname !== "/subscriptionDetails")
        &&
        <SubscriptionAlert paymentStatus={paymentStatus} />
      }
      {/* {showAppUpdateAlert &&  <AppUpdateAlert setShowAppUpdateAlert={setShowAppUpdateAlert} />} */}
      <div className={styles.parent} style={{...containerStyles,overflow:"hidden",height:"100vh"}}>
        <AnalyticsSideNav
          props={{ history }}
          title={"Contacts"}
          path={"/"}
          leadManger={true}
          operationManger={false}
          // project={"/projects"}
          task={"/tasks"}
          // resources={"/resources"}
          callLogs={"/callLogs"}
          faq={"/faq"}
          news={"/news"}
          // apiData={"/apiData"}
          // leadDistributor={"/leadDistributor"}
          // helpSupport={"/helpSupport"}
        />
          {/* <Route path="/helpSupport" component={HelpSupport} /> */}
          <Route path="/viewHelpSupport" component={HelpSupport} />
        {
          paymentStatus &&
          <>
            <Route exact path="/" component={UserPanel} />
            <Route path="/analytics" component={LeadManagerAnalytics} />
            <Route path="/reports" component={Reports} />
            {/* <Route path="/addProjects" component={AddProject} /> */}
            <Route path="/addTasks" component={AddTasks} />
            <Route path="/contactDetails" component={ContactDetails} />
            <Route path="/tasks" component={Task} />
            {/* <Route path="/projects" component={Project} /> */}
            {/* <Route path="/resources" component={Resources} /> */}
            <Route path="/addContacts" component={AddContacts} />
            <Route path="/editContacts" component={EditContact} />
            <Route path="/callLogs" component={CallLogsPanel} />
            {/* <Route path="/projectDetails" component={ProjectDetails} />
            <Route path="/editProjects" component={ProjectEditModal} /> */}
            <Route path="/addFAQ" component={AddFAQ} />
            <Route path="/editFAQ" component={EditFAQ} />
            <Route path="/faq" component={FAQPanel} />
            {/* <Route path="/leadDistributor" component={LeadDistributor} /> */}
            {/* <Route path="/apiData" component={ApiDataPanel} /> */}
            {/* <Route path="/reenquiredLeads" component={ReEnquiredApiDataPanel} /> */}
            <Route path="/booking" component={Booking} />
            <Route path="/addBooking" component={AddBooking} />
            <Route path="/viewBookingDetails" component={AddBooking} />
            <Route path="/news" component={NewsPanelLM} />
            {/* <Route path="/helpSupport" component={HelpSupport} /> */}
          </>
        }
        {/* <Route path="/subscriptionDetails" component={SubscriptionDetails} /> */}
        <Route path="/transactionHistory" component={TransactionHistory} />
        <Route path="/paymentDetails" component={PaymentDetails} />
        <Route path="/upgradeUsers" component={UpgradeUser} />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    leadsStage: state.leadsStage.stage,
    organizationUsers: state.organizationUsers.data,
    showDeactivationAlert: state.organization.showDeactivationAlert,
    paymentStatus: state.organization.paymentStatus,
    trialPeriodStatus: state.organization.organizationData.trial_period,
    theme: state.theme.isLightMode,
  };
};
export default connect(mapStateToProps)(LeadManagerRoute);
