import React, { useEffect, useState, useRef, FunctionComponent } from "react";
import styles from "./AddUsers.module.css";
import TextInput from "../../Components/TextInput/TextInput";
import { FaUser } from "react-icons/fa";
import Dropdown from "../../Components/DropDown/Dropdown";
import AddModal from "../../Components/Modals/AddModal/AddModal";
import { emailValidate } from "../../Values/validatorsnew";
import { connect, useDispatch } from "react-redux";
import {
  updateTeams,
  updateDesignations,
  createUser,
  updateContactEditUsers,
  updateApiDataEditUsers,
  updateTasksEditUsers,
} from "../../Services/organizations";
import Loading from "../../Components/Loading/Loading";
import { showSnackbarAction } from "../../Redux/actions";
import { AiOutlineClose } from "react-icons/ai";
import {
  updateTeamsAction,
  updateDesignationsAction,
} from "../../Redux/actions";
import { useLocation } from "react-router-dom";
import { EditAdminUserDetails, EditUserDetails, fetchProfilesData } from "../../Services/users";
import { getFilterBranch } from "../../Values/utils";
import { themeColors } from "../../Components/theme";
import Label from "../../Components/Label";
import axios from "axios";
import { url } from "../../Values/constants";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type props = {
  user: any;
  history: any;
  organization: any;
  organizationId: string;
  organizationUsers: any[];
  branchList: any[];
  theme: any;
};

const AddUsers: FunctionComponent<props> = ({
  history,
  organization,
  organizationId,
  organizationUsers,
  branchList,
  theme,
}) => {
  const location: any = useLocation();
  const [inputTextPassword, setInputTextPassword] = useState(false);

  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const numberRef: any = useRef();
  const emaileRef: any = useRef();
  const branchRef: any = useRef();
  const passwordRef: any = useRef("");

  const [teamSelected, setTeamSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [designationSelected, setDesignationSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [reportingTo, setReportingTo] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [profileSelected, setProfileSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [brancheSelected, setBranchSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });

  const [teams, setTeams] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);
  const [userTeams, setUserTeams] = useState<any[]>([]);
  const [userDesignations, setUserDesignations] = useState<any[]>([]);
  const [profile, setProfile] = useState<string[]>([]);
  const [reporting, setReporting] = useState<any>({
    label: "",
    value: "",
  });

  const [load, setLoad] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showDesigModal, setShowDesigModal] = useState(false);
  const [booleanSendEmail, setBooleanSendEmail] = useState(false);
  const [pwd, setPwd] = useState("");
  const [reportingToProfile, setReportingToProfile] = useState<any>({
    label: "",
    value: "",
  });
  const [phoneCountryCode, setPhoneCountryCode] = useState('in');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [finalPhoneNumber, setFinalPhoneNumber] = useState(location?.state?.detail?.contact_no?location?.state?.detail?.contact_no:"");
  
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setFinalPhoneNumber(value);
    }
    setPhoneCountryCode(country?.dialCode);
    setPhoneNumber(value)
  };

  const dispatcher = useDispatch();



  useEffect(() => {
    let teamList: any = [];
    let designationList: any = [];
    organizationUsers?.map(item => {
      if (item.team) {
        teamList.push(item.team);
      }
      if (item.designation) {
        designationList.push(item.designation);
      }
    })
    setUserTeams(teamList);
    setUserDesignations(designationList);
  }, [organizationUsers])

  useEffect(() => {
    let allTeams = [...userTeams, ...organization.teams];
    let allDesignations = [...userDesignations, ...organization.designations];
    let uniqueTeams = Array.from(new Set(allTeams));
    let uniqueDesignations = Array.from(new Set(allDesignations));
    setTeams(uniqueTeams);
    setDesignations(uniqueDesignations);
    setBranches(branchList);

    const unSubProfile = fetchProfilesData((data: string[]) =>
      setProfile(data)
    );

    return () => {
      unSubProfile();
    };
    // eslint-disable-next-line
  }, [organization, userTeams, userDesignations]);

  useEffect(() => {
    if (location.state !== undefined) {

      setDesignationSelected({
        label: location.state.detail.designation,
        value: location.state.detail.designation,
      });
      setProfileSelected({
        label: location.state.detail.profile,
        value: location.state.detail.profile,
      });
      setTeamSelected({
        label: location.state.detail.team,
        value: location.state.detail.team,
      })
      setReportingTo({
        label: location.state.detail.reporting_to,
        value: location.state.detail.reporting_to,
      })
      setBranchSelected({
        label: location.state.detail.branch,
        value: location.state.detail.branch,
      })
    }
    // eslint-disable-next-line
  }, [designations, profile]);

  useEffect(() => {
    let data: any = [{}];
    let items: any = [{}];
    if (organizationUsers) {
      organizationUsers.forEach((item: any, index: number) => {
        data.push({
          label: `${item.user_first_name} ${item.user_last_name} (${item.user_email})`,
          value: item.user_email,
        });
        items.push({
          label: item.profile,
          value: item.user_email,
        });
      });

      setReporting(data);
      setReportingToProfile(items);
    }
  }, [organizationUsers]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
  }, []);

  const handleCheck = () => {
    if (firstNameRef.current.value === "") {
      dispatcher(showSnackbarAction("Enter First Name!!", "error"));
      return false;
    } else if (lastNameRef.current.value === "") {
      dispatcher(showSnackbarAction("Enter Last Name!!", "error"));
      return false;
    } else if (finalPhoneNumber === "") {
      dispatcher(showSnackbarAction("Enter Phone Number!!", "error"));
      return false;
    } else if (emaileRef.current.value === "") {
      dispatcher(showSnackbarAction("Enter Email!!", "error"));
      return false;
    } else if (
      teamSelected.label === "Select" ||
      teamSelected.label === "+ New Team"
    ) {
      dispatcher(showSnackbarAction("Select Team!!", "error"));
      return false;
    } else if (
      designationSelected.label === "Select" ||
      designationSelected.label === "+ New Designation"
    ) {
      dispatcher(showSnackbarAction("Select Designation!!", "error"));
      return false;
    } else if (profileSelected.label === "Select") {
      dispatcher(showSnackbarAction("Select Profile!!", "error"));
      return false;
    } else if (reporting.length === 0) {
      return true;
    } else if (reportingTo.value === "Select") {
      dispatcher(showSnackbarAction("Select Reporting to!!", "error"));
      return false;
    } else if (
      brancheSelected.label === "Select" ||
      brancheSelected.label === "+ New Branch"
    ) {
      dispatcher(showSnackbarAction("Enter Branch!!", "error"));
      return false;
    } else {
      return true;
    }
  };

  const create = (e: any) => {
    e.preventDefault();
    const val = handleCheck();
    let activeUsers = organizationUsers.filter(
      (item) => item.status === "ACTIVE"
    );
    const reporting_to = reporting.length === 0 ? "" : reportingTo.value;
    if (val) {
      if (!profile.includes(profileSelected.value)) {
        dispatcher(showSnackbarAction("Invalid Profile!!", "error"));
      } else {
        setLoad(true);
        if (organization.trial_period === true) {
          createUser(
            emaileRef.current.value,
            firstNameRef.current.value,
            lastNameRef.current.value,
            teamSelected.label,
            reporting_to,
            finalPhoneNumber,
            phoneCountryCode == "in" ? 91 : parseInt(phoneCountryCode),
            designationSelected.label,
            brancheSelected.label === "Select" ||
              brancheSelected.label === "+ New Branch"
              ? ""
              : brancheSelected.value,
            activeUsers.length < Number(organization.org_trial_period_users_licenses)
              ? "ACTIVE"
              : "INACTIVE",
            profileSelected.value,
            dispatcher,
            history,
            organizationId,
            organization.admin_first_name + " " + organization.admin_last_name,
            organization.country,
            organization.state,
            false,
            (data: boolean) => setLoad(data),
            reportingToProfile,
            (data: boolean) => setBooleanSendEmail(data),
            (data: any) => setPwd(data),
          );
        }
        else {
          createUser(
            emaileRef.current.value,
            firstNameRef.current.value,
            lastNameRef.current.value,
            teamSelected.label,
            reporting_to,
            finalPhoneNumber,
            phoneCountryCode == "in" ? 91 : parseInt(phoneCountryCode),
            designationSelected.label,
            brancheSelected.label === "Select" ||
              brancheSelected.label === "+ New Branch"
              ? ""
              : brancheSelected.value,
            activeUsers.length < Number(organization.no_of_employees)
              ? "ACTIVE"
              : "INACTIVE",
            profileSelected.value,
            dispatcher,
            history,
            organizationId,
            organization.admin_first_name + " " + organization.admin_last_name,
            organization.country,
            organization.state,
            false,
            (data: boolean) => setLoad(data),
            reportingToProfile,
            (data: boolean) => setBooleanSendEmail(data),
            (data: any) => setPwd(data),
          );
        }
      }
    }
  };

  useEffect(() => {
    if (booleanSendEmail) {
      sendResetPasswordEmail();
    }
  }, [booleanSendEmail]);

  const sendResetPasswordEmail = async () => {
    let email = emaileRef.current.value.toLowerCase()
    let firstName = firstNameRef.current?.value;
    let lastName = lastNameRef.current?.value;
    if (email !== "") {
      try {
        if (email) {
          // alert(email);
          setLoad(true);
          const resetPasswordEmailApiData = {
            "template_name": "Password_Reset_Notification",
            "replaceable_params": [
              { "%#username#%": `${firstName} ${lastName}` },
              { "%#password#%": pwd }
            ],
            "email": email
          }
          await axios.post(
            url + '/email/sendEmail',
            resetPasswordEmailApiData
          );
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password reset information have been sent on you registered email id",
              "success"
            )
          );
          history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  }
  const adminEditCheck = () => {
    if (firstNameRef.current.value === "") {
      firstNameRef.current.value = location.state.detail.user_first_name;
    }
    if (lastNameRef.current.value === "") {
      lastNameRef.current.value = location.state.detail.user_last_name;
    }
    if (finalPhoneNumber === "") {
      setFinalPhoneNumber(location.state.detail.contact_no);
    }
    if (
      designationSelected.label === "Select" ||
      teamSelected.label === "+ New Designation"
    ) {
      designationSelected.label = location.state.detail.designation;
    }
    if (profileSelected.label === "Select") {
      profileSelected.label = location.state.detail.profile;
    }
    if (inputTextPassword === true) {
      if (passwordRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "Password is Required!!",
            "error"
          )
        );
        return;
      }
    }
    return true;
  };

  const EditCheck = () => {
    if (firstNameRef.current.value === "") {
      firstNameRef.current.value = location.state.detail.user_first_name;
    }
    if (lastNameRef.current.value === "") {
      lastNameRef.current.value = location.state.detail.user_last_name;
    }
    if (finalPhoneNumber === "") {
      setFinalPhoneNumber(location.state.detail.contact_no);
      // numberRef.current.value = location.state.detail.contact_no;
    }
    if (
      designationSelected.label === "Select" ||
      teamSelected.label === "+ New Designation"
    ) {
      designationSelected.label = location.state.detail.designation;
    }
    if (profileSelected.label === "Select") {
      profileSelected.label = location.state.detail.profile;
    }
    if (reportingTo.value === "Select") {
      reportingTo.value = location.state.detail.reporting_to;
    }
    if (
      teamSelected.label === "Select" ||
      teamSelected.label === "+ New Team"
    ) {
      teamSelected.label = location.state.detail.team;
    }
    if (inputTextPassword === true) {
      if (passwordRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "Password is Required!!",
            "error"
          )
        );
        return;
      }
    }
    return true;
  };
  const adminEdit=(e:any)=>{
    e.preventDefault();
    const val = adminEditCheck();
    if (val) {
        if (emaileRef.current.value !== location.state.detail?.user_email) {
          if (inputTextPassword === false) {
            const isConfirmed = window.confirm("Are you sure you want to change Email Id?");
            if (isConfirmed) {
              setInputTextPassword(true);
            }
            else {
              emaileRef.current.value = location.state.detail?.user_email;
            }
          }
          else {
            setLoad(true);
            setInputTextPassword(false);
            sendResetPasswordEmail();
            updateUserAuth();
            //user email in contacts collection   
            updateContactEditUsers(
              location.state.detail.user_email,
              location.state.detail.organization_id,
              emaileRef.current?.value.toLowerCase()
            );
            //update in apidData collection
            updateApiDataEditUsers(
              location.state.detail.admin_email_id,
              location.state.detail.organization_id,
              emaileRef.current?.value.toLowerCase()
            )
            //update in Tasks collection
            updateTasksEditUsers(
              location.state.detail.admin_email_id,
              location.state.detail.organization_id,
              emaileRef.current?.value.toLowerCase()
            )
            EditAdminUserDetails(
              location.state.detail.uid,
              firstNameRef.current.value,
              lastNameRef.current.value,
              finalPhoneNumber,
              designationSelected.label,
              profileSelected.value,
              dispatcher,
              finalPhoneNumber !== location.state.detail.contact_no ? true: false,
              (data: boolean) => setLoad(data),
              history,
              emaileRef.current.value
            );
          }
        }
        else {
          setLoad(true);
          EditAdminUserDetails(
            location.state.detail.uid,
            firstNameRef.current.value,
            lastNameRef.current.value,
            finalPhoneNumber,
            designationSelected.label,
            profileSelected.value,
            dispatcher,
            finalPhoneNumber !== location.state.detail.contact_no ? true: false,
            (data: boolean) => setLoad(data),
            history,
            emaileRef.current.value
          );
        }
    }
  };
  
  const edit = (e: any) => {
    e.preventDefault();
    const val = EditCheck();
    const reporting_to = reporting.length === 0 ? "" : reportingTo.value;
    if (val) {
      if (!profile.includes(profileSelected.value)) {
        dispatcher(showSnackbarAction("Invalid Profile!!", "error"));
      }
      else {
        if (emaileRef.current.value !== location.state.detail?.user_email) {
          if (inputTextPassword === false) {
            const isConfirmed = window.confirm("Are you sure you want to change Email Id?");
            if (isConfirmed) {
              setInputTextPassword(true);
            }
            else {
              emaileRef.current.value = location.state.detail?.user_email;
            }
          }
          else {
            setLoad(true);
            setInputTextPassword(false);
            sendResetPasswordEmail();
            updateUserAuth();
            //user email in contacts collection   
            updateContactEditUsers(
              location.state.detail.user_email,
              location.state.detail.organization_id,
              emaileRef.current?.value.toLowerCase()
            );
            //update in apidData collection
            updateApiDataEditUsers(
              location.state.detail.admin_email_id,
              location.state.detail.organization_id,
              emaileRef.current?.value.toLowerCase()
            )
            //update in Tasks collection
            updateTasksEditUsers(
              location.state.detail.admin_email_id,
              location.state.detail.organization_id,
              emaileRef.current?.value.toLowerCase()
            )
            EditUserDetails(
              location.state.detail.uid,
              firstNameRef.current.value,
              lastNameRef.current.value,
              finalPhoneNumber,
              designationSelected.label,
              profileSelected.value,
              reporting_to,
              teamSelected.label,
              dispatcher,
              finalPhoneNumber !== location.state.detail.contact_no
                ? true
                : false,
              (data: boolean) => setLoad(data),
              history,
              reportingToProfile,
              brancheSelected.label === "Select" ||
                brancheSelected.label === "+ New Branch"
                ? location.state.detail.branch
                  ? location.state.detail.branch
                  : ""
                : brancheSelected.label,
              emaileRef.current.value
            );
          }
        }
        else {
          setLoad(true);
          EditUserDetails(
            location.state.detail.uid,
            firstNameRef.current.value,
            lastNameRef.current.value,
            finalPhoneNumber,
            designationSelected.label,
            profileSelected.value,
            reporting_to,
            teamSelected.label,
            dispatcher,
            finalPhoneNumber !== location.state.detail.contact_no
              ? true
              : false,
            (data: boolean) => setLoad(data),
            history,
            reportingToProfile,
            brancheSelected.label === "Select" ||
              brancheSelected.label === "+ New Branch"
              ? location.state.detail.branch
                ? location.state.detail.branch
                : ""
              : brancheSelected.label,
            emaileRef.current.value
          );
        }
      }
    }
  };

  const updateUserAuth = async () => {
    const bodyData = {
      uid: location?.state?.detail?.uid,
      newEmail: emaileRef.current?.value,
      newPassword: passwordRef.current?.value
    }
    try {
      const repUpdateUserAuth = await axios.post(
        `${url}/utils/firebase-updateUser`, bodyData);
      // handle response
      console.log("repUpdateUserAuth", repUpdateUserAuth);
    } catch (error) {
      // handle error
    }
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "200px" };
  return (
    <>
      <div className={styles.parent} style={{ ...containerStyles, marginTop: "4%" }}>
        {/* {organizationUsers === undefined && <Loading />} */}
        {load && <Loading />}
        <div
          // className={styles.headerView}
          className="d-flex flex-row w-100 px-4 pt-4"
        >
          <h5
          // className={styles.heading}
          >
            {location.state !== undefined
              ? "Edit User Details"
              : "Add User Details"}
          </h5>
          <AiOutlineClose
            className={styles.closeIcon}
            size={25}
            onClick={() => history.replace("/")}
          />
        </div>
        <div
          // className={styles.child}
          className="w-100 d-flex flex-column gap-2 px-4"
        >
          <form className="card p-4 shadow rounded mt-2" style={cardContainerStyles}>
            <div className={styles.box2}>
              <div className={styles.divide}>
                <Label label="First Name" required={true} />
                <div className={styles.inputContainer} style={{ width: "207px" }}>
                  <TextInput
                    title={""}
                    style={{ backgroundColor: "#fff", height: "45px", width: "210px" }}
                    ref={firstNameRef}
                    disabled={false}
                    value={
                      location.state ? location.state.detail.user_first_name : ""
                    }
                  >
                    <FaUser color={"#808080"} />
                  </TextInput>
                </div>
              </div>

              <div className={styles.divide}>
                <Label label="Last Name" required={true} />
                <div className={styles.inputContainer} style={{ width: "207px" }}>
                  <TextInput
                    title={""}
                    style={{ backgroundColor: "#fff", height: "45px", width: "210px" }}
                    ref={lastNameRef}
                    disabled={false}
                    value={
                      location.state ? location.state.detail.user_last_name : ""
                    }
                  >
                    <FaUser color={"#808080"} />
                  </TextInput>
                </div>
              </div>
              <div className={styles.divide}>
                <Label label="Mobile Number" required={true} />
                <div className={styles.inputContainer}>
                  <PhoneInput
                    country={phoneCountryCode}
                    value={location.state ? `${location.state.detail.admin_contact_code}+${location.state.detail.contact_no}` : phoneNumber}
                    onChange={handlePhoneChange}
                    inputStyle={{ width: 200, height: "45px" }}
                    containerStyle={{ height: "45px", color: "rgb(54, 56, 83" }}
                  />
                  {/* <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={numberRef}
                  disabled={false}
                  value={location.state ? location.state.detail.contact_no : ""}
                /> */}
                </div>
              </div>
              
            </div>

            <div className={styles.box2}>
              <div className={styles.divide}>
                <Label label="Email Id" required={true} />
                <div className={styles.inputContainer} style={{ width: "207px" }}>
                  <TextInput
                    title={""}
                    // style={
                    //   location.state !== undefined
                    //     ? { backgroundColor: "#f5f5f5",height:"45px",width:"210px" }
                    //     : { backgroundColor: "#fff",height:"45px",width:"210px" }
                    // }
                    style={{ backgroundColor: "#fff", height: "45px", width: "210px" }}
                    validator={emailValidate}
                    ref={emaileRef}
                    // disabled={location.state !== undefined ? true : false}
                    value={location.state ? location.state.detail.user_email : ""}
                  // value={location.state.detail.user_email}
                  />
                </div>
              </div>
              <div className={styles.divide}>
                <Label label="Designation" required={true} />
                <div style={{ ...selectInputContainerStyles }}>
                  <Dropdown
                    title={" + New Designation"}
                    option={designations}
                    setDetails={() => setShowDesigModal(true)}
                    selectedValue={designationSelected}
                    setSelectedValue={(value) => setDesignationSelected(value)}
                    disable={location.state !== undefined && location.state.detail.profile==="Admin"?true:false}
                  />
                </div>
              </div>
              <div className={styles.divide}>
                <Label label="Profile" required={true} />
                <div style={{ ...selectInputContainerStyles }}>
                  <Dropdown
                    option={profile}
                    selectedValue={profileSelected}
                    setSelectedValue={(value) => setProfileSelected(value)}
                    disable={location.state !== undefined && location.state.detail.profile==="Admin"?true:false}
                  />
                </div>
              </div>
            </div>
            {location.state !== undefined && location.state.detail.profile === "Admin"?<></>:<>
              <div className={styles.box2}>
              <div className={styles.divide}>
                <Label label="Team" required={true} />
                <div style={{ ...selectInputContainerStyles }}>
                  <Dropdown
                    title={" + New Team"}
                    option={teams}
                    setDetails={() => setShowTeamModal(true)}
                    selectedValue={teamSelected}
                    setSelectedValue={(value) => {
                      setTeamSelected(value);
                    }}
                    disable={false}
                  />
                </div>
              </div>
             <div className={styles.divide}>
                <Label label="Reporting To" required={true} />
                <div style={{ ...selectInputContainerStyles }}>
                  <Dropdown
                    choices={reporting}
                    selectedValue={reportingTo}
                    setSelectedValue={(value) => setReportingTo(value)}
                    disable={false}
                  />
                </div>
              </div>
              <div className={styles.divide}>
                <Label label="Branch" required={true} />
                <div>
                  <div style={{ ...selectInputContainerStyles }}>
                    <Dropdown
                      title={" + New Branch"}
                      option={branches}
                      setDetails={() => setShowBranchModal(true)}
                      selectedValue={brancheSelected}
                      setSelectedValue={(value) => {
                        setBranchSelected(value);
                      }}
                      disable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            </>}
            <div className={styles.box2}>
              {inputTextPassword === true && (
                <div className={styles.divide}>
                  <Label label="Password" required={true} />
                  <div className={styles.inputContainer}>
                    <TextInput
                      title={"Password"}
                      style={{ backgroundColor: "#fff", height: "45px", width: "165px" }}
                      ref={passwordRef}
                    />
                  </div>
                </div>
              )}
            </div>
            {location.state !== undefined ? (
                <button
                  className={styles.button}
                  style={{ backgroundColor: theme ? "#272944" : "rgb(54, 56, 83)" }}
                  onClick={(e) =>
                    location.state?.detail?.profile === "Admin" ? adminEdit(e) : edit(e)
                  }
                >
                  Update
                </button>
              ) : (
                <button
                  className={styles.button}
                  style={{ backgroundColor: theme ? "#272944" : "rgb(54, 56, 83)" }}
                  onClick={(e) => create(e)}
                >
                  Submit
                </button>
              )}
          </form>
        </div>
      </div>

      {showTeamModal && (
        <>
          <AddModal
            show={showTeamModal}
            heading={"Create Team"}
            subHeading={"Enter Name"}
            close={() => setShowTeamModal(false)}
            onSave={(value: string) => {
              updateTeams(
                value,
                setLoad,
                organizationId,
                (data: boolean) => {
                  dispatcher(updateTeamsAction(value));
                  setShowTeamModal(data);
                  setTeamSelected({ label: value, value });
                },
                dispatcher
              );
            }}
          />
        </>
      )}
      {showBranchModal && (
        <>
          <AddModal
            show={showBranchModal}
            heading={"Create Branch"}
            subHeading={"Enter Name"}
            close={() => setShowBranchModal(false)}
            onSave={(value: string) => {
              setBranchSelected({ label: value, value });
              setShowBranchModal(false);
            }}
          />
        </>
      )}
      {showDesigModal && (
        <>
          <AddModal
            show={showDesigModal}
            heading={"Create Designation"}
            subHeading={"Enter designation"}
            close={() => setShowDesigModal(false)}
            onSave={(value) => {
              updateDesignations(
                value,
                setLoad,
                organizationId,
                (data: boolean) => {
                  dispatcher(updateDesignationsAction(value));
                  setShowDesigModal(data);
                  setDesignationSelected({ label: value, value });
                },
                dispatcher
              );
            }}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organization: state.organization,
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    branchList: state.branchList.data,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(AddUsers);
