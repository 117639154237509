import React, { useRef, useState, useEffect } from "react";
import TextInput from "../../Components/TextInput/TextInput";
import styles from "./AddContacts.module.css";
import { FaUser, FaFlag } from "react-icons/fa";
import {
  emailValidate,
  phoneValidate,
} from "../../Values/validatorsnew";
import { connect, useDispatch } from "react-redux";

import {
  contactResources,
  createContacts,
  fetchConstants,
  fetchNewPropertySubType,
  fetchpropertySubType,
  fetchpropertyType,
} from "../../Services/contacts";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import { showSnackbarAction } from "../../Redux/actions";
import { country_code, url } from "../../Values/constants";
import Select from "react-select";
import Dropdown from "../../Components/DropDown/Dropdown";
import { useLocation } from "react-router";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";
import axios from "axios";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddContacts = ({
  history,
  user,
  organizationUsers,
  role,
  theme,
  conatctHeader,
  authToken,
  organizationData,
}: {
  history: any;
  user: any;
  organizationUsers: any;
  role: string;
  theme: any;
  conatctHeader: any;
  authToken: any;
  organizationData: any;
}) => {
  const dispatcher = useDispatch();
  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const numberRef: any = useRef();
  const emaileRef: any = useRef();
  const alternateRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState<
    string[]
  >([]);
  const [phone, setPhone] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState('in');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [finalPhoneNumber, setFinalPhoneNumber] = useState('');
  const [countryCode, setCountryCode] =
    useState<string>("");
  const [note, setNote] = useState("");
  const noteRef: any = useRef();
  const [projectSelected, setProjectSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

  const [locationSelected, setlocationSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [budgetSelected, setBudgetSelected] = useState<any>(
    {
      label: "Select",
      value: "Select",
    }
  );
  const [propertyTypeSelected, setPropertyTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [leadTypeSelected, setLeadTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [propertyStageSelected, setPropertyStageSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [nextFollowSelected, setNextFollowSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [
    propertySubTypeSelected,
    setPropertySubTypeSelected,
  ] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadSourceSelected, setLeadSourceSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

  const [addSetSelected, setAddSetSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [campaignSetSelected, setCampaignSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [ownerSelected, setOwnerSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [usersList, setUsersList] = useState([]);

  const [projectsList, setProjectsList] = useState<any[]>(
    []
  );
  const [locationsList, setLocationsList] = useState<any[]>(
    []
  );
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [allPropertySubTypeList, setAllPropertySubTypeList] = useState<
    any[]
  >([]);
  const [propertyTypeOldList, setPropertyTypeOldList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [leadTypeList, setleadTypeList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<
    any[]
  >([]);
  const [leadSourceList, setLeadSourceList] = useState<
    any[]
  >([]);
  const [uidList, setUidList] = useState<any>();
  const [addSet, setAddSet] = useState<any[]>([]);
  const [leadSource, setLeadSource] = useState<any[]>([]);
  const [campaign, setCampaign] = useState<any[]>([]);
  const fieldOneRef: any = useRef();
  const fieldTwoRef: any = useRef();
  const fieldThreeRef: any = useRef();
  const fieldFourRef: any = useRef();
  const fieldFiveRef: any = useRef();
  const fieldSixRef: any = useRef();

  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.8); // Set an initial value, adjust as needed

  const handleResize = () => {
    // Update maxHeight when the window is resized
    setMaxHeight(window.innerHeight * 0.8); // Adjust the multiplier as needed
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    if (authToken) {
      getLeadsFilterValues();
    }
  }, [authToken]);
  const getLeadsFilterValues = async () => {
    axios.defaults.headers.common['x-access-token'] = authToken
    const res = await axios.post(url + '/leads/filterValues', {
      uid: user.uid,
    });
    setAddSet(res.data[0]?.addset);
    setCampaign(res.data[0]?.campaign);
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
    if (role === "Lead Manager" && organizationUsers) {
      let uids: { [key: string]: string } = {};
      let data: any = [];
      organizationUsers.map((item: any) => {
        data.push(
          `${item.user_first_name} ${item.user_last_name}-${item.user_email}`
        );
        uids[item.user_email] = item.uid;
      });
      setUsersList(data);
      setUidList(uids);
    }
  }, []);

  useEffect(() => {
    const unsub = contactResources(
      user.organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data),
    );

    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeOldList(data),
      (data) => setnextFollowUpList(data)
    );
    // fetchNewPropertySubType
    const unNewPropertyType = fetchpropertyType(
      user.organization_id,
      (data) => setPropertyTypeList(data)
    );
    const unNewPropertySubType = fetchNewPropertySubType(
      user.organization_id,
      (data) => setAllPropertySubTypeList(data)
    );
    let data = ["Data", "Leads"];
    setleadTypeList(data);
    const unsubProperty = fetchpropertySubType(
      user.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );

    return () => {
      unsub();
      unsubConst();
      unsubProperty();
      unNewPropertyType();
      unNewPropertySubType();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("allPropertySubTypeListallPropertySubTypeList:", allPropertySubTypeList);
    const filteredArray = allPropertySubTypeList.filter(item => item.property_type === propertyTypeSelected.value);
    const propertySubTypes = filteredArray.map(item => item.property_sub_type);
    console.log("filtered propertySubTypes", propertySubTypes);
    if (propertySubTypes.length !== 0) {
      setPropertySubType(propertySubTypes);
    } else {
      setPropertySubType([]);
    }
    // if (propertyTypeSelected.value === "Commercial") {
    //   setPropertySubType(comTypes);
    // } else if (
    //   propertyTypeSelected.value === "Residential"
    // ) {
    //   setPropertySubType(resTypes);
    // } else {
    //   setPropertySubType([]);
    // }
  }, [propertyTypeSelected]);

  useEffect(() => {
    let data: any[] = [];
    country_code.forEach((item) => {
      data.push(item.dial_code + " " + item.name);
    });
    setCountryCodeList(data);
  }, []);

  useEffect(() => {
    const code = "+" + phoneCountryCode; // Specify the code you want to find
    const result = countryCodeList.find(item => item.startsWith(code));
    if (result) {
      setCountryCode(result);
    }
  }, [phoneCountryCode]);

  useEffect(() => {
    let selected: string[] = [];
    if (user.country) {
      selected = countryCodeList.filter((item) =>
        item
          .toLowerCase()
          .includes(user.country.toLowerCase())
      );
    } else {
      selected = countryCodeList.filter((item) =>
        item.toLowerCase().includes("+91")
      );
    }
    if (selected.length !== 0) {
      setCountryCode(selected[0]);
    }
  }, [user.country, countryCodeList]);

  const choice = () => {
    let data = countryCodeList.map((item: any) => ({
      value: item,
      label: item,
    }));

    return data;
  };
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setFinalPhoneNumber(value);
    }
    setPhoneCountryCode(country?.dialCode);
    //  setCountryCode(country?.dialCode);
    setPhoneNumber(value)
  };
  const location: any = useLocation();
  const create = async (e: any) => {
    e.preventDefault();
    const numberExp = /^[0-9]+$/;
    if (user.organization_id) {
      if (firstNameRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "First Name Required!!",
            "error"
          )
        );
        return;
      } else if (lastNameRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "Last Name Required!!",
            "error"
          )
        );
        return;
      } else if (finalPhoneNumber === "") {
        dispatcher(
          showSnackbarAction(
            "Mobile Number Required!!",
            "error"
          )
        );
        return;
      } else if (
        emaileRef.current.value !== "" &&
        emailValidate(emaileRef.current.value) ===
        "Invalid Email"
      ) {
        dispatcher(
          showSnackbarAction("Invalid Email!!", "error")
        );
        return;
      } else if (!(leadTypeSelected.value === "Leads" || leadTypeSelected.value === "Data")) {
        dispatcher(
          showSnackbarAction(
            "Lead Type Required!!",
            "error"
          )
        );
        return;
      } else if (countryCode === "") {
        dispatcher(
          showSnackbarAction(
            "Country Code Required!!",
            "error"
          )
        );
        return;
      }
      else if (fieldOneRef.current.value !== "") {
        if (!numberExp.test(fieldOneRef.current.value)) {
          dispatcher(
            showSnackbarAction(
              `${conatctHeader.find((obj: any) => obj.label === "field_one").value} contain only number!!`,
              "error"
            )
          );
          return;
        }
      }
      else if (fieldTwoRef.current.value !== "") {
        if (!numberExp.test(fieldTwoRef.current.value)) {
          dispatcher(
            showSnackbarAction(
              `${conatctHeader.find((obj: any) => obj.label === "field_two").value} contain only number!!`,
              "error"
            )
          );
          return;
        }
      }
      setLoad(true);
      let l = ownerSelected.value.split("-");
      let email = l[1];

      const country = country_code.find(
        (el: any) =>
          el.dial_code === countryCode.split(" ")[0]
      );

      createContacts(
        user.organization_id,
        emaileRef.current.value,
        firstNameRef.current.value,
        lastNameRef.current.value,
        finalPhoneNumber,
        user.user_first_name + " " + user.user_last_name,
        role === "Lead Manager"
          ? uidList[email] !== undefined
            ? uidList[email]
            : user.uid
          : user.uid,
        dispatcher,
        history,
        role === "Lead Manager"
          ? email !== undefined
            ? email
            : user.user_email
          : user.user_email,
        alternateRef.current.value,
        countryCode.split(" ")[0],
        (data: boolean) => setLoad(data),
        country ? country.name : "India",
        propertyTypeSelected.value === "Select"
          ? ""
          : propertyTypeSelected.value,
        propertyStageSelected.value === "Select"
          ? ""
          : propertyStageSelected.value,
        leadTypeSelected.value === "Select"
          ? "Data"
          : leadTypeSelected.value,
        locationSelected.value === "Select"
          ? ""
          : locationSelected.value,
        budgetSelected.value === "Select"
          ? ""
          : budgetSelected.value,
        projectSelected.value === "Select"
          ? ""
          : projectSelected.value,
        propertySubTypeSelected.value === "Select"
          ? ""
          : propertySubTypeSelected.value,
        leadSourceSelected.value === "Select"
          ? "Self Generated"
          : leadSourceSelected.value,
        addSetSelected.value === "Select"
          ? ""
          : addSetSelected.value,
        campaignSetSelected.value === "Select"
          ? ""
          : campaignSetSelected.value,
        noteRef.current.value,
        false,
        user.user_email,
        organizationData.allowDuplicateLeads,
        fieldOneRef.current.value !== "" ? fieldOneRef.current.value : "",
        fieldTwoRef.current.value !== "" ? fieldTwoRef.current.value : "",
        fieldThreeRef.current.value !== "" ? fieldThreeRef.current.value : "",
        fieldFourRef.current.value !== "" ? fieldFourRef.current.value : "",
        fieldFiveRef.current.value !== "" ? fieldFiveRef.current.value : "",
        fieldSixRef.current.value !== "" ? fieldSixRef.current.value : ""
      );
    }
  };

  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };
  console.log("Selected Country Code Printed :", countryCode);
  return (
    // <div className={styles.parent}>
    //   {load === true && <Loading />}
    //   <div className={styles.child}>
    //     <div className={styles.headerView}>
    //       <p className={styles.heading}>
    //         {" "}
    //         {"Add Contacts Details"}
    //       </p>
    //       <AiOutlineClose
    //         className={styles.closeIcon}
    //         size={25}
    //         onClick={() => history.replace("/")}
    //       />
    //     </div>
    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>First Name</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter first name"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={firstNameRef}
    //           >
    //             <FaUser color={"#808080"} />
    //           </TextInput>
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Last Name</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter last name"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={lastNameRef}
    //           >
    //             <FaUser color={"#808080"} />
    //           </TextInput>
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Mobile No.</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Number"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={numberRef}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Email ID</p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Email"}
    //             style={{ backgroundColor: "#fff" }}
    //             validator={emailValidate}
    //             ref={emaileRef}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Alternate No.</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div>
    //           <TextInput
    //             title={"Enter Number"}
    //             style={{ backgroundColor: "#fff" }}
    //             ref={alternateRef}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div
    //           className={styles.title}
    //           style={{ marginBottom: "3%" }}
    //         >
    //           <p className={styles.one}>Country Code</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div>
    //           <Select
    //             options={choice()}
    //             onChange={(data) => {
    //               if (data) {
    //                 setCountryCode(data.label);
    //               }
    //             }}
    //             isSearchable={true}
    //             value={{
    //               label: countryCode,
    //               value: countryCode,
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Location</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={locationsList}
    //             selectedValue={locationSelected}
    //             setSelectedValue={(value) => {
    //               setlocationSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>

    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Project</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={projectsList}
    //             selectedValue={projectSelected}
    //             setSelectedValue={(value) => {
    //               setProjectSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Property Type</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={propertyTypeList}
    //             selectedValue={propertyTypeSelected}
    //             setSelectedValue={(value) => {
    //               setPropertyTypeSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Property Stage</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={propertyStageList}
    //             selectedValue={propertyStageSelected}
    //             setSelectedValue={(value) => {
    //               setPropertyStageSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Budget</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={budgetsList}
    //             selectedValue={budgetSelected}
    //             setSelectedValue={(value) => {
    //               setBudgetSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>
    //             Property Sub Type
    //           </p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={propertySubType}
    //             selectedValue={propertySubTypeSelected}
    //             setSelectedValue={(value) => {
    //               setPropertySubTypeSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     {/* Add New Key  */}
    //     <div className={styles.box2}>
    //       <div className={styles.divide}>
    //         <div className={styles.title}>
    //           <p className={styles.one}>Lead Type</p>
    //           <p className={styles.two}>*</p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <Dropdown
    //             option={leadTypeList}
    //             selectedValue={leadTypeSelected}
    //             setSelectedValue={(value) => {
    //               setLeadTypeSelected(value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>


    //     {user.profile === "Lead Manager" && (
    //       <div className={styles.box2}>
    //         <div className={styles.divide}>
    //           <div className={styles.title}>
    //             <p className={styles.one}>Lead Source</p>
    //             <p className={styles.two}></p>
    //           </div>
    //           <div style={{ marginTop: "7px" }}>
    //             <Dropdown
    //               option={leadSourceList}
    //               selectedValue={leadSourceSelected}
    //               setSelectedValue={(value) => {
    //                 setLeadSourceSelected(value);
    //               }}
    //             />
    //           </div>
    //         </div>
    //         <div className={styles.divide}>
    //           <div className={styles.title}>
    //             <p className={styles.one}>Owner</p>
    //             <p className={styles.two}></p>
    //           </div>
    //           <div style={{ marginTop: "7px" }}>
    //             <Dropdown
    //               option={usersList}
    //               selectedValue={ownerSelected}
    //               setSelectedValue={(value) => {
    //                 setOwnerSelected(value);
    //               }}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     )}

    //     {user.profile === "Team Lead" && (
    //       <div className={styles.box2}>
    //         <div className={styles.divide}>
    //           <div className={styles.title}>
    //             <p className={styles.one}>Lead Source</p>
    //             <p className={styles.two}></p>
    //           </div>
    //           <div style={{ marginTop: "7px" }}>
    //             <Dropdown
    //               option={leadSourceList}
    //               selectedValue={leadSourceSelected}
    //               setSelectedValue={(value) => {
    //                 setLeadSourceSelected(value);
    //               }}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     )}

    //     <div className={styles.box2}>
    //       <div
    //         className={styles.divide}
    //         style={{ width: "100%" }}
    //       >
    //         <div className={styles.title}>
    //           <p className={styles.one}>Add Note</p>
    //           <p className={styles.two}></p>
    //         </div>
    //         <div style={{ marginTop: "7px" }}>
    //           <textarea
    //             style={{
    //               width: "100%",
    //               resize: "none",
    //               marginTop: "17px",
    //             }}
    //             rows={4}
    //             cols={10}
    //             placeholder={"Enter Note"}
    //             ref={noteRef}
    //             onChange={(e) => setNote(e.target.value)}
    //             value={note}
    //           ></textarea>
    //         </div>
    //       </div>
    //     </div>

    //     <button
    //       className={styles.button}
    //       onClick={() => {
    //         console.log(user, "user");
    //         create();
    //       }}
    //     >
    //       Submit
    //     </button>
    //   </div>
    // </div>
    <div
      className={styles.parent}
      // className="container-fluid p-4 d-flex flex-column gap-4"
      style={{ ...containerStyles, marginTop: "4%" }}
    >
      {load === true && <Loading />}
      <div
        //  className={styles.headerView}
        className="d-flex flex-row w-100 px-4 pt-4"
      >
        <h5
        // className={styles.heading}
        >
          {" "}
          {"Add Contacts Details"}
        </h5>
        <AiOutlineClose
          className={styles.closeIcon}
          size={25}
          onClick={() => history.replace("/")}
        />
      </div>
      <div
        // className={styles.child}
        className="w-100 d-flex flex-column gap-2 px-4"
        style={{ maxHeight: "800px", overflowX: "hidden", overflowY: "scroll", marginBottom: "20px" }}
      >
        <form className="card p-4 shadow rounded mt-2"
          style={{
            ...cardContainerStyles,
            maxHeight: `${maxHeight}px`,
            overflowX: "hidden",
            overflowY: "scroll",
            marginBottom: "20px"
          }}
        >
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label="First Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={firstNameRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              <Label label="Last Name" required={true} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={lastNameRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "email").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  validator={emailValidate}
                  ref={emaileRef}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "contact_no").value} required={true} />
              <div className={styles.inputContainer}>
                <PhoneInput
                  country={phoneCountryCode}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputStyle={{ width: '140px', height: "45px" }}
                  containerStyle={{ height: "45px", marginLeft: "7px" }}
                />
                {/* phone => this.setState({ phone }) */}
                {/* <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff" }}
                  ref={numberRef}
                /> */}
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "country_code").value} required={true} />
              <div style={{ color: "#000", ...selectInputContainerStyles }} >
                <Select
                  className={styles.select}
                  options={choice()}
                  onChange={(data) => {
                    if (data) {
                      setCountryCode(data.label);
                    }
                  }}
                  isSearchable={true}
                  value={{
                    label: countryCode,
                    value: countryCode,
                  }}
                  isDisabled={true}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "alternate_no").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={alternateRef}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "location").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={locationsList}
                  selectedValue={locationSelected}
                  setSelectedValue={(value) => {
                    setlocationSelected(value);
                  }}
                />
              </div>
            </div>

            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "project").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={projectsList}
                  selectedValue={projectSelected}
                  setSelectedValue={(value) => {
                    setProjectSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "property_type").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyTypeList}
                  selectedValue={propertyTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertyTypeSelected(value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "property_stage").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertyStageList}
                  selectedValue={propertyStageSelected}
                  setSelectedValue={(value) => {
                    setPropertyStageSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "budget").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={budgetsList}
                  selectedValue={budgetSelected}
                  setSelectedValue={(value) => {
                    setBudgetSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "property_sub_type").value} required={false} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={propertySubType}
                  selectedValue={propertySubTypeSelected}
                  setSelectedValue={(value) => {
                    setPropertySubTypeSelected(value);
                  }}
                />
              </div>
            </div>
          </div>

          {/* Add New Key  */}
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "lead_type").value} required={true} />
              <div style={{ ...selectInputContainerStyles }}>
                <Dropdown
                  option={leadTypeList}
                  selectedValue={leadTypeSelected}
                  setSelectedValue={(value) => {
                    setLeadTypeSelected(value);
                  }}
                />
              </div>
            </div>

            {user.profile === "Lead Manager" && (
              <>
                <div className={styles.divide}>
                  <Label label={conatctHeader.find((obj: any) => obj.label === "lead_source").value} required={false} />
                  <div style={{ ...selectInputContainerStyles }}>
                    <Dropdown
                      option={leadSourceList}
                      selectedValue={leadSourceSelected}
                      setSelectedValue={(value) => {
                        setLeadSourceSelected(value);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.divide}>
                  <Label label={conatctHeader.find((obj: any) => obj.label === "contact_owner_email").value} required={false} />
                  <div style={{ ...selectInputContainerStyles }}>
                    <Dropdown
                      option={usersList}
                      selectedValue={ownerSelected}
                      setSelectedValue={(value) => {
                        setOwnerSelected(value);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {user.profile === "Team Lead" && (
            <div className={styles.box2}>
              <div className={styles.divide}>
                <Label label={conatctHeader.find((obj: any) => obj.label === "lead_source").value} required={false} />
                <div style={{ ...selectInputContainerStyles }}>
                  <Dropdown
                    option={leadSourceList}
                    selectedValue={leadSourceSelected}
                    setSelectedValue={(value) => {
                      setLeadSourceSelected(value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_one").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fieldOneRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_two").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fieldTwoRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_three").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fieldThreeRef}
                />
              </div>
            </div>
          </div>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_four").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fieldFourRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_five").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fieldFiveRef}
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
            <div className={styles.divide}>
              <Label label={conatctHeader.find((obj: any) => obj.label === "field_six").value} required={false} />
              <div className={styles.inputContainer}>
                <TextInput
                  title={""}
                  style={{ backgroundColor: "#fff", height: "45px", width: "140px" }}
                  ref={fieldSixRef}
                />
              </div>
            </div>
          </div>
          {user.profile === "Lead Manager" && (
            <>
              <div className={styles.box2}>
                <div className={styles.divide}>
                  <Label label={conatctHeader.find((obj: any) => obj.label === "addset").value} required={false} />
                  <div style={{ ...selectInputContainerStyles }}>
                    <Dropdown
                      option={addSet}
                      selectedValue={addSetSelected}
                      setSelectedValue={(value) => {
                        setAddSetSelected(value);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.divide}>
                  <Label label={conatctHeader.find((obj: any) => obj.label === "campaign").value} required={false} />
                  <div style={{ ...selectInputContainerStyles }}>
                    <Dropdown
                      option={campaign}
                      selectedValue={campaignSetSelected}
                      setSelectedValue={(value) => {
                        setCampaignSelected(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={styles.box2}>
            <div
              // className={styles.divide}
              style={{ width: "100%" }}
            >
              <Label label="Add Note" required={false} />
              <div>
                <textarea
                  // style={{
                  //   marginTop: "1rem",
                  // }}
                  className="form-control"
                  rows={2}
                  placeholder={""}
                  ref={noteRef}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                ></textarea>
              </div>
            </div>
          </div>

          <button
            type="submit"
            // className="btn btn-primary mt-5"
            style={{ backgroundColor: "#363853" }}
            className={styles.button}
            onClick={(e: any) => {
              create(e);
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
    theme: state.theme.isLightMode,
    conatctHeader: state.contactHeaderList.data,
    authToken: state.user.authToken,
    organizationData: state.organization.organizationData,
  };
};

export default connect(mapStateToProps)(AddContacts);
