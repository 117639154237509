import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import commonStyle from '../../Screens/common.module.css';
import { useTable, useColumnOrder } from 'react-table';

import { connect, useDispatch } from 'react-redux';
import TableHeader from '../TableHeader/TableHeader';
import CustomToggle from '../CustomToggle';
import {
  updateUserImport,
  updateUserStatus,
  EditUserTableHeader,
} from '../../Services/users';

import {
  updateAPIStatus,
  updateOrganizationStatus,
} from '../../Services/organizations';
import ColumnManagerModal from '../Modals/ColumnManagerModal/ColumnManager';
import {
  setClearFilter,
  setContactsSearchString,
  setTasksSearchString,
  setCallsSearchString,
  showSnackbarAction,
  updateFilterStatus,
} from '../../Redux/actions';
import { useHistory } from 'react-router';
import { FiEdit3 } from 'react-icons/fi';
import Loading from '../Loading/Loading';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';

import DistributionModel from '../Modals/DistributionModel/DistributionModel';
import { themeColors } from '../theme';
import { admin_mail } from '../../Values/constants';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


type props = {
  tableColumns: any[];
  tableRows: any[] | undefined;
  selectedRows: any[];
  setSelectedRows: (data: any[]) => void;
  tableType:
  | 'Organization'
  | 'Super admin'
  | 'User'
  | 'Task'
  | 'Project'
  | 'CallLogs'
  | 'API'
  | 'NEWS'
  | 'LeadDistributor'
  | 'DrillDown';
  showColumnModal: boolean;
  hideColumnModal: () => void;
  selectedRowsData?: any[];
  setSelectedRowsData?: (data: any[]) => void;

  organization: any;
  organizationUsers: any[];
  role: any;
  page: number;
  pageSize: number;
  setPage: (data: any) => void;
  setPageSize: (data: any) => void;
  isFinished: boolean;
  lastPage: number;
  totalCount: any;
  setBranch?: (text: boolean) => void;
  setUid?: (data: string) => void;
  user: any;
  setApiModal?: (data: boolean) => void;
  setApiEdit?: (data: boolean) => void;
  setApiData?: (data: any) => void;

  callApi?: (data: boolean, data2: Number | undefined) => Promise<void>;
  theme: any
};

const ApiCustomTable: FunctionComponent<props> = ({
  tableColumns,
  tableRows,
  selectedRows,
  setSelectedRows,
  tableType,
  showColumnModal,
  hideColumnModal,
  selectedRowsData,
  setSelectedRowsData,

  organization,
  organizationUsers,
  role,
  setBranch,
  setUid,
  user,
  setApiModal,
  setApiEdit,
  setApiData,
  page,
  pageSize,
  setPage,
  setPageSize,
  callApi,
  isFinished,
  lastPage,
  totalCount,
  theme
}) => {
  const dispatcher = useDispatch();
  const history = useHistory();
  const pageSizeOptions = [10, 25, 50, 100, 200, 500];
  const [checked, setChecked] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [distributionData, setDistributionData] = useState();
  const [indexs, setIndex] = useState(0);
  const [pageData, setPageData] = useState<any[]>([]);
  // const [firstLoad,setFirstLoad] = useState(false);


  useEffect(() => {
    if (tableRows) {
      const data = tableRows.slice(pageSize * page, pageSize * page + pageSize);
      setPageData(data);
    }
  }, [page, pageSize, tableRows]);

  const selectAll = (checked: boolean) => {

    const filterSelectedRows = (arr: any) => {
      let newArr = Array.from(new Set(arr.map((item: any) => item)));
      return newArr;
    }

    const uniqByProp_map = (prop: any) => (arr: any) =>
      Array.from(
        arr
          .reduce(
            (acc: any, item: any) => (
              item && item[prop] && acc.set(item[prop], item),
              acc
            ),
            new Map()
          )
          .values()
      );
    const uniqueById = uniqByProp_map("contactId");

    if (
      tableType === 'User' ||
      tableType === 'Project' ||
      tableType === 'Organization' ||
      tableType === 'CallLogs' ||
      tableType === 'Task'
    ) {
      if (checked === true) {
        if (tableRows) {
          let selectRowsIndex: any[] = [...selectedRows];
          let newRowIndex = pageData.map((lead) => lead.contactId || lead.project_id);
          let allSelectedRows = [...newRowIndex, ...selectRowsIndex];
          let filteredRows = filterSelectedRows(allSelectedRows);
          if (tableType === "User") {
            setSelectedRows(filteredRows);
          } else {
            setSelectedRows(allSelectedRows);
          }
        }

        if (tableRows) {
          if (selectedRowsData) {
            let selectRows: any[] = [...selectedRowsData];
            pageData.forEach((item: any) => {
              selectRows.push(item);
            });
            let filteredRowsData = uniqueById(selectRows);
            if (tableType === "User") {
              setSelectedRowsData && setSelectedRowsData(filteredRowsData);
            } else {
              setSelectedRowsData && setSelectedRowsData(selectRows);
            }
          } else {
            let selectRows: any[] = [];
            pageData.forEach((item: any) => {
              selectRows.push(item);
            });
            let filteredRowsData = uniqueById(selectRows);
            if (tableType === "User") {
              setSelectedRowsData && setSelectedRowsData(filteredRowsData);
            } else {
              setSelectedRowsData && setSelectedRowsData(selectRows);
            }
          }
        }
      } else {
        setSelectedRows([]);
        setSelectedRowsData && setSelectedRowsData([]);
      }
    } else {
      if (checked === true) {
        setSelectedRows(Array.from(Array(pageData.length).keys()));
      } else {
        setSelectedRows([]);
      }
    }
  };

  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => pageData, [pageData]);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [active, setActiveUsers] = useState(0);
  const tableInstance: any = useTable(
    {
      columns,
      data,
    },
    useColumnOrder
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    toggleHideAllColumns,
  } = tableInstance;
  useEffect(() => {
    const savedColumns = localStorage.getItem('columns');
    if (savedColumns) {
      setColumnOrder(JSON.parse(savedColumns));
      // setFirstLoad(true);
    }
    // setFirstLoad(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let storageColumn;
    if (
      tableType === 'CallLogs' &&
      user.calllogsColumn &&
      user.calllogsColumn.length > 0
    ) {
      storageColumn = user.calllogsColumn;
      setColumnOrder(storageColumn);
      // setFirstLoad(true);
    } else if (
      tableType === 'User' &&
      user.userColumn &&
      user.userColumn.length > 0
    ) {
      storageColumn = user.userColumn;
      setColumnOrder(storageColumn);
      // setFirstLoad(true);
    } else if (
      tableType === 'Project' &&
      user.userColumn &&
      user.userColumn.length > 0
    ) {
      storageColumn = user.userColumn;
      setColumnOrder(storageColumn);
      // setFirstLoad(true);
    } else if (
      tableType === 'Task' &&
      user.taskColumn &&
      user.taskColumn.length > 0
    ) {
      storageColumn = user.taskColumn;
      setColumnOrder(storageColumn);
      // setFirstLoad(true);
    }
    // setFirstLoad(true);
  }, [user, tableType]);

  useEffect(() => {
    if (organizationUsers) {
      let activeUsers = organizationUsers.filter(
        (item) => item.status === 'ACTIVE'
      );
      setActiveUsers(activeUsers.length);
    }
  }, [organizationUsers]);

  const setColumns = (columnList: any) => {
    setColumnOrder(columnList);
    if (
      tableType === 'User' ||
      tableType === 'Project' ||
      tableType === 'CallLogs' ||
      tableType === 'Task'
    ) {
      EditUserTableHeader(user.uid, tableType.toLowerCase(), columnList);
    }
    localStorage.setItem('columns', JSON.stringify(columnList));
    // var key: any;
    // for (var i = 0; i < localStorage.length; i++) {
    //   key = localStorage.key(i);

    //   if (key !== 'columns' && key !== 'isLightMode' && key !== 'totalMonths') {
    //     localStorage.removeItem(key);
    //   }
    // }
  };

  const checkStatus = (email: string) => {
    const l = data.filter((item) => item.reporting_to === email);
    if (l.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onToggle = (event: any, row: any) => {
    if (tableType === 'Organization') {
      setLoad(true);
      if (event.target.checked) {
        let check = checkStatus(row.original.user_email);
        if (check === true) {
          updateUserStatus(row.original.uid, 'INACTIVE', (data) =>
            setLoad(data)
          );
        } else {
          dispatcher(
            showSnackbarAction(
              'Some Users Are Reporting To This User!!',
              'warning'
            )
          );
          setLoad(false);
        }
      } else {
        let activeUsers = organizationUsers.filter(
          (item) => item.status === 'ACTIVE'
        );

        if (activeUsers.length === Number(organization.no_of_employees)) {
          dispatcher(
            showSnackbarAction(
              'Maximum active users limit reached!!',
              'warning'
            )
          );
          setLoad(false);
        } else {
          updateUserStatus(row.original.uid, 'ACTIVE', (data) => setLoad(data));
        }
      }
    }
    if (tableType === 'Super admin') {
      setLoad(true);
      if (event.target.checked) {
        updateOrganizationStatus(
          row.original.organization_id,
          'INACTIVE',
          (data) => setLoad(data)
        );
      } else {
        updateOrganizationStatus(
          row.original.organization_id,
          'ACTIVE',
          (data) => setLoad(data)
        );
      }
    }
    if (tableType === 'API') {
      setLoad(true);
      if (event.target.checked) {
        updateAPIStatus(row.original.api_key, 'INACTIVE', (data) =>
          setLoad(data)
        );
      } else {
        updateAPIStatus(row.original.api_key, 'ACTIVE', (data) =>
          setLoad(data)
        );
      }
    }
  };

  const changeImport = (event: any, row: any) => {
    setLoad(true);
    if (event.target.checked) {
      updateUserImport(
        row.original.organization_id,
        row.original.user_email,
        true,
        dispatcher,
        (data) => setLoad(data)
      );
    } else {
      updateUserImport(
        row.original.organization_id,
        row.original.user_email,
        false,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const onCheck = (event: any, row: any) => {

    if (
      tableType === 'User' ||
      tableType === 'Project' ||
      tableType === 'Organization' ||
      tableType === 'Task'
    ) {
      if (event.target.checked) {
        let data = [...selectedRows];
        data.push(row.contactId || row.project_id);
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          rowData.push(row);
          setSelectedRowsData && setSelectedRowsData(rowData);
        }
      } else if (!event.target.checked) {
        let data = [...selectedRows];
        let item = selectedRows.indexOf(row.contactId || row.project_id);
        if (item > -1) {
          data.splice(item, 1);
        }
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          let val = selectedRowsData.indexOf(row);
          if (val > -1) {
            rowData.splice(val, 1);
          }
          setSelectedRowsData && setSelectedRowsData(rowData);
        }
      }
    }

    if (event.target.checked) {
      let data = [...selectedRows];
      data.push(row.contactId || row.project_id);
      setSelectedRows(data);
    } else if (!event.target.checked) {
      let data = [...selectedRows];
      let item = selectedRows.indexOf(row.contactId || row.project_id);
      if (item > -1) {
        data.splice(item, 1);
      }
      setSelectedRows(data);
    }
  };

  const clearFilter = () => {
    if (tableType === 'User') {
      dispatcher(setContactsSearchString(''));
      dispatcher(updateFilterStatus(true));
    }
    if (tableType === 'Project') {
      dispatcher(setContactsSearchString(''));
    }
    if (tableType === 'Task') {
      dispatcher(setTasksSearchString(''));
    }
    if (tableType === 'CallLogs') {
      dispatcher(setCallsSearchString(''));
    }
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== 'columns' && key !== 'isLightMode' && key !== 'totalMonths' && key !== 'lastAlertTime') {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    if (tableType === 'User') {
      // history.push('/analytics');
    }
    if (tableType === 'Project') {
      history.push('/projects');
    }
  };
  const location: any = useLocation();

  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }

  const footerStyles = {
    backgroundColor: theme ? themeColors.thBackgroundColor : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }

  const buttonStyles = {
    backgroundColor: theme ? themeColors.buttonPrimary : themeColors.buttonPrimaryDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }
  const trBorderStyles = {
    borderBottom: theme ? `1px solid ${themeColors.trBorderColor}` : `1px solid ${themeColors.trBorderColorDark}`,
  }

  return (
    <>
      <div className={commonStyle.child} id="scrollable">
        {load === true && <Loading />}
        <div style={{ width: 'max-content' }} {...getTableProps()}>
          {/* {firstLoad &&  */}
          <TableHeader
            headerGroups={headerGroups}
            selectAll={(data) => {
              selectAll(data);
              setChecked(data);
            }}
            panel={tableType}
            allCheck={checked}
          />
          {/* } */}
          <InfiniteScroll
            style={{ overflow: 'visible', minHeight: "100vh", width: "100%", marginBottom: "-10rem", paddingRight: '100px', ...tdStyles }}
            dataLength={currentLimit}
            next={() => setCurrentLimit(currentLimit + 20)}
            hasMore={rows.length > currentLimit}
            loader={''}
            scrollableTarget="scrollable"
            className={commonStyle.infiniteScroll}
          >
            <div {...getTableBodyProps()}>
              {rows.slice(0, currentLimit).map((row: any, index: number) => {
                prepareRow(row);
                return (
                  <div
                    {...row.getRowProps()}
                    style={
                      row.original.status === 'INACTIVE'
                        ? {
                          // backgroundColor: '#D3D3D3',
                          // borderWidth: 2,
                          display: 'flex',
                          flexDirection: 'row',
                          ...trBorderStyles
                        }
                        : {
                          // backgroundColor: '#ffffff',
                          display: 'flex',
                          flexDirection: 'row',
                          ...trBorderStyles
                        }
                    }
                  >
                    <div className={commonStyle.sticky} style={tdStyles} >
                      <input
                        style={{ marginRight: "10px", accentColor: "#272944" }}
                        type="checkbox"
                        onChange={(e) => {
                          onCheck(e, row.original);
                        }}
                        checked={selectedRows.includes(row.original.contactId || row.original.project_id)}
                      />
                      {tableType !== 'Task' && user?.user_email !== admin_mail && (
                        <FiEdit3
                          size={15}
                          color="#272944"
                          style={{ marginRight: '7px' }}
                          onClick={() => {
                            if (tableType === 'Organization') {
                              history.push({
                                pathname: '/addUsers',
                                state: {
                                  detail: row.original,
                                  edit: true,
                                },
                              });
                            } else if (tableType === 'User') {
                              if (row.original.transfer_status === true) {
                                dispatcher(
                                  showSnackbarAction(
                                    'Lead With Transfer Status TRUE Cannot be Edited!!',
                                    'error'
                                  )
                                );
                              } else {
                                let data = { ...row.original };
                                history.push({
                                  pathname: '/editContacts',
                                  state: {
                                    detail: data,
                                  },
                                });
                              }
                            } else if (tableType === 'Project') {
                              if (row.original.transfer_status === true) {
                                dispatcher(
                                  showSnackbarAction(
                                    'Lead With Transfer Status TRUE Cannot be Edited!!',
                                    'error'
                                  )
                                );
                              } else {
                                let data = { ...row.original };
                                history.push({
                                  pathname: '/editProjects',
                                  state: {
                                    detail: data,
                                  },
                                });
                              }
                            } else if (tableType === 'Super admin') {
                              history.push({
                                pathname: '/editOrganizations',
                                state: { detail: row.original },
                              });
                            } else if (tableType === 'LeadDistributor') {
                              setShowDistributionModal(true);
                              setDistributionData(row.original);
                              setIndex(index);
                            } else if (tableType === 'API') {
                              setApiEdit && setApiEdit(true);
                              setApiModal && setApiModal(true);
                              setApiData && setApiData(row.original);
                            }
                          }}
                        />
                      )}
                      {showDistributionModal && (
                        <DistributionModel
                          open={showDistributionModal}
                          organization_id={user.organization_id}
                          close={() => {
                            setShowDistributionModal(false);
                          }}
                          data={distributionData}
                          index={indexs}
                        />
                      )}
                      {pageSize * page + index + 1}
                    </div>
                    {row.cells.map((cell: any) => {
                      return (
                        <div
                          className={commonStyle.td}

                          {...cell.getCellProps()}
                          style={
                            cell.column.Header === 'News Link'
                              ? { minWidth: '490px', ...tdStyles }
                              : tdStyles
                          }

                          onClick={() => {
                            if (
                              (cell.column.Header === 'Customer Name' &&
                                tableType === 'User') ||
                              (cell.column.Header === 'Customer Name' &&
                                tableType === 'Task') ||
                              (cell.column.Header === 'Customer Name' &&
                                tableType === 'CallLogs')
                            ) {
                              if (cell.row.original.transfer_status === true) {
                                dispatcher(
                                  showSnackbarAction(
                                    'This is a Transfered Lead!!',
                                    'error'
                                  )
                                );
                              } else {
                                if (user.user_first_name !== "SuperAdmin") {
                                  history.push({
                                    pathname: '/contactDetails',
                                    state: {
                                      detail: {
                                        ...cell.row.original,
                                        created_at:
                                          cell.row.original[
                                            'created_at'
                                          ].toDate(),
                                        lead_assign_time: cell.row.original[
                                          'lead_assign_time'
                                        ]
                                          ? cell.row.original[
                                            'lead_assign_time'
                                          ].toDate()
                                          : '',
                                        next_follow_up_date_time: cell.row
                                          .original['next_follow_up_date_time']
                                          ? cell.row.original[
                                            'next_follow_up_date_time'
                                          ].toDate()
                                          : '',
                                        modified_at: cell.row.original[
                                          'modified_at'
                                        ]
                                          ? cell.row.original[
                                            'modified_at'
                                          ].toDate()
                                          : '',
                                        stage_change_at: cell.row.original[
                                          'stage_change_at'
                                        ]
                                          ? cell.row.original[
                                            'stage_change_at'
                                          ].toDate()
                                          : '',
                                        feedback_time: cell.row.original[
                                          'feedback_time'
                                        ]
                                          ? cell.row.original[
                                            'feedback_time'
                                          ].toDate()
                                          : '',
                                        due_date: cell.row.original['due_date']
                                          ? cell.row.original['due_date'].toDate
                                            ? cell.row.original[
                                              'due_date'
                                            ].toDate()
                                            : ''
                                          : '',
                                      },
                                    },
                                  });
                                }

                              }
                            } else if (
                              (cell.column.Header === 'Project Name' &&
                                tableType === 'Project')
                            ) {
                              if (cell.row.original.transfer_status === true) {
                                dispatcher(
                                  showSnackbarAction(
                                    'This is a Transfered Lead!!',
                                    'error'
                                  )
                                );
                              } else {
                                history.push({
                                  pathname: "/projectDetails",
                                  state: {
                                    "AllProjectdata": tableRows,
                                    detail: {
                                      ...cell.row.original,
                                      created_at: cell.row.original[
                                        "created_at"
                                      ]
                                        ? cell.row.original[
                                          "created_at"
                                        ].toDate()
                                          ? cell.row.original[
                                            "created_at"
                                          ].toDate()
                                          : ""
                                        : "",
                                    },
                                  },
                                })
                              }
                            }
                          }}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                    {tableType === 'Organization' && (
                      <div
                        className={commonStyle.td}
                        style={tdStyles}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          {(tableType === 'Organization' ||
                            tableType === 'Super admin' ||
                            tableType === 'API') && (
                              <CustomToggle
                                size="small"
                                onChange={(e) => onToggle(e, row)}
                                checked={
                                  row.original.status === 'INACTIVE'
                                    ? true
                                    : false
                                }
                              />
                            )}
                        </div>
                      </div>
                    )}
                    {tableType === 'Organization' ? (
                      <div className={commonStyle.tdSmall} style={tdStyles}>
                        {row.original.profile === 'Lead Manager' ? (
                          <input
                            style={{ marginRight: "10px", accentColor: "#272944" }}
                            type="checkbox"
                            onChange={(e) => changeImport(e, row)}
                            checked={
                              row.original.import === true ? true : false
                            }
                          />
                        ) : null}
                      </div>
                    ) : null}

                    {tableType === 'Organization' ? (
                      <div
                        className={commonStyle.tdSmall}
                        style={{ paddingLeft: '30px', ...tdStyles }}
                      >
                        {row.original.profile === 'Lead Manager' ? (
                          <button
                            className={commonStyle.branchButton}
                            onClick={() => {
                              setBranch && setBranch(true);
                              setUid && setUid(row.original.uid);
                            }}
                          >
                            Branch
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: "center", borderRadius: " 0.5rem", padding: "0.5rem", marginTop: "1rem", marginBottom: "-1rem", ...footerStyles }}>

        <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
          <div style={{display:"flex",justifyContent:"flex-start",textAlign:"start",flexDirection:"row",width:"37%"}}>
            <button
              className={commonStyle.clearFilterEvent}
              onClick={() => clearFilter()}
            >
              <FilterListIcon style={{ height: 15, width: 15, marginRight: '10px' }} />
              Clear Filter
            </button>
            {tableType === 'Organization' && (
              <>
                <div className={commonStyle.selectContainer}>
                  <p className={commonStyle.countHead}>Total Licence:</p>
                  <p className={commonStyle.number}>
                    {organization.no_of_employees}
                  </p>
                </div>
                <div className={commonStyle.selectContainer}>
                  <p className={commonStyle.countHead}>Active Licence:</p>
                  <p className={commonStyle.number}>{active}</p>
                </div>
              </>
            )}
          </div>
         <div>
         <span>
            Page <strong>{page + 1 + '  '}</strong>
          </span>
          <select
            className={commonStyle.dropDownSelecct}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {pageSizeOptions.map((pagesize) => (
              <option key={pagesize} value={pagesize}>
                Show {pagesize}
              </option>
            ))}
          </select>
          <button
            className={commonStyle.addBox}
            onClick={() => {
              setChecked(false);
              if (page !== 0) {
                setPage(page - 1);
              }
            }}
            style={page === 0 ? { backgroundColor: "#53616e" } : {}}
            disabled={page === 0 ? true : false}
          >
            <ChevronLeftIcon />
          </button>
          <button
            className={commonStyle.addBox}
            onClick={async () => {
              setChecked(false);
              setPage(page + 1);
            }}
            style={page === lastPage ? { backgroundColor: "#212529" } : {}}
            disabled={page === lastPage ? true : false}
          >
            <ChevronRightIcon />
          </button>
         </div>
        </div>
        <div className={commonStyle.footer_table}>
          {/* <div style={{marginLeft:"20px"}} >
          <button
            className={commonStyle.clearFilterEvent}
            // style={buttonStyles}
            // style={
            //   location.state?.detail || localStorage.length > 0
            //     ? { color: '#fff', borderColor: '#fff' }
            //     : { color: '#555', borderColor: '#555' }
            // }
            onClick={() => clearFilter()}
          >
            <FilterListIcon style={{height:15,width:15,marginRight:'10px'}} />
            Clear Filter
          </button>
          {tableType === 'Organization' && (
            <>
              <div className={commonStyle.selectContainer}>
                <p className={commonStyle.countHead}>Total Licence:</p>
                <p className={commonStyle.number}>
                  {organization.no_of_employees}
                </p>
              </div>
              <div className={commonStyle.selectContainer}>
                <p className={commonStyle.countHead}>Active Licence:</p>
                <p className={commonStyle.number}>{active}</p>
              </div>
            </>
          )}
        </div> */}
          <div>
            <p className={commonStyle.countHead}>Selected Records: {selectedRows.length}</p>
          </div>
          {/* <div>
          <p style={{marginRight:"20px"}} className={commonStyle.countHead}>Total Records: {totalCount ? totalCount : 0}</p>
        </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '14px', marginRight: '10px' }}>Total Records:</span>
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{totalCount ? totalCount : 0}</span>
          </div>
        </div>
      </div>
      {showColumnModal === true && (
        <ColumnManagerModal
          columnsList={allColumns}
          open={showColumnModal}
          close={hideColumnModal}
          onChangeColumns={(data: any[]) => setColumns(data)}
          toggleAllColumns={toggleHideAllColumns}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("Find User Role :", state.user.data);
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
    organization: state.organization,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(ApiCustomTable);
