import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import styles from "../ListViewModal/ListViewModal.module.css";
import Loading from "../../Loading/Loading";
import TextInput from "../../TextInput/TextInput";
import { connect, useDispatch } from "react-redux";
import Dropdown from "../../DropDown/Dropdown";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import { showSnackbarAction } from "../../../Redux/actions";
import { createCustomButton } from "../../../Services/organizations";

type props = {
  open: boolean;
  close: () => void;
  organizationId: any;
};

const AddCustomButtonModal: FunctionComponent<props> = ({
  organizationId,
  open,
  close,
}) => {
  const urlRef: any = useRef();
  const nameRef: any = useRef();
  const parameterRef: any = useRef();
  const headerRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [type, setType] = useState("");

  const typeOptions = ["POST", "GET"];

  const dispatcher = useDispatch();

  const onSave = () => {
    if (nameRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Please Enter Button Name!!",
          "error"
        )
      );
    } else if (urlRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Please Enter URL Link!!",
          "error"
        )
      );
    } else if (type === "") {
      dispatcher(
        showSnackbarAction("Please Enter Type!!", "error")
      );
    } else {
      const para = JSON.parse(
        parameterRef.current.value
      ).reduce(
        (obj: any, item: any) =>
          Object.assign(obj, { [item.key]: item.value }),
        {}
      );
      const head =
        headerRef.current.value !== ""
          ? JSON.parse(headerRef.current.value)
          : undefined;

      setLoad(true);
      createCustomButton(
        organizationId,
        nameRef.current.value,
        urlRef.current.value,
        para,
        head,
        type,
        setLoad,
        dispatcher,
        close
      );
    }
  };
  return (
    <Modal
      isOpen={open}
      className={styles.parent}
      shouldCloseOnOverlayClick={true}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={close}
    >
      {load && <Loading />}
      <div className={styles.headingContainer}>
        <p className={styles.heading}>Add Custom Button</p>
      </div>
      <div className={styles.profileContainer}>
        <p className={styles.profile}>Button Name</p>
        <TextInput
          title={"Enter Button Name"}
          ref={nameRef}
          disabled={false}
        />
        <p
          className={styles.profile}
          style={{ marginTop: "25px" }}
        >
          Enter URL
        </p>
        <TextInput
          title={"Enter URL"}
          ref={urlRef}
          disabled={false}
        />
        <p
          className={styles.profile}
          style={{ marginTop: "25px" }}
        >
          Enter URL Parameters
        </p>
        <TextInput
          title={
            'Enter URL Parameter ( format - [{"key","value"}, {"key", "value"}] )'
          }
          ref={parameterRef}
          disabled={false}
        />
        <p
          className={styles.profile}
          style={{ marginTop: "25px" }}
        >
          Enter URL Headers
        </p>
        <TextInput
          title={"Enter URL Headers"}
          ref={headerRef}
          disabled={false}
        />
        <p
          className={styles.profile}
          style={{ marginTop: "25px" }}
        >
          Select Type
        </p>
        <Dropdown
          option={typeOptions}
          setSelectedValue={(val) =>
            setType(val.value.toLowerCase())
          }
        />
      </div>

      <div
        className={styles.buttonView}
        style={{ marginTop: "25px" }}
      >
        <button
          className={styles.cancelButton}
          onClick={close}
        >
          Cancel
        </button>
        <button
          className={styles.saveButton}
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
  };
};

export default connect(mapStateToProps)(
  AddCustomButtonModal
);
